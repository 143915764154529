<h4>{{ 'allDocuments.filters.types.title' | translate }}</h4>
<ng-container *ngIf="documentTypes && documentTypes.length">
  <div class="document-type-item"
    *ngFor="let documentType of documentTypes | slice:0:(showAllTypes ? documentTypes.length : 4)">
    <label [for]="documentType.code">
      <p-radioButton name="selectedDocumentType" [value]="documentType" [inputId]="documentType.code"
        [ngModel]="selectedValue" (ngModelChange)="onTypeChange($event)"></p-radioButton>
      {{documentType.label | translate}}
    </label>
  </div>
  <a href="#" class="cursor-pointer" (click)="$event.preventDefault(); toggleShowAllTypes()">
    {{ (showAllTypes ? 'allDocuments.filters.types.showLess' : 'allDocuments.filters.types.showAll') | translate }}
  </a>
</ng-container>
