import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DocumentStatus } from '../alldocuments.model';
import { DOCUMENT_STATUSES } from '../alldocuments.data';

@Component({
  selector: 'app-alldocuments-statuses',
  templateUrl: './alldocuments-statuses.component.html',
  styleUrls: ['./alldocuments-statuses.component.scss']
})
export class AlldocumentsStatusesComponent implements OnInit, OnChanges {
  @Input() selectedStatus: string | null = null;
  @Output() statusChange = new EventEmitter<string>();

  statuses: DocumentStatus[] = [];
  currentStatusClass: string = '';

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    // Initialize statuses from the imported constants with translated labels
    this.initializeStatuses();
    
    // Set initial status class if a status is selected
    this.updateStatusClass();

    // Subscribe to language changes to update translations
    this.translate.onLangChange.subscribe(() => {
      this.initializeStatuses();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Update status class when selectedStatus input changes
    if (changes.selectedStatus) {
      this.updateStatusClass();
    }
  }

  private initializeStatuses(): void {
    // Create a copy of DOCUMENT_STATUSES and translate the labels
    this.statuses = DOCUMENT_STATUSES.map(status => ({
      ...status,
      label: this.translate.instant(`documentStatuses.${status.value}`)
    }));
  }

  private updateStatusClass(): void {
    // Set the current status class based on selectedStatus
    if (this.selectedStatus) {
      this.currentStatusClass = `active-${this.selectedStatus}`;
    } else {
      this.currentStatusClass = '';
    }
  }

  onSelectedStatusChange(value: string): void {
    this.currentStatusClass = `active-${value}`;
    this.statusChange.emit(value);
  }
}
