<p-dialog [(visible)]="displayDialog" [draggable]="false" [header]="getModalTitle()" [maximizable]="!isMobileDevice()"
    [modal]="true" [style]="isMobileDevice() ? {width: '100vw', height: '100vh'} : {width: '70vw', height: '90vh'}"
    [contentStyle]="{overflow: 'hidden', display: 'flex', flexDirection: 'column'}" (onHide)="closeDialog()"
    styleClass="pdf-viewer-dialog" [position]="isMobileDevice() ? 'top' : 'center'">

    <div class="pdf-content">
        <div *ngIf="PdfLink == null || PdfLink == ''">
            <div [innerHTML]="documentHtmlContent" class="document-container"></div>
        </div>
        <!-- Use iframe for PDF display to avoid TypeScript errors with PDF.js -->
        <iframe #pdfIframe *ngIf="PdfLink && pdfUrl" [src]="pdfUrl" width="100%" frameborder="0"
            (load)="onPdfLoaded()" [attr.data-language]="originalDocumentLanguage"></iframe>
        <div *ngIf="PdfLink && pdfLoading" class="pdf-loading-container">
            <div class="pdf-loading-spinner">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                <p>{{ isChangingDocumentLanguage ? ('allDocuments.modal.changingLanguage' | translate) : ('allDocuments.modal.loading' |
                    translate) }}</p>
            </div>
        </div>
    </div>

    <div class="actions-footer">
        <div class="d-flex align-items-center gap-2">
            <p-button icon="pi pi-print" [label]="'allDocuments.modal.print' | translate" styleClass="p-button-sm"
                (onClick)="printDocument()"></p-button>
            <p-splitButton
                [label]="isDownloading ? ('allDocuments.modal.preparingFile' | translate) : ('allDocuments.modal.shareOriginal' | translate)"
                [icon]="isDownloading ? 'pi pi-spin pi-spinner' : 'pi pi-share-alt'" styleClass="p-button-sm"
                [model]="shareMenuItems" [disabled]="isDownloading">
            </p-splitButton>
        </div>
        <div class="lang-switcher-container">
            <p-selectButton [options]="availableLanguages" [(ngModel)]="originalDocumentLanguage" optionLabel="name"
                optionValue="code" disabled>
            </p-selectButton>
        </div>
        <p-button icon="pi pi-times" [label]="'modal.common.close' | translate" styleClass="p-button-sm p-button-danger"
            (onClick)="closeDialog()"></p-button>
    </div>
</p-dialog>