import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';

interface DocumentData {
	pdfLink: string;
	rawPdfUrl: string;
	pdfUrl: SafeResourceUrl;
	documentNumber?: string;
	documentHtmlContent?: string;
	documentId?: string;
	documentLanguage?: string; // Add this property to capture the document's original language
}

@Component({
	selector: 'app-alldocuments-view-pdf-modal',
	templateUrl: './alldocuments-view-pdf-modal.component.html',
	styleUrls: ['./alldocuments-view-pdf-modal.component.scss']
})
export class AlldocumentsViewPdfModalComponent implements OnInit, OnDestroy {
	@Output() pdfLoaded = new EventEmitter<void>();
	@Output() languageChanged = new EventEmitter<string>();

	// Add ViewChild to get reference to the iframe element
	@ViewChild('pdfIframe') pdfIframe: ElementRef;

	displayDialog: boolean = false;
	PdfLink: string = '';
	rawPdfUrl: string = '';
	pdfUrl: SafeResourceUrl;
	curentLang: string = '';
	documentHtmlContent: string = '';
	documentNumber: string = '';
	pdfLoading: boolean = true;
	selectedLanguage: string = '';
	shareMenuItems: MenuItem[] = [];

	// Document language properties
	documentId: string = '';
	isChangingDocumentLanguage: boolean = false;

	// Add iframeKey to force iframe refresh when it changes
	iframeKey: string = Date.now().toString();

	// Enhanced language options with flags
	availableLanguages = [
		{ code: 'en', name: 'English' },
		{ code: 'ar', name: 'العربية' },
		{ code: 'he', name: 'עברית' }
	];

	// Add a new property to track download state
	isDownloading: boolean = false;

	// Add property for original document language
	originalDocumentLanguage: string = '';

	constructor(
		private messageService: MessageService,
		private sanitizer: DomSanitizer,
		private translateService: TranslateService,
		private http: HttpClient
	) {
		this.curentLang = localStorage.getItem('curentLang') || 'en';
		this.selectedLanguage = this.curentLang;
	}

	ngOnInit() {
		this.initShareMenuItems();

		// Add event listener for window resize to handle orientation changes
		window.addEventListener('resize', () => {
			// Force change detection when window size changes
			// This ensures the dialog updates its size when device is rotated
			// We could use ChangeDetectorRef here if needed
		});
	}

	ngOnDestroy() {
	// Remove event listeners if added
	}

	/**
	 * Helper method to clear existing toasts before showing a new one
	 * This prevents multiple toasts from appearing simultaneously
	 */
	private clearAndAddToast(severity: string, summary: string, detail: string, life: number = 3000): void {
		// Clear existing toasts first
		this.messageService.clear();

		// Then add the new toast
		this.messageService.add({
			severity,
			summary,
			detail,
			life
		});
	}

	/**
	 * Initialize the share menu items for the SplitButton
	 */
	initShareMenuItems() {
		this.shareMenuItems = [
			{
				label: this.translateService.instant('allDocuments.modal.download'),
				icon: 'pi pi-download',
				command: () => this.downloadOriginalDocPdfFromLink()
			},
			{
				label: this.translateService.instant('allDocuments.modal.copyLink'),
				icon: 'pi pi-copy',
				command: () => this.copyLinkToClipboard(this.rawPdfUrl)
			},
			{
				label: this.translateService.instant('allDocuments.modal.shareWhatsapp'),
				icon: 'pi pi-whatsapp',
				tooltipOptions: {
					tooltipLabel: this.translateService.instant('allDocuments.modal.tooltips.shareWhatsapp'),
					tooltipPosition: 'left'
				},
				command: () => window.open(`https://wa.me/?text=${this.rawPdfUrl}`, '_blank')
			},
			{
				label: this.translateService.instant('allDocuments.modal.shareEmail'),
				icon: 'pi pi-envelope',
				tooltipOptions: {
					tooltipLabel: this.translateService.instant('allDocuments.modal.tooltips.shareEmail'),
					tooltipPosition: 'left'
				},
				command: () => window.open(`mailto:?subject=Document&body=${this.rawPdfUrl}`, '_blank')
			}
		];
	}

	/**
	 * Get the title for the modal, including document number if available
	 */
	getModalTitle(): string {
		if (this.documentNumber) {
			// Check if the translation key exists
			const key = 'allDocuments.modal.titleWithNumber';
			const hasKey = this.translateService.instant(key) !== key;

		  if (hasKey) {
			  return this.translateService.instant(key, { number: this.documentNumber });
		  } else {
			  // Fallback if the key doesn't exist
			  const title = this.translateService.instant('allDocuments.modal.title');
			  return `${title} - ${this.documentNumber}`;
		  }
	  }
	  return this.translateService.instant('allDocuments.modal.title');
  }

	openDialog(data: DocumentData): void {
		this.PdfLink = data.pdfLink;
		this.rawPdfUrl = data.rawPdfUrl;
		this.pdfUrl = data.pdfUrl;
		this.documentNumber = data.documentNumber || '';
		this.documentHtmlContent = data.documentHtmlContent || '';
		this.documentId = data.documentId || '';
		this.originalDocumentLanguage = data.documentLanguage || 'en';

	  this.pdfLoading = true;
	  this.selectedLanguage = this.curentLang;

	  this.initShareMenuItems();
	  this.displayDialog = true;
  }

	closeDialog(): void {
		this.displayDialog = false;
		this.pdfLoading = false;
	}

	onPdfLoaded(): void {
		this.pdfLoading = false;
		this.pdfLoaded.emit();
	}

	downloadDocumentPdf(): void {
		if (this.documentId && this.isChangingDocumentLanguage) {
			// If we're in the middle of changing languages, show a message
			this.clearAndAddToast(
				'info',
				this.translateService.instant('common.pleaseWait'),
				this.translateService.instant('common.documentLoading')
			);
			return;
		}

	  if (this.rawPdfUrl) {
		  // Set downloading flag to true
		  this.isDownloading = true;

		// Show loading message
		  this.clearAndAddToast(
			  'info',
			  this.translateService.instant('allDocuments.modal.preparingFile'),
			  this.translateService.instant('common.pleaseWait')
		  );

		fetch(this.rawPdfUrl)
			.then(response => response.blob())
			.then(blob => {
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				const filename = this.documentNumber
					? `Document-${this.documentNumber}-${this.originalDocumentLanguage}.pdf`
					: `Document-${this.originalDocumentLanguage}.pdf`;
				link.download = filename;
				link.click();
				window.URL.revokeObjectURL(url);

			// Show success message
				this.clearAndAddToast(
					'success',
					this.translateService.instant('common.success'),
					this.translateService.instant('common.downloadSuccess')
				);

			// Reset the flag when download completes
			this.isDownloading = false;
		})
		  .catch(error => {
			  console.error('Error downloading document:', error);
			  this.clearAndAddToast(
				  'error',
				  this.translateService.instant('common.error'),
				  this.translateService.instant('common.downloadError')
			  );

			// Also reset the flag on error
			this.isDownloading = false;
		});
	  }
  }

	downloadOriginalDocPdfFromLink(): void {
		this.downloadDocumentPdf();
	}

	copyLinkToClipboard(url: string): void {
		if (navigator.clipboard) {
			navigator.clipboard.writeText(url)
				.then(() => {
					this.clearAndAddToast(
						'success',
						this.translateService.instant('common.success'),
						this.translateService.instant('common.linkCopied')
					);
				})
				.catch(err => {
					console.error('Could not copy text: ', err);
					this.clearAndAddToast(
						'error',
						this.translateService.instant('common.error'),
						this.translateService.instant('common.copyError')
					);
				});
		} else {
			// Fallback for browsers that don't support clipboard API
			const textArea = document.createElement('textarea');
			textArea.value = url;
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();
			try {
				document.execCommand('copy');
				this.clearAndAddToast(
					'success',
					this.translateService.instant('common.success'),
					this.translateService.instant('common.linkCopied')
				);
			} catch (err) {
				console.error('Could not copy text: ', err);
				this.clearAndAddToast(
					'error',
					this.translateService.instant('common.error'),
					this.translateService.instant('common.copyError')
				);
			}
			document.body.removeChild(textArea);
		}
	}

	/**
	 * Get language name by code
	 */
	getLanguageName(code: string): string {
		const language = this.availableLanguages.find(lang => lang.code === code);
		return language ? language.name : code.toUpperCase();
	}

  /**
   * Print the document using the browser's print dialog
   * Uses the loaded iframe when possible for a better experience
   */
	printDocument(): void {
		try {
			// Check if the iframe exists and has loaded content
			if (!this.pdfIframe || this.pdfLoading) {
				this.clearAndAddToast(
					'info',
					this.translateService.instant('common.info'),
					this.translateService.instant('common.documentLoading')
				);
				return;
			}

			// Show loading message
			this.clearAndAddToast(
				'info',
				this.translateService.instant('common.info'),
				this.translateService.instant('common.pleaseWait')
			);

			// Get reference to the iframe element
			const iframe = this.pdfIframe.nativeElement;

			// Check if iframe's contentWindow is accessible
			if (iframe.contentWindow) {
				// Try to print from the iframe directly
				setTimeout(() => {
					try {
						// Focus and print the iframe content
						iframe.contentWindow.focus();
						iframe.contentWindow.print();

						// Show success message
						this.clearAndAddToast(
							'success',
							this.translateService.instant('common.success'),
							this.translateService.instant('common.printSuccess')
						);
					} catch (iframeError) {
						console.error('Error printing from iframe:', iframeError);
						// If direct iframe printing fails, fall back to new window approach
						this.printInNewWindow();
					}
				}, 500); // Small delay to ensure the iframe is ready
			} else {
				// If iframe contentWindow is not accessible (likely due to cross-origin issues)
				// Fall back to opening in a new window
				this.printInNewWindow();
			}
		} catch (error) {
			console.error('Error in printDocument:', error);
			this.printInNewWindow();
		}
	}

	/**
	 * Fallback print method that opens the PDF in a new window
	 * Used if direct iframe printing fails
	 */
	private printInNewWindow(): void {
		try {
			if (!this.rawPdfUrl) {
				this.clearAndAddToast(
					'error',
					this.translateService.instant('common.error'),
					'PDF URL is not available'
				);
				return;
			}

			// Open the PDF in a new window - this is the most reliable cross-browser solution
			const printWindow = window.open(this.rawPdfUrl, '_blank');

			if (printWindow) {
				// Show success message
				this.clearAndAddToast(
					'success',
					this.translateService.instant('common.success'),
					this.translateService.instant('common.printSuccess')
				);
			} else {
				// Show warning about popup blockers
				this.clearAndAddToast(
					'warning',
					this.translateService.instant('common.warning'),
					this.translateService.instant('common.popupBlocked')
				);
			}
		} catch (error) {
			console.error('Error opening document for print in new window:', error);
			this.clearAndAddToast(
				'error',
				this.translateService.instant('common.error'),
				'Failed to open document. Please try downloading and printing manually.'
			);
		}
	}

	/**
	 * Check if the current device is a mobile device
	 * @returns boolean indicating if the device is mobile
	 */
	isMobileDevice(): boolean {
		return window.innerWidth <= 768;
	}
}
