import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHelpService } from 'src/app/Data/service/http-help.service';
import { NavService } from '../../../../services/nav.service';

@Component({
	selector: 'app-languages',
	templateUrl: './languages.component.html',
	styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {

	public language: boolean = false;
	public userRegistId: any = JSON.parse(localStorage.getItem('user_id'));

	public languages: any[] = [
		{
			language: 'English',
			code: 'en',
			icon: 'us'
		},
		{
			language: 'Arabic',
			code: 'ar',
			icon: 'sa'
		},
		{
			language: 'Hebrew',
			code: 'he',
			icon: 'il'
		}
	];

	public selectedLanguage: any = {
		language: 'English',
		code: 'en',
		icon: 'us'
	};

	constructor(public navServices: NavService, private translate: TranslateService, private spinner: NgxSpinnerService, private service: HttpHelpService) { }

	ngOnInit() {
		const currentLang = localStorage.getItem('curentLang') || 'en';
		this.selectedLanguage = this.languages.find(lang => lang.code === currentLang);
	}

	changeLang(language: string) {
		this.spinner.show();

		let model = {
			"language": language === 'en' ? 'en' : language === 'ar' ? 'ar' : 'he',
			"user_id": this.userRegistId
		}

		this.service.updateUserLangById(model).subscribe({
			next: res => {
				if (res.status == 200) {
					this.changeLanguage(language);
					window.location.reload();
					this.spinner.hide();
				}
			},
			error: err => {
				this.spinner.hide();
			}
		});
	}

	changeLanguage(lang: string) {
		localStorage.setItem('curentLang', lang);
		this.translate.use(lang);
		this.selectedLanguage = this.languages.find(language => language.code === lang);
	}

}
