<app-breadcrumb
  [title]="
    curentLang === 'en'
      ? 'Add Invoice And Receipt'
      : curentLang === 'ar'
      ? ' اضافة فاتورة وايصال '
      : ' הוספת חשובנית מס/ קבלה'
  "
  [items]="
    curentLang === 'en'
      ? ['Dashboard']
      : curentLang === 'ar'
      ? ['لوحة التحكم']
      : ['  לוח הבקרה']
  "
  [active_item]="
    curentLang === 'en'
      ? 'Add Invoice And Receipt'
      : curentLang === 'ar'
      ? ' اضافة فاتورة وايصال '
      : ' הוספת חשובנית מס/ קבלה'
  "
>
</app-breadcrumb>

<div class="container-fluid basic_table">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-block row">
          <div class="col-sm-12 col-lg-12 col-xl-12">
            <div>
              <form [formGroup]="invoiceReceiptForm">
                <div class="row">
                  <div class="col-lg-12 row">
                    <div class="col-md-5">
                      <div class="form-group mx-2">
                        <label class="mt-2">{{
                          curentLang === "en"
                            ? "Select Company Name"
                            : curentLang === "ar"
                            ? "اختر اسم الشركة"
                            : "שם החברה "
                        }}</label>
                        <span class="text-danger">*</span>
                        <ng-select
                          formControlName="company_id"
                          [disabled]="mastercompany?.length === 1"
                          style="cursor: pointer"
                          placeholder="{{
                            curentLang === 'en'
                              ? 'Select'
                              : curentLang === 'ar'
                              ? 'اختر'
                              : 'בחר'
                          }}"
                        >
                          <ng-option
                            *ngFor="let item of mastercompany"
                            [value]="item.company_id"
                          >
                            {{
                              curentLang === "en"
                                ? item.company_name_en
                                : curentLang === "ar"
                                ? item.company_name_ar
                                : item.company_name_he
                            }}
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>

                    <div class="col-lg-5">
                      <div class="form-group mx-1">
                        <!-- 🌐 Desktop View -->
                        <div class="d-none d-lg-block">
                          <label class="mt-2">
                            {{
                              curentLang === "en"
                                ? "Select Customer Name"
                                : curentLang === "ar"
                                ? "اختر اسم العميل"
                                : "לקוח"
                            }}
                          </label>
                          <span class="text-danger">*</span>
                          <ng-select
                            formControlName="customer_id"
                            (change)="getCustomerById()"
                            style="cursor: pointer"
                            placeholder="{{
                              curentLang === 'en'
                                ? 'Select'
                                : curentLang === 'ar'
                                ? 'اختر'
                                : 'בחר'
                            }}"
                          >
                            <ng-option
                              *ngFor="let item of masterCustomer"
                              [value]="item.customer_id"
                            >
                              {{
                                curentLang === "en"
                                  ? item.customer_name_en
                                  : curentLang === "ar"
                                  ? item.customer_name_ar
                                  : item.customer_name_he
                              }}
                            </ng-option>
                          </ng-select>
                        </div>
  
                        <!-- 📱 Mobile View -->
                        <div class="d-lg-none">
                          <label class="mt-2">
                            {{ curentLang === "en" ? "Choose Customer" : curentLang === "ar" ? "اختر العميل" : "בחר את הלקוח" }}
                            <span class="text-danger">*</span>
                          </label>
                        
                          <label
                            class="select-customer-label mt-1"
                            (click)="openChooseCustomerModal(chooseCustomerModal)"
                            style="cursor: pointer; display: block; padding: 10px; background: #f5f5f5; border: 1px solid #ccc;"
                          >
                            {{
                              customerNameSelected
                                ? customerNameSelected
                                : curentLang === "en"
                                ? "Select Customer Name"
                                : curentLang === "ar"
                                ? "اختر اسم العميل"
                                : "לקוח"
                            }}
                          </label>
                        </div>
  
                        <!-- 🧩 Custom Modal for Mobile -->
                        <ng-template #chooseCustomerModal let-modal>
                          <div
                            class="modal-header row d-flex justify-content-between"
                          >
                            <div class="col-md-12">
                              <h6
                                class="modal-title row d-flex justify-content-between"
                                style="color: black"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Choose Customer "
                                    : curentLang === "ar"
                                    ? " اختر العميل "
                                    : " בחר את הלקוח   "
                                }}
                                <button
                                  type="button"
                                  class="close btn-close"
                                  style="color: black"
                                  aria-label="Close"
                                  (click)="modal.dismiss('Cross click')"
                                ></button>
                              </h6>
  
                              <div
                                class="modal-body xl:flex xl:justify-content-center"
                              >
                                <div
                                  class="d-flex justify-content-between align-items-center mb-1 bg-light p-2"
                                >
                                  <button
                                    [hidden]="
                                      user_type == 200 || user_type == 201
                                    "
                                    class="btn text-center"
                                    style="background-color: #141414; color: #fff"
                                    (click)="openXl(content)"
                                  >
                                    <i
                                      class="fa fa-plus-circle mx-1"
                                      style="
                                        font-size: 14px;
                                        cursor: pointer;
                                        color: #fff;
                                      "
                                    ></i>
                                  </button>
                                </div>
  
                                <p-orderList
                                  [value]="masterCustomer"
                                  [filterBy]="
                                    'customer_name_en,customer_name_ar,customer_name_he,id_number'
                                  "
                                  formControlName="customer_id"
                                  [showButtons]="true"
                                  [responsive]="true"
                                  [sourceStyle]="{ height: '30rem' }"
                                  [targetStyle]="{ height: '30rem' }"
                                  [listStyle]="{ height: '25rem' }"
                                  header=""
                                  filterPlaceholder="{{
                                    curentLang === 'en'
                                      ? 'Filter by name or ID'
                                      : curentLang === 'ar'
                                      ? 'ابحث بالاسم أو برقم الهوية'
                                      : 'סינון לפי שם או מספר תעודה'
                                  }}"
                                  (onReorder)="getCustomerById($event)"
                                  (onSelectionChange)="
                                    onCustomerSelect($event); modal.close()
                                  "
                                >
                                  <ng-template let-customer pTemplate="item">
                                    <div
                                      class="flex flex-wrap align-items-center customer-item"
                                    >
                                      <div class="flex-1 flex flex-column">
                                        <span style="font-size: 14px">
                                          <i class="fa fa-user text-sm mx-1"></i>
                                          {{
                                            curentLang === "en"
                                              ? customer.customer_name_en
                                              : curentLang === "ar"
                                              ? customer.customer_name_ar
                                              : customer.customer_name_he
                                          }}
                                          <br class="m-0 p-0" />
                                          <i
                                            class="fa fa-id-card text-sm mx-1"
                                          ></i>
                                          {{ customer.id_number }}
                                          <hr
                                            style="color: #141414"
                                            class="m-0 p-0"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </ng-template>
                                </p-orderList>
                              </div>
                            </div>
                            <hr style="color: gray" class="py-2" />
                          </div>
                        </ng-template>
                      </div>
                    </div>
                    <!-- ✅ ADD CUSTOMER MODAL -->
                    <ng-template #content let-modal>
                      <div class="modal-header d-flex justify-content-between">
                        <h6 class="modal-title" style="color: grey">
                          {{
                            curentLang === "en"
                              ? "Add Customer "
                              : curentLang === "ar"
                              ? " اضافة عميل "
                              : " הוסף לקוח "
                          }}
                        </h6>
                        <button
                          type="button"
                          class="close btn-close"
                          style="color: grey"
                          aria-label="Close"
                          (click)="modal.dismiss('Cross click')"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="container-fluid">
                          <div class="edit-profile">
                            <form [formGroup]="addCustomer" novalidate>
                              <!-- الصف الأول -->
                              <div class="row">
                                <div class="mb-3 col-xl-4 col-md-4">
                                  <label class="form-label" style="color: grey">
                                    {{
                                      curentLang === "en"
                                        ? "Customer Name"
                                        : curentLang === "ar"
                                        ? "اسم العميل"
                                        : " שם לקוח "
                                    }}
                                    <span class="text-danger" style="color: red"
                                      >*</span
                                    >
                                  </label>
                                  <input
                                    style="color: grey"
                                    class="form-control"
                                    type="text"
                                    formControlName="customer_name_he"
                                    maxlength="80"
                                    required
                                    [ngClass]="{
                                      'is-invalid':
                                        validate &&
                                        addCustomer.controls['customer_name_he']
                                          .invalid
                                    }"
                                  />
                                  <div
                                    *ngIf="
                                      validate &&
                                      addCustomer.controls['customer_name_he']
                                        .invalid
                                    "
                                    class="invalid-feedback text-danger"
                                  >
                                    {{
                                      curentLang === "en"
                                        ? "Please enter customer name"
                                        : curentLang === "ar"
                                        ? "الرجاء ادخال اسم العميل"
                                        : "נא להזין את שם הלקוח"
                                    }}
                                  </div>
                                </div>
  
                                <div class="mb-3 col-xl-4 col-md-4">
                                  <label class="form-label" style="color: grey">
                                    {{
                                      curentLang === "en"
                                        ? "Customer Type"
                                        : curentLang === "ar"
                                        ? "نوع العميل"
                                        : "סוג לקוח"
                                    }}
                                    <span class="text-danger" style="color: red"
                                      >*</span
                                    >
                                  </label>
                                  <select
                                    class="form-control form-select"
                                    formControlName="customer_type"
                                    [ngClass]="{
                                      'is-invalid':
                                        validate &&
                                        addCustomer.controls['customer_type']
                                          .invalid
                                    }"
                                  >
                                    <option
                                      *ngFor="
                                        let item of customerTypeData;
                                        let i = index
                                      "
                                      [value]="item?.lookup_detail_id"
                                    >
                                      {{
                                        curentLang === "en"
                                          ? item?.lookupDetailEnName
                                          : curentLang === "ar"
                                          ? item?.lookupDetailArName
                                          : item?.lookupDetailHeName
                                      }}
                                    </option>
                                  </select>
                                  <div
                                    *ngIf="
                                      validate &&
                                      addCustomer.controls['customer_type']
                                        .invalid
                                    "
                                    class="invalid-feedback text-danger"
                                  >
                                    {{
                                      curentLang === "en"
                                        ? "Please choose customer type"
                                        : curentLang === "ar"
                                        ? "الرجاء اختيار نوع العميل"
                                        : "אנא בחר סוג לקוח"
                                    }}
                                  </div>
                                </div>
  
                                <div class="mb-3 col-xl-4 col-md-4">
                                  <label class="form-label" style="color: grey">
                                    {{
                                      curentLang === "en"
                                        ? "Customer Phone"
                                        : curentLang === "ar"
                                        ? "هاتف العميل"
                                        : "טלפון לקוח"
                                    }}
                                  </label>
                                  <div class="input-group">
                                    <span
                                      style="color: #141414"
                                      class="input-group-text"
                                      >+972</span
                                    >
                                    <input
                                      class="form-control"
                                      type="text"
                                      maxlength="10"
                                      formControlName="customer_phone"
                                      [ngClass]="{
                                        'is-invalid':
                                          validate &&
                                          addCustomer.controls['customer_phone']
                                            .invalid
                                      }"
                                    />
                                  </div>
                                  <div
                                    *ngIf="
                                      validate &&
                                      addCustomer.controls['customer_phone']
                                        .invalid
                                    "
                                    class="invalid-feedback text-danger"
                                  >
                                    {{
                                      curentLang === "en"
                                        ? "Please enter a valid phone number"
                                        : curentLang === "ar"
                                        ? "الرجاء إدخال رقم هاتف صالح"
                                        : "נא להזין מספר טלפון תקין"
                                    }}
                                  </div>
                                </div>
                              </div>
  
                              <!-- الصف الثاني -->
                              <div class="row">
                                <div
                                  class="mb-3 col-xl-4 col-md-4"
                                  *ngIf="addCustomer.value.customer_type != 6"
                                >
                                  <label class="form-label" style="color: grey">
                                    {{
                                      curentLang === "en"
                                        ? "ID Number"
                                        : curentLang === "ar"
                                        ? "رقم الهوية"
                                        : "מספר ישות (ת.ז / ח.פ.)"
                                    }}
                                  </label>
                                  <input
                                    style="color: grey"
                                    class="form-control"
                                    type="number"
                                    formControlName="id_number"
                                    maxlength="9"
                                  />
                                </div>
  
                                <div class="mb-3 col-xl-4 col-md-4">
                                  <label class="form-label" style="color: grey">
                                    {{
                                      curentLang === "en"
                                        ? "City Name  "
                                        : curentLang === "ar"
                                        ? " اسم المدينة "
                                        : "שם העיר"
                                    }}
                                    <!-- <span class="text-danger" style="color: red;">*</span> -->
                                  </label>
                                  <input
                                    style="color: grey"
                                    class="form-control"
                                    type="text"
                                    formControlName="customer_address_city"
                                    maxlength="100"
                                  />
                                  <!-- <div *ngIf="validate && addCustomer.controls['customer_address_city'].invalid" class="invalid-feedback text-danger">
                                {{ curentLang === 'en' ? 'Please enter customer city' : (curentLang === 'ar' ? 'الرجاء إدخال مدينة العميل' : 'אנא הזן עיר לקוח') }}
                            </div> -->
                                </div>
  
                                <div class="mb-3 col-xl-4 col-md-4">
                                  <label class="form-label" style="color: grey">
                                    {{
                                      curentLang === "en"
                                        ? "Street Name"
                                        : curentLang === "ar"
                                        ? "أسم الشارع"
                                        : "שם רחוב"
                                    }}
                                    <!-- <span class="text-danger" style="color: red;">*</span> -->
                                  </label>
                                  <input
                                    style="color: grey"
                                    class="form-control"
                                    type="text"
                                    formControlName="customer_address_str"
                                    maxlength="100"
                                  />
                                  <!-- <div *ngIf="validate && addCustomer.controls['customer_address_str'].invalid" class="invalid-feedback text-danger">
                                {{ curentLang === 'en' ? 'Please enter customer street' : (curentLang === 'ar' ? 'الرجاء إدخال شارع العميل' : 'אנא הזן רחוב לקוח') }}
                            </div> -->
                                </div>
                              </div>
  
                              <!-- الصف الثالث -->
                              <div class="row">
                                <div class="mb-3 col-xl-4 col-md-4">
                                  <label class="form-label" style="color: grey">
                                    {{
                                      curentLang === "en"
                                        ? "Building Number"
                                        : curentLang === "ar"
                                        ? "رقم المبني"
                                        : "מספר בניין"
                                    }}
                                    <!-- <span class="text-danger" style="color: red;">*</span> -->
                                  </label>
                                  <input
                                    style="color: grey"
                                    class="form-control"
                                    type="number"
                                    formControlName="customer_Building_number"
                                    maxlength="100"
                                  />
                                  <!-- <div *ngIf="validate && addCustomer.controls['customer_Building_number'].invalid" class="invalid-feedback text-danger">
                                {{ curentLang === 'en' ? 'Please enter customer building number' : (curentLang === 'ar' ? 'الرجاء إدخال رقم مبنى العميل' : 'אנא הזן מספר בניין לקוח') }}
                            </div> -->
                                </div>
  
                                <div class="mb-3 col-xl-4 col-md-4">
                                  <label class="form-label" style="color: grey">
                                    {{
                                      curentLang === "en"
                                        ? "Zip Code"
                                        : curentLang === "ar"
                                        ? "الرقم البريدي"
                                        : "מיקוד דואר"
                                    }}
                                    <!-- <span class="text-danger" style="color: red;">*</span> -->
                                  </label>
                                  <input
                                    style="color: grey"
                                    class="form-control"
                                    type="number"
                                    formControlName="customer_address_zip_code"
                                    maxlength="20"
                                  />
                                  <!-- <div *ngIf="validate && addCustomer.controls['customer_address_zip_code'].invalid" class="invalid-feedback text-danger">
                                {{ curentLang === 'en' ? 'Please enter customer zip code' : (curentLang === 'ar' ? 'الرجاء إدخال الرقم البريدي للعميل' : 'אנא הזן את המיקוד של הלקוח') }}
                            </div> -->
                                </div>
  
                                <div class="mb-3 col-xl-4 col-md-4">
                                  <label class="form-label" style="color: grey">
                                    {{
                                      curentLang === "en"
                                        ? "Customer Vat Number"
                                        : curentLang === "ar"
                                        ? "الرقم الضريبي للعميل"
                                        : "מספר תיק במס ההכנסה"
                                    }}
                                  </label>
                                  <input
                                    style="color: grey"
                                    class="form-control"
                                    type="text"
                                    formControlName="customer_vat_number"
                                    maxlength="9"
                                    pattern="[0-9]{9}"
                                    title="{{
                                      curentLang === 'en'
                                        ? 'Please enter exactly 9 digits'
                                        : curentLang === 'ar'
                                        ? 'يرجى إدخال 9 أرقام فقط'
                                        : 'אנא הזן 9 ספרות בדיוק'
                                    }}"
                                    [ngClass]="{
                                      'is-invalid':
                                        validate &&
                                        addCustomer.controls[
                                          'customer_vat_number'
                                        ].invalid
                                    }"
                                  />
                                  <div
                                    *ngIf="
                                      validate &&
                                      addCustomer.controls['customer_vat_number']
                                        .invalid
                                    "
                                    class="invalid-feedback text-danger"
                                  >
                                    {{
                                      curentLang === "en"
                                        ? "Please enter exactly 9 digits"
                                        : curentLang === "ar"
                                        ? "يرجى إدخال 9 أرقام فقط"
                                        : "אנא הזן 9 ספרות בדיוק"
                                    }}
                                    <span class="text-danger" style="color: red"
                                      >*</span
                                    >
                                  </div>
                                </div>
                              </div>
  
                              <!-- الصف الرابع (حقل ضريبة القيمة المضافة، أو يمكنك إضافة المزيد من الحقول) -->
                              <div class="row">
                                <div
                                  class="mb-3 col-xl-4 col-md-4"
                                  *ngIf="addCustomer.value.customer_type != 5"
                                >
                                  <label class="form-label" style="color: grey">
                                    {{
                                      curentLang === "en"
                                        ? "Customer Vat Tax"
                                        : curentLang === "ar"
                                        ? "رقم ضريبة القيمة المضافة"
                                        : "  מס עוסק מורשה   "
                                    }}
                                  </label>
                                  <input
                                    style="color: grey"
                                    class="form-control"
                                    type="number"
                                    formControlName="customer_vat_tax"
                                    maxlength="9"
                                    [ngClass]="{
                                      'is-invalid':
                                        validate &&
                                        addCustomer.controls['customer_vat_tax']
                                          .invalid
                                    }"
                                  />
                                  <div
                                    *ngIf="
                                      validate &&
                                      addCustomer.controls['customer_vat_tax']
                                        .invalid
                                    "
                                    class="invalid-feedback text-danger"
                                  >
                                    {{
                                      curentLang === "en"
                                        ? "Please enter a valid VAT number"
                                        : curentLang === "ar"
                                        ? "الرجاء إدخال رقم ضريبة القيمة المضافة الصحيح"
                                        : 'אנא הזן מספר מס מע"מ תקין'
                                    }}
                                  </div>
                                </div>
                              </div>
  
                              <hr />
                              <!--
                    <h6 class="modal-title" style="color: grey;">
                      {{ curentLang === 'en' ? 'Additional fields' : (curentLang === 'ar' ? ' حقول اضافية   ' : 'שדות נוספים')}} 
                  </h6>
                  <hr style="width: 25%;"> 
  -->
                              <div class="row">
                                <!--   <div class="mb-3 col-xl-6 col-md-6">
                      <label class="form-label" style="color: grey;">
                          {{ curentLang === 'en' ? 'City Name Ar' : (curentLang === 'ar' ? 'اسم المدينة عربي' : 'שם עיר בערבית') }}
                           <span class="text-danger" style="color: red;">*</span>  
                      </label>
                      <input 
                          style="color: grey;" 
                          class="form-control"
                          type="text" 
                          formControlName="customer_address_city_ar"
                          maxlength="100"
                          
                      />
                     <div *ngIf="validate && addCustomer.controls['customer_address_city'].invalid" class="invalid-feedback text-danger">
                          {{ curentLang === 'en' ? 'Please enter customer city' : (curentLang === 'ar' ? 'الرجاء إدخال مدينة العميل' : 'אנא הזן עיר לקוח') }}
                      </div> 
                  </div>-->
  
                                <!--    <div class="mb-3 col-xl-6 col-md-6">
                    <label class="form-label" style="color: grey;">
                        {{ curentLang === 'en' ? 'City Name En' : (curentLang === 'ar' ? 'اسم المدينة انجليزي' : 'שם העיר באנגלית') }}
                         <span class="text-danger" style="color: red;">*</span>  
                    </label>
                    <input 
                        style="color: grey;" 
                        class="form-control"
                        type="text" 
                        formControlName="customer_address_city_en"
                        maxlength="100"
                        
                    />
                     <div *ngIf="validate && addCustomer.controls['customer_address_city'].invalid" class="invalid-feedback text-danger">
                        {{ curentLang === 'en' ? 'Please enter customer city' : (curentLang === 'ar' ? 'الرجاء إدخال مدينة العميل' : 'אנא הזן עיר לקוח') }}
                    </div>  
                </div>
                    -->
  
                                <!--   <div class="mb-3 col-xl-6 col-md-6">
                      <label class="form-label" style="color: grey;">
                          {{ curentLang === 'en' ? 'Street Name Ar' : (curentLang === 'ar' ? 'اسم الشارع عربي' : "שם הרחוב הוא ערבי") }}
                            <span class="text-danger" style="color: red;">*</span>  
                      </label>
                      <input 
                          style="color: grey;" 
                          class="form-control"
                          type="text" 
                          formControlName="customer_address_str_ar"
                          maxlength="100"
                      />
                       <div *ngIf="validate && addCustomer.controls['customer_address_str'].invalid" class="invalid-feedback text-danger">
                          {{ curentLang === 'en' ? 'Please enter customer street' : (curentLang === 'ar' ? 'الرجاء إدخال شارع العميل' : 'אנא הזן רחוב לקוח') }}
                      </div> 
                  </div>-->
  
                                <!--  <div class="mb-3 col-xl-6 col-md-6">
                    <label class="form-label" style="color: grey;">
                        {{ curentLang === 'en' ? 'Street Name En' : (curentLang === 'ar' ? 'اسم الشارع انجليزي' : 'שם רחוב באנגלית') }}
                         <span class="text-danger" style="color: red;">*</span> 
                    </label>
                    <input 
                        style="color: grey;" 
                        class="form-control"
                        type="text" 
                        formControlName="customer_address_str_en"
                        maxlength="100"
                    />
                  <div *ngIf="validate && addCustomer.controls['customer_address_str'].invalid" class="invalid-feedback text-danger">
                        {{ curentLang === 'en' ? 'Please enter customer street' : (curentLang === 'ar' ? 'الرجاء إدخال شارع العميل' : 'אנא הזן רחוב לקוח') }}
                    </div>  
                </div>-->
                              </div>
  
                              <div class="modal-footer">
                                <button
                                  type="button"
                                  class="btn btn-sm m-auto mt-2"
                                  style="background-color: #141414; color: #fff"
                                  (click)="addedData()"
                                >
                                  {{
                                    curentLang === "en"
                                      ? "Confirm"
                                      : curentLang === "ar"
                                      ? "تأكيد"
                                      : "אישור"
                                  }}
                                  <i
                                    class="fa fa-check-circle mx-1"
                                    style="color: #fff"
                                  ></i>
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-sm m-auto mt-2 btn-danger"
                                  (click)="modal.close('Close click')"
                                >
                                  {{
                                    curentLang === "en"
                                      ? "Back"
                                      : curentLang === "ar"
                                      ? "رجوع"
                                      : "חזרה"
                                  }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </ng-template>

                    <!-- <div class="col-md-2" style="padding-top: 42px">
                      <button
                        [hidden]="user_type == 200"
                        class="btn m-1"
                        style="background-color: #141414; color: #fff"
                        (click)="openXl(content)"
                      >
                        <i class="fa fa-plus-circle mx-1"></i>
                        {{
                          curentLang === "en"
                            ? "Add Customer"
                            : curentLang === "ar"
                            ? "  اضافة عميل"
                            : "   הוספת לקוח  "
                        }}
                      </button>
                    </div> -->
                  </div>

                  <div class="col-lg-12 row">
                    <div class="col-md-4">
                      <div class="col-lg-12">
                        <div class="form-group mx-2">
                          <label class="mt-2">
                            {{
                              curentLang === "en"
                                ? "Invoice Date"
                                : curentLang === "ar"
                                ? "تاريخ الفاتورة"
                                : "  תאריך "
                            }}</label
                          >
                          <input
                            type="date"
                            (change)="
                              filter($event);
                              getVatPercentageByVatListIdAndDate()
                            "
                            formControlName="invoice_date"
                            class="form-control w-100"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group mx-2">
                        <label class="mt-2">
                          {{
                            curentLang === "en"
                              ? "Vat Type"
                              : curentLang === "ar"
                              ? "نوع الضريبة"
                              : 'סוג המע"מ   '
                          }}</label
                        >
                        <span class="text-danger">*</span>

                        <ng-select
                          formControlName="vat_list_id"
                          (change)="getVatPercentageByVatListIdAndDate()"
                          style="cursor: pointer"
                          placeholder="{{
                            curentLang === 'en'
                              ? 'Select'
                              : curentLang === 'ar'
                              ? 'اختر'
                              : 'בחר'
                          }}"
                        >
                          <ng-option
                            *ngFor="let item of vatTypeList; let i = index"
                            [value]="item?.vat_list_id"
                          >
                            {{
                              curentLang === "en"
                                ? item.vat_name_en
                                : curentLang === "ar"
                                ? item.vat_name_ar
                                : item.vat_name_he
                            }}
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <!-- Toggle Button -->
                      <div class="form-group mx-2">
                        <label
                        class="mt-2"
                        style="cursor: pointer; color: black; text-decoration: underline;"
                        (click)="toggleFieldsVisibility()"
                      >
                        {{
                          curentLang === "en"
                            ? "Additional Info"
                            : curentLang === "ar"
                            ? "معلومات إضافية"
                            : "מידע נוסף"
                        }}
                      </label>
                      </div>
                    </div>
                    
                    <!-- Fields to Show/Hide -->
                    <div *ngIf="showFields">
                      
                      <div class="col-lg-3">
                        <div class="form-group mx-2">
                          <label class="mt-2">
                            {{
                              curentLang === "en"
                                ? "Document Language"
                                : curentLang === "ar"
                                ? "لغة المستند "
                                : "שפת מסמך"
                            }}
                          </label>
                          <select
                            formControlName="document_language"
                            class="form-control form-select"
                            (change)="filter($event)"
                          >
                            <option
                              *ngFor="let lang of availableLanguages"
                              [value]="lang.code"
                              [selected]="lang.code === 'he'"
                            >
                              {{ lang.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    
                      <div class="col-lg-3">
                        <div class="form-group mx-2">
                          <label class="mt-2">
                            {{
                              curentLang === "en"
                                ? "Currency"
                                : curentLang === "ar"
                                ? "العملة"
                                : "מטבע"
                            }}
                          </label>
                          <input
                            type="text"
                            formControlName="currency"
                            class="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <br />

                <div class="row">
                  <div class="col-lg-12">
                    <div class="table-responsive">
                      <table
                        class="table table-bordered d-none d-lg-table"
                        formArrayName="lines"
                      >
                        <thead>
                          <tr>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Product Name"
                                  : curentLang === "ar"
                                  ? "اسم المنتج"
                                  : "שם מוצר "
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Product Desc"
                                  : curentLang === "ar"
                                  ? "وصف المنتج"
                                  : " תיאור מוצר"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Price per unit"
                                  : curentLang === "ar"
                                  ? "سعر الوحدة"
                                  : "מחיר יחידה  "
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Quantity"
                                  : curentLang === "ar"
                                  ? "الكمية"
                                  : "כמות"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Discount"
                                  : curentLang === "ar"
                                  ? "خصم"
                                  : "הנחה"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Vat Amount"
                                  : curentLang === "ar"
                                  ? "قيمة الضريبة"
                                  : ' מע"מ'
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Total Amount"
                                  : curentLang === "ar"
                                  ? "المجموع"
                                  : '  סה"כ'
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Remove"
                                  : curentLang === "ar"
                                  ? "حذف"
                                  : "מחק"
                              }}
                            </th>
                          </tr>
                        </thead>
<tbody>
  <tr *ngFor="let item of invproducts.controls; let i = index" [formGroupName]="i">
    <td style="width: 20%">
      <ng-select formControlName="product_id" (change)="productchange(i)" style="cursor: pointer">
        <ng-option *ngFor="let prod of masterproduct" [value]="prod.product_id">
          {{ curentLang === 'en' ? prod.product_name_en : curentLang === 'ar' ? prod.product_name_ar : prod.product_name_he }}
        </ng-option>
      </ng-select>
    </td>
    <td style="width: 20%">
      <textarea maxlength="250" cols="10" rows="1" class="form-control form-control-sm" formControlName="description"></textarea>
    </td>
    <td style="width: 10%">
      <input class="form-control" type="text" appNumberFormat (change)="Itemcalculation(i)" formControlName="price_per_unit" />
    </td>
    <td style="width: 9%">
      <input class="form-control" type="text" appNumberFormat (change)="Itemcalculation(i)" formControlName="quantity" />
    </td>
    <td style="width: 10%">
      <input class="form-control" type="text" appNumberFormat (change)="Itemcalculation(i)" formControlName="discount" />
    </td>
    <td style="width: 11%">
      <input
        class="form-control"
        type="text"
        appNumberFormat
        formControlName="vat_amount"
        [readonly]="true"
      />
    </td>
    <td style="width: 13%">
      <input class="form-control" type="text" appNumberFormat formControlName="total_amount" (change)="onTotalAmountChange(i)" />
    </td>
    <td style="width: 5%">
      <i (click)="Removeproduct(i)" class="fa fa-trash" style="font-size: 22px; cursor: pointer; color: red;"></i>
    </td>
  </tr>
</tbody>
                        <tfoot>
                          <tr>
                            <td colspan="6">
                              <button
                                type="button"
                                class="btn m-2"
                                style="background-color: #141414; color: #fff"
                                (click)="addnewproduct()"
                              >
                                <i
                                  class="fa fa-plus-circle mx-1"
                                  style="
                                    font-size: 14px;
                                    cursor: pointer;
                                    color: #fff;
                                  "
                                ></i>
                                {{
                                  curentLang === "en"
                                    ? "Select Product"
                                    : curentLang === "ar"
                                    ? "اختيار منتج"
                                    : "בחר מוצר  "
                                }}
                              </button>

                              <button
                                [hidden]="user_type == 200"
                                type="button"
                                class="btn m-2"
                                style="background-color: #141414; color: #fff"
                                (click)="openXl2(content2)"
                              >
                                <i
                                  class="fa fa-plus-circle mx-1"
                                  style="
                                    font-size: 14px;
                                    cursor: pointer;
                                    color: #fff;
                                  "
                                ></i>
                                {{
                                  curentLang === "en"
                                    ? "Add Product"
                                    : curentLang === "ar"
                                    ? "اضافة منتج"
                                    : "הוספת פריט   "
                                }}
                              </button>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>

                    <!-- Mobile layout for smaller screens -->

                    <div class="d-lg-none" formArrayName="lines">
                      <div
                        *ngFor="let item of invproducts.controls; let i = index"
                        [formGroupName]="i"
                        class="product-card mx-2"
                        (click)="openChooseProductModal2(chooseProductModal2, i)"
                      >
                        <div class="product-header d-flex align-items-center">
                          <button
                            (click)="Removeproduct(i)"
                            class="btn btn-transparent"
                            style="margin-right: auto"
                          >
                            <i
                              class="fa fa-trash trash-icon"
                              style="
                                font-size: 20px;
                                color: red;
                                background-color: #fff;
                                border-radius: 20%;
                                padding: 5px;
                                cursor: pointer;
                              "
                            >
                            </i>
                          </button>
                          <!-- <h4 (click)="toggleCollapse(i)" class="collapse-toggle">
                          {{ curentLang === 'en' ? 'Product Details' : (curentLang === 'ar' ? 'تفاصيل المنتج' : 'פרטי מוצר') }}
                          <i [class]="collapsedIndexes[i] ? 'fa fa-chevron-down' : 'fa fa-chevron-up'" class="px-2"></i>
                      </h4> -->
                        </div>
                        <!-- <h6 style="color: #141414;">
                        {{ selectedProductNames[i] || 'No product selected' }}
                    </h6> -->
                        <div class="form-group">
                          <!-- <label>{{
                            curentLang === "en"
                              ? "Product Name"
                              : curentLang === "ar"
                              ? "المنتج"
                              : "מוצר"
                          }}</label> -->
                          <span class="select-product-input" (click)="openChooseProductModal2(chooseProductModal2, i)">
                            {{
                              selectedProductNames?.[i] ??
                                (currentLang === 'en'
                                  ? 'Select Product'
                                  : currentLang === 'ar'
                                  ? 'اختر المنتج'
                                  : 'בחר מוצר')
                            }}
                          </span>

                          <!-- <ng-select
                            formControlName="product_id"
                            (change)="productchange(i)"
                          >
                            <ng-option
                              *ngFor="let item of masterproduct; let i = index"
                              [value]="item.product_id"
                            >
                              {{
                                curentLang === "en"
                                  ? item.product_name_en
                                  : curentLang === "ar"
                                  ? item.product_name_ar
                                  : item.product_name_he
                              }}
                            </ng-option>
                          </ng-select> -->
                        </div>
                        <!-- <h6 style="color: #141414;">
                        {{ selectedProductPrice[i] }}
                    </h6> -->

                        <div class="product-grid">
                          <!-- Product Name -->
                          <!-- <div class="form-group">
                            <label>{{ curentLang === 'en' ? 'Product Name' : (curentLang === 'ar' ? 'اسم المنتج' : 'שם מוצר') }}</label>
                            <ng-select formControlName="product_id" (change)="productchange(i)">
                                <ng-option *ngFor="let item of masterproduct; let i = index" [value]="item.product_id">
                                    {{ curentLang === 'en' ? item.product_name_en : (curentLang === 'ar' ? item.product_name_ar : item.product_name_he) }}
                                </ng-option>
                            </ng-select>
                        </div> -->

                          <!-- Product Description -->
                          <!-- <div class="form-group">
                            <label>{{
                              curentLang === "en"
                                ? "Product Desc"
                                : curentLang === "ar"
                                ? "وصف المنتج"
                                : "תיאור מוצר"
                            }}</label>
                            <textarea
                              maxlength="250"
                              class="form-control"
                              formControlName="description"
                            ></textarea>
                          </div> -->

                          <!-- Price Per Unit -->
                          <div class="form-container">
                            <!-- Including VAT (Now Properly Aligned)
                            <div class="vat-group">
                              <label style="white-space: nowrap; font-size: 14px">
                                <input type="checkbox" disabled checked />
                                {{
                                  curentLang === "en"
                                    ? "Including VAT"
                                    : curentLang === "ar"
                                    ? "شامل ض.م"
                                    : curentLang === "he"
                                    ? 'כולל מע"מ'
                                    : "Including VAT"
                                }}
                              </label>
                            </div> -->
                            <!-- Price per unit -->
                            <div class="form-group2">
                              <label class="custom-label">
                                {{
                                  curentLang === "en"
                                    ? "Price per unit:"
                                    : curentLang === "ar"
                                    ? "سعر الوحدة:"
                                    : "מחיר יחידה:"
                                }}
                                <span style="color: red;" *ngIf="invoiceReceiptForm.get('lines')?.at(i)?.get('isVatIncluded')?.value">
                                  {{
                                    curentLang === "en"
                                      ? "(with VAT)"
                                      : curentLang === "ar"
                                      ? "(شامل)"
                                      : "(כולל)"
                                  }}
                                </span>
                              </label>
                              <span class="form-control-plaintext">
                                {{
                                  invoiceReceiptForm
                                    .get("lines")
                                    .controls[i].get("price_per_unit").value
                                    | number : "1.2-2"
                                }}
                              </span>
                            </div>
                            <div class="form-group2">
                              <label class="custom-label">
                                {{
                                  curentLang === "en"
                                    ? "Quantity:"
                                    : curentLang === "ar"
                                    ? "الكمية:"
                                    : "כמות:"
                                }}
                              </label>
                              <span class="form-control-plaintext">
                                {{
                                  invoiceReceiptForm
                                    .get("lines")
                                    .controls[i].get("quantity").value
                                    | number : "1.2-2"
                                }}
                              </span>
                            </div>
                            <div class="form-group2">
                              <label class="custom-label">
                                {{
                                  curentLang === "en"
                                    ? "Discount:"
                                    : curentLang === "ar"
                                    ? "خصم:"
                                    : "הנחה:"
                                }}
                              </label>
                              <span class="form-control-plaintext">
                                {{
                                  invoiceReceiptForm
                                    .get("lines")
                                    .controls[i].get("discount").value
                                    | number : "1.2-2"
                                }}
                              </span>
                            </div>

                          </div>

                          <!-- Quantity
                          <div class="form-group2">
                            <label class="custom-label">{{
                              curentLang === "en"
                                ? "Quantity"
                                : curentLang === "ar"
                                ? "الكمية"
                                : "כמות"
                            }}</label>
                            <input
                              class="form-control"
                              type="number"
                              min="0"
                              (change)="Itemcalculation(i)"
                              formControlName="quantity"
                            />
                          </div> -->

                          <!-- before vaaaaaaat -->
                          <!-- <div class="form-group2">
  <label class="custom-label">
    {{
      curentLang === "en"
        ? "Amount Before Discount"
        : curentLang === "ar"
        ? "المبلغ قبل الخصم"
        : "מחיר לפני הנחה"
    }}
  </label>
  <span class="form-control-plaintext">
    {{ invoiceReceiptForm.get('lines').controls[i].get('amount_before_discount').value }}
  </span>
</div> -->
                          <!-- after vaaaaaat -->

                          <!-- VAT -->
                          <!-- <div class="form-group2">
                            <label class="custom-label">
                              {{
                                curentLang === "en"
                                  ? "VAT Amount"
                                  : curentLang === "ar"
                                  ? "قيمة الضريبة"
                                  : 'מע"מ'
                              }}
                            </label>
                            <input
                              class="form-control"
                              formControlName="vat_amount"
                              readonly
                            />
                          </div> -->
                          <div class="form-container2">
                            <div class="form-group2">
                              <label class="custom-label">
                                {{
                                  curentLang === "en"
                                    ? "Amount Before VAT:"
                                    : curentLang === "ar"
                                    ? "المبلغ قبل الضريبة:"
                                    : 'סכום לפני מע"מ:'
                                }}
                              </label>
                              <span class="form-control-plaintext">
                                {{
                                  invoiceReceiptForm
                                    .get("lines")
                                    .controls[i].get("sub_total").value
                                    | number : "1.2-2"
                                }}
                              </span>
                            </div>
                            <div class="form-group2">
                              <label class="custom-label">
                                {{
                                  curentLang === "en"
                                    ? "VAT Amount:"
                                    : curentLang === "ar"
                                    ? "قيمة الضريبة:"
                                    : 'מע"מ:'
                                }}
                              </label>
                              <span class="form-control-plaintext">
                                {{
                                  invoiceReceiptForm
                                    .get("lines")
                                    .controls[i].get("vat_amount").value
                                    | number : "1.2-2"
                                }}
                              </span>
                            </div>
                            <!-- Total Amount -->
                          </div>
                          <div class="form-group2">
                            <label class="custom-label">
                              {{
                                curentLang === "en"
                                  ? "Total Amount After Tax:"
                                  : curentLang === "ar"
                                  ? "المبلغ بعد الضريبة:"
                                  : 'סה"כ כולל מע"מ'
                              }}
                            </label>
                            <span class="form-control-plaintext">
                              {{
                                invoiceReceiptForm
                                  .get("lines")
                                  .controls[i].get("total_amount").value
                                  | number : "1.0-0"
                              }}
                            </span>
                          </div>
                          <div
                            class="form-container2"
                            *ngIf="
                              invoiceReceiptForm
                                .get('lines')
                                .controls[i].get('description').value
                            "
                          >
                            <div class="form-group2">
                              <label class="custom-label">
                                {{
                                  curentLang === "en"
                                    ? "Product Desc:"
                                    : curentLang === "ar"
                                    ? "وصف المنتج:"
                                    : "תיאור מוצר:"
                                }}
                              </label>
                              <span
                              class="form-control-plaintext"
                              style="display: block; text-align: right; word-wrap: break-word;"
                            >
                              {{
                                invoiceReceiptForm
                                  .get("lines")
                                  .controls[i].get("description").value
                              }}
                            </span>
                            </div>
                          </div>

                          <!-- <div class="form-group">
                            <label>{{
                              curentLang === "en"
                                ? "Total Amount"
                                : curentLang === "ar"
                                ? "المجموع"
                                : 'סה"כ'
                            }}</label>
                            <input
                              class="form-control"
                              type="number"
                              min="0"
                              formControlName="total_amount"
                              (change)="onTotalAmountChange(i)"
                              readonly
                              disabled
                            />
                          </div> -->
                        </div>
                      </div>

                      <!-- Add button for small screens -->
                      <ng-template #chooseProductModal2 let-modal>
                        <div class="modal-header row d-flex justify-content-between">
                          <div class="col-md-12">
                            <div class="modal-title row d-flex justify-content-between align-items-start px-3" style="color: black">
                              <h6 class="mb-0 w-50">
                                {{
                                  curentLang === "en"
                                    ? "Choose Product "
                                    : curentLang === "ar"
                                    ? " اختر المنتج "
                                    : "בחר את המוצר"
                                }}
                              </h6>
                              <button type="button" class="btn-close align-self-start" aria-label="Close" (click)="closeAllModals()"></button>
                            </div>
                            <div class="modal-body xl:flex xl:justify-content-center">
                              <div class="d-flex justify-content-between align-items-center mb-1 p-2" style="background-color: #fcfcfc">
                                <button [hidden]="user_type == 200 || user_type == 201" class="btn text-center" style="background-color: #141414; color: #fff" (click)="closeAllModals()">
                                  <i class="fa fa-save mx-1" style="font-size: 14px; cursor: pointer; color: #fff"></i>
                                  {{
                                    curentLang === "en"
                                      ? "Save and Back"
                                      : curentLang === "ar"
                                      ? "حفظ وعودة"
                                      : " שמור"
                                  }}
                                </button>
                              </div>
                              <ng-container *ngFor="let item of invproducts.controls; let i = index">
                                <div [formGroupName]="i" class="product-card mx-2" *ngIf="i === selectedProductIndex">
                                  <div class="product-header">
                                    <!-- Product header content here -->
                                  </div>
                                  <div class="form-group">
                                    <label>{{
                                      curentLang === "en"
                                        ? "Product Name"
                                        : curentLang === "ar"
                                        ? "المنتج"
                                        : "מוצר"
                                    }}</label>
                                    <span class="select-customer-label" >
                                      {{
                                        selectedProductNames?.[i] ??
                                          (currentLang === 'en'
                                            ? 'Select Product'
                                            : currentLang === 'ar'
                                            ? 'اختر المنتج'
                                            : 'בחר מוצר')
                                      }}
                                    </span>
                                  </div>
                                  <div class="product-grid">
                                    <div class="form-group">
                                      <label>{{
                                        curentLang === "en"
                                          ? "Product Desc"
                                          : curentLang === "ar"
                                          ? "وصف المنتج"
                                          : "תיאור מוצר"
                                      }}</label>
                                      <textarea maxlength="250" class="form-control" formControlName="description"></textarea>
                                    </div>
                                    <div class="form-group">
                                      <label>{{
                                        curentLang === "en"
                                          ? "Price per unit"
                                          : curentLang === "ar"
                                          ? "سعر الوحدة"
                                          : "מחיר יחידה"
                                      }}</label>
                                      <!-- <div style="display: flex; align-items: center; gap: 10px;">
                                        <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="price_per_unit" style="flex: 9" />
                                        <label style="flex: 1; white-space: pre-line; font-size: 14px;">
                                          <input
                                            type="checkbox"
                                            (change)="onVatCheckboxChange(i, $event)"
                                            [checked]="invoiceReceiptForm.get('lines')?.at(i)?.get('isVatIncluded')?.value"
                                          />
                                          {{
                                            curentLang === "en"
                                              ? "Including\nVAT"
                                              : curentLang === "ar"
                                              ? "يشمل\nقيمه\nالضريبه"
                                              : 'כולל\nמע"מ'
                                          }}
                                        </label>
                                      </div> -->
                                    </div>
                                    <div class="form-group">
                                      <label>{{
                                        curentLang === "en"
                                          ? "Quantity"
                                          : curentLang === "ar"
                                          ? "الكمية"
                                          : "כמות"
                                      }}</label>
                                      <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="quantity" />
                                    </div>
                                    <div class="form-group">
                                      <label>{{
                                        curentLang === "en"
                                          ? "Discount"
                                          : curentLang === "ar"
                                          ? "خصم"
                                          : "הנחה"
                                      }}</label>
                                      <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="discount" />
                                    </div>
                                    <div class="form-group">
                                      <label>{{
                                        curentLang === "en"
                                          ? "Total Amount"
                                          : curentLang === "ar"
                                          ? "المجموع"
                                          : 'סה"כ'
                                      }}</label>
                                      <input class="form-control" type="number" min="0" formControlName="total_amount" (change)="onTotalAmountChange(i)" />
                                    </div>
                                    <div class="form-group">
                                      <label>{{
                                        curentLang === "en"
                                          ? "VAT Amount"
                                          : curentLang === "ar"
                                          ? "قيمة الضريبة"
                                          : 'מע"מ'
                                      }}</label>
                                      <input class="form-control" formControlName="vat_amount" [disabled]="true" />
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                            <hr style="color: gray" class="py-2" />
                          </div>
                        </div>
                      </ng-template>
                      
                      <div class="text-center mt-1">
                        <ng-template #chooseProductModal let-modal>
                          <div
                            class="modal-header row d-flex justify-content-between"
                          >
                            <div class="col-md-12">
                              <div
                                class="modal-title row d-flex justify-content-between align-items-start px-3"
                                style="color: black"
                              >
                                <h6 class="mb-0 w-50">
                                  {{
                                    curentLang === "en"
                                      ? " "
                                      : curentLang === "ar"
                                      ? " اختر المنتج "
                                      : "בחר את המוצר"
                                  }}
                                </h6>
                                <button
                                  type="button"
                                  class="btn-close align-self-start"
                                  aria-label="Close"
                                  (click)="
                                    Removeproduct(
                                      invproducts.controls.length - 1
                                    );
                                    closeAllModals()
                                  "
                                ></button>
                              </div>
                              <div
                                class="modal-body xl:flex xl:justify-content-center"
                              >
                                <div
                                  class="d-flex justify-content-between align-items-center mb-1 p-2"
                                  style="background-color: #fcfcfc"
                                >
                                  <button
                                    [hidden]="
                                      user_type == 200 || user_type == 201
                                    "
                                    class="btn text-center"
                                    style="
                                      background-color: #141414;
                                      color: #fff;
                                    "
                                    (click)="closeAllModals()"
                                  >
                                    <i
                                      class="fa fa-save mx-1"
                                      style="
                                        font-size: 14px;
                                        cursor: pointer;
                                        color: #fff;
                                      "
                                    ></i>
                                    {{
                                      curentLang === "en"
                                        ? "Save and Back"
                                        : curentLang === "ar"
                                        ? "حفظ وعودة"
                                        : " שמור"
                                    }}
                                  </button>
                                </div>
                                <ng-container
                                  *ngFor="
                                    let item of invproducts.controls;
                                    let i = index
                                  "
                                >
                                  <div
                                    [formGroupName]="i"
                                    class="product-card mx-2"
                                    *ngIf="
                                      i === invproducts.controls.length - 1
                                    "
                                  >
                                    <div class="product-header">
                                      <!-- Product header content here -->
                                    </div>
                                    <div class="form-group">
                                      <label>{{
                                        curentLang === "en"
                                          ? "Product Name"
                                          : curentLang === "ar"
                                          ? "المنتج"
                                          : "מוצר"
                                      }}</label>
                                      <span
                                        class="select-customer-label"
                                        (click)="
                                          Removeproduct(
                                            invproducts.controls.length - 1
                                          )
                                        "
                                      >
                                        {{
                                          selectedProductNames[i] ||
                                            (curentLang === "en"
                                              ? "Select Product"
                                              : curentLang === "ar"
                                              ? "اختر المنتج"
                                              : "בחר מוצר")
                                        }}
                                      </span>
                                    </div>
                                    <div class="product-grid">
                                      <div class="form-group">
                                        <label>{{
                                          curentLang === "en"
                                            ? "Product Desc"
                                            : curentLang === "ar"
                                            ? "وصف المنتج"
                                            : "תיאור מוצר"
                                        }}</label>
                                        <textarea
                                          maxlength="250"
                                          class="form-control"
                                          formControlName="description"
                                        ></textarea>
                                      </div>
                                      <div class="form-group">
                                        <label>{{
                                          curentLang === "en"
                                            ? "Price per unit"
                                            : curentLang === "ar"
                                            ? "سعر الوحدة"
                                            : "מחיר יחידה"
                                        }}</label>
                                        <div
                                          style="
                                            display: flex;
                                            align-items: center;
                                            gap: 10px;
                                          "
                                        >
                                        <input
  class="form-control"
  type="number"
  min="0"
  (change)="Itemcalculation(i)"
  formControlName="price_per_unit"
  style="flex: 9"
/>
<label
  style="
    flex: 1;
    white-space: pre-line;
    font-size: 14px;
  "
>
  <input type="checkbox" (change)="onVatCheckboxChange(i, $event)" />
  {{
    curentLang === "en"
      ? "Including\nVAT"
      : curentLang === "ar"
      ? "يشمل\nقيمه\nالضريبه"
      : 'כולל\nמע"מ'
  }}
</label>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <label>{{
                                          curentLang === "en"
                                            ? "Quantity"
                                            : curentLang === "ar"
                                            ? "الكمية"
                                            : "כמות"
                                        }}</label>
                                        <input
                                          class="form-control"
                                          type="number"
                                          min="0"
                                          (change)="Itemcalculation(i)"
                                          formControlName="quantity"
                                        />
                                      </div>
                                      <div class="form-group">
                                        <label>{{
                                          curentLang === "en"
                                            ? "Discount"
                                            : curentLang === "ar"
                                            ? "خصم"
                                            : "הנחה"
                                        }}</label>
                                        <input
                                          class="form-control"
                                          type="number"
                                          min="0"
                                          (change)="Itemcalculation(i)"
                                          formControlName="discount"
                                        />
                                      </div>
                                      <div class="form-group">
                                        <label>{{
                                          curentLang === "en"
                                            ? "Total Amount"
                                            : curentLang === "ar"
                                            ? "المجموع"
                                            : 'סה"כ'
                                        }}</label>
                                        <input
                                          class="form-control"
                                          type="number"
                                          min="0"
                                          formControlName="total_amount"
                                          (change)="onTotalAmountChange(i)"
                                        />
                                      </div>
                                      <div class="form-group">
                                        <label>{{
                                          curentLang === "en"
                                            ? "VAT Amount"
                                            : curentLang === "ar"
                                            ? "قيمة الضريبة"
                                            : 'מע"מ'
                                        }}</label>
                                        <input
                                          class="form-control"
                                          formControlName="vat_amount"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                              <hr style="color: gray" class="py-2" />
                            </div>
                          </div>
                        </ng-template>

                        <style>
                          .responsive-button {
                            background-color: #141414;
                            color: #fff;
                            width: 450px;
                          }

                          @media (max-width: 768px) {
                            .responsive-button {
                              width: 90%;
                            }
                          }
                        </style>

                        <button
                          type="button"
                          class="btn my-2 mx-1 responsive-button"
                          (click)="
                            addProductWithValidation(chooseProductModalSelected)
                          "
                        >
                          <i
                            class="fa fa-plus-circle mx-1"
                            style="
                              font-size: 14px;
                              cursor: pointer;
                              color: #fff;
                            "
                          ></i>
                          {{
                            curentLang === "en"
                              ? "Add Product"
                              : curentLang === "ar"
                              ? "اضافة منتج"
                              : "הוספת פריט   "
                          }}
                        </button>

                        <ng-template #chooseProductModalSelected let-modal>
                          <div
                            class="modal-header row d-flex justify-content-between"
                          >
                            <div class="col-md-12">
                              <div
                                class="modal-title row d-flex justify-content-between align-items-start px-3"
                                style="color: black"
                              >
                                <h6 class="mb-0 w-50">
                                  {{
                                    curentLang === "en"
                                      ? "Choose Product "
                                      : curentLang === "ar"
                                      ? " اختر المنتج "
                                      : "בחר את המוצר"
                                  }}
                                </h6>
                                <button
                                  type="button"
                                  class="btn-close align-self-start"
                                  aria-label="Close"
                                  (click)="closeModal(modal)"
                                ></button>
                              </div>
                              <div
                                class="modal-body xl:flex xl:justify-content-center"
                              >
                                <div
                                  class="d-flex justify-content-between align-items-center mb-1 p-2"
                                  style="background-color: #fcfcfc"
                                >
                                  <button
                                    [hidden]="
                                      user_type == 200 || user_type == 201
                                    "
                                    class="btn text-center"
                                    style="
                                      background-color: #141414;
                                      color: #fff;
                                    "
                                    (click)="openXl2(content2)"
                                  >
                                    <i
                                      class="fa fa-plus-circle mx-1"
                                      style="
                                        font-size: 14px;
                                        cursor: pointer;
                                        color: #fff;
                                      "
                                    ></i>
                                  </button>
                                </div>
                                <p-orderList
                                  [value]="masterproduct"
                                  [filterBy]="
                                    'product_name_en,product_name_ar,product_name_he,product_code'
                                  "
                                  [showButtons]="true"
                                  [responsive]="true"
                                  [sourceStyle]="{ height: '30rem' }"
                                  [targetStyle]="{ height: '30rem' }"
                                  filterPlaceholder="{{
                                    curentLang === 'en'
                                      ? 'Filter by name or product code'
                                      : curentLang === 'ar'
                                      ? 'ابحث بالاسم أو كود المنتج'
                                      : 'חפש לפי שם או קוד מוצר'
                                  }}"
                                  [listStyle]="{ height: '25rem' }"
                                  header=""
                                  (onSelectionChange)="
                                    onProductSelect($event.value, i)
                                  "
                                >
                                  <ng-template let-product pTemplate="item">
                                    <div
                                      class="flex flex-wrap align-items-center product-item"
                                    >
                                      <div class="flex-1 flex flex-column">
                                        <span style="font-size: 14px">
                                          <i
                                            class="fa fa-cart-arrow-down text-sm mx-1"
                                          ></i>
                                          {{
                                            curentLang === "en"
                                              ? product.product_name_en
                                              : curentLang === "ar"
                                              ? product.product_name_ar
                                              : product.product_name_he
                                          }}
                                          <br class="m-0 p-0" />
                                          <i
                                            class="fa fa-dollar text-sm mx-1"
                                          ></i>
                                          {{ product.price }}
                                          <hr
                                            style="color: #141414"
                                            class="m-0 p-0"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </ng-template>
                                </p-orderList>
                              </div>
                            </div>
                            <hr style="color: gray" class="py-2" />
                          </div>
                        </ng-template>
                      </div>
                    </div>
                    <!-- end Mobile layout for smaller screens -->

                    <div class="row d-flex justify-content-between">
                      <div class="col-md-6 col-sm-12" style="color: black">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="d-flex flex-column">
                              <div
                                class="d-flex justify-content-between px-4 my-2"
                              >
                                <div style="width: 200px">
                                  {{
                                    curentLang === "en"
                                      ? "Amount Before Discount"
                                      : curentLang === "ar"
                                      ? "   المبلغ قبل الخصم"
                                      : "מחיר לפני הנחה"
                                  }}
                                </div>
                                <input
                                  formControlName="amount_before_discount"
                                  class="form-control"
                                  [value]="
                                    invoiceReceiptForm.get('amount_before_discount')
                                      .value | numberFormat
                                  "
                                />
                              </div>

                              <div
                                class="d-flex justify-content-between px-4 my-2"
                              >
                                <div style="width: 200px">
                                  {{
                                    curentLang === "en"
                                      ? "Discount"
                                      : curentLang === "ar"
                                      ? "الخصم"
                                      : "הנחה"
                                  }}
                                </div>
                                <input
                                  formControlName="all_discount"
                                  class="form-control"
                                  [value]="
                                    invoiceReceiptForm.get('all_discount').value
                                      | numberFormat
                                  "
                                />
                              </div>

                              <div
                                class="d-flex justify-content-between px-4 my-2"
                              >
                                <div style="width: 200px">
                                  {{
                                    curentLang === "en"
                                      ? "Payment Amount"
                                      : curentLang === "ar"
                                      ? " قيمة الدفع  "
                                      : 'סה"כ  '
                                  }}
                                </div>
                                <input
                                  formControlName="payment_amount"
                                  class="form-control"
                                  [value]="
                                    invoiceReceiptForm.get('payment_amount').value
                                      | numberFormat
                                  "
                                />
                              </div>

                              <div
                                class="d-flex justify-content-between px-4 my-2"
                              >
                                <div style="width: 200px">
                                  {{
                                    curentLang === "en"
                                      ? "VAT Amount"
                                      : curentLang === "ar"
                                      ? "   قيمة الضريبة"
                                      : 'מע"מ     '
                                  }}
                                  ( {{ VatPercentage }} %)
                                </div>
                                <input
                                  formControlName="all_vat_amount"
                                  class="form-control"
                                  [value]="
                                  invoiceReceiptForm.get('all_vat_amount').value
                                      | numberFormat
                                  "
                                />
                              </div>

                              <div
                                class="d-flex justify-content-between px-4 my-2"
                              >
                                <div style="width: 200px">
                                  {{
                                    curentLang === "en"
                                      ? "Payment Amount Including VAT"
                                      : curentLang === "ar"
                                      ? "قيمة المبلغ شاملة الضريبة"
                                      : '    סה"כ לשתלום'
                                  }}
                                </div>
                                <input
                                formControlName="payment_amount_including_vat"
                                class="form-control"
                                [value]="invoiceReceiptForm.get('payment_amount_including_vat').value | number: '1.2-2'"
                              />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12" style="color: black">
                        <div class="mt-8 px-4">
                          <div>
                            <label for="notes">
                              {{
                                curentLang === "en"
                                  ? "Note"
                                  : curentLang === "ar"
                                  ? "ملاحظة"
                                  : "הערה"
                              }}</label
                            >
                            <textarea
                              formControlName="invoice_note"
                              id="notes"
                              name="notes"
                              class="form-textarea form-control my-2"
                              placeholder="{{
                                curentLang === 'en'
                                  ? 'Note'
                                  : curentLang === 'ar'
                                  ? 'ملاحظة'
                                  : 'הערה'
                              }}"
                              name="notes"
                            ></textarea>
                          </div>
                          <div
                            class="w-100 mt-8 px-4 d-flex justify-content-between"
                          >
                            <div>
                              <label>{{
                                curentLang === "en"
                                  ? "Assignment number"
                                  : curentLang === "ar"
                                  ? "رقم تخصيص"
                                  : "מספר הקצאה  "
                              }}</label>
                              <input
                                type="radio"
                                formControlName="allocation_number"
                                [value]="1"
                              />
                            </div>
                            <div>
                              <label>{{
                                curentLang === "en"
                                  ? "Without assignment number"
                                  : curentLang === "ar"
                                  ? "بدون رقم تخصيص"
                                  : "ללא מספר הקצאה    "
                              }}</label>
                              <input
                                type="radio"
                                formControlName="allocation_number"
                                [value]="0"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />

                <!-- Start Payment Method -->
                <!-- Check modal -->
                <ng-template #checkModal let-modal>
                  <div class="modal-header">
                    <h6 class="modal-title">
                      {{
                        curentLang === "en"
                          ? "Check Details"
                          : curentLang === "ar"
                          ? "تفاصيل الشيك"
                          : "פרטי שיק"
                      }}
                    </h6>
                    <button
                      type="button"
                      class="btn-close"
                      (click)="modal.dismiss()"
                    ></button>
                  </div>

                  <div class="modal-body" formArrayName="linesChecks">
                    <!-- Desktop Table -->
                    <div class="table-responsive d-none d-lg-block">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Date"
                                  : curentLang === "ar"
                                  ? "التاريخ"
                                  : "תאריך"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Reference"
                                  : curentLang === "ar"
                                  ? "رقم الشيك"
                                  : "מס' שיק"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Bank"
                                  : curentLang === "ar"
                                  ? "البنك"
                                  : "בנק"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Branch"
                                  : curentLang === "ar"
                                  ? "الفرع"
                                  : "סניף"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Account"
                                  : curentLang === "ar"
                                  ? "الحساب"
                                  : "חשבון"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Amount"
                                  : curentLang === "ar"
                                  ? "المبلغ"
                                  : "סכום"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Remove"
                                  : curentLang === "ar"
                                  ? "حذف"
                                  : "מחק"
                              }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of linesChecks.controls;
                              let i = index
                            "
                            [formGroupName]="i"
                          >
                            <td>
                              <input
                                type="date"
                                class="form-control"
                                formControlName="date"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control"
                                formControlName="reference"
                              />
                            </td>
                            <td>
                              <ng-select
                                formControlName="bank"
                                (change)="getBranches($event, i)"
                              >
                                <ng-option
                                  *ngFor="let item of masterBank"
                                  [value]="item.bank_code"
                                >
                                  {{ getBankLabel(item) }}
                                </ng-option>
                              </ng-select>
                            </td>
                            <td>
                              <ng-select formControlName="branch">
                                <ng-option
                                  *ngFor="let item of getBranchesForIndex(i)"
                                  [value]="item.bank_branch_code"
                                >
                                  {{ getBranchLabel(item) }}
                                </ng-option>
                              </ng-select>
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control"
                                formControlName="account"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control"
                                formControlName="amount"
                              />
                            </td>
                            <td>
                              <i
                                class="fa fa-trash"
                                style="color: red; cursor: pointer"
                                (click)="removeLineFromChecks(i)"
                              ></i>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="6">
                              <button
                                class="btn btn-dark"
                                (click)="addNewLineToChecks()"
                              >
                                <i class="fa fa-plus-circle me-1"></i>
                                {{
                                  curentLang === "en"
                                    ? "Add Check"
                                    : curentLang === "ar"
                                    ? "أضف شيك"
                                    : "הוסף שיק"
                                }}
                              </button>
                            </td>
                            <td>
                              <input
                                type="number"
                                readonly
                                [value]="getTotalAmountChecksArray()"
                                class="form-control"
                              />
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>

                    <!-- Mobile Layout -->
                    <div class="d-lg-none">
                      <div
                        *ngFor="let item of linesChecks.controls; let i = index"
                        class="border p-3 my-2"
                        [formGroupName]="i"
                      >
                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Date"
                              : curentLang === "ar"
                              ? "التاريخ"
                              : "תאריך"
                          }}</label>
                          <input
                            class="form-control"
                            formControlName="date"
                            type="date"
                          />
                          <small
                            *ngIf="
                              item.get('date')?.invalid &&
                              item.get('date')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Date is required"
                                : curentLang === "ar"
                                ? "التاريخ مطلوب"
                                : "תאריך נדרש"
                            }}
                          </small>
                        </div>

                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Reference"
                              : curentLang === "ar"
                              ? "رقم الشيك"
                              : "מס' שיק"
                          }}</label>
                          <input
                            class="form-control"
                            formControlName="reference"
                            type="number"
                          />
                          <small
                            *ngIf="
                              item.get('reference')?.invalid &&
                              item.get('reference')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Reference is required"
                                : curentLang === "ar"
                                ? "رقم الشيك مطلوب"
                                : "מספר שיק נדרש"
                            }}
                          </small>
                        </div>

                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Bank"
                              : curentLang === "ar"
                              ? "البنك"
                              : "בנק"
                          }}</label>
                          <ng-select
                            formControlName="bank"
                            (change)="getBranches($event, i)"
                          >
                            <ng-option
                              *ngFor="let item of masterBank"
                              [value]="item.bank_code"
                            >
                              {{ getBankLabel(item) }}
                            </ng-option>
                          </ng-select>
                          <small
                            *ngIf="
                              item.get('bank')?.invalid &&
                              item.get('bank')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Bank is required"
                                : curentLang === "ar"
                                ? "البنك مطلوب"
                                : "נדרש לבחור בנק"
                            }}
                          </small>
                        </div>

                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Branch"
                              : curentLang === "ar"
                              ? "الفرع"
                              : "סניף"
                          }}</label>
                          <ng-select formControlName="branch">
                            <ng-option
                              *ngFor="let item of getBranchesForIndex(i)"
                              [value]="item.bank_branch_code"
                            >
                              {{ getBranchLabel(item) }}
                            </ng-option>
                          </ng-select>
                          <small
                            *ngIf="
                              item.get('branch')?.invalid &&
                              item.get('branch')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Branch is required"
                                : curentLang === "ar"
                                ? "الفرع مطلوب"
                                : "נדרש לבחור סניף"
                            }}
                          </small>
                        </div>

                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Account"
                              : curentLang === "ar"
                              ? "الحساب"
                              : "חשבון"
                          }}</label>
                          <input
                            class="form-control"
                            formControlName="account"
                            type="number"
                          />
                          <small
                            *ngIf="
                              item.get('account')?.invalid &&
                              item.get('account')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Account is required"
                                : curentLang === "ar"
                                ? "رقم الحساب مطلوب"
                                : "מספר חשבון נדרש"
                            }}
                          </small>
                        </div>

                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Amount"
                              : curentLang === "ar"
                              ? "المبلغ"
                              : "סכום"
                          }}</label>
                          <input
                            class="form-control"
                            formControlName="amount"
                            type="number"
                          />
                          <small
                            *ngIf="
                              item.get('amount')?.invalid &&
                              item.get('amount')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Amount is required"
                                : curentLang === "ar"
                                ? "المبلغ مطلوب"
                                : "סכום נדרש"
                            }}
                          </small>
                        </div>

                        <div class="text-center">
                          <button
                            class="btn btn-sm btn-danger"
                            (click)="removeLineFromChecks(i)"
                          >
                            <i class="fa fa-trash me-1"></i>
                            {{
                              curentLang === "en"
                                ? "Delete"
                                : curentLang === "ar"
                                ? "حذف"
                                : "מחק"
                            }}
                          </button>
                        </div>
                      </div>

                      <div class="text-center my-2">
                        <input
                          type="number"
                          readonly
                          [value]="getTotalAmountChecksArray()"
                          class="form-control"
                        />
                      </div>

                      <div class="text-center">
                        <button
                          class="btn btn-dark"
                          (click)="addNewLineToChecks()"
                        >
                          <i class="fa fa-plus-circle me-1"></i>
                          {{
                            curentLang === "en"
                              ? "Add Check"
                              : curentLang === "ar"
                              ? "أضف شيك"
                              : "הוסף שיק"
                          }}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="modal-footer">
                    <button
                      class="btn btn-dark"
                      (click)="onConfirmCheckModal(modal)"
                    >
                      {{
                        curentLang === "en"
                          ? "Confirm"
                          : curentLang === "ar"
                          ? "تأكيد"
                          : "אישור"
                      }}
                    </button>
                  </div>
                </ng-template>
                <!-- Cash Modal -->
                <ng-template #cashModal let-modal>
                  <div class="modal-header">
                    <h6 class="modal-title">
                      {{
                        curentLang === "en"
                          ? "Cash Payment"
                          : curentLang === "ar"
                          ? "الدفع النقدي"
                          : "תשלום מזומן"
                      }}
                    </h6>
                    <button
                      type="button"
                      class="btn-close"
                      (click)="modal.dismiss()"
                    ></button>
                  </div>

                  <div class="modal-body" formArrayName="linesCash">
                    <!-- Desktop View -->
                    <div class="table-responsive d-none d-lg-block">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Date"
                                  : curentLang === "ar"
                                  ? "التاريخ"
                                  : "תאריך"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Amount"
                                  : curentLang === "ar"
                                  ? "المبلغ"
                                  : "סכום"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Remove"
                                  : curentLang === "ar"
                                  ? "حذف"
                                  : "מחק"
                              }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of linesCash.controls;
                              let i = index
                            "
                            [formGroupName]="i"
                          >
                            <td>
                              <input
                                type="date"
                                class="form-control"
                                formControlName="date"
                              />
                              <small
                                *ngIf="
                                  item.get('date')?.invalid &&
                                  item.get('date')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Date is required"
                                    : curentLang === "ar"
                                    ? "التاريخ مطلوب"
                                    : "תאריך נדרש"
                                }}
                              </small>
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control"
                                formControlName="amount"
                              />
                              <small
                                *ngIf="
                                  item.get('amount')?.invalid &&
                                  item.get('amount')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Amount is required"
                                    : curentLang === "ar"
                                    ? "المبلغ مطلوب"
                                    : "סכום נדרש"
                                }}
                              </small>
                            </td>
                            <td>
                              <i
                                class="fa fa-trash"
                                style="color: red; cursor: pointer"
                                (click)="removeLineFromCash(i)"
                              ></i>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="2">
                              <button
                                class="btn btn-dark"
                                (click)="addNewLineToCash()"
                              >
                                <i class="fa fa-plus-circle me-1"></i>
                                {{
                                  curentLang === "en"
                                    ? "Add Cash"
                                    : curentLang === "ar"
                                    ? "أضف نقدي"
                                    : "הוסף מזומן"
                                }}
                              </button>
                            </td>
                            <td>
                              <input
                                type="number"
                                readonly
                                [value]="getTotalAmountCashArray()"
                                class="form-control"
                              />
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>

                    <!-- Mobile View -->
                    <div class="d-lg-none">
                      <div
                        *ngFor="let item of linesCash.controls; let i = index"
                        class="border p-3 my-2"
                        [formGroupName]="i"
                      >
                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Date"
                              : curentLang === "ar"
                              ? "التاريخ"
                              : "תאריך"
                          }}</label>
                          <input
                            class="form-control"
                            formControlName="date"
                            type="date"
                          />
                          <small
                            *ngIf="
                              item.get('date')?.invalid &&
                              item.get('date')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Date is required"
                                : curentLang === "ar"
                                ? "التاريخ مطلوب"
                                : "תאריך נדרש"
                            }}
                          </small>
                        </div>

                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Amount"
                              : curentLang === "ar"
                              ? "المبلغ"
                              : "סכום"
                          }}</label>
                          <input
                            class="form-control"
                            formControlName="amount"
                            type="number"
                          />
                          <small
                            *ngIf="
                              item.get('amount')?.invalid &&
                              item.get('amount')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Amount is required"
                                : curentLang === "ar"
                                ? "المبلغ مطلوب"
                                : "סכום נדרש"
                            }}
                          </small>
                        </div>

                        <div class="text-center">
                          <button
                            class="btn btn-sm btn-danger"
                            (click)="removeLineFromCash(i)"
                          >
                            <i class="fa fa-trash me-1"></i>
                            {{
                              curentLang === "en"
                                ? "Delete"
                                : curentLang === "ar"
                                ? "حذف"
                                : "מחק"
                            }}
                          </button>
                        </div>
                      </div>

                      <div class="text-center my-2">
                        <input
                          type="number"
                          readonly
                          [value]="getTotalAmountCashArray()"
                          class="form-control"
                        />
                      </div>

                      <div class="text-center">
                        <button
                          class="btn btn-dark"
                          (click)="addNewLineToCash()"
                        >
                          <i class="fa fa-plus-circle me-1"></i>
                          {{
                            curentLang === "en"
                              ? "Add Cash"
                              : curentLang === "ar"
                              ? "أضف نقدي"
                              : "הוסף מזומן"
                          }}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="modal-footer">
                    <button
                      class="btn btn-dark"
                      (click)="onConfirmCashModal(modal)"
                    >
                      {{
                        curentLang === "en"
                          ? "Confirm"
                          : curentLang === "ar"
                          ? "تأكيد"
                          : "אישור"
                      }}
                    </button>
                  </div>
                </ng-template>
                <!-- Bank modal -->
                <ng-template #bankModal let-modal>
                  <div class="modal-header">
                    <h6 class="modal-title">
                      {{
                        curentLang === "en"
                          ? "Bank Transfer"
                          : curentLang === "ar"
                          ? "حوالة بنكية"
                          : "העברה בנקאית"
                      }}
                    </h6>
                    <button
                      type="button"
                      class="btn-close"
                      (click)="modal.dismiss()"
                    ></button>
                  </div>

                  <div class="modal-body" formArrayName="linesBankPayment">
                    <!-- Desktop View -->
                    <div class="table-responsive d-none d-lg-block">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Date"
                                  : curentLang === "ar"
                                  ? "التاريخ"
                                  : "תאריך"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Bank"
                                  : curentLang === "ar"
                                  ? "البنك"
                                  : "בנק"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Account"
                                  : curentLang === "ar"
                                  ? "الحساب"
                                  : "חשבון"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Amount"
                                  : curentLang === "ar"
                                  ? "المبلغ"
                                  : "סכום"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Remove"
                                  : curentLang === "ar"
                                  ? "حذف"
                                  : "מחק"
                              }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of linesBankPayment.controls;
                              let i = index
                            "
                            [formGroupName]="i"
                          >
                            <td>
                              <input
                                class="form-control"
                                type="date"
                                formControlName="date"
                              />
                              <small
                                *ngIf="
                                  item.get('date')?.invalid &&
                                  item.get('date')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Date is required"
                                    : curentLang === "ar"
                                    ? "التاريخ مطلوب"
                                    : "תאריך נדרש"
                                }}
                              </small>
                            </td>
                            <td>
                              <ng-select
                                formControlName="bank"
                                (change)="getBranches2($event, i)"
                              >
                                <ng-option
                                  *ngFor="let item of masterBank"
                                  [value]="item.bank_code"
                                >
                                  {{ getBankLabel(item) }}
                                </ng-option>
                              </ng-select>
                              <small
                                *ngIf="
                                  item.get('bank')?.invalid &&
                                  item.get('bank')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Bank is required"
                                    : curentLang === "ar"
                                    ? "البنك مطلوب"
                                    : "נדרש לבחור בנק"
                                }}
                              </small>
                            </td>
                            <td>
                              <input
                                class="form-control"
                                formControlName="account"
                                type="number"
                              />
                              <small
                                *ngIf="
                                  item.get('account')?.invalid &&
                                  item.get('account')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Account is required"
                                    : curentLang === "ar"
                                    ? "رقم الحساب مطلوب"
                                    : "מספר חשבון נדרש"
                                }}
                              </small>
                            </td>
                            <td>
                              <input
                                class="form-control"
                                formControlName="amount"
                                type="number"
                              />
                              <small
                                *ngIf="
                                  item.get('amount')?.invalid &&
                                  item.get('amount')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Amount is required"
                                    : curentLang === "ar"
                                    ? "المبلغ مطلوب"
                                    : "סכום נדרש"
                                }}
                              </small>
                            </td>
                            <td>
                              <i
                                class="fa fa-trash"
                                style="color: red; cursor: pointer"
                                (click)="removeLineFromBankPayment(i)"
                              ></i>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="4">
                              <button
                                class="btn btn-dark"
                                (click)="addNewLineToBankPayment()"
                              >
                                <i class="fa fa-plus-circle me-1"></i>
                                {{
                                  curentLang === "en"
                                    ? "Add Transfer"
                                    : curentLang === "ar"
                                    ? "أضف حوالة"
                                    : "הוסף העברה"
                                }}
                              </button>
                            </td>
                            <td>
                              <input
                                type="number"
                                readonly
                                [value]="getTotalAmountBankPaymentArray()"
                                class="form-control"
                              />
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>

                    <!-- Mobile View -->
                    <div class="d-lg-none">
                      <div
                        *ngFor="
                          let item of linesBankPayment.controls;
                          let i = index
                        "
                        class="border p-3 my-2"
                        [formGroupName]="i"
                      >
                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Date"
                              : curentLang === "ar"
                              ? "التاريخ"
                              : "תאריך"
                          }}</label>
                          <input
                            class="form-control"
                            type="date"
                            formControlName="date"
                          />
                          <small
                            *ngIf="
                              item.get('date')?.invalid &&
                              item.get('date')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Date is required"
                                : curentLang === "ar"
                                ? "التاريخ مطلوب"
                                : "תאריך נדרש"
                            }}
                          </small>
                        </div>

                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Bank"
                              : curentLang === "ar"
                              ? "البنك"
                              : "בנק"
                          }}</label>
                          <ng-select
                            formControlName="bank"
                            (change)="getBranches2($event, i)"
                          >
                            <ng-option
                              *ngFor="let item of masterBank"
                              [value]="item.bank_code"
                            >
                              {{ getBankLabel(item) }}
                            </ng-option>
                          </ng-select>
                          <small
                            *ngIf="
                              item.get('bank')?.invalid &&
                              item.get('bank')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Bank is required"
                                : curentLang === "ar"
                                ? "البنك مطلوب"
                                : "נדרש לבחור בנק"
                            }}
                          </small>
                        </div>

                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Account"
                              : curentLang === "ar"
                              ? "الحساب"
                              : "חשבון"
                          }}</label>
                          <input
                            class="form-control"
                            formControlName="account"
                            type="number"
                          />
                          <small
                            *ngIf="
                              item.get('account')?.invalid &&
                              item.get('account')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Account is required"
                                : curentLang === "ar"
                                ? "رقم الحساب مطلوب"
                                : "מספר חשבון נדרש"
                            }}
                          </small>
                        </div>

                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Amount"
                              : curentLang === "ar"
                              ? "المبلغ"
                              : "סכום"
                          }}</label>
                          <input
                            class="form-control"
                            formControlName="amount"
                            type="number"
                          />
                          <small
                            *ngIf="
                              item.get('amount')?.invalid &&
                              item.get('amount')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Amount is required"
                                : curentLang === "ar"
                                ? "المبلغ مطلوب"
                                : "סכום נדרש"
                            }}
                          </small>
                        </div>

                        <div class="text-center">
                          <button
                            class="btn btn-sm btn-danger"
                            (click)="removeLineFromBankPayment(i)"
                          >
                            <i class="fa fa-trash me-1"></i>
                            {{
                              curentLang === "en"
                                ? "Delete"
                                : curentLang === "ar"
                                ? "حذف"
                                : "מחק"
                            }}
                          </button>
                        </div>
                      </div>

                      <div class="text-center my-2">
                        <input
                          type="number"
                          readonly
                          [value]="getTotalAmountBankPaymentArray()"
                          class="form-control"
                        />
                      </div>

                      <div class="text-center">
                        <button
                          class="btn btn-dark"
                          (click)="addNewLineToBankPayment()"
                        >
                          <i class="fa fa-plus-circle me-1"></i>
                          {{
                            curentLang === "en"
                              ? "Add Transfer"
                              : curentLang === "ar"
                              ? "أضف حوالة"
                              : "הוסף העברה"
                          }}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="modal-footer">
                    <button
                      class="btn btn-dark"
                      (click)="onConfirmBankModal(modal)"
                    >
                      {{
                        curentLang === "en"
                          ? "Confirm"
                          : curentLang === "ar"
                          ? "تأكيد"
                          : "אישור"
                      }}
                    </button>
                  </div>
                </ng-template>
                <!-- Credit Card Modal -->
                <ng-template #creditModal let-modal>
                  <div class="modal-header">
                    <h6 class="modal-title">
                      {{
                        curentLang === "en"
                          ? "Credit Card Payment"
                          : curentLang === "ar"
                          ? "دفع بطاقة ائتمان"
                          : "תשלום בכרטיס אשראי"
                      }}
                    </h6>
                    <button
                      type="button"
                      class="btn-close"
                      (click)="modal.dismiss()"
                    ></button>
                  </div>

                  <div class="modal-body" formArrayName="linesCredit">
                    <!-- Desktop View -->
                    <div class="table-responsive d-none d-lg-block">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Date"
                                  : curentLang === "ar"
                                  ? "التاريخ"
                                  : "תאריך"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Payment Type"
                                  : curentLang === "ar"
                                  ? "نوع الدفع"
                                  : "סוג תשלום"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Credit Type"
                                  : curentLang === "ar"
                                  ? "نوع الأتمان"
                                  : "סוג אשראי"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Credit Code"
                                  : curentLang === "ar"
                                  ? "رمز البطاقة"
                                  : "קוד אשראי"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Account"
                                  : curentLang === "ar"
                                  ? "الحساب"
                                  : "חשבון"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Amount"
                                  : curentLang === "ar"
                                  ? "المبلغ"
                                  : "סכום"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Remove"
                                  : curentLang === "ar"
                                  ? "حذف"
                                  : "מחק"
                              }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of linesCredit.controls;
                              let i = index
                            "
                            [formGroupName]="i"
                          >
                            <td>
                              <input
                                type="date"
                                class="form-control"
                                formControlName="date"
                              />
                              <small
                                *ngIf="
                                  item.get('date')?.invalid &&
                                  item.get('date')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Date is required"
                                    : curentLang === "ar"
                                    ? "التاريخ مطلوب"
                                    : "תאריך נדרש"
                                }}
                              </small>
                            </td>
                            <td>
                              <select
                                class="form-control"
                                formControlName="credit_card_name"
                              >
                                <option [value]="1">Normal</option>
                                <option [value]="2">Payments</option>
                                <option [value]="3">Credit</option>
                                <option [value]="4">Deferred Payment</option>
                                <option [value]="5">Others</option>
                                <option [value]="0">Not Credit Card</option>
                              </select>
                              <small
                                *ngIf="
                                  item.get('credit_card_name')?.invalid &&
                                  item.get('credit_card_name')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Payment Type is required"
                                    : curentLang === "ar"
                                    ? "نوع الدفع مطلوب"
                                    : "שיטת תשלום נדרשת"
                                }}
                              </small>
                            </td>
                            <td>
                              <select
                                class="form-control"
                                formControlName="credit_type"
                              >
                                <option [value]="1">Isracard</option>
                                <option [value]="2">Cal</option>
                                <option [value]="3">Diners</option>
                                <option [value]="4">American Express</option>
                                <option [value]="6">Leumi Card</option>
                                <option [value]="0">Not Credit Card</option>
                              </select>
                              <small
                                *ngIf="
                                  item.get('credit_type')?.invalid &&
                                  item.get('credit_type')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Credit Type is required"
                                    : curentLang === "ar"
                                    ? "نوع الأتمان مطلوب"
                                    : "סוג אשראי נדרש"
                                }}
                              </small>
                            </td>
                            <td>
                              <input
                                class="form-control"
                                formControlName="credit_card_code"
                                type="text"
                              />
                              <small
                                *ngIf="
                                  item.get('credit_card_code')?.invalid &&
                                  item.get('credit_card_code')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Credit Code is required"
                                    : curentLang === "ar"
                                    ? "رمز البطاقة مطلوب"
                                    : "קוד אשראי נדרש"
                                }}
                              </small>
                            </td>
                            <td>
                              <input
                                class="form-control"
                                formControlName="account"
                                type="number"
                              />
                              <small
                                *ngIf="
                                  item.get('account')?.invalid &&
                                  item.get('account')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Account is required"
                                    : curentLang === "ar"
                                    ? "الحساب مطلوب"
                                    : "חשבון נדרש"
                                }}
                              </small>
                            </td>
                            <td>
                              <input
                                class="form-control"
                                formControlName="amount"
                                type="number"
                              />
                              <small
                                *ngIf="
                                  item.get('amount')?.invalid &&
                                  item.get('amount')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Amount is required"
                                    : curentLang === "ar"
                                    ? "المبلغ مطلوب"
                                    : "סכום נדרש"
                                }}
                              </small>
                            </td>
                            <td>
                              <i
                                class="fa fa-trash text-danger"
                                style="cursor: pointer"
                                (click)="removeLineFromCreidtPayment(i)"
                              ></i>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="6">
                              <button
                                class="btn btn-dark"
                                (click)="addNewLineToCredit()"
                              >
                                <i class="fa fa-plus-circle me-1"></i>
                                {{
                                  curentLang === "en"
                                    ? "Add Credit"
                                    : curentLang === "ar"
                                    ? "أضف بطاقة"
                                    : "הוסף אשראי"
                                }}
                              </button>
                            </td>
                            <td>
                              <input
                                type="number"
                                readonly
                                [value]="getTotalAmountCreidtArray()"
                                class="form-control"
                              />
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>

                    <!-- Mobile View -->
                    <div class="d-lg-none">
                      <div
                        *ngFor="let item of linesCredit.controls; let i = index"
                        class="border p-3 my-2"
                        [formGroupName]="i"
                      >
                        <div
                          class="form-group"
                          *ngFor="
                            let field of [
                              {
                                name: 'date',
                                type: 'date',
                                label:
                                  curentLang === 'en'
                                    ? 'Date'
                                    : curentLang === 'ar'
                                    ? 'التاريخ'
                                    : 'תאריך'
                              },
                              {
                                name: 'credit_card_name',
                                type: 'select',
                                label:
                                  curentLang === 'en'
                                    ? 'Payment Type'
                                    : curentLang === 'ar'
                                    ? 'نوع الدفع'
                                    : 'סוג תשלום',
                                options: [
                                  'Normal',
                                  'Payments',
                                  'Credit',
                                  'Deferred Payment',
                                  'Others',
                                  'Not Credit Card'
                                ]
                              },
                              {
                                name: 'credit_type',
                                type: 'select',
                                label:
                                  curentLang === 'en'
                                    ? 'Credit Type'
                                    : curentLang === 'ar'
                                    ? 'نوع الأتمان'
                                    : 'סוג אשראי',
                                options: [
                                  'Isracard',
                                  'Cal',
                                  'Diners',
                                  'American Express',
                                  'Leumi Card',
                                  'Not Credit Card'
                                ]
                              },
                              {
                                name: 'credit_card_code',
                                type: 'text',
                                label:
                                  curentLang === 'en'
                                    ? 'Credit Code'
                                    : curentLang === 'ar'
                                    ? 'رمز البطاقة'
                                    : 'קוד אשראי'
                              },
                              {
                                name: 'account',
                                type: 'number',
                                label:
                                  curentLang === 'en'
                                    ? 'Account'
                                    : curentLang === 'ar'
                                    ? 'الحساب'
                                    : 'חשבון'
                              },
                              {
                                name: 'amount',
                                type: 'number',
                                label:
                                  curentLang === 'en'
                                    ? 'Amount'
                                    : curentLang === 'ar'
                                    ? 'المبلغ'
                                    : 'סכום'
                              }
                            ]
                          "
                        >
                          <label>{{ field.label }}</label>

                          <select
                            *ngIf="field.type === 'select'"
                            class="form-control"
                            [formControlName]="field.name"
                          >
                            <option
                              *ngFor="
                                let option of field.options;
                                let j = index
                              "
                              [value]="j"
                            >
                              {{ option }}
                            </option>
                          </select>

                          <input
                            *ngIf="field.type !== 'select'"
                            class="form-control"
                            [type]="field.type"
                            [formControlName]="field.name"
                          />

                          <small
                            *ngIf="
                              item.get(field.name)?.invalid &&
                              item.get(field.name)?.touched
                            "
                            class="text-danger"
                          >
                            {{ field.label }}
                            {{
                              curentLang === "en"
                                ? "is required"
                                : curentLang === "ar"
                                ? "مطلوب"
                                : "נדרש"
                            }}
                          </small>
                        </div>

                        <div class="text-center">
                          <button
                            class="btn btn-sm btn-danger"
                            (click)="removeLineFromCreidtPayment(i)"
                          >
                            <i class="fa fa-trash me-1"></i>
                            {{
                              curentLang === "en"
                                ? "Delete"
                                : curentLang === "ar"
                                ? "حذف"
                                : "מחק"
                            }}
                          </button>
                        </div>
                      </div>

                      <div class="text-center my-2">
                        <input
                          type="number"
                          readonly
                          [value]="getTotalAmountCreidtArray()"
                          class="form-control"
                        />
                      </div>

                      <div class="text-center">
                        <button
                          class="btn btn-dark"
                          (click)="addNewLineToCredit()"
                        >
                          <i class="fa fa-plus-circle me-1"></i>
                          {{
                            curentLang === "en"
                              ? "Add Credit"
                              : curentLang === "ar"
                              ? "أضف بطاقة"
                              : "הוסף אשראי"
                          }}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="modal-footer">
                    <button
                      class="btn btn-dark"
                      (click)="onConfirmCreditModal(modal)"
                    >
                      {{
                        curentLang === "en"
                          ? "Confirm"
                          : curentLang === "ar"
                          ? "تأكيد"
                          : "אישור"
                      }}
                    </button>
                  </div>
                </ng-template>
                <!-- Payment Method Modal -->
                <ng-template #paymentMethodModal let-modal>
                  <div class="modal-header">
                    <h6 class="modal-title">
                      {{
                        curentLang === "en"
                          ? "Choose Payment Method"
                          : curentLang === "ar"
                          ? "اختر طريقة الدفع"
                          : "בחר אמצעי תשלום"
                      }}
                    </h6>
                    <button
                      type="button"
                      class="btn-close"
                      (click)="modal.dismiss()"
                    ></button>
                  </div>
                
                  <div class="modal-body">
                    <!-- Desktop View -->
                    <div class="table-responsive d-none d-lg-block">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Payment Method"
                                  : curentLang === "ar"
                                  ? "طريقة الدفع"
                                  : "אמצעי תשלום"
                              }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let method of paymentMethods"
                            (click)="onSelectPaymentMethod(method.code, modal)"
                            style="cursor: pointer"
                          >
                            <td>{{ method["name_" + curentLang] }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                
                    <!-- Mobile View -->
                    <div class="d-lg-none">
                      <div
                        *ngFor="let method of paymentMethods"
                        class="border p-3 my-2"
                        (click)="onSelectPaymentMethod(method.code, modal)"
                        style="cursor: pointer"
                      >
                        <div class="form-group">
                          <label>{{ method["name_" + curentLang] }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                
                  <div class="modal-footer">
                    <button
                      class="btn btn-dark"
                      (click)="modal.dismiss()"
                    >
                      {{
                        curentLang === "en"
                          ? "Close"
                          : curentLang === "ar"
                          ? "إغلاق"
                          : "סגור"
                      }}
                    </button>
                  </div>
                </ng-template>
                <button
                class="btn btn-dark my-2 w-75 d-block mx-auto"
                  (click)="openChoosePaymentMethodModal(paymentMethodModal)"
                  [disabled]="!invoiceReceiptForm.get('customer_id')?.value"
                >
                  {{
                    curentLang === "en"
                      ? "Select Payment Method"
                      : curentLang === "ar"
                      ? "اختر طريقة الدفع"
                      : "בחר אמצעי תשלום"
                  }}
                  <i class="fa fa-credit-card mx-1"></i>
                </button>

<!-- Payment Methods Summary with Edit and Delete -->
<div *ngIf="selectedPaymentMethodsSummary.length > 0" class="mt-2 p-3 rounded" style="background-color: white; border: 1px solid #ddd;">
  <table class="table table-borderless">
    <thead>
      <tr style="border-bottom: 2px solid #ddd;">
        <th style="text-align: start; color: black;">
          {{
            curentLang === 'en'
              ? 'Payment Type'
              : curentLang === 'ar'
              ? 'نوع الدفعة'
              : 'סוג תקבול'
          }}
        </th>
        <th style="text-align: start; color: black;">
          {{
            curentLang === 'en'
              ? 'Date'
              : curentLang === 'ar'
              ? 'التاريخ'
              : 'תאריך'
          }}
        </th>
        <th style="text-align: start; color: black;">
          {{
            curentLang === 'en'
              ? 'Amount'
              : curentLang === 'ar'
              ? 'المبلغ'
              : 'סכום'
          }}
        </th>
        <th style="text-align: start;"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let method of selectedPaymentMethodsSummary; let i = index" style="border-bottom: 1px solid #eee;">
        <td style="color: black;">{{ getMethodLabel(method.code) }}</td>
        <div *ngIf="method">
          <input
          type="date"
          [value]="formatDateForInput(method?.date)"
          disabled
          formControlName="invoice_date"
          style="
            border: none;
            background: transparent;
            color: inherit;
            font-size: inherit;
            width: auto;
            appearance: none;
            pointer-events: none;
            
            /* Hide the calendar icon for different browsers */
            -webkit-appearance: none;
            -moz-appearance: textfield;
            position: relative;
          "
        />
        </div>
                <td style="color: black;">{{ method.amount | number: '1.2-2' }} ₪</td>
        <td class="text-end">
          <i class="fa fa-edit text-primary me-2" style="cursor: pointer;" (click)="editPaymentMethod(method)"></i>
          <i class="fa fa-trash text-danger" style="cursor: pointer;" (click)="removeSelectedPaymentMethod(method.code)"></i>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="fw-bold text-end" style="color: black; margin-top: 10px; border-top: 2px solid #ddd; padding-top: 8px;">
    {{
      curentLang === 'en'
        ? 'Total Paid:'
        : curentLang === 'ar'
        ? 'إجمالي المدفوع:'
        : 'סה"כ שולם:'
    }} {{ getTotalAmount() | number: '1.2-2' }} ₪  </div>
</div>
                <!-- End Payment Method -->

                <div class="row d-flex justify-content-center">
                  <div class="col-md-3"></div>

                  <div class="col-md-3">
                    <div class="col-lg-12 p-2">
                      <label for="" style="font-weight: 800">
                        {{
                          curentLang === "en"
                            ? "Deduction From Source"
                            : curentLang === "ar"
                            ? " الخصم من المصدر"
                            : "    ניכוי במקור  "
                        }}
                      </label>
                      <input
                        type="number"
                        min="0"
                        formControlName="deduction_from_source"
                        class="form-control m-auto w-100"
                      />
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="col-lg-12 p-2">
                      <label for="" style="font-weight: 800">
                        {{
                          curentLang === "en"
                            ? "Final Amount"
                            : curentLang === "ar"
                            ? "القيمة النهائية "
                            : "    סהכ לתשלום  "
                        }}
                      </label>
                      <input
                        class="form-control m-auto w-100"
                        formControlName="total_amount"
                        type="number"
                      />
                    </div>
                  </div>

                  <div class="col-md-3"></div>
                </div>

                <div class="row d-flex justify-content-between text-center">
                  <div class="col-4 col-lg-4 p-1">
                    <button
                      class="btn"
                      style="background-color: #141414; color: #fff"
                      type="submit"
                      (click)="confirmInvoice()"
                    >
                      <i
                        class="fa fa-check mx-1"
                        style="font-size: 14px; cursor: pointer; color: #fff"
                      ></i>
                      {{
                        curentLang === "en"
                          ? "Confirm "
                          : curentLang === "ar"
                          ? "تأكيد    "
                          : "אישור    "
                      }}
                    </button>
                  </div>

                  <div class="col-4 col-lg-4 p-1">
                    <button
                      class="btn"
                      style="background-color: #277d29; color: #fff"
                      type="submit"
                      (click)="saveInvoiceAndReceipt()"
                    >
                      <i
                        class="fa fa-save m-1"
                        style="font-size: 14px; cursor: pointer; color: #fff"
                      ></i>
                      {{
                        curentLang === "en"
                          ? "Save "
                          : curentLang === "ar"
                          ? "حفظ "
                          : " שמירה "
                      }}
                    </button>
                  </div>

                  <div class="col-4 col-lg-4 p-1">
                    <button
                      class="btn btn-danger"
                      routerLink="/dashboard/documents"
                    >
                      <i
                        class="fa fa-rotate-left mx-1"
                        style="font-size: 14px; cursor: pointer; color: #fff"
                      ></i>
                      {{
                        curentLang === "en"
                          ? "Back"
                          : curentLang === "ar"
                          ? "رجوع"
                          : "חזרה"
                      }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--add Customer-->

<ng-template #content let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title" style="color: grey">
      {{
        curentLang === "en"
          ? "Add Customer "
          : curentLang === "ar"
          ? " اضافة عميل "
          : " הוסף לקוח "
      }}
    </h6>
    <button
      type="button"
      class="close btn-close"
      style="color: grey"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="edit-profile">
        <form [formGroup]="addCustomer" novalidate>
          <!-- الصف الأول -->
          <div class="row">
            <div class="mb-3 col-xl-4 col-md-4">
              <label class="form-label" style="color: grey">
                {{
                  curentLang === "en"
                    ? "Customer Name"
                    : curentLang === "ar"
                    ? "اسم العميل"
                    : " שם לקוח "
                }}
                <span class="text-danger" style="color: red">*</span>
              </label>
              <input
                style="color: grey"
                class="form-control"
                type="text"
                formControlName="customer_name_he"
                maxlength="80"
                required
                [ngClass]="{
                  'is-invalid':
                    validate && addCustomer.controls['customer_name_he'].invalid
                }"
              />
              <div
                *ngIf="
                  validate && addCustomer.controls['customer_name_he'].invalid
                "
                class="invalid-feedback text-danger"
              >
                {{
                  curentLang === "en"
                    ? "Please enter customer name"
                    : curentLang === "ar"
                    ? "الرجاء ادخال اسم العميل"
                    : "נא להזין את שם הלקוח"
                }}
              </div>
            </div>

            <div class="mb-3 col-xl-4 col-md-4">
              <label class="form-label" style="color: grey">
                {{
                  curentLang === "en"
                    ? "Customer Type"
                    : curentLang === "ar"
                    ? "نوع العميل"
                    : "סוג לקוח"
                }}
                <span class="text-danger" style="color: red">*</span>
              </label>
              <select
                class="form-control form-select"
                required
                formControlName="customer_type"
                [ngClass]="{
                  'is-invalid':
                    validate && addCustomer.controls['customer_type'].invalid
                }"
              >
                <option
                  *ngFor="let item of customerTypeData; let i = index"
                  [value]="item?.lookup_detail_id"
                >
                  {{
                    curentLang === "en"
                      ? item?.lookupDetailEnName
                      : curentLang === "ar"
                      ? item?.lookupDetailArName
                      : item?.lookupDetailHeName
                  }}
                </option>
              </select>
              <div
                *ngIf="
                  validate && addCustomer.controls['customer_type'].invalid
                "
                class="invalid-feedback text-danger"
              >
                {{
                  curentLang === "en"
                    ? "Please choose customer type"
                    : curentLang === "ar"
                    ? "الرجاء اختيار نوع العميل"
                    : "אנא בחר סוג לקוח"
                }}
              </div>
            </div>

            <div class="mb-3 col-xl-4 col-md-4">
              <label class="form-label" style="color: grey">
                {{
                  curentLang === "en"
                    ? "Customer Phone"
                    : curentLang === "ar"
                    ? "هاتف العميل"
                    : "טלפון לקוח"
                }}
              </label>
              <div class="input-group">
                <span style="color: #141414" class="input-group-text"
                  >+972</span
                >
                <input
                  class="form-control"
                  type="text"
                  maxlength="10"
                  formControlName="customer_phone"
                  [ngClass]="{
                    'is-invalid':
                      validate && addCustomer.controls['customer_phone'].invalid
                  }"
                />
              </div>
              <div
                *ngIf="
                  validate && addCustomer.controls['customer_phone'].invalid
                "
                class="invalid-feedback text-danger"
              >
                {{
                  curentLang === "en"
                    ? "Please enter a valid phone number"
                    : curentLang === "ar"
                    ? "الرجاء إدخال رقم هاتف صالح"
                    : "נא להזין מספר טלפון תקין"
                }}
              </div>
            </div>
          </div>

          <!-- الصف الثاني -->
          <div class="row">
            <div
              class="mb-3 col-xl-4 col-md-4"
              *ngIf="addCustomer.value.customer_type != 6"
            >
              <label class="form-label" style="color: grey">
                {{
                  curentLang === "en"
                    ? "ID Number"
                    : curentLang === "ar"
                    ? "رقم الهوية"
                    : "מספר ישות (ת.ז / ח.פ.)"
                }}
                <!-- <span class="text-danger" style="color: red;">*</span> -->
              </label>
              <input
                style="color: grey"
                class="form-control"
                type="number"
                formControlName="id_number"
                maxlength="9"
              />
            </div>

            <div class="mb-3 col-xl-4 col-md-4">
              <label class="form-label" style="color: grey">
                {{
                  curentLang === "en"
                    ? "City Name  "
                    : curentLang === "ar"
                    ? " اسم المدينة "
                    : "שם העיר"
                }}
                <!-- <span class="text-danger" style="color: red;">*</span> -->
              </label>
              <input
                style="color: grey"
                class="form-control"
                type="text"
                formControlName="customer_address_city"
                maxlength="100"
              />
              <!-- <div *ngIf="validate && addCustomer.controls['customer_address_city'].invalid" class="invalid-feedback text-danger">
                                {{ curentLang === 'en' ? 'Please enter customer city' : (curentLang === 'ar' ? 'الرجاء إدخال مدينة العميل' : 'אנא הזן עיר לקוח') }}
                            </div> -->
            </div>

            <div class="mb-3 col-xl-4 col-md-4">
              <label class="form-label" style="color: grey">
                {{
                  curentLang === "en"
                    ? "Street Name"
                    : curentLang === "ar"
                    ? "أسم الشارع"
                    : "שם רחוב"
                }}
                <!-- <span class="text-danger" style="color: red;">*</span> -->
              </label>
              <input
                style="color: grey"
                class="form-control"
                type="text"
                formControlName="customer_address_str"
                maxlength="100"
              />
              <!-- <div *ngIf="validate && addCustomer.controls['customer_address_str'].invalid" class="invalid-feedback text-danger">
                                {{ curentLang === 'en' ? 'Please enter customer street' : (curentLang === 'ar' ? 'الرجاء إدخال شارع العميل' : 'אנא הזן רחוב לקוח') }}
                            </div> -->
            </div>
          </div>

          <!-- الصف الثالث -->
          <div class="row">
            <div class="mb-3 col-xl-4 col-md-4">
              <label class="form-label" style="color: grey">
                {{
                  curentLang === "en"
                    ? "Building Number"
                    : curentLang === "ar"
                    ? "رقم المبني"
                    : "מספר בניין"
                }}
                <!-- <span class="text-danger" style="color: red;">*</span> -->
              </label>
              <input
                style="color: grey"
                class="form-control"
                type="number"
                formControlName="customer_Building_number"
                maxlength="100"
              />
              <!-- <div *ngIf="validate && addCustomer.controls['customer_Building_number'].invalid" class="invalid-feedback text-danger">
                                {{ curentLang === 'en' ? 'Please enter customer building number' : (curentLang === 'ar' ? 'الرجاء إدخال رقم مبنى العميل' : 'אנא הזן מספר בניין לקוח') }}
                            </div> -->
            </div>

            <div class="mb-3 col-xl-4 col-md-4">
              <label class="form-label" style="color: grey">
                {{
                  curentLang === "en"
                    ? "Zip Code"
                    : curentLang === "ar"
                    ? "الرقم البريدي"
                    : "מיקוד דואר"
                }}
                <!-- <span class="text-danger" style="color: red;">*</span> -->
              </label>
              <input
                style="color: grey"
                class="form-control"
                type="number"
                formControlName="customer_address_zip_code"
                maxlength="20"
              />
              <!-- <div *ngIf="validate && addCustomer.controls['customer_address_zip_code'].invalid" class="invalid-feedback text-danger">
                                {{ curentLang === 'en' ? 'Please enter customer zip code' : (curentLang === 'ar' ? 'الرجاء إدخال الرقم البريدي للعميل' : 'אנא הזן את המיקוד של הלקוח') }}
                            </div> -->
            </div>

            <div class="mb-3 col-xl-4 col-md-4">
              <label class="form-label" style="color: grey">
                {{
                  curentLang === "en"
                    ? "Customer Vat Number"
                    : curentLang === "ar"
                    ? "الرقم الضريبي للعميل"
                    : "מספר תיק במס ההכנסה"
                }}
                <span class="text-danger" style="color: red">*</span>
              </label>
              <input
                style="color: grey"
                class="form-control"
                type="text"
                formControlName="customer_vat_number"
                maxlength="9"
                required
                pattern="[0-9]{9}"
                title="{{
                  curentLang === 'en'
                    ? 'Please enter exactly 9 digits'
                    : curentLang === 'ar'
                    ? 'يرجى إدخال 9 أرقام فقط'
                    : 'אנא הזן 9 ספרות בדיוק'
                }}"
                [ngClass]="{
                  'is-invalid':
                    validate &&
                    addCustomer.controls['customer_vat_number'].invalid
                }"
              />
              <div
                *ngIf="
                  validate &&
                  addCustomer.controls['customer_vat_number'].invalid
                "
                class="invalid-feedback text-danger"
              >
                {{
                  curentLang === "en"
                    ? "Please enter exactly 9 digits"
                    : curentLang === "ar"
                    ? "يرجى إدخال 9 أرقام فقط"
                    : "אנא הזן 9 ספרות בדיוק"
                }}
                <span class="text-danger" style="color: red">*</span>
              </div>
            </div>
          </div>

          <!-- الصف الرابع (حقل ضريبة القيمة المضافة، أو يمكنك إضافة المزيد من الحقول) -->
          <div class="row">
            <div
              class="mb-3 col-xl-4 col-md-4"
              *ngIf="addCustomer.value.customer_type != 5"
            >
              <label class="form-label" style="color: grey">
                {{
                  curentLang === "en"
                    ? "Customer Vat Tax"
                    : curentLang === "ar"
                    ? "رقم ضريبة القيمة المضافة"
                    : "  מס עוסק מורשה   "
                }}
              </label>
              <input
                style="color: grey"
                class="form-control"
                type="number"
                formControlName="customer_vat_tax"
                maxlength="9"
                [ngClass]="{
                  'is-invalid':
                    validate && addCustomer.controls['customer_vat_tax'].invalid
                }"
              />
              <div
                *ngIf="
                  validate && addCustomer.controls['customer_vat_tax'].invalid
                "
                class="invalid-feedback text-danger"
              >
                {{
                  curentLang === "en"
                    ? "Please enter a valid VAT number"
                    : curentLang === "ar"
                    ? "الرجاء إدخال رقم ضريبة القيمة المضافة الصحيح"
                    : 'אנא הזן מספר מס מע"מ תקין'
                }}
              </div>
            </div>
          </div>

          <hr />
          <!--
                    <h6 class="modal-title" style="color: grey;">
                      {{ curentLang === 'en' ? 'Additional fields' : (curentLang === 'ar' ? ' حقول اضافية   ' : 'שדות נוספים')}} 
                  </h6>
                  <hr style="width: 25%;"> 
  -->
          <div class="row">
            <!--   <div class="mb-3 col-xl-6 col-md-6">
                      <label class="form-label" style="color: grey;">
                          {{ curentLang === 'en' ? 'City Name Ar' : (curentLang === 'ar' ? 'اسم المدينة عربي' : 'שם עיר בערבית') }}
                           <span class="text-danger" style="color: red;">*</span>  
                      </label>
                      <input 
                          style="color: grey;" 
                          class="form-control"
                          type="text" 
                          formControlName="customer_address_city_ar"
                          maxlength="100"
                          
                      />
                     <div *ngIf="validate && addCustomer.controls['customer_address_city'].invalid" class="invalid-feedback text-danger">
                          {{ curentLang === 'en' ? 'Please enter customer city' : (curentLang === 'ar' ? 'الرجاء إدخال مدينة العميل' : 'אנא הזן עיר לקוח') }}
                      </div> 
                  </div>-->

            <!--    <div class="mb-3 col-xl-6 col-md-6">
                    <label class="form-label" style="color: grey;">
                        {{ curentLang === 'en' ? 'City Name En' : (curentLang === 'ar' ? 'اسم المدينة انجليزي' : 'שם העיר באנגלית') }}
                         <span class="text-danger" style="color: red;">*</span>  
                    </label>
                    <input 
                        style="color: grey;" 
                        class="form-control"
                        type="text" 
                        formControlName="customer_address_city_en"
                        maxlength="100"
                        
                    />
                     <div *ngIf="validate && addCustomer.controls['customer_address_city'].invalid" class="invalid-feedback text-danger">
                        {{ curentLang === 'en' ? 'Please enter customer city' : (curentLang === 'ar' ? 'الرجاء إدخال مدينة العميل' : 'אנא הזן עיר לקוח') }}
                    </div>  
                </div>
                    -->

            <!--   <div class="mb-3 col-xl-6 col-md-6">
                      <label class="form-label" style="color: grey;">
                          {{ curentLang === 'en' ? 'Street Name Ar' : (curentLang === 'ar' ? 'اسم الشارع عربي' : "שם הרחוב הוא ערבי") }}
                            <span class="text-danger" style="color: red;">*</span>  
                      </label>
                      <input 
                          style="color: grey;" 
                          class="form-control"
                          type="text" 
                          formControlName="customer_address_str_ar"
                          maxlength="100"
                      />
                       <div *ngIf="validate && addCustomer.controls['customer_address_str'].invalid" class="invalid-feedback text-danger">
                          {{ curentLang === 'en' ? 'Please enter customer street' : (curentLang === 'ar' ? 'الرجاء إدخال شارع العميل' : 'אנא הזן רחוב לקוח') }}
                      </div> 
                  </div>-->

            <!--  <div class="mb-3 col-xl-6 col-md-6">
                    <label class="form-label" style="color: grey;">
                        {{ curentLang === 'en' ? 'Street Name En' : (curentLang === 'ar' ? 'اسم الشارع انجليزي' : 'שם רחוב באנגלית') }}
                         <span class="text-danger" style="color: red;">*</span> 
                    </label>
                    <input 
                        style="color: grey;" 
                        class="form-control"
                        type="text" 
                        formControlName="customer_address_str_en"
                        maxlength="100"
                    />
                  <div *ngIf="validate && addCustomer.controls['customer_address_str'].invalid" class="invalid-feedback text-danger">
                        {{ curentLang === 'en' ? 'Please enter customer street' : (curentLang === 'ar' ? 'الرجاء إدخال شارع العميل' : 'אנא הזן רחוב לקוח') }}
                    </div>  
                </div>-->
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm m-auto mt-2"
              style="background-color: #141414; color: #fff"
              (click)="addedData()"
            >
              {{
                curentLang === "en"
                  ? "Confirm"
                  : curentLang === "ar"
                  ? "تأكيد"
                  : "אישור"
              }}
              <i class="fa fa-check-circle mx-1" style="color: #fff"></i>
            </button>
            <button
              type="button"
              class="btn btn-sm m-auto mt-2 btn-danger"
              (click)="modal.close('Close click')"
            >
              {{
                curentLang === "en"
                  ? "Back"
                  : curentLang === "ar"
                  ? "رجوع"
                  : "חזרה"
              }}
              <i class="fa fa-rotate-left mx-1"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<!--add product-->

<ng-template #content2 let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title" style="color: grey">
      {{
        curentLang === "en"
          ? "Add Product "
          : curentLang === "ar"
          ? " اضافة منتج "
          : "  הוסף מוצר"
      }}
    </h6>
    <button
      type="button"
      class="close btn-close"
      style="color: grey"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="edit-profile">
        <div class="row d-flex justify-content-between">
          <form
            [formGroup]="addProduct"
            novalidate
            [ngClass]="{ 'was-validated': validate }"
          >
            <div class="row">
              <!-- <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Product Name Ar' : (curentLang === 'ar' ? '  اسم المنتج عربي   ' : '  שם המוצר בערבית  ')}}
                               </label>
                                <input style="color: grey;" 
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="product_name_ar" 
                                  />
                              </div> -->

              <!-- <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Product Name En' : (curentLang === 'ar' ? '  اسم المنتج انجليزي   ' : '   שם המוצר באנגלית  ')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control"
                                   type="text"
                                   maxlength="80"
                                    formControlName="product_name_en"
                                   />
                                </div> -->

              <div class="mb-3 col-xl-4 col-md-4">
                <label class="form-label" style="color: grey">
                  {{
                    curentLang === "en"
                      ? "Product Name "
                      : curentLang === "ar"
                      ? "  اسم المنتج    "
                      : " שם מוצר  "
                  }}
                  <span class="text-danger">*</span>
                </label>
                <input
                  style="color: grey"
                  class="form-control"
                  onlyAlphabets
                  required=""
                  type="text"
                  formControlName="product_name_he"
                  maxlength="80"
                />
                <div class="invalid-feedback text-danger">
                  {{
                    curentLang === "en"
                      ? "please enter product name"
                      : curentLang === "ar"
                      ? "   الرجاء ادخال أسم المنتج "
                      : " נא להזין את שם המוצר      "
                  }}
                </div>
              </div>

              <div class="mb-3 col-xl-4 col-md-4">
                <label class="form-label" style="color: grey">
                  {{
                    curentLang === "en"
                      ? "Product Code"
                      : curentLang === "ar"
                      ? " كود المنتج "
                      : " קוד מוצר   "
                  }}
                  <span class="text-danger">*</span>
                </label>
                <input
                  style="color: grey"
                  onlyAlphabets
                  required=""
                  class="form-control"
                  type="text"
                  formControlName="product_code"
                  maxlength="20"
                />
                <div class="invalid-feedback text-danger">
                  {{
                    curentLang === "en"
                      ? "please enter product code"
                      : curentLang === "ar"
                      ? "   الرجاء ادخال كود المنتج "
                      : " נא להזין את קוד המוצר "
                  }}
                </div>
              </div>

              <div class="mb-3 col-xl-4 col-md-4">
                <label class="form-label" style="color: grey">
                  {{
                    curentLang === "en"
                      ? "Product Price"
                      : curentLang === "ar"
                      ? "    سعر المنتج "
                      : "   מחיר מוצר "
                  }}
                  <span class="text-danger">*</span>
                </label>
                <input
                  style="color: grey"
                  onlyAlphabets
                  required=""
                  class="form-control"
                  type="number"
                  formControlName="price"
                  maxlength="20"
                />
                <div class="invalid-feedback text-danger">
                  {{
                    curentLang === "en"
                      ? "please enter product price"
                      : curentLang === "ar"
                      ? "   الرجاء ادخال سعر المنتج "
                      : " נא להזין את מחיר המוצר"
                  }}
                </div>
              </div>

              <div class="mb-3 col-xl-6 col-md-6">
                <label class="form-label" style="color: grey">
                  {{
                    curentLang === "en"
                      ? "Product Category"
                      : curentLang === "ar"
                      ? "    قسم المنتج "
                      : " מדור מוצר "
                  }}
                  <span class="text-danger">*</span>
                </label>

                <ng-select
                  formControlName="categories_id"
                  [(ngModel)]="selectedProduct"
                  onlyAlphabets
                  required=""
                  (change)="filterProduct(selectedProduct)"
                  style="cursor: pointer"
                  placeholder="{{
                    curentLang === 'en'
                      ? 'Select'
                      : curentLang === 'ar'
                      ? 'اختر'
                      : 'בחר'
                  }}"
                >
                  <ng-option
                    *ngFor="let item of categoriesData; let i = index"
                    [value]="item?.categories_id"
                  >
                    {{
                      curentLang === "en"
                        ? item?.categories_name_en
                        : curentLang === "ar"
                        ? item?.categories_name_ar
                        : item?.categories_name_he
                    }}
                  </ng-option>
                </ng-select>
                <div
                  *ngIf="addProduct.get('categories_id')?.errors?.required"
                  class="text-danger"
                >
                  <p>
                    {{
                      curentLang === "en"
                        ? "please chooce category"
                        : curentLang === "ar"
                        ? "   الرجاء اختيار القسم "
                        : "אנא בחר מחלקה"
                    }}
                  </p>
                </div>
              </div>

              <div class="mb-3 col-xl-6 col-md-6">
                <label class="form-label" style="color: grey">
                  {{
                    curentLang === "en"
                      ? "Measurement Unit Product"
                      : curentLang === "ar"
                      ? "  وحدة قياس المنتج "
                      : " יחידת מדידת מוצר "
                  }}
                  <span class="text-danger">*</span>
                </label>

                <ng-select
                  formControlName="measurement_unit_id"
                  [(ngModel)]="selectedMeasurementUnit"
                  onlyAlphabets
                  required=""
                  (change)="filterMeasurementUnit(selectedMeasurementUnit)"
                  style="cursor: pointer"
                  placeholder="{{
                    curentLang === 'en'
                      ? 'Select'
                      : curentLang === 'ar'
                      ? 'اختر'
                      : 'בחר'
                  }}"
                >
                  <ng-option
                    *ngFor="let item of unitMesauereData; let i = index"
                    [value]="item?.measurement_unit_id"
                  >
                    {{
                      curentLang === "en"
                        ? item?.measurement_unit_name_en
                        : curentLang === "ar"
                        ? item?.measurement_unit_name_ar
                        : item?.measurement_unit_name_he
                    }}
                  </ng-option>
                </ng-select>
                <div
                  *ngIf="
                    addProduct.get('measurement_unit_id')?.errors?.required
                  "
                  class="text-danger"
                >
                  <p>
                    {{
                      curentLang === "en"
                        ? "please chooce measurement unit name"
                        : curentLang === "ar"
                        ? "   الرجاء اختيار وحدة قياس المنتج  "
                        : "  אנא בחר יחידת מדידה של מוצר  "
                    }}
                  </p>
                </div>
              </div>

              <!-- <div class="mb-3 col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Description En' : (curentLang === 'ar' ? '  وصف المنتج انجليزي  ' : '   תיאור המוצר באנגלית')}} 
                                   </label>
                                        <textarea
                                         maxlength="600" name=""
                                          id="des_en" cols="10" rows="5" type="text"
                                          class="form-control form-control-sm"
                                           formControlName="des_en">


                                   </textarea>
                                  </div> -->

              <!-- <div class="mb-3 col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Description Ar' : (curentLang === 'ar' ? '  وصف المنتج عربي  ' : '   תיאור המוצר בערבית    ')}}   
                                   </label>
                                        <textarea
                                         maxlength="600" name=""
                                          id="des_ar" cols="10" rows="5" type="text"
                                          class="form-control form-control-sm"
                                           formControlName="des_ar">
                                   </textarea>
                                  </div> -->

              <div class="mb-3 col-xl-12 col-md-12">
                <label class="form-label" style="color: grey">
                  {{
                    curentLang === "en"
                      ? "Description "
                      : curentLang === "ar"
                      ? "  وصف المنتج   "
                      : "   תיאור מוצר בעברית    "
                  }}
                  <span class="text-danger">*</span>
                </label>
                <textarea
                  maxlength="600"
                  name=""
                  onlyAlphabets
                  required=""
                  id="des_he"
                  cols="10"
                  rows="5"
                  type="text"
                  class="form-control form-control-sm"
                  formControlName="des_he"
                >
                </textarea>

                <div class="invalid-feedback text-danger">
                  {{
                    curentLang === "en"
                      ? "please enter product description  "
                      : curentLang === "ar"
                      ? "   الرجاء ادخال وصف المنتج "
                      : "נא להזין תיאור מוצר"
                  }}
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm m-auto mt-2"
                style="background-color: #141414; color: #fff"
                (click)="addedProductData()"
              >
                {{
                  curentLang === "en"
                    ? "Confirm"
                    : curentLang === "ar"
                    ? "  تاكيد "
                    : "  אישור  "
                }}
                <i class="fa fa-check-circle mx-1" style="color: #fff"></i>
              </button>

              <button
                type="button"
                class="btn btn-sm m-auto mt-2 btn-danger"
                (click)="modal.close('Close click')"
              >
                {{
                  curentLang === "en"
                    ? "Back"
                    : curentLang === "ar"
                    ? "  رجوع "
                    : "  חזרה  "
                }}
                <i class="fa fa-rotate-left mx-1"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
