import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../../service/http-help.service";
import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";

@Component({
  selector: "app-update-company",
  templateUrl: "./update-company.component.html",
  styleUrls: ["./update-company.component.scss"],
})
export class UpdateCompanyComponent implements OnInit {
  curentLang: string = "";
  layoutVersion: any;
  userRegistId: any = JSON.parse(localStorage.getItem("user_id"));
  merchantId = JSON.parse(localStorage.getItem("merchant_id"));
  user_type = JSON.parse(localStorage.getItem("user_type"));

  companyInfoData: any = [];
  id: number;
  uuid: string;
  companyNameAr: string = "";
  companyNameEn: string = "";
  companyNamehE: string = "";
  logoUrl: string = "";
  TaxRegister: string = "";
  CommercialRegister: string = "";
  startInvoiceNumber: string = "";
  VatListId: number;
  VATNameAR: string = "";
  VATNameEN: string = "";
  VATNameHE: string = "";
  CompanyAddressSTR: string = "";
  CompanyAddressSTRAr: string = "";
  CompanyAddressSTREn: string = "";
  CompanyBuildingNumber: string = "";
  CompanyAddressCity: string = "";
  CompanyAddressCityAr: string = "";
  CompanyAddressCityEn: string = "";
  CompanyAddressZipCode: string = "";
  CompanyId: number;
  companyPhone: any;
  templates: any[] = [];

  public file: any;
  url: any;
  files: File[] = [];
  currentImageUrl: any;
  updateCompanyInfo: FormGroup;
  leftNote: string = '';
  rightNote: string = '';
  updateCompanyInfoByAdmin: FormGroup;
  vatInfoData: any = [];
  public validate = false;

  newOrders = {
    colorClass: "primary",
    icon: "new-order",
    colorClass2: "warning",
    icon2: "customers",
    colorClass3: "success",
    icon4: "invoice",
    colorClass4: "secondary",
    icon3: "profit",
  };

  uploadImage = new FormGroup({
    file: new FormControl(""),
    id: new FormControl(""),
  });

  constructor(
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    private service: HttpHelpService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private cdRef: ChangeDetectorRef
  ) {
    this.curentLang = localStorage.getItem("curentLang");
    this.uuid = this.activatedRoute.snapshot.params["id"];

    this.updateCompanyInfo = new FormGroup({
      company_name_ar: new FormControl(""),
      company_name_en: new FormControl(""),
      company_name_he: new FormControl(""),
      company_address_str: new FormControl(""),
      company_address_str_ar: new FormControl(""),
      company_address_str_en: new FormControl(""),
      company_Building_number: new FormControl(""),
      company_phone: new FormControl(""),
      company_address_city: new FormControl(""),
      company_address_city_ar: new FormControl(""),
      template_id: new FormControl(""), // Add this here
      template_url: new FormControl(""), // Add this here
      left_note: new FormControl(""), // Add this here
      right_note: new FormControl(""), // Add this here
      company_address_city_en: new FormControl(""),
      company_address_zip_code: new FormControl(""),
      updated_by: new FormControl(this.merchantId),
    });

    this.updateCompanyInfoByAdmin = new FormGroup({
      tax_register: new FormControl("", [
        Validators.required,
        Validators.pattern("^[0-9]{9}$"),
      ]),
      commercial_register: new FormControl("", [
        Validators.required,
        Validators.pattern("^[0-9]"),
      ]),
      company_name_ar: new FormControl(""),
      company_name_en: new FormControl(""),
      company_phone: new FormControl(""),
      company_name_he: new FormControl("", Validators.required),
      company_address_str: new FormControl("", Validators.required),
      company_address_str_ar: new FormControl("", Validators.required),
      company_address_str_en: new FormControl("", Validators.required),
      company_Building_number: new FormControl("", Validators.required),
      company_address_city: new FormControl("", Validators.required),
      company_address_city_ar: new FormControl(""),
      company_address_city_en: new FormControl(""),
      company_address_zip_code: new FormControl("", Validators.required),
      template_id: new FormControl(""), // Add this here
      template_url: new FormControl(""), // Add this here

      updated_by: new FormControl(this.userRegistId),
    });
  }

  openVerticallyCentered(dataDeleted: any) {
    this.modalService.open(dataDeleted, { centered: true });
  }
  openTemplateModal(templateModal: any) {
    this.modalService.open(templateModal, { centered: true });
  }
  deleteCompanyLogoByCompanyId() {
    this.spinner.show();
    this.service.deleteCompanyLogoByCompanyId(this.CompanyId).subscribe({
      next: (res) => {
        if (
          res.statusCode == 400 ||
          res.statusCode == 500 ||
          res.statusCode == 404
        ) {
          Swal.fire({
            icon: "error",
            title:
              this.curentLang === "ar"
                ? res.messageAr
                : this.curentLang === "en"
                ? res.messageEn
                : res.messageHe,
          });
          this.spinner.hide();
          return;
        } else {
          Swal.fire({
            icon: "success",
            title:
              this.curentLang === "ar"
                ? res.messageAr
                : this.curentLang === "en"
                ? res.messageEn
                : res.messageHe,
          });
          this.getCompanyInfoById();
          this.modalService.dismissAll();
          this.spinner.hide();
        }
      },
      error: (err) => {
        Swal.fire(err.messageAr);
        this.spinner.hide();
      },
    });
  }

  getCompanyInfoById(): Promise<void> {
    this.spinner.show();
    return new Promise((resolve, reject) => {
      this.service.getCompanyInfoById(this.id).subscribe({
        next: (res) => {
          this.companyInfoData = res;
  
          this.companyPhone = res?.company_phone;
          this.companyNameAr = res?.company_name_ar;
          this.companyNameEn = res?.company_name_en;
          this.companyNamehE = res?.company_name_he;
          this.logoUrl = res?.logo_url;
          this.CompanyId = res?.company_id;
          this.TaxRegister = res?.tax_register;
          this.CommercialRegister = res?.commercial_register;
          this.startInvoiceNumber = res?.start_invoice_number;
          this.VatListId = res?.vat_list_id;
          this.VATNameAR = res?.vatList?.vat_name_ar;
          this.VATNameEN = res?.vatList?.vat_name_en;
          this.VATNameHE = res?.vatList?.vat_name_he;
          this.CompanyAddressSTR = res?.company_address_str;
          this.CompanyAddressSTRAr = res?.company_address_str_ar;
          this.CompanyAddressSTREn = res?.company_address_str_en;
          this.CompanyAddressCity = res?.company_address_city;
          this.CompanyAddressCityAr = res?.company_address_city_ar;
          this.CompanyAddressCityEn = res?.company_address_city_en;
          this.CompanyAddressZipCode = res?.company_address_zip_code;
          this.CompanyBuildingNumber = res?.company_Building_number;
  
          // ✅ Convert <br> back to \n for textarea input
          this.leftNote = res?.left_note ? res.left_note.replace(/<br>/g, '\n') : '';
          this.rightNote = res?.right_note ? res.right_note.replace(/<br>/g, '\n') : '';
  
          // ✅ Ensure the template_id is set correctly in the form
          if (res?.template_id) {
            this.updateCompanyInfo.patchValue({ template_id: res.template_id });
            this.updateCompanyInfoByAdmin.patchValue({
              template_id: res.template_id,
            });
          }
  
          this.spinner.hide();
          resolve();
        },
        error: (error) => {
          this.spinner.hide();
          reject(error);
        },
      });
    });
  }

  getCompanyIdByUuid(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.service.getCompanyIdByUuid(this.uuid).subscribe({
        next: (res) => {
          this.id = res?.company_id;
          this.getCompanyInfoById().then(resolve).catch(reject); // Ensure company data is loaded
        },
        error: (error) => {
          Swal.fire({
            icon: "error",
            title:
              this.curentLang === "ar"
                ? "فشل في الحصول على معلومات الشركة"
                : this.curentLang === "en"
                ? "Failed to get company information"
                : "נכשל בהשגת מידע על החברה",
          });
          reject(error);
        },
      });
    });
  }

  ngOnInit() {
    
    this.getCompanyIdByUuid()
      .then(() => {
        console.log("Company data loaded. Now loading templates...");
        this.loadTemplates();
      })
      .catch((error) => {
        console.error("Error loading company data:", error);
        this.loadTemplates(); // Still load templates, but without saved template
      });

    this.getAllActiveVatList();

    this.layoutVersion = localStorage.getItem("layout_version") || "light-only";
    localStorage.setItem("layout_version", this.layoutVersion);
    document.body.classList.add(
      this.layoutVersion === "dark-only" ? "dark-only" : "light-only"
    );

    this.userRegistId = JSON.parse(localStorage.getItem("user_id"));
    this.merchantId = JSON.parse(localStorage.getItem("merchant_id"));
    this.user_type = JSON.parse(localStorage.getItem("user_type"));
  }
  selectedTemplate: any = null; // Store selected template details

  loadTemplates(): void {
    this.service.getTemplatesSelect().subscribe(
      (data: any) => {
        console.log("Templates data from API:", data); // Debug API response
  
        if (Array.isArray(data) && data.length > 0) {
          this.templates = data;
  
          // Get the saved template ID from the form
          let savedTemplateId =
            this.updateCompanyInfo.get("template_id")?.value ||
            this.updateCompanyInfoByAdmin.get("template_id")?.value;
  
          console.log("Saved Template ID from Form:", savedTemplateId); // Debugging
  
          if (savedTemplateId) {
            savedTemplateId = Number(savedTemplateId); // Convert to number for consistency
  
            // Check if this ID exists in the templates array
            const existsInTemplates = this.templates.some(
              (template) => template.id === savedTemplateId
            );
            if (existsInTemplates) {
              this.selectedTemplate = this.templates.find(
                (template) => template.id === savedTemplateId
              );
            } else {
              console.warn(
                `Saved Template ID (${savedTemplateId}) not found in API response. Using first template.`
              );
              this.selectedTemplate = this.templates[0];
            }
          } else {
            console.warn("No saved template found. Using first template.");
            this.selectedTemplate = this.templates[0];
          }
  
          // Update form controls with the selected template
          if (this.selectedTemplate) {
            this.updateCompanyInfo.patchValue({
              template_id: this.selectedTemplate.id,
              template_url: this.selectedTemplate.template_url,
            });
            this.updateCompanyInfoByAdmin.patchValue({
              template_id: this.selectedTemplate.id,
              template_url: this.selectedTemplate.template_url,
            });
          }
  
          console.log("Final Selected Template:", this.selectedTemplate); // Debugging
        }
      },
      (error) => {
        console.error("Error fetching templates:", error);
      }
    );
  }

  onTemplateChange(selectedTemplateId: string) {
    if (!selectedTemplateId) {
      console.error("Template ID is missing or invalid:", selectedTemplateId);
      return;
    }
  
    const templateIdNumber = Number(selectedTemplateId); // Convert to number
    console.log("Selected Template ID:", templateIdNumber); // Debugging
  
    // Find the selected template object from the array
    const foundTemplate = this.templates.find(
      (template) => template.id === templateIdNumber
    );
  
    if (!foundTemplate) {
      console.error("No matching template found for ID:", templateIdNumber);
      return;
    }
  
    this.selectedTemplate = foundTemplate; // Store the selected template object
  
    console.log("Selected Template:", this.selectedTemplate); // Debugging
  
    // Update form controls
    this.updateCompanyInfo.patchValue({ template_id: templateIdNumber });
    this.updateCompanyInfoByAdmin.patchValue({ template_id: templateIdNumber });
  
    console.log(
      "Updated Form Value:",
      this.updateCompanyInfo.value.template_id
    );
  }
  getAllActiveVatList() {
    this.spinner.show();
    this.service.getAllActiveVatList().subscribe({
      next: (res) => {
        this.vatInfoData = res;
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }

  setValue(userId: number) {
    const userToUpdate = this.companyInfoData;
    if (userToUpdate) {
      this.uploadImage?.get("id")?.setValue(userToUpdate.company_id);
      this.uploadImage?.get("file")?.setValue(this.currentImageUrl);
    }
  }

  executeFunctions(userId: number, uploadData: any) {
    this.getId(userId);
    this.openVerticallyCentered2(uploadData, userId);
    this.setValue(userId);
    const selectedProfile = this.companyInfoData;
    if (selectedProfile) {
      this.currentImageUrl = selectedProfile?.logo_url;
    }
  }

  executeFunctionsSignatureModal(userId: number, uploadSignature: any) {
    this.getId(userId);
    this.openVerticallyCentered2(uploadSignature, userId);
    this.setValue(userId);
    const selectedProfile = this.companyInfoData;
    if (selectedProfile) {
      this.currentImageUrl = selectedProfile?.seal_url;
    }
  }

  uploadFile() {
    const formData = new FormData();
    if (this.file != null) {
      formData.append("file", this.file, this.file.name);
    }
    this.spinner.show();
    this.service.uploadCompanyImage(this.id, formData).subscribe({
      next: (response) => {
        Swal.fire({
          icon: "success",
          title:
            this.curentLang === "ar"
              ? response.messageAr
              : this.curentLang === "en"
              ? response.messageEn
              : response.messageHe,
        });
        this.modalService.dismissAll();
        this.getCompanyInfoById();
        this.uploadImage.reset();
        this.spinner.hide();
        if (response.status == 400) {
          Swal.fire(response.message);
          this.spinner.hide();
        }
      },
      error: (error) => {
        Swal.fire(error.message);
        this.spinner.hide();
      },
    });
  }
  uploadSeal() {
    const formData = new FormData();
    if (this.file != null) {
      formData.append("file", this.file, this.file.name);
    }
    this.spinner.show();
    this.service.uploadCompanySeal(this.id, formData).subscribe({
      next: (response) => {
        Swal.fire({
          icon: "success",
          title:
            this.curentLang === "ar"
              ? response.messageAr
              : this.curentLang === "en"
              ? response.messageEn
              : response.messageHe,
        });
        this.modalService.dismissAll();
        this.getCompanyInfoById();
        this.uploadImage.reset();
        this.spinner.hide();
        if (response.status == 400) {
          Swal.fire(response.message);
          this.spinner.hide();
        }
      },
      error: (error) => {
        Swal.fire(error.message);
        this.spinner.hide();
      },
    });
  }

  onFileChanged(event: any) {
    this.file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.currentImageUrl = event.target.result;
        this.cdRef.detectChanges();
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  openVerticallyCentered2(uploadData: any, userId: any) {
    this.modalService.open(uploadData, { centered: true });
    userId = this.id;
  }

  getId(id: number) {
    this.id = id;
  }

  updateCompanyById(): void {
    console.log("Template ID from Form:", this.updateCompanyInfo.value.template_id);
  
    let model = {
      company_name_en: this.updateCompanyInfo.value.company_name_en,
      company_name_ar: this.updateCompanyInfo.value.company_name_ar,
      company_name_he: this.updateCompanyInfo.value.company_name_he,
      company_Building_number: this.updateCompanyInfo.value.company_Building_number,
      company_address_str: this.updateCompanyInfo.value.company_address_str,
      company_address_city: this.updateCompanyInfo.value.company_address_city,
      company_address_str_ar: this.updateCompanyInfo.value.company_address_str_ar,
      company_address_str_en: this.updateCompanyInfo.value.company_address_str_en,
      company_address_city_ar: this.updateCompanyInfo.value.company_address_city_ar,
      company_address_city_en: this.updateCompanyInfo.value.company_address_city_en,
      company_address_zip_code: this.updateCompanyInfo.value.company_address_zip_code,
      company_phone: this.updateCompanyInfo.value.company_phone,
      templateId: this.updateCompanyInfo.value.template_id, // Ensure this is included
  
      // ✅ Convert new lines to <br> before sending to API
      left_note: this.updateCompanyInfo.value.left_note 
        ? this.updateCompanyInfo.value.left_note.replace(/\n/g, '<br>') 
        : '',
  
      right_note: this.updateCompanyInfo.value.right_note 
        ? this.updateCompanyInfo.value.right_note.replace(/\n/g, '<br>') 
        : '',
  
      updated_by: Number(this.merchantId),
    };
  
    console.log("Sending model:", model); // Debugging log
  
    this.spinner.show();
    this.service.updateCompanyById(this.id, model).subscribe(
      (res) => {
        console.log("API Response:", res); // Debugging log
  
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title:
              this.curentLang === "ar"
                ? res.messageAr
                : this.curentLang === "en"
                ? res.messageEn
                : res.messageHe,
          });
          this.modalService.dismissAll();
          this.getCompanyInfoById();
        } else {
          this.handleErrorResponse(res);
        }
        this.spinner.hide();
      },
      (error) => {
        console.error("API Error:", error);
        Swal.fire({
          icon: "error",
          title: "An error occurred while updating the company.",
        });
        this.spinner.hide();
      }
    );
  }

  updateCompanyByAdminByCompanyId() {
    console.log(
      "Template ID from Form (Admin):",
      this.updateCompanyInfoByAdmin.value.template_id
    );

    if (
      !this.updateCompanyInfoByAdmin.value.company_name_he ||
      !this.updateCompanyInfoByAdmin.value.company_address_str ||
      !this.updateCompanyInfoByAdmin.value.company_Building_number ||
      !this.updateCompanyInfoByAdmin.value.company_address_city ||
      !this.updateCompanyInfoByAdmin.value.company_address_str_ar ||
      !this.updateCompanyInfoByAdmin.value.company_address_str_en ||
      !this.updateCompanyInfoByAdmin.value.company_address_city_ar ||
      !this.updateCompanyInfoByAdmin.value.company_address_city_en ||
      !this.updateCompanyInfoByAdmin.value.company_Building_number ||
      !this.updateCompanyInfoByAdmin.value.company_address_zip_code ||
      !this.updateCompanyInfoByAdmin.value.commercial_register ||
      !this.updateCompanyInfoByAdmin.value.tax_register
    ) {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يرجى ملء الحقول المطلوبة"
            : this.curentLang === "en"
            ? "Please fill out the required fields"
            : "אנא מלא את השדות הנדרשים",
      });

      this.updateCompanyInfoByAdmin.markAllAsTouched();
      this.validate = !this.validate;
      return;
    }

    const companyNameHe = this.updateCompanyInfoByAdmin.value.company_name_he;
    this.updateCompanyInfoByAdmin.patchValue({
      company_name_ar: companyNameHe,
      company_name_en: companyNameHe,
    });

    let model = {
      tax_register: this.updateCompanyInfoByAdmin.value.tax_register,
      commercial_register:
        this.updateCompanyInfoByAdmin.value.commercial_register,
      company_name_en: this.updateCompanyInfoByAdmin.value.company_name_en,
      company_name_ar: this.updateCompanyInfoByAdmin.value.company_name_ar,
      company_name_he: this.updateCompanyInfoByAdmin.value.company_name_he,
      company_phone: this.updateCompanyInfoByAdmin.value.company_phone,
      company_address_str:
        this.updateCompanyInfoByAdmin.value.company_address_str,
      company_address_str_ar:
        this.updateCompanyInfoByAdmin.value.company_address_str_ar,
      company_address_str_en:
        this.updateCompanyInfoByAdmin.value.company_address_str_en,
      company_Building_number:
        this.updateCompanyInfoByAdmin.value.company_Building_number,
      company_address_city:
        this.updateCompanyInfoByAdmin.value.company_address_city,
      company_address_city_ar:
        this.updateCompanyInfoByAdmin.value.company_address_city_ar,
      company_address_city_en:
        this.updateCompanyInfoByAdmin.value.company_address_city_en,
      company_address_zip_code:
        this.updateCompanyInfoByAdmin.value.company_address_zip_code,
      templateId: this.updateCompanyInfoByAdmin.value.template_id, // Ensure this is included
      updated_by: Number(this.userRegistId),
    };

    console.log("Sending model (Admin):", model); // Debugging log

    this.spinner.show();
    this.service.updateCompanyByAdminByCompanyId(this.id, model).subscribe(
      (res) => {
        console.log("API Response (Admin):", res); // Debugging log

        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title:
              this.curentLang === "ar"
                ? res.messageAr
                : this.curentLang === "en"
                ? res.messageEn
                : res.messageHe,
          });
          this.modalService.dismissAll();
          this.getCompanyInfoById();
        } else {
          this.handleErrorResponse(res);
        }
        this.spinner.hide();
      },
      (error) => {
        console.error("API Error (Admin):", error);
        Swal.fire({
          icon: "error",
          title: "An error occurred while updating the company.",
        });
        this.spinner.hide();
      }
    );
  }

  // Common function to handle error responses
  handleErrorResponse(res: any) {
    let errorMessage =
      this.curentLang === "ar"
        ? "حدث خطأ غير معروف"
        : this.curentLang === "en"
        ? "An unknown error occurred"
        : "אירעה שגיאה לא ידועה";
    if (res.messageAr || res.messageEn || res.messageHe) {
      errorMessage =
        this.curentLang === "ar"
          ? res.messageAr
          : this.curentLang === "en"
          ? res.messageEn
          : res.messageHe;
    }

    Swal.fire({ icon: "error", title: errorMessage });
  }

  isNumber(event: KeyboardEvent): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}
