import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Customer, CustomerEvent } from './alldocuments-customers.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomersService } from './customers.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alldocuments-customers',
  templateUrl: './alldocuments-customers.component.html',
  styleUrls: ['./alldocuments-customers.component.scss']
})
export class AlldocumentsCustomersComponent implements OnInit, OnDestroy {
  @Input() selectedCustomer: any = null;
  @Input() placeholder: string = 'allDocuments.filters.customers.placeholder';
  @Input() loadingPlaceholder: string = 'allDocuments.filters.customers.loading';
  @Input() currentLang: string = 'en';

  @Output() customerChange = new EventEmitter<CustomerEvent>();
  @Output() customersLoaded = new EventEmitter<Customer[]>();
  
  customers: Customer[] = [];
  filteredCustomers: Customer[] = [];
  customersLoading: boolean = true;
  
  private destroy$ = new Subject<void>();

  constructor(
    private customersService: CustomersService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    const currentLang = localStorage.getItem('currentLang') || this.currentLang;

    // Fixed: Update to match the actual CustomersService API
    this.customersLoading = true;
    this.customersService.getCustomers()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (customers) => {
          this.customers = customers;
          this.filteredCustomers = [...customers];
          this.customersLoading = false;
          this.customersLoaded.emit(customers); // Emit the loaded customers
        },
        error: (error) => {
          console.error('Error loading customers:', error);
          this.customersLoading = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCustomerChange(event: any): void {
    this.customerChange.emit({
      value: event.value,
      originalEvent: event.originalEvent
    });
  }

  onFilter(event: any): void {
    this.filteredCustomers = this.customersService.filterCustomers(
      this.customers, 
      event.filter
    );
  }
}
