import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  TemplateRef,
  Input,
} from "@angular/core";
import { Table } from "primeng/table";
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { HttpHelpService } from "../../service/http-help.service";
import * as $ from "jquery";
import "bootstrap-daterangepicker";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import Swal from "sweetalert2";
import DOMPurify from "dompurify";
import { environment } from "src/environments/environment";
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

interface DateRange {
  start: Date | null;
  end: Date | null;
}

interface DocumentFilters {
  generalSearch: string;
  documentType: string;
  documentStatus: string;
  dateRange: DateRange;
  period: string;
  companyId: string | null;
  customerName: string | null;
}

interface DocumentData {
  createdDate: Date;
  documentType: string;
  documentStatus: string;
  documentNumber: string;
  confirmationNumber: string;
  documentDate: Date;
  companyName: string;
  customerName: string;
  paymentAmount: number;
  isConfirmed: boolean;
  hasRefNumber: boolean;
}

@Component({
  selector: "app-merchant-documents",
  templateUrl: "./merchant-documents.component.html",
  styleUrls: ["./merchant-documents.component.scss"],
})
export class MerchantDocumentsComponent implements OnInit, AfterViewInit {
  @Input() isInsideModal: boolean = false; // Receives value from parent

  @ViewChild("dt") table!: Table;
  @ViewChild("content4") content4: TemplateRef<any>;
  invoiceId: any;
  invoiceInfoData: any = [];
  selectedInvoiceUuid: string | null = null;

  rowsPerPage: number = 20;
  totalRecords: number = 0;
  MerchantNameEn: any;
  documents: any[] = [];
  DocumentNumber: any;
  loading: boolean = true;
  curentLang: string = "";
  merchantId: any;
  user_type: any;
  filteredDocuments: DocumentData[] = [];
  searchTerm: string = "";
  CopyVersion: any;
  rangeDates: Date[] = [];
  PdfbaseUrl: string = "";
  isSkelton: boolean = true;

  filteredCompanies: any[] = [];
  Confirm: any;
  CompanyVat: any;
  filteredCustomers: any[] = [];
  documentHtmlContent: string = "";
  PdfLink: string = "";
  pdfUrl: SafeResourceUrl = "";
  invoiceHtmlContent: any;

  pdfInvoiceId: any;
  rawPdfUrl: string = "";
  curentLangDocument: string = this.curentLang; 
  availableLanguages = [
    { code: "en", name: "English" },
    { code: "ar", name: "Arabic" },
    { code: "he", name: "Hebrew" },
  ];
  documentTypes = [
    {
      code: "",
      name:
        this.curentLang === "en"
          ? "--All--"
          : this.curentLang === "ar"
          ? "-- الكل --"
          : "-- כל הסוגים --",
    },
    { code: "305", name: "Tax Invoice" },
    { code: "320", name: "Invoice With Receipt" },
    { code: "332", name: "Financial Request" },
    { code: "400", name: "Receipt" },
    { code: "330", name: "Reverse Invoice" },
    { code: "100", name: "Order" },
    { code: "200", name: "Mission" },
    { code: "000", name: "Offer Price" },
  ];

  documentStatuses = [
    {
      code: "",
      name:
        this.curentLang === "en"
          ? "--All--"
          : this.curentLang === "ar"
          ? "-- الكل --"
          : "-- כל הסטטוסים --",
    },
    { code: "confirmed", name: "Confirmed" },
    { code: "cancelled", name: "Cancelled" },
    { code: "saved", name: "Saved" },
  ];

  periods = [
    { code: "current_month", name: "Current Month" },
    { code: "last_month", name: "Last Month" },
    { code: "last_2_months", name: "Last 2 Months" },
    { code: "start_of_year", name: "Start of Year" },
    { code: "last_year", name: "Last Year" },
    { code: "last_2_years", name: "Last 2 Years" }, 

  ];

  filters: DocumentFilters = {
    generalSearch: "",
    documentType: "",
    documentStatus: "",
    dateRange: { start: null, end: null },
    period: "last_2_years",
    companyId: null,
    customerName: null,
  };

  sortField: string = "createdDate";
  sortOrder: number = 0;

  datatableActions: MenuItem[] | undefined;
  hideCompanyColumn: boolean = false; 
  currentPage: number = 0;

  constructor(
    private merchantDocumentsService: HttpHelpService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private service: HttpHelpService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.curentLang = localStorage.getItem("curentLang") || "en";
    this.user_type = JSON.parse(localStorage.getItem("user_type") || "{}");
    this.merchantId = JSON.parse(localStorage.getItem("merchant_id") || "{}");
    this.loadFiltersFromLocalStorage(); 
    this.translate.use(this.curentLang);
  }
  openXl2(content2: any) {
    this.modalService.open(content2, { size: 'lg' });
  }

  isCreateRefNumberVisible(rowData: any): boolean {
    return rowData.isConfirmed && !rowData.hasRefNumber;
  }

  ngOnInit() {
    this.translate.get('datatableActions').subscribe((translation: any) => {
      this.datatableActions = [
        {
          label: translation.editDocument,
          command: (event) => {
            const parent = event.originalEvent.target.closest('.datatable__action');
            this.router.navigate([`/dashboard/update-invoice/${parent.dataset.invoiceuuid}`], { queryParams: { returnUrl: '/dashboard/documents' } });
          }
        },
        {
          label: translation.viewDocument,
          command: (event) => {
            const parent = event.originalEvent.target.closest('.datatable__action');
            this.openPdfInModal(parent.dataset.link);
          }
        },
        {
          label: translation.cancelDocument,
          command: (event) => {
            const parent = event.originalEvent.target.closest('.datatable__action');
            this.cancelDocument(Number(parent.dataset.id));
          },
        },
        {
          label: translation.createRefNumber,
          command: (event) => {
            const parent = event.originalEvent.target.closest('.datatable__action');
            const invoiceUuid = parent.dataset.id;
            const documentnumber = parent.dataset.documentnumber;
            this.createRefNumberForInvoice(invoiceUuid, documentnumber);
          }
        }
      ];
    });
    this.loadCompanies();
    this.getCustomers();
    this.setDefaultDateRange();
    // this.filteredDocuments = [...this.documents];
    // this.loadDocuments({ first: 0, rows: this.rowsPerPage });
    this.translateDocumentTypes();
    this.translateDocumentStatuses();
    this.translatePeriods();
    // this.getInvoiceId("some-valid-invoice-id");
    // this.clearFilters();
  }

  loadFiltersFromLocalStorage() {
    const savedFilters = localStorage.getItem('documentFilters');
    if (savedFilters) {
      this.filters = JSON.parse(savedFilters);
      this.rangeDates = [
        new Date(this.filters.dateRange.start),
        new Date(this.filters.dateRange.end),
      ];
    }
  }

  saveFiltersToLocalStorage() {
    localStorage.setItem('documentFilters', JSON.stringify(this.filters));
  }

  createRefNumberForInvoice(invoice_uuid: string, documentnumber: string): void {
    this.translate.get(['createRefNumber.confirmTitle', 'createRefNumber.confirmText', 'createRefNumber.confirmButton', 'createRefNumber.cancelButton']).subscribe(translations => {
      Swal.fire({
        icon: 'info',
        title: translations['createRefNumber.confirmTitle'],
        text: translations['createRefNumber.confirmText'].replace('XXX', documentnumber),
        showCancelButton: true,
        confirmButtonText: translations['createRefNumber.confirmButton'],
        cancelButtonText: translations['createRefNumber.cancelButton']
      }).then((result) => {
        if (result.isConfirmed) {
          this.merchantDocumentsService.createRefNumber(invoice_uuid).subscribe({
            next: (response: any) => {
              this.translate.get(response.status === 200 ? 'createRefNumber.successMessage' : 'createRefNumber.errorMessage').subscribe(message => {
                Swal.fire({
                  icon: response.status === 200 ? "success" : "error",
                  title: message
                });
              });
              if (response.status === 200) {
                this.loadDocuments({ first: 0, rows: this.rowsPerPage });
              }
            },
            error: (error: any) => {
              console.error(`Error creating reference number for invoice: ${invoice_uuid}`, error);
            },
          });
        }
      });
    });
  }

  openPdfInModal(pdfLink: string) {
    if (pdfLink) {
      this.PdfLink = pdfLink;
      this.rawPdfUrl = pdfLink;
      console.log("rawPdfUrl set to:", this.rawPdfUrl);
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(pdfLink);
      console.log("Opening Modal with PDF:", pdfLink);
      console.log("Sanitized PDF URL:", this.pdfUrl);

      setTimeout(() => {
        this.modalService.open(this.content4, { size: "lg" });
      });
    } else {
      console.error("Invalid PDF link");
    }
  }

  openLink(url: string) {
    if (url) {
      window.open(url, "_blank");
    } else {
      console.error("Invalid URL");
    }
  }
  downloadOriginalDocPdfFromLink() {
    fetch(this.PdfLink)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `Document-${this.pdfInvoiceId || "Document"}.pdf`;
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title:
            this.curentLang === "ar"
              ? "حدث خطأ أثناء التنزيل"
              : this.curentLang === "en"
              ? "An error occurred during download"
              : "אירעה שגיאה במהלך ההורדה",
          text: error.message,
        });
      });
  }

  openExportPdfHtmlModal(row: any, content: any) {
    const invoiceId = row.invoice_id;
    const lang = this.curentLang;

    this.service.exportPdfHtml(invoiceId, lang).subscribe({
      next: (blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          let htmlContent = reader.result as string;

          // تطهير المحتوى
          htmlContent = DOMPurify.sanitize(htmlContent);

          // تحديث الروابط
          const parser = new DOMParser();
          const doc = parser.parseFromString(htmlContent, "text/html");
          doc.querySelectorAll("img, link").forEach((element) => {
            const attr = element.tagName === "IMG" ? "src" : "href";
            const url = element.getAttribute(attr);
            if (url && !url.startsWith("http")) {
              element.setAttribute(attr, `https://base-url.com/${url}`);
            }
          });

          this.invoiceHtmlContent = doc.documentElement.outerHTML;
        };
        reader.readAsText(blob);
      },
      error: (err) => {
        console.error("Failed to fetch invoice HTML:", err);
      },
    });
  }
  openPdfModal(row: any, content: any) {
    // إذا لم يكن هناك رابط pdf_link، قم بتشغيل الهيكل العظمي
    if (!row.pdf_link) {
      this.openExportPdfHtmlModal(row, content);

      // تكوين الرابط النهائي
      this.PdfbaseUrl = environment.PdfbaseUrl;
      const merchantId = JSON.parse(localStorage.getItem("merchant_id"));
      const companyId = row.company_id;
      const invoiceType = row.invoice_type;
      const invoiceUuid = row.invoice_uuid;

      // تكوين رابط PDF النهائي مع سلسلة استعلام ديناميكية
      const timestamp = new Date().getTime(); // طابع زمني فريد
      const generatedPdfLink = `${this.PdfbaseUrl}/merchant_${merchantId}/company_${companyId}/invoices/${invoiceType}/${invoiceUuid}.pdf?t=${timestamp}`;

      this.rawPdfUrl = generatedPdfLink;

      this.pdfUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(generatedPdfLink);

      // إيقاف عرض الهيكل العظمي بعد ثانيتين وعرض الرابط
      setTimeout(() => {
        this.isSkelton = false;
      }, 4000);
    } else {
      // إذا كان pdf_link موجود، استخدم الرابط مباشرةً مع سلسلة استعلام ديناميكية
      const timestamp = new Date().getTime(); // طابع زمني فريد
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        `${row.pdf_link}?t=${timestamp}`
      );
      this.rawPdfUrl = `${row.pdf_link}?t=${timestamp}`;

      this.isSkelton = false;
    }

    // فتح المودال
    this.modalService.open(content, { size: "xl" });
  }
  downloadDocumentPdf() {
    
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
      color: 'green',
      backdrop: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
    Toast.fire({
      icon: 'success',
      title: this.curentLang === 'ar' ? 'جاري الآن تجهيز الملف' : (this.curentLang === 'en' ? 'The file is now being prepared' : 'הקובץ נמצא כעת בהכנה')
    });

    this.service.downloadDocumentPdf(this.pdfInvoiceId, this.curentLangDocument).subscribe({
      next: res => {
        // this.spinner.hide();
   
        
        // إنشاء رابط لتحميل الـ PDF
        const blob = new Blob([res], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `Invoice-${this.pdfInvoiceId}.pdf`; // تحديد اسم الملف
        link.click();
  
        // إطلاق الـ URL لتجنب أي تسرب في الذاكرة
        window.URL.revokeObjectURL(url);
        this.getPdfByInvoiceId();
      },
      error: error => {
        // this.spinner.hide();
        
      }
    });
  }
  copyLinkToClipboard(link: string) {
    const tempInput = document.createElement("input");
    document.body.appendChild(tempInput);
    tempInput.value = link;
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    const copyMessage = document.getElementById("copy-message");
    if (copyMessage) {
      copyMessage.style.display = "block";
      setTimeout(() => {
        copyMessage.style.display = "none";
      }, 3000);
    }
  }

  //working up


  getPdfByInvoiceId() {

    this.service.getPdfByInvoiceId(this.invoiceId).subscribe({
      next: res => {
        this.invoiceInfoData = res;
         
       this.PdfLink = this.invoiceInfoData.pdf_link
        
        this.Confirm = this.invoiceInfoData?.confirm;
        this.curentLangDocument = this.invoiceInfoData?.document_language;
       this.CopyVersion =  this.invoiceInfoData?.copy_version;
       this.pdfInvoiceId  = this.invoiceInfoData?.invoice_id;

         this.DocumentNumber = this.invoiceInfoData?.document_number;
        this.CompanyVat = this.invoiceInfoData?.company_vat;
        this.MerchantNameEn = this.invoiceInfoData?.merchant?.merchant_name_en;

      },
      error: error => {
      }
    });
  }

  getInvoiceId(id: any) {
    console.log("Setting invoice ID:", id); // Log the invoice ID being set
    this.invoiceId = id;
    this.getPdfByInvoiceId();
    this.selectedInvoiceUuid = id; // تعيين الصف النشط
  }

  // downloadTranslatedDocumentPdf() {
  //   const Toast = Swal.mixin({
  //     toast: true,
  //     position: "top",
  //     showConfirmButton: false,
  //     timer: 4000,
  //     timerProgressBar: true,
  //     color: "green",
  //     backdrop: true,
  //     didOpen: (toast) => {
  //       toast.addEventListener("mouseenter", Swal.stopTimer);
  //       toast.addEventListener("mouseleave", Swal.resumeTimer);
  //     },
  //   });

  //   Toast.fire({
  //     icon: "success",
  //     title:
  //       this.curentLang === "ar"
  //         ? "جاري الآن تجهيز الملف"
  //         : this.curentLang === "en"
  //         ? "The file is now being prepared"
  //         : "הקובץ נמצא כעת בהכנה",
  //   });

  //   this.merchantDocumentsService
  //     .downloadDocumentPdf(this.pdfInvoiceId, this.curentLangDocument)
  //     .subscribe({
  //       next: (res: Blob) => {
  //         const blob = new Blob([res], { type: "application/pdf" });
  //         const url = window.URL.createObjectURL(blob);
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.download = `Document-${this.pdfInvoiceId}.pdf`;
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);
  //         window.URL.revokeObjectURL(url);
  //       },
  //       error: (error) => {
  //         Swal.fire({
  //           icon: "error",
  //           title:
  //             this.curentLang === "ar"
  //               ? "حدث خطأ أثناء التنزيل"
  //               : this.curentLang === "en"
  //               ? "An error occurred during download"
  //               : "אירעה שגיאה במהלך ההורדה",
  //           text: error.message || "Unknown error",
  //         });
  //       },
  //     });
  // }

  filterDocumentLanguage(event: any) {
    this.curentLangDocument = event;
  }

  //working down
  translateDocumentTypes() {
    this.documentTypes = this.documentTypes.map((docType) => ({
      ...docType,
      name:
        docType.code === ""
          ? this.curentLang === "en"
            ? "Select Document Type"
            : this.curentLang === "ar"
              ? "اختر نوع المستند"
              : "בחר סוג מסמך"
          : this.getTranslatedDocumentType(docType.code),
    }));
  }

  translateDocumentStatuses() {
    this.translate.get('documentStatuses').subscribe((translations: any) => {
      this.documentStatuses = this.documentStatuses.map((status) => ({
        ...status,
        name:
          status.code === ""
            ? translations.selectStatus
            : translations[status.code] || status.name,
      }));
    });
  }

  translatePeriods() {
    this.periods = this.periods.map((period) => ({
      ...period,
      name: this.getTranslatedPeriod(period.code),
    }));
  }
  getTranslatedDocumentStatus(status: string): string {
    switch (this.curentLang) {
      case "ar":
        return status === "Not cancelled" ? "غير ملغاة" : "ملغاة";
      case "he":
        return status === "Not cancelled" ? "לא בוטל" : "בוטל";
      default:
        return status;
    }
  }

  getTranslatedPeriod(period: string): string {
    const periodMap: { [key: string]: { en: string; ar: string; he: string } } = {
      current_month: {
        en: "Current Month",
        ar: "الشهر الحالي",
        he: "חודש נוכחי",
      },
      last_month: { en: "Last Month", ar: "الشهر الماضي", he: "חודש שעבר" },
      last_2_months: {
        en: "Last 2 Months",
        ar: "آخر شهرين",
        he: "שני החודשים האחרונים",
      },
      start_of_year: {
        en: "Start of Year",
        ar: "بداية السنة",
        he: "תחילת השנה",
      },
      last_year: { en: "Last Year", ar: "السنة الماضية", he: "שנה שעברה" },
      last_2_years: { // Add this entry
        en: "Last 2 Years",
        ar: "اخر سنتين",
        he: "שנתיים אחרונים",
      },
    };
    return periodMap[period][this.curentLang] || periodMap[period].en;
  }
  ngAfterViewInit() {
    this.initializeDateRangePicker();
  }
  setDefaultDateRange() {
    const today = new Date();
    const start = new Date(today.getFullYear() - 2, 0, 1);
    const end = new Date(today.getFullYear(), 11, 31);
    this.rangeDates = [start, end];
    this.filters.dateRange = {
      start: start,
      end: end,
    };
  }
  
  initializeDateRangePicker() {
    const self = this;
    const start = this.rangeDates[0];
    const end = this.rangeDates[1];
  
    $("#daterange").daterangepicker(
      {
        autoUpdateInput: false, // Prevent automatic input updates
        startDate: start,
        endDate: end,
        locale: {
          cancelLabel: "Clear",
          format: "DD/MM/YYYY",
        },
      },
      function (start, end) {
        if (start.isSame(end, "day")) {
          end = start.clone().add(1, "day"); // Ensure end date is different
        }
        self.rangeDates = [start.toDate(), end.toDate()];
        self.filters.dateRange = { start: start.toDate(), end: end.toDate() };
        self.applyFilters();
        $("#daterange").val(
          start.format("DD/MM/YYYY") + " - " + end.format("DD/MM/YYYY")
        );
      }
    );
  
    $("#daterange").on("cancel.daterangepicker", function () {
      $(this).val(""); // Clear input
      self.rangeDates = [];
      self.filters.dateRange = { start: null, end: null };
      self.applyFilters();
    });
  
    // Set the initial value of the date range picker
    $("#daterange").val(
      `${this.formatDateToYYYYMMDD(start)} - ${this.formatDateToYYYYMMDD(end)}`
    );
  }

  cancelDocument(id: number): void {
    this.translate.get('datatableActions').subscribe((translation: any) => {
      const document = this.documents.find(doc => doc.invoice_id === id);
      const documentNumber = document ? document.document_number : 'N/A';

      Swal.fire({
        title: translation.cancelDocumentTitle,
        text: translation.cancelDocumentText.replace('XXX', documentNumber),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: translation.cancelDocumentConfirm,
        cancelButtonText: translation.cancelDocumentCancel,
      }).then((result) => {
        if (result.isConfirmed) {
          this.merchantDocumentsService.convertTheInvoiceToReverseInvoice(id).subscribe({
            next: (response: any) => {
              Swal.fire({
                icon: response.status === 200 ? "success" : "error",
                title:
                  this.curentLang === "ar"
                    ? response.messageAr
                    : this.curentLang === "en"
                      ? response.messageEn
                      : response.messageHe
              });
              if (response.status === 200) {
                this.loadDocuments({ first: 0, rows: this.rowsPerPage });
              }
            },
            error: (error: any) => {
              console.error(`Error canceling document:${id}`, error);
              this.filteredCompanies = [];
            },
          });
        }
      });
    });
  }

  loadCompanies(): void {
    this.merchantDocumentsService.GetCompany().subscribe({
      next: (response: any) => {
        if (response && Array.isArray(response)) {
          this.filteredCompanies = [
            {
              name:
                this.curentLang === "en"
                  ? "Select Company"
                  : this.curentLang === "ar"
                    ? "اختر الشركة"
                    : "בחר חברה",
              code: null,
            },
            ...response.map((company: any) => ({
              name: company.company_name_en?.trim(),
              code: company.company_id ?? null,
            })),
          ];

          // Check if there is only one unique company
          const uniqueCompanies = new Set(this.filteredCompanies.map(company => company.code));
          this.hideCompanyColumn = uniqueCompanies.size <= 1;
        } else {
          console.warn("Unexpected response format:", response);
          this.filteredCompanies = [];
        }
      },
      error: (error: any) => {
        console.error("Error fetching companies:", error);
        this.filteredCompanies = [];
      },
    });
  }

  getCustomers(): void {
    this.merchantDocumentsService.getCustomersdrop().subscribe({
      next: (data: any) => {
        if (data && Array.isArray(data.customers)) {
          console.log("Raw Customers Data:", data.customers); // Log the raw customers data
          this.filteredCustomers = [
            {
              name:
                this.curentLang === "en"
                  ? "Select Customer"
                  : this.curentLang === "ar"
                  ? "اختر العميل"
                  : "בחר לקוח",
              code: null,
            },
            ...data.customers.map((customer: any) => ({
              name:
                customer[`customer_customer_name_${this.curentLang}`]?.trim() ??
                "Unknown",
              code: customer.customer_customer_id ?? null, // Ensure the customer ID is saved
            })),
          ];
          console.log("Filtered Customers:", this.filteredCustomers); // Log the filtered customers
        } else {
          console.warn("Unexpected response format:", data);
          this.filteredCustomers = [];
        }
      },
      error: (error: any) => {
        console.error("Error fetching customers:", error);
        this.filteredCustomers = [];
      },
    });
  }
  formatDateToYYYYMMDD(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }



  updateDateRangeByPeriod() {
    const today = new Date();
    let start: Date;
    let end: Date;
  
    switch (this.filters.period) {
      case "current_month":
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      case "last_month":
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        end = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case "last_2_months":
        start = new Date(today.getFullYear(), today.getMonth() - 2, 1);
        end = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case "start_of_year":
        start = new Date(today.getFullYear(), 0, 1);
        end = today;
        break;
      case "last_year":
        start = new Date(today.getFullYear() - 1, 0, 1);
        end = new Date(today.getFullYear() - 1, 11, 31);
        break;
      case "last_2_years":
        start = new Date(today.getFullYear() - 1, 0, 1);
        end = new Date(today.getFullYear(), 11, 31);
        break;
      default:
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    }
  
    this.rangeDates = [start, end];
    this.filters.dateRange = { start, end };
  
    $("#daterange").val(
      `${this.formatDateToYYYYMMDD(start)} - ${this.formatDateToYYYYMMDD(end)}`
    );
  
    $("#daterange").data("daterangepicker").setStartDate(start);
    $("#daterange").data("daterangepicker").setEndDate(end);
  
    this.saveFiltersToLocalStorage(); 
    this.loadDocuments({ first: 0, rows: this.rowsPerPage });
}
  applyGeneralSearch() {
    if (!this.searchTerm.trim()) {
      this.filteredDocuments = [...this.documents];
      this.loadDocuments({ first: 0, rows: this.rowsPerPage }); // Reload if empty
      return;
    }

    const searchLower = this.normalizeSearchTerm(this.searchTerm.toLowerCase());

    this.filteredDocuments = this.documents.filter((doc) =>
      Object.values(doc).some((value) =>
        value
          ? this.normalizeSearchTerm(value.toString().toLowerCase()).includes(
              searchLower
            )
          : false
      )
    );
    this.saveFiltersToLocalStorage(); 
  }

  normalizeSearchTerm(text: string): string {
    if (!text) return "";

    // Normalize Arabic characters and remove diacritics
    const arabicNormalizationMap: { [key: string]: string } = {
      أ: "ا",
      إ: "ا",
      آ: "ا",
      ة: "ه",
      ى: "ي",
    };

    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
      .replace(/[أإآ]/g, "ا") // Normalize Alef variations
      .replace(/ة/g, "ه") // Convert Taa Marbuta to Haa
      .replace(/ى/g, "ي") // Convert Maqsura to Ya
      .replace(/[\u064B-\u065F]/g, ""); // Remove Arabic diacritics
  }

  onPageChange(event: any) {
    this.rowsPerPage = event.rows;
    this.currentPage = event.page;
    this.loadDocuments(event);
  }
  applyFilters() {
    console.log(
      "Applying filters with customer ID:",
      this.filters.customerName
    );
    this.saveFiltersToLocalStorage(); 
    this.loadDocuments({ first: 0, rows: this.rowsPerPage });
  }

  loadDocuments(event: any, fromDate: string = "", toDate: string = "") {
    this.loading = true;
  
    const page = event.first / event.rows;
    const size = event.rows;
    const sortField = event.sortField || this.sortField;
    const sortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  
    // Map sortField to sortIndex
    const sortFieldMap: { [key: string]: number } = {
      createdDate: 0,
      documentType: 1,
      documentStatus: 2,
      documentNumber: 3,
      confirmationNumber: 4,
      documentDate: 5,
      companyName: 6,
      customerName: 7,
      paymentAmount: 8,
    };
  
    const sortIndex =
      sortFieldMap[sortField] !== undefined ? sortFieldMap[sortField] : 0;
  
    // Map documentStatus to API values using keys
    const statusMap: { [key: string]: number } = {
      saved: 0,
      confirmed: 1,
      cancelled: 2,
    };
  
    const documentStatusValue = this.filters.documentStatus;

    const mappedStatus =
      documentStatusValue && statusMap[documentStatusValue] !== undefined
        ? statusMap[documentStatusValue]
        : null;

    const filterParams: any = {
      fromDate:
        fromDate ||
        (this.filters.dateRange?.start
          ? this.formatDateToYYYYMMDD(this.filters.dateRange.start)
          : ""),
      toDate:
        toDate ||
        (this.filters.dateRange?.end
          ? this.formatDateToYYYYMMDD(this.filters.dateRange.end)
          : ""),
      companyId: this.filters.companyId,
      invoice_type: this.filters.documentType,
      merchant_id: this.merchantId, // Always send merchant_id
      customer_id: this.filters.customerName, // Send customer_id if available
      searchKeyword: this.filters.generalSearch,
      status: mappedStatus,
      limit: size,
      offset: page * size,
      sortIndex: sortIndex,
      sort: sortOrder
    };

    console.log("Filter Params:", filterParams); // Log the filter params
  
    this.merchantDocumentsService.getFilteredDocuments(filterParams).subscribe(
      (response) => {
        if (response && response.data) {
          this.documents = response.data.map((doc) => ({
            ...doc,
            createdDate: new Date(doc.createdDate),
            paymentAmount: Number(doc.paymentAmount) || 0,
            documentType: doc.documentType ? doc.documentType.toString() : "",
            invoiceNumber: doc.invoiceNumber
              ? doc.invoiceNumber.toString()
              : "",
            isConfirmed: doc.confirm === 1,
            hasRefNumber: doc.confirmation_number !== null,
          }));
  
          this.filteredDocuments = [...this.documents]; // Update filtered data
          this.totalRecords = response.total;
        } else {
          this.documents = [];
          this.filteredDocuments = [];
          this.totalRecords = 0;
        }
  
        this.loading = false;
      },
      (error) => {
        console.error("Filtering API Error:", error);
        this.handleError(error);
        this.loading = false;
      }
    );
  }
  showFilters() {
    document.querySelector('#filterRow').classList.toggle('d-none');
  }
  clearFilters() {
    const today = new Date();
    const start = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    const end = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    this.searchTerm = ""; // Reset the search term
    this.filteredDocuments = [...this.documents]; // Reset filtered documents

    this.filters = {
      generalSearch: "",
      documentType: "",
      documentStatus: "",
      dateRange: { start, end },
      period: "last_2_years",
      companyId: null,
      customerName: null,
    };

    $("#daterange").val(
      `${this.formatDateToYYYYMMDD(start)} - ${this.formatDateToYYYYMMDD(end)}`
    );

    $("#daterange").data("daterangepicker").setStartDate(start);
    $("#daterange").data("daterangepicker").setEndDate(end);

    const fromDate = this.formatDateToYYYYMMDD(start);
    const toDate = this.formatDateToYYYYMMDD(end);

    this.saveFiltersToLocalStorage(); 
    this.loadDocuments({ first: 0, rows: this.rowsPerPage, fromDate, toDate });
  }

  onGeneralSearch() {
    this.table.reset();
    this.loadDocuments({ first: 0, rows: this.rowsPerPage });
  }
  hasActiveFilters(): boolean {
    return !!(
      this.filters.generalSearch ||
      this.filters.documentType ||
      this.filters.documentStatus ||
      this.filters.dateRange.start ||
      this.filters.dateRange.end ||
      this.filters.companyId ||
      this.filters.customerName
    );
  }

  handleDocumentsResponse(response: any) {
    if (response && response.data && Array.isArray(response.data)) {
      this.documents = response.data;
      this.filteredDocuments = this.documents;
      this.totalRecords = response.count || 0;
    } else {
      this.documents = [];
      this.filteredDocuments = [];
      this.totalRecords = 0;
    }
    this.loading = false;
  }

  handleError(error: any) {
    console.error("Error fetching documents:", error);
    this.documents = [];
    this.totalRecords = 0;
    this.loading = false;
  }

  getSeverity(status: string): string {
    switch (status) {
      case "Not cancelled":
        return "success";
      case "Cancelled":
        return "danger";
      default:
        return "info";
    }
  }

  getTranslatedDocumentType(type: string): string {
    switch (this.curentLang) {
      case "ar":
        return this.getArabicDocumentType(type);
      case "he":
        return this.getHebrewDocumentType(type);
      default:
        return this.getEnglishDocumentType(type);
    }
  }

  private getEnglishDocumentType(type: string): string {
    const typeMap: { [key: string]: string } = {
      "305": "Tax Invoice",
      "320": "Invoice With Receipt",
      "332": "Financial Request",
      "400": "Receipt",
      "330": "Reverse Invoice",
      "100": "Order",
      "200": "Mission",
      "000": "Offer Price",
    };
    return typeMap[type] || type;
  }

  private getArabicDocumentType(type: string): string {
    const typeMap: { [key: string]: string } = {
      "305": "فاتورة ضريبية",
      "320": "فاتورة مع ايصال",
      "332": "مطالبة مالية",
      "400": "ايصال",
      "330": "فاتورة عكسية",
      "100": "طلبية",
      "200": "مهمة",
      "000": "عرض سعر",
    };
    return typeMap[type] || type;
  }

  private getHebrewDocumentType(type: string): string {
    const typeMap: { [key: string]: string } = {
      "305": "חשבונית מס",
      "320": "חשבונית עם קבלה",
      "332": "בקשה כספית",
      "400": "קבלה",
      "330": "חשבונית הפוכה",
      "100": "הזמנה",
      "200": "משימה",
      "000": "הצעת מחיר",
    };
    return typeMap[type] || type;
  }

  getFilteredActions(doc: any): MenuItem[] {
    if (!this.datatableActions) {
      return [];
    }
    return this.datatableActions.filter(action => {
      if (action.label === this.translate.instant('datatableActions.viewDocument') && doc.confirm === 0) {
        return false;
      }
      if (action.label === this.translate.instant('datatableActions.editDocument') && doc.confirm !== 0) {
        return false;
      }
      if (action.label === this.translate.instant('datatableActions.createRefNumber') && (doc.confirm === 0 || doc.is_deleted === 1 || doc.hasRefNumber)) {
        return false;
      }
      if (action.label === this.translate.instant('datatableActions.cancelDocument') && doc.is_deleted === 1) {
        return false;
      }
      return true;
    });
  }

  rowAction(doc: any) {
    if (doc.confirm == 0) {
      this.router.navigate([`/dashboard/update-invoice/${doc.invoice_uuid}`], { queryParams: { returnUrl: '/dashboard/documents' } });
    } else {
      this.openPdfInModal(doc.pdf_link);
    }
  }

  exportReportToPdf() {
    this.loading = true;

    const page = this.currentPage;
    const size = this.rowsPerPage;
    const sortField = this.sortField;
    const sortOrder = this.sortOrder === 1 ? "ASC" : "DESC";

    // Map sortField to sortIndex
    const sortFieldMap: { [key: string]: number } = {
      createdDate: 0,
      documentType: 1,
      documentStatus: 2,
      documentNumber: 3,
      confirmationNumber: 4,
      documentDate: 5,
      companyName: 6,
      customerName: 7,
      paymentAmount: 8,
    };

    const sortIndex =
      sortFieldMap[sortField] !== undefined ? sortFieldMap[sortField] : 0;

    // Map documentStatus to API values using keys
    const statusMap: { [key: string]: number } = {
      confirmed: 0,
      cancelled: 1,
      saved: 2,
    };

    const documentStatusValue = this.filters.documentStatus;

    const mappedStatus =
      documentStatusValue && statusMap[documentStatusValue] !== undefined
        ? statusMap[documentStatusValue]
        : null;

    const confirm = mappedStatus === null ? null : mappedStatus === 2 ? 0 : 1;

    const filterParams: any = {
      fromDate:
        this.filters.dateRange?.start
          ? this.formatDateToYYYYMMDD(this.filters.dateRange.start)
          : "",
      toDate:
        this.filters.dateRange?.end
          ? this.formatDateToYYYYMMDD(this.filters.dateRange.end)
          : "",
      companyId: this.filters.companyId,
      invoice_type: this.filters.documentType,
      customer_id: this.filters.customerName,
      searchKeyword: this.filters.generalSearch,
      status: mappedStatus === 2 ? null : mappedStatus,
      limit: size,
      offset: page * size,
      sortIndex: sortIndex,
      sort: sortOrder,
      confirm: confirm,
      lang: this.curentLang,
    };

    console.log("Filter Params:", filterParams); // Log the filter params

    this.merchantDocumentsService.exportReportToPdf(filterParams).subscribe({
      next: (blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'report.pdf';
        link.click();
        window.URL.revokeObjectURL(url);

        this.loading = false;
      },
      error: (error) => {
        console.error('Error exporting report:', error);
        this.loading = false;
      }
    });
  }
}
