import { Injectable } from '@angular/core';
import { DocumentType } from './alldocuments-types.model';
import { DOCUMENT_TYPES } from './alldocuments-types.data';

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeService {
  getDocumentTypes(): Promise<DocumentType[]> {
    const currentLang = localStorage.getItem('currentLang') || 'en';
    return new Promise((resolve) => {
      setTimeout(() => {
        // Assuming DOCUMENT_TYPES is an object with language keys
        resolve([...DOCUMENT_TYPES]);
      }, 1000); // 1 second timeout
    });
  }

  isTypeSelected(selectedType: DocumentType | null, type: DocumentType): boolean {
    if (!selectedType || !type) return false;
    return selectedType.code === type.code;
  }
}
