<app-breadcrumb
  [title]="
    curentLang === 'en'
      ? 'Add Invoice'
      : curentLang === 'ar'
      ? ' اضافة فاتورة '
      : '    הוספת חשובנית מס'
  "
  [items]="
    curentLang === 'en'
      ? ['Dashboard']
      : curentLang === 'ar'
      ? ['لوحة التحكم']
      : ['  לוח הבקרה']
  "
  [active_item]="
    curentLang === 'en'
      ? 'Add Invoice'
      : curentLang === 'ar'
      ? ' اضافة فاتورة '
      : '      הוספת חשובנית מס  '
  "
>
</app-breadcrumb>

<!--Add Invoice -->

<div class="container-fluid basic_table">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-block row">
          <div class="col-sm-12 col-lg-12 col-xl-12">
            <div>
              <form [formGroup]="invoiceform">
                <div class="row">
                  <div class="col-lg-12 row">
                    <div class="col-md-5 d-none d-md-block">
                      <div class="form-group mx-2">
                        <label class="mt-2">
                          {{
                            curentLang === "en"
                              ? "Company Name"
                              : curentLang === "ar"
                              ? "اسم الشركة"
                              : "שם החברה"
                          }}
                        </label>
                        <span class="text-danger">*</span>

                        <!--
                                            <input class="form-control w-100" type="text" name="search" formControlName="company_id"
                                                [(ngModel)]="selectedCompany" readonly />
                                            -->

                        <ng-select
                          formControlName="company_id"
                          (change)="getCompanyId($event); getCompanyInfoById()"
                          [disabled]="mastercompany?.length === 1"
                          style="cursor: pointer"
                          placeholder="{{
                            curentLang === 'en'
                              ? 'Select'
                              : curentLang === 'ar'
                              ? 'اختر'
                              : 'בחר'
                          }}"
                        >
                          <ng-option
                            *ngFor="let item of mastercompany; let i = index"
                            [value]="item.company_id"
                          >
                            {{
                              curentLang === "en"
                                ? item.company_name_en
                                : curentLang === "ar"
                                ? item.company_name_ar
                                : item.company_name_he
                            }}
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="form-group mx-2">
                        <!-- يظهر في الشاشات الكبيرة فقط -->
                        <div class="d-none d-lg-block">
                          <label class="mt-2">
                            {{
                              curentLang === "en"
                                ? "Select Customer Name"
                                : curentLang === "ar"
                                ? "اختر اسم العميل"
                                : "לקוח       "
                            }}
                          </label>
                          <span class="text-danger">*</span>
                          <ng-select
                            formControlName="customer_id"
                            (change)="getCustomerById()"
                            style="cursor: pointer"
                            placeholder="{{
                              curentLang === 'en'
                                ? 'Select'
                                : curentLang === 'ar'
                                ? 'اختر'
                                : 'בחר'
                            }}"
                          >
                            <ng-option
                              *ngFor="let item of mastercustomer; let i = index"
                              [value]="item.customer_id"
                            >
                              {{
                                curentLang === "en"
                                  ? item.customer_name_en
                                  : curentLang === "ar"
                                  ? item.customer_name_ar
                                  : item.customer_name_he
                              }}
                            </ng-option>
                          </ng-select>
                        </div>

                        <!-- يظهر في الشاشات الصغيرة فقط -->
                        <div class="col-md-5 mobile-only">
                          <div class="form-group mx-2">
                            <label class="mt-2">
                              {{ curentLang === 'en' ? 'Select Company Name' : (curentLang === 'ar' ? 'اختر اسم الشركة' : 'שם החברה ')}}
                            </label>
                            <span class="text-danger">*</span>
                            <ng-select formControlName="company_id" 
                                       [disabled]="mastercompany?.length === 1"
                                       style="cursor: pointer;" 
                                       placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                              <ng-option *ngFor="let item of mastercompany" [value]="item.company_id">
                                {{ curentLang === 'en' ? item.company_name_en : (curentLang === 'ar' ? item.company_name_ar : item.company_name_he) }}
                              </ng-option>
                            </ng-select>
                          </div>
                        </div>
                        <div class="d-lg-none">
                          <label class="mt-1">
                            {{
                              curentLang === "en"
                                ? "Choose Customer "
                                : curentLang === "ar"
                                ? " اختر العميل "
                                : " בחר את הלקוח   "
                            }}
                            <span class="text-danger">*</span>
                          </label>

                          <label
                            class="select-customer-label mt-1"
                            (click)="
                              openChooseCustomerModal(chooseCustomerModal)
                            "
                          >
                            {{
                              customerNameSelected
                                ? customerNameSelected
                                : curentLang === "en"
                                ? "Select Customer Name"
                                : curentLang === "ar"
                                ? "اختر اسم العميل"
                                : "לקוח"
                            }}
                          </label>
                        </div>

                        <!-- المودال الخاص بالشاشات الصغيرة -->
                        <ng-template #chooseCustomerModal let-modal>
                          <div
                            class="modal-header row d-flex justify-content-between"
                          >
                            <div class="col-md-12">
                              <h6
                                class="modal-title row d-flex justify-content-between"
                                style="color: black"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Choose Customer "
                                    : curentLang === "ar"
                                    ? " اختر العميل "
                                    : " בחר את הלקוח   "
                                }}

                                <button
                                  type="button"
                                  class="close btn-close"
                                  style="color: black"
                                  aria-label="Close"
                                  (click)="modal.dismiss('Cross click')"
                                ></button>
                              </h6>

                              <div
                                class="modal-body xl:flex xl:justify-content-center"
                              >
                                <div
                                  class="d-flex justify-content-between align-items-center mb-1 bg-light p-2"
                                >
                                  <!-- <h4 style="font-size: 18px; color: black;">
                                                                            {{ curentLang === 'en' ? 'List of Customers' : (curentLang === 'ar' ? 'قائمة العملاء' : 'רשימת לקוחות') }}
                                                                        </h4> -->
                                  <button
                                    [hidden]="
                                      user_type == 200 || user_type == 201
                                    "
                                    class="btn text-center"
                                    style="
                                      background-color: #141414;
                                      color: #fff;
                                    "
                                    (click)="openXl(content)"
                                  >
                                    <i
                                      class="fa fa-plus-circle mx-1"
                                      style="
                                        font-size: 14px;
                                        cursor: pointer;
                                        color: #fff;
                                      "
                                    ></i>
                                  </button>
                                </div>

                                <p-orderList
                                  [value]="mastercustomer"
                                  [filterBy]="
                                    'customer_name_en,customer_name_ar,customer_name_he,id_number'
                                  "
                                  formControlName="customer_id"
                                  [showButtons]="true"
                                  [responsive]="true"
                                  [sourceStyle]="{ height: '30rem' }"
                                  [targetStyle]="{ height: '30rem' }"
                                  filterPlaceholder="{{
                                    curentLang === 'en'
                                      ? 'Filter by name or ID'
                                      : curentLang === 'ar'
                                      ? 'ابحث بالاسم أو برقم الهوية'
                                      : 'סינון לפי שם או מספר תעודה'
                                  }}"
                                  [listStyle]="{ height: '25rem' }"
                                  header=""
                                  (onReorder)="getCustomerById($event)"
                                  (onSelectionChange)="onCustomerSelect($event)"
                                >
                                  <ng-template let-customer pTemplate="item">
                                    <div
                                      class="flex flex-wrap align-items-center customer-item"
                                    >
                                      <div class="flex-1 flex flex-column">
                                        <span class="" style="font-size: 14px">
                                          <i
                                            class="fa fa-user text-sm mx-1"
                                          ></i>
                                          {{
                                            curentLang === "en"
                                              ? customer.customer_name_en
                                              : curentLang === "ar"
                                              ? customer.customer_name_ar
                                              : customer.customer_name_he
                                          }}
                                          <br class="m-0 p-0" />
                                          <i
                                            class="fa fa-id-card text-sm mx-1"
                                          ></i>
                                          {{ customer.id_number }}
                                          <hr
                                            style="color: #141414"
                                            class="m-0 p-0"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </ng-template>
                                </p-orderList>
                              </div>
                            </div>
                            <hr style="color: gray" class="py-2" />
                            <!---->
                          </div>
                        </ng-template>
                      </div>
                    </div>

                    <div class="col-md-2" style="padding-top: 42px">
                      <!-- يظهر في الشاشات الكبيرة فقط -->
                      <div class="d-none d-lg-block">
                        <button
                          [hidden]="user_type == 200 || user_type == 201"
                          class="btn m-1"
                          style="background-color: #141414; color: #fff"
                          (click)="openXl(content)"
                        >
                          <i class="fa fa-plus-circle mx-1"></i>
                          {{
                            curentLang === "en"
                              ? "Add Customer"
                              : curentLang === "ar"
                              ? "  اضافة عميل"
                              : "   הוספת לקוח "
                          }}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 row">
                    <div class="col-lg-4">
                      <div class="col-lg-12">
                        <div class="form-group mx-2">
                          <label class="mt-1">
                            {{
                              curentLang === "en"
                                ? "Invoice Date"
                                : curentLang === "ar"
                                ? "تاريخ الفاتورة"
                                : "  תאריך "
                            }}</label
                          >
                          <input
                            type="date"
                            (change)="
                              filter($event);
                              getVatPercentageByVatListIdAndDate()
                            "
                            formControlName="invoice_date"
                            class="form-control w-100"
                          />

                          <!-- <p-calendar 
                                                 showIcon="true"    style="display: block; width: 100%;"
                                                 (ngModelChange)="filter($event);getVatPercentageByVatListIdAndDate()"
                                                    formControlName="invoice_date" 
                                                    dateFormat="dd/mm/yy"
                                                     placeholder="DD/MM/YYYY"
                                                      >
                                                    </p-calendar>
                                                     -->
                        </div>
                      </div>

                      <hr style="color: gray" class="py-1" />
                    </div>

                    <div class="col-lg-4" *ngIf="showDetails">
                      <div class="form-group mx-2">
                        <label class="mt-2">
                          {{
                            curentLang === "en"
                              ? "Vat Type"
                              : curentLang === "ar"
                              ? "نوع الضريبة"
                              : 'סוג המע"מ  '
                          }}</label
                        >
                        <span class="text-danger">*</span>
                        <ng-select
                          formControlName="vat_list_id"
                          (change)="getVatPercentageByVatListIdAndDate()"
                          style="cursor: pointer"
                          placeholder="{{
                            curentLang === 'en'
                              ? 'Select'
                              : curentLang === 'ar'
                              ? 'اختر'
                              : 'בחר'
                          }}"
                        >
                          <ng-option
                            *ngFor="let item of vatTypeList; let i = index"
                            [value]="item?.vat_list_id"
                          >
                            {{
                              curentLang === "en"
                                ? item.vat_name_en
                                : curentLang === "ar"
                                ? item.vat_name_ar
                                : item.vat_name_he
                            }}
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>

                    <div class="col-lg-4" *ngIf="showDetails">
                      <div class="form-group mx-2">
                        <label class="mt-2">
                          {{
                            curentLang === "en"
                              ? "Document Language"
                              : curentLang === "ar"
                              ? "لغة المستند  "
                              : "  שפת מסמך"
                          }}</label
                        >
                        <select
                          formControlName="document_language"
                          class="form-control form-select"
                        >
                          <option
                            *ngFor="let lang of availableLanguages"
                            [ngValue]="lang.code"
                            [selected]="lang.code === 'he'"
                          >
                            {{ lang.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 pt-2">
                    <button
                      (click)="toggleDetails()"
                      class="btn btn-link"
                      style="
                        color: #141414;
                        font-size: 14px;
                        font-weight: bolder;
                      "
                    >
                      <span>
                        {{
                          showDetails
                            ? curentLang === "en"
                              ? "Hide Details"
                              : curentLang === "ar"
                              ? "إخفاء البيانات"
                              : "הסתר נתונים"
                            : curentLang === "en"
                            ? "Additional Information"
                            : curentLang === "ar"
                            ? "معلومات إضافية"
                            : "מידע נוסף"
                        }}
                      </span>
                    </button>
                  </div>
                </div>
                <br />

                <div class="row">
                  <div class="col-lg-12">
                    <div class="table-responsive">
                      <table
                        class="table table-bordered d-none d-lg-table"
                        formArrayName="lines"
                      >
                        <thead>
                          <tr>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Product Name"
                                  : curentLang === "ar"
                                  ? "اسم المنتج"
                                  : "שם מוצר "
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Product Desc"
                                  : curentLang === "ar"
                                  ? "وصف المنتج"
                                  : " תיאור מוצר"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Price per unit"
                                  : curentLang === "ar"
                                  ? "سعر الوحدة"
                                  : "מחיר יחידה  "
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Quantity"
                                  : curentLang === "ar"
                                  ? "الكمية"
                                  : "כמות"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Discount"
                                  : curentLang === "ar"
                                  ? "خصم"
                                  : "הנחה"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Vat Amount"
                                  : curentLang === "ar"
                                  ? "قيمة الضريبة"
                                  : ' מע"מ'
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Total Amount"
                                  : curentLang === "ar"
                                  ? "المجموع"
                                  : '  סה"כ'
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Remove"
                                  : curentLang === "ar"
                                  ? "حذف"
                                  : "מחק"
                              }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of invproducts.controls; let i = index" [formGroupName]="i">
                            <td style="width: 20%">
                              <ng-select formControlName="product_id" (change)="productchange(i)" style="cursor: pointer">
                                <ng-option *ngFor="let prod of masterproduct" [value]="prod.product_id">
                                  {{ curentLang === 'en' ? prod.product_name_en : curentLang === 'ar' ? prod.product_name_ar : prod.product_name_he }}
                                </ng-option>
                              </ng-select>
                            </td>
                            <td style="width: 20%">
                              <textarea maxlength="250" cols="10" rows="1" class="form-control form-control-sm" formControlName="description"></textarea>
                            </td>
                            <td style="width: 10%">
                              <input class="form-control" type="text" appNumberFormat (change)="Itemcalculation(i)" formControlName="price_per_unit" />
                            </td>
                            <td style="width: 9%">
                              <input class="form-control" type="text" appNumberFormat (change)="Itemcalculation(i)" formControlName="quantity" />
                            </td>
                            <td style="width: 10%">
                              <input class="form-control" type="text" appNumberFormat (change)="Itemcalculation(i)" formControlName="discount" />
                            </td>
                            <td style="width: 11%">
                              <input
                                class="form-control"
                                type="text"
                                appNumberFormat
                                formControlName="vat_amount"
                                [readonly]="true"
                              />
                            </td>
                            <td style="width: 13%">
                              <input class="form-control" type="text" appNumberFormat formControlName="total_amount" (change)="onTotalAmountChange(i)" />
                            </td>
                            <td style="width: 5%">
                              <i (click)="Removeproduct(i)" class="fa fa-trash" style="font-size: 22px; cursor: pointer; color: red;"></i>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="6">
                              <button
                                type="button"
                                class="btn m-2"
                                style="background-color: #141414; color: #fff"
                                (click)="addnewproduct()"
                              >
                                <i
                                  class="fa fa-plus-circle mx-1"
                                  style="
                                    font-size: 14px;
                                    cursor: pointer;
                                    color: #fff;
                                  "
                                ></i>
                                {{
                                  curentLang === "en"
                                    ? "Select Product"
                                    : curentLang === "ar"
                                    ? "اختيار منتج"
                                    : "בחר מוצר  "
                                }}
                              </button>

                              <button
                                [hidden]="user_type == 200"
                                type="button"
                                class="btn m-2"
                                style="background-color: #141414; color: #fff"
                                (click)="openXl2(content2)"
                              >
                                <i
                                  class="fa fa-plus-circle mx-1"
                                  style="
                                    font-size: 14px;
                                    cursor: pointer;
                                    color: #fff;
                                  "
                                ></i>
                                {{
                                  curentLang === "en"
                                    ? "Add Product"
                                    : curentLang === "ar"
                                    ? "اضافة منتج"
                                    : "הוספת פריט   "
                                }}
                              </button>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>

                    <!-- Mobile layout for smaller screens -->

                    <div class="d-lg-none" formArrayName="lines">
                      <div
                        *ngFor="let item of invproducts.controls; let i = index"
                        [formGroupName]="i"
                        class="product-card mx-2"
                        (click)="openChooseProductModal2(chooseProductModal2, i)"
                      >
                        <div class="product-header d-flex align-items-center">
                          <button
                            (click)="Removeproduct(i)"
                            class="btn btn-transparent"
                            style="margin-right: auto"
                          >
                            <i
                              class="fa fa-trash trash-icon"
                              style="
                                font-size: 20px;
                                color: red;
                                background-color: #fff;
                                border-radius: 20%;
                                padding: 5px;
                                cursor: pointer;
                              "
                            >
                            </i>
                          </button>
                          <!-- <h4 (click)="toggleCollapse(i)" class="collapse-toggle">
                          {{ curentLang === 'en' ? 'Product Details' : (curentLang === 'ar' ? 'تفاصيل المنتج' : 'פרטי מוצר') }}
                          <i [class]="collapsedIndexes[i] ? 'fa fa-chevron-down' : 'fa fa-chevron-up'" class="px-2"></i>
                      </h4> -->
                        </div>
                        <!-- <h6 style="color: #141414;">
                        {{ selectedProductNames[i] || 'No product selected' }}
                    </h6> -->
                        <div class="form-group">
                          <!-- <label>{{
                            curentLang === "en"
                              ? "Product Name"
                              : curentLang === "ar"
                              ? "المنتج"
                              : "מוצר"
                          }}</label> -->
                          <span class="select-product-input" (click)="openChooseProductModal2(chooseProductModal2, i)">
                            {{
                              selectedProductNames[i] ||
                                (curentLang === "en"
                                  ? "Select Product"
                                  : curentLang === "ar"
                                  ? "اختر المنتج"
                                  : "בחר מוצר")
                            }}
                          </span>

                          <!-- <ng-select
                            formControlName="product_id"
                            (change)="productchange(i)"
                          >
                            <ng-option
                              *ngFor="let item of masterproduct; let i = index"
                              [value]="item.product_id"
                            >
                              {{
                                curentLang === "en"
                                  ? item.product_name_en
                                  : curentLang === "ar"
                                  ? item.product_name_ar
                                  : item.product_name_he
                              }}
                            </ng-option>
                          </ng-select> -->
                        </div>
                        <!-- <h6 style="color: #141414;">
                        {{ selectedProductPrice[i] }}
                    </h6> -->

                        <div class="product-grid">
                          <!-- Product Name -->
                          <!-- <div class="form-group">
                            <label>{{ curentLang === 'en' ? 'Product Name' : (curentLang === 'ar' ? 'اسم المنتج' : 'שם מוצר') }}</label>
                            <ng-select formControlName="product_id" (change)="productchange(i)">
                                <ng-option *ngFor="let item of masterproduct; let i = index" [value]="item.product_id">
                                    {{ curentLang === 'en' ? item.product_name_en : (curentLang === 'ar' ? item.product_name_ar : item.product_name_he) }}
                                </ng-option>
                            </ng-select>
                        </div> -->

                          <!-- Product Description -->
                          <!-- <div class="form-group">
                            <label>{{
                              curentLang === "en"
                                ? "Product Desc"
                                : curentLang === "ar"
                                ? "وصف المنتج"
                                : "תיאור מוצר"
                            }}</label>
                            <textarea
                              maxlength="250"
                              class="form-control"
                              formControlName="description"
                            ></textarea>
                          </div> -->

                          <!-- Price Per Unit -->
                          <div class="form-container">
                            <!-- Including VAT (Now Properly Aligned)
                            <div class="vat-group">
                              <label style="white-space: nowrap; font-size: 14px">
                                <input type="checkbox" disabled checked />
                                {{
                                  curentLang === "en"
                                    ? "Including VAT"
                                    : curentLang === "ar"
                                    ? "شامل ض.م"
                                    : curentLang === "he"
                                    ? 'כולל מע"מ'
                                    : "Including VAT"
                                }}
                              </label>
                            </div> -->
                            <!-- Price per unit -->
                            <div class="form-group2">
                              <label class="custom-label">
                                {{
                                  curentLang === "en"
                                    ? "Price per unit:"
                                    : curentLang === "ar"
                                    ? "سعر الوحدة:"
                                    : "מחיר יחידה:"
                                }}
                                <span style="color: red;" *ngIf="invoiceform.get('lines')?.at(i)?.get('isVatIncluded')?.value">
                                  {{
                                    curentLang === "en"
                                      ? "(with VAT)"
                                      : curentLang === "ar"
                                      ? "(شامل)"
                                      : "(כולל)"
                                  }}
                                </span>
                              </label>
                              <span class="form-control-plaintext">
                                {{
                                  invoiceform
                                    .get("lines")
                                    .controls[i].get("price_per_unit").value
                                    | number : "1.2-2"
                                }}
                              </span>
                            </div>
                            <div class="form-group2">
                              <label class="custom-label">
                                {{
                                  curentLang === "en"
                                    ? "Quantity:"
                                    : curentLang === "ar"
                                    ? "الكمية:"
                                    : "כמות:"
                                }}
                              </label>
                              <span class="form-control-plaintext">
                                {{
                                  invoiceform
                                    .get("lines")
                                    .controls[i].get("quantity").value
                                    | number : "1.2-2"
                                }}
                              </span>
                            </div>
                            <div class="form-group2">
                              <label class="custom-label">
                                {{
                                  curentLang === "en"
                                    ? "Discount:"
                                    : curentLang === "ar"
                                    ? "خصم:"
                                    : "הנחה:"
                                }}
                              </label>
                              <span class="form-control-plaintext">
                                {{
                                  invoiceform
                                    .get("lines")
                                    .controls[i].get("discount").value
                                    | number : "1.2-2"
                                }}
                              </span>
                            </div>

                          </div>

                          <!-- Quantity
                          <div class="form-group2">
                            <label class="custom-label">{{
                              curentLang === "en"
                                ? "Quantity"
                                : curentLang === "ar"
                                ? "الكمية"
                                : "כמות"
                            }}</label>
                            <input
                              class="form-control"
                              type="number"
                              min="0"
                              (change)="Itemcalculation(i)"
                              formControlName="quantity"
                            />
                          </div> -->

                          <!-- before vaaaaaaat -->
                          <!-- <div class="form-group2">
  <label class="custom-label">
    {{
      curentLang === "en"
        ? "Amount Before Discount"
        : curentLang === "ar"
        ? "المبلغ قبل الخصم"
        : "מחיר לפני הנחה"
    }}
  </label>
  <span class="form-control-plaintext">
    {{ invoiceform.get('lines').controls[i].get('amount_before_discount').value }}
  </span>
</div> -->
                          <!-- after vaaaaaat -->

                          <!-- VAT -->
                          <!-- <div class="form-group2">
                            <label class="custom-label">
                              {{
                                curentLang === "en"
                                  ? "VAT Amount"
                                  : curentLang === "ar"
                                  ? "قيمة الضريبة"
                                  : 'מע"מ'
                              }}
                            </label>
                            <input
                              class="form-control"
                              formControlName="vat_amount"
                              readonly
                            />
                          </div> -->
                          <div class="form-container2">
                            <div class="form-group2">
                              <label class="custom-label">
                                {{
                                  curentLang === "en"
                                    ? "Amount Before VAT:"
                                    : curentLang === "ar"
                                    ? "المبلغ قبل الضريبة:"
                                    : 'סכום לפני מע"מ:'
                                }}
                              </label>
                              <span class="form-control-plaintext">
                                {{
                                  invoiceform
                                    .get("lines")
                                    .controls[i].get("sub_total").value
                                    | number : "1.2-2"
                                }}
                              </span>
                            </div>
                            <div class="form-group2">
                              <label class="custom-label">
                                {{
                                  curentLang === "en"
                                    ? "VAT Amount:"
                                    : curentLang === "ar"
                                    ? "قيمة الضريبة:"
                                    : 'מע"מ:'
                                }}
                              </label>
                              <span class="form-control-plaintext">
                                {{
                                  invoiceform
                                    .get("lines")
                                    .controls[i].get("vat_amount").value
                                    | number : "1.2-2"
                                }}
                              </span>
                            </div>
                            <!-- Total Amount -->
                          </div>
                          <div class="form-group2">
                            <label class="custom-label">
                              {{
                                curentLang === "en"
                                  ? "Total Amount After Tax:"
                                  : curentLang === "ar"
                                  ? "المبلغ بعد الضريبة:"
                                  : 'סה"כ כולל מע"מ'
                              }}
                            </label>
                            <span class="form-control-plaintext">
                              {{
                                invoiceform
                                  .get("lines")
                                  .controls[i].get("total_amount").value
                                  | number : "1.2-2"
                                }}
                            </span>
                          </div>
                          <div
                            class="form-container2"
                            *ngIf="
                              invoiceform
                                .get('lines')
                                .controls[i].get('description').value
                            "
                          >
                            <div class="form-group2">
                              <label class="custom-label">
                                {{
                                  curentLang === "en"
                                    ? "Product Desc:"
                                    : curentLang === "ar"
                                    ? "وصف المنتج:"
                                    : "תיאור מוצר:"
                                }}
                              </label>
                              <span
                              class="form-control-plaintext"
                              style="display: block; text-align: right; word-wrap: break-word;"
                            >
                              {{
                                invoiceform
                                  .get("lines")
                                  .controls[i].get("description").value
                              }}
                            </span>
                            </div>
                          </div>

                          <!-- <div class="form-group">
                            <label>{{
                              curentLang === "en"
                                ? "Total Amount"
                                : curentLang === "ar"
                                ? "المجموع"
                                : 'סה"כ'
                            }}</label>
                            <input
                              class="form-control"
                              type="number"
                              min="0"
                              formControlName="total_amount"
                              (change)="onTotalAmountChange(i)"
                              readonly
                              disabled
                            />
                          </div> -->
                        </div>
                      </div>

                      <!-- Add button for small screens -->
                      <ng-template #chooseProductModal2 let-modal>
                        <div class="modal-header row d-flex justify-content-between">
                          <div class="col-md-12">
                            <div class="modal-title row d-flex justify-content-between align-items-start px-3" style="color: black">
                              <h6 class="mb-0 w-50">
                                {{
                                  curentLang === "en"
                                    ? "Choose Product "
                                    : curentLang === "ar"
                                    ? " اختر المنتج "
                                    : "בחר את המוצר"
                                }}
                              </h6>
                              <button type="button" class="btn-close align-self-start" aria-label="Close" (click)="closeAllModals()"></button>
                            </div>
                            <div class="modal-body xl:flex xl:justify-content-center">
                              <div class="d-flex justify-content-between align-items-center mb-1 p-2" style="background-color: #fcfcfc">
                                <button [hidden]="user_type == 200 || user_type == 201" class="btn text-center" style="background-color: #141414; color: #fff" (click)="closeAllModals()">
                                  <i class="fa fa-save mx-1" style="font-size: 14px; cursor: pointer; color: #fff"></i>
                                  {{
                                    curentLang === "en"
                                      ? "Save and Back"
                                      : curentLang === "ar"
                                      ? "حفظ وعودة"
                                      : " שמור"
                                  }}
                                </button>
                              </div>
                              <ng-container *ngFor="let item of invproducts.controls; let i = index">
                                <div [formGroupName]="i" class="product-card mx-2" *ngIf="i === selectedProductIndex">
                                  <div class="product-header">
                                    <!-- Product header content here -->
                                  </div>
                                  <div class="form-group">
                                    <label>{{
                                      curentLang === "en"
                                        ? "Product Name"
                                        : curentLang === "ar"
                                        ? "المنتج"
                                        : "מוצר"
                                    }}</label>
                                    <span class="select-customer-label" (click)="Removeproduct(invproducts.controls.length - 1)">
                                      {{
                                        selectedProductNames[i] ||
                                          (curentLang === "en"
                                            ? "Select Product"
                                            : curentLang === "ar"
                                            ? "اختر المنتج"
                                            : "בחר מוצר")
                                      }}
                                    </span>
                                  </div>
                                  <div class="product-grid">
                                    <div class="form-group">
                                      <label>{{
                                        curentLang === "en"
                                          ? "Product Desc"
                                          : curentLang === "ar"
                                          ? "وصف المنتج"
                                          : "תיאור מוצר"
                                      }}</label>
                                      <textarea maxlength="250" class="form-control" formControlName="description"></textarea>
                                    </div>
                                    <div class="form-group">
                                      <label>{{
                                        curentLang === "en"
                                          ? "Price per unit"
                                          : curentLang === "ar"
                                          ? "سعر الوحدة"
                                          : "מחיר יחידה"
                                      }}</label>
                                      <div style="display: flex; align-items: center; gap: 10px;">
                                        <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="price_per_unit" style="flex: 9" />
                                        <label style="flex: 1; white-space: pre-line; font-size: 14px;">
                                          <input
                                            type="checkbox"
                                            (change)="onVatCheckboxChange(i, $event)"
                                            [checked]="invoiceform.get('lines')?.at(i)?.get('isVatIncluded')?.value"
                                          />
                                          {{
                                            curentLang === "en"
                                              ? "Including\nVAT"
                                              : curentLang === "ar"
                                              ? "يشمل\nقيمه\nالضريبه"
                                              : 'כולל\nמע"מ'
                                          }}
                                        </label>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <label>{{
                                        curentLang === "en"
                                          ? "Quantity"
                                          : curentLang === "ar"
                                          ? "الكمية"
                                          : "כמות"
                                      }}</label>
                                      <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="quantity" />
                                    </div>
                                    <div class="form-group">
                                      <label>{{
                                        curentLang === "en"
                                          ? "Discount"
                                          : curentLang === "ar"
                                          ? "خصم"
                                          : "הנחה"
                                      }}</label>
                                      <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="discount" />
                                    </div>
                                    <div class="form-group">
                                      <label>{{
                                        curentLang === "en"
                                          ? "Total Amount"
                                          : curentLang === "ar"
                                          ? "المجموع"
                                          : 'סה"כ'
                                      }}</label>
                                      <input class="form-control" type="number" min="0" formControlName="total_amount" (change)="onTotalAmountChange(i)" />
                                    </div>
                                    <div class="form-group">
                                      <label>{{
                                        curentLang === "en"
                                          ? "VAT Amount"
                                          : curentLang === "ar"
                                          ? "قيمة الضريبة"
                                          : 'מע"מ'
                                      }}</label>
                                      <input class="form-control" formControlName="vat_amount" [readonly]="true" />                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                            <hr style="color: gray" class="py-2" />
                          </div>
                        </div>
                      </ng-template>
                      
                      <div class="text-center mt-1">
                        <ng-template #chooseProductModal let-modal>
                          <div
                            class="modal-header row d-flex justify-content-between"
                          >
                            <div class="col-md-12">
                              <div
                                class="modal-title row d-flex justify-content-between align-items-start px-3"
                                style="color: black"
                              >
                                <h6 class="mb-0 w-50">
                                  {{
                                    curentLang === "en"
                                      ? " "
                                      : curentLang === "ar"
                                      ? " اختر المنتج "
                                      : "בחר את המוצר"
                                  }}
                                </h6>
                                <button
                                  type="button"
                                  class="btn-close align-self-start"
                                  aria-label="Close"
                                  (click)="
                                    Removeproduct(
                                      invproducts.controls.length - 1
                                    );
                                    closeAllModals()
                                  "
                                ></button>
                              </div>
                              <div
                                class="modal-body xl:flex xl:justify-content-center"
                              >
                                <div
                                  class="d-flex justify-content-between align-items-center mb-1 p-2"
                                  style="background-color: #fcfcfc"
                                >
                                  <button
                                    [hidden]="
                                      user_type == 200 || user_type == 201
                                    "
                                    class="btn text-center"
                                    style="
                                      background-color: #141414;
                                      color: #fff;
                                    "
                                    (click)="closeAllModals()"
                                  >
                                    <i
                                      class="fa fa-save mx-1"
                                      style="
                                        font-size: 14px;
                                        cursor: pointer;
                                        color: #fff;
                                      "
                                    ></i>
                                    {{
                                      curentLang === "en"
                                        ? "Save and Back"
                                        : curentLang === "ar"
                                        ? "حفظ وعودة"
                                        : " שמור"
                                    }}
                                  </button>
                                </div>
                                <ng-container
                                  *ngFor="
                                    let item of invproducts.controls;
                                    let i = index
                                  "
                                >
                                  <div
                                    [formGroupName]="i"
                                    class="product-card mx-2"
                                    *ngIf="
                                      i === invproducts.controls.length - 1
                                    "
                                  >
                                    <div class="product-header">
                                      <!-- Product header content here -->
                                    </div>
                                    <div class="form-group">
                                      <label>{{
                                        curentLang === "en"
                                          ? "Product Name"
                                          : curentLang === "ar"
                                          ? "المنتج"
                                          : "מוצר"
                                      }}</label>
                                      <span
                                        class="select-customer-label"
                                        (click)="
                                          Removeproduct(
                                            invproducts.controls.length - 1
                                          )
                                        "
                                      >
                                        {{
                                          selectedProductNames[i] ||
                                            (curentLang === "en"
                                              ? "Select Product"
                                              : curentLang === "ar"
                                              ? "اختر المنتج"
                                              : "בחר מוצר")
                                        }}
                                      </span>
                                    </div>
                                    <div class="product-grid">
                                      <div class="form-group">
                                        <label>{{
                                          curentLang === "en"
                                            ? "Product Desc"
                                            : curentLang === "ar"
                                            ? "وصف المنتج"
                                            : "תיאור מוצר"
                                        }}</label>
                                        <textarea
                                          maxlength="250"
                                          class="form-control"
                                          formControlName="description"
                                        ></textarea>
                                      </div>
                                      <div class="form-group">
                                        <label>{{
                                          curentLang === "en"
                                            ? "Price per unit"
                                            : curentLang === "ar"
                                            ? "سعر الوحدة"
                                            : "מחיר יחידה"
                                        }}</label>
                                        <div
                                          style="
                                            display: flex;
                                            align-items: center;
                                            gap: 10px;
                                          "
                                        >
                                        <input
  class="form-control"
  type="number"
  min="0"
  (change)="Itemcalculation(i)"
  formControlName="price_per_unit"
  style="flex: 9"
/>
<label
  style="
    flex: 1;
    white-space: pre-line;
    font-size: 14px;
  "
>
  <input type="checkbox" (change)="onVatCheckboxChange(i, $event)" />
  {{
    curentLang === "en"
      ? "Including\nVAT"
      : curentLang === "ar"
      ? "يشمل\nقيمه\nالضريبه"
      : 'כולל\nמע"מ'
  }}
</label>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <label>{{
                                          curentLang === "en"
                                            ? "Quantity"
                                            : curentLang === "ar"
                                            ? "الكمية"
                                            : "כמות"
                                        }}</label>
                                        <input
                                          class="form-control"
                                          type="number"
                                          min="0"
                                          (change)="Itemcalculation(i)"
                                          formControlName="quantity"
                                        />
                                      </div>
                                      <div class="form-group">
                                        <label>{{
                                          curentLang === "en"
                                            ? "Discount"
                                            : curentLang === "ar"
                                            ? "خصم"
                                            : "הנחה"
                                        }}</label>
                                        <input
                                          class="form-control"
                                          type="number"
                                          min="0"
                                          (change)="Itemcalculation(i)"
                                          formControlName="discount"
                                        />
                                      </div>
                                      <div class="form-group">
                                        <label>{{
                                          curentLang === "en"
                                            ? "Total Amount"
                                            : curentLang === "ar"
                                            ? "المجموع"
                                            : 'סה"כ'
                                        }}</label>
                                        <input
                                          class="form-control"
                                          type="number"
                                          min="0"
                                          formControlName="total_amount"
                                          (change)="onTotalAmountChange(i)"
                                        />
                                      </div>
                                      <div class="form-group">
                                        <label>{{
                                          curentLang === "en"
                                            ? "VAT Amount"
                                            : curentLang === "ar"
                                            ? "قيمة الضريبة"
                                            : 'מע"מ'
                                        }}</label>
                                        <input
                                          class="form-control"
                                          formControlName="vat_amount"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                              <hr style="color: gray" class="py-2" />
                            </div>
                          </div>
                        </ng-template>

                        <style>
                          .responsive-button {
                            background-color: #141414;
                            color: #fff;
                            width: 450px;
                          }

                          @media (max-width: 768px) {
                            .responsive-button {
                              width: 90%;
                            }
                          }
                        </style>

                        <button
                          type="button"
                          class="btn my-2 mx-1 responsive-button"
                          (click)="
                            addProductWithValidation(chooseProductModalSelected)
                          "
                        >
                          <i
                            class="fa fa-plus-circle mx-1"
                            style="
                              font-size: 14px;
                              cursor: pointer;
                              color: #fff;
                            "
                          ></i>
                          {{
                            curentLang === "en"
                              ? "Add Product"
                              : curentLang === "ar"
                              ? "اضافة منتج"
                              : "הוספת פריט   "
                          }}
                        </button>

                        <ng-template #chooseProductModalSelected let-modal>
                          <div
                            class="modal-header row d-flex justify-content-between"
                          >
                            <div class="col-md-12">
                              <div
                                class="modal-title row d-flex justify-content-between align-items-start px-3"
                                style="color: black"
                              >
                                <h6 class="mb-0 w-50">
                                  {{
                                    curentLang === "en"
                                      ? "Choose Product "
                                      : curentLang === "ar"
                                      ? " اختر المنتج "
                                      : "בחר את המוצר"
                                  }}
                                </h6>
                                <button
                                  type="button"
                                  class="btn-close align-self-start"
                                  aria-label="Close"
                                  (click)="closeModal(modal)"
                                ></button>
                              </div>
                              <div
                                class="modal-body xl:flex xl:justify-content-center"
                              >
                                <div
                                  class="d-flex justify-content-between align-items-center mb-1 p-2"
                                  style="background-color: #fcfcfc"
                                >
                                  <button
                                    [hidden]="
                                      user_type == 200 || user_type == 201
                                    "
                                    class="btn text-center"
                                    style="
                                      background-color: #141414;
                                      color: #fff;
                                    "
                                    (click)="openXl2(content2)"
                                  >
                                    <i
                                      class="fa fa-plus-circle mx-1"
                                      style="
                                        font-size: 14px;
                                        cursor: pointer;
                                        color: #fff;
                                      "
                                    ></i>
                                  </button>
                                </div>
                                <p-orderList
                                  [value]="masterproduct"
                                  [filterBy]="
                                    'product_name_en,product_name_ar,product_name_he,product_code'
                                  "
                                  [showButtons]="true"
                                  [responsive]="true"
                                  [sourceStyle]="{ height: '30rem' }"
                                  [targetStyle]="{ height: '30rem' }"
                                  filterPlaceholder="{{
                                    curentLang === 'en'
                                      ? 'Filter by name or product code'
                                      : curentLang === 'ar'
                                      ? 'ابحث بالاسم أو كود المنتج'
                                      : 'חפש לפי שם או קוד מוצר'
                                  }}"
                                  [listStyle]="{ height: '25rem' }"
                                  header=""
                                  (onSelectionChange)="
                                    onProductSelect($event.value, i)
                                  "
                                >
                                  <ng-template let-product pTemplate="item">
                                    <div
                                      class="flex flex-wrap align-items-center product-item"
                                    >
                                      <div class="flex-1 flex flex-column">
                                        <span style="font-size: 14px">
                                          <i
                                            class="fa fa-cart-arrow-down text-sm mx-1"
                                          ></i>
                                          {{
                                            curentLang === "en"
                                              ? product.product_name_en
                                              : curentLang === "ar"
                                              ? product.product_name_ar
                                              : product.product_name_he
                                          }}
                                          <br class="m-0 p-0" />
                                          <i
                                            class="fa fa-dollar text-sm mx-1"
                                          ></i>
                                          {{ product.price }}
                                          <hr
                                            style="color: #141414"
                                            class="m-0 p-0"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </ng-template>
                                </p-orderList>
                              </div>
                            </div>
                            <hr style="color: gray" class="py-2" />
                          </div>
                        </ng-template>
                      </div>
                    </div>
                    <!-- end Mobile layout for smaller screens -->

                    <div class="row d-flex justify-content-between">
                      <div class="col-md-6 col-sm-12" style="color: black">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="d-flex flex-column">
                              <div
                                class="d-flex justify-content-between px-4 my-2"
                              >
                                <div style="width: 200px">
                                  {{
                                    curentLang === "en"
                                      ? "Amount Before Discount"
                                      : curentLang === "ar"
                                      ? "   المبلغ قبل الخصم"
                                      : "מחיר לפני הנחה"
                                  }}
                                </div>
                                <input
                                  formControlName="amount_before_discount"
                                  class="form-control"
                                  [value]="
                                    invoiceform.get('amount_before_discount')
                                      .value | numberFormat
                                  "
                                />
                              </div>

                              <div
                                class="d-flex justify-content-between px-4 my-2"
                              >
                                <div style="width: 200px">
                                  {{
                                    curentLang === "en"
                                      ? "Discount"
                                      : curentLang === "ar"
                                      ? "الخصم"
                                      : "הנחה"
                                  }}
                                </div>
                                <input
                                  formControlName="all_discount"
                                  class="form-control"
                                  [value]="
                                    invoiceform.get('all_discount').value
                                      | numberFormat
                                  "
                                />
                              </div>

                              <div
                                class="d-flex justify-content-between px-4 my-2"
                              >
                                <div style="width: 200px">
                                  {{
                                    curentLang === "en"
                                      ? "Payment Amount"
                                      : curentLang === "ar"
                                      ? " قيمة الدفع  "
                                      : 'סה"כ  '
                                  }}
                                </div>
                                <input
                                  formControlName="payment_amount"
                                  class="form-control"
                                  [value]="
                                    invoiceform.get('payment_amount').value
                                      | numberFormat
                                  "
                                />
                              </div>

                              <div formArrayName="lines">
                                <div *ngFor="let line of invproducts.controls; let i = index" [formGroupName]="i" class="d-flex justify-content-between px-4 my-2">
                                  <div style="width: 200px">
                                    {{
                                      curentLang === "en"
                                        ? "VAT Amount"
                                        : curentLang === "ar"
                                        ? "قيمة الضريبة"
                                        : 'מע"מ'
                                    }}
                                    ( {{ VatPercentage }} %)
                                  </div>
                                  <input
                                    formControlName="vat_amount"
                                    class="form-control"
                                    type="number"
                                    min="0"
                                    (blur)="formatTotalAmount(i)"
                                  />
                                </div>
                              </div>

                              <div
                                class="d-flex justify-content-between px-4 my-2"
                              >
                                <div style="width: 200px">
                                  {{
                                    curentLang === "en"
                                      ? "Payment Amount Including VAT"
                                      : curentLang === "ar"
                                      ? "قيمة المبلغ شاملة الضريبة"
                                      : '    סה"כ לשתלום'
                                  }}
                                </div>
                                <input
                                formControlName="payment_amount_including_vat"
                                class="form-control"
                                [value]="invoiceform.get('payment_amount_including_vat').value | number: '1.2-2'"
                              />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12" style="color: black">
                        <div class="mt-8 px-4">
                          <div>
                            <label for="notes">
                              {{
                                curentLang === "en"
                                  ? "Note"
                                  : curentLang === "ar"
                                  ? "ملاحظة"
                                  : "הערה"
                              }}</label
                            >
                            <textarea
                              formControlName="invoice_note"
                              id="notes"
                              name="notes"
                              class="form-textarea form-control my-2"
                              placeholder="{{
                                curentLang === 'en'
                                  ? 'Note'
                                  : curentLang === 'ar'
                                  ? 'ملاحظة'
                                  : 'הערה'
                              }}"
                              name="notes"
                            ></textarea>
                          </div>
                          <div
                            class="w-100 mt-8 px-4 d-flex justify-content-between"
                          >
                            <div>
                              <label>{{
                                curentLang === "en"
                                  ? "Assignment number"
                                  : curentLang === "ar"
                                  ? "رقم تخصيص"
                                  : "מספר הקצאה  "
                              }}</label>
                              <input
                                type="radio"
                                formControlName="allocation_number"
                                [value]="1"
                              />
                            </div>
                            <div>
                              <label>{{
                                curentLang === "en"
                                  ? "Without assignment number"
                                  : curentLang === "ar"
                                  ? "بدون رقم تخصيص"
                                  : "ללא מספר הקצאה    "
                              }}</label>
                              <input
                                type="radio"
                                formControlName="allocation_number"
                                [value]="0"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <br />

                <div class="row d-flex justify-content-between text-center">
                  <br />
                  <div class="col-4 col-lg-4 p-1">
                    <button
                      class="btn"
                      style="background-color: #141414; color: #fff"
                      type="submit"
                      (click)="confirmInvoice()"
                    >
                      <i
                        class="fa fa-check mx-1"
                        style="font-size: 14px; cursor: pointer; color: #fff"
                      ></i>
                      {{
                        curentLang === "en"
                          ? "Confirm "
                          : curentLang === "ar"
                          ? "تأكيد    "
                          : "אישור "
                      }}
                    </button>
                  </div>

                  <div class="col-4 col-lg-4 p-1">
                    <button
                      type="button"
                      class="btn"
                      (click)="SaveInvoice()"
                      style="background-color: #277d29; color: #fff"
                      type="submit"
                    >
                      <i
                        class="fa fa-save mx-1"
                        style="font-size: 14px; cursor: pointer; color: #fff"
                      ></i>
                      {{
                        curentLang === "en"
                          ? "Save "
                          : curentLang === "ar"
                          ? "حفظ "
                          : " שמירה "
                      }}
                    </button>
                  </div>

                  <div class="col-4 col-lg-4 p-1">
                    <button
                      class="btn btn-danger"
                      routerLink="/dashboard/documents"
                    >
                      <i
                        ><i
                          class="fa fa-rotate-left mx-1"
                          style="font-size: 14px; cursor: pointer; color: #fff"
                        ></i
                      ></i>
                      {{
                        curentLang === "en"
                          ? "Back"
                          : curentLang === "ar"
                          ? "رجوع"
                          : "חזרה"
                      }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!----------------------------------->

    <!-- <div class=" col-sm-6 col-lg-6 col-xl-6 ">
     
    <div class="card  p-3  ">
       <div class="panel mb-5 text-center  bg-white ">
           <h4 class="text-black dark:text-white m-2"> {{ curentLang === 'en' ? 'Company Info' : (curentLang === 'ar' ? 'معلومات الشركة' : 'מידע על החברה')}}</h4>
           <div class="flex shrink-0 items-center text-black dark:text-white m-2 ">
             <img [src]="LOGO" alt="" class="my-3 text-center" style="width: 100px; height: 100px; border-radius: 50%;"/>
         </div>
         <div class="mt-6 space-y-1 my-2 mx-2" style="color: black;">
           <div>{{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? 'اسم الشركة' : 'שם החברה')}} : {{ curentLang === 'en' ? CompanyNameEn : (curentLang === 'ar' ? CompanyNameAr : CompanyNameHe)}}</div>
           <div>{{ curentLang === 'en' ? 'Company Address Street' : (curentLang === 'ar' ? 'شارع  الشركة' : 'מספר רחוב כתובת החברה')}} : {{ CompanyAddress_str}}</div>
           <div>{{ curentLang === 'en' ? 'Company Address Building Number' : (curentLang === 'ar' ? 'رقم المبنى الشركة' : 'עיר כתובת החברה')}} : {{ CompanyAddress_number}}</div>
           <div>{{ curentLang === 'en' ? 'Company Address City' : (curentLang === 'ar' ? 'مدينة الشركة' : 'מיקוד כתובת החברה')}} : {{ CompanyAddress_city}}</div>
           <div>{{ curentLang === 'en' ? 'Company Address Zip Code' : (curentLang === 'ar' ? 'رمز بريدي الشركة' : 'ארץ כתובת החברה')}} : {{ CompanyAddress_zip_code}}</div>
           
           <div> {{ curentLang === 'en' ? 'Company Tax Number' : (curentLang === 'ar' ? ' الرقم الضريبي للشركة  ' : 'מספר מס חברה  ')}} : {{TaxCompany}}</div>
           
       </div>
         
          
        
        
     </div>
    </div>
 
</div>

<div class=" col-sm-6 col-lg-6 col-xl-6   ">

   <div class=" card  p-3  ">
       <div class="panel mb-5 text-center bg-white ">
           <h4 class="text-black dark:text-white m-2">  {{ curentLang === 'en' ? 'Customer Info' : (curentLang === 'ar' ? 'معلومات العميل' : 'מידע על הלקוח')}}</h4>
        
         <div class="mt-6 space-y-1 my-2 mx-2" style="color: black;">
           <div> {{ curentLang === 'en' ? 'Customer Name' : (curentLang === 'ar' ? 'اسم العميل' : 'שם הלקוח')}} : {{  curentLang === 'en' ? CustomerNameEn : (curentLang === 'ar' ? CustomerNameAr : CustomerNameHe)}}</div>
           <div>{{ curentLang === 'en' ? 'Customer Address' : (curentLang === 'ar' ? 'عنوان العميل' : 'כתובת הלקוח')}} : {{ oneCustomerData?.customer_address}}</div>
           <div> {{ curentLang === 'en' ? 'Customer Vat Number' : (curentLang === 'ar' ? 'الرقم الضريبي للعميل' : 'מספר מס חשבונית')}} : {{oneCustomerData?.customer_vat_number}}</div>
           <div> {{ curentLang === 'en' ? 'Customer Id Number' : (curentLang === 'ar' ? 'رقم الهوية' : 'מספר ישות (ת.ז / ח.פ.)')}} : {{oneCustomerData?.id_number}}</div>
       </div>
         
         </div>
   </div>
     
</div> -->

       
  </div>
</div>

<!--add Customer-->

<ng-template #content let-modal>
  <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title" style="color: grey;">
          {{ curentLang === 'en' ? 'Add Customer ' : (curentLang === 'ar' ? ' اضافة عميل ' : ' הוסף לקוח ')}} 
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="container-fluid">
          <div class="edit-profile">
              <form [formGroup]="addCustomer" novalidate>

                  <!-- الصف الأول -->
                  <div class="row">
                    <div class="mb-3 col-xl-4 col-md-4">
                        <label class="form-label" style="color: grey;">
                            {{ curentLang === 'en' ? 'Customer Name' : (curentLang === 'ar' ? 'اسم العميل' : ' שם לקוח ') }}
                            <span class="text-danger" style="color: red;">*</span>
                        </label>
                        <input 
                            style="color: grey;" 
                            class="form-control"
                            type="text" 
                            formControlName="customer_name_he" 
                            maxlength="80"
                            required
                            [ngClass]="{ 'is-invalid': validate && addCustomer.controls['customer_name_he'].invalid }"
                        />
                        <div *ngIf="validate && addCustomer.controls['customer_name_he'].invalid" class="invalid-feedback text-danger">
                            {{ curentLang === 'en' ? 'Please enter customer name' : (curentLang === 'ar' ? 'الرجاء ادخال اسم العميل' : 'נא להזין את שם הלקוח') }}
                        </div>
                    </div>
                
                    <div class="mb-3 col-xl-4 col-md-4">
                        <label class="form-label" style="color: grey;">
                            {{ curentLang === 'en' ? 'Customer Type' : (curentLang === 'ar' ? 'نوع العميل' : 'סוג לקוח') }}
                            <span class="text-danger" style="color: red;">*</span>
                        </label>
                        <select 
                            class="form-control form-select"
                            formControlName="customer_type"
                            [ngClass]="{ 'is-invalid': validate && addCustomer.controls['customer_type'].invalid }"
                        >
                            <option *ngFor="let item of customerTypeData; let i = index" [value]="item?.lookup_detail_id">
                                {{ curentLang === 'en' ? item?.lookupDetailEnName : (curentLang === 'ar' ? item?.lookupDetailArName : item?.lookupDetailHeName) }}
                            </option>
                        </select>
                        <div *ngIf="validate && addCustomer.controls['customer_type'].invalid" class="invalid-feedback text-danger">
                            {{ curentLang === 'en' ? 'Please choose customer type' : (curentLang === 'ar' ? 'الرجاء اختيار نوع العميل' : 'אנא בחר סוג לקוח') }}
                        </div>
                    </div>
                
                    <div class="mb-3 col-xl-4 col-md-4">
                        <label class="form-label" style="color: grey;">
                            {{ curentLang === 'en' ? 'Customer Phone' : (curentLang === 'ar' ? 'هاتف العميل' : 'טלפון לקוח') }}
                        </label>
                        <div class="input-group">
                            <span style="color: #141414;" class="input-group-text">+972</span>
                            <input 
                                class="form-control" 
                                type="text" 
                                maxlength="10"
                                formControlName="customer_phone"
                                [ngClass]="{ 'is-invalid': validate && addCustomer.controls['customer_phone'].invalid }"
                            />
                        </div>
                        <div *ngIf="validate && addCustomer.controls['customer_phone'].invalid" class="invalid-feedback text-danger">
                            {{ curentLang === 'en' ? 'Please enter a valid phone number' : (curentLang === 'ar' ? 'الرجاء إدخال رقم هاتف صالح' : 'נא להזין מספר טלפון תקין') }}
                        </div>
                    </div>
                </div>

                  <!-- الصف الثاني -->
                  <div class="row">
                      <div class="mb-3 col-xl-4 col-md-4" *ngIf="addCustomer.value.customer_type != 6">
                          <label class="form-label" style="color: grey;">
                              {{ curentLang === 'en' ? 'ID Number' : (curentLang === 'ar' ? 'رقم الهوية' : 'מספר ישות (ת.ז / ח.פ.)') }}
                               
                          </label>
                          <input 
                              style="color: grey;" 
                              class="form-control"
                              type="number" 
                              formControlName="id_number"
                              maxlength="9"
                           />
                      
                      </div>

                      <div class="mb-3 col-xl-4 col-md-4">
                          <label class="form-label" style="color: grey;">
                            {{ curentLang === 'en' ? 'City Name  ' : (curentLang === 'ar' ? ' اسم المدينة ' : 'שם העיר')}}
                            <!-- <span class="text-danger" style="color: red;">*</span> -->
                          </label>
                          <input 
                              style="color: grey;" 
                              class="form-control"
                              type="text" 
                              formControlName="customer_address_city"
                              maxlength="100"
                              
                          />
                          <!-- <div *ngIf="validate && addCustomer.controls['customer_address_city'].invalid" class="invalid-feedback text-danger">
                              {{ curentLang === 'en' ? 'Please enter customer city' : (curentLang === 'ar' ? 'الرجاء إدخال مدينة العميل' : 'אנא הזן עיר לקוח') }}
                          </div> -->
                      </div>

                      

                      <div class="mb-3 col-xl-4 col-md-4">
                          <label class="form-label" style="color: grey;">
                              {{ curentLang === 'en' ? 'Street Name' : (curentLang === 'ar' ? 'أسم الشارع' : 'שם רחוב') }}
                              <!-- <span class="text-danger" style="color: red;">*</span> -->
                          </label>
                          <input 
                              style="color: grey;" 
                              class="form-control"
                              type="text" 
                              formControlName="customer_address_str"
                              maxlength="100"
                          />
                          <!-- <div *ngIf="validate && addCustomer.controls['customer_address_str'].invalid" class="invalid-feedback text-danger">
                              {{ curentLang === 'en' ? 'Please enter customer street' : (curentLang === 'ar' ? 'الرجاء إدخال شارع العميل' : 'אנא הזן רחוב לקוח') }}
                          </div> -->
                      </div>
                  </div>

                  <!-- الصف الثالث -->
                  <div class="row">
                      <div class="mb-3 col-xl-4 col-md-4">
                          <label class="form-label" style="color: grey;">
                              {{ curentLang === 'en' ? 'Building Number' : (curentLang === 'ar' ? 'رقم المبني' : 'מספר בניין') }}
                              <!-- <span class="text-danger" style="color: red;">*</span> -->
                          </label>
                          <input 
                              style="color: grey;" 
                              class="form-control"
                              type="number" 
                              formControlName="customer_Building_number"
                              maxlength="100"
                          />
                          <!-- <div *ngIf="validate && addCustomer.controls['customer_Building_number'].invalid" class="invalid-feedback text-danger">
                              {{ curentLang === 'en' ? 'Please enter customer building number' : (curentLang === 'ar' ? 'الرجاء إدخال رقم مبنى العميل' : 'אנא הזן מספר בניין לקוח') }}
                          </div> -->
                      </div>

                      <div class="mb-3 col-xl-4 col-md-4">
                          <label class="form-label" style="color: grey;">
                              {{ curentLang === 'en' ? 'Zip Code' : (curentLang === 'ar' ? 'الرقم البريدي' : 'מיקוד דואר') }}
                              <!-- <span class="text-danger" style="color: red;">*</span> -->
                          </label>
                          <input 
                              style="color: grey;" 
                              class="form-control"
                              type="number" 
                              formControlName="customer_address_zip_code"
                              maxlength="20"
                          />
                          <!-- <div *ngIf="validate && addCustomer.controls['customer_address_zip_code'].invalid" class="invalid-feedback text-danger">
                              {{ curentLang === 'en' ? 'Please enter customer zip code' : (curentLang === 'ar' ? 'الرجاء إدخال الرقم البريدي للعميل' : 'אנא הזן את המיקוד של הלקוח') }}
                          </div> -->
                      </div>

                      <div class="mb-3 col-xl-4 col-md-4">
                        <label class="form-label" style="color: grey;">
                            {{ curentLang === 'en' ? 'Customer Vat Number' : (curentLang === 'ar' ? 'الرقم الضريبي للعميل' : 'מספר תיק במס ההכנסה') }}
                        </label>
                        <input 
                            style="color: grey;" 
                            class="form-control"
                            type="text"
                            formControlName="customer_vat_number"
                            maxlength="9"
                            pattern="[0-9]{9}"
                            title="{{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}"
                            [ngClass]="{ 'is-invalid': validate && addCustomer.controls['customer_vat_number'].invalid }"
                        />
                        <div *ngIf="validate && addCustomer.controls['customer_vat_number'].invalid" class="invalid-feedback text-danger">
                            {{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}
                            <span class="text-danger" style="color: red;">*</span>
                        </div>
                    </div>
                  </div>

                  <!-- الصف الرابع (حقل ضريبة القيمة المضافة، أو يمكنك إضافة المزيد من الحقول) -->
                  <div class="row">
                      <div class="mb-3 col-xl-4 col-md-4" *ngIf="addCustomer.value.customer_type != 5">
                          <label class="form-label" style="color: grey;">
                              {{ curentLang === 'en' ? 'Customer Vat Tax' : (curentLang === 'ar' ? 'رقم ضريبة القيمة المضافة' : '  מס עוסק מורשה   ') }}
                          </label>
                          <input 
                              style="color: grey;" 
                              class="form-control"
                              type="number"
                              formControlName="customer_vat_tax"
                              maxlength="9"
                              [ngClass]="{ 'is-invalid': validate && addCustomer.controls['customer_vat_tax'].invalid }"
                          />
                          <div *ngIf="validate && addCustomer.controls['customer_vat_tax'].invalid" class="invalid-feedback text-danger">
                              {{ curentLang === 'en' ? 'Please enter a valid VAT number' : (curentLang === 'ar' ? 'الرجاء إدخال رقم ضريبة القيمة المضافة الصحيح' : 'אנא הזן מספר מס מע"מ תקין') }}
                          </div>
                      </div>
                  </div>

                  <hr>
 <!--
                  <h6 class="modal-title" style="color: grey;">
                    {{ curentLang === 'en' ? 'Additional fields' : (curentLang === 'ar' ? ' حقول اضافية   ' : 'שדות נוספים')}} 
                </h6>
                <hr style="width: 25%;"> 
-->
                <div class="row">
          

                

                 <!--   <div class="mb-3 col-xl-6 col-md-6">
                    <label class="form-label" style="color: grey;">
                        {{ curentLang === 'en' ? 'City Name Ar' : (curentLang === 'ar' ? 'اسم المدينة عربي' : 'שם עיר בערבית') }}
                         <span class="text-danger" style="color: red;">*</span>  
                    </label>
                    <input 
                        style="color: grey;" 
                        class="form-control"
                        type="text" 
                        formControlName="customer_address_city_ar"
                        maxlength="100"
                        
                    />
                   <div *ngIf="validate && addCustomer.controls['customer_address_city'].invalid" class="invalid-feedback text-danger">
                        {{ curentLang === 'en' ? 'Please enter customer city' : (curentLang === 'ar' ? 'الرجاء إدخال مدينة العميل' : 'אנא הזן עיר לקוח') }}
                    </div> 
                </div>-->


            <!--    <div class="mb-3 col-xl-6 col-md-6">
                  <label class="form-label" style="color: grey;">
                      {{ curentLang === 'en' ? 'City Name En' : (curentLang === 'ar' ? 'اسم المدينة انجليزي' : 'שם העיר באנגלית') }}
                       <span class="text-danger" style="color: red;">*</span>  
                  </label>
                  <input 
                      style="color: grey;" 
                      class="form-control"
                      type="text" 
                      formControlName="customer_address_city_en"
                      maxlength="100"
                      
                  />
                   <div *ngIf="validate && addCustomer.controls['customer_address_city'].invalid" class="invalid-feedback text-danger">
                      {{ curentLang === 'en' ? 'Please enter customer city' : (curentLang === 'ar' ? 'الرجاء إدخال مدينة العميل' : 'אנא הזן עיר לקוח') }}
                  </div>  
              </div>
                  -->

                

               <!--   <div class="mb-3 col-xl-6 col-md-6">
                    <label class="form-label" style="color: grey;">
                        {{ curentLang === 'en' ? 'Street Name Ar' : (curentLang === 'ar' ? 'اسم الشارع عربي' : "שם הרחוב הוא ערבי") }}
                          <span class="text-danger" style="color: red;">*</span>  
                    </label>
                    <input 
                        style="color: grey;" 
                        class="form-control"
                        type="text" 
                        formControlName="customer_address_str_ar"
                        maxlength="100"
                    />
                     <div *ngIf="validate && addCustomer.controls['customer_address_str'].invalid" class="invalid-feedback text-danger">
                        {{ curentLang === 'en' ? 'Please enter customer street' : (curentLang === 'ar' ? 'الرجاء إدخال شارع العميل' : 'אנא הזן רחוב לקוח') }}
                    </div> 
                </div>-->

                
                 <!--  <div class="mb-3 col-xl-6 col-md-6">
                  <label class="form-label" style="color: grey;">
                      {{ curentLang === 'en' ? 'Street Name En' : (curentLang === 'ar' ? 'اسم الشارع انجليزي' : 'שם רחוב באנגלית') }}
                       <span class="text-danger" style="color: red;">*</span> 
                  </label>
                  <input 
                      style="color: grey;" 
                      class="form-control"
                      type="text" 
                      formControlName="customer_address_str_en"
                      maxlength="100"
                  />
                <div *ngIf="validate && addCustomer.controls['customer_address_str'].invalid" class="invalid-feedback text-danger">
                      {{ curentLang === 'en' ? 'Please enter customer street' : (curentLang === 'ar' ? 'الرجاء إدخال شارع العميل' : 'אנא הזן רחוב לקוח') }}
                  </div>  
              </div>-->

              </div>



                  <div class="modal-footer">
                      <button type="button" class="btn btn-sm m-auto mt-2" 
                              style="background-color: #141414; color: #fff;" 
                              (click)="addedData()"> 
                          {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تأكيد' : 'אישור') }}
                          <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                      </button>
                      <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" 
                              (click)="modal.close('Close click')">
                          {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
                          <i class="fa fa-rotate-left mx-1"></i>
                      </button>
                  </div>
              </form>
              
          </div>
      </div>
  </div>
</ng-template>

<!--add product-->

<ng-template #content2 let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title" style="color: grey;">
      {{ curentLang === 'en' ? 'Add Product ' : (curentLang === 'ar' ? ' اضافة منتج ' : 'הוספת מוצר') }} 
    </h6>
    <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="edit-profile">
        <div class="row d-flex justify-content-between">
          <form [formGroup]="addProduct" novalidate [ngClass]="{ 'was-validated': validate }">
            <div class="row">
              <div class="mb-3 col-xl-4 col-md-4">
                <label class="form-label" style="color: grey;"> 
                  {{ curentLang === 'en' ? 'Product Name ' : (curentLang === 'ar' ? '  اسم المنتج    ' : ' שם מוצר ') }}
                  <span class="text-danger">*</span>
                </label>
                <input required style="color: grey;" class="form-control" onlyAlphabets type="text" formControlName="product_name_he" maxlength="80" />
                <div class="invalid-feedback text-danger">
                  {{ curentLang === 'en' ? 'please enter product name' : (curentLang === 'ar' ? '   الرجاء ادخال أسم المنتج ' : ' נא להזין את שם המוצר ') }}  
                </div>
              </div>

              <div class="mb-3 col-xl-4 col-md-4">
                <label class="form-label" style="color: grey;"> 
                  {{ curentLang === 'en' ? 'Product Code' : (curentLang === 'ar' ? ' رقم الصنف ' : ' קוד מוצר ') }}
                </label>
                <input style="color: grey;" onlyAlphabets class="form-control" type="text" formControlName="product_code" maxlength="20" />
              </div>

              <div class="mb-3 col-xl-4 col-md-4">
                <label class="form-label" style="color: grey;"> 
                  {{ curentLang === 'en' ? 'Product Price' : (curentLang === 'ar' ? ' سعر المنتج ' : ' מחיר ') }}
                </label>
                <input style="color: grey;" onlyAlphabets class="form-control" type="number" formControlName="price" maxlength="20" />
              </div>

              <div class="mb-3 col-xl-6 col-md-6">
                <label class="form-label" style="color: grey;"> 
                  {{ curentLang === 'en' ? 'Product Category' : (curentLang === 'ar' ? ' قسم المنتج ' : ' קטגוריה') }}
                </label>
                <ng-select formControlName="categories_id" [(ngModel)]="selectedProduct" (change)="filterProduct(selectedProduct)" style="cursor: pointer;"
                  placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                  <ng-option *ngFor="let item of categoriesData; let i = index" [value]="item?.categories_id">
                    {{ curentLang === 'en' ? item?.categories_name_en : (curentLang === 'ar' ? item?.categories_name_ar  : item?.categories_name_he ) }}
                  </ng-option>
                </ng-select>
              </div>

              <div class="mb-3 col-xl-6 col-md-6">
                <label class="form-label" style="color: grey;"> 
                  {{ curentLang === 'en' ? 'Measurement Unit Product' : (curentLang === 'ar' ? ' وحدة قياس المنتج ' : 'יחידת מדידה') }}
                </label>
                <ng-select formControlName="measurement_unit_id" [(ngModel)]="selectedMeasurementUnit" (change)="filterMeasurementUnit(selectedMeasurementUnit)" style="cursor: pointer;"
                  placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                  <ng-option *ngFor="let item of unitMesauereData; let i = index" [value]="item?.measurement_unit_id">
                    {{ curentLang === 'en' ? item?.measurement_unit_name_en : (curentLang === 'ar' ? item?.measurement_unit_name_ar : item?.measurement_unit_name_he ) }}
                  </ng-option>
                </ng-select>
              </div>

              <div class="mb-3 col-xl-12 col-md-12">
                <label class="form-label" style="color: grey;"> 
                  {{ curentLang === 'en' ? 'Description ' : (curentLang === 'ar' ? ' وصف المنتج ' : ' תיאור מוצר בעברית ') }} 
                </label>
                <textarea maxlength="600" name="" id="des_he" cols="10" rows="5" type="text" class="form-control form-control-sm" formControlName="des_he">
                </textarea>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-sm m-auto mt-2" style="background-color: #141414; color: #fff;" (click)="addedProductData()"> 
                {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? ' تاكيد ' : ' אישור ') }}
                <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
              </button>

              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? ' رجوع ' : ' חזרה ') }}
                <i class="fa fa-rotate-left mx-1"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
