import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../../service/http-help.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs";
import { of } from "rxjs/internal/observable/of";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  AddCustomerEndPoient,
  AddInvoiceEndPoient,
} from "../../service/global.service";
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { israelPhoneNumberValidator } from "src/app/validators/israelPhoneNumberValidator";

@Component({
  selector: 'app-update-receipt',
  templateUrl: './update-receipt.component.html',
  styleUrls: ['./update-receipt.component.scss']
})
export class UpdateReceiptComponent implements OnInit {
  @ViewChild("checkModal") checkModalRef!: TemplateRef<any>;
  @ViewChild("cashModal") cashModalRef!: TemplateRef<any>;
  @ViewChild("bankModal") bankModalRef!: TemplateRef<any>;
  @ViewChild("creditModal") creditModalRef!: TemplateRef<any>;
  today: any;
  curentLang: string = "";
  userRegistId: any = JSON.parse(localStorage.getItem("user_id"));
  merchantId = JSON.parse(localStorage.getItem("merchant_id"));
  companyDefault = JSON.parse(localStorage.getItem("companyDefault"));
  user_type = JSON.parse(localStorage.getItem("user_type"));

  selectedCustomer: any;
  addCustomer: FormGroup;
  public validate = false;
  customerTypeData: any = [];
  public validate2 = false;

  constructor(
    private builder: FormBuilder,
    private service: HttpHelpService,
    private router: Router,
    private alert: ToastrService,
    private activeoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) {
    this.curentLang = localStorage.getItem("curentLang");
    this.companyDefault = localStorage.getItem("companyDefault");

    this.addCustomer = this.fb.group({
      customer_name_he: ["", Validators.required],
      customer_name_en: [""],
      customer_name_ar: [""],
      customer_type: ["", Validators.required],
      customer_phone: [""],
      id_number: [null],
      customer_vat_number: [null],
      customer_vat_tax: [null],
      customer_address_str: [""], // جعل هذا الحقل اختياريًا
      customer_address_str_ar: [""], // جعل هذا الحقل اختياريًا
      customer_address_str_en: [""], // جعل هذا الحقل اختياريًا

      customer_address_city: [""], // جعل هذا الحقل اختياريًا
      customer_address_city_ar: [""], // جعل هذا الحقل اختياريًا
      customer_address_city_en: [""], // جعل هذا الحقل اختياريًا

      customer_address_zip_code: [""], // جعل هذا الحقل اختياريًا
      customer_Building_number: [""], // جعل هذا الحقل اختياريًا
    });
  }
  receiptId: any; // Declare receiptId property
  receiptDetails: any;
  selectedPaymentMethods: string[] = []; // Store multiple selected methods
  disableAddCheckButton: boolean = false; // Control add button state
  masterCustomer: any;
  masterCompany: any;
  selectedPaymentMethodsSummary: { code: string; amount: number }[] = [];
  showFields: boolean = false;

  masterPaymentMethod: any;
  customerNameSelected: any;
  addedPayments: { [key: string]: number } = {};
  masterBank: any;
  masterBranch: any;
  masterBranches: any[] = [];
  es;
  allBranchesData: any;
  cashType = 1;
  checkType = 2;
  bankTransferType = 4;
  creditCardType = 3;
  creditFilter: any;
  selectedCompany: any;
  paymentMethodConfirmed = false;

  companyNameAr: any;
  companyNameEn: any;
  isSubmitting = false;
  creditModalOpen = false;

  companyNameHe: any;
  selectedMonth: any;
  selectedYear: any;
  oneCustomerData: any; // Declare the property to store customer data
  ngOnInit(): void {
    this.curentLang = localStorage.getItem("curentLang");
    this.companyDefault = localStorage.getItem("companyDefault");
    this.companyNameAr = localStorage.getItem("company_name_ar");
    this.companyNameEn = localStorage.getItem("company_name_en");
    this.companyNameHe = localStorage.getItem("company_name_he");

    this.companyDefault = localStorage.getItem("companyDefault");
    if (this.companyDefault) {
      this.selectedCompany = this.companyDefault;

      if (this.curentLang == "ar") {
        this.selectedCompany = this.companyNameAr;
      } else if (this.curentLang === "he") {
        this.selectedCompany = this.companyNameHe;
      } else if (this.curentLang === "en") {
        this.selectedCompany = this.companyNameEn;
      } else {
        this.curentLang = "en";
      }
    }

    // تعيين اللغة الافتراضية استنادًا إلى لغة الموقع الحالية
    if (this.curentLang === "ar") {
      this.curentLang = "ar"; // لغة الموقع العربية
    } else if (this.curentLang === "he") {
      this.curentLang = "he"; // لغة الموقع العبرية
    } else {
      this.curentLang = "en";
    }

    this.userRegistId = JSON.parse(localStorage.getItem("user_id"));
    const today = new Date();
    this.selectedMonth = today.getMonth() + 1;
    this.selectedYear = today.getFullYear();
    this.checkClosedMonth();
    this.onCustomerTypeChange();

    this.GetCustomers();
    this.GetCompany();
    this.GetBankList();
    this.getAllBankBranch();
    this.getAllCustomerType();
    if (this.companyDefault !== null && this.companyDefault !== "null") {
      this.receiptForm.patchValue({
        company_id: Number(this.companyDefault),
      });
      this.checkSequenceExists(Number(this.companyDefault));
    }

    this.receiptForm.valueChanges.subscribe(() => {
      this.calculateTotalAmount();
    });

    this.receiptForm.get("company_id").valueChanges.subscribe((companyId) => {
      if (companyId) {
        this.checkSequenceExists(companyId);
      }
    });

    const currentDate = new Date();

    //    this.receiptForm.patchValue({
    //   invoice_date: this.formatDate(currentDate), // تعيين تاريخ محدد
    //   });
  }

  formatDateForInput(dateString?: string): string {
    if (!dateString) return ''; // Handle empty or undefined dates
    const parts = dateString.split('/');
    if (parts.length === 3) {
      return `${parts[2]}-${parts[1].padStart(2, '0')}-${parts[0].padStart(2, '0')}`;
    }
    return ''; // Return empty string if format is incorrect
  }
  translateAndPopulatePayments(lines: any[], formArrayName: string, paymentType: string) {
    const formArray = this.receiptForm.get(formArrayName) as FormArray;
    formArray.clear(); // Clear existing lines
  
    if (lines && lines.length > 0) {
      lines.forEach((line) => {
        const translatedLine = this.builder.group({
          date: [line.date || this.getReceiptDate(), Validators.required],
          amount: [line.amount || 0, Validators.required],
          reference: [line.reference || "", Validators.required],
          bank: [line.bank || ""],
          branch: [line.branch || ""],
          account: [line.account || ""],
          credit_card_code: [line.credit_card_code || ""],
          credit_card_name: [line.credit_card_name || ""],
          credit_type: [line.credit_type || ""],
          payment_type: [line.payment_type || paymentType],
        });
  
        formArray.push(translatedLine);
      });
    }
  }
  
  updatePaymentMethodsSummary() {
    this.selectedPaymentMethodsSummary = [];
  
    // Add Cash Payments
    const cashTotal = this.getTotalAmountCashArray();
    if (cashTotal > 0) {
      this.selectedPaymentMethodsSummary.push({ code: "CASH", amount: cashTotal });
    }
  
    // Add Check Payments
    const checksTotal = this.getTotalAmountChecksArray();
    if (checksTotal > 0) {
      this.selectedPaymentMethodsSummary.push({ code: "CHK", amount: checksTotal });
    }
  
    // Add Bank Transfers
    const bankTotal = this.getTotalAmountBankPaymentArray();
    if (bankTotal > 0) {
      this.selectedPaymentMethodsSummary.push({ code: "BT", amount: bankTotal });
    }
  
    // Add Credit Card Payments
    const creditTotal = this.getTotalAmountCreidtArray();
    if (creditTotal > 0) {
      this.selectedPaymentMethodsSummary.push({ code: "CC", amount: creditTotal });
    }
  }
  checkClosedMonth() {
    this.spinner.show();
    this.service
      .checkClosedMonth(
        this.companyDefault,
        this.selectedMonth,
        this.selectedYear
      )
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          if (res.status == 200) {
            this.toastr.warning(
              this.curentLang === "en"
                ? res.messageEn
                : this.curentLang === "ar"
                ? res.messageAr
                : res.messageHe,
              "",
              { timeOut: 7000 } // زيادة مدة العرض إلى 5 ثوانٍ
            );
          } else {
            // this.toastr.info(
            //   this.curentLang === "en"
            //     ? "The selected month and year are open."
            //     : this.curentLang === "ar"
            //     ? "الشهر والسنة المختارة مفتوحة."
            //     : "החודש והשנה שנבחרו פתוחים.",
            //   "",
            //   { timeOut: 7000 } // زيادة مدة العرض إلى 5 ثوانٍ
            // );
          }
        },
        error: (error) => {
          this.spinner.hide();
        },
      });
  }

  formatDate(date: Date): string {
    const day = ("0" + date?.getDate()).slice(-2);
    const month = ("0" + (date?.getMonth() + 1)).slice(-2);
    const year = date?.getFullYear();
    return `${day}/${month}/${year}`;
  }

  openXlCustomer(contentAddCustomer: any) {
    this.modalService.open(contentAddCustomer, { size: "xl", centered: true });
  }

  filterAddCustomer(event: any) {
    let x = event.target.value;
  }
  getAllCustomerType() {
    this.spinner.show();
    this.service.getAllCustomerType().subscribe({
      next: (res) => {
        this.customerTypeData = res;

        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }

  onCustomerTypeChange() {
    this.addCustomer.get("customer_type")?.valueChanges.subscribe((value) => {
      if (value == 5) {
        //    this.addCustomer.get('id_number')?.setValidators([Validators.required, Validators.maxLength(9)]);
        this.addCustomer.get("customer_vat_number")?.clearValidators();
      } else if (value == 6) {
        this.addCustomer
          .get("customer_vat_number")
          ?.setValidators([
            Validators.required,
            Validators.pattern("[0-9]{9}"),
          ]);
        //    this.addCustomer.get('id_number')?.clearValidators();
      }
      //   this.addCustomer.get('id_number')?.updateValueAndValidity();
      this.addCustomer.get("customer_vat_number")?.updateValueAndValidity();
    });
  }
  toggleFieldsVisibility(): void {
    this.showFields = !this.showFields;
  }
  addedData() {
    if (this.addCustomer.invalid) {
      this.validate = true;
      Object.keys(this.addCustomer.controls).forEach((key) => {
        const control = this.addCustomer.get(key);
        if (control.invalid) {
          // Optional: Scroll to or highlight invalid fields
        }
      });
      return;
    }

    // Copy customer_name_he into en & ar
    const customerNameHe = this.addCustomer.get("customer_name_he")?.value;
    this.addCustomer.patchValue({
      customer_name_en: customerNameHe,
      customer_name_ar: customerNameHe,
    });

    // Copy city & street
    const city = this.addCustomer.get("customer_address_city")?.value;
    const street = this.addCustomer.get("customer_address_str")?.value;
    this.addCustomer.patchValue({
      customer_address_city_en: city,
      customer_address_city_ar: city,
      customer_address_str_en: street,
      customer_address_str_ar: street,
    });

    const model = {
      customer_name_en: this.addCustomer.value.customer_name_en,
      customer_name_ar: this.addCustomer.value.customer_name_ar,
      customer_name_he: this.addCustomer.value.customer_name_he,
      customer_address_str: this.addCustomer.value.customer_address_str,
      customer_address_str_ar: this.addCustomer.value.customer_address_str_ar,
      customer_address_str_en: this.addCustomer.value.customer_address_str_en,
      customer_address_city: this.addCustomer.value.customer_address_city,
      customer_address_city_ar: this.addCustomer.value.customer_address_city_ar,
      customer_address_city_en: this.addCustomer.value.customer_address_city_en,
      customer_address_zip_code:
        this.addCustomer.value.customer_address_zip_code,
      customer_Building_number: this.addCustomer.value.customer_Building_number,
      customer_phone:
        this.addCustomer.value.customer_phone !== ""
          ? "+972" + this.addCustomer.value.customer_phone
          : "",
      customer_vat_number: this.addCustomer.value.customer_vat_number,
      customer_vat_tax: this.addCustomer.value.customer_vat_tax,
      id_number: this.addCustomer.value.id_number,
      customer_type: Number(this.addCustomer.value.customer_type),
      merchant_id: Number(this.merchantId),
      created_by: Number(this.userRegistId),
    };

    this.spinner.show();

    this.service.Post(AddCustomerEndPoient.POST, model).subscribe(
      (res) => {
        this.spinner.hide();

        if (res.status === 200) {
          // ✅ Add new customer to list (triggers UI update)
          this.masterCustomer = [...this.masterCustomer, res.data]; // or res.object if your API returns it under a different name

          Swal.fire({
            icon: "success",
            title:
              this.curentLang === "ar"
                ? res.messageAr
                : this.curentLang === "en"
                ? res.messageEn
                : res.messageHe,
          });

          this.modalService.dismissAll();
          this.getAllCustomerType();

          // Reset form
          this.addCustomer.reset({
            customer_name_en: "",
            customer_name_ar: "",
            customer_name_he: "",
            customer_address_str: "",
            customer_address_str_ar: "",
            customer_address_str_en: "",
            customer_address_city: "",
            customer_address_city_ar: "",
            customer_address_city_en: "",
            customer_address_zip_code: "",
            customer_Building_number: "",
            customer_phone: "",
            customer_vat_number: null,
            customer_vat_tax: null,
            id_number: null,
            customer_type: null,
          });

          this.validate = false;
        } else {
          let errorMessage =
            this.curentLang === "ar"
              ? res.messageAr
              : this.curentLang === "en"
              ? res.messageEn
              : res.messageHe;

          Swal.fire({
            icon: "error",
            title: errorMessage,
          });
        }
      },
      (error) => {
        this.spinner.hide();
        Swal.fire({
          icon: "error",
          title:
            this.curentLang === "en"
              ? "An error occurred"
              : this.curentLang === "ar"
              ? "حدث خطأ"
              : "אירעה שגיאה",
        });
      }
    );
  }
  openChooseCustomerModal(chooseCustomerModal: any) {
    this.modalService.open(chooseCustomerModal, { size: "xl" });
  }

  getCustomerTypeName() {
    const customerTypeId = this.addCustomer.get("customer_type").value;
    const selectedType = this.customerTypeData.find(
      (item) => item.lookup_detail_id === customerTypeId
    );
    return selectedType
      ? this.curentLang === "en"
        ? selectedType.lookupDetailEnName
        : this.curentLang === "ar"
        ? selectedType.lookupDetailArName
        : selectedType.lookupDetailHeName
      : "";
  }

  isNumber(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    } else {
      return true;
    }
  }

  checkSequenceExists(companyId: number): void {
    const docType = "400"; // نوع المستند ثابت
    this.spinner.show();
    this.service.checkSequenceExists(companyId, docType).subscribe(
      (res) => {
        const sequenceExists = res;

        if (!sequenceExists) {
          Swal.fire({
            title:
              this.curentLang === "en"
                ? "Enter the starting number for the document sequence:"
                : this.curentLang === "ar"
                ? "أدخل الرقم الابتدائي لتسلسل المستند:"
                : "הזן את המספר ההתחלתי של רצף המסמכים:",
            input: "number",
            inputAttributes: {
              min: "1",
              step: "1",
            },
            inputValue: 1, // تعيين القيمة الافتراضية إلى 1
            showCancelButton: false,
            confirmButtonText:
              this.curentLang === "en"
                ? "Submit"
                : this.curentLang === "ar"
                ? "إرسال"
                : "שלח",
            allowOutsideClick: false,
            allowEscapeKey: false,
            preConfirm: (value) => {
              if (!value || value < 1) {
                Swal.showValidationMessage(
                  this.curentLang === "en"
                    ? "Please enter a valid number greater than 0"
                    : this.curentLang === "ar"
                    ? "يرجى إدخال رقم صالح أكبر من 0"
                    : "אנא הזן מספר חוקי גדול מ-0"
                );
              }
            },
          }).then((result) => {
            if (result.value) {
              this.createSequence(companyId, parseInt(result.value, 10));
            }
          });
        }

        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  confirmReceipt() {
    this.spinner.show();
    let isValidCustomer = true;
  
    const linesCash = this.receiptForm.get("linesCash") as FormArray;
    const linesChecks = this.receiptForm.get("linesChecks") as FormArray;
    const linesBankPayment = this.receiptForm.get("linesBankPayment") as FormArray;
    const linesCredit = this.receiptForm.get("linesCredit") as FormArray;
  
    if (
      linesCash?.length === 0 &&
      linesChecks?.length === 0 &&
      linesBankPayment?.length === 0 &&
      linesCredit?.length === 0
    ) {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يجب أن يكون هناك بيانات على الأقل في واحدة من وسائل الدفع"
            : this.curentLang === "he"
            ? "חייבים להיות נתונים לפחות באחד מאמצעי התשלום"
            : "There must be data in at least one of the payment methods",
      });
      this.spinner.hide();
      return;
    }
  
    if (isValidCustomer && this.receiptForm.valid) {
      const cashAmount = this.getTotalAmountCashArray();
      const checksAmount = this.getTotalAmountChecksArray();
      const bankPaymentAmount = this.getTotalAmountBankPaymentArray();
      const creditAmount = this.getTotalAmountCreidtArray();
  
      this.receiptForm.get("cash_amount").setValue(cashAmount);
      this.receiptForm.get("checks_amount").setValue(checksAmount);
      this.receiptForm.get("bank_payment_amount").setValue(bankPaymentAmount);
      this.receiptForm.get("credit_amount").setValue(creditAmount);
  
      const deductionFromSource = Number(
        this.receiptForm.get("deduction_from_source").value || 0
      );
      const totalLinesPayment =
        cashAmount +
        checksAmount +
        bankPaymentAmount +
        creditAmount +
        Number(deductionFromSource);
  
      if (
        totalLinesPayment !==
        Number(this.receiptForm.get("total_amount").value)
      ) {
        Swal.fire({
          icon: "warning",
          title:
            this.curentLang === "ar"
              ? "المبلغ الإجمالي للإيصال ليس مطابقًا للمبلغ الإجمالي للدفع"
              : this.curentLang === "he"
              ? "הסכום הכולל של הקבלה לא מתאים לסכום הכולל של התשלום"
              : "The total amount of the receipt does not match the total amount of the payment",
        });
        this.spinner.hide();
        return;
      }
  
      this.spinner.show();
      return this.service
        .SaveReceipt(this.receiptForm.getRawValue())
        .subscribe({
          next: (res: any) => {
            if (res.status === 200) {
              this.router.navigate([`/dashboard/documents`]);
  
              const Toast = Swal.mixin({
                toast: true,
                position: "top",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                color: "green",
                backdrop: true,
  
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
  
              Toast.fire({
                icon: "success",
                title:
                  this.curentLang === "ar"
                    ? res.messageAr
                    : this.curentLang === "en"
                    ? res.messageEn
                    : res.messageHe,
              });
  
              this.modalService.dismissAll();
            } else {
              let errorMessage =
                this.curentLang === "ar"
                  ? res.messageAr
                  : this.curentLang === "en"
                  ? res.messageEn
                  : res.messageHe;
  
              Swal.fire({
                icon: "error",
                title: errorMessage,
              });
            }
            this.spinner.hide();
          },
          error: (error) => {
            this.spinner.hide();
            Swal.fire({
              icon: "error",
              title:
                this.curentLang === "ar"
                  ? "حدث خطأ أثناء تأكيد الإيصال"
                  : this.curentLang === "he"
                  ? "אירעה שגיאה בעת אישור הקבלה"
                  : "An error occurred while confirming the receipt",
            });
          },
        });
    } else {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يرجى ملء جميع الحقول"
            : this.curentLang === "en"
            ? "Please fill all fields"
            : this.curentLang === "he"
            ? "נא למלא כל השדות"
            : "",
      });
      this.spinner.hide();
    }
  }
  editPaymentMethod(method: any) {
    switch (method.code) {
      case "CASH":
        this.openEditModal(this.receiptForm.get('linesCash') as FormArray, method, this.cashModalRef);
        break;
      case "CHK":
        this.openEditModal(this.receiptForm.get('linesChecks') as FormArray, method, this.checkModalRef);
        break;
      case "BT":
        this.openEditModal(this.receiptForm.get('linesBankPayment') as FormArray, method, this.bankModalRef);
        break;
      case "CC":
        this.openEditModal(this.receiptForm.get('linesCredit') as FormArray, method, this.creditModalRef);
        break;
      default:
        console.warn('Unknown payment method type:', method.code);
    }
  }
  
  openEditModal(formArray: FormArray, method: any, modalRef: TemplateRef<any>) {
    console.log('Editing Method:', method);
    console.log('FormArray Controls:', formArray.controls.map(control => control.value));
  
    const targetPaymentType = String(method.code).toUpperCase(); // Ensure it's a string
    const targetAmount = parseFloat(method.amount || '0');
  
    console.log(`🔎 Searching for: { paymentType: '${targetPaymentType}', amount: ${targetAmount} }`);
  
    // Define mapping for numeric payment types to their actual codes
    const paymentTypeMap: { [key: string]: string } = {
      '1': 'CASH',
      '2': 'CHK',
      '3': 'CC',
      '4': 'BT'  // This maps '4' to 'BT'
    };
  
    const index = formArray.controls.findIndex((control) => {
      const formGroup = control as FormGroup;
      let storedPaymentType: any = formGroup.get('payment_type')?.value;
  
      // Convert numeric payment types to their actual codes
      storedPaymentType = paymentTypeMap[storedPaymentType] || String(storedPaymentType).toUpperCase();
  
      const storedAmount = parseFloat(formGroup.get('amount')?.value || '0');
  
      console.log(`🧐 Checking: { paymentType: '${storedPaymentType}', amount: ${storedAmount} }`);
  
      return storedPaymentType === targetPaymentType && storedAmount === targetAmount;
    });
  
    if (index !== -1) {
      const control = formArray.at(index) as FormGroup;
  
      // Update form values
      Object.keys(method).forEach((key) => {
        if (control.get(key)) {
          control.get(key)?.setValue(method[key]);
        }
      });
  
      // Open the modal
      this.modalService.open(modalRef, {
        size: 'lg',
        backdrop: 'static',
        centered: true,
      });
    } else {
      console.warn('❌ No matching payment method found for editing.');
    }
  }
  createSequence(companyId: number, startWith: number): void {
    this.spinner.show();
    const docType = "400"; // نوع المستند ثابت

    this.service.createSequence(companyId, docType, startWith).subscribe(
      (res) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          color: "green",
          backdrop: true,

          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title:
            this.curentLang === "ar"
              ? "تم إنشاء التسلسل بنجاح"
              : this.curentLang === "en"
              ? "Sequence created successfully"
              : "רצף נוצר בהצלחה",
        });
      },
      (error) => {}
    );
    this.spinner.hide();
  }

  filter(event: any) {
    let x = event;
  }

  filterDate(event: any) {
    let x = new Date(event.target.value);
    this.selectedMonth = x.getMonth() + 1; // Month is 0-based, so add 1
    this.selectedYear = x.getFullYear();

    this.checkClosedMonth();
  }

  filterPayment(event: any) {
    let x = event.target.value;
  }

  filterCreidt(event: any, index: number) {
    const selectedValue = event.target.value;
    this.creditFilter = selectedValue;

    // Update the form control for credit_card_code at the specified index
    (this.receiptForm.get("linesCredit") as FormArray)
      .at(index)
      .get("credit_card_code")
      ?.setValue(this.creditFilter);
  }

  filterCustomer(event: any) {
    this.selectedCustomer = event;
  }

  availableLanguages = [
    { code: "he", name: "Hebrew" },
    { code: "en", name: "English" },
    { code: "ar", name: "Arabic" },
  ];
  getCustomerById() {
    const customerId = this.receiptForm.get("customer_id")?.value;

    if (customerId != null && customerId !== "") {
      this.spinner.show();
      this.service.getCustomerById(Number(customerId)).subscribe({
        next: (res) => {
          this.oneCustomerData = res;

          // تحديث النموذج ببيانات العميل المستلمة
          this.receiptForm.patchValue({
            customer_name: this.oneCustomerData?.[
              "customer_name_" + this.curentLang
            ].substring(0, 100),
            customer_id_number: this.oneCustomerData?.id_number,
            customer_vat_number: this.oneCustomerData?.customer_vat_number || 0,
          });

          this.customerNameSelected =
            this.receiptForm.get("customer_name")?.value;

          this.spinner.hide();
        },
        error: (error) => {
          this.spinner.hide();
        },
      });
    }
  }
  openChoosePaymentMethodModal(modal: NgbModalRef) {
    this.modalService.open(modal, { size: "md" });
  }
  onPaymentMethodChange(selectedMethods: string[]) {
    // Handle null or undefined gracefully
    if (!selectedMethods) return;

    // Compare with previously selected methods
    const previouslySelected = this.selectedPaymentMethods || [];

    // Find newly added methods
    const newlyAdded = selectedMethods.filter(
      (method) => !previouslySelected.includes(method)
    );

    // Update the tracked selection
    this.selectedPaymentMethods = selectedMethods;

    // Mark that payment method was confirmed AFTER customer was selected
    this.paymentMethodConfirmed = true;

    // Add new line for each newly added method
    for (const method of newlyAdded) {
      switch (method) {
        case "CHK":
          this.addNewLineToChecks();
          this.disableAddCheckButton = true;
          break;
        case "CASH":
          this.addNewLineToCash();
          break;
        case "BT":
          this.addNewLineToBankPayment();
          break;
        case "CC":
          this.addNewLineToCredit();
          break;
      }
    }
  }

  // Remove a payment method from selection
  removePaymentMethod(method: string) {
    this.selectedPaymentMethods = this.selectedPaymentMethods.filter(
      (m) => m !== method
    );
    if (method === "CHK") {
      (this.receiptForm.get("linesChecks") as FormArray).clear();
      this.disableAddCheckButton = false; // Re-enable Add button if removed
    }
    if (method === "CASH") {
      (this.receiptForm.get("linesCash") as FormArray).clear();
    }
    if (method === "BT") {
      (this.receiptForm.get("linesBankPayment") as FormArray).clear();
    }
    if (method === "CC") {
      (this.receiptForm.get("linesCredit") as FormArray).clear();
    }
  }

  // Clears all existing payment method lines before switching
  clearAllPaymentLines() {
    (this.receiptForm.get("linesChecks") as FormArray).clear();
    (this.receiptForm.get("linesCash") as FormArray).clear();
    (this.receiptForm.get("linesBankPayment") as FormArray).clear();
    (this.receiptForm.get("linesCredit") as FormArray).clear();
    this.disableAddCheckButton = false; // Reset button state
  }
  paymentMethods = [
    { code: "CASH", name_en: "Cash", name_ar: "نقدي", name_he: "מזומן" },
    { code: "CHK", name_en: "Check", name_ar: "شيك", name_he: "שיק" },
    {
      code: "BT",
      name_en: "Bank Transfer",
      name_ar: "حوالة بنكية",
      name_he: "העברה בנקאית",
    },
    {
      code: "CC",
      name_en: "Credit Card",
      name_ar: "بطاقة ائتمان",
      name_he: "כרטיס אשראי",
    },
  ];

  onCustomerSelect(event: any): void {
    const selectedCustomer = event.value[0].customer_id; // الحصول على العميل المختار

    this.receiptForm.get("customer_id")?.setValue(selectedCustomer); // تحديث قيمة الحقل
    this.getCustomerById();

    this.modalService.dismissAll();
  }
  openXl(content: any) {
    this.modalService.open(content, { size: "xl", centered: true });
  }
  modalRef: NgbModalRef;

  onSelectPaymentMethod(code: string, modal: NgbModalRef) {
    modal.close();

    const selected = this.receiptForm.get("selectedPaymentMethod")?.value || [];
    if (!selected.includes(code)) {
      const updated = [...selected, code];
      this.receiptForm.patchValue({ selectedPaymentMethod: updated });
    }

    this.paymentMethodConfirmed = true;

    switch (code) {
      case "CHK":
        (this.receiptForm.get("linesChecks") as FormArray).clear(); // ✅ clear
        this.addNewLineToChecks(); // ✅ add one
        this.modalRef = this.modalService.open(this.checkModalRef, {
          size: "lg",
          backdrop: "static",
          centered: true,
        });
        this.modalRef.result.catch(() => {
          (this.receiptForm.get("linesChecks") as FormArray).clear(); // ✅ discard on cancel
          this.calculateTotalAmount();
        });
        break;

      case "CASH":
        (this.receiptForm.get("linesCash") as FormArray).clear(); // ✅ clear
        this.addNewLineToCash(); // ✅ add one
        this.modalRef = this.modalService.open(this.cashModalRef, {
          size: "lg",
          backdrop: "static",
          centered: true,
        });
        this.modalRef.result.catch(() => {
          (this.receiptForm.get("linesCash") as FormArray).clear(); // ✅ discard
          this.calculateTotalAmount();
        });
        break;

      case "BT":
        (this.receiptForm.get("linesBankPayment") as FormArray).clear(); // ✅ clear
        this.addNewLineToBankPayment(); // ✅ add one
        this.modalRef = this.modalService.open(this.bankModalRef, {
          size: "lg",
          backdrop: "static",
          centered: true,
        });
        this.modalRef.result.catch(() => {
          (this.receiptForm.get("linesBankPayment") as FormArray).clear(); // ✅ discard
          this.calculateTotalAmount();
        });
        break;

      case "CC":
        (this.receiptForm.get("linesCredit") as FormArray).clear(); // ✅ clear
        this.addNewLineToCredit(); // ✅ add one
        this.modalRef = this.modalService.open(this.creditModalRef, {
          size: "lg",
          backdrop: "static",
          centered: true,
        });
        this.modalRef.result.catch(() => {
          (this.receiptForm.get("linesCredit") as FormArray).clear(); // ✅ discard
          this.calculateTotalAmount();
        });
        break;
    }
  }

  updatePaymentMethodSummary(code: string, amount: number) {
    const existing = this.selectedPaymentMethodsSummary.find(
      (m) => m.code === code
    );
    if (existing) {
      existing.amount = amount;
    } else {
      this.selectedPaymentMethodsSummary.push({ code, amount });
    }
  }
  removeSelectedPaymentMethod(code: string) {
    // Remove from selectedPaymentMethod array
    const current = this.receiptForm.get('selectedPaymentMethod')?.value || [];
    const updated = current.filter((c: string) => c !== code);
    this.receiptForm.patchValue({ selectedPaymentMethod: updated });
  
    // Remove from summary array
    this.selectedPaymentMethodsSummary = this.selectedPaymentMethodsSummary.filter(
      (m) => m.code !== code
    );
  
    // Clear FormArray
    switch (code) {
      case 'CHK':
        (this.receiptForm.get('linesChecks') as FormArray).clear();
        break;
      case 'CASH':
        (this.receiptForm.get('linesCash') as FormArray).clear();
        break;
      case 'BT':
        (this.receiptForm.get('linesBankPayment') as FormArray).clear();
        break;
      case 'CC':
        (this.receiptForm.get('linesCredit') as FormArray).clear();
        break;
    }
  }
  getMethodLabel(code: string): string {
    switch (code) {
      case "CHK":
        return this.curentLang === "en"
          ? "Check"
          : this.curentLang === "ar"
          ? "شيك"
          : "שיק";
      case "CASH":
        return this.curentLang === "en"
          ? "Cash"
          : this.curentLang === "ar"
          ? "نقدي"
          : "מזומן";
      case "BT":
        return this.curentLang === "en"
          ? "Bank Transfer"
          : this.curentLang === "ar"
          ? "حوالة بنكية"
          : "העברה בנקאית";
      case "CC":
        return this.curentLang === "en"
          ? "Credit Card"
          : this.curentLang === "ar"
          ? "بطاقة ائتمان"
          : "כרטיס אשראי";
      default:
        return code;
    }
  }
  receiptForm = this.builder.group({
    selectedPaymentMethod: this.fb.control([]), // ✅ array of strings
    invoice_date: this.builder.control(
      this.datePipe.transform(new Date(), "yyyy-MM-dd"),
      Validators.required
    ),
    document_language: this.builder.control("he"),
    currency: this.builder.control("ILS", Validators.required),
    cash_amount: this.builder.control(0),
    checks_amount: this.builder.control(null as number | null),
    deduction_from_source: this.builder.control(0),
    bank_payment_amount: this.builder.control(0),
    credit_amount: this.builder.control(0),
    total_amount: this.builder.control(0, Validators.required),
    merchant_id: this.builder.control(this.merchantId),
    company_id: this.builder.control(null, Validators.required),
    customer_id: this.builder.control("", Validators.required),
    customer_name: this.builder.control(""), // ✅ already added
    customer_id_number: this.builder.control(""), // ✅ already added
    customer_vat_number: this.builder.control(0), // ✅ <-- ADD THIS
    created_by: this.builder.control(this.userRegistId),
    linesCash: this.builder.array([]),
    linesChecks: this.builder.array([]),
    linesBankPayment: this.builder.array([]),
    linesCredit: this.builder.array([]),
    receipt_number: this.builder.control(""), // Add receipt_number to the form group
    receipt_date: this.builder.control(null), // Add receipt_date to the form group
    receipt_status: this.builder.control("Pending"), // Add receipt_status to the form group
  });

  get linesChecks() {
    return this.receiptForm.get("linesChecks") as FormArray;
  }

  get linesCash() {
    return this.receiptForm.get("linesCash") as FormArray;
  }

  get linesBankPayment() {
    return this.receiptForm.get("linesBankPayment") as FormArray;
  }

  get linesCredit() {
    return this.receiptForm.get("linesCredit") as FormArray;
  }

  SaveReceipt() {
    // 1) If we’re already submitting, ignore further clicks
    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;

    if (this.receiptForm.valid) {
      this.spinner.show(); // Start the spinner

      // Ensure cash_amount and checks_amount are set correctly
      const cashAmount = this.getTotalAmountCashArray();
      const checksAmount = this.getTotalAmountChecksArray();
      const bankPaymentAmount = this.getTotalAmountBankPaymentArray();
      const creditAmount = this.getTotalAmountCreidtArray();

      this.receiptForm.get("cash_amount").setValue(cashAmount);
      this.receiptForm.get("checks_amount").setValue(checksAmount);
      this.receiptForm.get("bank_payment_amount").setValue(bankPaymentAmount);
      this.receiptForm.get("credit_amount").setValue(creditAmount);

      const linesCash = this.receiptForm.get("linesCash") as FormArray;
      const linesChecks = this.receiptForm.get("linesChecks") as FormArray;
      const linesBankPayment = this.receiptForm.get(
        "linesBankPayment"
      ) as FormArray;
      const linesCredit = this.receiptForm.get("linesCredit") as FormArray;

      if (
        linesCash?.length === 0 &&
        linesChecks?.length === 0 &&
        linesBankPayment?.length === 0 &&
        linesCredit?.length === 0
      ) {
        Swal.fire({
          icon: "warning",
          title:
            this.curentLang === "ar"
              ? "يجب أن يكون هناك بيانات على الأقل في واحدة من وسائل الدفع"
              : this.curentLang === "he"
              ? " חייבים להיות נתונים לפחות באחד מאמצעי התשלום "
              : "There must be data in at least one of the payment methods",
        });
        this.spinner.hide();
        this.isSubmitting = false; // Re-enable submissions
        return; // Stop execution
      }

      this.service.SaveReceipt(this.receiptForm.getRawValue()).subscribe(
        (res) => {
          let result: any = res;

          if (result.status === 200) {
            this.router.navigate(["/dashboard/documents"]);

            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              color: "green",
              backdrop: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title:
                this.curentLang === "ar"
                  ? result.messageAr
                  : this.curentLang === "en"
                  ? result.messageEn
                  : result.messageHe,
            });

            this.receiptForm.reset();
          } else if (
            result.status === 400 ||
            result.status === 403 ||
            result.status === 500
          ) {
            let errorMessage;
            switch (result.status) {
              case 400:
              case 403:
              case 500:
                errorMessage =
                  this.curentLang === "ar"
                    ? result.messageAr
                    : this.curentLang === "en"
                    ? result.messageEn
                    : result.messageHe;
                break;
              default:
                errorMessage =
                  this.curentLang === "ar"
                    ? "حدث خطأ غير معروف"
                    : this.curentLang === "en"
                    ? "An unknown error occurred"
                    : "אירעה שגיאה לא ידועה";
            }

            this.toastr.error(errorMessage);
          }
          this.spinner.hide();
          this.isSubmitting = false; // Re-enable the button
        },
        (error) => {
          // Handle error
          this.spinner.hide();
          this.isSubmitting = false; // Re-enable the button
        }
      );
    } else {
      // If the form is invalid
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يرجى ملء جميع الحقول"
            : this.curentLang === "en"
            ? "Please fill all fields"
            : this.curentLang === "he"
            ? "נא למלא כל השדות"
            : "",
      });
      // We’re not submitting anymore
      this.isSubmitting = false;
    }
  }

  addNewLineToCash() {
    let linesCashArray = this.receiptForm.get("linesCash") as FormArray;

    let customerId = this.receiptForm.get("customer_id")?.value;
    let companyId = this.receiptForm.get("company_id")?.value;

    if (
      customerId != null &&
      customerId != "" &&
      companyId != null &&
      companyId != ""
    ) {
      linesCashArray.push(this.GenerateRowCash());

      // Update cash_amount
      this.receiptForm
        .get("cash_amount")
        .setValue(this.getTotalAmountCashArray());
      this.calculateTotalAmount(); // Update total_amount
    } else {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يرجى ملء جميع الحقول"
            : this.curentLang === "en"
            ? "Please fill all fields"
            : this.curentLang === "he"
            ? "נא למלא כל השדות"
            : "",
      });
    }
  }

  addNewLineToBankPayment() {
    let linesBankPaymentArray = this.receiptForm.get(
      "linesBankPayment"
    ) as FormArray;

    let customerId = this.receiptForm.get("customer_id")?.value;
    let companyId = this.receiptForm.get("company_id")?.value;

    if (
      customerId != null &&
      customerId != "" &&
      companyId != null &&
      companyId != ""
    ) {
      linesBankPaymentArray.push(this.GenerateRowBankPayment());

      // Update cash_amount
      this.receiptForm
        .get("cash_amount")
        .setValue(this.getTotalAmountBankPaymentArray());
      this.calculateTotalAmount(); // Update total_amount
    } else {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يرجى ملء جميع الحقول"
            : this.curentLang === "en"
            ? "Please fill all fields"
            : this.curentLang === "he"
            ? "נא למלא כל השדות"
            : "",
      });
    }
  }

  addNewLineToChecks() {
    let linesChecksArray = this.receiptForm.get("linesChecks") as FormArray;

    let customerId = this.receiptForm.get("customer_id")?.value;
    let companyId = this.receiptForm.get("company_id")?.value;

    if (
      customerId != null &&
      customerId != "" &&
      companyId != null &&
      companyId != ""
    ) {
      linesChecksArray.push(this.GenerateRowCheck());

      // Update checks_amount
      this.receiptForm
        .get("checks_amount")
        .setValue(this.getTotalAmountChecksArray());
      this.calculateTotalAmount(); // Update total_amount
    } else {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يرجى ملء جميع الحقول"
            : this.curentLang === "en"
            ? "Please fill all fields"
            : this.curentLang === "he"
            ? "נא למלא כל השדות"
            : "",
      });
    }
  }

  addNewLineToCredit() {
    const linesCreditArray = this.receiptForm.get("linesCredit") as FormArray;

    const customerId = this.receiptForm.get("customer_id")?.value;
    const companyId = this.receiptForm.get("company_id")?.value;

    if (customerId && companyId) {
      linesCreditArray.push(this.GenerateRowCreidt());

      this.receiptForm
        .get("checks_amount")
        ?.setValue(this.getTotalAmountCreidtArray());
      this.calculateTotalAmount(); // Update total_amount
    } else {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يرجى ملء جميع الحقول"
            : this.curentLang === "en"
            ? "Please fill all fields"
            : this.curentLang === "he"
            ? "נא למלא כל השדות"
            : "",
      });
    }
  }
  getReceiptDate(): string {
    return (
      this.receiptForm.get("invoice_date")?.value ||
      this.datePipe.transform(new Date(), "yyyy-MM-dd")
    );
  }
  // GENERATE ROWS
  GenerateRowCheck() {
    return this.builder.group({
      date: this.builder.control(this.getReceiptDate(), Validators.required),
      reference: this.builder.control(1, Validators.required),
      bank: this.builder.control("", Validators.required),
      branch: this.builder.control("", Validators.required),
      account: this.builder.control(
        { value: 0, disabled: false },
        Validators.required
      ),
      amount: this.builder.control(
        { value: 0, disabled: false },
        Validators.required
      ),
      payment_type: this.builder.control({
        value: this.checkType,
        disabled: true,
      }),
    });
  }

  GenerateRowCash() {
    return this.builder.group({
      date: this.builder.control(this.getReceiptDate(), Validators.required),
      amount: this.builder.control("", Validators.required),
      payment_type: this.builder.control({
        value: this.cashType,
        disabled: true,
      }),
    });
  }

  GenerateRowBankPayment() {
    return this.builder.group({
      date: this.builder.control(this.getReceiptDate(), Validators.required),
      amount: this.builder.control("", Validators.required),
      account: this.builder.control({ value: 0, disabled: false }),
      bank: this.builder.control(""),
      payment_type: this.builder.control({
        value: this.bankTransferType,
        disabled: true,
      }),
    });
  }

  GenerateRowCreidt() {
    return this.builder.group({
      date: this.builder.control(this.getReceiptDate(), Validators.required),
      amount: this.builder.control("", [
        Validators.required,
        Validators.min(0.01),
      ]),
      credit_card_code: this.builder.control("", Validators.required),
      credit_card_name: this.builder.control("", Validators.required),
      account: this.builder.control("", [
        Validators.required,
        Validators.min(1),
      ]),
      credit_type: this.builder.control("", Validators.required),
      payment_type: this.builder.control({
        value: this.creditCardType,
        disabled: true,
      }),
    });
  }

  // Remove Cash Row
  removeLineFromCash(index: number) {
    const linesCashArray = this.receiptForm.get("linesCash") as FormArray;
    linesCashArray.removeAt(index);
    this.calculateTotalAmount();
  }

  // Remove Check Row
  removeLineFromChecks(index: number) {
    const linesChecksArray = this.receiptForm.get("linesChecks") as FormArray;
    linesChecksArray.removeAt(index);
    this.calculateTotalAmount();
  }

  removeLineFromBankPayment(index: number) {
    const linesChecksArray = this.receiptForm.get(
      "linesBankPayment"
    ) as FormArray;
    linesChecksArray.removeAt(index);
    this.calculateTotalAmount();
  }

  removeLineFromCreidtPayment(index: number) {
    const linesChecksArray = this.receiptForm.get("linesCredit") as FormArray;
    linesChecksArray.removeAt(index);
    this.calculateTotalAmount();
  }

  getTotalAmountChecksArray(): number {
    let total = 0;
    const array = this.receiptForm.get("linesChecks") as FormArray;
    array.controls.forEach((group: FormGroup) => {
      const value = group.get("amount")?.value;
      total += parseFloat(value) || 0;
    });
    return total;
  }

  getTotalAmountCashArray(): number {
    let totalAmount = 0;
    const linesCashArray = this.receiptForm.get("linesCash") as FormArray;
    linesCashArray.controls.forEach((control: FormGroup) => {
      const value = control.get("amount")?.value;
      totalAmount += parseFloat(value) || 0;
    });
    return totalAmount;
  }

  getTotalAmountBankPaymentArray(): number {
    let totalAmount = 0;
    const linesBankPaymentArray = this.receiptForm.get(
      "linesBankPayment"
    ) as FormArray;
    linesBankPaymentArray.controls.forEach((control: FormGroup) => {
      const value = control.get("amount")?.value;
      totalAmount += parseFloat(value) || 0;
    });
    return totalAmount;
  }

  getTotalAmountCreidtArray(): number {
    let totalAmount = 0;
    const linesChecksArray = this.receiptForm.get("linesCredit") as FormArray;

    linesChecksArray.controls.forEach((control: FormGroup) => {
      const rawAmount = control.get("amount")?.value;
      const parsedAmount = parseFloat(rawAmount) || 0;
      totalAmount += parsedAmount;
    });

    return totalAmount;
  }

  getBankLabel(item: any): string {
    return this.curentLang === "en"
      ? item.bank_name_en
      : this.curentLang === "ar"
      ? item.bank_name_ar
      : item.bank_name_he;
  }

  getBranchLabel(item: any): string {
    return this.curentLang === "en"
      ? item.bank_branch_name_en
      : this.curentLang === "ar"
      ? item.bank_branch_name_ar
      : item.bank_branch_name_he;
  }
  calculateTotalAmount(): void {
    const cash = this.getTotalAmountCashArray?.() || 0;
    const checks = this.getTotalAmountChecksArray();
    const bank = this.getTotalAmountBankPaymentArray?.() || 0;
    const credit = this.getTotalAmountCreidtArray?.() || 0;
    const deduction = Number(
      this.receiptForm.get("deduction_from_source")?.value || 0
    );
    const total = cash + checks + bank + credit + deduction;

    this.receiptForm.get("total_amount")?.setValue(total);
  }

  // Called when clicking "Confirm" in modal
  onConfirmCheckModal(modal: any) {
    const linesChecks = this.receiptForm.get("linesChecks") as FormArray;
    let valid = true;

    linesChecks.controls.forEach((control) => {
      control.markAllAsTouched();
      if (control.invalid) {
        valid = false;
      }
    });

    if (!valid) return;

    const total = this.getTotalAmountChecksArray();
    this.receiptForm.patchValue({ checks_amount: total });

    this.addedPayments["CHK"] = total;

    // 🔁 Update the summary just like cash
    this.selectedPaymentMethodsSummary = [
      ...this.selectedPaymentMethodsSummary.filter((m) => m.code !== "CHK"),
      { code: "CHK", amount: total },
    ];

    modal.close();
  }
  onConfirmCashModal(modal: NgbModalRef) {
    const cashArray = this.receiptForm.get("linesCash") as FormArray;
    let valid = true;

    cashArray.controls.forEach((control) => {
      control.markAllAsTouched();
      if (control.invalid) {
        valid = false;
      }
    });

    if (!valid) return;

    const total = this.getTotalAmountCashArray();
    this.addedPayments["CASH"] = total;
    this.selectedPaymentMethodsSummary = [
      ...this.selectedPaymentMethodsSummary.filter((m) => m.code !== "CASH"),
      { code: "CASH", amount: total },
    ];

    modal.close();
  }
  onConfirmBankModal(modal: NgbModalRef) {
    if (this.linesBankPayment.invalid) {
      this.linesBankPayment.markAllAsTouched();
      return;
    }

    const total = this.getTotalAmountBankPaymentArray();
    this.addedPayments["BT"] = total;

    // Update external summary
    const existing = this.selectedPaymentMethodsSummary.find(
      (x) => x.code === "BT"
    );
    if (existing) {
      existing.amount = total;
    } else {
      this.selectedPaymentMethodsSummary.push({
        code: "BT",
        amount: total,
      });
    }

    modal.close();
  }
  onConfirmCreditModal(modal: NgbModalRef) {
    console.log("🔘 Confirm Credit Modal Clicked");

    if (!this.linesCredit || this.linesCredit.length === 0) {
      console.warn("⚠️ No credit lines found");
      return;
    }

    let isValid = true;

    this.linesCredit.controls.forEach((control, index) => {
      control.markAllAsTouched();
      if (control.invalid) {
        isValid = false;
        console.warn(`❌ Credit line ${index + 1} is invalid`, control.errors);
      }
    });

    if (!isValid) {
      console.warn("🛑 Credit form has errors. Confirmation blocked.");
      return;
    }

    const total = this.getTotalAmountCreidtArray();
    this.addedPayments["CC"] = total;

    const existing = this.selectedPaymentMethodsSummary.find(
      (x) => x.code === "CC"
    );
    if (existing) {
      existing.amount = total;
    } else {
      this.selectedPaymentMethodsSummary.push({
        code: "CC",
        amount: total,
      });
    }

    this.creditModalOpen = false;
    modal.close();

    console.log("✅ Credit Modal Confirmed | Total:", total);
  }
  getTotalAmount(): number {
    return this.receiptForm.get("total_amount").value;
  }

  // SELECT OPTIONS
  GetCompany() {
    this.service.GetCompany().subscribe((res) => {
      this.masterCompany = res;
    });
  }

  GetCustomers() {
    this.spinner.show();
    this.service.getAllActiveCustomersBasedOnMerchantId().subscribe((res) => {
      this.masterCustomer = res;
      this.spinner.hide();
    });
  }

  getAllBankBranch() {
    this.spinner.show();
    this.service.getAllBankBranch().subscribe((res) => {
      this.allBranchesData = res;

      this.spinner.hide();
    });
  }

  GetBankList() {
    this.spinner.show();
    this.service.GetBankList().subscribe((res) => {
      this.masterBank = res;

      this.spinner.hide();
    });
  }

  getBranches(bankCode: string, index: number) {
    // التحقق إذا كانت القيم غير موجودة (undefined أو فارغة)
    if (!bankCode || index === undefined || index === null) {
      console.warn("bankCode or index is missing");
      // تعيين قائمة فارغة للفروع لهذا السطر
      this.masterBranches[index] = [];
      // إعادة تعيين حقل الفرع في الفورم
      const branchControl = (this.receiptForm.get("linesChecks") as FormArray)
        .at(index)
        .get("branch");
      branchControl.reset(); // إعادة تعيين حقل الفرع
      return;
    }

    // إذا كانت القيم موجودة، نتابع استدعاء الـ API
    const selectedBank = this.masterBank.find(
      (bank) => bank.bank_code === bankCode
    );
    if (selectedBank) {
      const bankId = selectedBank.bank_id;
      this.spinner.show(); // عرض الـ spinner أثناء التحميل
      this.service.getAllBranchById(bankId).subscribe({
        next: (res) => {
          // تخزين البيانات الخاصة بالفروع لهذا السطر
          this.masterBranches[index] = res;

          // التحقق من وجود الفرع المختار ضمن الفروع المحملة
          const branchControl = (
            this.receiptForm.get("linesChecks") as FormArray
          )
            .at(index)
            .get("branch");
          const branchValue = branchControl.value;
          const branchExists = res.some(
            (branch) => branch.bank_branch_code === branchValue
          );
          if (!branchExists) {
            // إعادة تعيين حقل الفرع إذا لم يكن موجودًا
            branchControl.reset();
          }

          this.spinner.hide(); // إخفاء الـ spinner بعد نجاح الاستدعاء
        },
        error: (error) => {
          console.error("Error fetching branches:", error);
          this.spinner.hide(); // إخفاء الـ spinner عند حدوث خطأ
        },
      });
    }
  }

  getBranches2(bankCode: string, index: number) {
    // التحقق إذا كانت القيم غير موجودة (undefined أو فارغة)
    if (!bankCode || index === undefined || index === null) {
      console.warn("bankCode or index is missing");
      // تعيين قائمة فارغة للفروع لهذا السطر
      this.masterBranches[index] = [];
      // إعادة تعيين حقل الفرع في الفورم
      const branchControl = (
        this.receiptForm.get("linesBankPayment") as FormArray
      )
        .at(index)
        .get("branch");
      branchControl.reset(); // إعادة تعيين حقل الفرع
      return;
    }
  }

  onCancelSelection(event: MouseEvent) {
    event.stopPropagation(); // منع انتشار الحدث إلى العناصر الأخرى داخل ng-select
    // تنفيذ الأكواد الخاصة بك هنا
  }

  getBranchesForIndex(index: number) {
    // Return the branches data for the corresponding row index
    return this.masterBranches[index] || [];
  }

  validateNumberInput(event: any) {
    const arabicToEnglishMap = {
      "٠": "0",
      "١": "1",
      "٢": "2",
      "٣": "3",
      "٤": "4",
      "٥": "5",
      "٦": "6",
      "٧": "7",
      "٨": "8",
      "٩": "9",
    };

    let inputValue = event.target.value;

    // تحويل الأرقام العربية إلى أرقام إنجليزية
    inputValue = inputValue.replace(
      /[\u0660-\u0669]/g,
      (match) => arabicToEnglishMap[match]
    );

    // تحديث قيمة الحقل بالقيمة المعدلة
    event.target.value = inputValue;

    // إذا كنت تستخدم Reactive Forms، يجب تحديث قيمة الـ form control أيضًا
    const formControl = this.receiptForm.get("deduction_from_source");
    if (formControl) {
      formControl.setValue(inputValue, { emitEvent: false }); // تحديث القيمة بدون إطلاق حدث التغيير
    }
  }
}
