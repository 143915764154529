import { Injectable } from '@angular/core';
import { HttpHelpService } from 'src/app/Data/service/http-help.service';
import { Company, CompanyData, CompanyResponse } from './alldocuments-companies.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  private companiesSubject = new BehaviorSubject<Company[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private readonly LANG_KEY = 'currentLang';

  public companies$ = this.companiesSubject.asObservable();
  public loading$ = this.loadingSubject.asObservable();

  constructor(private httpHelpService: HttpHelpService) { }

  getCompanies(): Observable<Company[]> {
    // Get current language from localStorage
    const currentLang = localStorage.getItem(this.LANG_KEY) || 'en';

    this.loadingSubject.next(true);

    return this.httpHelpService.GetCompany().pipe(
      map((response: any) => {
        if (response && Array.isArray(response as CompanyResponse)) {
          const companiesArr = response.map((company: CompanyData) => ({
            name: company.company_name_en?.trim() ||
              company[`company_name_${currentLang}`]?.trim() ||
              'Unnamed Company',
            code: company.company_id?.toString() ?? null,
            label: company.company_name_en?.trim() ||
              company[`company_name_${currentLang}`]?.trim() ||
              'Unnamed Company',
            value: company.company_id?.toString() ?? null
          }));

          this.companiesSubject.next(companiesArr);
          return companiesArr;
        }
        return [];
      }),
      catchError(error => {
        console.error("Error fetching companies:", error);
        return of([]);
      }),
      tap(() => this.loadingSubject.next(false))
    );
  }

  filterCompanies(companies: Company[], filterValue: string): Company[] {
    if (!filterValue) {
      return [...companies];
    }
    
    const lowerFilter = filterValue.toLowerCase();
    return companies.filter(company => 
      company.label && company.label.toLowerCase().includes(lowerFilter)
    );
  }

  isSingleCompany(companies: Company[]): boolean {
    const uniqueCompanyCodes = new Set(companies.map(c => c.code));
    return uniqueCompanyCodes.size <= 1;
  }

  getCompanyById(companyId: string | number): Company | undefined {
    if (!companyId || !this.companiesSubject.value.length) {
      return undefined;
    }

    const idString = companyId.toString();
    return this.companiesSubject.value.find(company => 
      company.code === idString || company.value === idString
    );
  }
}
