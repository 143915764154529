import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { DocumentType, DocumentTypeEvent } from '../alldocuments-types.model';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DocumentTypeService } from '../types.service';

@Component({
  selector: 'app-alldocuments-types-mobile',
  templateUrl: './alldocuments-types-mobile.component.html',
  styleUrls: ['./alldocuments-types-mobile.component.scss']
})
export class AlldocumentsTypesMobileComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedType: DocumentType | null = null;
  @Output() typeChange = new EventEmitter<DocumentTypeEvent>();

  documentTypes: DocumentType[] = [];
  showAllTypes: boolean = false;
  selectedValue: DocumentType | null = null;
  private destroy$ = new Subject<void>();

  constructor(public translate: TranslateService, private documentTypeService: DocumentTypeService) { }

  async ngOnInit(): Promise<void> {
    this.documentTypes = await this.documentTypeService.getDocumentTypes();
    this.selectedType = null;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedType) {
      this.selectedValue = this.selectedType;
    }
  }

  toggleShowAllTypes(): void {
    this.showAllTypes = !this.showAllTypes;
  }

  onTypeChange(value: DocumentType): void {
    this.selectedValue = value;
    this.typeChange.emit({
      value,
      originalEvent: undefined
    });
  }

  isTypeSelected(type: DocumentType): boolean {
    return this.documentTypeService.isTypeSelected(this.selectedType, type);
  }
}
