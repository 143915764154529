<app-breadcrumb
  [title]="
    curentLang === 'en'
      ? 'Add Receipts'
      : curentLang === 'ar'
      ? ' اضافة ايصال '
      : 'הוספת קבלה'
  "
  [items]="
    curentLang === 'en'
      ? ['Dashboard']
      : curentLang === 'ar'
      ? ['لوحة التحكم']
      : ['  הגדרות  ']
  "
  [active_item]="
    curentLang === 'en'
      ? 'Add Receipts'
      : curentLang === 'ar'
      ? ' اضافة ايصال '
      : 'הוספת קבלה'
  "
>
</app-breadcrumb>

<div class="container-fluid basic_table">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-block row">
          <div class="col-sm-12 col-lg-12 col-xl-12">
            <div>
              <form [formGroup]="receiptForm">
                <div class="row">
                  <div class="col-lg-5">
                    <div class="form-group mx-2">
                      <label class="mt-2">{{
                        curentLang === "en"
                          ? "  Company Name"
                          : curentLang === "ar"
                          ? "  اسم الشركة"
                          : "  שם החברה"
                      }}</label>
                      <span class="text-danger">*</span>
                      <!-- <select formControlName="company_id" class="form-control form-select">
                                                <option *ngFor="let item of masterCompany" [value]="item.company_id">
                                                    {{ curentLang === 'en' ? item.company_name_en : (curentLang === 'ar'
                                                    ? item.company_name_ar : item.company_name_he) }}
                                                </option>
                                            </select> -->

                      <!--     <input class="form-control w-100" type="text" name="search" formControlName="company_id"
                                            [(ngModel)]="selectedCompany"  readonly
                                            
                                            />
                                            -->

                      <ng-select
                        formControlName="company_id"
                        [disabled]="masterCompany?.length === 1"
                        (change)="filter($event)"
                        style="cursor: pointer"
                        placeholder="{{
                          curentLang === 'en'
                            ? 'Select'
                            : curentLang === 'ar'
                            ? 'اختر'
                            : 'בחר'
                        }}"
                      >
                        <ng-option
                          *ngFor="let item of masterCompany; let i = index"
                          [value]="item.company_id"
                        >
                          {{
                            curentLang === "en"
                              ? item.company_name_en
                              : curentLang === "ar"
                              ? item.company_name_ar
                              : item.company_name_he
                          }}
                        </ng-option>
                      </ng-select>
                    </div>
                  </div>

                  <div class="col-lg-5">
                    <div class="form-group mx-1">
                      <!-- 🌐 Desktop View -->
                      <div class="d-none d-lg-block">
                        <label class="mt-2">
                          {{
                            curentLang === "en"
                              ? "Select Customer Name"
                              : curentLang === "ar"
                              ? "اختر اسم العميل"
                              : "לקוח"
                          }}
                        </label>
                        <span class="text-danger">*</span>
                        <ng-select
                          formControlName="customer_id"
                          (change)="getCustomerById()"
                          style="cursor: pointer"
                          placeholder="{{
                            curentLang === 'en'
                              ? 'Select'
                              : curentLang === 'ar'
                              ? 'اختر'
                              : 'בחר'
                          }}"
                        >
                          <ng-option
                            *ngFor="let item of masterCustomer"
                            [value]="item.customer_id"
                          >
                            {{
                              curentLang === "en"
                                ? item.customer_name_en
                                : curentLang === "ar"
                                ? item.customer_name_ar
                                : item.customer_name_he
                            }}
                          </ng-option>
                        </ng-select>
                      </div>

                      <!-- 📱 Mobile View -->
                      <div class="d-lg-none">
                        <label class="mt-2">
                          {{
                            curentLang === "en"
                              ? "Choose Customer"
                              : curentLang === "ar"
                              ? "اختر العميل"
                              : "בחר את הלקוח"
                          }}
                          <span class="text-danger">*</span>
                        </label>

                        <label
                          class="select-customer-label mt-1"
                          (click)="openChooseCustomerModal(chooseCustomerModal)"
                          style="
                            cursor: pointer;
                            display: block;
                            padding: 10px;
                            background: #f5f5f5;
                            border: 1px solid #ccc;
                          "
                        >
                          {{
                            customerNameSelected
                              ? customerNameSelected
                              : curentLang === "en"
                              ? "Select Customer Name"
                              : curentLang === "ar"
                              ? "اختر اسم العميل"
                              : "לקוח"
                          }}
                        </label>
                      </div>

                      <!-- 🧩 Custom Modal for Mobile -->
                      <ng-template #chooseCustomerModal let-modal>
                        <div
                          class="modal-header row d-flex justify-content-between"
                        >
                          <div class="col-md-12">
                            <h6
                              class="modal-title row d-flex justify-content-between"
                              style="color: black"
                            >
                              {{
                                curentLang === "en"
                                  ? "Choose Customer "
                                  : curentLang === "ar"
                                  ? " اختر العميل "
                                  : " בחר את הלקוח   "
                              }}
                              <button
                                type="button"
                                class="close btn-close"
                                style="color: black"
                                aria-label="Close"
                                (click)="modal.dismiss('Cross click')"
                              ></button>
                            </h6>

                            <div
                              class="modal-body xl:flex xl:justify-content-center"
                            >
                              <div
                                class="d-flex justify-content-between align-items-center mb-1 bg-light p-2"
                              >
                                <button
                                  [hidden]="
                                    user_type == 200 || user_type == 201
                                  "
                                  class="btn text-center"
                                  style="background-color: #141414; color: #fff"
                                  (click)="openXl(content)"
                                >
                                  <i
                                    class="fa fa-plus-circle mx-1"
                                    style="
                                      font-size: 14px;
                                      cursor: pointer;
                                      color: #fff;
                                    "
                                  ></i>
                                </button>
                              </div>

                              <p-orderList
                                [value]="masterCustomer"
                                [filterBy]="
                                  'customer_name_en,customer_name_ar,customer_name_he,id_number'
                                "
                                formControlName="customer_id"
                                [showButtons]="true"
                                [responsive]="true"
                                [sourceStyle]="{ height: '30rem' }"
                                [targetStyle]="{ height: '30rem' }"
                                [listStyle]="{ height: '25rem' }"
                                header=""
                                filterPlaceholder="{{
                                  curentLang === 'en'
                                    ? 'Filter by name or ID'
                                    : curentLang === 'ar'
                                    ? 'ابحث بالاسم أو برقم الهوية'
                                    : 'סינון לפי שם או מספר תעודה'
                                }}"
                                (onReorder)="getCustomerById($event)"
                                (onSelectionChange)="
                                  onCustomerSelect($event); modal.close()
                                "
                              >
                                <ng-template let-customer pTemplate="item">
                                  <div
                                    class="flex flex-wrap align-items-center customer-item"
                                  >
                                    <div class="flex-1 flex flex-column">
                                      <span style="font-size: 14px">
                                        <i class="fa fa-user text-sm mx-1"></i>
                                        {{
                                          curentLang === "en"
                                            ? customer.customer_name_en
                                            : curentLang === "ar"
                                            ? customer.customer_name_ar
                                            : customer.customer_name_he
                                        }}
                                        <br class="m-0 p-0" />
                                        <i
                                          class="fa fa-id-card text-sm mx-1"
                                        ></i>
                                        {{ customer.id_number }}
                                        <hr
                                          style="color: #141414"
                                          class="m-0 p-0"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </ng-template>
                              </p-orderList>
                            </div>
                          </div>
                          <hr style="color: gray" class="py-2" />
                        </div>
                      </ng-template>
                    </div>
                  </div>
                  <!-- ✅ ADD CUSTOMER MODAL -->
                  <ng-template #content let-modal>
                    <div class="modal-header d-flex justify-content-between">
                      <h6 class="modal-title" style="color: grey">
                        {{
                          curentLang === "en"
                            ? "Add Customer "
                            : curentLang === "ar"
                            ? " اضافة عميل "
                            : " הוסף לקוח "
                        }}
                      </h6>
                      <button
                        type="button"
                        class="close btn-close"
                        style="color: grey"
                        aria-label="Close"
                        (click)="modal.dismiss('Cross click')"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="container-fluid">
                        <div class="edit-profile">
                          <form [formGroup]="addCustomer" novalidate>
                            <!-- الصف الأول -->
                            <div class="row">
                              <div class="mb-3 col-xl-4 col-md-4">
                                <label class="form-label" style="color: grey">
                                  {{
                                    curentLang === "en"
                                      ? "Customer Name"
                                      : curentLang === "ar"
                                      ? "اسم العميل"
                                      : " שם לקוח "
                                  }}
                                  <span class="text-danger" style="color: red"
                                    >*</span
                                  >
                                </label>
                                <input
                                  style="color: grey"
                                  class="form-control"
                                  type="text"
                                  formControlName="customer_name_he"
                                  maxlength="80"
                                  required
                                  [ngClass]="{
                                    'is-invalid':
                                      validate &&
                                      addCustomer.controls['customer_name_he']
                                        .invalid
                                  }"
                                />
                                <div
                                  *ngIf="
                                    validate &&
                                    addCustomer.controls['customer_name_he']
                                      .invalid
                                  "
                                  class="invalid-feedback text-danger"
                                >
                                  {{
                                    curentLang === "en"
                                      ? "Please enter customer name"
                                      : curentLang === "ar"
                                      ? "الرجاء ادخال اسم العميل"
                                      : "נא להזין את שם הלקוח"
                                  }}
                                </div>
                              </div>

                              <div class="mb-3 col-xl-4 col-md-4">
                                <label class="form-label" style="color: grey">
                                  {{
                                    curentLang === "en"
                                      ? "Customer Type"
                                      : curentLang === "ar"
                                      ? "نوع العميل"
                                      : "סוג לקוח"
                                  }}
                                  <span class="text-danger" style="color: red"
                                    >*</span
                                  >
                                </label>
                                <select
                                  class="form-control form-select"
                                  formControlName="customer_type"
                                  [ngClass]="{
                                    'is-invalid':
                                      validate &&
                                      addCustomer.controls['customer_type']
                                        .invalid
                                  }"
                                >
                                  <option
                                    *ngFor="
                                      let item of customerTypeData;
                                      let i = index
                                    "
                                    [value]="item?.lookup_detail_id"
                                  >
                                    {{
                                      curentLang === "en"
                                        ? item?.lookupDetailEnName
                                        : curentLang === "ar"
                                        ? item?.lookupDetailArName
                                        : item?.lookupDetailHeName
                                    }}
                                  </option>
                                </select>
                                <div
                                  *ngIf="
                                    validate &&
                                    addCustomer.controls['customer_type']
                                      .invalid
                                  "
                                  class="invalid-feedback text-danger"
                                >
                                  {{
                                    curentLang === "en"
                                      ? "Please choose customer type"
                                      : curentLang === "ar"
                                      ? "الرجاء اختيار نوع العميل"
                                      : "אנא בחר סוג לקוח"
                                  }}
                                </div>
                              </div>

                              <div class="mb-3 col-xl-4 col-md-4">
                                <label class="form-label" style="color: grey">
                                  {{
                                    curentLang === "en"
                                      ? "Customer Phone"
                                      : curentLang === "ar"
                                      ? "هاتف العميل"
                                      : "טלפון לקוח"
                                  }}
                                </label>
                                <div class="input-group">
                                  <span
                                    style="color: #141414"
                                    class="input-group-text"
                                    >+972</span
                                  >
                                  <input
                                    class="form-control"
                                    type="text"
                                    maxlength="10"
                                    formControlName="customer_phone"
                                    [ngClass]="{
                                      'is-invalid':
                                        validate &&
                                        addCustomer.controls['customer_phone']
                                          .invalid
                                    }"
                                  />
                                </div>
                                <div
                                  *ngIf="
                                    validate &&
                                    addCustomer.controls['customer_phone']
                                      .invalid
                                  "
                                  class="invalid-feedback text-danger"
                                >
                                  {{
                                    curentLang === "en"
                                      ? "Please enter a valid phone number"
                                      : curentLang === "ar"
                                      ? "الرجاء إدخال رقم هاتف صالح"
                                      : "נא להזין מספר טלפון תקין"
                                  }}
                                </div>
                              </div>
                            </div>

                            <!-- الصف الثاني -->
                            <div class="row">
                              <div
                                class="mb-3 col-xl-4 col-md-4"
                                *ngIf="addCustomer.value.customer_type != 6"
                              >
                                <label class="form-label" style="color: grey">
                                  {{
                                    curentLang === "en"
                                      ? "ID Number"
                                      : curentLang === "ar"
                                      ? "رقم الهوية"
                                      : "מספר ישות (ת.ז / ח.פ.)"
                                  }}
                                </label>
                                <input
                                  style="color: grey"
                                  class="form-control"
                                  type="number"
                                  formControlName="id_number"
                                  maxlength="9"
                                />
                              </div>

                              <div class="mb-3 col-xl-4 col-md-4">
                                <label class="form-label" style="color: grey">
                                  {{
                                    curentLang === "en"
                                      ? "City Name  "
                                      : curentLang === "ar"
                                      ? " اسم المدينة "
                                      : "שם העיר"
                                  }}
                                  <!-- <span class="text-danger" style="color: red;">*</span> -->
                                </label>
                                <input
                                  style="color: grey"
                                  class="form-control"
                                  type="text"
                                  formControlName="customer_address_city"
                                  maxlength="100"
                                />
                                <!-- <div *ngIf="validate && addCustomer.controls['customer_address_city'].invalid" class="invalid-feedback text-danger">
                              {{ curentLang === 'en' ? 'Please enter customer city' : (curentLang === 'ar' ? 'الرجاء إدخال مدينة العميل' : 'אנא הזן עיר לקוח') }}
                          </div> -->
                              </div>

                              <div class="mb-3 col-xl-4 col-md-4">
                                <label class="form-label" style="color: grey">
                                  {{
                                    curentLang === "en"
                                      ? "Street Name"
                                      : curentLang === "ar"
                                      ? "أسم الشارع"
                                      : "שם רחוב"
                                  }}
                                  <!-- <span class="text-danger" style="color: red;">*</span> -->
                                </label>
                                <input
                                  style="color: grey"
                                  class="form-control"
                                  type="text"
                                  formControlName="customer_address_str"
                                  maxlength="100"
                                />
                                <!-- <div *ngIf="validate && addCustomer.controls['customer_address_str'].invalid" class="invalid-feedback text-danger">
                              {{ curentLang === 'en' ? 'Please enter customer street' : (curentLang === 'ar' ? 'الرجاء إدخال شارع العميل' : 'אנא הזן רחוב לקוח') }}
                          </div> -->
                              </div>
                            </div>

                            <!-- الصف الثالث -->
                            <div class="row">
                              <div class="mb-3 col-xl-4 col-md-4">
                                <label class="form-label" style="color: grey">
                                  {{
                                    curentLang === "en"
                                      ? "Building Number"
                                      : curentLang === "ar"
                                      ? "رقم المبني"
                                      : "מספר בניין"
                                  }}
                                  <!-- <span class="text-danger" style="color: red;">*</span> -->
                                </label>
                                <input
                                  style="color: grey"
                                  class="form-control"
                                  type="number"
                                  formControlName="customer_Building_number"
                                  maxlength="100"
                                />
                                <!-- <div *ngIf="validate && addCustomer.controls['customer_Building_number'].invalid" class="invalid-feedback text-danger">
                              {{ curentLang === 'en' ? 'Please enter customer building number' : (curentLang === 'ar' ? 'الرجاء إدخال رقم مبنى العميل' : 'אנא הזן מספר בניין לקוח') }}
                          </div> -->
                              </div>

                              <div class="mb-3 col-xl-4 col-md-4">
                                <label class="form-label" style="color: grey">
                                  {{
                                    curentLang === "en"
                                      ? "Zip Code"
                                      : curentLang === "ar"
                                      ? "الرقم البريدي"
                                      : "מיקוד דואר"
                                  }}
                                  <!-- <span class="text-danger" style="color: red;">*</span> -->
                                </label>
                                <input
                                  style="color: grey"
                                  class="form-control"
                                  type="number"
                                  formControlName="customer_address_zip_code"
                                  maxlength="20"
                                />
                                <!-- <div *ngIf="validate && addCustomer.controls['customer_address_zip_code'].invalid" class="invalid-feedback text-danger">
                              {{ curentLang === 'en' ? 'Please enter customer zip code' : (curentLang === 'ar' ? 'الرجاء إدخال الرقم البريدي للعميل' : 'אנא הזן את המיקוד של הלקוח') }}
                          </div> -->
                              </div>

                              <div class="mb-3 col-xl-4 col-md-4">
                                <label class="form-label" style="color: grey">
                                  {{
                                    curentLang === "en"
                                      ? "Customer Vat Number"
                                      : curentLang === "ar"
                                      ? "الرقم الضريبي للعميل"
                                      : "מספר תיק במס ההכנסה"
                                  }}
                                </label>
                                <input
                                  style="color: grey"
                                  class="form-control"
                                  type="text"
                                  formControlName="customer_vat_number"
                                  maxlength="9"
                                  pattern="[0-9]{9}"
                                  title="{{
                                    curentLang === 'en'
                                      ? 'Please enter exactly 9 digits'
                                      : curentLang === 'ar'
                                      ? 'يرجى إدخال 9 أرقام فقط'
                                      : 'אנא הזן 9 ספרות בדיוק'
                                  }}"
                                  [ngClass]="{
                                    'is-invalid':
                                      validate &&
                                      addCustomer.controls[
                                        'customer_vat_number'
                                      ].invalid
                                  }"
                                />
                                <div
                                  *ngIf="
                                    validate &&
                                    addCustomer.controls['customer_vat_number']
                                      .invalid
                                  "
                                  class="invalid-feedback text-danger"
                                >
                                  {{
                                    curentLang === "en"
                                      ? "Please enter exactly 9 digits"
                                      : curentLang === "ar"
                                      ? "يرجى إدخال 9 أرقام فقط"
                                      : "אנא הזן 9 ספרות בדיוק"
                                  }}
                                  <span class="text-danger" style="color: red"
                                    >*</span
                                  >
                                </div>
                              </div>
                            </div>

                            <!-- الصف الرابع (حقل ضريبة القيمة المضافة، أو يمكنك إضافة المزيد من الحقول) -->
                            <div class="row">
                              <div
                                class="mb-3 col-xl-4 col-md-4"
                                *ngIf="addCustomer.value.customer_type != 5"
                              >
                                <label class="form-label" style="color: grey">
                                  {{
                                    curentLang === "en"
                                      ? "Customer Vat Tax"
                                      : curentLang === "ar"
                                      ? "رقم ضريبة القيمة المضافة"
                                      : "  מס עוסק מורשה   "
                                  }}
                                </label>
                                <input
                                  style="color: grey"
                                  class="form-control"
                                  type="number"
                                  formControlName="customer_vat_tax"
                                  maxlength="9"
                                  [ngClass]="{
                                    'is-invalid':
                                      validate &&
                                      addCustomer.controls['customer_vat_tax']
                                        .invalid
                                  }"
                                />
                                <div
                                  *ngIf="
                                    validate &&
                                    addCustomer.controls['customer_vat_tax']
                                      .invalid
                                  "
                                  class="invalid-feedback text-danger"
                                >
                                  {{
                                    curentLang === "en"
                                      ? "Please enter a valid VAT number"
                                      : curentLang === "ar"
                                      ? "الرجاء إدخال رقم ضريبة القيمة المضافة الصحيح"
                                      : 'אנא הזן מספר מס מע"מ תקין'
                                  }}
                                </div>
                              </div>
                            </div>

                            <hr />
                            <!--
                  <h6 class="modal-title" style="color: grey;">
                    {{ curentLang === 'en' ? 'Additional fields' : (curentLang === 'ar' ? ' حقول اضافية   ' : 'שדות נוספים')}} 
                </h6>
                <hr style="width: 25%;"> 
-->
                            <div class="row">
                              <!--   <div class="mb-3 col-xl-6 col-md-6">
                    <label class="form-label" style="color: grey;">
                        {{ curentLang === 'en' ? 'City Name Ar' : (curentLang === 'ar' ? 'اسم المدينة عربي' : 'שם עיר בערבית') }}
                         <span class="text-danger" style="color: red;">*</span>  
                    </label>
                    <input 
                        style="color: grey;" 
                        class="form-control"
                        type="text" 
                        formControlName="customer_address_city_ar"
                        maxlength="100"
                        
                    />
                   <div *ngIf="validate && addCustomer.controls['customer_address_city'].invalid" class="invalid-feedback text-danger">
                        {{ curentLang === 'en' ? 'Please enter customer city' : (curentLang === 'ar' ? 'الرجاء إدخال مدينة العميل' : 'אנא הזן עיר לקוח') }}
                    </div> 
                </div>-->

                              <!--    <div class="mb-3 col-xl-6 col-md-6">
                  <label class="form-label" style="color: grey;">
                      {{ curentLang === 'en' ? 'City Name En' : (curentLang === 'ar' ? 'اسم المدينة انجليزي' : 'שם העיר באנגלית') }}
                       <span class="text-danger" style="color: red;">*</span>  
                  </label>
                  <input 
                      style="color: grey;" 
                      class="form-control"
                      type="text" 
                      formControlName="customer_address_city_en"
                      maxlength="100"
                      
                  />
                   <div *ngIf="validate && addCustomer.controls['customer_address_city'].invalid" class="invalid-feedback text-danger">
                      {{ curentLang === 'en' ? 'Please enter customer city' : (curentLang === 'ar' ? 'الرجاء إدخال مدينة العميل' : 'אנא הזן עיר לקוח') }}
                  </div>  
              </div>
                  -->

                              <!--   <div class="mb-3 col-xl-6 col-md-6">
                    <label class="form-label" style="color: grey;">
                        {{ curentLang === 'en' ? 'Street Name Ar' : (curentLang === 'ar' ? 'اسم الشارع عربي' : "שם הרחוב הוא ערבי") }}
                          <span class="text-danger" style="color: red;">*</span>  
                    </label>
                    <input 
                        style="color: grey;" 
                        class="form-control"
                        type="text" 
                        formControlName="customer_address_str_ar"
                        maxlength="100"
                    />
                     <div *ngIf="validate && addCustomer.controls['customer_address_str'].invalid" class="invalid-feedback text-danger">
                        {{ curentLang === 'en' ? 'Please enter customer street' : (curentLang === 'ar' ? 'الرجاء إدخال شارع العميل' : 'אנא הזן רחוב לקוח') }}
                    </div> 
                </div>-->

                              <!--  <div class="mb-3 col-xl-6 col-md-6">
                  <label class="form-label" style="color: grey;">
                      {{ curentLang === 'en' ? 'Street Name En' : (curentLang === 'ar' ? 'اسم الشارع انجليزي' : 'שם רחוב באנגלית') }}
                       <span class="text-danger" style="color: red;">*</span> 
                  </label>
                  <input 
                      style="color: grey;" 
                      class="form-control"
                      type="text" 
                      formControlName="customer_address_str_en"
                      maxlength="100"
                  />
                <div *ngIf="validate && addCustomer.controls['customer_address_str'].invalid" class="invalid-feedback text-danger">
                      {{ curentLang === 'en' ? 'Please enter customer street' : (curentLang === 'ar' ? 'الرجاء إدخال شارع العميل' : 'אנא הזן רחוב לקוח') }}
                  </div>  
              </div>-->
                            </div>

                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-sm m-auto mt-2"
                                style="background-color: #141414; color: #fff"
                                (click)="addedData()"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Confirm"
                                    : curentLang === "ar"
                                    ? "تأكيد"
                                    : "אישור"
                                }}
                                <i
                                  class="fa fa-check-circle mx-1"
                                  style="color: #fff"
                                ></i>
                              </button>
                              <button
                                type="button"
                                class="btn btn-sm m-auto mt-2 btn-danger"
                                (click)="modal.close('Close click')"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Back"
                                    : curentLang === "ar"
                                    ? "رجوع"
                                    : "חזרה"
                                }}
                                <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <!-- <div class="col-md-2" style="padding-top: 42px">
                    <button
                      [hidden]="user_type == 200"
                      class="btn m-1"
                      style="background-color: #141414; color: #fff"
                      (click)="openXlCustomer(contentAddCustomer)"
                    >
                      <i class="fa fa-plus-circle mx-1"></i>
                      {{
                        curentLang === "en"
                          ? "Add Customer"
                          : curentLang === "ar"
                          ? "  اضافة عميل"
                          : " הוסף לקוח "
                      }}
                    </button>
                  </div> -->

                  <div class="col-lg-3">
                    <div class="form-group mx-2">
                      <label class="mt-2">
                        {{
                          curentLang === "en"
                            ? "Receipt Date"
                            : curentLang === "ar"
                            ? "تاريخ الايصال"
                            : "תאריך קבלה  "
                        }}</label
                      >

                      <!--    <p-calendar   style="display: block; width: 100%;"
                                            showIcon="true"  
                                               formControlName="invoice_date" 
                                               dateFormat="dd/mm/yy"
                                                placeholder="DD/MM/YYYY"
                                                 >
                                               </p-calendar>
                                           -->
                      <input
                        type="date"
                        (change)="filterDate($event)"
                        formControlName="invoice_date"
                        class="form-control w-100"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <!-- Toggle Button -->
                    <div class="form-group mx-2">
                      <label
                      class="mt-2"
                      style="cursor: pointer; color: black; text-decoration: underline;"
                      (click)="toggleFieldsVisibility()"
                    >
                      {{
                        curentLang === "en"
                          ? "Additional Info"
                          : curentLang === "ar"
                          ? "معلومات إضافية"
                          : "מידע נוסף"
                      }}
                    </label>
                    </div>
                  </div>
                  
                  <!-- Fields to Show/Hide -->
                  <div *ngIf="showFields">
                    <div class="col-lg-3">
                      <div class="form-group mx-2">
                        <label class="mt-2">
                          {{
                            curentLang === "en"
                              ? "Document Language"
                              : curentLang === "ar"
                              ? "لغة المستند "
                              : "שפת מסמך"
                          }}
                        </label>
                        <select
                          formControlName="document_language"
                          class="form-control form-select"
                          (change)="filter($event)"
                        >
                          <option
                            *ngFor="let lang of availableLanguages"
                            [value]="lang.code"
                            [selected]="lang.code === 'he'"
                          >
                            {{ lang.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  
                    <div class="col-lg-3">
                      <div class="form-group mx-2">
                        <label class="mt-2">
                          {{
                            curentLang === "en"
                              ? "Currency"
                              : curentLang === "ar"
                              ? "العملة"
                              : "מטבע"
                          }}
                        </label>
                        <input
                          type="text"
                          formControlName="currency"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <br />
                <!-- <div class="form-group">
                    <label>
                      {{
                        curentLang === 'en'
                          ? 'Payment Method'
                          : curentLang === 'ar'
                          ? 'طريقة الدفع'
                          : 'אמצעי תשלום'
                      }}
                    </label>
                  
                    <ng-select
                    [items]="paymentMethods"
                    bindLabel="name_{{ curentLang }}"
                    bindValue="code"
                    [multiple]="true"
                    [formControl]="receiptForm.get('selectedPaymentMethod')"
                    [disabled]="!receiptForm.get('customer_id')?.value || !addCustomer.get('customer_name_he')?.value"
                    [class.is-invalid]="
                      receiptForm.get('selectedPaymentMethod')?.invalid &&
                      receiptForm.get('selectedPaymentMethod')?.touched
                    "
                    (change)="onPaymentMethodChange($event)"
                    placeholder="{{
                      curentLang === 'en'
                        ? 'Select Payment Method'
                        : curentLang === 'ar'
                        ? 'اختر طريقة الدفع'
                        : 'בחר אמצעי תשלום'
                    }}"
                  >
                  </ng-select>
                  
                    <div
                      class="invalid-feedback d-block"
                      *ngIf="
                        receiptForm.get('selectedPaymentMethod')?.invalid &&
                        receiptForm.get('selectedPaymentMethod')?.touched
                      "
                    >
                      {{
                        curentLang === 'en'
                          ? 'Please select a payment method.'
                          : curentLang === 'ar'
                          ? 'يرجى اختيار طريقة الدفع.'
                          : 'אנא בחר אמצעי תשלום.'
                      }}
                    </div>
                  </div> -->
                <!-- Start Payment Method -->
                <!-- Check modal -->
                <ng-template #checkModal let-modal>
                  <div class="modal-header">
                    <h6 class="modal-title">
                      {{
                        curentLang === "en"
                          ? "Check Details"
                          : curentLang === "ar"
                          ? "تفاصيل الشيك"
                          : "פרטי שיק"
                      }}
                    </h6>
                    <button
                      type="button"
                      class="btn-close"
                      (click)="modal.dismiss()"
                    ></button>
                  </div>

                  <div class="modal-body" formArrayName="linesChecks">
                    <!-- Desktop Table -->
                    <div class="table-responsive d-none d-lg-block">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Date"
                                  : curentLang === "ar"
                                  ? "التاريخ"
                                  : "תאריך"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Reference"
                                  : curentLang === "ar"
                                  ? "رقم الشيك"
                                  : "מס' שיק"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Bank"
                                  : curentLang === "ar"
                                  ? "البنك"
                                  : "בנק"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Branch"
                                  : curentLang === "ar"
                                  ? "الفرع"
                                  : "סניף"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Account"
                                  : curentLang === "ar"
                                  ? "الحساب"
                                  : "חשבון"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Amount"
                                  : curentLang === "ar"
                                  ? "المبلغ"
                                  : "סכום"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Remove"
                                  : curentLang === "ar"
                                  ? "حذف"
                                  : "מחק"
                              }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of linesChecks.controls;
                              let i = index
                            "
                            [formGroupName]="i"
                          >
                            <td>
                              <input
                                type="date"
                                class="form-control"
                                formControlName="date"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control"
                                formControlName="reference"
                              />
                            </td>
                            <td>
                              <ng-select
                                formControlName="bank"
                                (change)="getBranches($event, i)"
                              >
                                <ng-option
                                  *ngFor="let item of masterBank"
                                  [value]="item.bank_code"
                                >
                                  {{ getBankLabel(item) }}
                                </ng-option>
                              </ng-select>
                            </td>
                            <td>
                              <ng-select formControlName="branch">
                                <ng-option
                                  *ngFor="let item of getBranchesForIndex(i)"
                                  [value]="item.bank_branch_code"
                                >
                                  {{ getBranchLabel(item) }}
                                </ng-option>
                              </ng-select>
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control"
                                formControlName="account"
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control"
                                formControlName="amount"
                              />
                            </td>
                            <td>
                              <i
                                class="fa fa-trash"
                                style="color: red; cursor: pointer"
                                (click)="removeLineFromChecks(i)"
                              ></i>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="6">
                              <button
                                class="btn btn-dark"
                                (click)="addNewLineToChecks()"
                              >
                                <i class="fa fa-plus-circle me-1"></i>
                                {{
                                  curentLang === "en"
                                    ? "Add Check"
                                    : curentLang === "ar"
                                    ? "أضف شيك"
                                    : "הוסף שיק"
                                }}
                              </button>
                            </td>
                            <td>
                              <input
                                type="number"
                                readonly
                                [value]="getTotalAmountChecksArray()"
                                class="form-control"
                              />
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>

                    <!-- Mobile Layout -->
                    <div class="d-lg-none">
                      <div
                        *ngFor="let item of linesChecks.controls; let i = index"
                        class="border p-3 my-2"
                        [formGroupName]="i"
                      >
                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Date"
                              : curentLang === "ar"
                              ? "التاريخ"
                              : "תאריך"
                          }}</label>
                          <input
                            class="form-control"
                            formControlName="date"
                            type="date"
                          />
                          <small
                            *ngIf="
                              item.get('date')?.invalid &&
                              item.get('date')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Date is required"
                                : curentLang === "ar"
                                ? "التاريخ مطلوب"
                                : "תאריך נדרש"
                            }}
                          </small>
                        </div>

                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Reference"
                              : curentLang === "ar"
                              ? "رقم الشيك"
                              : "מס' שיק"
                          }}</label>
                          <input
                            class="form-control"
                            formControlName="reference"
                            type="number"
                          />
                          <small
                            *ngIf="
                              item.get('reference')?.invalid &&
                              item.get('reference')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Reference is required"
                                : curentLang === "ar"
                                ? "رقم الشيك مطلوب"
                                : "מספר שיק נדרש"
                            }}
                          </small>
                        </div>

                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Bank"
                              : curentLang === "ar"
                              ? "البنك"
                              : "בנק"
                          }}</label>
                          <ng-select
                            formControlName="bank"
                            (change)="getBranches($event, i)"
                          >
                            <ng-option
                              *ngFor="let item of masterBank"
                              [value]="item.bank_code"
                            >
                              {{ getBankLabel(item) }}
                            </ng-option>
                          </ng-select>
                          <small
                            *ngIf="
                              item.get('bank')?.invalid &&
                              item.get('bank')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Bank is required"
                                : curentLang === "ar"
                                ? "البنك مطلوب"
                                : "נדרש לבחור בנק"
                            }}
                          </small>
                        </div>

                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Branch"
                              : curentLang === "ar"
                              ? "الفرع"
                              : "סניף"
                          }}</label>
                          <ng-select formControlName="branch">
                            <ng-option
                              *ngFor="let item of getBranchesForIndex(i)"
                              [value]="item.bank_branch_code"
                            >
                              {{ getBranchLabel(item) }}
                            </ng-option>
                          </ng-select>
                          <small
                            *ngIf="
                              item.get('branch')?.invalid &&
                              item.get('branch')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Branch is required"
                                : curentLang === "ar"
                                ? "الفرع مطلوب"
                                : "נדרש לבחור סניף"
                            }}
                          </small>
                        </div>

                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Account"
                              : curentLang === "ar"
                              ? "الحساب"
                              : "חשבון"
                          }}</label>
                          <input
                            class="form-control"
                            formControlName="account"
                            type="number"
                          />
                          <small
                            *ngIf="
                              item.get('account')?.invalid &&
                              item.get('account')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Account is required"
                                : curentLang === "ar"
                                ? "رقم الحساب مطلوب"
                                : "מספר חשבון נדרש"
                            }}
                          </small>
                        </div>

                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Amount"
                              : curentLang === "ar"
                              ? "المبلغ"
                              : "סכום"
                          }}</label>
                          <input
                            class="form-control"
                            formControlName="amount"
                            type="number"
                          />
                          <small
                            *ngIf="
                              item.get('amount')?.invalid &&
                              item.get('amount')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Amount is required"
                                : curentLang === "ar"
                                ? "المبلغ مطلوب"
                                : "סכום נדרש"
                            }}
                          </small>
                        </div>

                        <div class="text-center">
                          <button
                            class="btn btn-sm btn-danger"
                            (click)="removeLineFromChecks(i)"
                          >
                            <i class="fa fa-trash me-1"></i>
                            {{
                              curentLang === "en"
                                ? "Delete"
                                : curentLang === "ar"
                                ? "حذف"
                                : "מחק"
                            }}
                          </button>
                        </div>
                      </div>

                      <div class="text-center my-2">
                        <input
                          type="number"
                          readonly
                          [value]="getTotalAmountChecksArray()"
                          class="form-control"
                        />
                      </div>

                      <div class="text-center">
                        <button
                          class="btn btn-dark"
                          (click)="addNewLineToChecks()"
                        >
                          <i class="fa fa-plus-circle me-1"></i>
                          {{
                            curentLang === "en"
                              ? "Add Check"
                              : curentLang === "ar"
                              ? "أضف شيك"
                              : "הוסף שיק"
                          }}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="modal-footer">
                    <button
                      class="btn btn-dark"
                      (click)="onConfirmCheckModal(modal)"
                    >
                      {{
                        curentLang === "en"
                          ? "Confirm"
                          : curentLang === "ar"
                          ? "تأكيد"
                          : "אישור"
                      }}
                    </button>
                  </div>
                </ng-template>
                <!-- Cash Modal -->
                <ng-template #cashModal let-modal>
                  <div class="modal-header">
                    <h6 class="modal-title">
                      {{
                        curentLang === "en"
                          ? "Cash Payment"
                          : curentLang === "ar"
                          ? "الدفع النقدي"
                          : "תשלום מזומן"
                      }}
                    </h6>
                    <button
                      type="button"
                      class="btn-close"
                      (click)="modal.dismiss()"
                    ></button>
                  </div>

                  <div class="modal-body" formArrayName="linesCash">
                    <!-- Desktop View -->
                    <div class="table-responsive d-none d-lg-block">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Date"
                                  : curentLang === "ar"
                                  ? "التاريخ"
                                  : "תאריך"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Amount"
                                  : curentLang === "ar"
                                  ? "المبلغ"
                                  : "סכום"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Remove"
                                  : curentLang === "ar"
                                  ? "حذف"
                                  : "מחק"
                              }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of linesCash.controls;
                              let i = index
                            "
                            [formGroupName]="i"
                          >
                            <td>
                              <input
                                type="date"
                                class="form-control"
                                formControlName="date"
                              />
                              <small
                                *ngIf="
                                  item.get('date')?.invalid &&
                                  item.get('date')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Date is required"
                                    : curentLang === "ar"
                                    ? "التاريخ مطلوب"
                                    : "תאריך נדרש"
                                }}
                              </small>
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control"
                                formControlName="amount"
                              />
                              <small
                                *ngIf="
                                  item.get('amount')?.invalid &&
                                  item.get('amount')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Amount is required"
                                    : curentLang === "ar"
                                    ? "المبلغ مطلوب"
                                    : "סכום נדרש"
                                }}
                              </small>
                            </td>
                            <td>
                              <i
                                class="fa fa-trash"
                                style="color: red; cursor: pointer"
                                (click)="removeLineFromCash(i)"
                              ></i>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="2">
                              <button
                                class="btn btn-dark"
                                (click)="addNewLineToCash()"
                              >
                                <i class="fa fa-plus-circle me-1"></i>
                                {{
                                  curentLang === "en"
                                    ? "Add Cash"
                                    : curentLang === "ar"
                                    ? "أضف نقدي"
                                    : "הוסף מזומן"
                                }}
                              </button>
                            </td>
                            <td>
                              <input
                                type="number"
                                readonly
                                [value]="getTotalAmountCashArray()"
                                class="form-control"
                              />
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>

                    <!-- Mobile View -->
                    <div class="d-lg-none">
                      <div
                        *ngFor="let item of linesCash.controls; let i = index"
                        class="border p-3 my-2"
                        [formGroupName]="i"
                      >
                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Date"
                              : curentLang === "ar"
                              ? "التاريخ"
                              : "תאריך"
                          }}</label>
                          <input
                            class="form-control"
                            formControlName="date"
                            type="date"
                          />
                          <small
                            *ngIf="
                              item.get('date')?.invalid &&
                              item.get('date')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Date is required"
                                : curentLang === "ar"
                                ? "التاريخ مطلوب"
                                : "תאריך נדרש"
                            }}
                          </small>
                        </div>

                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Amount"
                              : curentLang === "ar"
                              ? "المبلغ"
                              : "סכום"
                          }}</label>
                          <input
                            class="form-control"
                            formControlName="amount"
                            type="number"
                          />
                          <small
                            *ngIf="
                              item.get('amount')?.invalid &&
                              item.get('amount')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Amount is required"
                                : curentLang === "ar"
                                ? "المبلغ مطلوب"
                                : "סכום נדרש"
                            }}
                          </small>
                        </div>

                        <div class="text-center">
                          <button
                            class="btn btn-sm btn-danger"
                            (click)="removeLineFromCash(i)"
                          >
                            <i class="fa fa-trash me-1"></i>
                            {{
                              curentLang === "en"
                                ? "Delete"
                                : curentLang === "ar"
                                ? "حذف"
                                : "מחק"
                            }}
                          </button>
                        </div>
                      </div>

                      <div class="text-center my-2">
                        <input
                          type="number"
                          readonly
                          [value]="getTotalAmountCashArray()"
                          class="form-control"
                        />
                      </div>

                      <div class="text-center">
                        <button
                          class="btn btn-dark"
                          (click)="addNewLineToCash()"
                        >
                          <i class="fa fa-plus-circle me-1"></i>
                          {{
                            curentLang === "en"
                              ? "Add Cash"
                              : curentLang === "ar"
                              ? "أضف نقدي"
                              : "הוסף מזומן"
                          }}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="modal-footer">
                    <button
                      class="btn btn-dark"
                      (click)="onConfirmCashModal(modal)"
                    >
                      {{
                        curentLang === "en"
                          ? "Confirm"
                          : curentLang === "ar"
                          ? "تأكيد"
                          : "אישור"
                      }}
                    </button>
                  </div>
                </ng-template>
                <!-- Bank modal -->
                <ng-template #bankModal let-modal>
                  <div class="modal-header">
                    <h6 class="modal-title">
                      {{
                        curentLang === "en"
                          ? "Bank Transfer"
                          : curentLang === "ar"
                          ? "حوالة بنكية"
                          : "העברה בנקאית"
                      }}
                    </h6>
                    <button
                      type="button"
                      class="btn-close"
                      (click)="modal.dismiss()"
                    ></button>
                  </div>

                  <div class="modal-body" formArrayName="linesBankPayment">
                    <!-- Desktop View -->
                    <div class="table-responsive d-none d-lg-block">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Date"
                                  : curentLang === "ar"
                                  ? "التاريخ"
                                  : "תאריך"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Bank"
                                  : curentLang === "ar"
                                  ? "البنك"
                                  : "בנק"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Account"
                                  : curentLang === "ar"
                                  ? "الحساب"
                                  : "חשבון"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Amount"
                                  : curentLang === "ar"
                                  ? "المبلغ"
                                  : "סכום"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Remove"
                                  : curentLang === "ar"
                                  ? "حذف"
                                  : "מחק"
                              }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of linesBankPayment.controls;
                              let i = index
                            "
                            [formGroupName]="i"
                          >
                            <td>
                              <input
                                class="form-control"
                                type="date"
                                formControlName="date"
                              />
                              <small
                                *ngIf="
                                  item.get('date')?.invalid &&
                                  item.get('date')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Date is required"
                                    : curentLang === "ar"
                                    ? "التاريخ مطلوب"
                                    : "תאריך נדרש"
                                }}
                              </small>
                            </td>
                            <td>
                              <ng-select
                                formControlName="bank"
                                (change)="getBranches2($event, i)"
                              >
                                <ng-option
                                  *ngFor="let item of masterBank"
                                  [value]="item.bank_code"
                                >
                                  {{ getBankLabel(item) }}
                                </ng-option>
                              </ng-select>
                              <small
                                *ngIf="
                                  item.get('bank')?.invalid &&
                                  item.get('bank')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Bank is required"
                                    : curentLang === "ar"
                                    ? "البنك مطلوب"
                                    : "נדרש לבחור בנק"
                                }}
                              </small>
                            </td>
                            <td>
                              <input
                                class="form-control"
                                formControlName="account"
                                type="number"
                              />
                              <small
                                *ngIf="
                                  item.get('account')?.invalid &&
                                  item.get('account')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Account is required"
                                    : curentLang === "ar"
                                    ? "رقم الحساب مطلوب"
                                    : "מספר חשבון נדרש"
                                }}
                              </small>
                            </td>
                            <td>
                              <input
                                class="form-control"
                                formControlName="amount"
                                type="number"
                              />
                              <small
                                *ngIf="
                                  item.get('amount')?.invalid &&
                                  item.get('amount')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Amount is required"
                                    : curentLang === "ar"
                                    ? "المبلغ مطلوب"
                                    : "סכום נדרש"
                                }}
                              </small>
                            </td>
                            <td>
                              <i
                                class="fa fa-trash"
                                style="color: red; cursor: pointer"
                                (click)="removeLineFromBankPayment(i)"
                              ></i>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="4">
                              <button
                                class="btn btn-dark"
                                (click)="addNewLineToBankPayment()"
                              >
                                <i class="fa fa-plus-circle me-1"></i>
                                {{
                                  curentLang === "en"
                                    ? "Add Transfer"
                                    : curentLang === "ar"
                                    ? "أضف حوالة"
                                    : "הוסף העברה"
                                }}
                              </button>
                            </td>
                            <td>
                              <input
                                type="number"
                                readonly
                                [value]="getTotalAmountBankPaymentArray()"
                                class="form-control"
                              />
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>

                    <!-- Mobile View -->
                    <div class="d-lg-none">
                      <div
                        *ngFor="
                          let item of linesBankPayment.controls;
                          let i = index
                        "
                        class="border p-3 my-2"
                        [formGroupName]="i"
                      >
                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Date"
                              : curentLang === "ar"
                              ? "التاريخ"
                              : "תאריך"
                          }}</label>
                          <input
                            class="form-control"
                            type="date"
                            formControlName="date"
                          />
                          <small
                            *ngIf="
                              item.get('date')?.invalid &&
                              item.get('date')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Date is required"
                                : curentLang === "ar"
                                ? "التاريخ مطلوب"
                                : "תאריך נדרש"
                            }}
                          </small>
                        </div>

                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Bank"
                              : curentLang === "ar"
                              ? "البنك"
                              : "בנק"
                          }}</label>
                          <ng-select
                            formControlName="bank"
                            (change)="getBranches2($event, i)"
                          >
                            <ng-option
                              *ngFor="let item of masterBank"
                              [value]="item.bank_code"
                            >
                              {{ getBankLabel(item) }}
                            </ng-option>
                          </ng-select>
                          <small
                            *ngIf="
                              item.get('bank')?.invalid &&
                              item.get('bank')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Bank is required"
                                : curentLang === "ar"
                                ? "البنك مطلوب"
                                : "נדרש לבחור בנק"
                            }}
                          </small>
                        </div>

                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Account"
                              : curentLang === "ar"
                              ? "الحساب"
                              : "חשבון"
                          }}</label>
                          <input
                            class="form-control"
                            formControlName="account"
                            type="number"
                          />
                          <small
                            *ngIf="
                              item.get('account')?.invalid &&
                              item.get('account')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Account is required"
                                : curentLang === "ar"
                                ? "رقم الحساب مطلوب"
                                : "מספר חשבון נדרש"
                            }}
                          </small>
                        </div>

                        <div class="form-group">
                          <label>{{
                            curentLang === "en"
                              ? "Amount"
                              : curentLang === "ar"
                              ? "المبلغ"
                              : "סכום"
                          }}</label>
                          <input
                            class="form-control"
                            formControlName="amount"
                            type="number"
                          />
                          <small
                            *ngIf="
                              item.get('amount')?.invalid &&
                              item.get('amount')?.touched
                            "
                            class="text-danger"
                          >
                            {{
                              curentLang === "en"
                                ? "Amount is required"
                                : curentLang === "ar"
                                ? "المبلغ مطلوب"
                                : "סכום נדרש"
                            }}
                          </small>
                        </div>

                        <div class="text-center">
                          <button
                            class="btn btn-sm btn-danger"
                            (click)="removeLineFromBankPayment(i)"
                          >
                            <i class="fa fa-trash me-1"></i>
                            {{
                              curentLang === "en"
                                ? "Delete"
                                : curentLang === "ar"
                                ? "حذف"
                                : "מחק"
                            }}
                          </button>
                        </div>
                      </div>

                      <div class="text-center my-2">
                        <input
                          type="number"
                          readonly
                          [value]="getTotalAmountBankPaymentArray()"
                          class="form-control"
                        />
                      </div>

                      <div class="text-center">
                        <button
                          class="btn btn-dark"
                          (click)="addNewLineToBankPayment()"
                        >
                          <i class="fa fa-plus-circle me-1"></i>
                          {{
                            curentLang === "en"
                              ? "Add Transfer"
                              : curentLang === "ar"
                              ? "أضف حوالة"
                              : "הוסף העברה"
                          }}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="modal-footer">
                    <button
                      class="btn btn-dark"
                      (click)="onConfirmBankModal(modal)"
                    >
                      {{
                        curentLang === "en"
                          ? "Confirm"
                          : curentLang === "ar"
                          ? "تأكيد"
                          : "אישור"
                      }}
                    </button>
                  </div>
                </ng-template>
                <!-- Credit Card Modal -->
                <ng-template #creditModal let-modal>
                  <div class="modal-header">
                    <h6 class="modal-title">
                      {{
                        curentLang === "en"
                          ? "Credit Card Payment"
                          : curentLang === "ar"
                          ? "دفع بطاقة ائتمان"
                          : "תשלום בכרטיס אשראי"
                      }}
                    </h6>
                    <button
                      type="button"
                      class="btn-close"
                      (click)="modal.dismiss()"
                    ></button>
                  </div>

                  <div class="modal-body" formArrayName="linesCredit">
                    <!-- Desktop View -->
                    <div class="table-responsive d-none d-lg-block">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Date"
                                  : curentLang === "ar"
                                  ? "التاريخ"
                                  : "תאריך"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Payment Type"
                                  : curentLang === "ar"
                                  ? "نوع الدفع"
                                  : "סוג תשלום"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Credit Type"
                                  : curentLang === "ar"
                                  ? "نوع الأتمان"
                                  : "סוג אשראי"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Credit Code"
                                  : curentLang === "ar"
                                  ? "رمز البطاقة"
                                  : "קוד אשראי"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Account"
                                  : curentLang === "ar"
                                  ? "الحساب"
                                  : "חשבון"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Amount"
                                  : curentLang === "ar"
                                  ? "المبلغ"
                                  : "סכום"
                              }}
                            </th>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Remove"
                                  : curentLang === "ar"
                                  ? "حذف"
                                  : "מחק"
                              }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of linesCredit.controls;
                              let i = index
                            "
                            [formGroupName]="i"
                          >
                            <td>
                              <input
                                type="date"
                                class="form-control"
                                formControlName="date"
                              />
                              <small
                                *ngIf="
                                  item.get('date')?.invalid &&
                                  item.get('date')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Date is required"
                                    : curentLang === "ar"
                                    ? "التاريخ مطلوب"
                                    : "תאריך נדרש"
                                }}
                              </small>
                            </td>
                            <td>
                              <select
                                class="form-control"
                                formControlName="credit_card_name"
                              >
                                <option [value]="1">Normal</option>
                                <option [value]="2">Payments</option>
                                <option [value]="3">Credit</option>
                                <option [value]="4">Deferred Payment</option>
                                <option [value]="5">Others</option>
                                <option [value]="0">Not Credit Card</option>
                              </select>
                              <small
                                *ngIf="
                                  item.get('credit_card_name')?.invalid &&
                                  item.get('credit_card_name')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Payment Type is required"
                                    : curentLang === "ar"
                                    ? "نوع الدفع مطلوب"
                                    : "שיטת תשלום נדרשת"
                                }}
                              </small>
                            </td>
                            <td>
                              <select
                                class="form-control"
                                formControlName="credit_type"
                              >
                                <option [value]="1">Isracard</option>
                                <option [value]="2">Cal</option>
                                <option [value]="3">Diners</option>
                                <option [value]="4">American Express</option>
                                <option [value]="6">Leumi Card</option>
                                <option [value]="0">Not Credit Card</option>
                              </select>
                              <small
                                *ngIf="
                                  item.get('credit_type')?.invalid &&
                                  item.get('credit_type')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Credit Type is required"
                                    : curentLang === "ar"
                                    ? "نوع الأتمان مطلوب"
                                    : "סוג אשראי נדרש"
                                }}
                              </small>
                            </td>
                            <td>
                              <input
                                class="form-control"
                                formControlName="credit_card_code"
                                type="text"
                              />
                              <small
                                *ngIf="
                                  item.get('credit_card_code')?.invalid &&
                                  item.get('credit_card_code')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Credit Code is required"
                                    : curentLang === "ar"
                                    ? "رمز البطاقة مطلوب"
                                    : "קוד אשראי נדרש"
                                }}
                              </small>
                            </td>
                            <td>
                              <input
                                class="form-control"
                                formControlName="account"
                                type="number"
                              />
                              <small
                                *ngIf="
                                  item.get('account')?.invalid &&
                                  item.get('account')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Account is required"
                                    : curentLang === "ar"
                                    ? "الحساب مطلوب"
                                    : "חשבון נדרש"
                                }}
                              </small>
                            </td>
                            <td>
                              <input
                                class="form-control"
                                formControlName="amount"
                                type="number"
                              />
                              <small
                                *ngIf="
                                  item.get('amount')?.invalid &&
                                  item.get('amount')?.touched
                                "
                                class="text-danger"
                              >
                                {{
                                  curentLang === "en"
                                    ? "Amount is required"
                                    : curentLang === "ar"
                                    ? "المبلغ مطلوب"
                                    : "סכום נדרש"
                                }}
                              </small>
                            </td>
                            <td>
                              <i
                                class="fa fa-trash text-danger"
                                style="cursor: pointer"
                                (click)="removeLineFromCreidtPayment(i)"
                              ></i>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="6">
                              <button
                                class="btn btn-dark"
                                (click)="addNewLineToCredit()"
                              >
                                <i class="fa fa-plus-circle me-1"></i>
                                {{
                                  curentLang === "en"
                                    ? "Add Credit"
                                    : curentLang === "ar"
                                    ? "أضف بطاقة"
                                    : "הוסף אשראי"
                                }}
                              </button>
                            </td>
                            <td>
                              <input
                                type="number"
                                readonly
                                [value]="getTotalAmountCreidtArray()"
                                class="form-control"
                              />
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>

                    <!-- Mobile View -->
                    <div class="d-lg-none">
                      <div
                        *ngFor="let item of linesCredit.controls; let i = index"
                        class="border p-3 my-2"
                        [formGroupName]="i"
                      >
                        <div
                          class="form-group"
                          *ngFor="
                            let field of [
                              {
                                name: 'date',
                                type: 'date',
                                label:
                                  curentLang === 'en'
                                    ? 'Date'
                                    : curentLang === 'ar'
                                    ? 'التاريخ'
                                    : 'תאריך'
                              },
                              {
                                name: 'credit_card_name',
                                type: 'select',
                                label:
                                  curentLang === 'en'
                                    ? 'Payment Type'
                                    : curentLang === 'ar'
                                    ? 'نوع الدفع'
                                    : 'סוג תשלום',
                                options: [
                                  'Normal',
                                  'Payments',
                                  'Credit',
                                  'Deferred Payment',
                                  'Others',
                                  'Not Credit Card'
                                ]
                              },
                              {
                                name: 'credit_type',
                                type: 'select',
                                label:
                                  curentLang === 'en'
                                    ? 'Credit Type'
                                    : curentLang === 'ar'
                                    ? 'نوع الأتمان'
                                    : 'סוג אשראי',
                                options: [
                                  'Isracard',
                                  'Cal',
                                  'Diners',
                                  'American Express',
                                  'Leumi Card',
                                  'Not Credit Card'
                                ]
                              },
                              {
                                name: 'credit_card_code',
                                type: 'text',
                                label:
                                  curentLang === 'en'
                                    ? 'Credit Code'
                                    : curentLang === 'ar'
                                    ? 'رمز البطاقة'
                                    : 'קוד אשראי'
                              },
                              {
                                name: 'account',
                                type: 'number',
                                label:
                                  curentLang === 'en'
                                    ? 'Account'
                                    : curentLang === 'ar'
                                    ? 'الحساب'
                                    : 'חשבון'
                              },
                              {
                                name: 'amount',
                                type: 'number',
                                label:
                                  curentLang === 'en'
                                    ? 'Amount'
                                    : curentLang === 'ar'
                                    ? 'المبلغ'
                                    : 'סכום'
                              }
                            ]
                          "
                        >
                          <label>{{ field.label }}</label>

                          <select
                            *ngIf="field.type === 'select'"
                            class="form-control"
                            [formControlName]="field.name"
                          >
                            <option
                              *ngFor="
                                let option of field.options;
                                let j = index
                              "
                              [value]="j"
                            >
                              {{ option }}
                            </option>
                          </select>

                          <input
                            *ngIf="field.type !== 'select'"
                            class="form-control"
                            [type]="field.type"
                            [formControlName]="field.name"
                          />

                          <small
                            *ngIf="
                              item.get(field.name)?.invalid &&
                              item.get(field.name)?.touched
                            "
                            class="text-danger"
                          >
                            {{ field.label }}
                            {{
                              curentLang === "en"
                                ? "is required"
                                : curentLang === "ar"
                                ? "مطلوب"
                                : "נדרש"
                            }}
                          </small>
                        </div>

                        <div class="text-center">
                          <button
                            class="btn btn-sm btn-danger"
                            (click)="removeLineFromCreidtPayment(i)"
                          >
                            <i class="fa fa-trash me-1"></i>
                            {{
                              curentLang === "en"
                                ? "Delete"
                                : curentLang === "ar"
                                ? "حذف"
                                : "מחק"
                            }}
                          </button>
                        </div>
                      </div>

                      <div class="text-center my-2">
                        <input
                          type="number"
                          readonly
                          [value]="getTotalAmountCreidtArray()"
                          class="form-control"
                        />
                      </div>

                      <div class="text-center">
                        <button
                          class="btn btn-dark"
                          (click)="addNewLineToCredit()"
                        >
                          <i class="fa fa-plus-circle me-1"></i>
                          {{
                            curentLang === "en"
                              ? "Add Credit"
                              : curentLang === "ar"
                              ? "أضف بطاقة"
                              : "הוסף אשראי"
                          }}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="modal-footer">
                    <button
                      class="btn btn-dark"
                      (click)="onConfirmCreditModal(modal)"
                    >
                      {{
                        curentLang === "en"
                          ? "Confirm"
                          : curentLang === "ar"
                          ? "تأكيد"
                          : "אישור"
                      }}
                    </button>
                  </div>
                </ng-template>
                <!-- Payment Method Modal -->
                <ng-template #paymentMethodModal let-modal>
                  <div class="modal-header">
                    <h6 class="modal-title">
                      {{
                        curentLang === "en"
                          ? "Choose Payment Method"
                          : curentLang === "ar"
                          ? "اختر طريقة الدفع"
                          : "בחר אמצעי תשלום"
                      }}
                    </h6>
                    <button
                      type="button"
                      class="btn-close"
                      (click)="modal.dismiss()"
                    ></button>
                  </div>
                
                  <div class="modal-body">
                    <!-- Desktop View -->
                    <div class="table-responsive d-none d-lg-block">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>
                              {{
                                curentLang === "en"
                                  ? "Payment Method"
                                  : curentLang === "ar"
                                  ? "طريقة الدفع"
                                  : "אמצעי תשלום"
                              }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let method of paymentMethods"
                            (click)="onSelectPaymentMethod(method.code, modal)"
                            style="cursor: pointer"
                          >
                            <td>{{ method["name_" + curentLang] }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                
                    <!-- Mobile View -->
                    <div class="d-lg-none">
                      <div
                        *ngFor="let method of paymentMethods"
                        class="border p-3 my-2"
                        (click)="onSelectPaymentMethod(method.code, modal)"
                        style="cursor: pointer"
                      >
                        <div class="form-group">
                          <label>{{ method["name_" + curentLang] }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                
                  <div class="modal-footer">
                    <button
                      class="btn btn-dark"
                      (click)="modal.dismiss()"
                    >
                      {{
                        curentLang === "en"
                          ? "Close"
                          : curentLang === "ar"
                          ? "إغلاق"
                          : "סגור"
                      }}
                    </button>
                  </div>
                </ng-template>
                <button
                class="btn btn-dark my-2 w-75 d-block mx-auto"
                (click)="openChoosePaymentMethodModal(paymentMethodModal)"
                [disabled]="!receiptForm.get('customer_id')?.value"
              >
                {{
                  curentLang === "en"
                    ? "Select Payment Method"
                    : curentLang === "ar"
                    ? "اختر طريقة الدفع"
                    : "בחר אמצעי תשלום"
                }}
                <i class="fa fa-credit-card mx-1"></i>
              </button>
              
<!-- Payment Methods Summary with Edit and Delete -->
<div *ngIf="selectedPaymentMethodsSummary.length > 0" class="mt-2 p-3 rounded" style="background-color: white; border: 1px solid #ddd;">
  <table class="table table-borderless">
    <thead>
      <tr style="border-bottom: 2px solid #ddd;">
        <th style="text-align: start; color: black;">
          {{
            curentLang === 'en'
              ? 'Payment Type'
              : curentLang === 'ar'
              ? 'نوع الدفعة'
              : 'סוג תקבול'
          }}
        </th>
        <th style="text-align: start; color: black;">
          {{
            curentLang === 'en'
              ? 'Date'
              : curentLang === 'ar'
              ? 'التاريخ'
              : 'תאריך'
          }}
        </th>
        <th style="text-align: start; color: black;">
          {{
            curentLang === 'en'
              ? 'Amount'
              : curentLang === 'ar'
              ? 'المبلغ'
              : 'סכום'
          }}
        </th>
        <th style="text-align: start;"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let method of selectedPaymentMethodsSummary; let i = index" style="border-bottom: 1px solid #eee;">
        <td style="color: black;">{{ getMethodLabel(method.code) }}</td>
        <div *ngIf="method">
          <input
          type="date"
          [value]="formatDateForInput(method?.date)"
          disabled
          formControlName="invoice_date"
          style="
            border: none;
            background: transparent;
            color: inherit;
            font-size: inherit;
            width: auto;
            appearance: none;
            pointer-events: none;
            
            /* Hide the calendar icon for different browsers */
            -webkit-appearance: none;
            -moz-appearance: textfield;
            position: relative;
          "
        />
        </div>
                <td style="color: black;">{{ method.amount | number: '1.2-2' }} ₪</td>
        <td class="text-end">
          <i class="fa fa-edit text-primary me-2" style="cursor: pointer;" (click)="editPaymentMethod(method)"></i>
          <i class="fa fa-trash text-danger" style="cursor: pointer;" (click)="removeSelectedPaymentMethod(method.code)"></i>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="fw-bold text-end" style="color: black; margin-top: 10px; border-top: 2px solid #ddd; padding-top: 8px;">
    {{
      curentLang === 'en'
        ? 'Total Paid:'
        : curentLang === 'ar'
        ? 'إجمالي المدفوع:'
        : 'סה"כ שולם:'
    }} {{ getTotalAmount() | number: '1.2-2' }} ₪  </div>
</div>
                <!-- End Payment Method -->

                <br />

                <!-- <div class="row d-flex  ">
                                    <div class="col-lg-12 m-2 text-center d-flex justify-content-between">
                                        <br />
                                        <div class="col-lg-12   ">
                                            <label for="" style="font-weight: 800;">
                                            {{ curentLang === "en" ? "Deduction From Source" : curentLang === "ar" ? "  الخصم من المصدر" : "   ניכוי מהמקור  " }}
                                            </label>
                                            <input type="text" formControlName="deduction_from_source" class="form-control m-auto w-25">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 m-2 text-center d-flex justify-content-between">
                                        <br />
                                        <div class="col-lg-12">
                                            <label for="" style="font-weight: 800;">
                                                {{ curentLang === "en" ? "Final Amount" : curentLang === "ar" ? "القيمة النهائية " : "     סכום סופי  " }}
                                            </label>
                                         
                                            <input class="form-control m-auto w-25" formControlName="total_amount" type="number" [value]="getTotalAmount()">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 m-2 text-center d-flex justify-content-between">
                                        <hr>  
                                    </div>
                                  
                                </div> -->

                <div class="row d-flex justify-content-center">
                  <div class="col-md-3"></div>

                  <div class="col-md-3">
                    <div class="col-lg-12 p-2">
                      <label for="" style="font-weight: 800">
                        {{
                          curentLang === "en"
                            ? "Deduction From Source"
                            : curentLang === "ar"
                            ? "  الخصم من المصدر "
                            : "   ניכוי במקור    "
                        }}
                      </label>
                      <input
                        type="number"
                        min="0"
                        formControlName="deduction_from_source"
                        class="form-control m-auto w-100"
                        (input)="validateNumberInput($event)"
                      />
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="col-lg-12 p-2">
                      <label for="" style="font-weight: 800">
                        {{
                          curentLang === "en"
                            ? "Final Amount"
                            : curentLang === "ar"
                            ? "القيمة النهائية "
                            : "     סהכ לתשלום    "
                        }}
                      </label>
                      <!-- Second input -->
                      <input
                        class="form-control m-auto w-100"
                        formControlName="total_amount"
                        type="number"
                        [value]="getTotalAmount()"
                      />
                    </div>
                  </div>

                  <div class="col-md-3"></div>
                </div>
                <div class="row d-flex justify-content-between text-center">
                  <div class="col-4 col-lg-4 p-1">
                    <!-- Confirm button -->
                    <button
                      class="btn"
                      style="background-color: #141414; color: #fff"
                      type="submit"
                      (click)="confirmReceipt()"
                    >
                      <i
                        class="fa fa-check mx-1"
                        style="font-size: 14px; cursor: pointer; color: #fff"
                      ></i>
                      {{
                        curentLang === "en"
                          ? "Confirm "
                          : curentLang === "ar"
                          ? "تأكيد    "
                          : "אישור    "
                      }}
                    </button>
                  </div>
                
                  <div class="col-4 col-lg-4 p-1">
                    <!-- Save button -->
                    <button
                      class="btn"
                      style="background-color: #277d29; color: #fff"
                      type="submit"
                      (click)="SaveReceipt()"
                    >
                      <i
                        class="fa fa-save mx-1"
                        style="font-size: 14px; cursor: pointer; color: #fff"
                      ></i>
                      {{
                        curentLang === "en"
                          ? "Save "
                          : curentLang === "ar"
                          ? "حفظ "
                          : " שמירה "
                      }}
                    </button>
                  </div>
                
                  <div class="col-4 col-lg-4 p-1">
                    <!-- Back button -->
                    <a
                      class="btn btn-danger"
                      routerLink="/dashboard/documents"
                    >
                      <i
                        class="fa fa-rotate-left mx-1"
                        style="font-size: 14px; cursor: pointer; color: #fff"
                      ></i>
                      {{
                        curentLang === "en"
                          ? "Back"
                          : curentLang === "ar"
                          ? "رجوع"
                          : "חזרה"
                      }}
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--add Customer-->

<ng-template #contentAddCustomer let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title" style="color: grey">
      {{
        curentLang === "en"
          ? "Add Customer "
          : curentLang === "ar"
          ? " اضافة عميل "
          : " הוסף לקוח "
      }}
    </h6>
    <button
      type="button"
      class="close btn-close"
      style="color: grey"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="edit-profile">
        <form [formGroup]="addCustomer" novalidate>
          <!-- الصف الأول -->
          <div class="row">
            <div class="mb-3 col-xl-4 col-md-4">
              <label class="form-label" style="color: grey">
                {{
                  curentLang === "en"
                    ? "Customer Name"
                    : curentLang === "ar"
                    ? "اسم العميل"
                    : " שם לקוח "
                }}
                <span class="text-danger" style="color: red">*</span>
              </label>
              <input
                style="color: grey"
                class="form-control"
                type="text"
                formControlName="customer_name_he"
                maxlength="80"
                required
                [ngClass]="{
                  'is-invalid':
                    validate && addCustomer.controls['customer_name_he'].invalid
                }"
              />
              <div
                *ngIf="
                  validate && addCustomer.controls['customer_name_he'].invalid
                "
                class="invalid-feedback text-danger"
              >
                {{
                  curentLang === "en"
                    ? "Please enter customer name"
                    : curentLang === "ar"
                    ? "الرجاء ادخال اسم العميل"
                    : "נא להזין את שם הלקוח"
                }}
              </div>
            </div>

            <div class="mb-3 col-xl-4 col-md-4">
              <label class="form-label" style="color: grey">
                {{
                  curentLang === "en"
                    ? "Customer Type"
                    : curentLang === "ar"
                    ? "نوع العميل"
                    : "סוג לקוח"
                }}
                <span class="text-danger" style="color: red">*</span>
              </label>
              <select
                class="form-control form-select"
                required
                formControlName="customer_type"
                [ngClass]="{
                  'is-invalid':
                    validate && addCustomer.controls['customer_type'].invalid
                }"
              >
                <option
                  *ngFor="let item of customerTypeData; let i = index"
                  [value]="item?.lookup_detail_id"
                >
                  {{
                    curentLang === "en"
                      ? item?.lookupDetailEnName
                      : curentLang === "ar"
                      ? item?.lookupDetailArName
                      : item?.lookupDetailHeName
                  }}
                </option>
              </select>
              <div
                *ngIf="
                  validate && addCustomer.controls['customer_type'].invalid
                "
                class="invalid-feedback text-danger"
              >
                {{
                  curentLang === "en"
                    ? "Please choose customer type"
                    : curentLang === "ar"
                    ? "الرجاء اختيار نوع العميل"
                    : "אנא בחר סוג לקוח"
                }}
              </div>
            </div>

            <div class="mb-3 col-xl-4 col-md-4">
              <label class="form-label" style="color: grey">
                {{
                  curentLang === "en"
                    ? "Customer Phone"
                    : curentLang === "ar"
                    ? "هاتف العميل"
                    : "טלפון לקוח"
                }}
              </label>
              <div class="input-group">
                <span style="color: #141414" class="input-group-text"
                  >+972</span
                >
                <input
                  class="form-control"
                  type="text"
                  maxlength="10"
                  formControlName="customer_phone"
                  [ngClass]="{
                    'is-invalid':
                      validate && addCustomer.controls['customer_phone'].invalid
                  }"
                />
              </div>
              <div
                *ngIf="
                  validate && addCustomer.controls['customer_phone'].invalid
                "
                class="invalid-feedback text-danger"
              >
                {{
                  curentLang === "en"
                    ? "Please enter a valid phone number"
                    : curentLang === "ar"
                    ? "الرجاء إدخال رقم هاتف صالح"
                    : "נא להזין מספר טלפון תקין"
                }}
              </div>
            </div>
          </div>

          <!-- الصف الثاني -->
          <div class="row">
            <div
              class="mb-3 col-xl-4 col-md-4"
              *ngIf="addCustomer.value.customer_type != 6"
            >
              <label class="form-label" style="color: grey">
                {{
                  curentLang === "en"
                    ? "ID Number"
                    : curentLang === "ar"
                    ? "رقم الهوية"
                    : "מספר ישות (ת.ז / ח.פ.)"
                }}
                <!-- <span class="text-danger" style="color: red;">*</span> -->
              </label>
              <input
                style="color: grey"
                class="form-control"
                type="number"
                formControlName="id_number"
                maxlength="9"
              />
            </div>

            <div class="mb-3 col-xl-4 col-md-4">
              <label class="form-label" style="color: grey">
                {{
                  curentLang === "en"
                    ? "City Name  "
                    : curentLang === "ar"
                    ? " اسم المدينة "
                    : "שם העיר"
                }}
                <!-- <span class="text-danger" style="color: red;">*</span> -->
              </label>
              <input
                style="color: grey"
                class="form-control"
                type="text"
                formControlName="customer_address_city"
                maxlength="100"
              />
              <!-- <div *ngIf="validate && addCustomer.controls['customer_address_city'].invalid" class="invalid-feedback text-danger">
                                {{ curentLang === 'en' ? 'Please enter customer city' : (curentLang === 'ar' ? 'الرجاء إدخال مدينة العميل' : 'אנא הזן עיר לקוח') }}
                            </div> -->
            </div>

            <div class="mb-3 col-xl-4 col-md-4">
              <label class="form-label" style="color: grey">
                {{
                  curentLang === "en"
                    ? "Street Name"
                    : curentLang === "ar"
                    ? "أسم الشارع"
                    : "שם רחוב"
                }}
                <!-- <span class="text-danger" style="color: red;">*</span> -->
              </label>
              <input
                style="color: grey"
                class="form-control"
                type="text"
                formControlName="customer_address_str"
                maxlength="100"
              />
              <!-- <div *ngIf="validate && addCustomer.controls['customer_address_str'].invalid" class="invalid-feedback text-danger">
                                {{ curentLang === 'en' ? 'Please enter customer street' : (curentLang === 'ar' ? 'الرجاء إدخال شارع العميل' : 'אנא הזן רחוב לקוח') }}
                            </div> -->
            </div>
          </div>

          <!-- الصف الثالث -->
          <div class="row">
            <div class="mb-3 col-xl-4 col-md-4">
              <label class="form-label" style="color: grey">
                {{
                  curentLang === "en"
                    ? "Building Number"
                    : curentLang === "ar"
                    ? "رقم المبني"
                    : "מספר בניין"
                }}
                <!-- <span class="text-danger" style="color: red;">*</span> -->
              </label>
              <input
                style="color: grey"
                class="form-control"
                type="number"
                formControlName="customer_Building_number"
                maxlength="100"
              />
              <!-- <div *ngIf="validate && addCustomer.controls['customer_Building_number'].invalid" class="invalid-feedback text-danger">
                                {{ curentLang === 'en' ? 'Please enter customer building number' : (curentLang === 'ar' ? 'الرجاء إدخال رقم مبنى العميل' : 'אנא הזן מספר בניין לקוח') }}
                            </div> -->
            </div>

            <div class="mb-3 col-xl-4 col-md-4">
              <label class="form-label" style="color: grey">
                {{
                  curentLang === "en"
                    ? "Zip Code"
                    : curentLang === "ar"
                    ? "الرقم البريدي"
                    : "מיקוד דואר"
                }}
                <!-- <span class="text-danger" style="color: red;">*</span> -->
              </label>
              <input
                style="color: grey"
                class="form-control"
                type="number"
                formControlName="customer_address_zip_code"
                maxlength="20"
              />
              <!-- <div *ngIf="validate && addCustomer.controls['customer_address_zip_code'].invalid" class="invalid-feedback text-danger">
                                {{ curentLang === 'en' ? 'Please enter customer zip code' : (curentLang === 'ar' ? 'الرجاء إدخال الرقم البريدي للعميل' : 'אנא הזן את המיקוד של הלקוח') }}
                            </div> -->
            </div>

            <div class="mb-3 col-xl-4 col-md-4">
              <label class="form-label" style="color: grey">
                {{
                  curentLang === "en"
                    ? "Customer Vat Number"
                    : curentLang === "ar"
                    ? "الرقم الضريبي للعميل"
                    : "מספר תיק במס ההכנסה"
                }}
                <span class="text-danger" style="color: red">*</span>
              </label>
              <input
                style="color: grey"
                class="form-control"
                type="text"
                formControlName="customer_vat_number"
                maxlength="9"
                required
                pattern="[0-9]{9}"
                title="{{
                  curentLang === 'en'
                    ? 'Please enter exactly 9 digits'
                    : curentLang === 'ar'
                    ? 'يرجى إدخال 9 أرقام فقط'
                    : 'אנא הזן 9 ספרות בדיוק'
                }}"
                [ngClass]="{
                  'is-invalid':
                    validate &&
                    addCustomer.controls['customer_vat_number'].invalid
                }"
              />
              <div
                *ngIf="
                  validate &&
                  addCustomer.controls['customer_vat_number'].invalid
                "
                class="invalid-feedback text-danger"
              >
                {{
                  curentLang === "en"
                    ? "Please enter exactly 9 digits"
                    : curentLang === "ar"
                    ? "يرجى إدخال 9 أرقام فقط"
                    : "אנא הזן 9 ספרות בדיוק"
                }}
                <span class="text-danger" style="color: red">*</span>
              </div>
            </div>
          </div>

          <!-- الصف الرابع (حقل ضريبة القيمة المضافة، أو يمكنك إضافة المزيد من الحقول) -->
          <div class="row">
            <div
              class="mb-3 col-xl-4 col-md-4"
              *ngIf="addCustomer.value.customer_type != 5"
            >
              <label class="form-label" style="color: grey">
                {{
                  curentLang === "en"
                    ? "Customer Vat Tax"
                    : curentLang === "ar"
                    ? "رقم ضريبة القيمة المضافة"
                    : "  מס עוסק מורשה   "
                }}
              </label>
              <input
                style="color: grey"
                class="form-control"
                type="number"
                formControlName="customer_vat_tax"
                maxlength="9"
                [ngClass]="{
                  'is-invalid':
                    validate && addCustomer.controls['customer_vat_tax'].invalid
                }"
              />
              <div
                *ngIf="
                  validate && addCustomer.controls['customer_vat_tax'].invalid
                "
                class="invalid-feedback text-danger"
              >
                {{
                  curentLang === "en"
                    ? "Please enter a valid VAT number"
                    : curentLang === "ar"
                    ? "الرجاء إدخال رقم ضريبة القيمة المضافة الصحيح"
                    : 'אנא הזן מספר מס מע"מ תקין'
                }}
              </div>
            </div>
          </div>

          <hr />
          <!--
                    <h6 class="modal-title" style="color: grey;">
                      {{ curentLang === 'en' ? 'Additional fields' : (curentLang === 'ar' ? ' حقول اضافية   ' : 'שדות נוספים')}} 
                  </h6>
                  <hr style="width: 25%;"> 
  -->
          <div class="row">
            <!--   <div class="mb-3 col-xl-6 col-md-6">
                      <label class="form-label" style="color: grey;">
                          {{ curentLang === 'en' ? 'City Name Ar' : (curentLang === 'ar' ? 'اسم المدينة عربي' : 'שם עיר בערבית') }}
                           <span class="text-danger" style="color: red;">*</span>  
                      </label>
                      <input 
                          style="color: grey;" 
                          class="form-control"
                          type="text" 
                          formControlName="customer_address_city_ar"
                          maxlength="100"
                          
                      />
                     <div *ngIf="validate && addCustomer.controls['customer_address_city'].invalid" class="invalid-feedback text-danger">
                          {{ curentLang === 'en' ? 'Please enter customer city' : (curentLang === 'ar' ? 'الرجاء إدخال مدينة العميل' : 'אנא הזן עיר לקוח') }}
                      </div> 
                  </div>-->

            <!--    <div class="mb-3 col-xl-6 col-md-6">
                    <label class="form-label" style="color: grey;">
                        {{ curentLang === 'en' ? 'City Name En' : (curentLang === 'ar' ? 'اسم المدينة انجليزي' : 'שם העיר באנגלית') }}
                         <span class="text-danger" style="color: red;">*</span>  
                    </label>
                    <input 
                        style="color: grey;" 
                        class="form-control"
                        type="text" 
                        formControlName="customer_address_city_en"
                        maxlength="100"
                        
                    />
                     <div *ngIf="validate && addCustomer.controls['customer_address_city'].invalid" class="invalid-feedback text-danger">
                        {{ curentLang === 'en' ? 'Please enter customer city' : (curentLang === 'ar' ? 'الرجاء إدخال مدينة العميل' : 'אנא הזן עיר לקוח') }}
                    </div>  
                </div>
                    -->

            <!--   <div class="mb-3 col-xl-6 col-md-6">
                      <label class="form-label" style="color: grey;">
                          {{ curentLang === 'en' ? 'Street Name Ar' : (curentLang === 'ar' ? 'اسم الشارع عربي' : "שם הרחוב הוא ערבי") }}
                            <span class="text-danger" style="color: red;">*</span>  
                      </label>
                      <input 
                          style="color: grey;" 
                          class="form-control"
                          type="text" 
                          formControlName="customer_address_str_ar"
                          maxlength="100"
                      />
                       <div *ngIf="validate && addCustomer.controls['customer_address_str'].invalid" class="invalid-feedback text-danger">
                          {{ curentLang === 'en' ? 'Please enter customer street' : (curentLang === 'ar' ? 'الرجاء إدخال شارع العميل' : 'אנא הזן רחוב לקוח') }}
                      </div> 
                  </div>-->

            <!--  <div class="mb-3 col-xl-6 col-md-6">
                    <label class="form-label" style="color: grey;">
                        {{ curentLang === 'en' ? 'Street Name En' : (curentLang === 'ar' ? 'اسم الشارع انجليزي' : 'שם רחוב באנגלית') }}
                         <span class="text-danger" style="color: red;">*</span> 
                    </label>
                    <input 
                        style="color: grey;" 
                        class="form-control"
                        type="text" 
                        formControlName="customer_address_str_en"
                        maxlength="100"
                    />
                  <div *ngIf="validate && addCustomer.controls['customer_address_str'].invalid" class="invalid-feedback text-danger">
                        {{ curentLang === 'en' ? 'Please enter customer street' : (curentLang === 'ar' ? 'الرجاء إدخال شارع العميل' : 'אנא הזן רחוב לקוח') }}
                    </div>  
                </div>-->
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-sm m-auto mt-2"
              style="background-color: #141414; color: #fff"
              (click)="addedData()"
            >
              {{
                curentLang === "en"
                  ? "Confirm"
                  : curentLang === "ar"
                  ? "تأكيد"
                  : "אישור"
              }}
              <i class="fa fa-check-circle mx-1" style="color: #fff"></i>
            </button>
            <button
              type="button"
              class="btn btn-sm m-auto mt-2 btn-danger"
              (click)="modal.close('Close click')"
            >
              {{
                curentLang === "en"
                  ? "Back"
                  : curentLang === "ar"
                  ? "رجوع"
                  : "חזרה"
              }}
              <i class="fa fa-rotate-left mx-1"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
