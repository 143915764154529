import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../../Data/service/http-help.service";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from 'sweetalert2';
import { OtpEndPoient } from "../../Data/service/global.service";
import { AuthService } from "src/app/Auth/service/auth.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})

export class DashboardComponent implements OnInit {
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  officeIdNumber = JSON.parse(localStorage.getItem('office_id'));

  allAnalyticsSubplansData:any=[];
  allActivePlan:any=[];
  subPlansByMerchant:any=[];
  subscribeToPlanDetails:any=[];
  profileData:any = [];
  allOfficeStatisticsData:any=[];
  verifyUser:any;
  merchantId:any;
  term:any;
  p: any;
  otpCode: string; // تعديل النوع إلى string
  public show: boolean = false;

  newOrders = {
    colorClass: "primary",
    icon: "new-order",
    colorClass2: "warning",
    icon2: "customers",
    colorClass3: "success",
    icon4: "invoice",
    colorClass4: "secondary",
    icon3: "profit"
  };
  constructor
  ( private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private authService: AuthService,
    private Router: Router,
  )
     
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));    

  };

  getAnalyticsSubscribeToPlan() {
    this.spinner.show();
    this.service.getAnalyticsSubscribeToPlan().subscribe({
      next: res => {
        this.allAnalyticsSubplansData = res;
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  getAllActivePlans() {
    this.spinner.show();
    this.service.getAllActivePlans().subscribe({
      next: res => {
        this.allActivePlan = res;
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  getSubscribeDetailsByMerchantId(){
    this.spinner.show()
    this.service.getSubscribeDetailsByMerchantId( ).subscribe(({
      next:res => {
        this.subPlansByMerchant = res;
        this.spinner.hide();
      },
      error:err => {
        
      }
    }))
  };

  getProfileInfo() {
    this.spinner.show();
    this.service.getProfileInfo().subscribe({
        next: res => {
            this.profileData = res;		
            const data = this.profileData?.lookupDetailsUserType?.lookupDetailEnName;
            localStorage.setItem("userType_en", data);
             
            
            this.verifyUser = this.profileData.mverified;
           

            // تحقق من قيمة verifyUser بعد الحصول على البيانات
            if (this.verifyUser === 0) {
                Swal.fire({
                    icon: 'warning',
                    title: this.curentLang === 'ar' ? 'برجاء كتابة كود التفعيل' :
                           (this.curentLang === 'en' ? 'Please fill all fields' : 
                           (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
                });
            }

            this.spinner.hide();
        },
        error: error => {
            this.spinner.hide();
        }
    });
}

showPassword() {
  this.show = !this.show;
}

isNumber(event: KeyboardEvent) {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    event.preventDefault();
  } else {
    return true;
  }
}

otp() {
  let model = {
    "user_id": Number(this.userRegistId),
    "otp": this.otpCode
  };
  this.spinner.show();
  this.authService.Otp(OtpEndPoient.POST, model).subscribe((res) => {
    if (res.status === 200) {
      Swal.fire({
        icon: 'success',
        title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
      });
      //  localStorage.setItem('token', res.accessToken);
      // localStorage.setItem('user_type', res.userData.user_type);
      // localStorage.setItem('userType_en',res.userData.userType_en) ;  
      // localStorage.setItem('user_id', res.userData.user_id);
      // localStorage.setItem('office_id', res.userData.office_id);
      // localStorage.setItem('merchant_id', res.userData.merchant_id);
      // localStorage.setItem('curentLang', res.userData.language);
      // this.authService.saveCurrentUser();
      //   this.Router.navigate(['/dashboard/dashboard']);
      // this.authService.isLogin.next(true)
      this.spinner.hide();
      window.location.reload();
      // this.Router.navigate(['/login'])
      // localStorage.removeItem('user_id')
    } else if (res.status === 400 || res.status === 403 || res.status === 500 || res.status === 404) {
      let errorMessage;
      switch (res.status) {
        case 400:
          errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
          break;
        case 403:
          errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
          break;
        case 500:
          errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
          break;
        case 404:
          errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
          break;
        default:
          errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
      }
      Swal.fire({
        icon: 'error',
        title: errorMessage
      });
    }
    this.spinner.hide();
  });
};

getOfficeStatistics() {
  this.spinner.show();
  this.service.getOfficeStatistics(this.officeIdNumber).subscribe({
    next: res => {
      this.allOfficeStatisticsData = res;
      
      
      this.spinner.hide();
    },
    error: error => {
      this.spinner.hide();
    }
  });
};

    ngOnInit() {
      // استدعاء الدوال المطلوبة
      this.getProfileInfo();
      this.getAnalyticsSubscribeToPlan();
      this.getAllActivePlans();
      this.getOfficeStatistics();
      // this.getSubscribeDetailsByMerchantId();
  
      // إعداد التخطيط
      this.layoutVersion = localStorage.getItem("layout_version");
      if (this.layoutVersion == null || this.layoutVersion === undefined) {
          localStorage.setItem("layout_version", "light-only");
          this.layoutVersion = "light-only";
      } else {
          this.layoutVersion = localStorage.getItem("layout_version");
          if (this.layoutVersion === "dark-only") {
              document.body.classList.add("dark-only");
          } else {
              document.body.classList.add("light-only");
          }
      }
  
      // جلب بيانات المستخدم من التخزين المحلي
      this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
      this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
      this.user_type = JSON.parse(localStorage.getItem('user_type'));
      this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));    

  }
    

}
