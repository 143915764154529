import { Router } from "@angular/router";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, catchError, tap, throwError } from "rxjs";

import { environment } from "src/environments/environment";
import { baseUrl } from "./global.service";

@Injectable({
  providedIn: "root",
})
export class HttpHelpService {
  curentLang = new BehaviorSubject(null);

  headers: any;
  baseUrl: any;
  sortColumn: any;
  sortDirection: string;
  constructor(private _HttpClient: HttpClient, private Router: Router) {
    // this.curentLang.next(localStorage.getItem('curentLang')||'ar');
  }

  /* global function */
  getFilteredInvoices(
    fromDate: string,
    toDate: string,
    merchantId: number,
    sort: string
  ): Observable<any> {
    const params = new HttpParams()
      .set("fromDate", fromDate)
      .set("toDate", toDate)
      .set("merchant_id", merchantId.toString())
      .set("sort", sort);

    return this._HttpClient.get<any>(`${baseUrl}invoice/filter`, { params });
  }
  /* start priviliges functions*/
  getPrivileges(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}privileges/leftMenu`);
  }
  getAllPrivilegesScreen(): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}privileges/getAllPrivilegesScreen`
    );
  }

  /* end priviliges functions*/

  /* start plans functions*/

  getAllActivePlans(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}plan/active`);
  }

  getPlanById(id): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}plan/${id}`);
  }

  getAllPans(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}plan`);
  }

  changePlanStatus(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}plan/update-status/${id}`,
      model
    );
  }

  changeShowSignatureCompany(id: number, model: any): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}company/changeShowSignatureCompany/${id}/${model}`
    );
  }

  updatePlanById(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(`${baseUrl}plan/${id}`, model);
  }

  /*  end plans functions*/
//get customer dropdown

getCustomersdrop(): Observable<any> {
  return this._HttpClient.get<any>(`${baseUrl}customers/select`);
}



  /* start subscribe-to-plans functions*/
  getAnalyticsSubscribeToPlan(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}dashboard/statistics`);
  }

  getSubscribeDetailsByMerchantId(): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}subscribe-to-plans/getSubscribeDetailsByMerchantId`
    );
  }

  Post(endPoint: string, model: any): Observable<any> {
    return this._HttpClient.post<any>(baseUrl + endPoint, model);
  }

  increasePlanDetailsByAdmin(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}subscribe-to-plans/increasePlanDetailsByAdmin/${id}`,
      model
    );
  }
  /*  end subscribe-to-plans functions*/

  /* start merchant functions */

  getAllMerchantInfo(): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}merchant-info/getAllMerchantInfoByBasedToken`
    );
  }

  getMerchantInfoById(id): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}merchant-info/${id}`);
  }

  updateMerchantById(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}merchant-info/updateMerchantInfo/${id}`,
      model
    );
  }

  changeMerchantStatus(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}merchant-info/updateStatusMerchantByAdmin/${id}`,
      model
    );
  }
  /* end merchant functions */

  /* start company functions */
  getAllCompaniesExcel(): Observable<Blob> {
    return this._HttpClient.get(`${baseUrl}company/companiesExcel`, {
      responseType: "blob",
    });
  }

  getAllCompaniesInfo(
    limit: number,
    offset: number,
    keyword?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set("limit", limit.toString())
      .set("offset", offset.toString());

    if (keyword) {
      params = params.set("keyword", keyword);
    }

    return this._HttpClient.get<any>(
      `${baseUrl}company/getALLCompanyByBasedToken`,
      { params }
    );
  }

  getAllActiveCompaniesInfoByMerchantId(): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}company/getAllActiveCompanyByMerchant`
    );
  }
  uploadCompanyImage(id: number, model: any): Observable<any> {
    return this._HttpClient.post<any>(
      `${baseUrl}company/updateLogo/${id}`,
      model
    );
  }

  /*getCompanyInfoById(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}company/getCompanyByCompanyId/${id}`
    );
  }*/

  // ...template...
  getTemplatesSelect(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}templates/select`);
  }
  // ...template...

  getSequencesByCompanyId(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}company/getSequencesByCompanyId/${id}`
    );
  }
  uploadCompanySeal(id: number, model: any): Observable<any> {
    return this._HttpClient.post<any>(
      `${baseUrl}company/updateSeal/${id}`,
      model
    );
  }

  changeShowSealCompany(id: number, model: any): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}company/changeShowSealCompany/${id}/${model}`
    );
  }

  getCompanyInfoById(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}company/getCompanyByCompanyId/${id}`
    );
  }

  updateSequenceValue(model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}company/updateSequenceValue`,
      model
    );
  }

  getCompanyIdByUuid(company_uuid: string): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}company/getCompanyId/${company_uuid}`
    );
  }

  getActivityInCompany(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}company/getActivityInCompany/${id}`
    );
  }

  updateCompanyById(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}company/updateCompanyByCompanyId/${id}`,
      model
    );
  }

  updateCompanyByAdminByCompanyId(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}company/updateCompanyByAdmin/${id}`,
      model
    );
  }

  deleteCompanyByCompanyId(id: number): Observable<any> {
    return this._HttpClient.delete<any>(
      `${baseUrl}company/deleteCompanyByCompanyId/${id}`
    );
  }

  deleteCompanyLogoByCompanyId(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}company/removeLogoCompany/${id}`
    );
  }

  /*  end company functions */

  /* start vat functions */
  getAllActiveVatList(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}vat-list/active`);
  }

  getAllVatList(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}vat-list`);
  }

  changeVatStatus(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(`${baseUrl}vat-list/status/${id}`, model);
  }

  updateVatById(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(`${baseUrl}vat-list/${id}`, model);
  }

  /*  end vat functions */

  /* start vat details functions */

  getVatPercentageByVatListIdAndDate(id: number, date: any): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}vat-details/getVatPercentageByVatListIdAndDate/${id}/${date}`
    );
  }

  getAllVatDetailsByVatListId(id): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}vat-details/getAllVatDetailsByVatListId/${id}`
    );
  }

  updateVatDetailsByID(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(`${baseUrl}vat-details/${id}`, model);
  }

  deleteVatDetailsByID(id: number): Observable<any> {
    return this._HttpClient.delete<any>(`${baseUrl}vat-details/${id}`);
  }

  /*  end vat details functions */

  /* start banks functions */

  getAllBanks(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}banks`);
  }

  getActiveBanks(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}banks/getActiveBanks`);
  }

  getBankById(id): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}banks/${id}`);
  }

  updateBankById(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(`${baseUrl}banks/${id}`, model);
  }

  changeBankStatus(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(`${baseUrl}banks/status/${id}`, model);
  }

  deleteBankByID(id: number): Observable<any> {
    return this._HttpClient.delete<any>(`${baseUrl}banks/${id}`);
  }

  /* end banks functions */

  /* start banks branches functions */

  getAllBankBranchById(id): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}banks-branches/getAllBankBranchById/${id}`
    );
  }

  getAllBankBranch(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}banks-branches`);
  }

  updateBankBranchById(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(`${baseUrl}banks-branches/${id}`, model);
  }

  deleteBankBranchByID(id: number): Observable<any> {
    return this._HttpClient.delete<any>(`${baseUrl}banks-branches/${id}`);
  }

  /* end banks branches functions */

  /* start payment method functions */

  getAllPaymentMethod(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}payment-methods`);
  }

  getPaymentMethodById(id): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}payment-methods/getPaymentMethodById/${id}`
    );
  }

  updatePaymentMethodById(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}payment-methods/updatePaymentMethodById/${id}`,
      model
    );
  }

  deletePaymentMethodById(id: number): Observable<any> {
    return this._HttpClient.delete<any>(
      `${baseUrl}payment-methods/deletePaymentMethodById/${id}`
    );
  }

  /*  start categories functions */

  getAllCategories(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}categories`);
  }
  getAllActiveCategories(): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}categories/getActiveCategories`
    );
  }

  changeCategoryStatus(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}categories/status/${id}`,
      model
    );
  }

  updateCategoryById(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(`${baseUrl}categories/${id}`, model);
  }

  /*  end categories functions */

  /* start lookupdetails functions */
  getAllStatus(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}lookup-det/getAllStatus`);
  }

  getAllCustomerType(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}lookup-det/getAllCustomerType`);
  }

  getAllUserType(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}lookup-det/getAllUserType`);
  }

  getAllStatusSubscribed(): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}lookup-det/getAllStatusSubscribed`
    );
  }

  /*  end lookupdetails functions */

  /*  start users functions */

  getProfileInfo(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}users/getProfileInfo`);
  }

  getUserById(id: any): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}users/${id}`);
  }

  updateUserLangById(model: any): Observable<any> {
    return this._HttpClient.put<any>(`${baseUrl}users/updateLanguage`, model);
  }

  updateUserName(model: any): Observable<any> {
    return this._HttpClient.put<any>(`${baseUrl}users/updateUserName`, model);
  }

  updateUserInfo(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}users/updateUserInfo/${id}`,
      model
    );
  }

  getALLUsersByBasedToken(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}users/getALLUsersByBasedToken`);
  }

  getAllUserByOfficeId(office_id: any): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}users/getAllUserByOfficeId/${office_id}`
    );
  }

  getAllUserByMerchantId(merchant_id: any): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}users/getAllUserByMerchantId/${merchant_id}`
    );
  }

  changeUserStatus(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}users/updateUserStatus/${id}`,
      model
    );
  }

  updateUserImage(id: number, model: any): Observable<any> {
    return this._HttpClient.post<any>(
      `${baseUrl}users/updateUserImage/${id}`,
      model
    );
  }

  ChangeUserPasswordByUserId(model: any): Observable<any> {
    return this._HttpClient.post<any>(
      `${baseUrl}users/ChangeUserPasswordByUserId`,
      model
    );
  }

  ChangeUserPasswordByAdmin(model: any): Observable<any> {
    return this._HttpClient.post<any>(
      `${baseUrl}users/ChangeUserPasswordByAdmin`,
      model
    );
  }

  updateUserToken(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}users/updateToken`);
  }

  /*  end users functions */

  /* start users privilieg functions */
  getAllPrivilegesByUser(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}users-privileges/getAllPrivilegesByUser/${id}`,
      model
    );
  }

  getAllPrivileges(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}users-privileges/getAllPrivilegesByUser/${id}`
    );
  }

  changePrivilegeStatus(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}users-privileges/status/${id}`,
      model
    );
  }

  deletePrivilege(id: number): Observable<any> {
    return this._HttpClient.delete<any>(`${baseUrl}users-privileges/${id}`);
  }

  /* start customers functions*/

  getAllCustomersBasedOnMerchant(
    limit: number,
    offset: number
  ): Observable<any> {
    const params = new HttpParams()
      .set("limit", limit.toString())
      .set("offset", offset.toString());
    return this._HttpClient.get<any>(
      `${baseUrl}customers/getAllCustomersBasedOnMerchant`,
      { params }
    );
  }

  updateCustomersById(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(`${baseUrl}customers/${id}`, model);
  }

  changeCustomerStatus(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}customers/updateStatusCustomer/${id}`,
      model
    );
  }

  getAllActiveCustomersBasedOnMerchantId(): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}customers/getAllActiveCustomersBasedOnMerchantId`
    );
  }

  getCustomerById(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}customers/getCustomerById/${id}`
    );
  }

  deleteCustomerById(id: number): Observable<any> {
    return this._HttpClient.delete<any>(`${baseUrl}customers/${id}`);
  }

  /*  end customers functions*/

  /* start products functions*/

  getAllProductsBasedOnMerchant(
    limit: number,
    offset: number
  ): Observable<any> {
    const params = new HttpParams()
      .set("limit", limit.toString())
      .set("offset", offset.toString());
    return this._HttpClient.get<any>(
      `${baseUrl}products/getAllProductsBasedOnMerchant`,
      { params }
    );
  }

  updateProductsById(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(`${baseUrl}products/${id}`, model);
  }

  changeProductStatus(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}products/updateStatusProduct/${id}`,
      model
    );
  }

  getAllActiveProductsBasedOnMerchantId(): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}products/getAllActiveProductsBasedOnMerchantId`
    );
  }

  deleteProductById(id: number): Observable<any> {
    return this._HttpClient.delete<any>(`${baseUrl}products/${id}`);
  }

  /*  end products functions*/

  /* start measurement-units functions*/

  getAllActiveMeasurementUnits(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}measurement-units/active`);
  }

  getAllMeasurementUnits(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}measurement-units`);
  }

  changeMeasurementUnitsStatus(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}measurement-units/update-status/${id}`,
      model
    );
  }

  deleteMeasurementUnitById(id: number): Observable<any> {
    return this._HttpClient.delete<any>(`${baseUrl}measurement-units/${id}`);
  }

  updateMeasurementById(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}measurement-units/${id}`,
      model
    );
  }

  /*  end measurement-units functions*/

  /* start invoices-types functions*/

  getAllInvoicesTypes(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}invoice-type-list`);
  }

  getInvoicesAndReceiptsByInvoiceid(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}invoice/getInvoicesAndReceiptsByInvoiceUuid/${id}`
    );
  }

  getAllActiveInvoicesTypes(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}invoice-type-list/active`);
  }

  changeInvoicesTypesStatus(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}invoice-type-list/status/${id}`,
      model
    );
  }

  deleteInvoicesTypesById(id: number): Observable<any> {
    return this._HttpClient.delete<any>(`${baseUrl}invoice-type-list/${id}`);
  }

  updateInvoicesTypesById(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}invoice-type-list/${id}`,
      model
    );
  }

  /*  end invoices-types functions*/

  /* add receipt functions */

  getReceiptById(id: number): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}receipt/getReceiptById/${id}`);
  }

  getAllReceiptsByMerchantId(
    id: number,
    limit: number,
    offset: number
  ): Observable<any> {
    const params = new HttpParams()
      .set("limit", limit.toString())
      .set("offset", offset.toString());
    return this._HttpClient.get<any>(
      `${baseUrl}receipt/getAllReceiptsByMerchantId/${id} `,
      { params }
    );
  }

  getReceiptPdfById(id: number): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}receipt/getPdf/${id}`);
  }

  updateVersionCopyReceipt(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}receipt/updateVersionCopy/${id}`
    );
  }

  updateReceiptById(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}receipt/updateReceipt/${id}`,
      model
    );
  }

  deleteReceiptById(id: number): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}receipt/deleteReceipt/${id}`);
  }

  /* end add receipt functions */

  /* start app info functions */

  getAppInfoData(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}app-info`);
  }

  updateAppInfoData(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(`${baseUrl}app-info/${id}`, model);
  }

  /* end app info functions */

  /* start invoices functions*/

  getALLInvoiceByBasedMerchantId(
    limit: number,
    offset: number
  ): Observable<any> {
    const params = new HttpParams()
      .set("limit", limit.toString())
      .set("offset", offset.toString());

    return this._HttpClient.get<any>(
      `${baseUrl}invoice/getALLInvoiceByBasedMerchantId`,
      { params }
    );
  }

  getAllFinancialRequestByMerchantId(
    limit?: any,
    offset?: any
  ): Observable<any> {
    const params = new HttpParams()
      .set("limit", limit.toString())
      .set("offset", offset.toString());
    return this._HttpClient.get<any>(
      `${baseUrl}invoice/getAllFinancialRequestByMerchantId`,
      { params }
    );
  }

  getAllReverseInvoiceByMerchantId(limit?: any, offset?: any): Observable<any> {
    const params = new HttpParams()
      .set("limit", limit.toString())
      .set("offset", offset.toString());
    return this._HttpClient.get<any>(
      `${baseUrl}invoice/getAllReverseInvoiceByMerchantId`,
      { params }
    );
  }

  getAllInvoicesAndReceiptsBasedMerchantId(): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}invoice/getAllInvoicesAndReceiptsBasedMerchantId`
    );
  }

  getInvoicesFilters(
    fromDate: any,
    toDate: any,
    companyId?: number,
    limit?: any,
    offset?: number,
    invoice_type?: any,
    merchant_id?: any,
    searchKeyword?: string
  ): Observable<any> {
    let params = new HttpParams();

    if (fromDate) {
      params = params.set("fromDate", fromDate?.toString());
    }

    if (toDate) {
      params = params.set("toDate", toDate?.toString());
    }

    if (companyId) {
      params = params.set("companyId", companyId?.toString());
    }

    if (limit) {
      params = params.set("limit", limit?.toString());
    }

    if (offset) {
      params = params.set("offset", offset?.toString());
    }

    if (invoice_type !== undefined && invoice_type !== null) {
      params = params.set("invoice_type", invoice_type?.toString());
    }

    if (merchant_id) {
      params = params.set("merchant_id", merchant_id?.toString());
    }

    if (searchKeyword) {
      params = params.set("searchKeyword", searchKeyword);
    }

    return this._HttpClient.get<any>(`${baseUrl}invoice/filter`, { params });
  }

  getInvoicesByInvoiceId(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}invoice/getInvoicesByInvoiceId/${id}`
    );
  }

  deleteInvoiceById(id: number): Observable<any> {
    return this._HttpClient.delete<any>(
      `${baseUrl}invoice/${id}`
    );
  }

  exportPdfHtml(invoiceId: any, lang: string): Observable<Blob> {
    return this._HttpClient.get(
      `${baseUrl}export-pdf/html/${invoiceId}/${lang}`,
      {
        responseType: "blob", // تصحيح استخدام 'blob' فقط بدون 'as json'
      }
    );
  }

  confirmInvoice(id: any): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}invoice/confirmInvoice/${id}`);
  }

  convrtFinaicalRequestToInvoice(id: any): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}invoice/convrtFinaicalRequestToInvoice/${id}`
    );
  }
  // confirmInvoice(invoiceId: string): Observable<any> {
  //    return this._HttpClient.post<any>(`${baseUrl}/confirmInvoice`,{invoiceId}).pipe(
  //     tap(res => {
  //     }),
  //     catchError(error => {
  //       return throwError(error);
  //     })
  //   );
  // }

  getPdfByInvoiceId(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}invoice/getPdfByInvoiceId/${id}`
    );
  }

  updateVersionCopy(id: any): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}invoice/updateVersionCopy/${id}`
    );
  }

  downloadDocumentPdf(invoiceId: any, lang: string): Observable<Blob> {
    return this._HttpClient.get(
      `${baseUrl}export-pdf/Invoice-pdf/${invoiceId}/${lang}`,
      {
        responseType: "blob", // تصحيح استخدام 'blob' فقط بدون 'as json'
      }
    );
  }

  /*downloadDocumentPdf(invoiceId: any, lang: string): Observable<Blob> {
    return this._HttpClient.get(`${baseUrl}export-pdf/Invoice-pdf/${invoiceId}/${lang}`, {
      responseType: 'blob' // تصحيح استخدام 'blob' فقط بدون 'as json'
    });
  };
*/
  downloadAllInvoicesPdf(
    company_id: number,
    lang: string,
    fromDate: string,
    toDate: string
  ): Observable<HttpResponse<Blob>> {
    const url = `${baseUrl}export-pdf/printAllInvoices`;

    // إعداد المعلمات
    const params = {
      company_id: company_id.toString(),
      lang: lang,
      fromDate: fromDate,
      toDate: toDate,
    };

    // استدعاء الـ API
    return this._HttpClient.get<Blob>(url, {
      params: params,
      responseType: "blob" as "json", // تحديد نوع الاستجابة كـ Blob
      observe: "response", // استرجاع الاستجابة الكاملة (status, headers, body)
    });
  }

  GetCustomer(): Observable<any> {
    return this._HttpClient.get(
      `${baseUrl}customers/getAllCustomersBasedOnMerchant`
    );
  }

  GetPaymentMethodList(): Observable<any> {
    return this._HttpClient.get(`${baseUrl}payment-methods`);
  }

  GetBankList(): Observable<any> {
    return this._HttpClient.get(`${baseUrl}banks/getActiveBanks`);
  }
  GetCompany(): Observable<any> {
    return this._HttpClient.get(
      `${baseUrl}company/getAllActiveCompanyByMerchant?sortBy=createdDate&order=desc`
    );
  }
  GetCustomerbyId(customer_id: any) {
    return this._HttpClient.get(
      `${baseUrl}customers/getCustomerById/${customer_id}`
    );
  }

  GetCompanybyId(company_id: any) {
    return this._HttpClient.get(
      `${baseUrl}company/getCompanyByCompanyId/${company_id}`
    );
  }
  GetProducts() {
    return this._HttpClient.get(
      `${baseUrl}products/getAllProductsBasedOnMerchant`
    );
  }
  GetVatTypeList() {
    return this._HttpClient.get(`${baseUrl}vat-list/active`);
  }

  GetInvoiceTypeList() {
    return this._HttpClient.get(`${baseUrl}invoice-type-list/active`);
  }

  getAllBranchById(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}banks-branches/getAllBankBranchById/${id}`
    );
  }

  getBranchById(id: number): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}banks-branches/${id}`);
  }

  GetProductbycode(code: any) {
    return this._HttpClient.get(`${baseUrl}products/getProductById/` + code);
  }

  SaveInvoice(invoicedata: any) {
    return this._HttpClient.post(`${baseUrl}invoice`, invoicedata);
  }

  GetYear(year: any) {
    return this._HttpClient.get(`${baseUrl}yearly-max-amount/year/${year}`);
  }

  SaveReceipt(receiptdata: any) {
    return this._HttpClient.post(`${baseUrl}receipt`, receiptdata);
  }

  SaveUpdatedInvoice(id: any, invoicedata: any) {
    return this._HttpClient.put(`${baseUrl}invoice/${id}`, invoicedata);
  }

  updateInvoiceAndReceipt(id: any, invoicedata: any) {
    return this._HttpClient.put(
      `${baseUrl}invoice/updateInvoiceAndRecipte/${id}`,
      invoicedata
    );
  }

  createInvoiceAndReceipt(invoicedata: any) {
    return this._HttpClient.post(
      `${baseUrl}invoice/createInvoiceAndReceipt`,
      invoicedata
    );
  }

  GetInvoiceForUpdate(InvoiceId: any) {
    return this._HttpClient.get(
      `${baseUrl}invoice/getInvoicesByInvoiceId/` + InvoiceId
    );
  }
  getInvoicesAndReceiptsByInvoiceId(InvoiceId: any) {
    return this._HttpClient.get(
      `${baseUrl}invoice/getInvoicesAndReceiptsByInvoiceId/` + InvoiceId
    );
  }

  convertTheInvoiceToReverseInvoice(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}invoice/convertTheInvoiceToReverseInvoice/${id}`
    );
  }

  updateTokenBody(id: any, updateTokenBody: any) {
    return this._HttpClient.put(`${baseUrl}oauth/${id}`, updateTokenBody);
  }

  refreshAccessToken(
    refreshToken: string,
    clientId: string,
    clientSecret: string
  ): Observable<any> {
    const tokenUrl = environment.tokenUrl; // استخدام tokenUrl من ملف البيئة

    const body = new URLSearchParams();
    body.set("grant_type", "refresh_token");
    body.set("refresh_token", refreshToken);
    body.set("client_id", clientId);
    body.set("client_secret", clientSecret);
    body.set("scope", "scope");

    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });

    return this._HttpClient.post(tokenUrl, body.toString(), { headers });
  }

  exchangeCodeForToken(
    code: string,
    clientId: string,
    clientSecret: string,
    redirectUri: string,
    scope: string
  ): Observable<any> {
    const tokenUrl = environment.tokenUrl; // استخدام tokenUrl من ملف البيئة

    const body = new URLSearchParams({
      grant_type: "authorization_code",
      code: code,
      redirect_uri: redirectUri,
      client_id: clientId,
      client_secret: clientSecret,
      scope: scope,
    }).toString();

    // Create the authorization header using Base64 encoding
    const authHeader = `Basic ${btoa(`${clientId}:${clientSecret}`)}`;

    // Prepare headers for the HTTP request
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: authHeader,
    });

    // Make the HTTP post request to the token URL
    return this._HttpClient.post(tokenUrl, body, { headers });
  }

  SaveTokenData(tokenData: any) {
    return this._HttpClient.post(`${baseUrl}oauth/save-token`, tokenData);
  }

  sendInvoiceToGovernment(token: string, data: any): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    const url =
      "https://openapi.taxes.gov.il/shaam/tsandbox/Invoices/v1/Approval";

    return this._HttpClient.post<any>(url, data, { headers: headers });
  }

  getValidToken(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}oauth/getValidToken`);
  }

  getAllTransactionsByMerchantId(id: any) {
    return this._HttpClient.get(
      `${baseUrl}zip/getAllTransactionsByMerchantId/${id}`
    );
  }

  /* start terms and conditions functions */

  getAllTermsAndConditions(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}terms-and-conditions`);
  }

  getAllActiveTermsAndConditions(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}terms-and-conditions/active`);
  }

  updateTermsData(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}terms-and-conditions/update/${id}`,
      model
    );
  }

  changeTermsStatus(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}terms-and-conditions/update-status/${id}`,
      model
    );
  }

  deleteTermsById(id: number): Observable<any> {
    return this._HttpClient.delete<any>(
      `${baseUrl}terms-and-conditions/delete/${id}`
    );
  }

  getInvoiceAndRecipeToPdf(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}invoice/getInvoiceAndRecipeToPdf/${id}`
    );
  }

  /* start accounting office functions */

  getAllAccountingOffice(limit: number, offset: number): Observable<any> {
    const params = new HttpParams()
      .set("limit", limit.toString())
      .set("offset", offset.toString());

    return this._HttpClient.get<any>(
      `${baseUrl}accounting-office/getAllOffice`,
      { params }
    );
  }

  changeAccountingOfficeStatus(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}accounting-office/changeStatusOffice/${id}`,
      model
    );
  }

  updateOfficesById(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}accounting-office/updateOffice/${id}`,
      model
    );
  }

  getALLActiveCompany(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}company/getALLActiveCompany`);
  }

  getAllPermissionsByOfficeId(
    id: number,
    limit: number,
    offset: number
  ): Observable<any> {
    const params = new HttpParams()
      .set("limit", limit.toString())
      .set("offset", offset.toString());

    return this._HttpClient.get<any>(
      `${baseUrl}office-company-permissions/getAllPermissionsByOfficeId/${id}`,
      { params }
    );
  }

  getAllUserAssesToCompany(
    officeId: number,
    companyId: number
  ): Observable<any> {
    const params = new HttpParams()
      .set("officeId", officeId.toString())
      .set("companyId", companyId.toString());
    return this._HttpClient.get<any>(
      `${baseUrl}accountant-users-company-access/getAllUserAssesToCompany`,
      { params }
    );
  }

  getAllPendingPermissionsByOffice(
    limit: number,
    offset: number
  ): Observable<any> {
    const params = new HttpParams()
      .set("limit", limit.toString())
      .set("offset", offset.toString());

    return this._HttpClient.get<any>(
      `${baseUrl}office-company-permissions/getAllPendingPermissionsByOffice`,
      { params }
    );
  }

  changeCompanyPermissionStatus(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}office-company-permissions/${id}`,
      model
    );
  }

  changeCompanyPermissionStatusAll(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}office-company-permissions/update-pending-status/${id}`,
      model
    );
  }

  getAllOfficeInfo(id: number): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}accounting-office/${id}`);
  }

  updateOfficeById(id: number, model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}accounting-office/updateOffice/${id}`,
      model
    );
  }

  getAllAgreedPermissionsByOfficeId(
    id: number,
    limit: number,
    offset: number,
    keyword?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set("limit", limit.toString())
      .set("offset", offset.toString());

    if (keyword) {
      params = params.set("keyword", keyword);
    }

    return this._HttpClient.get<any>(
      `${baseUrl}office-company-permissions/getAllAgreedPermissionsByOfficeId/${id}`,
      { params }
    );
  }

  getAllAccessByUserId(
    id: number,
    limit: number,
    offset: number,
    keyword?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set("limit", limit.toString())
      .set("offset", offset.toString());

    if (keyword) {
      params = params.set("keyword", keyword);
    }
    return this._HttpClient.get<any>(
      `${baseUrl}accountant-users-company-access/getAllAccessByUserId/${id}`,
      { params }
    );
  }

  getAllActiveAgreedPermissionsByOfficeId(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}office-company-permissions/getAllActiveAgreedPermissionsByOfficeId/${id}`
    );
  }

  deleteOnAccess(id: number): Observable<any> {
    return this._HttpClient.delete<any>(
      `${baseUrl}accountant-users-company-access/deleteOnAccess/${id}`
    );
  }

  deleteRelatedOfficeToCompany(id: number): Observable<any> {
    return this._HttpClient.delete<any>(
      `${baseUrl}office-company-permissions/deleteRelatedOfficeToCompany/${id}`
    );
  }

  getAllPermissionsByMerchantId(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}office-company-permissions/getAllPermissionsByMerchantId/${id}`
    );
  }

  getAllActiveAccountingOffices(): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}accounting-office/getAllActiveAccountingOffices`
    );
  }

  getAllActiveCompanyByMerchant(): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}company/getAllActiveCompanyByMerchant`
    );
  }

  getAllCompanyDocumentsComponent(
    id: number,
    limit: number,
    offset: number,
    fromDate: any,
    toDate: any,
    searchKeyword: any
  ): Observable<any> {
    let params = new HttpParams()
      .set("limit", limit.toString())
      .set("offset", offset.toString());

    if (fromDate) {
      params = params.set("fromDate", fromDate.toString());
    }

    if (toDate) {
      params = params.set("toDate", toDate.toString());
    }

    if (searchKeyword) {
      params = params.set("searchKeyword", searchKeyword.toString());
    }

    return this._HttpClient.get<any>(
      `${baseUrl}invoice/allInvoiceByCompanyId/${id}`,
      { params }
    );
  }

  printAllInvoiceByCompanyId(
    id: number,
    fromDate: any,
    toDate: any
  ): Observable<any> {
    const params = new HttpParams()
      .set("fromDate", fromDate?.toString())
      .set("toDate", toDate?.toString());
    return this._HttpClient.get<any>(
      `${baseUrl}invoice/printAllInvoiceByCompanyId/${id}`,
      { params }
    );
  }

  /* start reports functions */

  getAllReportsIncome(
    companyId: string,
    startDate: string,
    endDate: string,
    lang: string
  ): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("lang", lang);

    return this._HttpClient.get<any>(`${baseUrl}reports/income`, { params });
  }

  getFinanicalReport(
    companyId: string,
    startDate: string,
    endDate: string,
    lang: string
  ): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("lang", lang);

    return this._HttpClient.get<Blob>(
      `${baseUrl}reports/${companyId}/financial-report/pdf`,
      { params, responseType: "blob" as "json" }
    );
  }

  getAllReportsIncomeExel(
    companyId: string,
    startDate: string,
    endDate: string,
    lang: string
  ): Observable<Blob> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("lang", lang);

    return this._HttpClient.get<Blob>(`${baseUrl}reports/income/excel`, {
      params,
      responseType: "blob" as "json",
    });
  }

  getAllReportsIncomePdf(
    companyId: string,
    startDate: string,
    endDate: string,
    lang: string
  ): Observable<Blob> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("lang", lang);

    return this._HttpClient.get<Blob>(`${baseUrl}reports/income/pdf`, {
      params,
      responseType: "blob" as "json",
    });
  }

  getAllConsoliDatedIncome(
    companyId: string,
    startDate: string,
    endDate: string,
    lang: string
  ): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("lang", lang);
    return this._HttpClient.get<any>(`${baseUrl}reports/consolidated-income`, {
      params,
    });
  }

  getAllConsoliDatedIncomeExel(
    companyId: string,
    startDate: string,
    endDate: string,
    lang: string
  ): Observable<Blob> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("lang", lang);
    return this._HttpClient.get<Blob>(
      `${baseUrl}reports/consolidated-income/excel`,
      { params, responseType: "blob" as "json" }
    );
  }

  getAllConsoliDatedIncomePdf(
    companyId: string,
    startDate: string,
    endDate: string,
    lang: string
  ): Observable<Blob> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("lang", lang);
    return this._HttpClient.get<Blob>(
      `${baseUrl}reports/consolidated-income-report-pdf`,
      { params, responseType: "blob" as "json" }
    );
  }

  getAllReportsDocument(
    companyId: string,
    startDate: string,
    endDate: string,
    lang: string
  ): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("lang", lang);
    return this._HttpClient.get<any>(`${baseUrl}reports/document`, { params });
  }

  getAllReportsDocumentExel(
    companyId: string,
    startDate: string,
    endDate: string,
    lang: string
  ): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("lang", lang);
    return this._HttpClient.get<any>(`${baseUrl}reports/document/excel`, {
      params,
      responseType: "blob" as "json",
    });
  }

  getAllReportsDocumentPdf(
    companyId: string,
    startDate: string,
    endDate: string,
    lang: string
  ): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("lang", lang);
    return this._HttpClient.get<any>(`${baseUrl}reports/document/pdf`, {
      params,
      responseType: "blob" as "json",
    });
  }

  getAllReportsItem(
    companyId: string,
    startDate: string,
    endDate: string,
    lang: string
  ): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("lang", lang);
    return this._HttpClient.get<any>(`${baseUrl}reports/item`, { params });
  }

  getAllReportsItemExel(
    companyId: string,
    startDate: string,
    endDate: string,
    lang: string
  ): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("lang", lang);
    return this._HttpClient.get<any>(`${baseUrl}reports/item/excel`, {
      params,
      responseType: "blob" as "json",
    });
  }

  getAllReportsItemPdf(
    companyId: string,
    startDate: string,
    endDate: string,
    lang: string
  ): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("lang", lang);
    return this._HttpClient.get<any>(`${baseUrl}reports/item-pdf`, {
      params,
      responseType: "blob" as "json",
    });
  }

  getAllReportsConsolidatedIitem(
    companyId: string,
    startDate: string,
    endDate: string,
    lang: string
  ): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("lang", lang);
    return this._HttpClient.get<any>(`${baseUrl}reports/consolidated-item`, {
      params,
    });
  }

  getAllConsolidatedItemPdf(
    companyId: string,
    startDate: string,
    endDate: string,
    lang: string
  ): Observable<Blob> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("lang", lang);

    return this._HttpClient.get<Blob>(
      `${baseUrl}reports/consolidated-item-pdf`,
      { params, responseType: "blob" as "json" }
    );
  }

  getAllReportsConsolidatedIitemeExel(
    companyId: string,
    startDate: string,
    endDate: string,
    lang: string
  ): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("lang", lang);
    return this._HttpClient.get<any>(
      `${baseUrl}reports/consolidated-item/excel`,
      { params, responseType: "blob" as "json" }
    );
  }

  getAllReceiptsDetails(
    companyId: string,
    startDate: string,
    endDate: string,
    lang: string
  ): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("lang", lang);
    return this._HttpClient.get<any>(`${baseUrl}reports/receipts-details`, {
      params,
    });
  }

  getAllReceiptsDetailsExel(
    companyId: string,
    startDate: string,
    endDate: string,
    lang: string
  ): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("lang", lang);
    return this._HttpClient.get<any>(
      `${baseUrl}reports/receipts-details/excel`,
      { params, responseType: "blob" as "json" }
    );
  }

  getAllReceiptsDetailsPdf(
    companyId: string,
    startDate: string,
    endDate: string,
    lang: string
  ): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("lang", lang);
    return this._HttpClient.get<any>(`${baseUrl}reports/receipts-details-pdf`, {
      params,
      responseType: "blob" as "json",
    });
  }

  getDashboardReport(
    companyId: string,
    startDate: string,
    endDate: string,
    lang: string
  ): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("lang", lang);
    return this._HttpClient.get<any>(`${baseUrl}reports/getDashboardReport`, {
      params,
    });
  }

  getDashboardReportExel(companyId: string, lang: string): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)

      .set("lang", lang);
    return this._HttpClient.get<any>(
      `${baseUrl}reports/getDashboardReport/excel`,
      { params, responseType: "blob" as "json" }
    );
  }

  getDashboardReportPdf(companyId: string, lang: string): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)

      .set("lang", lang);
    return this._HttpClient.get<any>(
      `${baseUrl}reports/getDashboardReport-pdf`,
      { params, responseType: "blob" as "json" }
    );
  }

  getDashboardReports(companyId: string): Observable<any> {
    const params = new HttpParams().set("companyId", companyId);
    // .set('startDate', startDate)
    // .set('endDate', endDate)
    // .set('lang', lang)
    return this._HttpClient.get<any>(`${baseUrl}reports/getDashboardReport`, {
      params,
    });
  }

  getSummaryReport(companyId: string): Observable<any> {
    const params = new HttpParams().set("companyId", companyId);
    // .set('startDate', startDate)
    // .set('endDate', endDate)
    // .set('lang', lang)
    return this._HttpClient.get<any>(`${baseUrl}reports/getSummaryReport`, {
      params,
    });
  }

  getSummaryReportExel(companyId: string, lang: string): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)

      .set("lang", lang);
    return this._HttpClient.get<any>(
      `${baseUrl}reports/getSummaryReport/excel`,
      { params, responseType: "blob" as "json" }
    );
  }

  getSummaryReportpdf(companyId: string, lang: string): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)

      .set("lang", lang);
    return this._HttpClient.get<any>(`${baseUrl}reports/getSummaryReport-pdf`, {
      params,
      responseType: "blob" as "json",
    });
  }

  getSummaryReportHtml(companyId: string, lang: string): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)

      .set("lang", lang);
    return this._HttpClient.get<any>(`${baseUrl}reports/getSummaryReportHtml`, {
      params,
      responseType: "text" as "json",
    });
  }

  getIncomeReportHtml(
    companyId: string,
    lang: string,
    startDate: any,
    endDate: any
  ): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)

      .set("lang", lang)
      .set("startDate", startDate)
      .set("endDate", endDate);
    return this._HttpClient.get<any>(`${baseUrl}reports/income/html`, {
      params,
      responseType: "text" as "json",
    });
  }

  getIncomeReport(
    companyId: string,
    startDate: any,
    endDate: any
  ): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("startDate", startDate)
      .set("endDate", endDate);
    return this._HttpClient.get<any>(`${baseUrl}reports/income`, { params });
  }

  checkSequenceExists(companyId: number, docType: string): Observable<boolean> {
    let params = new HttpParams()
      .set("companyId", companyId.toString())
      .set("docType", docType);
    return this._HttpClient.get<boolean>(
      `${baseUrl}company/checkSequenceExists`,
      { params }
    );
  }

  createSequence(
    companyId: number,
    docType: string,
    startWith: number
  ): Observable<void> {
    return this._HttpClient.post<void>(`${baseUrl}company/createSequence`, {
      companyId,
      docType,
      startWith,
    });
  }

  /* */

  getOfficeStatistics(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}office-company-permissions/getOfficeStatistics/${id}`
    );
  }

  setCompanyDefault(model: any): Observable<any> {
    return this._HttpClient.put<any>(
      `${baseUrl}users/setCompanyDefault`,
      model
    );
  }

  // ConectWithIsr(companyId: any): Observable<any> {
  //   const params = new HttpParams().set('companyId', companyId);
  //   const url = environment.production ? environment.apiUrl : `${environment.apiUrl}`;

  //   return this._HttpClient.get<any>(url, { params });
  // }

  //ConnectWithTaxGovernment(companyId: any): Observable<any> {
  //const params = new HttpParams().set('companyId', companyId);

  //return this._HttpClient.get<any>(`https://intg.thinvoices.com/oauth-prod/authorization-code`, { params });
  //}

  ConnectWithTaxGovernment(companyId: any): Observable<any> {
    const params = new HttpParams().set("companyId", companyId);
    const headers = new HttpHeaders().set(
      "x-api-key",
      "4f67a1e2f3c4b5d6e7f8a9b0c1d2e3f4a5b6c7d8e9f0a1b2c3d4e5f6a7b8c9d0"
    );

    return this._HttpClient.get<any>(
      `https://intg.thinvoices.com/oauth-prod/authorization-code`,
      { params, headers }
    );
  }

  uploadExcelFile(formData: FormData): Observable<any> {
    return this._HttpClient.post<any>(`${baseUrl}zip/uploadExcel`, formData);
  }

  deleteConfirmationNumberByUuid(invoice_uuid: string): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}invoice/deleteConfirmationNumberByUuid/${invoice_uuid}`
    );
  }

  getAllTransactionsHistoryZipFile(): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}zip/getAllTransactions`);
  }

  getAllResponseLogsByLogId(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}zip/getAllResponseLogsByLogId/${id}`
    );
  }

  /* start reports functions */

  getAllClosedMonthCompany(
    companyId: number,
    officeId: number
  ): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("officeId", officeId);
    return this._HttpClient.get<any>(`${baseUrl}closed-months/company-office`, {
      params,
    });
  }

  changeLockClosedStatus(id: any, updatedBy: any): Observable<any> {
    const params = new HttpParams().set("updatedBy", updatedBy);
    return this._HttpClient.put<any>(
      `${baseUrl}closed-months/${id}/toggle-lock`,
      null,
      { params }
    );
  }

  getLockedMonthInvoicesByAccess(): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}accounting-office/getLockedMonthInvoicesByAccess`
    );
  }

  cancleAert(invoice_id: any, updated_by: any): Observable<any> {
    const params = new HttpParams().set("updated_by", updated_by);
    return this._HttpClient.put<any>(
      `${baseUrl}invoice/cancel-alert/${invoice_id}`,
      null,
      { params }
    );
  }

  checkClosedMonth(
    companyId: number,
    month: number,
    year: number
  ): Observable<any> {
    const params = new HttpParams()
      .set("companyId", companyId)
      .set("month", month.toString())
      .set("year", year.toString());

    return this._HttpClient.get<any>(`${baseUrl}closed-months/check`, {
      params,
    });
  }

  deleteClosedMonth(id: number): Observable<any> {
    return this._HttpClient.delete<any>(`${baseUrl}closed-months/${id}`);
  }

  getAllInvoiceLockedByCompanyId(companyId: number): Observable<any> {
    return this._HttpClient.get<any>(`${baseUrl}invoice/locked/${companyId}`);
  }

  // integration

  InvoiceDecisionApiCancel(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}integration/InvoiceDecisionApiCancel/${id}`
    );
  }

  InvoiceDecisionApiContinue(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}integration/InvoiceDecisionApiContinue/${id}`
    );
  }

  InvoiceDecisionApiFurtherObjection(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}integration/InvoiceDecisionApiFurtherObjection/${id}`
    );
  }

  InvoiceAfterHearingApproval(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}integration/InvoiceAfterHearingApproval/${id}`
    );
  }

  InvoiceReversingTheCharge(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}integration/InvoiceReversingTheCharge/${id}`
    );
  }

  reSendInvoiceSecondToGovernmentMainProject(id: number): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}integration/sendInvoiceSecondToGovernmentMainProject/${id}`
    );
  }

  getMerchantDocuments(page: number, size: number): Observable<any> {
    let params = new HttpParams().set("limit", size).set("offset", page * size);
    return this._HttpClient.get<any>(
      `${baseUrl}invoice/getALLInvoiceByBasedMerchantId`,
      { params }
    );
  }

  /*getMerchantDocuments(page: number, size: number): Observable<any> {
    let params = new HttpParams().set("limit", size).set("offset", page * size);
    return this._HttpClient.get<any>(
      `${baseUrl}invoice/getALLInvoiceByBasedMerchantId`,
      { params }
    );
  }
*/
getFilteredDocuments(filters: any): Observable<any> {
  const {
    fromDate,
    toDate,
    companyId,
    invoice_type,
    customer_id,
    merchant_id,
    searchKeyword,
    status,
    limit,
    offset,
    sortIndex,
    sort
  } = filters;

  let params = new HttpParams()
    .set("fromDate", fromDate || "")
    .set("toDate", toDate || "")
    .set("companyId", companyId || "")
    .set("invoice_type", invoice_type || "")
    .set("merchant_id", merchant_id || "")
    .set("searchKeyword", searchKeyword || "")
    .set("customer_id", customer_id || "")
    .set("limit", limit)
    .set("offset", offset)
    .set("sortIndex", sortIndex !== undefined && sortIndex !== null ? sortIndex.toString() : "")
    .set("sort", sort);

  if (status !== null) {
    params = params.set("status", status.toString());
  }

  return this._HttpClient.get<any>(`${baseUrl}invoice/filter`, { params });
}
  
  createRefNumber(invoice_uuid: string): Observable<any> {
    return this._HttpClient.get<any>(
      `${baseUrl}invoice/confirmInvoice/${invoice_uuid}`
    );
  }

  exportReportToPdf(filters: any): Observable<Blob> {
    const {
      fromDate,
      toDate,
      companyId,
      invoice_type,
      customer_id,
      searchKeyword,
      status,
      limit,
      offset,
      sortIndex,
      sort,
      lang
    } = filters;
    
    let params = new HttpParams()
      .set("fromDate", fromDate || "")
      .set("toDate", toDate || "")
      .set("companyId", companyId || "")
      .set("lang", lang || "en")
       .set("limit", limit || 10000) // Use a large number to get all records for PDF
      .set("offset", offset || 0)
      .set("invoice_type", invoice_type || "")
      .set("searchKeyword", searchKeyword || "")
      .set("sort", sort || "DESC");

    // Handle sortIndex safely - convert to string only if defined
    if (sortIndex !== undefined && sortIndex !== null) {
      params = params.set("sortIndex", sortIndex.toString());
    } else {
      params = params.set("sortIndex", "0");
    }

    // Handle customer_id safely
    if (customer_id) {
      params = params.set("customer_id", customer_id);
    }
    
    // Handle status separately to avoid sending null or undefined
    if (status !== null && status !== undefined) {
      params = params.set("status", status.toString());
    }

    return this._HttpClient.get(`${baseUrl}documents/export/report`, {
      params,
      responseType: 'blob'
    });
  }
}
