<app-breadcrumb [title]="curentLang === 'en' ? 'Dashboard' : (curentLang === 'ar' ? 'لوحة التحكم' : 'לוח הבקרה')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Dashboard' : (curentLang === 'ar' ? 'لوحة التحكم' : 'לוח הבקרה')">
</app-breadcrumb>



<div *ngIf="verifyUser == 1">


<!-- statics -->
<div class="container-fluid">
  <div class="row widget-grid">
    <div class="col">
    <div class="row">
      <div class="col-12">
        <div class="card-header">
          <h5 style="color: grey;">{{ 'dashboard.statistics' | translate }}</h5>
        </div>
      </div>
    </div>
    
    <div class="row  d-flex justify-content-between" *ngIf="user_type == 100 || user_type == 101">
      <!-- <div class="col-xxl-4 col-sm-6 box-col-6 ">
        <div class="card profile-box">
          <div class="card-body">
            <div class="media">
              <div class="media-body">
                <div class="greeting-user">
                  <h5 class="f-w-600"> {{ curentLang === 'en' ? 'Welcome to THI Invoices' : (curentLang === 'ar' ? ' مرحبا بكم في THI Invoices' : '    ברוכים הבאים ל-THI Invoices  ')}}</h5>
                  <p> {{ curentLang === 'en' ? 'THere whats happing in your account ' : (curentLang === 'ar' ? ' هنا ما يحدث في حسابك ' : ' הנה מה קורה בחשבון שלך x   ')}} </p>
                  <div class="whatsnew-btn"><a class="btn " style="background-color: transparent; color: transparent;"> {{ curentLang === 'en' ? 'Whats New !' : (curentLang === 'ar' ? ' ما هو جديد  ' : ' הנה מה חדש  ')}}</a></div>
                </div>
              </div>
              <div>
                <div class="clockbox">
                  <svg id="clock" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
                    
                  </svg>
                </div>
                <div class="badge f-10 p-0" id="txt"></div>
              </div>
            </div>
            <div class="cartoon"><img class="img-fluid" src="assets/images/dashboard/cartoon.svg" alt="vector women with leptop" /></div>
          </div>
        </div>
      </div> -->

      <div class="col-md-12 ">
        <div class="row">
          <div class="col-xxl-3 col-sm-6 box-col-6">
            <div class="card small-widget">
              <div class="card-body {{ newOrders.colorClass }}">
                <span class="f-light">{{ curentLang === 'en' ? 'Invoice Used Count' : (curentLang === 'ar' ? ' عدد المستندات المستخدمة' : 'מספר המסמכים בשימוש    ') }}</span>
                <div class="d-flex align-items-end gap-1">
                  <h4 style="color: grey;">{{ allAnalyticsSubplansData.invoiceUsedCount }}</h4>
                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <!---->
          <div class="col-xxl-3 col-sm-6 box-col-6">
            <div class="card small-widget">
              <div class="card-body {{ newOrders.colorClass3 }}">
                <span class="f-light">{{ curentLang === 'en' ? 'Number Of Documents' : (curentLang === 'ar' ? '   عدد المستندات    ' : ' מספר מסמכים  ') }}</span>
                <div class="d-flex align-items-end gap-1">
                  <h4 *ngIf="allAnalyticsSubplansData.is_unlimited_documents == false" style="color: grey;">{{ allAnalyticsSubplansData.number_of_documents }}</h4>
                  <h4 *ngIf="allAnalyticsSubplansData.is_unlimited_documents == true" style="color: grey;">
                    {{ curentLang === 'en' ? 'Unlimited' : (curentLang === 'ar' ? ' غير محدود ' : ' ללא הגבלה ') }}
                  </h4>
                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <!---->
          <div class="col-xxl-3 col-sm-6 box-col-6">
            <div class="card small-widget">
              <div class="card-body {{ newOrders.colorClass2 }}">
                <span class="f-light">{{ curentLang === 'en' ? 'Number Of Companies' : (curentLang === 'ar' ? '    عدد الشركات    ' : '     מספר חברות       ') }}</span>
                <div class="d-flex align-items-end gap-1">
                  <h4 style="color: grey;">{{ allAnalyticsSubplansData.number_of_company }}</h4>
                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <!---->
          <div class="col-xxl-3 col-sm-6 box-col-6  ">
            <div class="card small-widget">
              <div class="card-body {{ newOrders.colorClass2 }}">
                <span class="f-light">{{ curentLang === 'en' ? 'Number Of Users' : (curentLang === 'ar' ? ' عدد المستخدمين  ' : '  מספר משתמשים  ') }}</span>
                <div class="d-flex align-items-end gap-1"> 
                  <h4 style="color: grey;">{{ allAnalyticsSubplansData.number_of_users }}</h4>
                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon2 }}"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
             <!---->
             <div class="col-xxl-3 col-sm-6 box-col-6 mt-3">
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass4 }}">
                  <span class="f-light">{{ curentLang === 'en' ? 'Company Used Count' : (curentLang === 'ar' ? '  عدد الشركات المستخدمة    ' : '     מספר חברות שנמצאו    ') }}</span>
                  <div class="d-flex align-items-end gap-1"> 
                    <h4 style="color: grey;">{{ allAnalyticsSubplansData.companyUsedCount }}</h4>
                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon3 }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

             <!---->
             <div class="col-xxl-3 col-sm-6 box-col-6 mt-3">
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass }}">
                  <span class="f-light">{{ curentLang === 'en' ? 'User Used Count' : (curentLang === 'ar' ? ' عدد المستخدمين المستخدم   ' : ' מספר משתמשים שנמצאו ') }}</span>
                  <div class="d-flex align-items-end gap-1"> 
                    <h4 style="color: grey;">{{ allAnalyticsSubplansData.userUsedCount }}</h4>
                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon2 }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

             <!---->
             <div class="col-xxl-3 col-sm-6 box-col-6 mt-3">
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass }}">
                  <span class="f-light">{{ curentLang === 'en' ? 'From Date' : (curentLang === 'ar' ? '  من تاريخ ' : '  מהתאריך של   ') }}</span>
                  <div class="d-flex align-items-end gap-1"> 
                    <h4 style="color: grey;">{{ allAnalyticsSubplansData.start_date | date }}</h4>
                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{  newOrders.icon }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

             <!---->
             <div class="col-xxl-3 col-sm-6 box-col-6 mt-3">
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass }}">
                  <span class="f-light">{{ curentLang === 'en' ? 'To Date    ' : (curentLang === 'ar' ? ' الي تاريخ' : ' עד היום ') }}</span>
                  <div class="d-flex align-items-end gap-1"> 
                    <h4 style="color: grey;">{{ allAnalyticsSubplansData.end_date | date }}</h4>
                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{  newOrders.icon }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

        </div>
      </div>
    
  
    </div>

    <!---->

    <div class="row  d-flex justify-content-between" *ngIf="user_type == 200 || user_type == 201">
      <div class="col-xxl-4 col-sm-6 box-col-6 ">
        <div class="card profile-box">
          <div class="card-body">
            <div class="media">
              <div class="media-body">
                <div class="greeting-user">
                  <h5 class="f-w-600"> {{ curentLang === 'en' ? 'Welcome to THI Invoices' : (curentLang === 'ar' ? ' مرحبا بكم في THI Invoices' : '    ברוכים הבאים ל-THI Invoices  ')}}</h5>
                  <p> {{ curentLang === 'en' ? 'THere whats happing in your account ' : (curentLang === 'ar' ? ' هنا ما يحدث في حسابك ' : ' הנה מה קורה בחשבון שלך x   ')}} </p>
                  <div class="whatsnew-btn"><a class="btn " style="background-color: transparent; color: transparent;"> {{ curentLang === 'en' ? 'Whats New !' : (curentLang === 'ar' ? ' ما هو جديد  ' : ' הנה מה חדש  ')}}</a></div>
                </div>
              </div>
              <div>
                <div class="clockbox">
                  <svg id="clock" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
                    <!-- رموز الساعة -->
                  </svg>
                </div>
                <div class="badge f-10 p-0" id="txt"></div>
              </div>
            </div>
            <div class="cartoon"><img class="img-fluid" src="assets/images/dashboard/cartoon.svg" alt="vector women with leptop" /></div>
          </div>
        </div>
      </div>

      <div class="col-md-8 ">
        <div class="row">
          <div class="col-xxl-4 col-sm-6 box-col-6">
            <div class="card small-widget">
              <div class="card-body {{ newOrders.colorClass }}">
                <span class="f-light">{{ curentLang === 'en' ? 'Number Of Documents ' : (curentLang === 'ar' ? ' عدد المستندات ' : 'מספר מסמכים  ') }}</span>
                <div class="d-flex align-items-end gap-1">
                  <h4 style="color: grey;">{{ allAnalyticsSubplansData.invoiceCount }}</h4>
                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <!---->
          <div class="col-xxl-4 col-sm-6 box-col-6">
            <div class="card small-widget">
              <div class="card-body {{ newOrders.colorClass3 }}">
                <span class="f-light">{{ curentLang === 'en' ? 'Number Of Merchant' : (curentLang === 'ar' ? '   عدد التجار    ' : 'מספר סוחרים      ') }}</span>
                <div class="d-flex align-items-end gap-1">
                  <h4 style="color: grey;">{{ allAnalyticsSubplansData?.merchantCount }}</h4>
                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <!---->
          <div class="col-xxl-4 col-sm-6 box-col-6">
            <div class="card small-widget">
              <div class="card-body {{ newOrders.colorClass2 }}">
                <span class="f-light">{{ curentLang === 'en' ? 'Number Of Companies' : (curentLang === 'ar' ? '    عدد الشركات    ' : '     מספר חברות       ') }}</span>
                <div class="d-flex align-items-end gap-1">
                  <h4 style="color: grey;">{{ allAnalyticsSubplansData?.companyCount }}</h4>
                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <!---->
          <div class="col-xxl-4 col-sm-6 box-col-6 mt-3">
            <div class="card small-widget">
              <div class="card-body {{ newOrders.colorClass2 }}">
                <span class="f-light">{{ curentLang === 'en' ? 'Number Of Users' : (curentLang === 'ar' ? ' عدد المستخدمين  ' : '  מספר משתמשים  ') }}</span>
                <div class="d-flex align-items-end gap-1"> 
                  <h4 style="color: grey;">{{ allAnalyticsSubplansData?.userCount }}</h4>
                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon2 }}"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
             <!---->
             <div class="col-xxl-4 col-sm-6 box-col-6 mt-3">
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass4 }}">
                  <span class="f-light">{{ curentLang === 'en' ? 'Number Of Subscribed Merchants' : (curentLang === 'ar' ? '  عدد التجار المشتركين   ' : 'מספר הסוחרים המשתתפים') }}</span>
                  <div class="d-flex align-items-end gap-1"> 
                    <h4 style="color: grey;">{{ allAnalyticsSubplansData.merchantSubscribedCount }}</h4>
                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon3 }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

             <!---->
             <div class="col-xxl-4 col-sm-6 box-col-6 mt-3">
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass }}">
                  <span class="f-light">{{ curentLang === 'en' ? 'Merchant Not Subscribed Count' : (curentLang === 'ar' ? '  عدد التجار الغير المشترك ' : ' מספר סוחרים שאינם משתתפים') }}</span>
                  <div class="d-flex align-items-end gap-1"> 
                    <h4 style="color: grey;">{{ allAnalyticsSubplansData.merchantNotSubscribedCount }}</h4>
                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon2 }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

        </div>
      </div>
    
  
    </div>


    <div class="row  d-flex justify-content-between" *ngIf="user_type == 300 || user_type == 301">
      

      <div class="col-md-12">
        <div class="row">
          <div class="col-xxl-3 col-lg-4  col-sm-12 box-col-6">
            <div class="card small-widget">
              <div class="card-body {{ newOrders.colorClass }}">
                <span class="f-light">{{ curentLang === 'en' ? 'Monthly office statistics ' : (curentLang === 'ar' ? ' إحصائيات المكتب الشهرية' : 'סטטיסטיקות משרדיות חודשיות') }}</span>
                <div class="d-flex align-items-end gap-1">
                  <h4 style="color: grey;" >
                  <i class="fa fa-bar-chart-o" style="font-size: 25px;"></i>
                  </h4>
                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <!---->
          <div class="col-xxl-3 col-lg-4  col-sm-12 box-col-6">
            <div class="card small-widget">
              <div class="card-body {{ newOrders.colorClass3 }}">
                <span class="f-light">{{ curentLang === 'en' ? 'Requests Accepted' : (curentLang === 'ar' ? ' الطلبات المقبولة   ' : 'הבקשות התקבלו ') }}</span>
                <div class="d-flex align-items-end gap-1">
                  <h4 style="color: grey;">{{ allOfficeStatisticsData?.month?.agreed }}</h4>
                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <!---->
          <div class="col-xxl-3 col-lg-4  col-sm-12 box-col-6">
            <div class="card small-widget">
              <div class="card-body {{ newOrders.colorClass2 }}">
                <span class="f-light">{{ curentLang === 'en' ? 'Pending Requests' : (curentLang === 'ar' ? ' طلبات قيد الأنتظار   ' : 'בקשות ממתינות') }}</span>
                <div class="d-flex align-items-end gap-1">
                  <h4 style="color: grey;">{{ allOfficeStatisticsData?.month?.pending }}</h4>
                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <!---->
          <div class="col-xxl-3 col-lg-4  col-sm-12 box-col-6 ">
            <div class="card small-widget">
              <div class="card-body {{ newOrders.colorClass2 }}">
                <span class="f-light">{{ curentLang === 'en' ? 'Rejected Requests' : (curentLang === 'ar' ? 'الطلبات المرفوضة' : 'הבקשות נדחו') }}</span>
                <div class="d-flex align-items-end gap-1"> 
                  <h4 style="color: grey;">{{ allOfficeStatisticsData?.month?.rejected}}</h4>
                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon2 }}"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>
             <!---->
             <div class="col-xxl-3 col-lg-4  col-sm-12 box-col-6  ">
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass }}">
                  <span class="f-light">{{ curentLang === 'en' ? 'Annual office statistics ' : (curentLang === 'ar' ? ' إحصائيات المكتب السنوية' : 'סטטיסטיקה שנתית של משרד') }}</span>
                  <div class="d-flex align-items-end gap-1">
                    <h4 style="color: grey;">
                    <i class="fa fa-bar-chart-o" style="font-size: 25px;"></i>
                    </h4>
                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

             <!---->
             <div class="col-xxl-3 col-lg-4  col-sm-12 box-col-6">
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass3 }}">
                  <span class="f-light">{{ curentLang === 'en' ? 'Requests Accepted' : (curentLang === 'ar' ? ' الطلبات المقبولة   ' : 'הבקשות התקבלו ') }}</span>
                  <div class="d-flex align-items-end gap-1">
                    <h4 style="color: grey;">{{ allOfficeStatisticsData?.year?.agreed }}</h4>
                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <!---->
            <div class="col-xxl-3 col-lg-4  col-sm-12 box-col-6">
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass2 }}">
                  <span class="f-light">{{ curentLang === 'en' ? 'Pending Requests' : (curentLang === 'ar' ? ' طلبات قيد الأنتظار   ' : 'בקשות ממתינות') }}</span>
                  <div class="d-flex align-items-end gap-1">
                    <h4 style="color: grey;">{{ allOfficeStatisticsData?.year?.pending }}</h4>
                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <!---->
            <div class="col-xxl-3 col-lg-4  col-sm-12 box-col-6 ">
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass2 }}">
                  <span class="f-light">{{ curentLang === 'en' ? 'Rejected Requests' : (curentLang === 'ar' ? 'الطلبات المرفوضة' : 'הבקשות נדחו') }}</span>
                  <div class="d-flex align-items-end gap-1"> 
                    <h4 style="color: grey;">{{ allOfficeStatisticsData?.year?.rejected}}</h4>
                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon2 }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

        </div>
      </div>
    
  
    </div>

    </div>
    </div>
  </div>
<!---->

<!-- sub plans -->
<div class="container-fluid" *ngIf="user_type != 300" [hidden]="user_type == 301">
  <app-subscription-plans></app-subscription-plans>
</div>

<!---->

<!--Merchant Subscription Plans-->

<!-- <div class="container-fluid basic_table" *ngIf="user_type == 100">
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header">
        <h5 style="color: grey;">
            {{ curentLang === 'en' ? 'Merchant subscription plans' : (curentLang === 'ar' ? 'خطط الأشتراك الخاصة بالتاجر' : 'תוכניות מנוי לסוחר')}}
        </h5>
     
      </div>
      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Plan Name' : (curentLang === 'ar' ? '  اسم الخطة' : '  שם התוכנית')}} </th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Price' : (curentLang === 'ar' ? '  السعر' : '  מחיר')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Amount Paid' : (curentLang === 'ar' ? '  المبلغ المدفوع' : '  כמות שולמה')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Payment Type' : (curentLang === 'ar' ? '   نوع الدفع' : '   סוג תשלום')}}</th>
                  <th scope="col">{{ curentLang === 'en' ? 'Number Of Documents' : (curentLang === 'ar' ? ' عدد المستندات' : ' מספר מסמכים')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Documents Remaining' : (curentLang === 'ar' ? ' المستندات المتبقية' : ' מסמכים נשארו')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Number Of Companies' : (curentLang === 'ar' ? ' عدد الشركات' : ' מספר חברות')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Number Of Users' : (curentLang === 'ar' ? ' عدد المستخدمين' : ' מספר משתמשים')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Status' : (curentLang === 'ar' ? 'الحالة' : 'סטטוס')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of subPlansByMerchant | paginate: {itemsPerPage:7, currentPage:p } |filter:term ; let i = index ">
                  <td scope="row">{{i+1}}</td>
                  <td> {{curentLang === 'en' ? item.subscribeToPlanDetails[0]?.plan_name_en : (curentLang === 'ar' ? item.subscribeToPlanDetails[0]?.plan_name_ar : item.subscribeToPlanDetails[0]?.plan_name_he)}}</td>
                  <td> {{item.subscribeToPlanDetails[0]?.price}}</td>
                  <td>{{item.subscribeToPlanDetails[0]?.amount_paid}}</td>
                  <td>{{item.subscribeToPlanDetails[0]?.payment_type}}</td>
                  <td>{{item.number_of_documents}}</td>
                  <td>{{ item.documents_remaining}}</td>
                  <td>{{item.number_of_company}}</td>
                  <td>{{item.number_of_users}}</td>
                  <td *ngIf="item.status == 1" class="font-success">{{ curentLang === 'en' ? 'Avilable' : (curentLang === 'ar' ? ' متوفر' : ' זמין')}}</td>
                  <td>{{ item.createdDate | date:'shortDate'}}</td>
                </tr>
          
              </tbody>
            </table>
            <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>                

          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
</div> -->

</div>

<div class="modal-overlay-otp" *ngIf="verifyUser === 0">
  <div class="modal-content-otp">
    <div class="login-card-otp" >
      <div>
        <div class="login-main">
          <form class="theme-form" (ngSubmit)="otp()">
            <h4 style="color: #141414; text-align: center;" class="mb-3">{{ 'forget.otp' | translate }}</h4>
            <div class="text-center mt-4 mb-4">
              <span class="reset-password-link">{{ 'forget.if' | translate }}
                <a class="btn-link text-danger" [routerLink]="'/resend-otp'">{{ 'forget.resend' | translate }}</a>
              </span>
            </div>
            <label class="col-form-label pt-0 m-auto text-center" style="color: #141414;">{{ 'forget.enter' | translate }}</label>
            <div class="form-group">
              <div class="row">
                <div class="col">
                  <input class="form-control text-center opt-text"
                         type="text"
                         placeholder="0000"
                         maxlength="4"
                         [(ngModel)]="otpCode"
                         inputmode="numeric"
                         pattern="[0-9]*"
                         name="otpCode"
                         (keypress)="isNumber($event)">
                </div>
              </div>
            </div>
            <button [disabled]="!otpCode || otpCode.length !== 4"
                    class="btn d-block w-100 m-t-10"
                    style="background-color: #141414; color: #fff;"
                    type="submit">{{ 'forget.submit' | translate }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
