<div class="dropdown-wrapper">
  <i class="pi pi-calendar" (click)="calendar.toggle()"></i>
  <p-calendar #calendar [(ngModel)]="selectedTimePeriod" selectionMode="range" 
    showButtonBar="true" [numberOfMonths]="2" [touchUI]="true" 
    placeholder="{{ 'allDocuments.filters.period.placeholder' | translate }}" (onSelect)="onCalendarSelect($event)">
    <ng-template pTemplate="header">
      <div class="d-flex justify-content-between pb-3">
        <p-selectButton [options]="translatedTimePeriodOptions" optionLabel="label"
          (onOptionClick)="onTimePeriodSelect($event.option)">
          <ng-template let-item>
            {{ item.label | translate }}
          </ng-template>
        </p-selectButton>
        <button pButton type="button" label="{{ 'allDocuments.filters.period.apply' | translate }}" icon="pi pi-check" 
          class="p-button-primary" (click)="applyTimePeriod()"></button>
      </div>
    </ng-template>
  </p-calendar>
</div>
