import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionPlansComponent } from './subscription-plans.component';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ButtonModule } from 'primeng/button';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CardModule } from 'primeng/card';
import { TooltipModule } from 'primeng/tooltip'; // Add import for TooltipModule
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [SubscriptionPlansComponent],
    imports: [
        CommonModule,
        FormsModule,
        ToastModule,
        ConfirmDialogModule,
        ButtonModule,
        SelectButtonModule,
        CardModule,
        TooltipModule // Add TooltipModule to imports
    ],
    exports: [SubscriptionPlansComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SubscriptionPlansModule { }
