<app-breadcrumb
*ngIf="!isInsideModal"
  [title]="
    curentLang === 'en'
      ? 'Documents'
      : curentLang === 'ar'
      ? 'المستندات'
      : 'מסמכים'
  "
  [items]="
    curentLang === 'en'
      ? ['Dashboard']
      : curentLang === 'ar'
      ? ['لوحة التحكم']
      : ['לוח הבקרה']
  "
  [active_item]="
    curentLang === 'en'
      ? 'Documents'
      : curentLang === 'ar'
      ? 'المستندات'
      : 'מסמכים'
  "
>
</app-breadcrumb>
<div class="card">
  <div class="card position-sticky">
    <div class="d-flex justify-content-between px-3 bg-light">
      <div class="d-flex">
        <button
          type="button"
          (click)="openXl2(content2)"
          class="p-button p-button-primary px-2"
          title="{{'documents.addInvoice'|translate}}"
        >
        <span class="d-none d-md-inline">{{'documents.newDocument'|translate}}</span>
        <svg width="32" class="d-md-none" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.-->
          <path fill="#fff"
            d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zm48 96a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm16 80c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 48-48 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l48 0 0 48c0 8.8 7.2 16 16 16s16-7.2 16-16l0-48 48 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-48 0 0-48z" />
        </svg>
        </button>
        <button type="button" (click)="exportReportToPdf()" class="p-button p-button-secondary p-0 bg-transparent border-0 ms-2"
          title="{{'documents.exportReport'|translate}}">
          <!-- <span class="d-none d-md-inline text-black">{{'documents.exportReport'|translate}}</span> -->
          <svg style="max-height: 50px; width:50px" width="176" height="229" viewBox="0 0 176 229" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.48793 81.899C0.68293 76.252 0.480927 70.552 0.478927 64.897L0.495925 31.103C0.497925 27.295 -0.0270834 17.721 0.733917 14.541C2.03392 9.10099 8.22793 2.17399 13.9919 1.29699C18.4449 0.619993 23.3769 1.01399 27.8939 1.01999L100.891 1.01699C105.337 1.01699 125.439 -0.0880089 128.649 1.90399C131.74 3.82199 134.327 6.71299 136.938 9.21899L162.979 34.304C167 38.14 174.437 43.4 175.219 49.312C176.194 56.681 175.688 64.555 175.688 71.988L175.69 199.949C175.689 210.98 176.838 225.065 162.763 228.751C159.596 228.435 156.141 228.728 152.944 228.735L29.7889 228.755C23.4769 228.759 17.3329 228.936 11.0369 228.207C10.8559 228.036 10.9319 228.079 10.7339 227.986C0.0629168 223.034 0.460926 216.802 0.465926 206.732L0.48793 190.193V187.801L0.483917 124.827C0.485917 114.187 0.71993 103.506 0.48793 92.871C0.83993 90.314 0.64693 84.614 0.48793 81.899ZM125.394 46.016C122.102 42.592 123.484 34.913 123.482 30.444L123.486 9.27399C98.3749 8.96799 73.2259 9.30599 48.1099 9.28199L25.0249 9.26399C14.0679 9.24099 17.9889 8.57099 8.91492 11.778V159.14C8.61992 161.981 8.90693 164.982 8.91193 167.843L8.90793 205.298C8.89893 222.808 11.1139 220.553 26.6679 220.539L145.396 220.538C168.885 220.551 167.456 222.841 167.411 196.221L167.41 172.443C167.414 169.963 168.402 160.627 166.995 159.14C167.893 157.094 167.411 139.891 167.41 136.94L167.406 107.594C167.407 104.94 168.205 91.939 166.995 90.029C168.19 86.983 167.535 53.427 166.995 52.63C163.279 52.476 159.509 52.617 155.788 52.619L133.677 52.63C132.574 52.478 131.431 52.583 130.321 52.63C128.452 50.152 126.638 49.054 125.394 46.016Z"
              fill="white" />
            <path
              d="M123.486 9.27501L123.482 30.444C123.484 34.914 122.102 42.592 125.394 46.016C126.638 49.054 128.452 50.152 130.321 52.63C131.431 52.583 132.574 52.478 133.677 52.63C132.499 52.604 130.722 52.203 129.662 52.63C130.896 54.577 133.203 56.073 134.849 57.7C143.778 66.535 156.51 76.965 164.213 86.36C164.906 87.205 166.15 89.665 166.995 90.029C168.205 91.939 167.407 104.94 167.406 107.594L167.41 136.94C167.411 139.891 167.893 157.094 166.995 159.14L26.545 159.142C20.678 159.141 14.777 158.948 8.91504 159.14V11.778C17.989 8.57101 14.068 9.24101 25.025 9.26401L48.11 9.28201C73.226 9.30601 98.375 8.96801 123.486 9.27501ZM75.012 63.787C74.681 63.993 71.375 66.169 71.23 66.303C70.816 74.306 71.204 82.606 71.201 90.639C68.344 90.765 59.073 90.014 57.262 91.826C51.237 97.857 65.826 111.199 69.937 116.533L79.31 128.767C87.515 139.273 86.17 136.514 93.782 130.509C97.29 124.021 107.355 112.297 112.363 106.162C117.472 99.904 121.806 97.737 115.369 90.639C111.388 90.361 107.321 90.668 103.325 90.639L103.343 74.914C103.355 68.192 104.516 63.699 95.623 63.757L82.263 63.765C79.897 63.75 77.354 63.525 75.012 63.787Z"
              fill="#E8E8E7" />
            <path
              d="M129.662 52.63C130.722 52.203 132.499 52.604 133.677 52.63L155.788 52.619C159.509 52.617 163.279 52.476 166.995 52.63C167.535 53.427 168.19 86.983 166.995 90.029C166.15 89.665 164.906 87.205 164.213 86.36C156.51 76.965 143.778 66.535 134.849 57.7C133.203 56.073 130.896 54.577 129.662 52.63Z"
              fill="#D6D5DF" />
            <path
              d="M71.2011 90.639C71.2041 82.606 70.8161 74.306 71.2301 66.303C71.3751 66.169 74.6811 63.993 75.0121 63.787C77.3541 63.525 79.8971 63.75 82.2631 63.765L95.6231 63.757C104.516 63.699 103.355 68.192 103.343 74.914L103.325 90.639C107.321 90.668 111.388 90.361 115.369 90.639C121.806 97.737 117.472 99.904 112.363 106.162C107.355 112.297 97.2901 124.021 93.7821 130.509C86.1701 136.514 87.5151 139.273 79.3101 128.767L69.9371 116.533C65.8261 111.199 51.2371 97.857 57.2621 91.826C59.0731 90.014 68.3441 90.765 71.2011 90.639ZM20.6831 159.098C16.7571 159.055 12.8271 159.012 8.91509 159.14C8.71609 161.058 8.78211 163.05 8.84811 165.022C8.87911 165.97 8.9101 166.914 8.9121 167.843L8.90811 205.298C8.90011 220.843 10.6451 220.81 21.9201 220.597C23.3451 220.57 24.9231 220.541 26.6681 220.539L145.396 220.538C156.82 220.544 162.349 221.089 165.011 218.585C167.822 215.941 167.434 209.895 167.411 196.221L167.41 172.443C167.411 171.865 167.465 170.914 167.53 169.777C167.745 166.036 168.074 160.28 166.995 159.14L26.5451 159.142C24.5941 159.142 22.6391 159.12 20.6831 159.098Z"
              fill="#F86D54" />
            <path
              d="M49.5901 203.544V174.453H62.1471C64.3061 174.453 66.1951 174.879 67.8151 175.731C69.4341 176.583 70.6941 177.781 71.5931 179.325C72.4931 180.868 72.9431 182.672 72.9431 184.737C72.9431 186.82 72.4791 188.624 71.5511 190.149C70.6321 191.673 69.3391 192.848 67.6731 193.672C66.0161 194.495 64.0791 194.907 61.8631 194.907H54.3631V188.771H60.2721C61.2001 188.771 61.9911 188.61 62.6441 188.288C63.3071 187.957 63.8141 187.488 64.1641 186.882C64.5241 186.276 64.7041 185.561 64.7041 184.737C64.7041 183.904 64.5241 183.193 64.1641 182.606C63.8099 182.006 63.279 181.53 62.6441 181.243C61.9911 180.921 61.2001 180.76 60.2721 180.76H57.4881V203.544H49.5901ZM87.0341 203.544H75.8401V174.453H86.9201C89.9121 174.453 92.4981 175.035 94.6761 176.2C96.8269 177.319 98.5892 179.062 99.7321 181.2C100.926 183.368 101.522 185.968 101.522 188.998C101.522 192.029 100.93 194.633 99.7471 196.811C98.5631 198.979 96.8871 200.646 94.7181 201.811C92.5501 202.966 89.9881 203.544 87.0341 203.544ZM83.7381 196.839H86.7491C88.1891 196.839 89.4151 196.607 90.4281 196.143C91.4511 195.679 92.2281 194.879 92.7581 193.743C93.2981 192.606 93.5681 191.025 93.5681 188.998C93.5681 186.972 93.2931 185.39 92.7441 184.254C92.2041 183.118 91.4091 182.317 90.3571 181.853C89.3161 181.389 88.0371 181.157 86.5221 181.157H83.7381V196.839ZM105.176 203.544V174.453H125.631V180.816H113.074V185.816H124.381V192.18H113.074V203.544H105.176Z"
              fill="white" />
          </svg>
        </button>
      </div>
      <div class="d-flex">
        <button type="button" class="p-button-primary p-button d-md-none px-2 me-2" [class]="hasActiveFilters() ? 'filters-active' : ''" (click)="showFilters()">
          <svg width="32" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.-->
            <path fill="#fff"
              d="M3.9 54.9C10.5 40.9 24.5 32 40 32l432 0c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9 320 448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6l0-79.1L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z" />
          </svg>
        </button>
        <button type="button" (click)="clearFilters()" class="p-button-secondary p-button reset-button px-2" title="Reset">
          <svg width="32" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.-->
            <path fill="#fff"
              d="M386.3 160L336 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l128 0c17.7 0 32-14.3 32-32l0-128c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0s-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3s163.8-62.5 226.3 0L386.3 160z" />
          </svg>
        </button>
      </div>
    </div>
  </div>

  <p-table
    #dt
    [value]="filteredDocuments"
    [lazy]="true"
    [paginator]="true"
    [rowsPerPageOptions]="[10, 20, 50, 100]"
    [rows]="rowsPerPage"
    [totalRecords]="totalRecords"
    [loading]="loading"
    [sortField]="sortField"
    [sortOrder]="sortOrder"
    (onLazyLoad)="loadDocuments($event)"
    [dir]="curentLang === 'ar' || curentLang === 'he' ? 'rtl' : 'ltr'"
    [scrollable]="true"
  >
  <ng-template pTemplate="header">
    <tr class="d-none d-block d-md-flex" id="filterRow">
      <th>
        <div class="dropdown-container">
          <!-- <label class="dropdown-label">
            <strong>
              {{
                curentLang === "en"
                  ? "Search"
                  : curentLang === "ar"
                  ? "بحث"
                  : "חיפוש"
              }}
            </strong>
          </label> -->
          <input
            type="text"
            class="form-control dropdown-input full-width-dropdown"
            [(ngModel)]="filters.generalSearch"
            (input)="applyGeneralSearch()"
            [placeholder]="
              curentLang === 'en'
                ? 'Search'
                : curentLang === 'ar'
                ? 'بحث'
                : 'חיפוש'
            "
            [dir]="curentLang === 'ar' || curentLang === 'he' ? 'rtl' : 'ltr'"
            name="generalSearch"
          />
        </div>
      </th>
      <th>
        <div class="dropdown-container">
          <!-- <label class="dropdown-label">
            <strong>
              {{
                curentLang === "en"
                  ? "Select Customer"
                  : curentLang === "ar"
                  ? "اختر العميل"
                  : "בחר לקוח"
              }}
            </strong>
          </label> -->
          <p-dropdown
            id="customerName"
            [(ngModel)]="filters.customerName"
            [options]="filteredCustomers"
            optionLabel="name"
            optionValue="code"
            [filter]="true"
            [placeholder]="
              curentLang === 'en'
                ? 'Select Customer'
                : curentLang === 'ar'
                ? 'اختر العميل'
                : 'בחר לקוח'
            "
            (onChange)="applyFilters()"
            class="full-width-dropdown"
          ></p-dropdown>
        </div>
      </th>
      <th *ngIf="!hideCompanyColumn">
        <div class="dropdown-container">
          <!-- <label class="dropdown-label">
            <strong>
              {{
                curentLang === "en"
                  ? "Select Company"
                  : curentLang === "ar"
                  ? "اختر الشركة"
                  : "בחר חברה"
              }}
            </strong>
          </label> -->
          <p-dropdown
            [(ngModel)]="filters.companyId"
            [options]="filteredCompanies"
            optionLabel="name"
            optionValue="code"
            [placeholder]="
              curentLang === 'en'
                ? 'Select Company'
                : curentLang === 'ar'
                ? 'اختر الشركة'
                : 'בחר חברה'
            "
            [filter]="true"
            class="full-width-dropdown"
            (onChange)="applyFilters()"
          ></p-dropdown>
        </div>
      </th>
      <th>
        <div class="dropdown-container">
          <!-- <label class="dropdown-label">
            <strong>
              {{
                curentLang === "en"
                  ? "Select Document Type"
                  : curentLang === "ar"
                  ? "اختر نوع المستند"
                  : "בחר סוג מסמך"
              }}
            </strong>
          </label> -->
          <p-dropdown
            [options]="documentTypes"
            [(ngModel)]="filters.documentType"
            optionLabel="name"
            optionValue="code"
            [placeholder]="
              curentLang === 'en'
                ? 'Select Document Type'
                : curentLang === 'ar'
                ? 'اختر نوع المستند'
                : 'בחר סוג מסמך'
            "
            (onChange)="applyFilters()"
            class="full-width-dropdown"
          ></p-dropdown>
        </div>
      </th>
      <th>
        <div class="dropdown-container">
          <!-- <label class="dropdown-label">
            <strong>
              {{
                curentLang === "en"
                  ? "Select Status"
                  : curentLang === "ar"
                  ? "اختر الحالة"
                  : "בחר סטטוס"
              }}
            </strong>
          </label> -->
          <p-dropdown
            [options]="documentStatuses"
            [(ngModel)]="filters.documentStatus"
            optionLabel="name"
            optionValue="code"
            [placeholder]="
              curentLang === 'en'
                ? 'Select Status'
                : curentLang === 'ar'
                ? 'اختر الحالة'
                : 'בחר סטטוס'
            "
            (onChange)="applyFilters()"
            class="full-width-dropdown"
          ></p-dropdown>
        </div>
      </th>
      <th>
        <div class="custom-header-container">
          <div class="input-group">
            <!-- <label class="dropdown-label">
              <strong>
                {{
                  curentLang === 'en'
                    ? 'Period'
                    : curentLang === 'ar'
                    ? 'الفترة'
                    : 'תקופה'
                }}
              </strong>
            </label> -->
            <p-dropdown
              [options]="periods"
              [(ngModel)]="filters.period"
              optionLabel="name"
              optionValue="code"
              (onChange)="updateDateRangeByPeriod(); applyFilters()"
              [placeholder]="
                curentLang === 'en'
                  ? 'Select Period'
                  : curentLang === 'ar'
                  ? 'اختر الفترة'
                  : 'בחר תקופה'
              "
              class="full-width-dropdown mr-2"
            ></p-dropdown>
          </div>
        </div>
      </th>
      <th>
        <div class="custom-header-container">
          <div class="input-group">
            <!-- <label class="dropdown-label">
              <strong>
                {{
                  curentLang === 'en'
                    ? 'Date Range'
                    : curentLang === 'ar'
                    ? 'نطاق التاريخ'
                    : 'טווח תאריכים'
                }}
              </strong>
            </label> -->
            <input
            type="text"
            id="daterange"
            class="custom-daterange"
            [placeholder]="
              curentLang === 'en'
                ? 'Select Date Range'
                : curentLang === 'ar'
                ? 'اختر نطاق التاريخ'
                : 'בחר טווח תאריכים'
            "
            name="daterange"
          />
          <i class="pi pi-calendar calendar-icon" (click)="openDateRangePicker()"></i>
          </div>
        </div>
      </th>
    </tr>
    <tr class="d-none d-md-flex">
      <th pSortableColumn="documentDate">
        <strong>
          {{
            curentLang === "en"
              ? "Document Date"
              : curentLang === "ar"
              ? "تاريخ المستند"
              : "תאריך מסמך"
          }}
        </strong>
        <p-sortIcon field="documentDate"></p-sortIcon>
      </th>
      <th pSortableColumn="documentType">
        <strong>
          {{
            curentLang === "en"
              ? "Type"
              : curentLang === "ar"
              ? "النوع"
              : "סוג"
          }}
        </strong>
        <p-sortIcon field="documentType"></p-sortIcon>
      </th>
      <th pSortableColumn="documentNumber">
        <strong>
          {{
          curentLang === "en"
          ? "Number"
          : curentLang === "ar"
          ? "الرقم"
          : "מספר"
          }}
        </strong>
        <p-sortIcon field="documentNumber"></p-sortIcon>
      </th>
      <th pSortableColumn="companyName" *ngIf="!hideCompanyColumn">
        <strong>
          {{
          curentLang === "en"
          ? "Company"
          : curentLang === "ar"
          ? "الشركة"
          : "חברה"
          }}
        </strong>
        <p-sortIcon field="companyName"></p-sortIcon>
      </th>
      <th pSortableColumn="customerName" class="wrap-content">
        <strong>
          {{
          curentLang === "en"
          ? "Customer"
          : curentLang === "ar"
          ? "العميل"
          : "לקוח"
          }}
        </strong>
        <p-sortIcon field="customerName"></p-sortIcon>
      </th>
      <th pSortableColumn="paymentAmount">
        <strong>
          {{
            curentLang === "en"
              ? "Amount"
              : curentLang === "ar"
              ? "المبلغ"
              : "סכום"
          }}
        </strong>
        <p-sortIcon field="paymentAmount"></p-sortIcon>
      </th>
      <th pSortableColumn="confirmationNumber" class="multiline-text">
        <strong>{{
            curentLang === "en"
              ? "Confirmation Number"
              : curentLang === "ar"
              ? "رقم التخصيص"
              : curentLang === "he"
              ? "מספר אישור"
              : "رقم التخصيص"
          }}</strong>
        <p-sortIcon field="confirmationNumber"></p-sortIcon>
      </th>
      <th pSortableColumn="documentStatus">
        <strong>
          {{
            curentLang === "en"
              ? "Status"
              : curentLang === "ar"
              ? "الحالة"
              : "סטָטוּס"
          }}
        </strong>
        <p-sortIcon field="documentStatus"></p-sortIcon>
      </th>
      <th class="flex-grow-0" style="min-width: 80px;">
        <strong>
          {{
            curentLang === "en"
              ? "Actions"
              : curentLang === "ar"
              ? "الإجراءات"
              : "נהלים"
          }}
        </strong>
      </th>
    </tr>
  </ng-template>
    <ng-template pTemplate="body" let-doc>
      <tr class="datatable__row" [class]="doc?.is_deleted == 1 ? 'text-danger' : ''">
        <!-- <td style="text-align: center">
          {{ doc.createdDate | date : "dd/MM/yyyy" }}
        </td> -->
        <td class="datatable__date" (click)="rowAction(doc)">
          <span class="multiline-text">{{
            doc.invoice_date | date : "dd/MM/yyyy"
          }}</span>
        </td>
        <td class="datatable__type" (click)="rowAction(doc)">{{ getTranslatedDocumentType(doc.invoice_type) }}</td>
        <td class="datatable__dnumber" (click)="rowAction(doc)">
          <span class="multiline-text">{{ doc.document_number }}</span>
        </td>
        <td class="datatable__company" *ngIf="!hideCompanyColumn" (click)="rowAction(doc)">
          <span class="multiline-text">{{ doc.company_name }}</span>
        </td>
        <td class="datatable__customer" (click)="rowAction(doc)">
          <span class="multiline-text">{{ doc.customer_name }}</span>
        </td>
        <td class="datatable__amount" (click)="rowAction(doc)">
          <span class="multiline-text">{{ doc.payment_amount | number:'1.2-2' }}</span>
        </td>
        <td class="datatable__cnumber" (click)="rowAction(doc)">
          <span class="multiline-text">{{ doc.confirmation_number }}</span>
        </td>
        <td class="datatable__status d-none d-md-flex align-items-center" (click)="rowAction(doc)">
          <svg *ngIf="doc?.is_deleted == 1 && doc?.confirm == 1"
          xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path fill="#f00"
              d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
          </svg>
          <svg *ngIf="doc?.is_deleted == 0 && doc?.confirm == 1"
          xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path fill="#0f0"
              d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
          </svg>
          <svg *ngIf="doc?.confirm == 0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.-->
            <path fill="#2196F3"
              d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-242.7c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32L64 32zm0 96c0-17.7 14.3-32 32-32l192 0c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32L96 224c-17.7 0-32-14.3-32-32l0-64zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
          </svg>
        </td>
        <td class="datatable__action flex-grow-0" [attr.data-link]="doc.pdf_link" [attr.data-id]="doc.invoice_id" [attr.data-invoiceuuid]="doc.invoice_uuid" [attr.data-documentnumber]="doc.document_number">
          <p-menu #menu [model]="getFilteredActions(doc)" [popup]="true"></p-menu>
          <button
            type="button"
            class="p-0 border-0 bg-transparent"
            (click)="menu.toggle($event)"
          >
        <svg width="32" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.-->
          <path fill="#000"
            d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
        </svg>
        </button>
        </td>
      </tr>
    </ng-template>
    <!--  -->
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="10" class="text-center">
          {{
            curentLang === "en"
              ? "No documents found."
              : curentLang === "ar"
              ? "لم يتم العثور على مستندات."
              : "לא נמצאו מסמכים."
          }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>



<ng-template #content4 let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title" style="color: grey;">
      {{curentLang === 'en' ? 'Document Details' : (curentLang === 'ar' ? 'تفاصيل المستند' : 'פרטי מסמך')}}
    </h6>
    <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4 mt-4">
          <button type="button" (click)="downloadDocumentPdf(); modal.close('Close click')" class="btn btn-sm mx-4 mt-4"
            style="background-color: #141414; color: #fff;">
            <i class="fa fa-file-pdf-o mx-1"></i>
            {{ curentLang === 'en' ? 'Print' : (curentLang === 'ar' ? 'طباعة نسخة' : 'הפקת העתק') }}
          </button>
        </div>

        <div class="col-md-4 mt-4">
          <div class="dropdown mx-4 mt-4">
            <button class="btn dropdown-toggle w-100" type="button" id="shareDocumentDropdown" data-bs-toggle="dropdown"
              aria-expanded="false" style="background-color: #141414; color: #fff;">
              {{ curentLang === 'en' ? 'Share Original Version' : (curentLang === 'ar' ? 'مشاركة النسخة الأصلية' : 'שתף את המקור') }}
              <i class="fa fa-share-alt mx-1 text-white" style="font-size: 15px;"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="shareDocumentDropdown">
              <li style="border-bottom: 1px solid #000;">
                <a class="dropdown-item" style="cursor: pointer; text-align: start; color: #000;" (click)="downloadOriginalDocPdfFromLink()">
                  <i class="fa fa-download mx-1" style="font-size: 15px; color: rgb(37, 16, 160);"></i>
                  {{ curentLang === 'en' ? 'Download original version': (curentLang === 'ar' ? 'تحميل النسخة الأصلية  ' : 'הורד את הגרסה המקורית') }}
                </a>
              </li>
              <li style="border-bottom: 1px solid #000;">
                <a class="dropdown-item" style="cursor: pointer; text-align: start; color: #000;" (click)="copyLinkToClipboard(rawPdfUrl)">
                  <i class="fa fa-copy mx-1" style="font-size: 15px; color: rgb(158, 40, 40);"></i>
                  {{ curentLang === 'en' ? 'Copy Link' : (curentLang === 'ar' ? 'نسخ الرابط' : 'העתק קישור') }}
                </a>
              </li>
              <li style="border-bottom: 1px solid #000;">
                <a class="dropdown-item" style="cursor: pointer; text-align: start; color: #000;" href="https://wa.me/?text={{ rawPdfUrl }}" target="_blank">
                    <i class="fa fa-whatsapp mx-1" style="font-size: 15px; color: rgb(32, 92, 32);"></i>
                    {{ curentLang === 'en' ? 'Share via WhatsApp' : (curentLang === 'ar' ? 'مشاركة عبر واتساب' : 'שתף בוואטסאפ') }}
                </a>
            </li>
              <li>
                <a class="dropdown-item" style="cursor: pointer; text-align: start; color: #000;" href="mailto:?subject=Document&body={{ rawPdfUrl }}">
                  <i class="fa fa-envelope mx-1" style="font-size: 15px; color: rgb(137, 137, 16);"></i>
                  {{ curentLang === 'en' ? 'Share via Email' : (curentLang === 'ar' ? 'مشاركة عبر البريد الإلكتروني' : 'שתף באימייל') }}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-md-4 my-2">
          <div class="form-group mx-4">
            <label class="mt-2" style="color: #141414;">
              {{ curentLang === 'en' ? 'Change Document Language' : (curentLang === 'ar' ? 'تغيير لغة المستند' : 'שינוי שפת המסמך') }}
            </label>
            <select class="form-control" (change)="filterDocumentLanguage($event.target.value)">
              <option *ngFor="let lang of availableLanguages" [value]="lang.code">
                {{ lang.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div *ngIf="PdfLink == null || PdfLink == ''">
          <div [innerHTML]="documentHtmlContent" class="document-container"></div>
        </div>
        
        <iframe *ngIf="PdfLink && pdfUrl" [src]="pdfUrl" width="100%" height="500px" frameborder="0"></iframe>
      </div>
    </div>

    <div class="modal-footer my-2">
      <button type="button" class="btn btn-sm mt-2 btn-danger" (click)="modal.close('Close click')">
        {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
        <i class="fa fa-rotate-left mx-1"></i>
      </button>
    </div>
  </div>
</ng-template>

<!--  -->
<!--  -->
<!-- <div class="col-6 col-sm-6 col-xl-4" style="cursor: pointer;" (click)="openXl2(content2)">
  <div class="ribbon-wrapper card">
    <div class="card-body text-center text-white">
      <i class="fa fa-file-text" style="color:#fff; font-size: 35px; cursor: pointer;"></i>
      <p>
        {{ curentLang === 'en' ? 'Invoices' : (curentLang === 'ar' ? '  الفواتير' : 'חשבוניות')}}
      </p>
    </div>
  </div>
</div> -->

<ng-template #content2 let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title" style="color: grey;">
      {{ curentLang === 'en' ? 'Invoices Types' : (curentLang === 'ar' ? 'أنواع الفواتير' : 'סוגי חשבוניות')}}
    </h6>
    <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="edit-profile">
        <div class="row d-flex justify-content-between">
          <div class="content">
            <p routerLink="/dashboard/add-invoice" style="cursor: pointer;" (click)="modal.close('Close click')">
              <i class="fa fa-plus mx-1" style="color: #141414; font-size: 14px;"></i>
              {{ curentLang === 'en' ? ' Tax Invoice' : (curentLang === 'ar' ? ' فاتورة ضريبة ' : '     חשבונית מס ')}}
            </p>
            <hr style="color: #141414; border: solid 1px;">
          </div>
          <div class="content">
            <p routerLink="/dashboard/add-receipts" style="cursor: pointer;" (click)="modal.close('Close click')">
              <i class="fa fa-plus mx-1" style="color: #141414; font-size: 14px;"></i>
              {{ curentLang === 'en' ? '  receipt' : (curentLang === 'ar' ? '   ايصال ' : 'קבלה   ')}}
            </p>
            <hr style="color: #141414; border: solid 1px;">
          </div>
          <div class="content">
            <p routerLink="/dashboard/add-invoice-with-receipt" style="cursor: pointer;" (click)="modal.close('Close click')">
              <i class="fa fa-plus mx-1" style="color: #141414; font-size: 14px;"></i>
              {{ curentLang === 'en' ? '  Invoice And Receipt' : (curentLang === 'ar' ? '   فاتورة مع ايصال  ' : 'חשבונית מס / קבלה   ')}}
            </p>
            <hr style="color: #141414; border: solid 1px;">
          </div>
          <div class="content">
            <p routerLink="/dashboard/add-finaical-request" style="cursor: pointer;" (click)="modal.close('Close click')">
              <i class="fa fa-plus mx-1" style="color: #141414; font-size: 14px;"></i>
              {{ curentLang === 'en' ? '  Finaical Request' : (curentLang === 'ar' ? '    مطالبة مالية  ' : '    חשבונית עסקה')}}
            </p>
            <hr style="color: #141414; border: solid 1px;">
          </div>
          <div class="content">
            <p routerLink="/dashboard/add-reverce-invoice" style="cursor: pointer;" (click)="modal.close('Close click')">
              <i class="fa fa-plus mx-1" style="color: #141414; font-size: 14px;"></i>
              {{ curentLang === 'en' ? 'Add Reverce Invoice' : (curentLang === 'ar' ? '  اضافة  فاتورة عكسية  ' : ' חשבונית זיכוי')}}
            </p>
            <hr style="color: #141414; border: solid 1px;">
          </div>
          <div class="content">
            <p routerLink="/dashboard/add-mission" style="cursor: pointer;" (click)="modal.close('Close click')">
              <i class="fa fa-plus mx-1" style="color: #141414; font-size: 14px;"></i>
              {{ curentLang === 'en' ? '  Mission' : (curentLang === 'ar' ? '     ارسالية  ' : ' תעודת משלוח   ')}}
            </p>
            <hr style="color: #141414; border: solid 1px;">
          </div>
          <div class="content">
            <p routerLink="/dashboard/add-offer-price" style="cursor: pointer;" (click)="modal.close('Close click')">
              <i class="fa fa-plus mx-1" style="color: #141414; font-size: 14px;"></i>
              {{ curentLang === 'en' ? '  Offer Price' : (curentLang === 'ar' ? '    عرض سعر  ' : '    הוסף הצעת מחיר  ')}}
            </p>
            <hr style="color: #141414; border: solid 1px;">
          </div>
          <div class="content">
            <p routerLink="/dashboard/add-order" style="cursor: pointer;" (click)="modal.close('Close click')">
              <i class="fa fa-plus mx-1" style="color: #141414; font-size: 14px;"></i>
              {{ curentLang === 'en' ? '  Order' : (curentLang === 'ar' ? '     طلبية  ' : '  הוסף הזמנה  ')}}
            </p>
            <hr style="color: #141414; border: solid 1px;">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <div></div>
    <button routerLink="/dashboard/documents" type="button" class="btn btn-sm ml-auto btn-danger" (click)="modal.close('Close click')">
      {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ')}}
      <i class="fa fa-rotate-left mx-1"></i>
    </button>
  </div>
</ng-template>