import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild, TemplateRef } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENTS_ACTIONS } from './alldocuments-actions.data';
import { DocumentsAction } from './alldocuments-actions.model';
import { DocumentType } from '../alldocuments-types/alldocuments-types.model';
import { DOCUMENT_TYPES } from '../alldocuments-types/alldocuments-types.data';

@Component({
  selector: 'app-alldocuments-actions',
  templateUrl: './alldocuments-actions.component.html',
  styleUrls: ['./alldocuments-actions.component.scss']
})
export class AlldocumentsActionsComponent implements OnInit, OnChanges {
  @Input() activeFiltersCount: string | number = 0;
  @Input() showResetColumnsButton: boolean = false;
  @Input() isPdfExporting: boolean = false;

  @Output() clearMessages = new EventEmitter<void>();
  @Output() newDocument = new EventEmitter<void>();
  @Output() exportPdf = new EventEmitter<void>();
  @Output() clearFilters = new EventEmitter<void>();
  @Output() resetColumns = new EventEmitter<void>();
  @Output() navigateToNewDocument = new EventEmitter<DocumentType>();

  @ViewChild('newDocumentModal') newDocumentModalTemplate: TemplateRef<any>;

  documentsActions: DocumentsAction[] = [];
  mobileDocumentsActions: MenuItem[] = [];
  documentTypes: DocumentType[] = [];
  documentTypesMenu: MenuItem[] = [];
  modalRef: NgbModalRef;

  // Dialog visibility control
  displayDocumentTypeDialog: boolean = false;

  constructor(
    private translate: TranslateService,
    private messageService: MessageService,
    private modalService: NgbModal,
    private router: Router
  ) {
    // Initialize document types with translation preparation
    this.documentTypes = DOCUMENT_TYPES.map(docType => {
      // Make sure we keep the original data
      return { ...docType };
    });
  }

  ngOnInit(): void {
    // Initialize actions from static data
    this.documentsActions = [...DOCUMENTS_ACTIONS];
    this.documentTypes = [...DOCUMENT_TYPES];

    // Filter out hidden actions
    this.documentsActions = this.documentsActions.filter(action => action.visible !== false);

    // Create menu items for document types
    this.buildDocumentTypesMenu();

    // Create mobile speed dial actions
    this.buildMobileActions();
    
    // Subscribe to language changes to rebuild menus when language changes
    this.translate.onLangChange.subscribe(() => {
      this.buildDocumentTypesMenu();
      this.buildMobileActions();
    });
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    // Update export button state if isPdfExporting changes
    if (changes.isPdfExporting) {
      // Rebuild the mobile actions with updated state
      this.buildMobileActions();
    }
  }

  private buildDocumentTypesMenu(): void {
    this.documentTypesMenu = this.documentTypes.map(docType => ({
      label: this.translate.instant(docType.label),
      icon: docType.icon,
      routerLink: docType.routerLink,
      command: () => {
        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('allDocuments.actions.newDocument'),
          detail: `${this.translate.instant('allDocuments.actions.creatingNew')} ${this.translate.instant(docType.label)}`,
          sticky: true
        });
      }
    }));
  }

  private buildMobileActions(): void {
    this.mobileDocumentsActions = [
      {
        label: this.translate.instant('allDocuments.actions.newDocument'),
        icon: 'pi pi-file',
        command: () => this.showNewDocumentModal()
      },
      {
        label: this.isPdfExporting 
          ? this.translate.instant('allDocuments.actions.exportingPdf')
          : this.translate.instant('allDocuments.actions.exportPdf'),
        icon: this.isPdfExporting ? 'pi pi-spin pi-spinner' : 'pi pi-download',
        command: () => this.onExportPdf(),
        disabled: this.isPdfExporting
      },
      {
        label: this.translate.instant('allDocuments.actions.clearFilters'),
        icon: 'pi pi-filter-slash',
        command: () => this.onClearFilters()
      },
      {
        label: this.translate.instant('allDocuments.actions.resetColumns'),
        icon: 'pi pi-sort-alt-slash',
        command: () => this.onResetColumns()
      }
    ];
  }

  // Handler methods that emit events up to the parent component
  onClearMessages(): void {
    this.clearMessages.emit();
  }

  onNewDocument(): void {
    this.showNewDocumentModal();
  }

  onExportPdf(): void {
    this.exportPdf.emit();
  }

  onClearFilters(): void {
    this.clearFilters.emit();
  }

  onResetColumns(): void {
    this.resetColumns.emit();
  }

  /**
   * Shows the document type selection dialog
   */
  showNewDocumentModal(): void {
    // Show the dialog with PrimeNG
    this.displayDocumentTypeDialog = true;
  }

  /**
   * Closes the document type selection dialog
   */
  closeDocumentTypeDialog(): void {
    this.displayDocumentTypeDialog = false;
  }
  
  /**
   * Handle document type selection and navigate to the corresponding route
   */
  selectDocumentType(docType: DocumentType): void {
    // Close the dialog
    this.displayDocumentTypeDialog = false;
    
    // Navigate to the specific route for this document type if available
    if (docType && docType.routerLink) {
      this.router.navigate([docType.routerLink]);
    }

    // Show success message
    this.messageService.add({
      severity: 'success',
      summary: this.translate.instant('allDocuments.actions.newDocument'),
      detail: `${this.translate.instant('allDocuments.actions.creatingNew')} ${this.translate.instant(docType.label)}`,
      life: 3000
    });

    // Emit the event for the parent component to handle
    this.navigateToNewDocument.emit(docType);
  }
}
