import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Company, CompanyEvent } from './alldocuments-companies.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompaniesService } from './companies.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alldocuments-companies',
  templateUrl: './alldocuments-companies.component.html',
  styleUrls: ['./alldocuments-companies.component.scss']
})
export class AlldocumentsCompaniesComponent implements OnInit, OnDestroy {
  @Input() selectedCompany: any = null;
  @Input() placeholder: string = 'allDocuments.filters.companies.placeholder';
  @Input() loadingPlaceholder: string = 'allDocuments.filters.companies.loading';
  @Input() currentLang: string = 'en';

  @Output() companyChange = new EventEmitter<CompanyEvent>();
  @Output() companiesLoaded = new EventEmitter<Company[]>(); // Add new event emitter
  
  companies: Company[] = [];
  filteredCompanies: Company[] = [];
  companiesLoading: boolean = true;
  
  private destroy$ = new Subject<void>();

  constructor(
    private companiesService: CompaniesService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    const currentLang = localStorage.getItem('currentLang') || this.currentLang;

    // Fixed: Update to match the actual CompaniesService API
    this.companiesLoading = true;
    this.companiesService.getCompanies()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (companies) => {
          this.companies = companies;
          this.filteredCompanies = [...companies];
          this.companiesLoading = false;
          this.companiesLoaded.emit(companies); // Emit the loaded companies
        },
        error: (error) => {
          console.error('Error loading companies:', error);
          this.companiesLoading = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCompanyChange(event: any): void {
    this.companyChange.emit({
      value: event.value,
      originalEvent: event.originalEvent
    });
  }

  onFilter(event: any): void {
    this.filteredCompanies = this.companiesService.filterCompanies(
      this.companies, 
      event.filter
    );
  }
}
