import { TimePeriodOption } from './alldocuments-period.model';

export const TIME_PERIOD_OPTIONS: TimePeriodOption[] = [
  { id: 'currentMonth', label: 'allDocuments.filters.period.currentMonth' },
  { id: 'lastMonth', label: 'allDocuments.filters.period.lastMonth' },
  { id: 'last2Months', label: 'allDocuments.filters.period.last2Months' },
  { id: 'startOfYear', label: 'allDocuments.filters.period.startOfYear' },
  { id: 'lastYear', label: 'allDocuments.filters.period.lastYear' },
  { id: 'last2Years', label: 'allDocuments.filters.period.last2Years' }
];
