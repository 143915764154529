<app-breadcrumb [title]="curentLang === 'en' ? 'Company Documents Info' : (curentLang === 'ar' ? ' معلومات مستندات وتقارير الشركات   ' : '   מידע על מסמכי החברה ודוחות   ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Company Documents And Reports Info' : (curentLang === 'ar' ? ' معلومات مستندات وتقارير الشركات   ' : ' מידע על מסמכי החברה ודוחות ')">
</app-breadcrumb>

<!--Company Documents Data-->
<div class="container-fluid basic_table my-2">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="row d-flex justify-content-start text-center   mx-2">
        
         
            <div class="row d-flex justify-content-start text-center  ">
              <div class="col-md-7  ">
                <div class="row d-flex justify-content-start text-center">
                  <div class="col-md-4 py-0   mt-1 text-start" style="color: black;">
                    <p class="px-2">
                      {{curentLang === 'en' ? 'Company Name :' : (curentLang === 'ar' ? 'اسم الشركة :' : 'שם החברה :')}}   
                      <span style="color: #E62E2D;">
                        {{curentLang === 'en' ? companyData?.company_name_en : (curentLang === 'ar' ? companyData?.company_name_ar  : companyData?.company_name_he )}}
        
                      </span> 
                      </p>
                      <hr style="color: grey;">
                  </div>
                     
                  <!-- <div class="col-md-4 py-0 text-start mt-1" style="color: black;">
                    <p class="px-2">
                      {{curentLang === 'en' ? 'Company Building Number :' : (curentLang === 'ar' ? 'رقم مبني الشركة :' : '  מספר בניין החברה       :')}}    
                       <span style="color: #E62E2D;">{{ companyData?.company_Building_number}}</span>
                    </p>
                  </div> -->
         
                  <div class="col-md-4 py-0 text-start mt-1" style="color: black;">
                    <p class="px-2">
                      {{curentLang === 'en' ? 'Street :' : (curentLang === 'ar' ? ' الشارع  :' : 'רְחוֹב:')}}   
                      <span style="color: #E62E2D;">
                        {{curentLang === 'en' ? companyData?.company_address_str : (curentLang === 'ar' ? companyData?.company_address_str  : companyData?.company_address_str )}}
                      </span> 
                      </p>
                      <hr style="color: grey;">
                  </div>
        
                  <div class="col-md-4 py-0 text-start mt-1" style="color: black;">
                    <p class="px-2">
                      {{curentLang === 'en' ? ' City :' : (curentLang === 'ar' ? ' المدينة  :' : 'עִיר   :')}}  
                      <span style="color: #E62E2D;">{{curentLang === 'en' ? companyData?.company_address_city : (curentLang === 'ar' ? companyData?.company_address_city  : companyData?.company_address_city )}}
                      </span>  
                      </p>
                      <hr style="color: grey;">
                  </div>
        
                  <!-- <div class="col-md-4 py-0 text-start mt-1" style="color: black;">
                    <p class="px-2">
                      {{curentLang === 'en' ? 'Company Zip Code   :' : (curentLang === 'ar' ? 'الرقم البريدي للشركة   :' : 'מיקוד החברה:')}}    
                       <span style="color: #E62E2D;">
                        {{ companyData?.company_Building_number}}
                       </span>
                    </p>
                  </div> -->
        
                  <!-- <div class="col-md-4 py-0 text-start mt-1" style="color: black;">
                    <p class="px-2">
                      {{curentLang === 'en' ? 'Tax Register Number :' : (curentLang === 'ar' ? 'رقم السجل الضريبي:' : 'מספר רישום מס :')}}    
                      <span style="color: #E62E2D;">{{companyData?.tax_register}}</span>     
                    </p>
                  </div> -->
        
                  <div class="col-md-4 py-0 text-start mt-1" style="color: black;">
                    <p class="px-2">
                      {{curentLang === 'en' ? 'Tax Register Date :' : (curentLang === 'ar' ? '  السجل التجاري :' : 'מרשם מסחרי:')}}    
                           <span style="color: #E62E2D;">{{companyData?.commercial_register}}</span> 
                    </p>
                    <hr style="color: grey;">
                  </div>  
        
                  <div class="col-md-4 py-0 text-start mt-1" style="color: black;">
                    <p class="px-2">
                      {{curentLang === 'en' ? 'Merchant Name :' : (curentLang === 'ar' ? 'أسم التاجر:' : '  שם סוחר:')}}    
                      <span style="color: #E62E2D;">{{curentLang === 'en' ? companyData?.merchant?.merchant_name_en : (curentLang === 'ar' ? companyData?.merchant?.merchant_name_ar  : companyData?.merchant?.merchant_name_he )}}
                      </span>   
                    </p>
                    <hr style="color: grey;">
                  </div>
        
                  
                  <div class="col-md-4 py-0 text-start mt-1 " style="color: black;">
                    <p class="px-2">
                      {{curentLang === 'en' ? 'Merchant Phone Number :' : (curentLang === 'ar' ? 'رقم هاتف التاجر:' : '  מספר טלפון של סוחר  :')}}    
                           <span style="color: #E62E2D;">{{MerchantPhone}}</span> 
                    </p>
                    <hr style="color: grey;">
                  </div>
    
                  <div class="col-md-4 py-0 text-start mt-1" style="color: black;" *ngFor="let item of employeeData.slice(0,2)">
                    <p class="px-2 p" (click)="openXl2(content2)" style="cursor: pointer;">
                      {{ curentLang === 'en' ? 'Employee Name :' : (curentLang === 'ar' ? 'أسم الموظف:' : 'שם הפקיד:') }}    
                      <span style="color: #E62E2D;">
                        {{ item?.user?.user_name }}
                      </span>
                      <span *ngIf="employeeData?.length >= 2">...</span>
                     
                    </p>
                  </div>
                  
                  
    
                
    
    
    
                </div>
           
            
              </div>   
              <div class="col-md-4  ">
                <div class="my-4  ">
                  <button class="btn btn-sm "  routerLink="/dashboard/closed-month/{{companyUUid}}"
                  style="background-color: #E62E2D; color: #fff; margin-right: 25px;">
                    {{curentLang === 'en' ? 'Close month ' : (curentLang === 'ar' ? '  اغلاق شهر ' : 'סגירת חודש')}}
                    <i class="fa fa-close mx-1" style="font-size: 15px;"></i>
                  </button>
                </div>
                </div>
              <div class="col-md-1  ">
         
              </div>
          </div>
          <div class="col-md-5 ">

       

          </div>
        </div>

        <hr style="color: gray;">
       
           
         
          <div class="row d-flex justify-content-center align-items-center ">
            
              <form [formGroup]="reportsForm" class="form-group ">
             
                  <div class="col-md-12  ">
                    <div class="card">

                   
                    <div class="row justify-content-center align-items-center mx-2  ">
             
            
                <div class="col-xl-4 col-md-6 col-sm-12   ">
                  <label class="form-label px-2 " style="color: grey;"> 
                    {{ curentLang === 'en' ? 'Period' : (curentLang === 'ar' ? 'الفترة' : 'פרק זמן')}}
                  </label>
                  <select class="form-control   form-select" style="cursor: pointer;" (change)="filterMonth($event)" (click)="confirmAction()">
                    <option [value]="1">{{ curentLang === 'en' ? 'Current month' : (curentLang === 'ar' ? 'الشهر الحالي' : 'החודש הנוכחי') }}</option>
                    <option [value]="2">{{ curentLang === 'en' ? 'Last month' : (curentLang === 'ar' ? 'الشهر الماضي' : 'חודש שעבר') }}</option>
                    <option [value]="5">{{ curentLang === 'en' ? 'Last two month' : (curentLang === 'ar' ? ' أخر شهران ' : 'החודשיים האחרונים') }}</option>
                    <option [value]="3">{{ curentLang === 'en' ? 'Start of the year' : (curentLang === 'ar' ? 'من بداية العام' : 'מתחילת השנה') }}</option>
                    <option [value]="4">{{ curentLang === 'en' ? 'Last year' : (curentLang === 'ar' ? 'العام الماضي' : 'שנה שעברה') }}</option>
                  </select>
                </div> 
            
                <div class="mb-3 col-xl-4 col-md-6 col-sm-12  " style="margin-top: 32px; ">
                  <div class="row ">
                  
                    <div class="col-md-6">
                      <label class="form-label text-center px-2 " style="color: grey;">
                        {{ curentLang === 'en' ? 'From Date' : (curentLang === 'ar' ? 'من تاريخ' : 'מהתאריך של') }}
                      </label>
                      <div class="p-calendar">
                        <p-calendar [(ngModel)]="fromDate" showIcon="true" (ngModelChange)="onFromDateChange($event)"   formControlName="startDate" dateFormat="dd/mm/yy" placeholder="DD/MM/YYYY"></p-calendar>
                      </div>
                    </div>
                    
                    <div class="col-md-6">
                      <div class="w-100 custom-calendar">
                        <label class="form-label w-100 px-2 " style="color: grey;">
                          {{ curentLang === 'en' ? 'To Date' : (curentLang === 'ar' ? 'الى تاريخ' : 'עד היום') }}
                        </label>
                        <p-calendar [(ngModel)]="toDate" showIcon="true" (ngModelChange)="onToDateChange($event)" formControlName="endDate" dateFormat="dd/mm/yy" placeholder="DD/MM/YYYY"></p-calendar>
                      </div>
                    </div>
                  
                  </div>
                  
                  
                </div>
            
                <div class="mb-3 col-xl-4 col-md-6 col-sm-12   my-3">
               
                </div>

                <div class="mb-3 col-xl-4 col-md-6 col-sm-12   my-3 " *ngIf="active3 != 1 && active3 != 3">
                  <label class="form-label  " style="color: grey;">
                    {{ curentLang === 'en' ? 'Report' : (curentLang === 'ar' ? ' التقرير  ' : '   הדו"ח') }}
                  </label>
                  <select class="form-select" style="color: black; cursor: pointer;" (change)="filter($event)" formControlName="document_type">
                    <option [value]="1">{{ curentLang === 'en' ? 'Income is detailed' : (curentLang === 'ar' ? ' الدخل مفصل ' : 'ההכנסה מפורטת') }}</option>
                    <option [value]="2">{{ curentLang === 'en' ? 'Total income received' : (curentLang === 'ar' ? '   مجموع الدخل والقبض ' : 'סך ההכנסות שהתקבלו  ') }}</option>
                    <option [value]="3">{{ curentLang === 'en' ? 'Document' : (curentLang === 'ar' ? ' مستند ' : 'מסמך') }}</option>
                    <option [value]="4">{{ curentLang === 'en' ? 'Item sales report' : (curentLang === 'ar' ? ' تقرير مبيعات الأصناف ' : 'דוח מכירת פריטים') }}</option>
                    <option [value]="5">{{ curentLang === 'en' ? 'Consolidated Item' : (curentLang === 'ar' ? ' البند الموحد ' : 'פריט מאוחד') }}</option>
                    <option [value]="6">{{ curentLang === 'en' ? 'Receipts' : (curentLang === 'ar' ? '  الأيصالات   ' : 'קבלות ') }}</option>
                    <option [value]="7">{{ curentLang === 'en' ? 'Dashboard' : (curentLang === 'ar' ? '  لوحة التحكم   ' : ' לוח הבקרה ') }}</option>
                  </select>
                </div>
              </div>
                </div>
              </div>
              
                <div class="modal-footer">
                  <button type="button" [disabled]="!reportsForm.valid" class="btn btn-sm m-auto my-2" style="background-color: #141414; color: #fff;" (click)="confirmAction()">
                    {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? ' تاكيد ' : ' אישור ') }}
                    <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                  </button>
                  
                  <button type="button" class="btn btn-sm m-auto my-2  " style="background-color: #E62E2D; color: #fff;" routerLink="/dashboard/office-companies">
                    {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? ' رجوع ' : ' חזרה ') }}
                    <i class="fa fa-rotate-left mx-1"></i>
                  </button>
                </div>

            </form>
             
            </div>

        <div class="card-body">
          <ul ngbNav #nav="ngbNav" [(activeId)]="active3" (navChange)="onNavChange1($event)" class="nav-tabs">
         
  <!---->
  <li [ngbNavItem]="1"  >
    <a ngbNavLink>
      {{curentLang === 'en' ? 'Summary ' : (curentLang === 'ar' ? 'الملخص ' : 'סיכום')}}    
    </a>
    <ng-template ngbNavContent>
      <div class="container-fluid basic_table my-2">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="modal-body">
                <div class="container-fluid">
                  <div class="edit-profile">
                    <div class="row d-flex justify-content-between">
                      <div *ngIf="dashboardReportsData?.length == 0" class="alert text-danger mt-3">
                        <span>{{ curentLang === 'en' ? 'There are no summary yet...' : (curentLang === 'ar' ? ' لا توجد ملخصات حتي الأن ... ' : 'איןz') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-body">
                <div class="container-fluid">
                  <div class="edit-profile">
                    <div class="row d-flex justify-content-end">
                      <div class="col-sm-12" [hidden]="dashboardReportsData?.length == 0">
                        <div class="card">
                          <div class="row  ">
                            <div class="card-header col-sm-4  "></div>
                            <div class="col-md-4"></div>
                            <div class="card-header col-md-4 ">
                              <div class="d-flex justify-content-end">
                                <button (click)="getSummaryReportpdf()" class="btn mt-3 mx-1" style="background-color: #141414; color: #fff;">
                                  <i class="fa fa-file-pdf-o mx-1"></i>
                                  {{ curentLang === 'en' ? ' Print ' : (curentLang === 'ar' ? 'طباعة نسخة  ' : 'הפקת העתק') }}
                                </button>
                                <button (click)="exportToExcelSummary()" class="btn mt-3 mx-1" style="background-color: #141414; color: #fff;">
                                  <i class="fa fa-download mx-1"></i>
                                  {{ curentLang === 'en' ? 'Download Exel' : (curentLang === 'ar' ? ' تحميل اكسيل ' : ' הורד את אקסל') }}
                                </button>
                              </div>
                            </div>

                            <div class="card-block row">
                              <div class="col-sm-12 col-lg-12 col-xl-12">
                                <div class="table-responsive page  ">
                                  <table class="table   text-center table-hover my-2 table-summary table-bordered border-black "
                                     [innerHTML]="summaryReportHtml">
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
         
            <li [ngbNavItem]="2">
              <a ngbNavLink>
                {{curentLang === 'en' ? 'Export Reports ' : (curentLang === 'ar' ? ' اصدار التقارير ' : 'הוצאת דוחות')}}  
              </a>
              <ng-template ngbNavContent>
                <div class="container-fluid basic_table my-2">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="card">
                        <div class="modal-body">
                          <div class="container-fluid">
                            <div class="edit-profile">
                              <div class="row d-flex justify-content-between">
                                <div *ngIf="rowData?.length == 0" class="alert text-danger mt-3">
                                  <span>{{ curentLang === 'en' ? 'There are no reports yet...' : (curentLang === 'ar' ? ' لا توجد تقارير حتي الأن ... ' : 'אין עדיין דיווחים') }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="modal-body">
                          <div class="container-fluid">
                            <div class="edit-profile">
                              <div class="row d-flex justify-content-end">
                                <div class="col-sm-12" [hidden]="rowData?.length == 0">
                                  <div class="card">
                                    <div class="row  ">
                                      <div class="card-header col-sm-4  "></div>
                                      <div class="col-md-4"></div>
                                      <div class="card-header col-md-4 ">
                                        <div class="d-flex justify-content-end">
                                          <button (click)="exportToPdf()" class="btn mt-3 mx-1" style="background-color: #141414; color: #fff;">
                                            <i class="fa fa-file-pdf-o mx-1"></i>
                                            {{ curentLang === 'en' ? ' Print ' : (curentLang === 'ar' ? 'طباعة نسخة  ' : 'הפקת העתק') }}
                                          </button>
                                          <button (click)="exportToExcel()" class="btn mt-3 mx-1" style="background-color: #141414; color: #fff;">
                                            <i class="fa fa-download mx-1"></i>
                                            {{ curentLang === 'en' ? 'Download Exel' : (curentLang === 'ar' ? ' تحميل اكسيل ' : ' הורד את אקסל') }}
                                          </button>
                                        </div>
                                      </div>

                                      <div class=" row">
                                        <div class="col-sm-12 col-lg-12 col-xl-12">
                                        

                                       


                                    

                                          <div class="table-responsive page">
                                            <ag-grid-angular
                                              style="width: 100%; height: 600px;"
                                              class="ag-theme-alpine text-start ag-header-cell-comp-wrapper"
                                              [columnDefs]="columnDefs"
                                              [rowData]="rowData"
                                              [gridOptions]="gridOptions"
                                              [suppressRowClickSelection]="true"
                                              [suppressCopyRowsToClipboard]="true"
                                              [enableBrowserTooltips]="true"
                                              [enableRangeSelection]="true"
                                              [rowMultiSelectWithClick]="true"
                                              [suppressDragLeaveHidesColumns]="true"
                                              [suppressMakeColumnVisibleAfterUnGroup]="true"
                                              [suppressAggFuncInHeader]="true"
                                              [suppressColumnVirtualisation]="true"
                                              [enableCellTextSelection]="true"
                                              [suppressContextMenu]="true"
                                              [suppressExcelExport]="true"
                                              [defaultColDef]="{
                                                sortable: true,
                                                filter: true,
                                                flex: 1,
                                                resizable: true,
                                                minWidth: 100,
                                                headerClass: 'ag-header-cell-comp-wrapper header-align-right',
                                                cellStyle: { 'background-color': '#ffffff', 'text-align': 'right' },
                                                suppressMenu: false,
                                                cellRenderer: 'currencyCellRenderer'
                                              }"
                                            ></ag-grid-angular>
                                          </div>

                                  
                                          
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
    <!---->
 
  
      <!---->      
    <li [ngbNavItem]="3">
      <a ngbNavLink>
        {{curentLang === 'en' ? 'Company Documents  ' : (curentLang === 'ar' ? 'مستندات الشركة  ' : 'מסמכי החברה    ')}}    
      </a>
      <ng-template ngbNavContent>
        <div class="card-block row">
          <div class="row d-flex justify-content-between">
            <div class="col-md-3">
              <div class="form-group search">
                <label for="search" style="color: grey;">
                  {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
                </label>
                <div class="input-group">
                  <input class="form-control form-control-search my-1" type="text" name="search"
                         [ngModelOptions]="{standalone: true}" id="search" [(ngModel)]="term" style="color: black;"
                         placeholder="{{curentLang === 'en' ? 'Search by customer name or document number ' : (curentLang === 'ar' ? 'بحث بأسم العميل او رقم المستند' : 'חפש לפי שם הלקוח או מספר המסמך')}}"
                  >
                </div>
              </div>
            </div>
  
            <div class="col-md-3 my-4">
              <div class="form-group search">
                <button (click)="downloadAllDocumentsZipFile()" class="btn m-2" style="background-color: #141414; color: #fff;">
                  <i class="fa fa-download mx-1"></i>
                  {{ curentLang === 'en' ? 'Download All Invoices ZIP' : (curentLang === 'ar' ? 'تحميل كل الفواتير ZIP' : 'הורד את כל החשבוניות ב-ZIP') }}
                </button>
              </div>
            </div>
  
            <div class="col-sm-12 col-lg-12 col-xl-12">
              <div class="table-responsive">
                <table class="table table-light text-start table-hover" style="background-color: #F1F1F1;">
                  <thead>
                    <tr>
                      <th scope="col">{{ curentLang === 'en' ? 'Document Number' : (curentLang === 'ar' ? '  رقم المستند' : '  מספר מסמך') }}</th>
                      <th scope="col">{{ curentLang === 'en' ? 'Invoice Type' : (curentLang === 'ar' ? '  نوع المستند  ' : ' סוג מסמך ')}}</th>
                      <th scope="col">{{ curentLang === 'en' ? ' Customer Name ' : (curentLang === 'ar' ? ' اسم العميل' : 'שם לקוח')}}</th>   
                      <th scope="col">{{ curentLang === 'en' ? ' Customer Vat Number ' : (curentLang === 'ar' ? ' رقم مشغل العميل' : 'מספר מע"מ של לקוח  ')}}</th>
                      <th scope="col">{{ curentLang === 'en' ? 'Total Amount ' : (curentLang === 'ar' ? 'المبلغ الاجمالي' : '  סכום כולל')}}</th>
                      <th scope="col">{{ curentLang === 'en' ? 'Invoice Date' : (curentLang === 'ar' ? '  تاريخ الفاتورة' : 'תאריך חשבונית ')}}</th>
                      <th scope="col" >{{ curentLang === 'en' ? 'View Pdf ' : (curentLang === 'ar' ? 'عرض PDF' : 'הצעה pdf')}}</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr 
                    *ngFor="let item of companyDocumentsData | paginate: { itemsPerPage: limit, currentPage: currentPage, totalItems: total } | filter: term; let i = index"
                    [ngClass]="{'table-danger': selectedInvoiceUuid === item.invoice_uuid }"
                    (click)="getInvoiceId(item.invoice_uuid);executeFunctions(item.company_id , content4)"
                    style="cursor: pointer"
                  >
                      <td scope="row">{{item?.document_number}}</td>
                      <td>{{ getInvoiceTypeDescription(item?.invoice_type) }}</td>
                      <td class="customer-name">{{item?.customer_name}}</td>
                      <td>{{item?.customer_vat_number}}</td>
                      <td>
                        <span *ngIf="item.invoice_type == 400">{{item?.total_amount | number:'1.2-2'}}</span>
                        <span *ngIf="item.invoice_type != 400">{{ item?.payment_amount_including_vat | number:'1.2-2'}}</span>
                      </td>
                      <td>{{ item?.invoice_date | date: 'dd/MM/yyyy' }}</td>

                      
                    <!-- <td (click)="getInvoiceId(item.invoice_uuid);openInvoiceXl(content4)">
                        <i class="fa fa-file-pdf-o" (click)="getCompanyId(item.company_id)"
                          [ngStyle]="{ 'pointer-events': item.confirm == 0 ? 'none' : 'auto', 'opacity': item.confirm == 0 ? 0.6 : 1 }"
                          style="color: grey; font-size: 15px; cursor: pointer; color: #213FA6;"
                             >
                        </i>
                      </td>
                            --> 
                      <td >
                         
                        <i class="fa fa-file-pdf-o"    style="cursor: pointer;"
                          [ngStyle]="{ 'pointer-events': item.confirm == 0 ? 'none' : 'auto', 'opacity': item.confirm == 0 ? 0.6 : 1 }"
                          style="color: grey; font-size: 15px; cursor: pointer; color: #213FA6;"
                             >
                        </i>
                       
                      </td>


                    </tr>
                  </tbody>
                </table>
                <div class="pagination-container">
                  <pagination-controls (pageChange)="onPageChange($event)" (totalItems)="total" (currentPage)="currentPage">
                  </pagination-controls>
                  <input type="number" class="mt-1" [(ngModel)]="customLimit" (change)="onLimitChange()">
                </div>
              </div>

              <div class="d-flex justify-content-between">
                <div></div>
                <div>
                  <a routerLink="/dashboard/office-companies">
                    <button class="btn btn-danger m-2">
                      {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה ')}}
                      <i class="fa fa-rotate-left mx-1"></i>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <!---->

        <!---->      
        
            
        
        
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content2 let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title"style="color: grey;">
      {{curentLang === 'en' ? 'Names Of Responsible Employees' : (curentLang === 'ar' ? ' أسماء الموظفيين المسؤليين ' : 'שמות עובדים אחראיים')}} 
    </h6>
    <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="edit-profile">
        <div class="row d-flex justify-content-start align-items-start text-start">
          <div class="col-md-12 py-0 text-start mt-1" style="color: black;">
            <p>
              {{curentLang === 'en' ? 'Employee Name :' : (curentLang === 'ar' ? 'أسم الموظف:' : 'שם הפקיד:')}}
            </p>
            <hr style="color: grey;">
            <span style="color: #E62E2D; margin: 0; padding: 0;" *ngFor="let item of employeeData">
              {{curentLang === 'en' ? item?.user?.user_name : (curentLang === 'ar' ? item?.user?.user_name : item?.user?.user_name)}}
              <hr style="color: grey;">
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>






<ng-template #content4 let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title"style="color: grey;">
      {{curentLangDocument === 'en' ? 'Invoice Details' : (curentLangDocument === 'ar' ? 'تفاصيل الفاتورة ' : '  פרטי חשבונית   ')}} 
    </h6>
    <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <button type="button" (click)="downloadDocumentPdf();modal.close('Close click')" class="btn btn-sm my-3"
    style=" background-color: #141414; color: #fff;">
    <i class="fa fa-file-pdf-o mx-1"></i>
    {{ curentLang === 'en' ? ' Print ' : (curentLang === 'ar' ? 'طباعة نسخة  ' : 'הפקת העתק') }}
  </button>
<div class="container" [hidden]="invoiceInfoData?.confirm == 0" >
    <div class="row m-auto">
        <div class="col-sm-12 ">

            <div class="page m-auto  invoice-content"  id="printDiv">

                <div class="card-2" style="background-color: #fff; box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);">

                    <div class="row m-2 ">
                        <div class="col-lg-12">
                            <div class="invoice-inner" id="invoice_wrapper">
                                <div class="invoice-top">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <div class="logo my-2"  *ngIf="invoiceInfoData?.logo" >
                                                <img 
                                                    [src]="invoiceInfoData?.logo"
                                                     title="company logo"
                                                    style="width: 100px;height: 100px; border-radius: 5%;">
                                            </div>
                                        </div>

                                        <div [class.py-5]="invoiceInfoData?.logo" [class.py-2]="!invoiceInfoData?.logo" class="col-sm-4" *ngIf="user_type == 300 || user_type == 301">
                                            <h2 class="text-center water-mark">
                                                {{ curentLangDocument === 'en' ? ' Accounting Copy' : (curentLangDocument === 'ar' ? 'نسخة المحاسب' : ' עותק רואה חשבון')}}
                                            </h2>
                                        </div>

                                        <div [class.py-5]="invoiceInfoData?.logo" [class.py-2]="!invoiceInfoData?.logo" class="col-sm-4" *ngIf="user_type == 200 || user_type == 201">
                                            <h2 class="text-center water-mark">{{invoiceInfoData?.copy_version}}</h2>
                                        </div>
                                        
                                        <div class="col-sm-4">
                                     
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice-info py-2">
                                    <div class="row">
                                       
                                        <div class="col-sm-6 mb-30">
                                            <div class="invoice-number ">
                                                <h4 class="inv-title-1" style="font-weight: bold; font-size: 14px;">{{ curentLangDocument === 'en' ? 'For ' : (curentLangDocument === 'ar' ? '   لحضرة' : 'לכבוד  ')}}</h4>
                                                <div class="invo-addr-1">
                                                    <div style="font-weight: 400;">
                                                        <!-- <strong> {{ curentLangDocument === 'en' ? 'Customer Name' : (curentLangDocument === 'ar' ? 'اسم العميل' : 'שם הלקוח')}} / </strong> -->
                                                        <strong>{{ invoiceInfoData?.customer_name }}</strong>
                                                    </div>
                                                    
                                                    <div *ngIf="invoiceInfoData?.customer_address; else noAddress" style="font-weight: 300;" class="m-0">
                                                        <strong>{{ invoiceInfoData?.customer_address }}</strong>
                                                    </div>
                                                    
                                                    <ng-template #noAddress>
                                                        <div style="font-weight: 300;">
                                                            <strong>{{ curentLangDocument === 'en' ? 'Customer Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : 'מס עוסק מורשה    ')}} </strong>
                                                            <div style="font-weight: 300;">
                                                                <strong>{{ invoiceInfoData?.customer_vat_number }}</strong>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    
                                                    <div *ngIf="invoiceInfoData?.customer_address" style="font-weight: 300;">
                                                        <strong>{{ curentLangDocument === 'en' ? 'Customer Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : 'מס עוסק מורשה    ')}} </strong>
                                                        <div style="font-weight: 300;">
                                                            <strong>{{ invoiceInfoData?.customer_vat_number }}</strong>
                                                        </div>
                                                    </div>
                                                    

                                                    
                                                    <div style="font-weight: 300;">
                                                        <!-- <strong>{{ curentLangDocument === 'en' ? 'Customer Id Number' : (curentLangDocument === 'ar' ? '  رقم الهوية للعميل' : '  מספר זיהוי לקוח ')}} / </strong> -->
                                                        <strong>{{ invoiceInfoData?.customer_id_number }}</strong>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div class="col-sm-6 mb-30">
                                            <div class="invoice-number text-end">
                                                <!-- <h4 class="inv-title-1"> {{ curentLangDocument === 'en' ? 'Invoice From' : (curentLangDocument === 'ar' ? 'فاتورة من' : 'חשבונית מ')}} </h4> -->
                                                <div class="invo-addr-1">
                                                    <div style="font-weight: bold;">
                                                        <!-- <strong>{{ curentLangDocument === 'en' ? 'Company Name' : (curentLangDocument === 'ar' ? 'اسم الشركة' : 'שם החברה')}} / </strong>  -->
                                                        <strong>{{ invoiceInfoData?.company_name }}</strong>
                                                    </div>
                                                    
                                                    <div style="font-weight: 300;">
                                                        <!-- <strong> {{ curentLangDocument === 'en' ? 'Company Address' : (curentLangDocument === 'ar' ? 'عنوان الشركة' : 'כתובת החברה')}} / </strong> -->
                                                        <strong>{{ invoiceInfoData?.company_address_str }} -</strong>
                                                        <strong>{{ invoiceInfoData?.company_Building_number }} -</strong>

                                                        
                                                        <strong>{{ invoiceInfoData?.company_address_city }}</strong>
                                                    </div>
                                                    
                                                  
                                                    <!-- <div style="font-weight: 400;">{{ invoiceInfoData?.company_address_zip_code }}</div> -->
                                                    <!-- <div style="font-weight: 300;">
                                                        <strong>{{ curentLangDocument === 'en' ? 'Company Vat Number' : (curentLangDocument === 'ar' ? '  الرقم الضريبي للشركة' : 'מספר מס חברה')}} / </strong>
                                                        <strong>{{ invoiceInfoData?.company_vat }}</strong>
                                                    
                                                    </div> -->

                                                    <div  style="font-weight: 300;"> 
                                                        <strong>{{ curentLangDocument === 'en' ? 'Company Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : 'מס עוסק מורשה    ')}}  </strong> 
                                                   </div>
                                                   <div  style="font-weight: 300;"> 
                                                   <strong>{{ invoiceInfoData?.company_vat }}</strong>
                                                   </div>
                                                  

                                                  
                                                    
                                                </div>
                                            </div>
                                            
                                            
                                            
                                        </div>
                                    </div>
                                    <div class="row  ">
                                        <div class="col-sm-4"></div>

                                        

                                        <div class="invoice text-center   col-sm-4">
                                            <!-- <h4 class="inv-title-1">{{ curentLangDocument === 'en' ? 'Invoice Details' : (curentLangDocument === 'ar' ? 'تفاصيل الفاتورة' : ' פרטי החשבונית  ')}}</h4> -->

                                       

                                            <div class="mb-1"*ngIf="invoiceInfoData?.document_number !== null">
                                           <strong *ngIf="invoiceInfoData?.invoice_type == 305" style="font-weight: bold;" class="mx-1">
                                            {{curentLangDocument === 'en' ? 'Tax Invoice Number' : (curentLangDocument === 'ar' ? 'فاتورة ضريبية رقم' : '  חשבונית מס מספר       ') }}
                                          </strong> 

                                          <strong *ngIf="invoiceInfoData?.invoice_type == 320" style="font-weight: bold;" class="mx-1">
                                            {{curentLangDocument === 'en' ? 'Invoice With Receipt Number' : (curentLangDocument === 'ar' ? 'فاتورة مع  ايصال رقم' : 'חשבונית מס קבלה') }}
                                         </strong> 

                                         <strong *ngIf="invoiceInfoData?.invoice_type == 332" style="font-weight: bold;" class="mx-1">
                                            {{curentLangDocument === 'en' ? ' Financial Request Number' : (curentLangDocument === 'ar' ? 'مطالبة مالية رقم' : ' תביעה כספית מס  ') }}
                                         </strong> 

                                         <strong *ngIf="invoiceInfoData?.invoice_type == 400" style="font-weight: bold;" class="mx-1">
                                            {{curentLangDocument === 'en' ? 'Receipt Number' : (curentLangDocument === 'ar' ? ' ايصال رقم' : ' מס קבלה  ') }}
                                         </strong> 

                                         <strong *ngIf="invoiceInfoData?.invoice_type == 330" style="font-weight: bold;" class="mx-1">
                                            {{curentLangDocument === 'en' ? 'Reverce Invoice Number' : (curentLangDocument === 'ar' ? '  فاتورة عكسية رقم  ' : '      חשבונית זיכוי קבלה ') }}
                                         </strong> 

                                         <strong *ngIf="invoiceInfoData?.invoice_type == 100" style="font-weight: bold;" class="mx-1">
                                          {{curentLang === 'en' ? '    order' : (curentLang === 'ar' ? 'طلبية    ' : '   לְהַזמִין   ') }}
                                       </strong> 

                                       <strong *ngIf="invoiceInfoData?.invoice_type == 200" style="font-weight: bold;" class="mx-1">
                                          {{curentLang === 'en' ? '    mission' : (curentLang === 'ar' ? 'ارسالية    ' : '   תעודת משלוח   ') }}
                                       </strong> 

                                       <strong *ngIf="invoiceInfoData?.invoice_type == 000" style="font-weight: bold;" class="mx-1">
                                          {{curentLang === 'en' ? '    Offer Price' : (curentLang === 'ar' ? 'عرض سعر     ' : ' מחיר הצעה') }}
                                       </strong> 

                                         <strong style="font-weight: 400; color: black;">{{invoiceInfoData?.document_number}}</strong>


                                            
                                        </div>
                                        <div [hidden]="invoiceInfoData?.confirmation_number == null"  style="font-weight: 300;color: black;">
                                            <strong style="font-weight: bold;" class="mx-1">{{ curentLangDocument === 'en' ? 'Confirmation Number' : (curentLangDocument === 'ar' ? ' رقم تخصيص' : ' מספר הקצאה  ')}} : </strong> 
                                           <strong>{{ invoiceInfoData?.confirmation_number }}  </strong>
                                       </div>
                                           
                                           
                                        </div>

                                        <div class="col-sm-4    text-end">
                                            <!-- <h4 class="inv-title-1">{{ curentLangDocument === 'en' ? 'Date' : (curentLangDocument === 'ar' ? ' التاريخ  ' : ' תאריך  ')}}</h4> -->
                                           
                                                 <div class="mb-0" style="font-weight: bold;">
                                                    {{ curentLangDocument === 'en' ? ' Date' : (curentLangDocument === 'ar' ? 'تاريخ ' : 'תאריך ')}}
                                                </div> 
                                                <div style="font-weight: 400; color: black;">{{ invoiceInfoData?.invoice_date| date:'dd/MM/yyyy' }}</div>
                                        </div>


                                    </div>
                                </div>
                                <div class="order-summary" *ngIf="invoiceInfoData?.invoice_type !== 400" >
                                    <div class="table-responsive">
                                        <table class="table invoice-table">
                                            <thead class="bg-active" style="font-weight: bold;   ">
                                                <tr>
                                                    <th
                                                        class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Item Name' : (curentLangDocument === 'ar' ? 'اسم الصنف' : 'פריט  ')}}</th>
                                                     <th
                                                        class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Description' : (curentLangDocument === 'ar' ? 'الوصف' : 'תיאור')}}</th> 
                                                    <!-- <th
                                                        class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Measure Unit' : (curentLangDocument === 'ar' ? 'وحدة القياس' : 'יחידת מידה')}}</th> -->
                                                    <th
                                                        class="text-center" style="font-weight: bold;"> {{ curentLangDocument === 'en' ? 'Price' : (curentLangDocument === 'ar' ? 'السعر  ' : ' מחיר  ')}}</th>
                                                    <th
                                                        class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Quantity' : (curentLangDocument === 'ar' ? 'الكمية' : 'כמות')}}</th>
                                                    <th
                                                        class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Discount' : (curentLangDocument === 'ar' ? 'الخصم' : 'הנחה')}}</th>
                                                    <th class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع' : 'סה"כ')}}</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor=" let item of invoiceInfoData?.lines">
                                                    <td class="text-center" style="font-weight: 400; color: black;" >{{item?.item_name}}</td>  
                                                    
                                                    <td  class="text-center  description-column"style="font-weight: 400; color: black;">{{item?.description}}</td>

                                                  <!--   <td class="text-center  description-column" style="font-weight: 400; color: black;">{{item?.measure_unit_description}}</td> -->

                                                    <td class="text-center" style="font-weight: 400; color: black;" >{{item?.price_per_unit | number:'1.2-2'}}</td>

                                                    <td class="text-center" style="font-weight: 400; color: black;" >{{item?.quantity}}</td>

                                                    <td class="text-center" style="font-weight: 400; color: black;" >{{item?.discount}}</td>

                                                    <td class="text-center" style="font-weight: 400; color: black;" >{{item?.sub_total | number:'1.2-2'}}</td>

                                                </tr>

                                            </tbody>
                                        </table>

                                      

                                    </div>
                                    <div class="small-table my-4">
                                        <div class="row">
                                            <div class="col-md-12  ">
                                                <div class="d-flex justify-content-between" style="background-color: #F1F1F1;">
                                                    <div class="col-md-6  p-2 ">
                                                        <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Amount Before Discount:' : (curentLangDocument === 'ar' ? 'المبلغ قبل الخصم' : '   מחיר לפני הנחה    ')}}</div>
                                                        <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Discount:' : (curentLangDocument === 'ar' ? 'الخصم' : 'הנחה')}}</div>
                                                        <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Payment Amount:' : (curentLangDocument === 'ar' ? '   المبلغ المدفوع    ' : '      סה"כ      ')}}</div>
                                                        <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'VAT Amount:' : (curentLangDocument === 'ar' ? '       المبلغ الضريبي    ' : 'מע"מ')}}</div>
                                                        <div style="width: 300px; font-weight: bold;" class="m-1"> {{ curentLangDocument === 'en' ? 'Payment Amount Including VAT:' : (curentLangDocument === 'ar' ? ' المبلغ المدفوع شامل الضريبة ' : 'סה"כ לשתלום')}}</div>

                                                    </div>

                                                 

                                                    <div class="col-md-6 text-end px-3 p-2">
                                                        <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.amount_before_discount | number:'1.2-2'}}</div>
                                                        <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.all_discount | number:'1.2-2'}}</div>
                                                        <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.payment_amount | number:'1.2-2'}}</div>
                                                        <div style="font-weight: 400; color: black;" class="my-1">({{invoiceInfoData?.vat_percentage}} %)   {{invoiceInfoData?.all_vat_amount | number:'1.2-2'}}</div>
                                                        <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.payment_amount_including_vat | number:'1.2-2'}}</div>

                                                    </div>
                                                   
                                                 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    

                                </div>


                                  <div class="order-summary  " *ngIf="invoiceInfoData?.invoice_type == 320 || invoiceInfoData?.invoice_type == 400">

                                    <div class="col-lg-12 " >
                                        <ng-container  >
                                            <div class="mt-4 d-flex justify-content-between">
                                                <span style="font-weight: bold; " class="mx-2">{{ curentLangDocument === 'en' ? 'Payment Info' : (curentLangDocument === 'ar' ? 'بيانات الدفع  ' : ' נתוני תשלום')}} </span>
                                                <!-- <span style="font-weight: 500;" class="mx-3">{{ receiptInfoData.checks_amount | number:'1.0-0' }}</span> -->
                                            </div>  
                                    
                                            <div class="table-responsive ">
                                                <table class="table invoice-table">
                                                    <thead>
                                                    
                                                        <tr>
                                                            <th
                                                                class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Payment Method ' : (curentLangDocument === 'ar' ? 'طرق الدفع  ' : 'דרכי תשלום')}}
                                                            </th>
                                                            <!-- <th
                                                                class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Ref' : (curentLangDocument === 'ar' ? 'مرجع  ' : 'רפ')}}</th> -->
                                                            <!-- <th
                                                                class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Bank' : (curentLangDocument === 'ar' ? 'بنك  ' : 'בַּנק')}}
                                                            </th> -->
                                                            <!-- <th
                                                                class="text-center" style="font-weight: bold;" > {{ curentLangDocument === 'en' ? 'Branch' : (curentLangDocument === 'ar' ? 'فرع  ' : 'סניף')}}
                                                            </th>
                                                            
                                                            <th
                                                                class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Account' : (curentLangDocument === 'ar' ? 'حساب  ' : 'חֶשְׁבּוֹן')}}
                                                            </th> -->
                    
                                                            <th
                                                                class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Details' : (curentLangDocument === 'ar' ? 'تفاصيل  ' : 'פרטים')}}</th>
                                                                <th
                                                                class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Date' : (curentLangDocument === 'ar' ? ' تاريخ  ' : ' תאריך')}}</th>
                                                            <th class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع  ' : 'סכום')}}</th>
                    
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor=" let item of invoiceInfoData?.receiptsDetails">
                                                            <td class="text-center" style="font-weight: 400; color: black;">
                                                                <span *ngIf=" item?.payment_type == 1"> {{ curentLangDocument === 'en' ? 'Cash' : (curentLangDocument === 'ar' ? 'كاش' : 'מזומן')}}</span>
                                                                <span *ngIf=" item?.payment_type == 2"> {{ curentLangDocument === 'en' ? 'Check' : (curentLangDocument === 'ar' ? 'شيك' : 'שיק')}}</span>
                                                                <span *ngIf=" item?.payment_type == 3"> {{ curentLangDocument === 'en' ? 'Bank' : (curentLangDocument === 'ar' ? 'بنك' : ' העבר  בנקאית')}}</span>
                                                                <span *ngIf=" item?.payment_type == 4"> {{ curentLangDocument === 'en' ? 'Credit' : (curentLangDocument === 'ar' ? 'ائتمان' : ' כרטיס אשראי')}}</span>
                                                            </td>
                                                            <!-- <td class="text-center">{{ item.bank }}</td>
                                                            <td class="text-center">{{ item.branch }}</td>
                                                            <td class="text-center">{{ item.account }}</td>
                                                             -->
                    
                                                            <td class="text-center description-column" style="font-weight: 400; color: black;">{{ item.details }}</td>
                                                            <td class="text-center" style="font-weight: 400; color: black;">{{ item.date | date: 'yyyy-MM-dd' }}</td>
                                                            <td class="text-center" style="font-weight: 400; color: black;">{{ item.amount | number:'1.2-2'}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </ng-container>
                                    </div>


              

                <div class="small-table my-4" >
                    <div class="row">
                        <div class="col-md-12 d-flex justify-content-between" >
                            <div class="col-md-6 mt-5">
                              <!-- <span>Weighted date: 05/05/24</span> -->
                             </div>
                             <div class="col-md-6">
                                <div class="row d-flex justify-content-between mx-1" style="background-color: #F1F1F1;">
                                    <div class="col-md-6 py-1" style="font-weight: bold; color: black;">
                                        <span class="py-1"> {{ curentLangDocument === 'en' ? 'Deduction at source' : (curentLangDocument === 'ar' ? 'الخصم من المصدر  ' : '  ניכוי במקור')}} :  </span>
                                    </div>

                                    <div class="col-md-6 text-end py-1" style="font-weight: 400; color: black;">
                                        <span class="mx-1">{{invoiceInfoData.deduction_from_source | number:'1.0-0'}}</span>
                                    </div>

                                    <div class="col-md-6 py-1" style="font-weight: bold; color: black;">
                                        <span>{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع' : 'סה"כ')}}  :  </span>
                                    </div>

                                    <div class="col-md-6 text-end py-1" style="font-weight: 400; color: black;">
                                        <span class="mx-1">{{ invoiceInfoData.total_amount | number:'1.2-2' }}</span>

                                    </div>


                                </div>
                                
                             </div>

                             <div class="col-md-6">

                             </div>
                        </div>
                    </div>
                </div>
                
                
                
                <!-- <div class=" d-flex justify-content-between"> 

                    <button type="button"  hidden
                    class="btn   m-2" 
                   style="background-color: #141414; color: #fff;"
                    
                    > 
                     {{ curentLangDocument === 'en' ? 'Update' : (curentLangDocument === 'ar' ? '     تحديث ' : '     עדכן  ') }}
                     <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                 </button>
            
                   

                        <img src="../../../../assets/images/logo/thi.png"  class="  m-2 "  alt="">
                  
                      
                    
            
                  </div> -->
                
            </div>
                                    
                                    
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>


            



        </div>

        

    </div>




    <!--back-->
    <div class=" d-flex justify-content-between"> 

        <button type="button"  hidden
        class="btn   m-2" 
       style="background-color: #141414; color: #fff;"
        
        > 
         {{ curentLangDocument === 'en' ? 'Update' : (curentLangDocument === 'ar' ? '     تحديث ' : '     עדכן  ') }}
         <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
     </button>

    <!--    <a routerLink="/dashboard/company-documents/{{invoiceInfoData?.company_uuid}}" *ngIf="user_type == 300 || user_type == 301">
          <button class="btn btn-danger m-2 " >
            {{ curentLangDocument === 'en' ? 'Back' : (curentLangDocument === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
          </button>
        </a>
-->
        <a routerLink="/dashboard/documents/{{invoiceInfoData?.company_uuid}}" *ngIf="user_type == 200 || user_type == 201">
            <button class="btn btn-danger m-2 " >
              {{ curentLangDocument === 'en' ? 'Back' : (curentLangDocument === 'ar' ? '  رجوع ' : '  חזרה  ') }}
              <i class="fa fa-rotate-left mx-1"></i>
            </button>
          </a>

          <button type="button" class="btn btn-sm mt-2 btn-danger" *ngIf="user_type == 300 || user_type == 301"
          (click)="modal.close('Close click')">
      {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
      <i class="fa fa-rotate-left mx-1"></i>
  </button>

      </div>
    </div>
  </div>
</ng-template>





 <!--Delete-->

              
 <ng-template #dataDeleted let-modal>
  <div class="modal-header">
      <h4 class="modal-title"></h4>
      <button type="button" class="close btn-close"  style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
        {{curentLang === 'en' ? 'Are you sure you want to delete it?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد حذفه؟ ' : ' האם אתה בטוח שברצונך למחוק אותו?      ') }}

      </p>
  </div>
  <div class="modal-footer">

    <button type="button" 
     class="btn btn-sm m-auto mt-2" 
    style="background-color: #141414; color: #fff;"
     (click)="deleteClosedMonth()"
     > 
     {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
      <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
  </button>

      <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
        {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
          <i class="fa fa-rotate-left mx-1"></i>
      </button>

  </div>
</ng-template>



  <!--cancle-->

  <ng-template #dataReject let-modal>
    <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body text-center">
      <p class="text-center text-danger"> <i class="fa fa-check-circle  text-danger"></i>
        {{curentLang === 'en' ? 'Are you sure you want to cancel the warning on this bill?' : (curentLang === 'ar' ? 'هل تريد بالتأكيد الغاء التحذير علي هذة الفاتورة ؟' : 'האם אתה בטוח שאתה רוצה לבטל את האזהרה על החשבון הזה?') }}
      </p>
    </div>
  
    <div class="modal-footer">

      <button type="button" 
       class="btn btn-sm m-auto mt-2" 
      style="background-color: #141414; color: #fff;"
       (click)="cancleAert()"
       > 
       {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تأكيد' : 'כדי להיות בטוח')}}
        <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
    </button>

        <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
        </button>

      

    </div>


  </ng-template>