<div class="dropdown-wrapper">
  <i [class]="selectedType ? selectedType.icon : 'pi pi-file'"></i>
  <p-dropdown 
    [options]="filteredDocumentTypes" 
    [(ngModel)]="selectedType" 
    [placeholder]="placeholder | translate" 
    [showClear]="true"
    (onChange)="onTypeChange($event)">
    <ng-template let-document pTemplate="item">
      <i [class]="document.icon"></i>
      <div class="ps-3">{{ document.label | translate }}</div>
    </ng-template>
    <ng-template let-document pTemplate="selectedItem">
      <div class="ps-3">{{ document.label | translate }}</div>
    </ng-template>
  </p-dropdown>
</div>
