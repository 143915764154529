import { Component, OnInit, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { NavService } from "../../services/nav.service";
import { LayoutService } from "../../services/layout.service";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../../../Data/service/http-help.service";

SwiperCore.use([Navigation, Pagination, Autoplay]);
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public elem: any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
 
  constructor(
    public layout: LayoutService, 
    public navServices: NavService,
     @Inject(DOCUMENT) private document: any,
     public translate:TranslateService ,   private router: Router,  private service:HttpHelpService  ,
     private spinner: NgxSpinnerService ,
     ) 
  {
     const layoutVersion = localStorage.getItem("layout_version");
  if (layoutVersion === "dark-only") {
    document.body.classList.add("dark-only");
  } else {
    document.body.classList.add("light-only");
  }
  this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  this.user_type = JSON.parse(localStorage.getItem('user_type'));
  this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu = false;
    this.navServices.levelMenu = false;
  }

  layoutToggle() {
    const currentVersion = localStorage.getItem("layout_version");
  
    if (currentVersion === "dark-only") {
      document.body.classList.remove("dark-only");
      document.body.classList.add("light-only");
      localStorage.setItem("layout_version", "light-only");
    } else {
      document.body.classList.remove("light-only");
      document.body.classList.add("dark-only");
      localStorage.setItem("layout_version", "dark-only");
    }
  }
  
  searchToggle() {
    this.navServices.search = true;
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  };

  ngOnInit() {
    this.elem = document.documentElement;
    this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
  }

}
