import { Component, OnInit, AfterViewInit, AfterContentInit, ChangeDetectorRef, Input, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, BehaviorSubject, of, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, switchMap, tap, catchError, finalize } from 'rxjs/operators';
import { MenuItem, MessageService, PrimeNGConfig, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { HttpHelpService } from 'src/app/Data/service/http-help.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { TieredMenu } from 'primeng/tieredmenu'; // Change this import from Menu to TieredMenu
import {
	DocumentStatus,
	Column,
	DocumentData,
	DocumentFilters,
	SortingOptions,
	TimePeriodOption,
	UserPreferences
} from './alldocuments.model';
import { DocumentType } from './alldocuments-types/alldocuments-types.model';
import { Company } from './alldocuments-companies/alldocuments-companies.model';
import { Customer, CustomerEvent } from './alldocuments-customers/alldocuments-customers.model';
import { CompanyEvent } from './alldocuments-companies/alldocuments-companies.model';
import { CompaniesService } from './alldocuments-companies/companies.service';
import { DocumentTypeEvent } from './alldocuments-types/alldocuments-types.model';

// Import only what's still needed from the data file
import { TABLE_COLUMNS, DOCUMENT_STATUSES } from './alldocuments.data';
import { DOCUMENT_TYPES } from './alldocuments-types/alldocuments-types.data';
import { TimePeriodEvent } from './alldocuments-period/alldocuments-period.model';
import { TIME_PERIOD_OPTIONS } from './alldocuments-period/time-period-options';
import { AlldocumentsViewPdfModalComponent } from './alldocuments-view-pdf-modal/alldocuments-view-pdf-modal.component';
import { CustomersService } from './alldocuments-customers/customers.service';

@Component({
	selector: 'app-alldocuments',
	templateUrl: './alldocuments.component.html',
	styleUrls: ['./alldocuments.component.scss'],
	providers: [MessageService, ConfirmationService]
})
export class AlldocumentsComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	curentLang: string = '';
	sortOrder: boolean = false;
	sortField: string = ''; // Add this property for mobile sorting
	breadcrumbs: MenuItem[] = [];
	filter: FormGroup<{ [K in keyof DocumentFilters]: any }>;
	documentTypes: DocumentType[] = DOCUMENT_TYPES;
	timePeriods: { label: string; value: string; }[];
	documents: any[] = [];
	totalRecords: number = 0;
	rowsPerPage: number = 10;
	loading: boolean = true;
	items: MenuItem[] = [];

	cols: Column[] = [];

	_selectedColumns: Column[];
	colsOptions: Column[];
	globalFilterFields: string[];

	selectedDocument: DocumentData;
	selectedDocumentType: DocumentType | null = null;
	isMobile: boolean;
	filtersMobile: boolean = false;
	sortingMobile: boolean = false;
	resizableColumns: boolean = true;

	// Add these properties for request optimization
	private destroy$ = new Subject<void>();
	private filterSubject = new BehaviorSubject<any>(null);
	hideCompanyColumn: boolean = false;
	// Make currentSorting public so it can be accessed from the template
	currentSorting: SortingOptions = {
		field: 'created_date',
		order: 'DESC',
		active: true
	};
	isPdfExporting: boolean = false;

	// Add the showCalendar property
	showCalendar: boolean = false;

	timePeriodOptions: TimePeriodOption[] = [];

	// Add ViewChild to reference the modal template
	@ViewChild('pdfViewerModal') pdfViewerModal: TemplateRef<any>;

	// Add properties for PDF viewing
	PdfLink: string = '';
	rawPdfUrl: string = '';
	pdfUrl: SafeResourceUrl;
	pdfLoading: boolean = true;

	// Add new property to store column widths
	columnWidths: { [key: string]: number } = {};

	menuVisible: boolean = false;

	@ViewChild('menu') menu: TieredMenu; // Update the type to TieredMenu

	// Add ViewChild for the calendar component
	@ViewChild('mainCalendar') mainCalendar: any;

	// Add this new template reference variable
	@ViewChild('newDocumentModal') newDocumentModal: TemplateRef<any>;

	// Add the statuses array back for filtering logic
	statuses: DocumentStatus[] = DOCUMENT_STATUSES;

	// Add these new properties for infinite scrolling
	isInfiniteScrolling: boolean = false;
	isLoadingMore: boolean = false;
	allDataLoaded: boolean = false;
	scrollThreshold: number = 500; // How far from bottom to trigger loading
	scrollHandler: any = null;
	infiniteScrollPage: number = 0;

	// Add a constant for the localStorage key
	private readonly STORAGE_KEY = 'alldocuments_preferences';

	// Add this property to store initial values that need to be applied after data loads
	private pendingPreferences: {
		customerId?: string;
		companyId?: string;
		documentTypeId?: string;
		statusValue?: string;
	} = {};

	// Add these new properties
	skeletonRowsArray: number[] = [];
	isFirstLoad: boolean = true;

	@ViewChild(AlldocumentsViewPdfModalComponent) pdfViewer: AlldocumentsViewPdfModalComponent;

	// Add a property to track if we've already shown a "no documents" toast
	private hasShownEmptyResultToast: boolean = false;

	constructor(
		private primengConfig: PrimeNGConfig,
		private translate: TranslateService,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private httpHelpService: HttpHelpService,
		private messageService: MessageService,
		private sanitizer: DomSanitizer,
		private modalService: NgbModal,
		private router: Router,
		private confirmationService: ConfirmationService,
		private companiesService: CompaniesService,
		private customersService: CustomersService // Add this line
	) {
		this.curentLang = localStorage.getItem('curentLang') || 'en';
		this.translate.use(this.curentLang);
		this.isMobile = window.innerWidth <= 768;
		this.filter = this.fb.group<{ [K in keyof DocumentFilters]: any }>({
			searchValue: [''],
			selectedCustomer: [null],
			selectedCompany: [null],
			selectedDocumentType: [null],
			selectedTimePeriod: [null],
			selectedStatus: [null]
		});
	}

	@Input() get selectedColumns(): Column[] {
		return this._selectedColumns;
	}

	set selectedColumns(val: Column[]) {
		// Ensure required columns are always included
		const requiredColumns = this.cols.filter(col => col.required);

		// Add any required columns that might be missing
		requiredColumns.forEach(requiredCol => {
			if (!val.some(col => col.field === requiredCol.field)) {
				val.push(requiredCol);
			}
		});

		// Restore original order
		this._selectedColumns = this.cols.filter(col => val.includes(col));
	}

	isMobileDevice(): boolean {
		return window.innerWidth <= 768;
	}

	ngOnInit(): void {
		this.primengConfig.ripple = true;

		// Explicitly set loading to true during initialization
		this.loading = true;

		// Initialize skeleton rows array based on current rows per page
		this.updateSkeletonRowsArray();

		// Initialize static data from imported constants
		this.cols = [...TABLE_COLUMNS];
		this.timePeriodOptions = [...TIME_PERIOD_OPTIONS];

		// Load saved preferences from localStorage
		this.loadUserPreferences();

		// Apply any pending status selection that was loaded from preferences
		this.applyPendingStatusSelection();

		this._selectedColumns = this.cols.sort((a, b) => a.id - b.id).filter(col => (col.visible));

		this.colsOptions = this.cols.filter(col => col.visible);

		this.globalFilterFields = this.cols.map(col => col.field);

		this.translate.get(['allDocuments.breadcrumbs.dashboard', 'allDocuments.breadcrumbs.title']).subscribe(translations => {
			this.breadcrumbs = [
				{ label: translations['allDocuments.breadcrumbs.dashboard'], routerLink: '/dashboard/dashboard' },
				{ label: translations['allDocuments.breadcrumbs.title'], styleClass: 'text-primary' }
			];
		});
		this.timePeriods = [];
		this.filter = this.fb.group<{ [K in keyof DocumentFilters]: any }>({
			searchValue: [''],
			selectedCustomer: [null],
			selectedCompany: [null],
			selectedDocumentType: [null],
			selectedTimePeriod: [null],
			selectedStatus: [null]
		});

		this.filter.get('selectedStatus')?.valueChanges.subscribe(value => {
			this.onFilterChange();
		});

		this.updateResizableColumns();
		window.addEventListener('resize', this.updateResizableColumns.bind(this));

		this.updateInfiniteScrollState();
		window.addEventListener('resize', this.updateInfiniteScrollState.bind(this));

		// Update filter changes with debouncing
		this.filter.valueChanges
			.pipe(
				takeUntil(this.destroy$),
				debounceTime(400), // Wait 400ms after last input before processing
				distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
				tap(() => {
					this.onFilterChange();
				})
			)
			.subscribe();

		// Initialize filter subject for optimized API calls
		this.filterSubject
			.pipe(
				takeUntil(this.destroy$),
				debounceTime(300),
				switchMap(event => {
					this.loading = true; // Ensure loading is true before API call
					return this.fetchDocumentsOptimized(event);
				})
			)
			.subscribe({
				next: (result) => {
					if (result) {
							this.documents = result.data?.length ? this.makeDocumentsStatuses(result.data) : [];
							if (this.documents.length) {
								this.documents = this.makeDocumentsTypes(this.documents);
							}
							this.totalRecords = result.total || 0;
						} else {
							// Ensure we have an empty array, not null/undefined
							this.documents = [];
							this.totalRecords = 0;
						}
						this.loading = false;
					// Set isFirstLoad to false after first data load is complete
					this.isFirstLoad = false;
						setTimeout(() => {
							this.cdr.detectChanges();
						}, 0);
					},
					error: () => {
						this.documents = [];
						this.totalRecords = 0;
						this.loading = false;
						this.isFirstLoad = false; // Also set to false on error
						setTimeout(() => {
							this.cdr.detectChanges();
						}, 0);
					}
				});

		// Initialize current sorting based on initial column configuration
		const initialSortedColumn = this.cols.find(col => col.isSorted);
		if (initialSortedColumn) {
			this.currentSorting = {
				field: initialSortedColumn.field,
				order: initialSortedColumn.sortOrder || 'DESC',
				active: true
			};

			// Initialize sortField for mobile sidebar
			this.sortField = initialSortedColumn.field;
			this.sortOrder = initialSortedColumn.sortOrder === 'ASC';
		}

		// Initialize base menu items with translations
		this.translate.get(['datatableActions.viewDocument', 'datatableActions.editDocument',
			'datatableActions.createRefNumber', 'datatableActions.cancelDocument', 
			'datatableActions.deleteDocument']) // Add delete translation key
			.subscribe(translations => {
				// Define base items that will be filtered for each document
				this.items = [
					{
						label: translations['datatableActions.viewDocument'],
						icon: 'pi pi-fw pi-search',
						command: (event) => {
							this.viewDocument(this.selectedDocument);
							this.closeMenu(); // Add this to hide the overlay
						}
					},
					{
						label: translations['datatableActions.editDocument'],
						icon: 'pi pi-fw pi-pencil',
						command: (event) => {
							this.editDocument(this.selectedDocument);
							this.closeMenu(); // Add this to hide the overlay
						}
					},
					{
						label: translations['datatableActions.createRefNumber'],
						icon: 'pi pi-fw pi-plus',
						command: (event) => {
							this.createRefNumber(this.selectedDocument);
							this.closeMenu(); // Add this to hide the overlay
						}
					},
					{
						label: translations['datatableActions.cancelDocument'],
						icon: 'pi pi-fw pi-trash',
						command: (event) => {
							this.cancelDocument(this.selectedDocument);
							this.closeMenu(); // Add this to hide the overlay
						}
					},
					{
						label: translations['datatableActions.deleteDocument'],
						icon: 'pi pi-fw pi-trash',
						command: (event) => {
							this.deleteDocumentPermanently(this.selectedDocument);
							this.closeMenu(); // Add this to hide the overlay
						}
					}
				];
				// Trigger initial data load WITHOUT setTimeout
				// Removing setTimeout ensures the loading state is consistent
				this.loadDocumentsLazy({ first: 0, rows: this.rowsPerPage });
			});
	}

	onFilterChange(): void {
		// Reset the toast tracking flag when filters change
		this.hasShownEmptyResultToast = false;

		this.loadDocumentsLazy({ first: 0, rows: this.rowsPerPage });
		// Save preferences whenever filters change
		this.saveUserPreferences();
	}

	ngOnDestroy(): void {
		window.removeEventListener('resize', this.updateResizableColumns.bind(this));
		window.removeEventListener('resize', this.updateInfiniteScrollState.bind(this));
		this.cleanupInfiniteScroll();
		this.destroy$.next();
		this.destroy$.complete();
		if (this.handleOutsideClick) {
			window.document.removeEventListener('click', this.handleOutsideClick);
			this.handleOutsideClick = null;
		}
	}

	updateResizableColumns(): void {
		this.resizableColumns = !this.isMobileDevice();
	}

	/**
	 * Update infinite scrolling state based on screen size
	 */
	updateInfiniteScrollState(): void {
		const isMobile = window.innerWidth <= 768;

		if (isMobile && !this.isInfiniteScrolling) {
			// Enable infinite scrolling for mobile
			this.isInfiniteScrolling = true;
			this.setupInfiniteScroll();
		} else if (!isMobile && this.isInfiniteScrolling) {
			// Disable infinite scrolling for desktop
			this.isInfiniteScrolling = false;
			this.cleanupInfiniteScroll();
		}
	}

	/**
	 * Setup scroll event listener for infinite scrolling
	 */
	setupInfiniteScroll(): void {
		this.cleanupInfiniteScroll(); // Remove existing handler if any

		this.scrollHandler = this.checkIfScrolledToBottom.bind(this);
		window.addEventListener('scroll', this.scrollHandler, { passive: true });
	}

	/**
	 * Remove scroll event listener
	 */
	cleanupInfiniteScroll(): void {
		if (this.scrollHandler) {
			window.removeEventListener('scroll', this.scrollHandler);
			this.scrollHandler = null;
		}
	}

	/**
	 * Check if user has scrolled to near bottom
	 */
	checkIfScrolledToBottom(): void {
		if (this.isLoadingMore || this.allDataLoaded || this.loading) {
			return; // Don't proceed if already loading or all data loaded
		}

		const scrollPosition = window.innerHeight + window.scrollY;
		const bodyHeight = document.body.offsetHeight;

		// If scrolled to near bottom
		if (scrollPosition >= bodyHeight - this.scrollThreshold) {
			this.loadMoreData();
		}
	}

	/**
	 * Load more data when scrolling on mobile
	 */
	loadMoreData(): void {
		if (this.isLoadingMore || this.allDataLoaded) return;

		this.isLoadingMore = true;
		this.infiniteScrollPage++;

		const nextPage = this.infiniteScrollPage;
		const offset = nextPage * this.rowsPerPage;

		// If we've already loaded all data, mark as complete
		if (offset >= this.totalRecords) {
			this.allDataLoaded = true;
			this.isLoadingMore = false;
			return;
		}

		// Create an event similar to LazyLoadEvent
		const event: LazyLoadEvent = {
			first: offset,
			rows: this.rowsPerPage,
			sortField: this.currentSorting.field,
			sortOrder: this.currentSorting.order === 'ASC' ? 1 : -1
		};

		// Fetch the next page of data
		this.httpHelpService.getFilteredDocuments(this.buildFilters(event)).pipe(
			finalize(() => {
				this.isLoadingMore = false;
			})
		).subscribe({
			next: (result) => {
				if (result && result.data?.length) {
					// Process the new documents
					const newDocs = this.makeDocumentsStatuses(result.data);
					const processedDocs = this.makeDocumentsTypes(newDocs);

					// Append new documents to existing array
					this.documents = [...this.documents, ...processedDocs];

					// Check if we've reached the end of data
					if (this.documents.length >= this.totalRecords) {
						this.allDataLoaded = true;
					}
				} else {
					// No more data
					this.allDataLoaded = true;
				}
			},
			error: (error) => {
				console.error('Error loading more documents:', error);
				// Show error message
				this.clearAndAddToast(
					'error',
					'Error',
					'Failed to load more documents',
					3000
				);
			}
		});
	}

	// Add this new method to filter actions based on document state
	getFilteredActions(doc: any): MenuItem[] {
		if (!this.items || !doc) {
			return [];
		}

		return this.items.filter(action => {
			// Show "View Document" only when status is confirmed or canceled AND has a document number
			if (action.label === this.translate.instant('datatableActions.viewDocument')) {
				// Check if status is confirmed (confirm=1 and is_deleted=0) OR cancelled (is_deleted=1)
				const isConfirmedOrCanceled = (doc.confirm === 1 && doc.is_deleted === 0) || doc.is_deleted === 1;
				// Ensure document has a document number
				const hasDocumentNumber = doc.document_number && doc.document_number.trim() !== '';

				return isConfirmedOrCanceled && hasDocumentNumber;
			}

			// Logic for Edit Document - only for documents that are NOT confirmed (confirm === 0)
			// and are not deleted (is_deleted === 0)
			if (action.label === this.translate.instant('datatableActions.editDocument')) {
				return doc.confirm === 0 && doc.is_deleted === 0;
			}

			// Logic for Create Reference Number - only for documents that ARE confirmed, don't have a ref number,
			// are not deleted, and are of type Invoice or Invoice With Receipt
			if (action.label === this.translate.instant('datatableActions.createRefNumber')) {
				// Check document is confirmed
				const isConfirmed = doc.confirm === 1;

				// Check document doesn't have a reference number already
				const hasNoRefNumber = doc.confirmation_number === null;

				// Check document is not deleted
				const isNotDeleted = doc.is_deleted === 0;

				// Check document type is Invoice (305) or Invoice with Receipt (320)
				// The original invoice_type code should be available before it's replaced with label in makeDocumentsTypes
				const isInvoiceType = doc.invoice_type_code === '305' || doc.invoice_type_code === '320';

				return isConfirmed && hasNoRefNumber && isNotDeleted && isInvoiceType;
			}

			// Logic for Cancel Document - only for documents that ARE confirmed, NOT already canceled,
			// and are of type Invoice or Invoice With Receipt
			if (action.label === this.translate.instant('datatableActions.cancelDocument')) {
				// Check document is confirmed
				const isConfirmed = doc.confirm === 1;

				// Check document is not already deleted/canceled
				const isNotDeleted = doc.is_deleted === 0;

				// Check document type is Invoice (305) or Invoice with Receipt (320)
				const isInvoiceType = doc.invoice_type_code === '305' || doc.invoice_type_code === '320';

				return isConfirmed && isNotDeleted && isInvoiceType;
			}

			// Show "Delete Document" only for documents that are saved (not confirmed)
			// i.e. we can only delete documents that aren't confirmed yet
			if (action.label === this.translate.instant('datatableActions.deleteDocument')) {
				return doc.confirm === 0 && doc.is_deleted === 0;
			}

			return true;
		});
	}

	// WORKING WITH DOCUMENTS STARTS HERE
	loadDocumentsLazy(event: LazyLoadEvent): void {
		this.loading = true;

		// Reset infinite scroll when filters change
		if (event.first === 0) {
			this.infiniteScrollPage = 0;
			this.allDataLoaded = false;
		}

		// Update sorting information based on event
		if (event.sortField) {
			this.currentSorting = {
				field: event.sortField,
				order: event.sortOrder === 1 ? 'ASC' : 'DESC',
				active: true
			};

			// Also update columns for UI state
			this.cols.forEach(col => {
				if (col.field === event.sortField) {
					col.isSorted = true;
					col.sortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
				} else {
					col.isSorted = false;
				}
				// Save preferences when sorting changes
				this.saveUserPreferences();
			});
		}

		// Update rows per page
		if (event.rows && event.rows !== this.rowsPerPage) {
			this.rowsPerPage = event.rows;
			// Update skeleton rows when rows per page changes
			this.updateSkeletonRowsArray();
			// Save preferences when rows per page changes
			this.saveUserPreferences();
		}

		this.filterSubject.next(event);
	}

	private fetchDocumentsOptimized(event: LazyLoadEvent): Observable<any> {
		if (!event) return of(null);

		// Reset the toast tracking flag with each new search
		this.hasShownEmptyResultToast = false;
		this.messageService.clear(); // Clear any existing messages

		const filters = this.buildFilters(event);

		// Directly call the API without cache checking logic
		return this.httpHelpService.getFilteredDocuments(filters).pipe(
			catchError(error => {
				console.error('Error loading documents:', error);

				// Only show error toast if we haven't already shown an empty result toast
				if (!this.hasShownEmptyResultToast) {
					// Create a detailed error message with search criteria
					const errorDetails = this.createSearchErrorDetails(filters);

					this.clearAndAddToast(
						'error',
						'Failed to load documents',
						errorDetails,
						6000 // Show message for longer
					);

					// Mark that we've shown a toast for this search
					this.hasShownEmptyResultToast = true;
				}

				return of({ data: [], total: 0 }); // Return empty data structure instead of null
			}),
			tap(result => {
				// Only attempt to show "no documents" message if an error message wasn't already shown
				if (!this.hasShownEmptyResultToast &&
					result &&
					Array.isArray((result as any).data) &&
					(result as any).data.length === 0) {

					const searchDetails = this.createSearchErrorDetails(filters);

						this.clearAndAddToast(
							'info',
							this.translate.instant('allDocuments.table.noDocuments'),
							searchDetails,
							5000
						);

						// Mark that we've shown a toast for this search
						this.hasShownEmptyResultToast = true;
				}
			})
		);
	}

	/**
	 * Creates a detailed error message from the search filters
	 */
	private createSearchErrorDetails(filters: any): string {
		const activeFilters: string[] = [];

		// Check which filters are active and create readable messages for each
		if (filters.searchKeyword) {
			activeFilters.push(`${this.translate.instant('allDocuments.filters.search.placeholder')}: "${filters.searchKeyword}"`);
		}

		if (filters.customer_id) {
			// Use the customer service to get the customer name by ID
			const customerName = this.customersService.getCustomerNameById(filters.customer_id);
			activeFilters.push(`${this.translate.instant('allDocuments.filters.customers.title')}: "${customerName}"`);
		}

		if (filters.companyId) {
			const company = this.companiesService.getCompanyById(filters.companyId);
			activeFilters.push(`${this.translate.instant('allDocuments.filters.companies.title')}: "${company?.label || filters.companyId}"`);
		}

		if (filters.invoice_type) {
			const docType = this.documentTypes.find(t => t.code === filters.invoice_type);
			// Important fix: Use translate.instant on the document type label to get the proper translation
			const translatedDocType = docType ? this.translate.instant(docType.label) : filters.invoice_type;
			activeFilters.push(`${this.translate.instant('allDocuments.filters.types.title')}: "${translatedDocType}"`);
		}

		if (filters.fromDate || filters.toDate) {
			activeFilters.push(`${this.translate.instant('allDocuments.filters.period.title')}: ${filters.fromDate || 'any'} to ${filters.toDate || 'any'}`);
		}

		if (filters.status !== null && filters.status !== undefined) {
			const status = this.statuses.find(s => s.id === filters.status);
			// Use translate service for displaying the status label
			const statusLabel = status ? this.translate.instant(status.label) : filters.status;
			activeFilters.push(`${this.translate.instant('allDocuments.filters.status')}: "${statusLabel}"`);
		}

		// If no filters were applied, say so
		if (activeFilters.length === 0) {
			return this.translate.instant('allDocuments.table.noMatchingDocuments');
		}

		// Return a formatted message with all active filters - using pipe separator instead of <>
		return this.translate.instant('allDocuments.table.noMatchingDocuments') + "\n" + activeFilters.join(" | \n");
	}

	// Helper method to build filters object
	private buildFilters(event: LazyLoadEvent): any {
		// Load saved preferences from localStorage
		const savedPreferences = localStorage.getItem(this.STORAGE_KEY);
		let preferences: UserPreferences | null = null;

		if (savedPreferences) {
			try {
				preferences = JSON.parse(savedPreferences);
			} catch (error) {
				console.error('Error parsing preferences from localStorage:', error);
			}
		}

		// Use preferences if available, otherwise fallback to current filter values
		const searchValue = preferences?.filters?.searchValue || this.filter.get('searchValue')?.value || '';
		const selectedCustomer = preferences?.filters?.selectedCustomer || this.filter.get('selectedCustomer')?.value || '';
		const selectedCompany = preferences?.filters?.selectedCompany || this.filter.get('selectedCompany')?.value || '';
		const selectedDocumentType = preferences?.filters?.selectedDocumentType
			? (typeof preferences.filters.selectedDocumentType === 'object' && 'code' in preferences.filters.selectedDocumentType
				? preferences.filters.selectedDocumentType.code
				: preferences.filters.selectedDocumentType)
			: (this.filter.get('selectedDocumentType')?.value as { code?: string })?.code || '';
		const selectedTimePeriod = preferences?.filters?.selectedTimePeriod || this.filter.get('selectedTimePeriod')?.value || [];
		const selectedStatus = this.filter.get('selectedStatus')?.value as string | null;

		// Format dates for API
		const formatDate = (dateInput: any): string => {
			if (!dateInput) return '';
			try {
				const dateObj = new Date(dateInput);

				// Ensure the date is valid
				if (isNaN(dateObj.getTime())) return '';

				// Format the date as YYYY-MM-DD using local time
				const year = dateObj.getFullYear();
				const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-based
				const day = String(dateObj.getDate()).padStart(2, '0');

				return `${year}-${month}-${day}`;
			} catch {
				return '';
			}
		};

		// Set default time period if not provided
		let fromDate = selectedTimePeriod[0] ? formatDate(selectedTimePeriod[0]) : '';
		let toDate = selectedTimePeriod[1] ? formatDate(selectedTimePeriod[1]) : '';

		if (!fromDate || !toDate) {
			const today = new Date();
			fromDate = formatDate(new Date(today.setFullYear(today.getFullYear() - 1))); // Default to one year ago
			toDate = formatDate(new Date()); // Default to today
		}

		// Get the current sort column to retrieve its filterValue
		const sortColumn = this.getColumnByField(this.currentSorting.field);
		const sortIndex = sortColumn?.filterValue ?? 0;

		// Ensure sort order is always a valid value
		const sortOrder = ['ASC', 'DESC'].includes(this.currentSorting.order)
			? this.currentSorting.order
			: 'DESC';

		return {
			fromDate,
			toDate,
			companyId: selectedCompany,
			invoice_type: selectedDocumentType,
			customer_id: selectedCustomer,
			merchant_id: '',
			searchKeyword: searchValue,
			limit: event.rows || 10,
			offset: event.first || 0,
			sortField: this.currentSorting.field,
			sortIndex,
			sort: sortOrder,
			status: this.mapStatusValueToId(selectedStatus)
		};
	}

	// Helper method to find a column by field name
	private getColumnByField(fieldName: string): Column | undefined {
		return this.cols.find(col => col.field === fieldName);
	}

	makeDocumentsStatuses(documents: any[]): DocumentData[] {
		return documents.map((document: any) => {
			// Ensure createdDate has a fallback value
			document.createdDate = document.createdDate || 'N/A';
			if (document.is_deleted == 1) {
				document.status = 'cancelled';
			} else if (document.confirm == 1 && document.is_deleted == 0) {
				document.status = 'confirmed';
			} else {
				document.status = 'saved';
			}
			return document;
		});
	}

	//TODO: create initerface for doctype and use it to create data with codes
	makeDocumentsTypes(documents: any[]): DocumentData[] {
		return documents.map((document: any) => {
			// Store the original invoice_type code before replacing it with the label
			document.invoice_type_code = document.invoice_type?.toString();

			const docType = this.documentTypes.find(type => type.code === document.invoice_type?.toString());

			if (docType) {
				// Keep the translation key in invoice_type - the template will use the translate pipe
				document.invoice_type = docType.label;
				document.icon = docType.icon;
			} else {
				document.invoice_type = 'Unknown';
				document.icon = 'pi pi-file';
			}

			if (this.selectedDocument && this.selectedDocument.number === document.number) {
				this.selectedDocumentType = docType || null;
			}
			return document;
		});
	}

	onSelectedDocumentTypeChange(value: DocumentType): void {
		this.selectedDocumentType = value;
	}

	// WORKING WITH DOCUMENTS ENDS HERE

	// WORKING WITH FILTERS STARTS HERE
	onSelectedStatusChange(value: string): void {
		this.loadDocumentsLazy({ first: 0, rows: this.rowsPerPage });
	}

	//todo return "all" if all filters activated
	countActiveFilters(): string | number {
		const filterValues = Object.values(this.filter.value);
		const activeFilters = filterValues.filter(value => value !== null && value !== '').length;

		// Total number of filter controls in the form
		const totalFilters = Object.keys(this.filter.controls).length;

		// Return 'all' if all filters are active
		if (activeFilters === totalFilters) {
			return 'all';
		}

		return activeFilters;
	}

	clearFilters(): void {
		this.selectedDocumentType = null;
		this.filter.reset();

		// Save the cleared filter state
		this.saveUserPreferences();

		this.clearAndAddToast('success', 'Filters Cleared', 'All filters have been cleared');

		// Reset infinite scroll state
		this.infiniteScrollPage = 0;
		this.allDataLoaded = false;
	}
	// WORKING WITH FILTERS ENDS HERE

	// WORKING WITH TABLE COLUMNS STARTS HERE
	resetColumns(): void {
		// Reset column visibility to default state
		this._selectedColumns = this.cols
			.sort((a, b) => a.id - b.id)
			.filter(col => col.visible);

		// Reset colsOptions to match
		this.colsOptions = this.cols.filter(col => col.visible);

		// Reset all columns sorting state
		this.cols.forEach(col => {
			// Completely clear all sorting indicators
			col.isSorted = false;
			col.sortOrder = undefined;
		});

		// Reset current sorting to default
		this.currentSorting = {
			field: 'created_date',
			order: 'DESC',
			active: true
		};

		// Save the reset preferences
		this.saveUserPreferences();

		// Force reload with explicit table state reset
		this.loading = true;

		// Use setTimeout to ensure this runs after current execution cycle
		setTimeout(() => {
			this.loadDocumentsLazy({
				first: 0,
				rows: this.rowsPerPage,
				sortField: 'created_date', // Hidden field
				sortOrder: -1,
				multiSortMeta: [] // Clear any multi-column sorting
			});

			// This will help the UI refresh properly
			this.cdr.detectChanges();
		});

		this.clearAndAddToast(
			'info',
			'Columns Reset',
			'Columns and sorting have been reset to default view'
		);
	}

	/**
	 * Determines if a column option should be disabled in the column selector
	 * This prevents users from hiding required columns like status
	 */
	isColumnOptionDisabled(option: any): boolean {
		return option.required === true;
	}
	// WORKING WITH TABLE COLUMNS ENDS HERE

	// WORKING WITH DOCUMENTS ACTIONS STARTS HERE
	downloadPDF(): void {
		// Set flag to show spinner icon
		this.isPdfExporting = true;

		try {
			// Create a LazyLoadEvent object with current pagination and sorting
			const event: LazyLoadEvent = {
				first: 0,
				rows: this.totalRecords, // Get all records for the PDF
				sortField: this.currentSorting.field,
				sortOrder: this.currentSorting.order === 'ASC' ? 1 : -1
			};

			// Get filters using the existing buildFilters method
			const rawFilters = this.buildFilters(event);

			// Create a sanitized filters object to prevent SQL injection and type errors
			const filters: any = {
				// String parameters - safe to pass as-is with default empty strings
				fromDate: rawFilters.fromDate || '',
				toDate: rawFilters.toDate || '',
				searchKeyword: rawFilters.searchKeyword || '',
				invoice_type: rawFilters.invoice_type || '',
				sort: rawFilters.sort || 'DESC',
				lang: this.curentLang || 'en',

				// Numeric parameters - need careful handling
				// Convert to actual numbers and provide fallbacks
				limit: parseInt(rawFilters.limit?.toString() || '10000') || 10000,
				offset: parseInt(rawFilters.offset?.toString() || '0') || 0
			};

			// CompanyId - ensure it's a number
			if (rawFilters.companyId && rawFilters.companyId !== '') {
				const companyIdNum = parseInt(rawFilters.companyId.toString());
				if (!isNaN(companyIdNum)) {
					filters.companyId = companyIdNum;
				}
			}

			// SortIndex - ensure it's a number
			const sortColumn = this.getColumnByField(this.currentSorting.field);
			if (sortColumn && sortColumn.filterValue !== undefined && sortColumn.filterValue !== null) {
				filters.sortIndex = parseInt(sortColumn.filterValue.toString()) || 0;
			} else {
				filters.sortIndex = 0;
			}

			// Customer ID - ensure it's a number
			if (rawFilters.customer_id && rawFilters.customer_id !== '') {
				const customerIdNum = parseInt(rawFilters.customer_id.toString());
				if (!isNaN(customerIdNum)) {
					filters.customer_id = customerIdNum;
				}
			}

			// Status - ensure it's a number
			if (rawFilters.status !== null && rawFilters.status !== undefined && rawFilters.status !== '') {
				const statusNum = parseInt(rawFilters.status.toString());
				if (!isNaN(statusNum)) {
					filters.status = statusNum;
				}
			}

			this.httpHelpService.exportReportToPdf(filters).subscribe({
				next: (blob) => {
					const url = window.URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.href = url;
					link.download = 'documents_report.pdf';
					link.click();
					window.URL.revokeObjectURL(url);

					this.clearAndAddToast(
						'success',
						'PDF Downloaded',
						'Documents report has been successfully downloaded'
					);

					// Reset flag when download completes
					this.isPdfExporting = false;
				},
				error: (error) => {
					console.error('Error exporting report:', error);
					this.clearAndAddToast(
						'error',
						'Download Failed',
						'Could not download PDF report. Please check the console for details.'
					);

					// Reset flag on error
					this.isPdfExporting = false;
				}
			});
		} catch (err) {
			console.error('Exception during PDF export preparation:', err);
			this.clearAndAddToast(
				'error',
				'Export Failed',
				'An error occurred while preparing the PDF export'
			);

			// Reset the UI state
			this.isPdfExporting = false;
		}
	}

	/**
	 * Helper method to clear existing toasts before showing a new one
	 * This prevents multiple toasts from appearing simultaneously
	 */
	private clearAndAddToast(severity: string, summary: string, detail: string, life: number = 3000): void {
		// Clear existing toasts first
		this.messageService.clear();

		// Then add the new toast
		this.messageService.add({
			severity,
			summary,
			detail,
			life
		});
	}

	/**
	 * Handle new document creation initiated from the actions component.
	 * This is now just a fallback method since the modal has been moved to the actions component.
	 */
	newDocument(): void {
		// This is now handled by the actions component
		console.log('newDocument called from parent component, but modal is now in actions component');
	}

	/**
	 * Handle selection and navigation from the actions component
	 */
	handleNavigateToNewDocument(docType: DocumentType): void {
		console.log(`Creating new document of type: ${docType.label}`);
		// Any additional logic that needs to happen in the parent component
	}

	// WORKING WITH DOCUMENTS ACTIONS ENDS HERE

	// WORKING WITH DOCUMENT STARTS HERE
	// Add a method for downloading the PDF directly
	downloadPdfFromModal() {
		if (this.rawPdfUrl) {
			fetch(this.rawPdfUrl)
				.then((response) => response.blob())
				.then((blob) => {
					const url = window.URL.createObjectURL(blob);
					const link = document.createElement("a");
					link.href = url;
					// Use document_number from the selected document
					const filename = this.selectedDocument?.document_number
						? `Document-${this.selectedDocument.document_number}.pdf`
						: 'Document.pdf';
					link.download = filename;
					link.click();
					window.URL.revokeObjectURL(url);
				})
				.catch((error) => {
					this.clearAndAddToast(
						'error',
						'Download Error',
						'An error occurred while downloading the document'
					);
				});
		}
	}

	// Handle PDF load events
	onPdfLoaded() {
		this.pdfLoading = false;
	}

	editDocument(doc: any) {
		if (doc && doc.invoice_uuid) {
		  setTimeout(() => {
			const docTypeCode = doc.invoice_type_code;
			const docNumber = doc.document_number || "Unknown";
	  
			console.log("Invoice UUID:", doc.invoice_uuid);
			console.log("Document Type Code:", docTypeCode);
			console.log("Document Number:", docNumber);
	  
			// Generate a dynamic filename based on document type
			let filename = `Document-${docNumber}.pdf`;
	  
			const isInvoiceType = docTypeCode === "305" ;
			const isInvoiceWithReceipt = docTypeCode === "320" ;
			const isReceiptOnly = docTypeCode === "400"; // Only for standalone receipts
	  
			if (isInvoiceType) {
			  filename = `Invoice-${docNumber}.pdf`;
			  console.log("Navigating to update-invoice.");
			  this.router.navigate(["/dashboard/update-invoice", doc.invoice_uuid]);
			} else if (isInvoiceWithReceipt) {
			  filename = `InvoiceWithReceipt-${docNumber}.pdf`;
			  console.log("Navigating to update-invoice-with-receipt.");
			  this.router.navigate(["/dashboard/update-invoice-with-receipt", doc.invoice_uuid]);
			} else if (isReceiptOnly) {
			  filename = `Receipt-${docNumber}.pdf`;
			  console.log("Navigating to add-receipts.");
			  this.router.navigate(["/dashboard/update-receipt", doc.invoice_uuid]);			} else {
			  console.warn(`Unknown document type code: ${docTypeCode}`);
			  this.clearAndAddToast("warning", "Edit", `Unknown document type code: ${docTypeCode}`);
			}
	  
			// Log the generated filename
			console.log("Generated Filename:", filename);
		  });
		} else {
		  console.log("Invalid document. Cannot edit.");
		  this.clearAndAddToast("info", "Edit", "Cannot edit document");
		}
	  }

	createRefNumber(doc: any): void {
		// Check for both invoice_id and invoice_uuid since the API expects a numeric value
		if (!doc || (!doc.invoice_id && !doc.invoice_uuid)) {
			this.clearAndAddToast('error', 'Error', 'Document information is missing');
			return;
		}

		this.translate.get(['createRefNumber.confirmTitle', 'createRefNumber.confirmText',
			'createRefNumber.confirmButton', 'createRefNumber.cancelButton']).subscribe(translations => {
				this.confirmationService.confirm({
					header: translations['createRefNumber.confirmTitle'],
					message: translations['createRefNumber.confirmText'].replace('XXX', doc.document_number),
					icon: 'pi pi-info-circle',
					acceptLabel: translations['createRefNumber.confirmButton'],
					rejectLabel: translations['createRefNumber.cancelButton'],
					accept: () => {
						// Use invoice_id (numeric) instead of invoice_uuid for the API call
						this.httpHelpService.createRefNumber(doc.invoice_id).subscribe({
							next: (response: any) => {
								this.translate.get(response.status === 200 ? 'createRefNumber.successMessage' : 'createRefNumber.errorMessage').subscribe(message => {
									this.clearAndAddToast(
										response.status === 200 ? 'success' : 'error',
										message,
										'',
										3000
									);
								});
								if (response.status === 200) {
									this.loadDocumentsLazy({ first: 0, rows: this.rowsPerPage });
								}
							},
							error: (error: any) => {
								console.error(`Error creating reference number for invoice: ${doc.invoice_id}`, error);

								// Show more detailed error information
								let errorMessage = 'Failed to create reference number';
								if (error.error && error.error.message) {
									errorMessage += `: ${error.error.message}`;
								}

								this.clearAndAddToast(
									'error',
									'Error',
									errorMessage,
									5000
								);
							},
						});
					}
				});
			});
	}

	cancelDocument(doc: any) {
		if (!doc || !doc.invoice_id) {
			this.clearAndAddToast('error', 'Error', 'Document information is missing');
			return;
		}

		this.translate.get('datatableActions').subscribe((translation: any) => {
			this.confirmationService.confirm({
				header: translation.cancelDocumentTitle,
				message: translation.cancelDocumentText.replace('XXX', doc.document_number),
				icon: 'pi pi-exclamation-triangle',
				acceptLabel: translation.cancelDocumentConfirm,
				rejectLabel: translation.cancelDocumentCancel,
				acceptButtonStyleClass: 'p-button-danger',
				accept: () => {
					this.httpHelpService.convertTheInvoiceToReverseInvoice(doc.invoice_id).subscribe({
						next: (response: any) => {
							let message = this.curentLang === "ar"
								? response.messageAr
								: this.curentLang === "en"
									? response.messageEn
									: response.messageHe;

							this.clearAndAddToast(
								response.status === 200 ? 'success' : 'error',
								response.status === 200 ? 'Success' : 'Error',
								message,
								3000
							);

							if (response.status === 200) {
								this.loadDocumentsLazy({ first: 0, rows: this.rowsPerPage });
							}
						},
						error: (error: any) => {
							console.error(`Error canceling document: ${doc.invoice_id}`, error);

							// Show more detailed error information
							let errorMessage = 'Failed to cancel the document';
							if (error.error && error.error.message) {
								errorMessage += `: ${error.error.message}`;
							}

							this.clearAndAddToast(
								'error',
								'Error',
								errorMessage,
								5000
							);
						},
					});
				}
			});
		});
	}

	/**
	 * Permanently delete a document from the system
	 * This is different from cancellation which creates a reverse invoice
	 */
	deleteDocumentPermanently(doc: any): void {
		if (!doc || !doc.invoice_id) {
			this.clearAndAddToast('error', 'Error', 'Document information is missing');
			return;
		}

		// First translate the document type to ensure it displays properly
		this.translate.get(['datatableActions', doc.invoice_type]).subscribe((translations: any) => {
			// Get the translated document type
			const translatedDocType = translations[doc.invoice_type] || doc.invoice_type;

			this.confirmationService.confirm({
				header: translations.datatableActions.deleteDocumentTitle,
				message: translations.datatableActions.deleteDocumentText
					.replace('#XXX', doc.document_number ?? '')
					.replace('TYPE', translatedDocType)
					.replace('DATE', this.formatDisplayDate(doc.invoice_date)),
				icon: 'pi pi-exclamation-triangle',
				acceptLabel: translations.datatableActions.deleteDocumentConfirm,
				rejectLabel: translations.datatableActions.deleteDocumentCancel,
				acceptButtonStyleClass: 'p-button-danger',
				accept: () => {
					this.httpHelpService.deleteInvoiceById(doc.invoice_id).subscribe({
						next: (response: any) => {
							this.clearAndAddToast(
								'success',
								'Success',
								this.translate.instant('datatableActions.deleteDocumentSuccess'),
								3000
							);
							
							// Refresh the document list
							this.loadDocumentsLazy({ first: 0, rows: this.rowsPerPage });
						},
						error: (error: any) => {
							console.error(`Error deleting document: ${doc.invoice_id}`, error);

							// Show more detailed error information
							let errorMessage = this.translate.instant('datatableActions.deleteDocumentError');
							if (error.error && error.error.message) {
								errorMessage += `: ${error.error.message}`;
							}

							this.clearAndAddToast(
								'error',
								'Error',
								errorMessage,
								5000
							);
						},
					});
				}
			});
		});
	}

	// Helper method to format dates for display
	private formatDisplayDate(dateStr: string): string {
		if (!dateStr) return '';

		try {
			const date = new Date(dateStr);
			if (isNaN(date.getTime())) return dateStr;

			// Format date according to user's locale
			return date.toLocaleDateString(this.curentLang || undefined, {
				year: 'numeric',
				month: 'short',
				day: 'numeric'
			});
		} catch (error) {
			console.error('Error formatting date for display:', error);
			return dateStr;
		}
	}

	// WORKING WITH DOCUMENT ENDS HERE

	//todo add button at bottom righht for thhis method
	clearMessages() {
		this.messageService.clear();
	}

	ngAfterViewInit(): void {
		this.cdr.detectChanges();
	}

	ngAfterContentInit(): void {
		// this.cdr.detectChanges();
	}

	// Add this method to apply sorting from the mobile sidebar
	applySortingFromMobile(): void {
		this.sortingMobile = false; // Close the sidebar

		// Update currentSorting based on mobile UI selections
		this.currentSorting = {
			field: this.sortField || 'created_date',
			order: this.sortOrder ? 'ASC' : 'DESC',
			active: true
		};

		// Update columns UI state
		this.cols.forEach(col => {
			if (col.field === this.sortField) {
				col.isSorted = true;
				col.sortOrder = this.sortOrder ? 'ASC' : 'DESC';
			} else {
				col.isSorted = false;
			}
		});

		// Reload data with new sorting
		this.loadDocumentsLazy({
			first: 0,
			rows: this.rowsPerPage,
			sortField: this.sortField,
			sortOrder: this.sortOrder ? 1 : -1
		});

		// Save preferences after sorting changes
		this.saveUserPreferences();

		// Add feedback message
		this.clearAndAddToast(
			'info',
			'Sorting Applied',
			`Documents sorted by ${this.getColumnByField(this.sortField)?.header || this.sortField} (${this.sortOrder ? 'Ascending' : 'Descending'})`
		);
	}

	// Update the event handlers for mobile sortOrder and sortField changes
	onSortOrderChange(): void {
		// Just update the sort order, but don't apply or close sidebar
		console.log('Sort order changed to:', this.sortOrder ? 'ASC' : 'DESC');
	}

	onSortFieldChange(): void {
		// Just update the sort field, but don't apply or close sidebar
		console.log('Sort field changed to:', this.sortField);
	}

	onTimePeriodSelect(option: TimePeriodOption): void {
		const today = new Date();
		let startDate: Date;
		let endDate = new Date(); // Default end date is today

		// Calculate start date based on selected option
		switch (option.label) {
			case 'Current Month':
				startDate = new Date(today.getFullYear(), today.getMonth(), 1);
				break;
			case 'Last Month':
				startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
				endDate = new Date(today.getFullYear(), today.getMonth(), 0);
				break;
			case 'Last 2 Months':
				startDate = new Date(today.getFullYear(), today.getMonth() - 2, 1);
				break;
			case 'Start of Year':
				startDate = new Date(today.getFullYear(), 0, 1);
				break;
			case 'Last Year':
				startDate = new Date(today.getFullYear() - 1, 0, 1);
				endDate = new Date(today.getFullYear() - 1, 11, 31);
				break;
			case 'Last 2 Years':
				startDate = new Date(today.getFullYear() - 2, 0, 1);
				break;
			default:
				return; // Do nothing for unrecognized options
		}

		// Update the form control with the new date range
		this.filter.get('selectedTimePeriod')?.setValue([startDate, endDate] as unknown as DocumentFilters['selectedTimePeriod']);

		// Reset the selected state of all options
		this.timePeriodOptions.forEach(opt => opt.selected = false);

		// Mark the current option as selected
		option.selected = true;
	}

	/**
	 * Handle row click to view or edit document
	 * @param event Click event
	 * @param document The document data for the clicked row
	 */
	onRowClick(event: MouseEvent, document: any): void {
		// Check if click was on the last column (actions column)
		const target = event.target as HTMLElement;
		// Check if the click was on or inside the actions column
		if (target.closest('td:last-child')) {
			// If click was on the actions column, don't do anything
			return;
		}

		// If document is confirmed (confirm !== 0), view it. Otherwise edit it.
		if (document.confirm !== 0) {
			this.viewDocument(document);
		} else {
			this.editDocument(document);
		}
	}

	// Add method to capture column resize events
	onColumnResize(event: any): void {
		if (event.element && event.element.offsetWidth) {
			const columnField = event.column.field;
			this.columnWidths[columnField] = event.element.offsetWidth;
		}
	}

	getFormattedTimePeriod(): string {
		const timePeriod = this.filter.get('selectedTimePeriod')?.value;
		if (!timePeriod || (!timePeriod[0] && !timePeriod[1])) {
			return '';
		}

		if (timePeriod[0] && timePeriod[1]) {
			return `${this.formatDate(timePeriod[0])} - ${this.formatDate(timePeriod[1])}`;
		} else if (timePeriod[0]) {
			return `From ${this.formatDate(timePeriod[0])}`;
		} else if (timePeriod[1]) {
			return `Until ${this.formatDate(timePeriod[1])}`;
		}

		return '';
	}

	private formatDate(date: any): string {
		if (!date) return '';

		try {
			// If it's already a Date object
			if (date instanceof Date) {
				return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
			}

			// If it's a timestamp number or string that can be converted
			const dateObj = new Date(date);
			if (!isNaN(dateObj.getTime())) {
				return `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
			}

			// If we can't parse it, just return it as a string
			return String(date);
		} catch (error) {
			console.error('Error formatting date:', error);
			return String(date);
		}
	}

	// Add the applyTimePeriod method
	applyTimePeriod(): void {
		// This method is called when the user clicks Apply on the time period calendar
		// It should update the filter and trigger a reload of the documents
		this.onFilterChange();
		// Close the calendar if needed
		this.showCalendar = false;
		// Close the main calendar if it exists
		if (this.mainCalendar) {
			this.mainCalendar.hideOverlay();
		}
	}

	toggleMenu(event: Event, docData: DocumentData): void {
		this.selectedDocument = docData;

	// Toggle menu visibility
		if (this.menuVisible) {
			this.closeMenu();
		} else {
			this.menuVisible = true;
			this.menu.toggle(event);

			// Add an event listener to detect clicks outside the menu
			setTimeout(() => {
				// Use window.document to reference the global document object
				window.document.addEventListener('click', this.handleOutsideClick = (e: Event) => {
					// Check if click is outside menu and the button
					const menuElement = window.document.querySelector('.p-tieredmenu');
					const buttonElement = e.target as HTMLElement;

					if (menuElement && !menuElement.contains(buttonElement) &&
						!buttonElement.closest('.p-button-rounded')) {
						this.closeMenu();
					}
				}, { once: true });
			}, 0);
		}
	}

	// Add a property to store the outside click handler
	private handleOutsideClick: ((e: Event) => void) | null = null;

	closeMenu(): void {
		// Hide both menu and overlay
		this.menuVisible = false;
		this.menu.hide();

		// Remove event listener if exists
		if (this.handleOutsideClick) {
			window.document.removeEventListener('click', this.handleOutsideClick);
			this.handleOutsideClick = null;
		}
	}

	/**
	 * Handle search input changes from the search component
	 * Note that the incoming value is already normalized by the search component
	 */
	onSearchChange(value: string): void {
		const searchValueControl = this.filter.get('searchValue');
		if (searchValueControl) {
			searchValueControl.setValue(value, { emitEvent: false });
		}
		this.onFilterChange();
	}

	/**
	 * Handle search clearing from the search component 
	 * The feedback message is now handled by the search component
	 */
	onSearchClear(): void {
		this.filter.get('searchValue')?.setValue('', { emitEvent: false });
		this.onFilterChange();
	}

	/**
	 * Handle document type selection from the types component
	 */
	onDocumentTypeChange(event: DocumentTypeEvent): void {
		const selectedDocumentTypeControl = this.filter.get('selectedDocumentType');
		if (selectedDocumentTypeControl) {
			selectedDocumentTypeControl.setValue(event.value, { emitEvent: true });
		}
		this.selectedDocumentType = event.value; // Update the selectedDocumentType property
		this.onFilterChange();
	}

	/**
	 * Handle time period selection from the period component
	 */
	onPeriodChange(event: TimePeriodEvent): void {
		const selectedTimePeriodControl = this.filter.get('selectedTimePeriod');
		if (selectedTimePeriodControl) {
			selectedTimePeriodControl.setValue(event.value, { emitEvent: true });
		}
		this.onFilterChange();
	}

	// Handle status change from the status component
	onStatusChange(value: string): void {
		const statusControl = this.filter.get('selectedStatus');
		if (statusControl) {
			statusControl.setValue(value, { emitEvent: true });
		}
		this.onFilterChange();

		// Explicitly save user preferences when status changes
		this.saveUserPreferences();
	}

	// Add methods to save and restore user preferences

	/**
	 * Save current filter and sorting preferences to localStorage
	 */
	private saveUserPreferences(): void {
		try {
			const preferences: UserPreferences = {
				filters: {
					searchValue: this.filter.get('searchValue')?.value as string,
					selectedCustomer: this.filter.get('selectedCustomer')?.value as string,
					selectedCompany: this.filter.get('selectedCompany')?.value as string,
					selectedDocumentType: this.getDocTypePreference(),
					selectedTimePeriod: this.filter.get('selectedTimePeriod')?.value as Date[] || null,
					selectedStatus: this.mapStatusValueToId(this.filter.get('selectedStatus')?.value as string | null)
				},
				sorting: {
					field: this.currentSorting.field,
					order: this.currentSorting.order
				},
				columns: this._selectedColumns.map(col => col.field),
				rowsPerPage: this.rowsPerPage
			};

			localStorage.setItem(this.STORAGE_KEY, JSON.stringify(preferences));
		} catch (error) {
			console.error('Error saving preferences to localStorage:', error);
			// Silently fail - storing preferences is a non-critical feature
		}
	}

	/**
	 * Load saved user preferences from localStorage
	 */
	private loadUserPreferences(): void {
		try {
			const savedPreferences = localStorage.getItem(this.STORAGE_KEY);
			if (!savedPreferences) return;

			const preferences: UserPreferences = JSON.parse(savedPreferences);

			if (preferences.filters) {
				if (preferences.filters.searchValue) {
					this.filter.get('searchValue')?.setValue(preferences.filters.searchValue, { emitEvent: false });
				}

				if (preferences.filters.selectedCustomer) {
					this.pendingPreferences.customerId = preferences.filters.selectedCustomer;
				}

				if (preferences.filters.selectedCompany) {
					this.pendingPreferences.companyId = preferences.filters.selectedCompany;
				}

				if (preferences.filters.selectedDocumentType) {
					const docType = preferences.filters.selectedDocumentType;
					if (typeof docType === 'object' && docType !== null && docType.code) {
						this.pendingPreferences.documentTypeId = docType.code;
						const matchingDocType = this.documentTypes.find(dt => dt.code === docType.code);
						if (matchingDocType) {
							this.filter.get('selectedDocumentType')?.setValue(matchingDocType, { emitEvent: false });
							this.selectedDocumentType = matchingDocType;
						}
					} else if (typeof docType === 'string') {
						this.pendingPreferences.documentTypeId = docType;
						const matchingDocType = this.documentTypes.find(dt => dt.code === docType);
						if (matchingDocType) {
							this.filter.get('selectedDocumentType')?.setValue(matchingDocType, { emitEvent: false });
							this.selectedDocumentType = matchingDocType;
						}
					}
				}

				if (preferences.filters.selectedTimePeriod) {
					const timePeriod = preferences.filters.selectedTimePeriod;
					if (Array.isArray(timePeriod) && timePeriod.length > 0) {
						const dateRange = timePeriod.map(date => date ? new Date(date) : null);
						this.filter.get('selectedTimePeriod')?.setValue(dateRange as any, { emitEvent: false });
					}
				}

				if (preferences.filters.selectedStatus !== undefined && preferences.filters.selectedStatus !== null) {
					const statusValue = this.mapStatusIdToValue(preferences.filters.selectedStatus);
					this.filter.get('selectedStatus')?.setValue(statusValue, { emitEvent: false });
				}
			}

			if (preferences.sorting) {
				this.currentSorting = {
					field: preferences.sorting.field || 'created_date',
					order: preferences.sorting.order || 'DESC',
					active: true
				};

				this.cols.forEach(col => {
					if (col.field === this.currentSorting.field) {
						col.isSorted = true;
						col.sortOrder = preferences.sorting?.order ?? 'DESC';
					} else {
						col.isSorted = false;
					}
				});

				this.sortField = this.currentSorting.field;
				this.sortOrder = this.currentSorting.order === 'ASC';
			}

			if (preferences.columns && Array.isArray(preferences.columns)) {
				const savedColumns = this.cols.filter(col =>
					(preferences.columns?.includes(col.field) || col.required)
				);
				if (savedColumns.length > 0) {
					this._selectedColumns = savedColumns;
					this.colsOptions = savedColumns;
				}
			}

			if (preferences.rowsPerPage) {
				this.rowsPerPage = preferences.rowsPerPage;
			}
		} catch (error) {
			console.error('Error loading preferences from localStorage:', error);
			// If there's an error parsing the saved preferences, continue with defaults
		}
	}

	/**
	 * Helper to get the document type preference to store
	 * Returns the document type code if available
	 */
	private getDocTypePreference(): string | null {
		const docType = this.filter.get('selectedDocumentType')?.value as DocumentType;
		if (docType && docType.code) {
			return docType.code;
		}
		return null;
	}

	/**
	 * Apply pending customer selection after customer data loads
	 */
	applyPendingCustomerSelection(customers: Customer[]): void {
		if (this.pendingPreferences.customerId && customers?.length) {
			console.log('Applying pending customer selection:', this.pendingPreferences.customerId);

			// Find the customer in the loaded customer list to ensure proper selection
			const customerControl = this.filter.get('selectedCustomer');
			if (customerControl) {
				// Set the customer ID in the filter - must match type expected by the control
				customerControl.setValue(this.pendingPreferences.customerId, { emitEvent: false });

				// Log that we successfully applied the customer preference
				console.log('Customer preference applied:', this.pendingPreferences.customerId);
			}

			// Clear pending selection
			this.pendingPreferences.customerId = undefined;
		}
	}

	/**
	 * Apply pending company selection after company data loads
	 */
	applyPendingCompanySelection(companies: Company[]): void {
		if (this.pendingPreferences.companyId && companies?.length) {
			console.log('Applying pending company selection:', this.pendingPreferences.companyId);

			// Find the company in the loaded company list to ensure proper selection
			const companyControl = this.filter.get('selectedCompany');
			if (companyControl) {
				// Set the company ID in the filter - must match type expected by the control
				companyControl.setValue(this.pendingPreferences.companyId, { emitEvent: false });

				// Log that we successfully applied the company preference
				console.log('Company preference applied:', this.pendingPreferences.companyId);
			}

			// Clear pending selection
			this.pendingPreferences.companyId = undefined;
		}
	}

	/**
	 * Apply pending document type selection after document types load
	 */
	applyPendingDocumentTypeSelection(documentTypes: DocumentType[]): void {
		if (this.pendingPreferences.documentTypeId && documentTypes?.length) {
			const matchingDocType = documentTypes.find(dt => dt.code === this.pendingPreferences.documentTypeId);
			if (matchingDocType) {
				this.filter.get('selectedDocumentType')?.setValue(matchingDocType, { emitEvent: false });
				this.selectedDocumentType = matchingDocType;
			}
			// Clear pending selection
			this.pendingPreferences.documentTypeId = undefined;
		}
	}

	/**
	 * Apply pending status selection after statuses load
	 */
	applyPendingStatusSelection(): void {
		if (this.pendingPreferences.statusValue) {
			const statusControl = this.filter.get('selectedStatus');
			if (statusControl) {
				console.log(`Applying saved status filter: ${this.pendingPreferences.statusValue}`);
				statusControl.setValue(this.pendingPreferences.statusValue, { emitEvent: false });
			}
			// Clear pending selection after applying
			this.pendingPreferences.statusValue = undefined;
		}
	}

	/**
	 * Handle company selection from the companies component
	 */
	onCompanyChange(event: CompanyEvent): void {
		const selectedCompanyControl = this.filter.get('selectedCompany');
		if (selectedCompanyControl) {
			selectedCompanyControl.setValue(event.value, { emitEvent: true });
		}
		this.onFilterChange();
	}

	/**
	 * Handle customer selection from the customers component
	 */
	onCustomerChange(event: CustomerEvent): void {
		const selectedCustomerControl = this.filter.get('selectedCustomer');
		if (selectedCustomerControl) {
			selectedCustomerControl.setValue(event.value, { emitEvent: true });
		}
		this.onFilterChange();
	}

	// Add method to handle customer data loaded event
	onCustomersLoaded(customers: Customer[]): void {
		console.log('Customers loaded event received in parent component', customers.length);
		this.applyPendingCustomerSelection(customers);
	}

	// Add method to handle companies data loaded event 
	onCompaniesLoaded(companies: Company[]): void {
		console.log('Companies loaded event received in parent component', companies.length);
		this.applyPendingCompanySelection(companies);
	}

	// Add method to handle document types loaded event
	onDocumentTypesLoaded(documentTypes: DocumentType[]): void {
		this.applyPendingDocumentTypeSelection(documentTypes);
	}

	// Add this new method to update the skeleton rows array
	private updateSkeletonRowsArray(): void {
		// Create an array with length equal to rowsPerPage (or a minimum of 3 rows)
		// This array is just for ngFor to iterate over
		const rowsToShow = Math.min(Math.max(this.rowsPerPage, 3), 10); // Min 3, max 10 rows
		this.skeletonRowsArray = Array(rowsToShow).fill(0).map((_, i) => i);
	}

	// Update the viewDocument method to correctly use the PrimeNG dialog
	viewDocument(doc: DocumentData) {
		if (doc && doc.pdf_link) {
			this.pdfLoading = true;

			// Sanitize the URL for safe embedding
			const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(doc.pdf_link);

			// Use the pdfViewer component reference to open the dialog
			if (this.pdfViewer) {
				this.pdfViewer.openDialog({
					pdfLink: doc.pdf_link,
					rawPdfUrl: doc.pdf_link,
					pdfUrl: safeUrl,
					documentNumber: doc.document_number || '',
					documentHtmlContent: '',
					documentId: doc.invoice_id || '',
					documentLanguage: doc.document_language || ''
				});
			} else {
				console.error('PDF Viewer component reference not available');
				this.clearAndAddToast(
					'error',
					'Error',
					'Could not open the document viewer'
				);
			}
		} else {
			this.clearAndAddToast(
				'info',
				'Document Unavailable',
				'PDF document cannot be viewed or is still being generated.'
			);
		}
	}

	private mapStatusValueToId(value: string | null): number | null {
		const status = DOCUMENT_STATUSES.find(s => s.value === value);
		return status ? status.id : null;
	}

	private mapStatusIdToValue(id: number | null): string | null {
		const status = DOCUMENT_STATUSES.find(s => s.id === id);
		return status ? status.value : null;
	}
}
