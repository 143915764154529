import { Component, Input, OnInit, Type } from "@angular/core";
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl } from "@angular/forms";
import { HttpHelpService } from "../service/http-help.service";
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import Swal from 'sweetalert2';
import { AddMerchantUserEndPoient, AddUserEndPoient } from "../service/global.service";
import { israelPhoneNumberValidator } from './../../validators/israelPhoneNumberValidator';
import { passwordValidators } from "../../validators/passwordValidators";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"]
})

export class UsersComponent implements OnInit {
  
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term:any;
  p: any;
  addUser:FormGroup;
  merchantUser:FormGroup;
  customerDataMerchant:any=[];
  receiptId: number;
  updatedData: any;
  viewData: any;
  UserPhone:any
  userData:any=[];
  userTypeData:any=[];
  public show: boolean = false;
  userId:any;
  @Input() userModalId: number;
  public validate = false;
  usersComponent: Type<UsersComponent> = UsersComponent;
  @Input() isInsideModal: boolean = false;
  public isProfile2 = true;
  openedIndex: number | null = null; 

   constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));


   this.addUser=new FormGroup({
    'phone_number': new FormControl('', [Validators.required, israelPhoneNumberValidator()]),
    user_name:new FormControl(''),
    language :new FormControl(this.curentLang),  
     password: new FormControl('', passwordValidators),
    user_type: new FormControl(''),
    created_by:new FormControl(this.userRegistId),
    });

    this.merchantUser=new FormGroup({
      'phone_number': new FormControl('', [Validators.required, israelPhoneNumberValidator()]),
      user_name:new FormControl(''),
      password: new FormControl('', passwordValidators),
      merchant_id: new FormControl(this.merchantId),
      created_by:new FormControl(this.userRegistId),
      });
  };

  toggleCollapse(index: number): void {
    // إذا كان القسم المفتوح هو القسم الذي نضغط عليه، إغلاقه، وإلا فتح القسم الجديد
    this.openedIndex = this.openedIndex === index ? null : index;
     
    
  };

  formatPhoneNumber(phoneNumber: string): string {
    // Remove any non-digit characters except '+'
    const cleaned = phoneNumber.replace(/[^ \d]/g, '');
  
    // Ensure the number ends with '+'
    if (!cleaned.endsWith('+')) {
      return `${cleaned}+`;
    }
  
    return cleaned;
  };
  
  getALLUsersByBasedToken() {
    this.spinner.show();
    this.service.getALLUsersByBasedToken().subscribe({
      next: res => {
        this.userData = res;
      
        
        const user = this.userData.find(user => user.phone_number === this.UserPhone);
  
        if (user) {
           this.UserPhone = this.formatPhoneNumber(user.phone_number);
       
         }
         this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  getAllUserType() {
    this.spinner.show();
    this.service.getAllUserType().subscribe({
      next: res => {
        this.userTypeData = res;
         
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  openXl(content:any) {
    this.modalService.open(content, { size: 'xl' , centered:true });
  };

  openXl2(content2:any) {
    this.modalService.open(content2, { size: 'md' , centered:true });
  };

  filter(event:any)
  {
    let x = event.target.value;
   };
   handlePasswordValidation(formGroup: FormGroup) {
    const passwordControl = formGroup.get('password');
    if (passwordControl) {
      passwordControl.markAsTouched();
    }
  }
  
  showPassword() {
    this.show = !this.show;
    const passwordInput = document.querySelector('input[name="login[password]"]') as HTMLInputElement;
    if (passwordInput) {
      passwordInput.focus();
    }
    this.handlePasswordValidation(this.addUser);
    this.handlePasswordValidation(this.merchantUser);
  }

  addedData() {
    if (
      this.addUser.value.phone_number === '' ||
      this.addUser.value.user_name === '' ||
      this.addUser.value.password === '' ||
      this.addUser.value.user_type === ''
    ) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : 
               (this.curentLang === 'en' ? 'Please fill all fields' : 
               (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
      });
      this.validate = !this.validate;
      return; 
    } else {
      let userPhone = this.addUser.value.phone_number;
      if (userPhone.startsWith('0')) {
        userPhone = userPhone.substring(1);
      }
  
      const validPrefixes = ['50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
      let validationStartPhoneNumber = userPhone.substring(0, 2);
  
      if (!validPrefixes.includes(validationStartPhoneNumber)) {
        Swal.fire({
          icon: 'warning',
          title: this.curentLang === 'ar' ? 'يجب أن يبدأ رقم الهاتف بأي رقم من هذة الأرقام (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : 
                 (this.curentLang === 'en' ? 'Phone number must start with one of these numbers (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : 
                 (this.curentLang === 'he' ? 'צריך להתחיל עם אחד מהמספרים (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : ''))
        });
        return;
      }
  
      if (userPhone.length < 7) {
        Swal.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? 'يجب ألا يقل رقم الهاتف عن 7 أرقام' : 
                 (this.curentLang === 'en' ? 'The phone number must be at least 7 digits long' : 
                 (this.curentLang === 'he' ? 'מספר הטלפון חייב להיות בן 7 ספרות לפחות' : ''))
        });
        return;
      }
  
      let password = this.addUser.value.password;
      if (password.length < 8 || password.length > 20) {
        Swal.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? 'يجب أن تكون كلمة المرور أطول من أو تساوي 8 أحرف وأقل من أو تساوي 20 حرفًا' : 
                 (this.curentLang === 'en' ? 'Password must be longer than or equal to 8 characters and shorter than or equal to 20 characters' : 
                 (this.curentLang === 'he' ? 'הסיסמה חייבת להיות ארוכה מ-8 תווים וקצרה מ-20 תווים' : ''))
        });
        return;
      }
  
      let customerPhone = this.addUser.value.phone_number;
      if (customerPhone.startsWith('0')) {
        customerPhone = customerPhone.substring(1);
      }
      let model = {
        "phone_number": '+972' + customerPhone,
        user_name: this.addUser.value.user_name,
        password: this.addUser.value.password,
        user_type: this.addUser.value.user_type,
        language: this.curentLang,
        created_by: Number(this.userRegistId),
      };
  
      this.spinner.show();
      this.service.Post(AddUserEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : 
                   (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getALLUsersByBasedToken();
          this.getAllUserType();
          this.addUser.reset();
        } else if (res.status === 400 || res.status === 403 || res.status === 406 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
            case 403:
            case 406:
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                             (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : 
                             (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
    }
  }
  

  addMerchantUser() {
    if 
    (
      this.merchantUser.value.phone_number == ''||
      this.merchantUser.value.user_name == ''||
      this.merchantUser.value.password == ''
    ) 
      {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
      });
      
      this.validate = !this.validate;
      return; 
    }

    const validPrefixes = ['50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
    let validationStartPhoneNumber = this.merchantUser.value.phone_number.substring(0, 2);
  
    if (!validPrefixes.includes(validationStartPhoneNumber)) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? ' يجب ان يبدأ رقم الهاتف بأي رقم من هذة الأرقام (50, 51, 52, 53, 54, 55, 56, 57, 58, 59) ' : 
               (this.curentLang === 'en' ? ' Phone number must start with one of these numbers (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : 
               (this.curentLang === 'he' ? ' צריך להתחיל עם אחד מהמספרים (50, 51, 52, 53, 54, 55, 56, 57, 58, 59) ' : ''))
      });
      return;
    };

    let userPhone = this.merchantUser.value.phone_number;
    if (userPhone.length <  7) {
        Swal.fire({
            icon: 'error',
            title: this.curentLang === 'ar' ? 'يجب ألا يقل رقم الهاتف عن 7 أرقام' : 
                   (this.curentLang === 'en' ? 'The phone number must be at least 7 digits long ' : 
                   (this.curentLang === 'he' ? 'מספר הטלפון חייב להיות בן 7 ספרות לפחות' : ''))
        });
        return;
    }
    
      
    else
    {
      let password = this.merchantUser.value.password;
      if (password.length < 8 || password.length > 20) {
          Swal.fire({
              icon: 'error',
              title: this.curentLang === 'ar' ? 'يجب أن تكون كلمة المرور أطول من أو تساوي 8 أحرف وأقل من أو تساوي 20 حرفًا' : 
                     (this.curentLang === 'en' ? 'Password must be longer than or equal to 8 characters and shorter than or equal to 20 characters' : 
                     (this.curentLang === 'he' ? 'הסיסמה חייבת להיות ארוכה מ-8 תווים וקצרה מ-20 תווים' : ''))
          });
          return;
      }
      let customerPhone = this.merchantUser.value.phone_number;
      if (customerPhone.startsWith('0')) {
        customerPhone = customerPhone.substring(1);
      }
      let model = {
        "phone_number": '+972' + customerPhone,
        user_name: this.merchantUser.value.user_name,
        password: this.merchantUser.value.password,
        merchant_id: Number(this.merchantId),
        created_by: Number(this.userRegistId),
      };
    
      this.spinner.show();
      this.service.Post(AddMerchantUserEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getALLUsersByBasedToken();
          this.getAllUserType();
          this.merchantUser.reset();
        } else if (res.status === 400 || res.status === 403 ||  res.status === 406 || res.status === 500 ) {
          let errorMessage;
          switch (res.status) {
            case 400:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 403:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
              case 406:
                errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                break;
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
      
    }

  };


  changeUserStatus(id:number, user_status:any){
    this.spinner.show();
  
    let model =
    {
      "user_status": user_status == 1 ? 59 : 1,
     "updated_by": this.userRegistId
    }
  
    this.service.changeUserStatus(id , model ).subscribe(({
      
      next:res =>{
       if (res.status ==200){

        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
        })
          this.getALLUsersByBasedToken();
          this.spinner.hide();
       }
  
      },
      error:err =>{
        Swal.fire({
          icon:"error",
          title:err.error.message[0]
        });
        this.spinner.hide();
  
      }
      
    }))
  };

  ngOnInit() 
  {
  this.getALLUsersByBasedToken();
  this.getAllUserType();
  this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) 
   {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   }
    else 
    {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only")
     {
       document.body.classList.add("dark-only");
     } 
     else
     {
       document.body.classList.add("light-only");
     }
   };
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));
 
  };

  openXl4(content4:any) {
    this.modalService.open(content4, { size: 'lg'  });
  };

  getId(id:number)
{
   this.userId = id ;
   
   
};
}
