<p-card class="subscription-card mb-5">
    <div class="current-subscription-header">
        <h3>Current Subscription</h3>
        <span class="status-active" *ngIf="currentPlan">Active</span>
        <span class="status-inactive" *ngIf="!currentPlan">No Active Plan</span>
    </div>
    <div class="current-subscription-details bordered-section" *ngIf="currentPlan">
        <div class="detail-item">
            <span class="detail-label">Plan Name</span>
            <span class="detail-value">{{ currentPlan.name }}</span>
        </div>
        <div class="detail-item">
            <span class="detail-label">Price</span>
            <span class="detail-value">${{ currentPlan.price }}/{{ currentPlan.billing }}</span>
        </div>
        <div class="detail-item">
            <span class="detail-label">Companies</span>
            <span class="detail-value">{{ currentPlan.companies }} (<span class="highlight-count">{{ currentPlan.companies -
                    (currentPlan.companiesUsed || 0) }}</span>
                left)</span>
        </div>
        <div class="detail-item">
            <span class="detail-label">Users</span>
            <span class="detail-value">{{ currentPlan.users }} (<span class="highlight-count">{{ currentPlan.users -
                    (currentPlan.usersUsed || 0) }}</span>
                left)</span>
        </div>
        <div class="detail-item">
            <span class="detail-label">Trial Period</span>
            <span class="detail-value">{{ currentPlan.trial }} days (ends <span class="highlight-count">{{ trialEndDate | date:
                    'shortDate' }}</span>)</span>
        </div>
        <div class="detail-item">
            <span class="detail-label">Billing Type</span>
            <span class="detail-value">{{ currentPlan.billing | titlecase }}</span>
        </div>
        <div class="detail-item">
            <span class="detail-label">Subscription Start Date</span>
            <span class="detail-value">{{ currentPlan.startDate | date }}</span>
        </div>
        <div class="detail-item">
            <span class="detail-label">Next Payment</span>
            <span class="detail-value next-payment">
                {{ currentPlan.nextPaymentDate | date }}
                <i class="pi pi-info-circle ml-2" pTooltip="Payment will be charged to the card ending with 1234"
                    tooltipPosition="top" [showDelay]="0" [hideDelay]="0" tooltipStyleClass="payment-tooltip"></i>
            </span>
        </div>
    </div>
    <div class="action-buttons d-flex justify-content-between">
        <button pButton pRipple label="Cancel Subscription" class="p-button-danger" icon="pi pi-times" *ngIf="currentPlan"
            type="button" (onClick)="cancelSubscription()">
        </button>
        <button pButton pRipple label="Edit Payment Data" class="p-button-secondary" icon="pi pi-credit-card" type="button"
            routerLink="/checkout" *ngIf="currentPlan">
        </button>
    </div>

    <hr class="mt-4 mb-5">

    <header class="subscription-header">
        <h3>Available Plans</h3>
        <p-selectButton [options]="billingOptions" [(ngModel)]="planType" (onChange)="setPlanType(planType)">
        </p-selectButton>
    </header>

    <div class="plans-grid">
        <ng-container *ngFor="let plan of filteredPlans">
            <div class="plan-card" [style]="{
                                                                                                                                borderColor: currentPlan?.id === plan.id ? '#54BA4A' : (plan.isRelevant ? 'darkcyan' : '#E5E7EB'),
                                                                                                                                borderWidth: currentPlan?.id === plan.id || plan.isRelevant ? '2px' : '1px',
                                                                                                                                backgroundColor: plan.price > currentPlan.price ? '#f0f9ff' : '#ffffff'
                                                                                                                            }">
                <div class="plan-header">
                    <div class="plan-info">
                        <h4 class="plan-title">{{ plan.name }}</h4>
                        <p class="plan-description">
                            {{ plan.description }}
                        </p>
                    </div>
                    <div class="price-container">
                        <div class="price-display">
                            <span class="currency-symbol">$</span>
                            <span>{{ plan.price }}</span>
                            <span class="price-period">/mo</span>
                        </div>
                        <p *ngIf="plan.billing === 'yearly'" class="yearly-price">
                            {{ "$" + plan.price * 12 + " billed yearly" }}
                        </p>
                    </div>
                </div>

                <ul class="features-list">
                    <li *ngFor="let feature of plan.features" class="feature-item">
                        <div class="feature-icon">
                            <i class="pi pi-check"></i>
                        </div>
                        <span class="feature-text" [class.highlight-feature]="!currentPlan.features.includes(feature)">
                            {{ feature }}
                        </span>
                    </li>
                </ul>

                <div class="action-buttons">
                    <button *ngIf="currentPlan?.id !== plan.id" pButton pRipple label="Subscribe Now" (onClick)="selectPlan(plan.id)"
                        class="p-button-primary subscribe-button">
                    </button>
                    <span *ngIf="currentPlan?.id === plan.id" class="current-plan-text w-100">
                        Current Plan
                    </span>
                </div>

                <!-- Plan Badge -->
                <div *ngIf="plan.billing === 'yearly'" class="custom-badge success-badge">
                    Save 16%
                </div>
                <div *ngIf="plan.isRelevant" class="custom-badge help-badge">
                    Most Relevant
                </div>
            </div>
        </ng-container>
    </div>
</p-card>
<div class="subscription-plans">
    <button *ngFor="let plan of filteredPlans" (click)="confirmSubscription(plan.id)" class="btn btn-primary">
        {{ plan.name }}
    </button>
</div>

<p-confirmDialog></p-confirmDialog> <!-- Confirmation dialog -->