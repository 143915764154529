import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpHelpService } from "../service/http-help.service";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";
import Swal from 'sweetalert2';
import { AddProductEndPoient } from "../service/global.service";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"]
})

export class ProductsComponent implements OnInit {
  @Input() isInsideModal: boolean = false;
  openedIndex: number | null = null; 

  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term:any;
  p: any;
  addProduct:FormGroup;
  updateProduct:FormGroup;
  productsData:any=[];
  customerDataMerchant:any=[];
  customerTypeData:any=[];
  productId: number;
  updatedData: any;
  viewData: any;
  categoriesData:any=[];
  unitMesauereData:any=[];
  selectedProduct:any;
  selectedMeasurementUnit:any;
  currentPage: number = 1;
  limit: number = 50;
  offset: number = 0;
  total: number = 0; 
  public validate = false;
  public validateUpdate = false;

  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));

    this.addProduct = new FormGroup({
      product_name_en: new FormControl('', Validators.required),
      product_name_ar: new FormControl('', Validators.required),
      product_name_he: new FormControl('', Validators.required),
      des_ar: new FormControl('', Validators.required),
      des_en: new FormControl('', Validators.required),
      des_he: new FormControl('', Validators.required),
      product_code: new FormControl('', Validators.required),
      price: new FormControl(0, Validators.required),  // Set default value to 0
      measurement_unit_id: new FormControl('', Validators.required),
      categories_id: new FormControl('', Validators.required),
      merchant_id: new FormControl(this.merchantId),
      created_by: new FormControl(this.userRegistId),
    });

    this.updateProduct=new FormGroup({
      product_name_en :new FormControl('' , Validators.required),
      product_name_ar:new FormControl('' , Validators.required),
      product_name_he :new FormControl('' , Validators.required),  
      des_ar :new FormControl('' , Validators.required), 
      des_en :new FormControl('' , Validators.required), 
      des_he :new FormControl('' , Validators.required), 
      product_code :new FormControl('' , Validators.required),
      price :new FormControl('' , Validators.required),  
      measurement_unit_id :new FormControl('' , Validators.required),  
      categories_id :new FormControl('' , Validators.required),  
      merchant_id :new FormControl(this.merchantId),   
    updated_by:new FormControl(this.userRegistId),
      });
  };

  toggleCollapse(index: number): void {
    // إذا كان القسم المفتوح هو القسم الذي نضغط عليه، إغلاقه، وإلا فتح القسم الجديد
    this.openedIndex = this.openedIndex === index ? null : index;
     
    
  };


  onPageChange(page: number) {
    this.currentPage = page;
    const offset = (page - 1) * this.limit;
    this.getAllProductsBasedOnMerchant(this.limit, offset);
  }
  openXl(content:any) {
    this.modalService.open(content, { size: 'xl' , centered:true });
  };

  getAllProductsBasedOnMerchant(limit?: number, offset?: number) {
    limit = limit || this.limit;
    offset = offset || (this.currentPage - 1) * this.limit;
    this.spinner.show();
    this.service.getAllProductsBasedOnMerchant(limit, offset).subscribe({
      next: res => {
        this.productsData = res.products;
        this.total = res.count;
         
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  getAllCustomerType() {
    this.spinner.show();
    this.service.getAllCustomerType().subscribe({
      next: res => {
        this.customerTypeData = res;
         
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  getAllActiveCategories() {
    this.spinner.show();
    this.service.getAllActiveCategories().subscribe({
      next: res => {
        this.categoriesData = res;
         
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  getAllActiveMeasurementUnits() {
    this.spinner.show();
    this.service.getAllActiveMeasurementUnits().subscribe({
      next: res => {
        this.unitMesauereData = res;
         this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  addedData() {
    const product_name_he = String(this.addProduct.value.product_name_he)?.trim() || '';

    if (product_name_he === '') {
        Swal.fire({
            icon: 'warning',
            title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة وعدم تركها فارغة أو تحتوي على مسافات فقط' : 
                   (this.curentLang === 'en' ? 'Please fill out the required fields and do not leave them empty or containing only spaces' : 
                   (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים ואל תשאיר אותם ריקים או המכילים רק רווחים' : ''))
        });

        this.addProduct.markAllAsTouched();
        this.validate = !this.validate;
        return;
    }

    // تعيين قيمة product_name_he إلى الحقول الأخرى
    const productNameHe = this.addProduct.value.product_name_he;
    const descriptionNameHe = this.addProduct.value.des_he;
    this.addProduct.patchValue({
        product_name_ar: productNameHe,
        product_name_en: productNameHe,
        des_ar: descriptionNameHe,
        des_en: descriptionNameHe,
    });

    let model = {
        product_name_en: this.addProduct.value.product_name_en,
        product_name_ar: this.addProduct.value.product_name_ar,
        product_name_he: this.addProduct.value.product_name_he,
        product_code: this.addProduct.value.product_code || '',
        price: this.addProduct.value.price || 0,
        des_ar: this.addProduct.value.des_ar || '',
        des_en: this.addProduct.value.des_en || '',
        des_he: this.addProduct.value.des_he || '',
        measurement_unit_id: this.addProduct.value.measurement_unit_id || null,
        categories_id: this.addProduct.value.categories_id || null,
        merchant_id: Number(this.merchantId),
        created_by: Number(this.userRegistId),
    };

    this.spinner.show();
    this.service.Post(AddProductEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
            Swal.fire({
                icon: 'success',
                title: this.curentLang === 'ar' ? res.messageAr : 
                       (this.curentLang === 'en' ? res.messageEn : res.messageHe)
            });
            this.modalService.dismissAll();
            this.getAllActiveCategories();
            this.getAllCustomerType();
            this.getAllActiveMeasurementUnits();
            this.getAllProductsBasedOnMerchant();

            this.addProduct.reset();
            this.addProduct.patchValue({
                product_name_en: '',
                product_name_ar: '',
                product_name_he: '',
                des_ar: '',
                des_en: '',
                des_he: '',
                product_code: '',
                price: 0,
                measurement_unit_id: null,
                categories_id: null,
                merchant_id: this.merchantId,
                created_by: this.userRegistId,
            });
            this.validate = false; // Reset validation state
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
            let errorMessage;
            switch (res.status) {
                case 400:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                   (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                case 403:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                   (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                case 500:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                   (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                default:
                    errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : 
                                   (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
            }
            Swal.fire({
                icon: 'error',
                title: errorMessage
            });
        }
        this.spinner.hide();
    });
};

  changeStatus(id:number, product_status:any){
    this.spinner.show();
  
    let model =
    {
      "product_status": product_status == 1 ? 59 : 1,
     "updated_by": this.userRegistId
    }
  
    this.service.changeProductStatus(id , model ).subscribe(({
      
      next:res =>{
       if (res.status ==200){

        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
        })
          this.getAllProductsBasedOnMerchant();
          this.spinner.hide();
       }
  
      },
      error:err =>{
        Swal.fire({
          icon:"error",
          title:err.error.message[0]
        });
        this.spinner.hide();
  
      }
      
    }))
  };

  getId(id: number) {
    this.productId = id;
    
  };

  filter(event:any)
  {
      this.selectedProduct = event;
     
   };

   filterMeasurementUnit(event:any)
  {
      this.selectedMeasurementUnit = event;
     
   };

  setValue(productId: number) {
    for (let i = 0; i < this.productsData.length; i++) {
      if (this.productsData[i].product_id == this.productId) {
        const selectedCategory = this.productsData.find(item => item.product_id === productId);

        this.updateProduct.controls.product_name_en.setValue(this.productsData[i].product_name_en);
        this.updateProduct.controls.product_name_ar.setValue(this.productsData[i].product_name_ar);
        this.updateProduct.controls.product_name_he.setValue(this.productsData[i].product_name_he);
        this.updateProduct.controls.product_code.setValue(this.productsData[i].product_code);
        this.updateProduct.controls.price.setValue(this.productsData[i].price);
        this.updateProduct.controls.des_ar.setValue(this.productsData[i].des_ar);
        this.updateProduct.controls.des_en.setValue(this.productsData[i].des_en);
        this.updateProduct.controls.des_he.setValue(this.productsData[i].des_he);
        this.updateProduct.controls.measurement_unit_id.setValue(this.productsData[i].measurement_unit_id);
        this.updateProduct.controls.categories_id.setValue(this.productsData[i].categories_id);
      }
    };
  };

  executeFunctions(ProductID: number, uploadData: any) {
    this.updatedData = uploadData;
    this.getId(ProductID);
    this.setValue(ProductID);
    this.updateMd(this.updatedData);
  };

  executeFunctionsView(ProductID: number, view: any) {
    this.viewData = view;
    this.getId(ProductID);
    this.setValue(ProductID);
    this.updateViewMd(this.viewData);
  };
  
  updateViewMd(paymentsUpdated: any) {
    this.modalService.open(paymentsUpdated, { size: 'xl' , centered:true});
  };

  updateMd(paymentsUpdated: any) {
    this.modalService.open(paymentsUpdated, { size: 'xl' , centered:true});
  };

 

  updateProductsById() {

     // // تعيين قيمة product_name_he و des_he إلى الحقول الأخرى
    // const productNameHe = this.updateProduct.value.product_name_he;
    // const descriptionNameHe = this.updateProduct.value.des_he;
    // this.updateProduct.patchValue({
    //   product_name_ar: productNameHe,
    //   product_name_en: productNameHe,
    //   des_ar: descriptionNameHe,
    //   des_en: descriptionNameHe,
    // });

    const product_name_he = String(this.updateProduct.value.product_name_he)?.trim() || '';
    const product_code = String(this.updateProduct.value.product_code)?.trim() || '';
    const price = Number(this.updateProduct.value.price) || 0;
    const des_he = String(this.updateProduct.value.des_he)?.trim() || '';
    const measurement_unit_id = Number(this.updateProduct.value.measurement_unit_id) || 0;
    const categories_id = Number(this.updateProduct.value.categories_id) || 0;

    if (
        product_name_he === '' ||
        product_code === '' ||
        price === 0 ||
        des_he === '' ||
        measurement_unit_id === 0 ||
        categories_id === 0
    ) {
        Swal.fire({
            icon: 'warning',
            title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة وعدم تركها فارغة أو تحتوي على مسافات فقط' : 
                   (this.curentLang === 'en' ? 'Please fill out the required fields and do not leave them empty or containing only spaces' : 
                   (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים ואל תשאיר אותם ריקים או המכילים רק רווחים' : ''))
        });

        this.updateProduct.markAllAsTouched();
        this.validateUpdate = !this.validateUpdate;
        return;
    }

    // تعيين قيمة product_name_he و des_he إلى الحقول الأخرى
    const productNameHe = this.updateProduct.value.product_name_he;
    const descriptionNameHe = this.updateProduct.value.des_he;
    this.updateProduct.patchValue({
        product_name_ar: productNameHe,
        product_name_en: productNameHe,
        des_ar: descriptionNameHe,
        des_en: descriptionNameHe,
    });

    let model = {
        product_name_en: this.updateProduct.value.product_name_en,
        product_name_ar: this.updateProduct.value.product_name_ar,
        product_name_he: this.updateProduct.value.product_name_he,
        product_code: this.updateProduct.value.product_code,
        price: price,
        des_ar: this.updateProduct.value.des_ar,
        des_en: this.updateProduct.value.des_en,
        des_he: this.updateProduct.value.des_he,
        measurement_unit_id: measurement_unit_id,
        categories_id: categories_id,
        merchant_id: Number(this.merchantId),
        updated_by: Number(this.userRegistId),
    };

 
    this.spinner.show();
    this.service.updateProductsById(this.productId, model).subscribe((res) => {
        if (res.status === 200) {
            Swal.fire({
                icon: 'success',
                title: this.curentLang === 'ar' ? res.messageAr : 
                       (this.curentLang === 'en' ? res.messageEn : res.messageHe)
            });
            this.modalService.dismissAll();
            this.getAllCustomerType();
            this.getAllActiveCategories();
            this.getAllProductsBasedOnMerchant();
            this.getAllActiveMeasurementUnits();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
            let errorMessage;
            switch (res.status) {
                case 400:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                   (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                case 403:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                   (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                case 500:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                   (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                default:
                    errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : 
                                   (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
            }
            Swal.fire({
                icon: 'error',
                title: errorMessage
            });
        }
        this.spinner.hide();
    });
}

  

  openVerticallyCentered(dataDeleted:any) {
    this.modalService.open(dataDeleted, { centered: true });
  };
  
  deleteProductById() {
    this.spinner.show();
    this.service.deleteProductById(this.productId).subscribe({
      next: (res) => {
        if (res.status == 400) {
          Swal.fire({
            icon: 'error',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.spinner.hide();
          return;
        } else {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.getAllCustomerType();
          this.getAllProductsBasedOnMerchant();
          this.modalService.dismissAll();
          this.spinner.hide();
        }
      },
      error: (err) => {
        Swal.fire(err.messageAr);
        this.spinner.hide(); 
      }
    });
  };

  ngOnInit() 
  {
  this.getAllProductsBasedOnMerchant();
  this.getAllCustomerType();
  this.getAllActiveCategories();
  this.getAllActiveMeasurementUnits();
  this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) 
   {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   }
    else 
    {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only")
     {
       document.body.classList.add("dark-only");
     } 
     else
     {
       document.body.classList.add("light-only");
     }
   };
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));
  };
}
