import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Company, CompanyEvent } from '../alldocuments-companies.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompaniesService } from '../companies.service';

@Component({
  selector: 'app-alldocuments-companies-mobile',
  templateUrl: './alldocuments-companies-mobile.component.html',
  styleUrls: ['./alldocuments-companies-mobile.component.scss']
})
export class AlldocumentsCompaniesMobileComponent implements OnInit, OnDestroy {
  @Input() selectedCompanyId: string | null = null;
  @Input() placeholder: string = 'allDocuments.filters.companies.placeholder';
  @Input() currentLang: string = 'en';
  @Input() loading: boolean = false;

  @Output() companyChange = new EventEmitter<CompanyEvent>();

  companies: Company[] = [];
  selectedCompany: Company | null = null;
  private destroy$ = new Subject<void>();
  showAllCompanies: boolean = false;

  constructor(private companiesService: CompaniesService) { }

  ngOnInit(): void {
    // Subscribe to loading state
    this.companiesService.loading$
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => this.loading = loading);

    // Get companies without passing language - service handles it internally
    this.companiesService.getCompanies()
      .pipe(takeUntil(this.destroy$))
      .subscribe(companies => {
        this.companies = companies;

        // If we have a selectedCompanyId, find and select the matching company
        if (this.selectedCompanyId) {
          this.selectedCompany = this.companies.find(c => c.value === this.selectedCompanyId) || null;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleShowAllCompanies(): void {
    this.showAllCompanies = !this.showAllCompanies;
  }


  onCompanyChange(event: any): void {
    this.selectedCompany = event;
    this.companyChange.emit({
      value: event.value,
      originalEvent: undefined // No original event in mobile version
    });
  }
}
