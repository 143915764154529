import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { AlldocumentsComponent } from './alldocuments.component';
import { AlldocumentsActionsComponent } from './alldocuments-actions/alldocuments-actions.component';
import { AlldocumentsSearchComponent } from './alldocuments-search/alldocuments-search.component';
import { AlldocumentsCustomersComponent } from './alldocuments-customers/alldocuments-customers.component';
import { AlldocumentsCustomersMobileComponent } from './alldocuments-customers/alldocuments-customers-mobile/alldocuments-customers-mobile.component';
import { AlldocumentsCompaniesComponent } from './alldocuments-companies/alldocuments-companies.component';
import { AlldocumentsCompaniesMobileComponent } from './alldocuments-companies/alldocuments-companies-mobile/alldocuments-companies-mobile.component';
import { AlldocumentsTypesComponent } from './alldocuments-types/alldocuments-types.component';
import { AlldocumentsTypesMobileComponent } from './alldocuments-types/alldocuments-types-mobile/alldocuments-types-mobile.component';
import { AlldocumentsPeriodComponent } from './alldocuments-period/alldocuments-period.component';
import { AlldocumentsPeriodMobileComponent } from './alldocuments-period/alldocuments-period-mobile/alldocuments-period-mobile.component';
import { TimePeriodService } from './alldocuments-period/alldocuments-period.service';
import { AlldocumentsStatusesComponent } from './alldocuments-statuses/alldocuments-statuses.component';
import { AlldocumentsViewPdfModalModule } from './alldocuments-view-pdf-modal/alldocuments-view-pdf-modal.module';

import { RippleModule } from 'primeng/ripple';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { SkeletonModule } from 'primeng/skeleton';
import { MultiSelectModule } from 'primeng/multiselect';
import { MenuModule } from 'primeng/menu';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { BadgeModule } from 'primeng/badge';
import { SpeedDialModule } from 'primeng/speeddial';
import { SidebarModule } from 'primeng/sidebar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { BlockUIModule } from 'primeng/blockui';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';
import { CustomersService } from './alldocuments-customers/customers.service';
import { CompaniesService } from './alldocuments-companies/companies.service';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RippleModule,
        BreadcrumbModule,
        CardModule,
        ButtonModule,
        InputTextModule,
        DropdownModule,
        CalendarModule,
        SelectButtonModule,
        TableModule,
        SkeletonModule,
        MultiSelectModule,
        MenuModule,
        TieredMenuModule,
        ToastModule,
        BadgeModule,
        SpeedDialModule,
        SidebarModule,
        RadioButtonModule,
        ToggleButtonModule,
        OverlayPanelModule,
        NgbModule,
        ProgressSpinnerModule,
        ConfirmDialogModule,
        BlockUIModule,
        DialogModule,
        InputMaskModule,
        AlldocumentsViewPdfModalModule
    ],
    exports: [
        SelectButtonModule,
        AlldocumentsComponent
    ],
    declarations: [
        AlldocumentsComponent,
        AlldocumentsActionsComponent,
        AlldocumentsSearchComponent,
        AlldocumentsCustomersComponent,
        AlldocumentsCustomersMobileComponent,
        AlldocumentsCompaniesComponent,
        AlldocumentsCompaniesMobileComponent,
        AlldocumentsTypesComponent,
        AlldocumentsTypesMobileComponent,
        AlldocumentsPeriodComponent,
        AlldocumentsPeriodMobileComponent,
        AlldocumentsStatusesComponent
    ],
    bootstrap: [AlldocumentsComponent],
    providers: [
        MessageService,
        CustomersService,
        CompaniesService,
        TimePeriodService
    ]
})
export class AlldocumentsModule {}