import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TimePeriodEvent, TimePeriodOption } from './alldocuments-period.model';
import { TimePeriodService } from './alldocuments-period.service';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-alldocuments-period',
  templateUrl: './alldocuments-period.component.html',
  styleUrls: ['./alldocuments-period.component.scss']
})
export class AlldocumentsPeriodComponent implements OnInit {
  @Input() selectedTimePeriod: Date[] | null = null;
  @Output() timePeriodChange = new EventEmitter<TimePeriodEvent>();

  @ViewChild('calendar') calendar: any;

  timePeriodOptions: TimePeriodOption[] = [];
  translatedTimePeriodOptions: any[];

  constructor(
    private timePeriodService: TimePeriodService,
    private translate: TranslateService,
    private primengConfig: PrimeNGConfig
  ) {}

  ngOnInit(): void {
    this.timePeriodOptions = this.timePeriodService.getTimePeriodOptions();
    this.translateTimePeriodOptions();
  }

  translateTimePeriodOptions(): void {
    this.translatedTimePeriodOptions = this.timePeriodOptions.map(option => ({
      ...option,
      label: this.translate.instant(option.label)
    }));
  }

  onTimePeriodSelect(option: TimePeriodOption): void {
    const dates = this.timePeriodService.getTimePeriodDates(option);
    
    // Reset the selected state of all options
    this.timePeriodOptions.forEach(opt => opt.selected = false);
    
    // Mark the current option as selected
    option.selected = true;
    
    // Emit the selected time period
    this.timePeriodChange.emit({
      value: dates,
      originalEvent: undefined
    });
  }

  applyTimePeriod(): void {
    // Only emit if we have a complete date range (both start and end dates)
    if (this.selectedTimePeriod && this.selectedTimePeriod.length === 2) {
      if (this.calendar) {
        this.calendar.hideOverlay();
      }

      this.timePeriodChange.emit({
        value: this.selectedTimePeriod,
        originalEvent: undefined
      });
    }
  }

  // Add a new method to handle calendar selection events
  onCalendarSelect(event: any): void {
  // We don't auto-apply when selecting dates - user must click Apply button
  // This prevents applying incomplete ranges
  }
}
