<div class="cart-box" (click)="toggleCart()">
  <ng-container *ngIf="selectedCompanyLogo; else defaultIcon">
    <img [src]="selectedCompanyLogo"  alt="logo" style="width: 25px; height: 25px; border-radius: 50%; object-fit: contain;" />
  </ng-container>
  <ng-template #defaultIcon>
    <i class="fa fa-building" style="font-size: 25px; color: gray;"></i>
  </ng-template>
  <span class="badge rounded-pill badge-danger">{{allActivecompanyData?.length}}</span>
</div>

<div class="cart-dropdown onhover-show-div" [class.active]="openCart">
  <h6 class="f-18 mb-0 dropdown-title" style="color: black;">
    {{ curentLang === 'en' ? 'Companies' : (curentLang === 'ar' ? 'الشركات' : 'חברות') }}
  </h6>
  <ul>
    <li>
      <div class="media" *ngFor="let item of allActivecompanyData" 
           (click)="getCompanyId(item.company_id);setCompanyDefault()" 
           [ngClass]="{'active-company': item.company_id === selectedCompanyId}">
        <img class="img-fluid img-40 m-2" [src]="item.logo_url"   />
        <div class="media-body">
          <span>
            {{ curentLang === 'en' ? item.company_name_en : (curentLang === 'ar' ? item.company_name_ar : item.company_name_he) }}
          </span>
        </div>
      </div>
    </li>
  </ul>
</div>
