import { DocumentType } from './alldocuments-types.model';

export const DOCUMENT_TYPES: DocumentType[] = [
  { code: '305', label: 'allDocuments.documentTypes.taxInvoice', icon: 'pi pi-file', routerLink: '/dashboard/add-invoice' },
  { code: '320', label: 'allDocuments.documentTypes.invoiceWithReceipt', icon: 'pi pi-file-excel', routerLink: '/dashboard/add-invoice-with-receipt' },
  { code: '332', label: 'allDocuments.documentTypes.financialRequest', icon: 'pi pi-dollar', routerLink: '/dashboard/add-finaical-request' },
  { code: '400', label: 'allDocuments.documentTypes.receipt', icon: 'pi pi-book', routerLink: '/dashboard/add-receipts' },
  { code: '330', label: 'allDocuments.documentTypes.reverseInvoice', icon: 'pi pi-undo', routerLink: '/dashboard/add-reverce-invoice' },
  { code: '100', label: 'allDocuments.documentTypes.order', icon: 'pi pi-shopping-cart', routerLink: '/dashboard/add-order' },
  { code: '200', label: 'allDocuments.documentTypes.mission', icon: 'pi pi-briefcase', routerLink: '/dashboard/add-mission' },
  { code: '000', label: 'allDocuments.documentTypes.offerPrice', icon: 'pi pi-tag', routerLink: '/dashboard/add-offer-price' }
];
