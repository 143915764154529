import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Customer, CustomerEvent } from '../alldocuments-customers.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomersService } from '../customers.service';

@Component({
  selector: 'app-alldocuments-customers-mobile',
  templateUrl: './alldocuments-customers-mobile.component.html',
  styleUrls: ['./alldocuments-customers-mobile.component.scss']
})
export class AlldocumentsCustomersMobileComponent implements OnInit, OnDestroy {
  @Input() selectedCustomerId: string | null = null;
  @Input() placeholder: string = 'allDocuments.filters.customers.placeholder';
  @Input() currentLang: string = 'en';
  @Input() loading: boolean = false;

  @Output() customerChange = new EventEmitter<CustomerEvent>();

  customers: Customer[] = [];
  selectedCustomer: Customer | null = null;
  private destroy$ = new Subject<void>();
  showAllCustomers: boolean = false;

  constructor(private customersService: CustomersService) { }

  ngOnInit(): void {
    // Subscribe to loading state directly
    this.customersService.loading$
      .pipe(takeUntil(this.destroy$))
      .subscribe(loading => this.loading = loading);

    // Get customers without parameters - service handles language internally
    this.customersService.getCustomers()
      .pipe(takeUntil(this.destroy$))
      .subscribe(customers => {
        this.customers = customers;

        // If we have a selectedCustomerId, find and select the matching customer
        if (this.selectedCustomerId) {
          this.selectedCustomer = this.customers.find(c => c.value === this.selectedCustomerId) || null;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleShowAllCustomers(): void {
    this.showAllCustomers = !this.showAllCustomers;
  }

  onCustomerChange(event: any): void {
    this.selectedCustomer = event;
    this.customerChange.emit({
      value: event.value,
      originalEvent: undefined // No original event in mobile version
    });
  }
}
