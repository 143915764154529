<ng-container *ngIf="!isInsideModal">

<app-breadcrumb [title]="curentLang === 'en' ? 'Products Info' : (curentLang === 'ar' ? ' معلومات المنتجات  ' : '   פרטי מוצרים')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['הגדרות   '])" 
[active_item]="curentLang === 'en' ? 'Products Info' : (curentLang === 'ar' ? ' معلومات المنتجات  ' : '    פרטי מוצרים  ')">
</app-breadcrumb>
</ng-container>
<!--products info -->

<ng-container *ngIf="!isInsideModal">

<div class="container-fluid basic_table" >
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Products Info' : (curentLang === 'ar' ? '  معلومات المنتجات ' : ' פרטי מוצרים')}}
        </h5>

       <div>
        <button [hidden]="user_type == 200" class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
            <i class="fa fa-plus-circle mx-1"></i>
          {{ curentLang === 'en' ? 'Add Product' : (curentLang === 'ar' ? '  اضافة منتج' : 'הוספת מוצר')}}
        </button>

        

        <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getAllProductsBasedOnMerchant()">
          <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענון    ')}}
      </button>

         </div>
     
      </div>
      <div class="row">
        <div class="col-sm-5">
            <div class="form-group search m-3">
              <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                 <input class="form-control  w-100" type="text" name="search"
                  [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
            </div>
        </div>
    </div>
      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Product Name ' : (curentLang === 'ar' ? '  أسم المنتج   ' : 'שם מוצר')}} </th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Product Description ' : (curentLang === 'ar' ? '   وصف المنتج  ' : 'תיאור המוצר')}}</th>

                   <th scope="col"> {{ curentLang === 'en' ? 'Product Code' : (curentLang === 'ar' ? '   رقم الصنف  ' : '  מספר פרטי ')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Product Price' : (curentLang === 'ar' ? '   سعر المنتج  ' : 'מחיר')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Product Category' : (curentLang === 'ar' ? '   قسم المنتج  ' : 'קטגוריה')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Product Measurement Unit' : (curentLang === 'ar' ? '   وحدة قياس المنتج  ' : 'יחידת מדידה')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטטוס ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : 'תאריך הרשמה')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'View' : (curentLang === 'ar' ? '   عرض' : '  הצגה   ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  עריכה ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : 'מחיקה')}}</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of productsData| filter:term | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total}; let i = index">
                  <td scope="row">{{i+1}}</td>
                  <td> 
                    {{curentLang === 'en' ? item.product_name_en.slice(0, 20) : (curentLang === 'ar' ? item.product_name_ar.slice(0, 20) : item.product_name_he.slice(0, 20))}}
                  </td>

                  <td> 
                    {{curentLang === 'en' ? item.des_en.slice(0, 20) : (curentLang === 'ar' ? item.des_ar.slice(0, 20) : item.des_he.slice(0, 20))}}
                  </td>

                   <td>{{item.product_code}}</td>
                   <td>{{item.price}}</td>
                   <td>{{curentLang === 'en' ? item.categories?.categories_name_en : (curentLang === 'ar' ? item.categories?.categories_name_ar : item.categories?.categories_name_he)}}</td>
                   <td>{{curentLang === 'en' ? item.measurementUnits?.measurement_unit_name_en : (curentLang === 'ar' ? item.measurementUnits?.measurement_unit_name_ar : item.measurementUnits?.measurement_unit_name_he)}}</td>

                   <td>
                    <button  *ngIf="item.product_status==1" class=" btn" style="border: none;" (click)="changeStatus(item?.product_id, item.product_status)">
                        <i class="fa solid fa-solid fa-toggle-on " style=" color: #213FA6;" ></i>
                    </button> 

                    <button  *ngIf="item.product_status==59" class=" btn"  style="border: none;"(click)="changeStatus(item?.product_id, item.product_status)">
                        <i class="fa solid fa-solid fa-toggle-off " style=" color: red;"></i>
                    </button> 

                  </td>

                   <td>{{ item.createdDate | date: 'dd/MM/yyyy'}}</td>

                  <td>
                    <button class=" btn" style="border: none;" (click)="executeFunctionsView(  item?.product_id,viewData ) ">
                        <i class="fa fa-eye" style="color: grey;   cursor: pointer;"></i>
                    </button>
                  </td> 

                  <td >
                  <button class=" btn" style="border: none;"
                   (click)="executeFunctions(  item?.product_id,updatedData ) ">
                    <i class="fa fa-edit" style=" cursor: pointer; color: green;">
                   </i>
                  </button>
                   </td>

                   <td >
                    <button 
                    [ngStyle]="{ 'pointer-events': item.product_status == 1 ? 'none' : 'auto', 'color': item.product_status == 1 ? 'gray' : 'red' }" 
                    class="btn" 
                    style="border: none;" 
                    (click)="getId(item?.product_id);openVerticallyCentered(dataDeleted)">
                      <i class="fa fa-trash-o" style=" cursor: pointer;"></i>
                      </button>
            
                     </td>

                </tr>
          
              </tbody>
            </table>
            <div class="pagination-container">
              <pagination-controls 
                (pageChange)="onPageChange($event)" 
                (totalItems)="total" 
                (currentPage)="currentPage">
              </pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
</div>

</ng-container>
<!--add product-->

<ng-template #content let-modal>
  <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title" style="color: grey;">
          {{ curentLang === 'en' ? 'Add Product ' : (curentLang === 'ar' ? ' اضافة منتج ' : 'הוספת מוצר')}} 
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="container-fluid">
          <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                  <form [formGroup]="addProduct" novalidate [ngClass]="{ 'was-validated': validate }">
                      <div class="row">
                          <div class="mb-3 col-xl-4 col-md-4">
                              <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Product Name ' : (curentLang === 'ar' ? '  اسم المنتج    ' : ' שם מוצר ')}}
                                  <span class="text-danger">*</span>
                              </label>
                              <input required style="color: grey;" class="form-control" onlyAlphabets
                                  type="text" formControlName="product_name_he" maxlength="80" />
                              <div class="invalid-feedback text-danger">
                                  {{ curentLang === 'en' ? 'please enter product name' : (curentLang === 'ar' ? '   الرجاء ادخال أسم المنتج ' : ' נא להזין את שם המוצר ')}}  
                              </div>
                          </div>

                          <div class="mb-3 col-xl-4 col-md-4">
                              <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Product Code' : (curentLang === 'ar' ? ' رقم الصنف ' : ' קוד מוצר   ')}}
                              </label>
                              <input style="color: grey;" onlyAlphabets
                                  class="form-control" type="text" formControlName="product_code" maxlength="20" />
                          </div>

                          <div class="mb-3 col-xl-4 col-md-4">
                              <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Product Price' : (curentLang === 'ar' ? '    سعر المنتج ' : '   מחיר')}}
                              </label>
                              <input style="color: grey;" onlyAlphabets
                                  class="form-control" type="number" formControlName="price" maxlength="20" />
                          </div>

                          <div class="mb-3 col-xl-6 col-md-6">
                              <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Product Category' : (curentLang === 'ar' ? '    قسم المنتج ' : ' קטגוריה')}}
                              </label>
                              <ng-select formControlName="categories_id" [(ngModel)]="selectedProduct"
                                  (change)="filter(selectedProduct)" style="cursor: pointer;"
                                  placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                  <ng-option *ngFor="let item of categoriesData; let i = index"
                                      [value]="item?.categories_id">
                                      {{ curentLang === 'en' ?  item?.categories_name_en :(curentLang === 'ar' ? item?.categories_name_ar  :  item?.categories_name_he )}}
                                  </ng-option>
                              </ng-select>
                          </div>

                          <div class="mb-3 col-xl-6 col-md-6">
                              <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Measurement Unit Product' : (curentLang === 'ar' ? '  وحدة قياس المنتج ' : 'יחידת מדידה')}}
                              </label>
                              <ng-select formControlName="measurement_unit_id" [(ngModel)]="selectedMeasurementUnit"
                                  (change)="filterMeasurementUnit(selectedMeasurementUnit)" style="cursor: pointer;"
                                  placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                  <ng-option *ngFor="let item of unitMesauereData; let i = index"
                                      [value]="item?.measurement_unit_id">
                                      {{ curentLang === 'en' ?  item?.measurement_unit_name_en :(curentLang === 'ar' ? item?.measurement_unit_name_ar  :  item?.measurement_unit_name_he )}}
                                  </ng-option>
                              </ng-select>
                          </div>

                          <div class="mb-3 col-xl-12 col-md-12">
                              <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Description ' : (curentLang === 'ar' ? '  وصف المنتج   ' : '   תיאור מוצר בעברית    ')}} 
                              </label>
                              <textarea maxlength="600" name=""
                                  id="des_he" cols="10" rows="5" type="text"
                                  class="form-control form-control-sm"
                                  formControlName="des_he">
                              </textarea>
                          </div>
                      </div>

                      <div class="modal-footer">
                          <button type="button" class="btn btn-sm m-auto mt-2" 
                              style="background-color: #141414; color: #fff;"
                              (click)="addedData()"> 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>

                          <button type="button" class="btn btn-sm m-auto mt-2 btn-danger"
                              (click)="modal.close('Close click')">
                              {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                              <i class="fa fa-rotate-left mx-1"></i>
                          </button>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
</ng-template>


<!--update product-->

<ng-template #updatedData let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
                {{ curentLang === 'en' ? 'Update Info ' : (curentLang === 'ar' ? ' تعديل المعلومات ' : '    עריכה   ')}} 
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]="updateProduct"  novalidate [ngClass]="{ 'was-validated': validateUpdate }">
                        <div class="row">
                             <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Product Name Ar' : (curentLang === 'ar' ? '  اسم المنتج عربي   ' : '  שם המוצר בערבית  ')}}
                                  <span class="text-danger">*</span>
                               </label>
                                <input style="color: grey;"  onlyAlphabets required=""
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="product_name_ar" 
                                  />
                                  <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter product name' : (curentLang === 'ar' ? '   الرجاء ادخال أسم المنتج ' : ' נא להזין את שם המוצר      ')}}  </div>

                              </div> 
              
                               <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Product Name En' : (curentLang === 'ar' ? '  اسم المنتج انجليزي   ' : '   שם המוצר באנגלית  ')}}
                                 </label>
                                  <input style="color: grey;"   onlyAlphabets required=""
                                  class="form-control"
                                   type="text"
                                   maxlength="80"
                                    formControlName="product_name_en"
                                   />
                                   <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter product name' : (curentLang === 'ar' ? '   الرجاء ادخال أسم المنتج ' : ' נא להזין את שם המוצר      ')}}  </div>

                                </div> 
              
                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Product Name He' : (curentLang === 'ar' ? '  اسم المنتج عبري   ' : '  שם בעברית  ')}}
                                    <span class="text-danger">*</span>
                                 </label>
                                  <input style="color: grey;" class="form-control"  onlyAlphabets required=""
                                   type="text" 
                                   formControlName="product_name_he" 
                                   maxlength="80"
                                  />
                                  <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter product name' : (curentLang === 'ar' ? '   الرجاء ادخال أسم المنتج ' : ' נא להזין את שם המוצר      ')}}  </div>

                                </div>
              
                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Product Code' : (curentLang === 'ar' ? ' رقم الصنف ' : ' קוד מוצר   ')}}
                                    <span class="text-danger">*</span>
                                 </label>
                                  <input style="color: grey;"   onlyAlphabets required=""
                                  class="form-control" type="text" 
                                   formControlName="product_code"
                                   maxlength="20"
                                     />
                                     <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter product code' : (curentLang === 'ar' ? '   الرجاء ادخال رقم الصنف ' : ' נא להזין את קוד המוצר  ')}}  </div>

                                </div>

                                <div class="mb-3 col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Product Price' : (curentLang === 'ar' ? ' سعر المنتج ' : 'מחיר')}}
                                      <span class="text-danger">*</span>
                                   </label>
                                    <input style="color: grey;"   onlyAlphabets required=""
                                    class="form-control" type="text" 
                                     formControlName="price"
                                     maxlength="20"
                                       />
                                       <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter product price' : (curentLang === 'ar' ? '   الرجاء ادخال سعر المنتج ' : ' נא להזין את מחיר המוצר ')}}  </div>

                                  </div>

                                  <div class="mb-3 col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Product Category' : (curentLang === 'ar' ? '    قسم المنتج ' : ' קטגוריה')}}
                                        <span class="text-danger">*</span>
                                     </label>
     

                                   <ng-select formControlName="categories_id" (change)="filter($event)" onlyAlphabets required=""
                                   (change)="filter(selectedProduct)" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                   <ng-option *ngFor="let item of categoriesData; let i = index " [value]="item?.categories_id">
                                    {{ curentLang === 'en' ?  item?.categories_name_en :(curentLang === 'ar' ? item?.categories_name_ar  :  item?.categories_name_he )}}
                                  </ng-option>
                                  </ng-select>
                                  <div *ngIf="updateProduct.get('categories_id')?.errors?.required" class="text-danger">
                                    <p>{{ curentLang === 'en' ? 'please chooce category' : (curentLang === 'ar' ? '   الرجاء اختيار القسم ' : 'אנא בחר מחלקה')}} </p>  
                                    </div>
                                  </div>
                                         
                                  <div class="mb-3 col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Measurement Unit Product' : (curentLang === 'ar' ? '  وحدة قياس المنتج ' : ' יחידת מדידה')}}
                                        <span class="text-danger">*</span>
                                     </label>
                                  

                                   <ng-select formControlName="measurement_unit_id" (change)="filterMeasurementUnit($event)"  onlyAlphabets required=""
                                   (change)="filterMeasurementUnitz(selectedMeasurementUnit)" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                    <ng-option *ngFor="let item of unitMesauereData; let i = index " [value]="item?.measurement_unit_id">
                                      {{ curentLang === 'en' ?  item?.measurement_unit_name_en :(curentLang === 'ar' ? item?.measurement_unit_name_ar  :  item?.measurement_unit_name_he )}}
                                    </ng-option>
                                  </ng-select>
                                  <div *ngIf="updateProduct.get('measurement_unit_id')?.errors?.required" class="text-danger">
                                    <p>{{ curentLang === 'en' ? 'please chooce measurement unit name' : (curentLang === 'ar' ? '   الرجاء اختيار وحدة قياس المنتج  ' : '  אנא בחר יחידת מדידה של מוצר  ')}} </p>  
                                    </div>
                                  </div>

                                  <div class="mb-3 col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Description En' : (curentLang === 'ar' ? '  وصف المنتج انجليزي  ' : '   תיאור המוצר באנגלית')}} 
                                        <span class="text-danger">*</span>
                                   </label>
                                        <textarea
                                         maxlength="600" name="" onlyAlphabets required=""
                                          id="des_en" cols="10" rows="5" type="text"
                                          class="form-control form-control-sm"
                                           formControlName="des_en">
                                   </textarea>
                                   <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter product description  ' : (curentLang === 'ar' ? '   الرجاء ادخال وصف المنتج ' : 'נא להזין תיאור מוצר')}}  </div>

                                  </div> 

                                   <div class="mb-3 col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Description Ar' : (curentLang === 'ar' ? '  وصف المنتج عربي  ' : '   תיאור המוצר בערבית    ')}}   
                                        <span class="text-danger">*</span>
                                   </label>
                                        <textarea
                                         maxlength="600" name="" onlyAlphabets required=""
                                          id="des_ar" cols="10" rows="5" type="text"
                                          class="form-control form-control-sm"
                                           formControlName="des_ar">
                                   </textarea>
                                   <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter product description  ' : (curentLang === 'ar' ? '   الرجاء ادخال وصف المنتج ' : 'נא להזין תיאור מוצר')}}  </div>

                                  </div> 
              
                                  <div class="mb-3 col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Description' : (curentLang === 'ar' ? '  وصف المنتج   ' : '   תיאור מוצר בעברית    ')}} 
                                        <span class="text-danger">*</span>
                                   </label>
                                        <textarea
                                         maxlength="600" name="" onlyAlphabets required=""
                                          id="des_he" cols="10" rows="5" type="text"
                                          class="form-control form-control-sm"
                                           formControlName="des_he">
                                   </textarea>
                                   <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter product description  ' : (curentLang === 'ar' ? '   الرجاء ادخال وصف المنتج ' : 'נא להזין תיאור מוצר')}}  </div>

                                  </div>
                        </div>
                  
                    
        
                          <div class="modal-footer">

                            <button type="button"  
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="updateProductsById()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form>
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>

<!--view product-->

<ng-template #viewData let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
                {{ curentLang === 'en' ? 'View Info ' : (curentLang === 'ar' ? ' مشاهدة المعلومات ' : 'צפיה   ')}} 
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]="updateProduct">
                        <div class="row">
                             <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Product Name Ar' : (curentLang === 'ar' ? '  اسم المنتج عربي   ' : '  שם המוצר בערבית  ')}}
                               </label>
                                <input style="color: grey;" readonly
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="product_name_ar" 
                                  />
                              </div> 
              
                             <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Product Name En' : (curentLang === 'ar' ? '  اسم المنتج انجليزي   ' : '   שם המוצר באנגלית  ')}}
                                 </label>
                                  <input style="color: grey;" readonly
                                  class="form-control"
                                   type="text"
                                   maxlength="80"
                                    formControlName="product_name_en"
                                   />
                                </div> 
              
                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Product Name He' : (curentLang === 'ar' ? '  اسم المنتج عبري   ' : 'שם בעברית')}}
                                 </label>
                                  <input style="color: grey;" class="form-control"readonly
                                   type="text" 
                                   formControlName="product_name_he" 
                                   maxlength="80"
                                  />
                                   
                                </div>
              
                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Product Code' : (curentLang === 'ar' ? ' رقم الصنف ' : ' קוד מוצר   ')}}
                                 </label>
                                  <input style="color: grey;" readonly
                                  class="form-control" type="text" 
                                   formControlName="product_code"
                                   maxlength="20"
                                     />
                                </div>

                                <div class="mb-3 col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Product Price' : (curentLang === 'ar' ? ' سعر المنتج ' : 'מחיר')}}
                                   </label>
                                    <input style="color: grey;" readonly
                                    class="form-control" type="text" 
                                     formControlName="price"
                                     maxlength="20"
                                       />
                                  </div>

                                  <div class="mb-3 col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Product Category' : (curentLang === 'ar' ? '    قسم المنتج ' : ' קטגוריה   ')}}
                                     </label>
                                     <ng-select formControlName="categories_id"   (change)="filter($event)" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                      <ng-option *ngFor="let item of categoriesData; let i = index " [value]="item?.categories_id">
                                        {{ curentLang === 'en' ?  item?.categories_name_en :(curentLang === 'ar' ? item?.categories_name_ar  :  item?.categories_name_he )}}
                                      </ng-option>
                                    </ng-select>
                                  </div>
                                         
                                  <div class="mb-3 col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Measurement Unit Product' : (curentLang === 'ar' ? '  وحدة قياس المنتج ' : 'יחידת מדידה')}}
                                     </label>  
                                     
                                     <ng-select formControlName="measurement_unit_id"    (change)="filterMeasurementUnit($event)" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                      <ng-option *ngFor="let item of unitMesauereData; let i = index " [value]="item?.measurement_unit_id">
                                        {{ curentLang === 'en' ?  item?.measurement_unit_name_en :(curentLang === 'ar' ? item?.measurement_unit_name_ar  :  item?.measurement_unit_name_he )}}
                                      </ng-option>
                                    </ng-select>
                                    
                                  </div>

                                   <div class="mb-3 col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Description En' : (curentLang === 'ar' ? '  وصف المنتج انجليزي  ' : '   תיאור המוצר באנגלית')}} 
                                   </label>
                                        <textarea
                                         maxlength="600" name=""readonly
                                          id="des_en" cols="10" rows="5" type="text"
                                          class="form-control form-control-sm"
                                           formControlName="des_en">


                                   </textarea>
                                  </div> 

                                   <div class="mb-3 col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Description Ar' : (curentLang === 'ar' ? '  وصف المنتج عربي  ' : '   תיאור המוצר בערבית    ')}}   
                                   </label>
                                        <textarea
                                         maxlength="600" name=""readonly
                                          id="des_ar" cols="10" rows="5" type="text"
                                          class="form-control form-control-sm"
                                           formControlName="des_ar">
                                   </textarea>
                                  </div> 
              
                                  <div class="mb-3 col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Description  ' : (curentLang === 'ar' ? '  وصف المنتج    ' : 'תיאור מוצר')}} 
                                   </label>
                                        <textarea
                                         maxlength="600" name=""readonly
                                          id="des_he" cols="10" rows="5" type="text"
                                          class="form-control form-control-sm"
                                           formControlName="des_he">
                                   </textarea>
                                  </div>
                        </div>
                  
            
                      </form>
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>


    <!--Delete product-->

    <ng-template #dataDeleted let-modal>
        <div class="modal-header">
            <h4 class="modal-title"></h4>
            <button type="button" class="close btn-close"  style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
              {{curentLang === 'en' ? 'Are you sure you want to delete it?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد حذفه؟ ' : 'האם אתה בטוח שברצונך למחוק אותו?') }}
            </p>
        </div>
        <div class="modal-footer">
  
          <button type="button" 
           class="btn btn-sm m-auto mt-2" 
          style="background-color: #141414; color: #fff;"
           (click)="deleteProductById()"
           > 
            {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
            <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
        </button>
    
            <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                <i class="fa fa-rotate-left mx-1"></i>
            </button>
    
          
    
        </div>
    </ng-template>






    <!--products info -->

<ng-container *ngIf="isInsideModal">

  <div class="container-fluid basic_table" >
      <div class="row">
  <div class="col-sm-12">
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <h5 style="color: grey;"> 
              {{ curentLang === 'en' ? 'Products Info' : (curentLang === 'ar' ? '  معلومات المنتجات ' : ' מידע על מוצרים ')}}
          </h5>
  
         <div>
          <button [hidden]="user_type == 200 || user_type == 201" class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
              <i class="fa fa-plus-circle mx-1"></i>
            {{ curentLang === 'en' ? 'Add Product' : (curentLang === 'ar' ? '  اضافة منتج' : ' הוספת מוצר   ')}}
          </button>
  
          
  
          <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getAllProductsBasedOnMerchant()">
            <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענון    ')}}
        </button>
  
           </div>
       
        </div>
        <div class="row">
          <div class="col-sm-5">
              <div class="form-group search m-3">
                <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                   <input class="form-control  w-100" type="text" name="search"
                    [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
              </div>
          </div>
      </div>


       
      

      <div class="row">
        <div class="col-sm-12">
           
            <div class="card"  id="headingTwo"
             *ngFor="let item of productsData  | paginate: {itemsPerPage:50, currentPage:p } |filter:term ; let i = index ">
            
                    <p   
                    
                    (click)="toggleCollapse(i)" 
                    [attr.aria-expanded]="openedIndex === i" 
                    aria-controls="collapse"
                    style="cursor: pointer; background-color: #FB4438;" 
                    class="py-1">
                      <i class="fa fa-user-circle mx-1"  style="color: #fff; font-size: 16px;" > </i>
                      <span style="color: #fff; font-size: 16px;" class="mx-2">
                        {{curentLang === 'en' ? item.product_name_en.slice(0, 20) : (curentLang === 'ar' ? item.product_name_ar.slice(0, 20) : item.product_name_he.slice(0, 20))}}

                      </span>
                    </p>
              
                     
                    <div class="row d-flex justify-content-between">
        
                      <div class="col-md-12  col-sm-12 ">
                        <div class="row collapse   show" id="collapseTwo" [ngbCollapse]="openedIndex !== i">
        
                       
                        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                            {{ curentLang === 'en' ? 'Product Name' : (curentLang === 'ar' ? ' اسم المنتج ' : ' שם המוצר  ')}}
                          </span>
                            <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" >
                              {{curentLang === 'en' ? item.product_name_en.slice(0, 20) : (curentLang === 'ar' ? item.product_name_ar.slice(0, 20) : item.product_name_he.slice(0, 20))}}
                            </span>
                            <span class="col-sm-4   text-end ">
                              <i class="fa fa-info-circle px-5 "  style="color: #141414; font-size: 16px ;" ></i>
                            </span>
                         </div>
                         <hr style="border: solid 1px #141414;" >
                         <!---->
              
                         <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                            {{ curentLang === 'en' ? 'Product Description ' : (curentLang === 'ar' ? '   وصف المنتج  ' : '    תיאור מוצר  ')}}
                          </span>
                            <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" >
                              {{curentLang === 'en' ? item.des_en.slice(0, 20) : (curentLang === 'ar' ? item.des_ar.slice(0, 20) : item.des_he.slice(0, 20))}}
                            </span>
                            <span class="col-sm-4   text-end ">
                              <i class="fa fa-info-circle px-5 "  style="color: #141414; font-size: 16px ;" ></i>
                            </span>
                         </div>
                         <hr style="border: solid 1px #141414;" >
        
                         <!---->
                         <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                            {{ curentLang === 'en' ? 'Product Code' : (curentLang === 'ar' ? '   رقم الصنف  ' : '  קוד מוצר  ')}}
                          </span>
                            <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" >
                              {{item.product_code}}
                            </span>
                            <span class="col-sm-4   text-end ">
                              <i class="fa fa-info-circle px-5 "  style="color: #141414; font-size: 16px ;" ></i>
                            </span>
                         </div>
                         <hr style="border: solid 1px #141414;" >
        
                           <!---->
        
                           <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                            <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                              {{ curentLang === 'en' ? 'Product Price' : (curentLang === 'ar' ? '   سعر المنتج  ' : '    מחיר מוצר  ')}}
                            </span>
                              <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" >
                                {{item.price}}
                              </span>
                              <span class="col-sm-4   text-end ">
                                <i class="fa fa-money px-5 "  style="color: #141414; font-size: 16px ;" ></i>
                              </span>
                           </div>
                           <hr style="border: solid 1px #141414;" >
                              
                         
                            <!---->
                        
                            <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                              <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                                {{ curentLang === 'en' ? 'Product Category' : (curentLang === 'ar' ? '   قسم المنتج  ' : ' קטגוריה')}}
                              </span>
                                <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" >
                                  {{curentLang === 'en' ? item.categories?.categories_name_en : (curentLang === 'ar' ? item.categories?.categories_name_ar : item.categories?.categories_name_he)}}
                                </span>
                                <span class="col-sm-4   text-end ">
                                  <i class="fa fa-info-circle px-5 "  style="color: #141414; font-size: 16px ;" ></i>
                                </span>
                             </div>
                             <hr style="border: solid 1px #141414;" >
                                
                   
                           
                              <!---->
                
                              <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                                <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                                  {{ curentLang === 'en' ? 'Product Measurement Unit' : (curentLang === 'ar' ? '   وحدة قياس المنتج  ' : 'יחידת מדידה')}}
                                </span>
                                  <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" >
                                    {{curentLang === 'en' ? item.measurementUnits?.measurement_unit_name_en : (curentLang === 'ar' ? item.measurementUnits?.measurement_unit_name_ar : item.measurementUnits?.measurement_unit_name_he)}}                                  </span>
                                  <span class="col-sm-4   text-end ">
                                    <i class="fa fa-info-circle px-5 "  style="color: #141414; font-size: 16px ;" ></i>
                                  </span>
                               </div>
                               <hr style="border: solid 1px #141414;" >


                                <!---->
        
                               <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                                <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                                  {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטטוס ')}}
                                </span>
                                
                                <div class="text-center col-sm-4  ">  
                                  
                                </div>
                                  <span style="color: #141414;   " class="text-end col-sm-4"   >
                                    
                                    <span class="col-sm-4   text-end  ">
                                      <button [disabled]="item.user_type === 200 || item.user_type === 100"  
                                      class="btn px-5"
                                       style="border: none;" 
                                       *ngIf="item.product_status==1"  style="border: none;" (click)="changeStatus(item?.product_id, item.product_status)">
                                        <i class="fa solid fa-solid fa-toggle-on " style=" color: #213FA6; "></i>
                                      </button> 
                                      
                                      <button *ngIf="item.product_status==59" class=" btn px-5"  style="border: none;"(click)="changeStatus(item?.product_id, item.product_status)">
                                        <i class="fa solid fa-solid fa-toggle-off " style=" color: red;"></i>
                                      </button> 
                                    </span>
                                      
                                    
                                </span>
              
                             
                               </div>
                               <hr style="border: solid 1px #141414;" > 

                                     <!---->
                        
                            <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                              <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                                {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : 'תאריך הרשמה')}}
                              </span>
                                <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" >
                                  {{ item.createdDate | date: 'dd/MM/yyyy'}}
                                </span>
                                <span class="col-sm-4   text-end ">
                                  <i class="fa fa-history px-5 "  style="color: #141414; font-size: 16px ;" ></i>
                                </span>
                             </div>
                             <hr style="border: solid 1px #141414;" >


                                        <!---->
                        
                            <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                              <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                                {{ curentLang === 'en' ? 'View' : (curentLang === 'ar' ? '   عرض' : '  הצגה   ')}}
                              </span>
                                <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" >
                                   
                                </span>
                                <span class="col-sm-4   text-end ">
                                  <button class=" btn px-5" style="border: none;" (click)="executeFunctionsView(  item?.product_id,viewData ) ">
                                    <i class="fa fa-eye" style="color: grey;   cursor: pointer;"></i>
                                </button>
                                </span>
                             </div>
                             <hr style="border: solid 1px #141414;" >


              <!---->
                        
              <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                  {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  עריכה ')}}
                </span>
                  <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" >
                     
                  </span>
                  <span class="col-sm-4   text-end ">
                    <button class=" btn px-5" style="border: none;"
                    (click)="executeFunctions(  item?.product_id,updatedData ) ">
                     <i class="fa fa-edit" style=" cursor: pointer; color: green;">
                    </i>
                   </button>
                  </span>
               </div>
               <hr style="border: solid 1px #141414;" >

   <!---->
                        
   <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
    <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
      {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : 'מחיקה')}}

    </span>
      <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" >
         
      </span>
      <span class="col-sm-4   text-end ">
        <button 
        [ngStyle]="{ 'pointer-events': item.product_status == 1 ? 'none' : 'auto', 'color': item.product_status == 1 ? 'gray' : 'red' }" 
        class="btn px-5" 
        style="border: none;" 
        (click)="getId(item?.product_id);openVerticallyCentered(dataDeleted)">
          <i class="fa fa-trash-o" style=" cursor: pointer;"></i>
          </button>
      </span>
   </div>
   <hr style="border: solid 1px #141414;" >





                        </div>
                      </div>
        
                    </div>
                    <hr style="color: #141414; border: solid 1px;">
            </div>
        
            <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>    
        
        
        
        
          </div>
        
            </div>





      </div>
    </div>
  
      </div>
  </div>
  
  </ng-container>