<h4>{{ 'allDocuments.filters.customers.title' | translate }}</h4>
<ng-container *ngIf="customers && customers.length">
  <div class="p-field-radiobutton" *ngFor="let customer of customers | slice:0:(showAllCustomers ? customers.length : 3)">
    <label [for]="'mobile-customer-' + customer.value">
      <p-radioButton name="selectedCustomer" 
                    [value]="customer.value" 
                    [inputId]="'mobile-customer-' + customer.value"
                    [(ngModel)]="selectedValue"
                    (ngModelChange)="onCustomerChange($event)">
      </p-radioButton>
      {{customer.label}}
    </label>
  </div>
  <a href="#" class="cursor-pointer" (click)="$event.preventDefault(); toggleShowAllCustomers()">
    {{ (showAllCustomers ? 'allDocuments.filters.customers.showLess' : 'allDocuments.filters.customers.showAll') |
    translate:{count: customers.length} }}
  </a>
</ng-container>
<ng-container *ngIf="!customers || !customers.length || loading">
  <div class="loading-wrapper">
    <p-progressSpinner [style]="{width: '30px', height: '30px'}" styleClass="custom-spinner" strokeWidth="4">
    </p-progressSpinner>
    <p>{{ 'allDocuments.filters.customers.loading' | translate }}</p>
  </div>
</ng-container>
