<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <div class="header-logo-wrapper col-auto p-0">
      <div class="logo-wrapper">
        <a >
          <!-- <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="" />
          <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="" /> -->
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div>
 
    <div class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto">
      <ul class="nav-menus">

        <li class="cart-nav onhover-dropdown" *ngIf="user_type === 100">
          <app-cart></app-cart>
        </li>

        <app-languages></app-languages>

        <li class="onhover-dropdown   " *ngIf=" officeIdNumber !== null">
          <app-message-box></app-message-box>
        </li>  
    

        <li class="onhover-dropdown   " *ngIf=" officeIdNumber !== null">
          <app-notification></app-notification>
        </li>

        
        
        
        <li class="profile-nav onhover-dropdown p-0  ">
          <app-my-account></app-my-account>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
