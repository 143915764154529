import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { HttpHelpService } from "../../service/http-help.service";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import { DatePipe } from "@angular/common";
import { EMPTY, catchError, concatMap, of, throwError } from "rxjs";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { AddCustomerEndPoient, AddProductEndPoient } from "../../service/global.service";

@Component({
  selector: "app-update-invoice",
  templateUrl: "./update-invoice.component.html",
  styleUrls: ["./update-invoice.component.scss"]
})

export class UpdateInvoiceComponent implements OnInit {
  customerNameSelected: any;
  vatTypeList: any;
  vatPersentageData: any;
  VatPercentage: any;
  invoiceId: any;
  vatListId: number;
  
  today: any;
  mastercompany: any;
  oneCompanyData: any = [];
  oneCustomerData: any = [];
  id: number;
  masterInvoiceTypeList: any;
  curentLang: string = "";
  userRegistId: any = JSON.parse(localStorage.getItem("user_id"));
  merchantId = JSON.parse(localStorage.getItem("merchant_id"));
  user_type = JSON.parse(localStorage.getItem("user_type"));
  companyDefault = JSON.parse(localStorage.getItem("companyDefault"));
  CompanyNameEn: string = "";
  CompanyNameAr: string = "";
  CompanyNameHe: string = "";
  invoiceData : any 

  CustomerNameEn: string = "";
  CustomerNameAr: string = "";
  CustomerNameHe: string = "";
  modalOptions: NgbModalOptions;

  selectedProduct: any;
  selectedMeasurementUnit: any;
  CompanyAddress: string = "";
  TaxCompany: string = "";
  customerVatNumber: string = "";
  LOGO: string = "";
  companyId: any;
  CompanyAddress_zip_code: any;
  vatCheckboxStates: boolean[] = []; // Array to store checkbox states

  CompanyAddress_city: any;
  CompanyAddress_number: any;
  CompanyAddress_str: any;
  tokenApi: any;
  refreshSubscription: any;
  sendToGovernment: any;
  updateToken: Object;
  uuId: any;
  addCustomer: FormGroup;
  public validate = false;
  public validate2 = false;

  customerTypeData: any = [];
  addProduct: FormGroup;
  categoriesData: any = [];
  unitMesauereData: any = [];
  RefrechTookenExpiration: any;
  filterField: string = "customer_name_en"; // الحقل الافتراضي للفلترة
  filterFieldProduct: string = "product_name_en";
  getAllActiveMeasurementUnits() {
    this.spinner.show();
    this.service.getAllActiveMeasurementUnits().subscribe({
      next: (res) => {
        this.unitMesauereData = res;
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }
  constructor(
    private builder: FormBuilder,
    private service: HttpHelpService,
    private router: Router,
    private alert: ToastrService,
    private activeroute: ActivatedRoute,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {
    this.id = this.activeroute.snapshot.params['id'];

    this.curentLang = localStorage.getItem("curentLang");
    this.companyDefault = localStorage.getItem("companyDefault");

    this.addCustomer = this.fb.group({
      customer_name_he: ["", Validators.required],
      customer_name_en: [""],
      customer_name_ar: [""],
      customer_type: ["", Validators.required], // Add Validators.required here
      customer_phone: [""],
      id_number: [null],
      customer_vat_number: [null],
      customer_vat_tax: [null],
      customer_address_str: [""], // جعل هذا الحقل اختياريًا
      customer_address_str_ar: [""], // جعل هذا الحقل اختياريًا
      customer_address_str_en: [""], // جعل هذا الحقل اختياريًا
      customer_address_city: [""], // جعل هذا الحقل اختياريًا
      customer_address_city_ar: [""], // جعل هذا الحقل اختياريًا
      customer_address_city_en: [""], // جعل هذا الحقل اختياريًا
      customer_address_zip_code: [""], // جعل هذا الحقل اختياريًا
      customer_Building_number: [""], // جعل هذا الحقل اختياريًا
    });

    this.addProduct = new FormGroup({
      product_name_en: new FormControl("", Validators.required),
      product_name_ar: new FormControl("", Validators.required),
      product_name_he: new FormControl("", Validators.required),
      des_ar: new FormControl("", Validators.required),
      des_en: new FormControl("", Validators.required),
      des_he: new FormControl("", Validators.required),
      product_code: new FormControl("", Validators.required),
      price: new FormControl("", Validators.required),
      measurement_unit_id: new FormControl("", Validators.required),
      categories_id: new FormControl("", Validators.required),
      merchant_id: new FormControl(this.merchantId),
      created_by: new FormControl(this.userRegistId),
    });
    this.updateFilterField();
    this.updateFilterFieldProduct();
    this.modalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'xl'
    };
  }

  pagetitle = "Create Invoice";
  invoicedetail!: FormArray<any>;
  invoiceproduct!: FormGroup<any>;
  selectedProductIndex: number;

  mastercustomer: any;
  masterproduct: any;
  editinvoiceno: any;
  isedit = false;
  editinvdetail: any;
  saveInvoiceButtonClicked: boolean = false;
  companyNameAr: any;
  companyNameEn: any;
  companyNameHe: any;
  selectedCompany: any;
  YearData: any = new Date().getFullYear();
  MaxAmount: any;
  Year: any;
  selectedMonth: any;
  selectedYear: any;
  activeModal: any; // Add this property to store the modal reference

  closeActiveModal() {
    if (this.activeModal) {
      this.activeModal.close(); // Closes the active modal
    }  }
  getAllActiveCategories() {
    this.spinner.show();
    this.service.getAllActiveCategories().subscribe({
      next: (res) => {
        this.categoriesData = res;

        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }
  closeAllModals() {
    this.modalService.dismissAll('Cross click');
    
  }
  filterProduct(event: any) {
    this.selectedProduct = event;
  }

  filterMeasurementUnit(event: any) {
    this.selectedMeasurementUnit = event;
  }
  loadInvoiceData() {
    this.spinner.show(); // عرض رمز التحميل قبل استدعاء البيانات
  
    this.service.GetInvoiceForUpdate(this.id).subscribe(res => {
      this.invoiceData = res;
  
      this.companyId = this.invoiceData?.company_id;
      this.uuId = this.invoiceData?.invoice_uuid;
      this.CompanyNameAr = this.invoiceData?.company?.company_name_ar;
      this.CompanyNameEn = this.invoiceData?.company?.company_name_en;
      this.CompanyNameHe = this.invoiceData?.company?.company_name_he;
      this.VatPercentage = this.invoiceData?.vat_percentage;
  
      this.invoiceform.patchValue({
        invoice_type: this.invoiceData.invoice_type,
        customer_id: this.invoiceData.customer_id,
        company_id: this.invoiceData.company_id,
        invoice_date: this.invoiceData.invoice_date,
        customer_id_number: this.invoiceData?.customer_id_number,
        customer_name: this.invoiceData.customer_name,
        customer_vat_number: this.invoiceData.customer_vat_number,
        vat_list_id: this.invoiceData.vat_list_id,
        document_language: this.invoiceData.document_language,
        vat_percentage: this.invoiceData.vat_percentage,
        invoice_note: this.invoiceData.invoice_note,
        allocation_number: this.invoiceData.allocation_number
      });
  
      // ✅ Load sold items and assign product names
      if (this.invoiceData.soldItems && this.invoiceData.soldItems.length > 0) {
        const linesFormArray = this.invoiceform.get('lines') as FormArray;
        linesFormArray.clear(); // قم بمسح أي بيانات قديمة
  
        this.selectedProductNames = []; // ✅ Reset array before populating
  
        this.invoiceData.soldItems.forEach((item, i) => {
          const lineFormGroup = this.builder.group({
            index: item.index,
            product_id: item.product_id,
            category: item.category,
            measure_unit_description: item.measure_unit_description,
            measurement_unit_id: item.measurement_unit_id,
            description: item.description,
            catalog_id: item.catalog_id,
            item_name: item.item_name,
            quantity: item.quantity,
            price_per_unit: item.price_per_unit,
            discount: item.discount,
            sub_total: item.sub_total,
            vat_rate: item.vat_rate,
            vat_amount: item.vat_amount,
            total_amount: item.total_amount,
  
            // ✅ This is the fix: include isVatIncluded, default to false if undefined
            isVatIncluded: item.isVatIncluded ?? false
          });
  
          linesFormArray.push(lineFormGroup);
  
          // ✅ Set product name to show in UI
          this.selectedProductNames[i] = item.item_name;
        });
  
        this.getCompanyInfoById();
        this.getVatPercentageByVatListIdAndDate();
        this.getCustomerById();
      }
    });
  
    this.spinner.hide();
  }
  collapsedIndexes: boolean[] = [];
  showDetails: boolean = false; // Initial state
  toggleDetails() {
    this.showDetails = !this.showDetails; // Toggle the visibility
  }
  toggleCollapse(index: number) {
    this.collapsedIndexes[index] = !this.collapsedIndexes[index];
  }
  ngOnInit(): void {
    this.id = this.activeroute.snapshot.params['id'];

    this.invproducts.controls.forEach(() => {
      this.collapsedIndexes.push(false); // All products are expanded by default
    });
    this.curentLang = localStorage.getItem("curentLang");
    this.companyNameAr = localStorage.getItem("company_name_ar");
    this.companyNameEn = localStorage.getItem("company_name_en");
    this.companyNameHe = localStorage.getItem("company_name_he");
  
    if (this.companyDefault) {
      this.selectedCompany = this.companyDefault;
  
      if (this.curentLang == "ar") {
        this.selectedCompany = this.companyNameAr;
      } else if (this.curentLang === "he") {
        this.selectedCompany = this.companyNameHe;
      } else if (this.curentLang === "en") {
        this.selectedCompany = this.companyNameEn;
      } else {
        this.curentLang = "en";
      }
    }
    // تعيين اللغة الافتراضية استنادًا إلى لغة الموقع الحالية
    if (this.curentLang === "ar") {
      this.curentLang = "ar"; // لغة الموقع العربية
    } else if (this.curentLang === "he") {
      this.curentLang = "he"; // لغة الموقع العبرية
    } else {
      this.curentLang = "en";
    }
  
    // this.startAutomaticTokenRefresh();
  
    // تحديث النموذج بالقيم المستلمة
    this.invoiceform.patchValue({
      vat_list_id: 8,
    });
  
    this.getVatPercentageByVatListIdAndDate();
  
    //    this.invoiceform.get('document_language')?.setValue(this.curentLang);
    const today = new Date();
    this.selectedMonth = today.getMonth() + 1;
    this.selectedYear = today.getFullYear();
    this.checkClosedMonth();
    this.onCustomerTypeChange();
    this.loadInvoiceData();

    this.GetYear();
    this.GetCustomers();
    this.GetProducts();
    this.vatTypechange();
    this.GetCompany();
    this.GetInvoiceTypeList();
    this.getCustomerById();
    this.getCompanyInfoById();
    this.getCompanyInfoByDefaultId();
    this.getAllCustomerType();
    this.getAllActiveCategories();
    this.getAllActiveMeasurementUnits();
    this.editinvoiceno = this.activeroute.snapshot.paramMap.get("invoiceno");
    if (this.editinvoiceno != null) {
      this.pagetitle = "Edit Invoice";
      this.isedit = true;
      // this.SetEditInfo(this.editinvoiceno);
    }
    this.userRegistId = JSON.parse(localStorage.getItem("user_id"));
    this.merchantId = JSON.parse(localStorage.getItem("merchant_id"));
    this.user_type = JSON.parse(localStorage.getItem("user_type"));
    this.companyDefault = localStorage.getItem("companyDefault");
    if (this.companyDefault !== null && this.companyDefault !== "null") {
      this.invoiceform.patchValue({
        company_id: Number(this.companyDefault),
      });
      this.checkSequenceExists(Number(this.companyDefault));
    }
  
    this.invoiceform.get("company_id").valueChanges.subscribe((companyId) => {
      if (companyId) {
        this.checkSequenceExists(companyId);
      }
    });
  
    // Add the logic to populate the lines FormArray with the soldItems
    if (this.invoiceData.soldItems && this.invoiceData.soldItems.length > 0) {
      const linesFormArray = this.invoiceform.get('lines') as FormArray;
      linesFormArray.clear(); // Clear existing lines
  
      this.invoiceData.soldItems.forEach(item => {
        const lineFormGroup = this.builder.group({
          index: item.index,
          product_id: item.product_id, 
          category: item.category,
          measure_unit_description: item.measure_unit_description,
          measurement_unit_id: item.measurement_unit_id,
          description: item.description,
          catalog_id: item.catalog_id,
          item_name: item.item_name,
          quantity: item.quantity,    
          price_per_unit: item.price_per_unit, 
          discount: item.discount,    
          sub_total: item.sub_total,    
          vat_rate: item.vat_rate,    
          vat_amount: item.vat_amount,    
          total_amount: item.total_amount    ,
          isVatIncluded: item.isVatIncluded ?? false

        });
        linesFormArray.push(lineFormGroup); 
      });
  
      this.getCompanyInfoById();
      this.getVatPercentageByVatListIdAndDate();
      this.getCustomerById();
    }
  }
  openXl(content: any) {
    this.modalService.open(content, { size: "xl", centered: true });
  }

  openChooseCustomerModal(chooseCustomerModal: any) {
    this.modalService.open(chooseCustomerModal, { size: "xl" });
  }

  openChooseProductModal(chooseProductModal: any) {
    this.addnewproduct();
    this.activeModal = this.modalService.open(chooseProductModal, this.modalOptions);
  }
  openChooseProductModal2(chooseProductModal: any, index: number) {
    // Set the selected product index
    this.selectedProductIndex = index;
  
    // Open the modal with the same options
    this.activeModal = this.modalService.open(chooseProductModal, {
      backdrop: 'static',
      keyboard: false,
      size: 'xl'
    });
  }
  // resetForm() {
  //   // إعادة تعيين النموذج إلى حالته الأولية
  //   this.addCustomer.reset({
  //       // customer_name_en: null,
  //       // customer_name_ar: null,
  //       customer_vat_number: null,
  //       customer_vat_tax: null,
  //       id_number: null,
  //       customer_type: this.addCustomer.get('customer_type')?.value, // الاحتفاظ بالقيمة الحالية لـ `customer_type`
  //   });
  //   };

  @ViewChild("chooseProductModal") chooseProductModal: TemplateRef<any>;
  onProductSelect(product: any, i: number) {
    if (!product || (Array.isArray(product) && product.length === 0)) {
      console.error("No product selected");
      return;
    }
  
    console.log("Product selected:", product);
  
    const selectedProduct = Array.isArray(product) ? product[0] : product;
    console.log("Extracted Product:", selectedProduct);
  
    let productName = selectedProduct.product_name_en || "Unknown";
    if (this.curentLang === "ar") {
      productName = selectedProduct.product_name_ar || "اسم غير معروف";
    } else if (this.curentLang === "he") {
      productName = selectedProduct.product_name_he || "שם לא ידוע";
    }
  
    // التأكد من تهيئة `FormArray`
    if (!this.invproducts || !this.invproducts.controls) {
      console.error("FormArray invproducts is not initialized properly");
      return;
    }
  
    // ✅ إضافة عنصر جديد فقط عند اختيار المنتج
    this.invproducts.push(
      this.fb.group({
        index: this.invproducts.length + 1,
        product_id: selectedProduct.product_id,
        category: selectedProduct.category || "",
        measure_unit_description: selectedProduct.measure_unit_description || "",
        measurement_unit_id: selectedProduct.measurement_unit_id || "",
        description: selectedProduct.description || "",
        catalog_id: selectedProduct.catalog_id || "",
        item_name: selectedProduct.item_name || "",
        quantity: 1,
        price_per_unit: parseFloat(selectedProduct.price) || 0,
        discount: 0,
        sub_total: parseFloat(selectedProduct.price) || 0,
        vat_rate: this.VatPercentage || 0,
        vat_amount: 0,
        total_amount: parseFloat(selectedProduct.price) || 0,
      })
    );
    console.log("New product form added!");
  
    // تحديث النموذج مع البيانات
    const lastIndex = this.invproducts.controls.length - 1;
    this.invproducts.controls[lastIndex].patchValue({
      product_id: selectedProduct.product_id,
      description: selectedProduct.des_en || "",
      price_per_unit: parseFloat(selectedProduct.price) || 0,
      quantity: 1,
      discount: 0,
      vat_amount: 0,
      total_amount: parseFloat(selectedProduct.price) || 0,
    });
    
    // Re-disable vat_amount after setting values
    this.invproducts.controls[lastIndex].get('vat_amount')?.disable();
  
    console.log(
      "Updated form with:",
      this.invproducts.controls[lastIndex].value
    );
  
    // افتح المودال بعد تحديد المنتج
    this.activeModal = this.modalService.open(this.chooseProductModal,this.modalOptions );
  
    // استدعاء دالة productchange بعد تحديث المنتج
    this.productchange(lastIndex); // تمرير الـ index أو الرقم المناسب
  }

  openChooseProductModalSelected(chooseProductModalSelected: any) {
    this.activeModal = this.modalService.open(chooseProductModalSelected,this.modalOptions);
  }

  closeModal(modal: any) {
    // تعيين جميع القيم إلى false لإغلاق جميع العناصر
    this.collapsedIndexes = Array(this.invproducts.controls.length).fill(true);

    // إغلاق المودال
    modal.dismiss("Cross click");
  }

  updateFilterField(): void {
    if (this.curentLang === "en") {
      this.filterField = "customer_name_en";
    } else if (this.curentLang === "ar") {
      this.filterField = "customer_name_ar";
    } else if (this.curentLang === "he") {
      this.filterField = "customer_name_he";
    } else {
      this.filterField = "id_number";
    }
  }

  updateFilterFieldProduct(): void {
    if (this.curentLang === "en") {
      this.filterFieldProduct = "product_name_en";
    } else if (this.curentLang === "ar") {
      this.filterFieldProduct = "product_name_ar";
    } else if (this.curentLang === "he") {
      this.filterFieldProduct = "product_name_he";
    } else {
      this.filterFieldProduct = "product_code";
    }
  }

  onCustomerSelect(event: any): void {
    const selectedCustomer = event.value[0].customer_id; // الحصول على العميل المختار

    this.invoiceform.get("customer_id")?.setValue(selectedCustomer); // تحديث قيمة الحقل
    this.getCustomerById();

    this.modalService.dismissAll();
  }

  filterCustomer(event: any) {
    let x = event.target.value;
  }
  getAllCustomerType() {
    this.spinner.show();
    this.service.getAllCustomerType().subscribe({
      next: (res) => {
        this.customerTypeData = res;

        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }

  onCustomerTypeChange() {
    this.addCustomer.get("customer_type")?.valueChanges.subscribe((value) => {
      if (value == 5) {
        //    this.addCustomer.get('id_number')?.setValidators([Validators.required, Validators.maxLength(9)]);
        this.addCustomer.get("customer_vat_number")?.clearValidators();
      } else if (value == 6) {
        this.addCustomer
          .get("customer_vat_number")
          ?.setValidators([
            Validators.required,
            Validators.pattern("[0-9]{9}"),
          ]);
        //    this.addCustomer.get('id_number')?.clearValidators();
      }
      //   this.addCustomer.get('id_number')?.updateValueAndValidity();
      this.addCustomer.get("customer_vat_number")?.updateValueAndValidity();
    });
  }
  addedData() {
    if (this.addCustomer.invalid) {
        this.validate = true; // تفعيل عرض رسائل الفالديشن
        Object.keys(this.addCustomer.controls).forEach((key) => {
            const control = this.addCustomer.get(key);
            if (control.invalid) {
            }
        });
        return;
    }

    // نسخ قيمة customer_name_he إلى customer_name_en و customer_name_ar
    const customerNameHe = this.addCustomer.get("customer_name_he").value;
    this.addCustomer.patchValue({
        customer_name_en: customerNameHe,
        customer_name_ar: customerNameHe,
    });

    const customerAddressHe = this.addCustomer.get("customer_address_city").value;
    this.addCustomer.patchValue({
        customer_address_city_en: customerAddressHe,
        customer_address_city_ar: customerAddressHe,
    });

    const customerAddressStreetHe = this.addCustomer.get("customer_address_str").value;
    this.addCustomer.patchValue({
        customer_address_str_en: customerAddressStreetHe,
        customer_address_str_ar: customerAddressStreetHe,
    });

    let model = {
        customer_name_en: this.addCustomer.value.customer_name_en,
        customer_name_ar: this.addCustomer.value.customer_name_ar,
        customer_name_he: this.addCustomer.value.customer_name_he,
        customer_address_str: this.addCustomer.value.customer_address_str,
        customer_address_str_ar: this.addCustomer.value.customer_address_str_ar,
        customer_address_str_en: this.addCustomer.value.customer_address_str_en,

        customer_address_city: this.addCustomer.value.customer_address_city,
        customer_address_city_ar: this.addCustomer.value.customer_address_city_ar,
        customer_address_city_en: this.addCustomer.value.customer_address_city_en,
        customer_address_zip_code: this.addCustomer.value.customer_address_zip_code,
        customer_Building_number: this.addCustomer.value.customer_Building_number,
        customer_phone: this.addCustomer.value.customer_phone !== "" ? "+972" + this.addCustomer.value.customer_phone : "",
        customer_vat_number: this.addCustomer.value.customer_vat_number,
        customer_vat_tax: this.addCustomer.value.customer_vat_tax,
        id_number: this.addCustomer.value.id_number,
        customer_type: Number(this.addCustomer.value.customer_type),
        merchant_id: Number(this.merchantId),
        created_by: Number(this.userRegistId),
    };

    this.spinner.show();
    this.service.Post(AddCustomerEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
            Swal.fire({
                icon: "success",
                title: this.curentLang === "ar" ? res.messageAr : this.curentLang === "en" ? res.messageEn : res.messageHe,
            });
            this.modalService.dismissAll();
            this.getAllCustomerType();
            this.GetCustomers();
            this.addCustomer.reset({
                customer_name_en: "",
                customer_name_ar: "",
                customer_name_he: "",
                customer_address_str: "",
                customer_address_str_ar: "",
                customer_address_str_en: "",
                customer_address_city: "",
                customer_address_city_ar: "",
                customer_address_city_en: "",
                customer_address_zip_code: "",
                customer_Building_number: "",
                customer_phone: "",
                customer_vat_number: null,
                customer_vat_tax: null,
                id_number: null,
                customer_type: null,
            });
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
            let errorMessage;
            switch (res.status) {
                case 400:
                case 403:
                case 500:
                    errorMessage = this.curentLang === "ar" ? res.messageAr : this.curentLang === "en" ? res.messageEn : res.messageHe;
                    break;
                default:
                    errorMessage = this.curentLang === "ar" ? "حدث خطأ غير معروف" : this.curentLang === "en" ? "An unknown error occurred" : "אירעה שגיאה לא ידועה";
            }
            Swal.fire({
                icon: "error",
                title: errorMessage,
            });
        }
        this.spinner.hide();
    });
}

  getCustomerTypeName() {
    const customerTypeId = this.addCustomer.get("customer_type").value;
    const selectedType = this.customerTypeData.find(
      (item) => item.lookup_detail_id === customerTypeId
    );
    return selectedType
      ? this.curentLang === "en"
        ? selectedType.lookupDetailEnName
        : this.curentLang === "ar"
        ? selectedType.lookupDetailArName
        : selectedType.lookupDetailHeName
      : "";
  }

  isNumber(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    } else {
      return true;
    }
  }

  checkSequenceExists(companyId: number): void {
    const docType = "305"; // نوع المستند ثابت
    this.spinner.show();
    this.service.checkSequenceExists(companyId, docType).subscribe(
      (res) => {
        const sequenceExists = res;

        if (!sequenceExists) {
          Swal.fire({
            title:
              this.curentLang === "en"
                ? "Enter the starting number for the document sequence:"
                : this.curentLang === "ar"
                ? "أدخل الرقم الابتدائي لتسلسل المستند:"
                : "הזן את המספר ההתחלתי של רצף המסמכים:",
            input: "number",
            inputAttributes: {
              min: "1",
              step: "1",
            },
            inputValue: 1, // تعيين القيمة الافتراضية إلى 1
            showCancelButton: false,
            confirmButtonText:
              this.curentLang === "en"
                ? "Submit"
                : this.curentLang === "ar"
                ? "إرسال"
                : "שלח",
            allowOutsideClick: false,
            allowEscapeKey: false,
            preConfirm: (value) => {
              if (!value || value < 1) {
                Swal.showValidationMessage(
                  this.curentLang === "en"
                    ? "Please enter a valid number greater than 0"
                    : this.curentLang === "ar"
                    ? "يرجى إدخال رقم صالح أكبر من 0"
                    : "אנא הזן מספר חוקי גדול מ-0"
                );
              }
            },
          }).then((result) => {
            if (result.value) {
              this.createSequence(companyId, parseInt(result.value, 10));
            }
          });
        }

        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  createSequence(companyId: number, startWith: number): void {
    this.spinner.show();
    const docType = "305"; // نوع المستند ثابت

    this.service.createSequence(companyId, docType, startWith).subscribe(
      (res) => {
        Swal.fire({
          icon: "success",
          title:
            this.curentLang === "en"
              ? "Sequence created successfully"
              : this.curentLang === "ar"
              ? "تم إنشاء التسلسل بنجاح"
              : "רצף נוצר בהצלחה",
        });
      },
      (error) => {}
    );
    this.spinner.hide();
  }

  onDateChange(event: any) {
    const selectedDate: Date = event;
    const formattedDate = selectedDate
      .toISOString()
      .slice(0, 10)
      .replace(/-/g, "");
  }

  availableLanguages = [
    // { code: "en", name: "English" },
    { code: "ar", name: "Arabic" },
    { code: "he", name: "Hebrew" },
  ];

  // تعيين curentLang كلغة افتراضية

  invoiceform = this.builder.group({
    invoice_type: this.builder.control("305", Validators.required),
    customer_id: this.builder.control("", Validators.required),
    company_id: this.builder.control(null, Validators.required),
    vat_list_id: this.builder.control(null, Validators.required),
    vat_percentage: this.builder.control({ value: 0, disabled: true }),
    invoice_date: this.builder.control(
      this.datePipe.transform(new Date(), "yyyy-MM-dd"),
      Validators.required
    ),
    invoice_note: this.builder.control("", Validators.maxLength(255)),
    document_language: this.builder.control("he"),
    allocation_number: this.builder.control(null || 1, Validators.required),
    amount_before_discount: this.builder.control({ value: 0, disabled: true }),
    all_discount: this.builder.control({ value: 0, disabled: true }),
    payment_amount: this.builder.control({ value: 0, disabled: true }),
    all_vat_amount: this.builder.control({ value: 0, disabled: true }),
    payment_amount_including_vat: this.builder.control({
      value: 0,
      disabled: true,
    }),
    confirm: this.builder.control({ value: 0, disabled: true }),
    merchant_id: Number(this.merchantId),
    created_by: Number(this.userRegistId),
    customer_name: this.builder.control("", Validators.required),
    customer_id_number: this.builder.control(""),
    customer_vat_number: this.builder.control(""),
    lines: this.builder.array([], Validators.required),
  });

  confirmInvoice() {
    this.spinner.show();

    // التحقق من isValidTookenExpiration أولاً
    if (this.RefrechTookenExpiration === null) {
      this.spinner.hide();
      Swal.fire({
        icon: "warning",
        title:
        this.curentLang === "ar"
        ? "أنت غير رابط مع مصلحة الضرائب"
        : this.curentLang === "he"
        ? "אתה לא מקושר לרשות המיסים"
        : "You are not linked with the tax authority",
    showCancelButton: true,
    confirmButtonText:
      this.curentLang === "ar" ? "استمر رغم ذلك" 
      : this.curentLang === "he" ? "המשך בכל זאת"
      : "Keep going though",
    cancelButtonText:
      this.curentLang === "ar"
        ? "ربط مع مصلحة الضرائب"
        : this.curentLang === "he"
        ? "קשר אותי לרשות המיסים"
        : "Link with Tax Authority",
    reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // إذا اختار "استمر رغم ذلك"، نضيف حقل allocation_number ثم نتحقق من isValidCustomer
          (this.invoiceform as any).addControl(
            "allocation_number",
            new FormControl(0)
          );

          // استكمال التحقق من isValidCustomer بعد isValidTookenExpiration
          this.checkIsValidCustomer();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // الانتقال إلى صفحة الشركات إذا اختار "ربط مع مصلحة الضرائب"
          this.router.navigate(["/dashboard/documents"]);
        }
      });
    } else {
      // إذا كانت RefrechTookenExpiration ليست null، الانتقال مباشرةً للتحقق من isValidCustomer
      this.checkIsValidCustomer();
    }
  }

  // دالة للتحقق من isValidCustomer وتنفيذ باقي المنطق
  private checkIsValidCustomer() {
    let isValidCustomer = true;

    if (
      this.invoiceform.get("payment_amount_including_vat")?.value >=
      this.MaxAmount
    ) {
      const customerType = this.oneCustomerData.customer_type;
      const customerId = this.oneCustomerData.customer_id;

      if (customerType === 6) {
        const customerVatNumber = Number(
          this.invoiceform.get("customer_vat_number")?.value
        );
        if (customerVatNumber === 0 || customerVatNumber === null) {
          isValidCustomer = false;
          Swal.fire({
            icon: "warning",
            title:
              this.curentLang === "ar"
                ? "الرقم الضريبي للعميل غير صحيح"
                : "The customer tax number is incorrect",
            showCancelButton: true,
            confirmButtonText:
              this.curentLang === "ar" ? "استمر رغم ذلك" : "Keep going though",
            cancelButtonText:
              this.curentLang === "ar"
                ? "تعديل بيانات العميل"
                : "Edit Customer Data",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              this.invoiceform.patchValue({
                customer_vat_number: "999999998",
                customer_name: this.oneCustomerData?.[
                  "customer_name_" + this.curentLang
                ].substring(0, 25),
                customer_id_number: this.oneCustomerData?.id_number,
              });
              isValidCustomer = true;
              this.finalizeInvoiceCreation(); // استدعاء دالة إنشاء الفاتورة بعد التحقق
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire({
                title:
                  this.curentLang === "ar"
                    ? "تحديث الرقم الضريبي للعميل"
                    : "Update Customer VAT Number",
                input: "text",
                inputValue: this.oneCustomerData.customer_vat_number,
                showCancelButton: true,
                confirmButtonText:
                  this.curentLang === "ar" ? "تحديث" : "Update",
                inputValidator: (value) => {
                  if (
                    !value ||
                    value.trim() === "" ||
                    value.startsWith("0") ||
                    /^0+$/.test(value) ||
                    value.length < 9
                  ) {
                    return this.curentLang === "ar"
                      ? "الرجاء إدخال رقم ضريبي صالح لا يقل عن 9 أرقام ولا يبدأ بصفر"
                      : "Please enter a valid VAT number with at least 9 digits and does not start with 0";
                  }
                },
              }).then((inputResult) => {
                if (inputResult.isConfirmed) {
                  this.invoiceform.patchValue({
                    customer_vat_number: inputResult.value,
                    customer_name: this.oneCustomerData?.[
                      "customer_name_" + this.curentLang
                    ].substring(0, 25),
                    customer_id_number: this.oneCustomerData?.id_number,
                  });
                  isValidCustomer = true;

                  const model = {
                    customer_vat_number: inputResult.value,
                    updated_by: this.userRegistId,
                  };
                  this.service
                    .updateCustomersById(customerId, model)
                    .subscribe();
                  this.finalizeInvoiceCreation(); // استدعاء دالة إنشاء الفاتورة بعد التحديث
                }
              });
            }
          });
        } else {
          // استكمال إنشاء الفاتورة إذا كانت قيمة VAT صحيحة
          this.finalizeInvoiceCreation();
        }
      } else if (customerType === 5) {
        this.spinner.hide();
        const customerIdNumber = Number(
          this.invoiceform.get("customer_id_number")?.value
        );
        if (
          customerIdNumber === 0 ||
          customerIdNumber === null ||
          customerIdNumber === undefined
        ) {
          isValidCustomer = false;
          Swal.fire({
            icon: "warning",
            title:
              this.curentLang === "ar"
                ? "رقم الهوية للعميل غير صحيح"
                : "The customer ID number is incorrect",
            showCancelButton: true,
            confirmButtonText:
              this.curentLang === "ar" ? "استمر رغم ذلك" : "Keep going though",
            cancelButtonText:
              this.curentLang === "ar"
                ? "تعديل بيانات العميل"
                : "Edit Customer Data",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              this.invoiceform.patchValue({
                customer_id_number: "999999998",
                customer_name: this.oneCustomerData?.[
                  "customer_name_" + this.curentLang
                ].substring(0, 25),
                customer_vat_number:
                  this.oneCustomerData?.customer_vat_number || 0,
              });
              isValidCustomer = true;
              this.finalizeInvoiceCreation();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire({
                title:
                  this.curentLang === "ar"
                    ? "تحديث رقم الهوية للعميل"
                    : "Update Customer ID Number",
                input: "text",
                inputValue: this.oneCustomerData.id_number,
                showCancelButton: true,
                confirmButtonText:
                  this.curentLang === "ar" ? "تحديث" : "Update",
                inputValidator: (value) => {
                  if (
                    !value ||
                    value.trim() === "" ||
                    value.startsWith("0") ||
                    /^0+$/.test(value) ||
                    value.length < 9
                  ) {
                    return this.curentLang === "ar"
                      ? "الرجاء إدخال رقم هوية صالح لا يقل عن 9 أرقام ولا يبدأ بصفر"
                      : "Please enter a valid ID number with at least 9 digits and does not start with 0";
                  }
                },
              }).then((inputResult) => {
                if (inputResult.isConfirmed) {
                  this.invoiceform.patchValue({
                    customer_id_number: inputResult.value,
                    customer_vat_number:
                      this.oneCustomerData.customer_vat_number,
                    customer_name: this.oneCustomerData[
                      "customer_name_" + this.curentLang
                    ].substring(0, 25),
                  });
                  isValidCustomer = true;

                  const model = {
                    id_number: inputResult.value,
                    updated_by: this.userRegistId,
                  };
                  this.service
                    .updateCustomersById(customerId, model)
                    .subscribe();
                  this.finalizeInvoiceCreation();
                }
              });
            }
          });
        } else {
          this.finalizeInvoiceCreation(); // استكمال إنشاء الفاتورة إذا كانت قيمة ID صحيحة
        }
      } else {
        this.finalizeInvoiceCreation(); // إذا لم يكن customerType 5 أو 6
      }
    } else {
      this.finalizeInvoiceCreation(); // إذا لم تتجاوز القيمة MaxAmount
    }
  }

  // دالة خاصة لإنشاء الفاتورة بعد تحقق كل الشروط
  private finalizeInvoiceCreation() {
    this.spinner.show();
    return this.service
      .SaveInvoice(this.invoiceform.getRawValue())
      .pipe(
        concatMap((res: any) => {
          let result: any = res;
          this.invoiceId = result?.data?.invoice_id;
          this.uuId = result?.data?.invoice_uuid;

          if (result.status === 200) {
            return of(null);
          } else {
            this.spinner.hide();
            const errorMessage =
              this.curentLang === "ar"
                ? result.messageAr
                : this.curentLang === "en"
                ? result.messageEn
                : result.messageHe;
            Swal.fire({ icon: "error", title: errorMessage });
            return throwError(errorMessage);
          }
        }),
        catchError((error) => {
          this.spinner.hide();
          return throwError(error);
        }),
        concatMap(() => this.service.confirmInvoice(this.invoiceId))
      )
      .subscribe((res) => {
        if (res.status === 200) {
          this.router.navigate([`/dashboard/documents`]);
          Swal.fire({
            icon: "success",
            title:
              this.curentLang === "ar"
                ? res.messageAr
                : this.curentLang === "en"
                ? res.messageEn
                : res.messageHe,
          });
          this.modalService.dismissAll();
        } else if (res.status === 405) {
          this.router.navigate([`/dashboard/documents`]);
          Swal.fire({
            icon: "warning",
            title:
              this.curentLang === "ar"
                ? res.messageAr
                : this.curentLang === "en"
                ? res.messageEn
                : res.messageHe,
          });
          this.modalService.dismissAll();
        } else {
          const errorMessage =
            this.curentLang === "ar"
              ? res.messageAr
              : this.curentLang === "en"
              ? res.messageEn
              : res.messageHe;
          this.toastr.error(errorMessage);
        }
        this.spinner.hide();
      });
  }

  // دالة تحتوي على منطق تأكيد الفاتورة المتبقي
  private executeConfirmInvoiceLogic() {
    let isValidCustomer = true;
    let isValidTookenExpiration = true;

    if (
      this.invoiceform.get("payment_amount_including_vat")?.value >=
      this.MaxAmount
    ) {
      const customerType = this.oneCustomerData.customer_type;
      const customerId = this.oneCustomerData.customer_id;

      if (customerType === 6) {
        const customerVatNumber = Number(
          this.invoiceform.get("customer_vat_number")?.value
        );
        if (customerVatNumber === 0 || customerVatNumber === null) {
          isValidCustomer = false;
          Swal.fire({
            icon: "warning",
            title:
              this.curentLang === "ar"
                ? "الرقم الضريبي للعميل غير صحيح"
                : "The customer tax number is incorrect",
            showCancelButton: true,
            confirmButtonText:
              this.curentLang === "ar" ? "استمر رغم ذلك" : "Keep going though",
            cancelButtonText:
              this.curentLang === "ar"
                ? "تعديل بيانات العميل"
                : "Edit Customer Data",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              this.invoiceform.patchValue({
                customer_vat_number: "999999998",
                customer_name: this.oneCustomerData?.[
                  "customer_name_" + this.curentLang
                ].substring(0, 25),
                customer_id_number: this.oneCustomerData?.id_number,
              });
              isValidCustomer = true;
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire({
                title:
                  this.curentLang === "ar"
                    ? "تحديث الرقم الضريبي للعميل"
                    : "Update Customer VAT Number",
                input: "text",
                inputValue: this.oneCustomerData.customer_vat_number,
                showCancelButton: true,
                confirmButtonText:
                  this.curentLang === "ar" ? "تحديث" : "Update",
                inputValidator: (value) => {
                  if (
                    !value ||
                    value.trim() === "" ||
                    value.startsWith("0") ||
                    /^0+$/.test(value) ||
                    value.length < 9
                  ) {
                    return this.curentLang === "ar"
                      ? "الرجاء إدخال رقم ضريبي صالح لا يقل عن 9 أرقام ولا يبدأ بصفر"
                      : "Please enter a valid VAT number with at least 9 digits and does not start with 0";
                  }
                },
              }).then((inputResult) => {
                if (inputResult.isConfirmed) {
                  this.invoiceform.patchValue({
                    customer_vat_number: inputResult.value,
                    customer_name: this.oneCustomerData?.[
                      "customer_name_" + this.curentLang
                    ].substring(0, 25),
                    customer_id_number: this.oneCustomerData?.id_number,
                  });
                  isValidCustomer = true;

                  const model = {
                    customer_vat_number: inputResult.value,
                    updated_by: this.userRegistId,
                  };
                  this.service
                    .updateCustomersById(customerId, model)
                    .subscribe();
                }
              });
            }
          });
        }
      }
    }

    if (isValidCustomer && isValidTookenExpiration && this.invoiceform.valid) {
      this.spinner.show();
      return this.service
        .SaveInvoice(this.invoiceform.getRawValue())
        .pipe(
          concatMap((res: any) => {
            let result: any = res;
            this.invoiceId = result?.data?.invoice_id;
            this.uuId = result?.data?.invoice_uuid;

            if (result.status === 200) {
              return of(null);
            } else {
              this.spinner.hide();
              const errorMessage =
                this.curentLang === "ar"
                  ? result.messageAr
                  : this.curentLang === "en"
                  ? result.messageEn
                  : result.messageHe;
              Swal.fire({ icon: "error", title: errorMessage });
              return throwError(errorMessage);
            }
          }),
          catchError((error) => {
            this.spinner.hide();
            return throwError(error);
          }),
          concatMap(() => this.service.confirmInvoice(this.invoiceId))
        )
        .subscribe((res) => {
          if (res.status === 200) {
            this.router.navigate([`/dashboard/documents`]);
            Swal.fire({
              icon: "success",
              title:
                this.curentLang === "ar"
                  ? res.messageAr
                  : this.curentLang === "en"
                  ? res.messageEn
                  : res.messageHe,
            });
            this.modalService.dismissAll();
          } else {
            const errorMessage =
              this.curentLang === "ar"
                ? res.messageAr
                : this.curentLang === "en"
                ? res.messageEn
                : res.messageHe;
            this.toastr.error(errorMessage);
          }
          this.spinner.hide();
        });
    } else if (!isValidCustomer && !isValidTookenExpiration) {
      this.spinner.hide();
    } else {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يرجى ملء جميع الحقول"
            : "Please fill all fields",
      });
      this.spinner.hide();
      return EMPTY;
    }
  }

  getCompanyInfoByDefaultId() {
    this.spinner.show();
    this.service.getCompanyInfoById(this?.companyDefault).subscribe({
      next: (res) => {
        this.oneCompanyData = res;
        this.RefrechTookenExpiration =
          this.oneCompanyData.refresh_token_expiration;
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }
  // Add this method to your component class

  // Update the template to bind the click event
  // SaveInvoice() {
  //   let isValidCustomer = true; // متغير علم لتحديد صلاحية العميل

  //   if (
  //     this.invoiceform.get("payment_amount_including_vat")?.value >=
  //     this.MaxAmount
  //   ) {
  //     const customerType = this.oneCustomerData.customer_type;
  //     const customerId = this.oneCustomerData.customer_id;

  //     if (customerType === 6) {
  //       const customerVatNumber = Number(
  //         this.invoiceform.get("customer_vat_number")?.value
  //       );
  //       if (customerVatNumber === 0 || customerVatNumber === null) {
  //         isValidCustomer = false; // إيقاف علم صلاحية العميل مؤقتًا
  //         Swal.fire({
  //           icon: "warning",
  //           title:
  //             this.curentLang === "ar"
  //               ? "الرقم الضريبي للعميل غير صحيح"
  //               : this.curentLang === "en"
  //               ? "The customer tax number is incorrect"
  //               : "מספר המס של הלקוח שגוי",
  //           showCancelButton: true,
  //           confirmButtonText:
  //             this.curentLang === "ar" ? "استمر رغم ذلك" : "Keep going though",
  //           cancelButtonText:
  //             this.curentLang === "ar"
  //               ? "تعديل بيانات العميل"
  //               : "Edit Customer Data",
  //           reverseButtons: true,
  //         }).then((result) => {
  //           if (result.isConfirmed) {
  //             this.invoiceform.patchValue({
  //               customer_vat_number: "999999998",
  //               customer_name: this.oneCustomerData?.[
  //                 "customer_name_" + this.curentLang
  //               ].substring(0, 25),
  //               customer_id_number: this.oneCustomerData?.id_number,
  //             });
  //             isValidCustomer = true; // إعادة صلاحية العميل
  //             this.finalizeInvoiceCreation(); // Call finalizeInvoiceCreation after updating the form
  //           } else if (result.dismiss === Swal.DismissReason.cancel) {
  //             Swal.fire({
  //               title:
  //                 this.curentLang === "ar"
  //                   ? "تحديث الرقم الضريبي للعميل"
  //                   : "Update Customer VAT Number",
  //               input: "text",
  //               inputValue: this.oneCustomerData.customer_vat_number,
  //               showCancelButton: true,
  //               confirmButtonText:
  //                 this.curentLang === "ar" ? "تحديث" : "Update",
  //               inputValidator: (value) => {
  //                 if (
  //                   !value ||
  //                   value.trim() === "" ||
  //                   value.startsWith("0") ||
  //                   /^0+$/.test(value) ||
  //                   value.length < 9
  //                 ) {
  //                   return this.curentLang === "ar"
  //                     ? "الرجاء إدخال رقم ضريبي صالح لا يقل عن 9 أرقام ولا يبدأ بصفر"
  //                     : "Please enter a valid VAT number with at least 9 digits and does not start with 0";
  //                 }
  //               },
  //             }).then((inputResult) => {
  //               if (inputResult.isConfirmed) {
  //                 this.invoiceform.patchValue({
  //                   customer_vat_number: inputResult.value,
  //                   customer_name: this.oneCustomerData?.[
  //                     "customer_name_" + this.curentLang
  //                   ].substring(0, 25),
  //                   customer_id_number: this.oneCustomerData?.id_number,
  //                 });
  //                 isValidCustomer = true; // إعادة صلاحية العميل بعد التحديث

  //                 const model = {
  //                   customer_vat_number: inputResult.value,
  //                   updated_by: this.userRegistId,
  //                 };
  //                 this.service
  //                   .updateCustomersById(customerId, model)
  //                   .subscribe(() => {
  //                     this.finalizeInvoiceCreation(); // Call finalizeInvoiceCreation after updating the customer
  //                   });
  //               }
  //             });
  //           }
  //         });
  //       } else {
  //         this.finalizeInvoiceCreation(); // Call finalizeInvoiceCreation if VAT number is valid
  //       }
  //     }

  //     if (customerType === 5) {
  //       const customerIdNumber = Number(
  //         this.invoiceform.get("customer_id_number")?.value
  //       );

  //       if (
  //         customerIdNumber === 0 ||
  //         customerIdNumber === null ||
  //         customerIdNumber === undefined
  //       ) {
  //         isValidCustomer = false;
  //         Swal.fire({
  //           icon: "warning",
  //           title:
  //             this.curentLang === "ar"
  //               ? "رقم الهوية للعميل غير صحيح"
  //               : "The customer ID number is incorrect",
  //           showCancelButton: true,
  //           confirmButtonText:
  //             this.curentLang === "ar" ? "استمر رغم ذلك" : "Keep going though",
  //           cancelButtonText:
  //             this.curentLang === "ar"
  //               ? "تعديل بيانات العميل"
  //               : "Edit Customer Data",
  //           reverseButtons: true,
  //         }).then((result) => {
  //           if (result.isConfirmed) {
  //             this.invoiceform.patchValue({
  //               customer_id_number: "999999998",
  //               customer_name: this.oneCustomerData?.[
  //                 "customer_name_" + this.curentLang
  //               ].substring(0, 25),
  //               customer_vat_number:
  //                 this.oneCustomerData?.customer_vat_number || 0,
  //             });
  //             isValidCustomer = true;
  //             this.finalizeInvoiceCreation(); // Call finalizeInvoiceCreation after updating the form
  //           } else if (result.dismiss === Swal.DismissReason.cancel) {
  //             Swal.fire({
  //               title:
  //                 this.curentLang === "ar"
  //                   ? "تحديث رقم الهوية للعميل"
  //                   : "Update Customer ID Number",
  //               input: "text",
  //               inputValue: this.oneCustomerData.id_number,
  //               showCancelButton: true,
  //               confirmButtonText:
  //                 this.curentLang === "ar" ? "تحديث" : "Update",
  //               inputValidator: (value) => {
  //                 if (
  //                   !value ||
  //                   value.trim() === "" ||
  //                   value.startsWith("0") ||
  //                   /^0+$/.test(value) ||
  //                   value.length < 9
  //                 ) {
  //                   return this.curentLang === "ar"
  //                     ? "الرجاء إدخال رقم هوية صالح لا يقل عن 9 أرقام ولا يبدأ بصفر"
  //                     : "Please enter a valid ID number with at least 9 digits and does not start with 0";
  //                 }
  //               },
  //             }).then((inputResult) => {
  //               if (inputResult.isConfirmed) {
  //                 this.invoiceform.patchValue({
  //                   customer_id_number: inputResult.value,
  //                   customer_vat_number:
  //                     this.oneCustomerData.customer_vat_number,
  //                   customer_name: this.oneCustomerData[
  //                     "customer_name_" + this.curentLang
  //                   ].substring(0, 25),
  //                 });
  //                 isValidCustomer = true;

  //                 const model = {
  //                   id_number: inputResult.value,
  //                   updated_by: this.userRegistId,
  //                 };
  //                 this.service
  //                   .updateCustomersById(customerId, model)
  //                   .subscribe(() => {
  //                     this.finalizeInvoiceCreation(); // Call finalizeInvoiceCreation after updating the customer
  //                   });
  //               }
  //             });
  //           }
  //         });
  //       } else {
  //         this.finalizeInvoiceCreation(); // Call finalizeInvoiceCreation if ID number is valid
  //       }
  //     }
  //   }

  //   if (isValidCustomer && this.invoiceform.valid) {
  //     this.finalizeInvoiceCreation(); // Call finalizeInvoiceCreation if form is valid
  //   } else if (!isValidCustomer) {
  //     this.spinner.hide();
  //   } else {
  //     Swal.fire({
  //       icon: "warning",
  //       title:
  //         this.curentLang === "ar"
  //           ? "يرجى ملء جميع الحقول"
  //           : "Please fill all fields",
  //     }).finally(() => {
  //       this.spinner.hide();
  //     });
  //   }
  // }

  SaveInvoice() {
    let isValidCustomer = true; // متغير علم لتحديد صلاحية العميل
  
    if (this.invoiceform.get("payment_amount_including_vat")?.value >= this.MaxAmount) {
      const customerType = this.oneCustomerData.customer_type;
      const customerId = this.oneCustomerData.customer_id;
  
      if (customerType === 6) {
       // const customerVatNumber = Number(this.oneCustomerData.customer_vat_number);
          const customerVatNumber = Number(this.invoiceform.get("customer_vat_number")?.value)
        if (customerVatNumber === 0 || customerVatNumber === null) {
          isValidCustomer = false; // إيقاف علم صلاحية العميل مؤقتًا
          Swal.fire({
            icon: 'warning',
            title: this.curentLang === 'ar' ? 'الرقم الضريبي للعميل غير صحيح' : (this.curentLang === 'en' ? 'The customer tax number is incorrect' : 'מספר המס של הלקוח שגוי'),
            showCancelButton: true,
            confirmButtonText: this.curentLang === 'ar' ? 'استمر رغم ذلك' : 'Keep going though',
            cancelButtonText: this.curentLang === 'ar' ? 'تعديل بيانات العميل' : 'Edit Customer Data',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
              this.invoiceform.patchValue({
                customer_vat_number: '999999998',
                customer_name: this.oneCustomerData?.['customer_name_' + this.curentLang].substring(0, 25),
                customer_id_number: this.oneCustomerData?.id_number,
              });
              isValidCustomer = true; // إعادة صلاحية العميل
  
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire({
                title: this.curentLang === 'ar' ? 'تحديث الرقم الضريبي للعميل' : 'Update Customer VAT Number',
                input: 'text',
                inputValue: this.oneCustomerData.customer_vat_number,
                showCancelButton: true,
                confirmButtonText: this.curentLang === 'ar' ? 'تحديث' : 'Update',
                inputValidator: (value) => {
                  if (!value || value.trim() === '' || value.startsWith('0') || /^0+$/.test(value) || value.length < 9) {
                    return this.curentLang === 'ar' ? 'الرجاء إدخال رقم ضريبي صالح لا يقل عن 9 أرقام ولا يبدأ بصفر' : 'Please enter a valid VAT number with at least 9 digits and does not start with 0';
                  }
                }
              }).then((inputResult) => {
                if (inputResult.isConfirmed) {
                  this.invoiceform.patchValue({
                    customer_vat_number: inputResult.value,
                    customer_name: this.oneCustomerData?.['customer_name_' + this.curentLang].substring(0, 25),
                    customer_id_number: this.oneCustomerData?.id_number
                  });
                  isValidCustomer = true; // إعادة صلاحية العميل بعد التحديث
  
                  const model = { customer_vat_number: inputResult.value, updated_by: this.userRegistId };
                  this.service.updateCustomersById(customerId, model).subscribe();
                }
              });
            }
          });
        }
      }
  
      if (customerType === 5) {
        const customerIdNumber = Number(this.invoiceform.get("customer_id_number")?.value);
  
        if (customerIdNumber === 0 || customerIdNumber === null || customerIdNumber === undefined) {
          isValidCustomer = false;
          Swal.fire({
            icon: 'warning',
            title: this.curentLang === 'ar' ? 'رقم الهوية للعميل غير صحيح' : 'The customer ID number is incorrect',
            showCancelButton: true,
            confirmButtonText: this.curentLang === 'ar' ? 'استمر رغم ذلك' : 'Keep going though',
            cancelButtonText: this.curentLang === 'ar' ? 'تعديل بيانات العميل' : 'Edit Customer Data',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
              this.invoiceform.patchValue({
                customer_id_number: '999999998',
                customer_name: this.oneCustomerData?.['customer_name_' + this.curentLang].substring(0, 25),
                customer_vat_number: this.oneCustomerData?.customer_vat_number || 0,
              });
              isValidCustomer = true;
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire({
                title: this.curentLang === 'ar' ? 'تحديث رقم الهوية للعميل' : 'Update Customer ID Number',
                input: 'text',
                inputValue: this.oneCustomerData.id_number,
                showCancelButton: true,
                confirmButtonText: this.curentLang === 'ar' ? 'تحديث' : 'Update',
                inputValidator: (value) => {
                  if (!value || value.trim() === '' || value.startsWith('0') || /^0+$/.test(value) || value.length < 9) {
                    return this.curentLang === 'ar' ? 'الرجاء إدخال رقم هوية صالح لا يقل عن 9 أرقام ولا يبدأ بصفر' : 'Please enter a valid ID number with at least 9 digits and does not start with 0';
                  }
                }
              }).then((inputResult) => {
                if (inputResult.isConfirmed) {
                  this.invoiceform.patchValue({
                    customer_id_number: inputResult.value,
                    customer_vat_number: this.oneCustomerData.customer_vat_number,
                    customer_name: this.oneCustomerData['customer_name_' + this.curentLang].substring(0, 25),
                  });
                  isValidCustomer = true;
  
                  const model = { id_number: inputResult.value, updated_by: this.userRegistId };
                  this.service.updateCustomersById(customerId, model).subscribe();
                }
              });
            }
          });
        }
      }
    }
  
    if (isValidCustomer && this.invoiceform.valid) {
      // تابع التنفيذ الطبيعي هنا بعد تحقق البيانات
      this.spinner.show();
      this.service.SaveInvoice(this.invoiceform.getRawValue()).subscribe(
        (res: any) => {
          let result: any = res;
          this.invoiceId = result?.data?.invoice_id;
          this.uuId = result?.data?.invoice_uuid;
  
          if (result.status === 200) {
            this.spinner.hide();
            this.router.navigate(['/dashboard/documents']);
            Swal.fire({
              icon: 'success',
              title: this.curentLang === 'ar' ? result.messageAr : result.messageEn
            }).then(() => {
              this.spinner.hide();
              this.invoiceform.reset();
            });
          } else if ([400, 403, 500].includes(result.status)) {
            this.spinner.hide();
            this.toastr.error(result.message);
          }
        },
        error => {
          this.spinner.hide();
        }
      );
    } else if (!isValidCustomer) {
      this.spinner.hide();
    } else {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : 'Please fill all fields'
      });
    }
  }

  GetYear() {
    this.spinner.show();
    this.service.GetYear(Number(this.YearData)).subscribe({
      next: (res) => {
        this.YearData = res;
        this.MaxAmount = this.YearData.maxAmount;
        this.Year = this.YearData.year;
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }
  addProductWithValidation(chooseProductModalSelected: any) {
    let customercode = this.invoiceform.get("customer_id")?.value;
    let vatListId = this.invoiceform.get("vat_list_id")?.value;
    let invoiceTypeCode = this.invoiceform.get("invoice_type")?.value;
    let invoiceDate = this.invoiceform.get("invoice_date")?.value;
    let companyid = this.invoiceform.get("company_id")?.value;

    if (
      (customercode != null &&
        customercode != "" &&
        vatListId != null &&
        vatListId != "" &&
        invoiceTypeCode != null &&
        invoiceTypeCode != "" &&
        invoiceDate != null &&
        invoiceDate != "" &&
        companyid != null &&
        companyid != "") ||
      this.isedit
    ) {
      this.openChooseProductModalSelected(chooseProductModalSelected);
    } else {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يرجى ملء جميع الحقول"
            : this.curentLang === "en"
            ? "Please fill all fields"
            : this.curentLang === "he"
            ? "נא למלא כל השדות"
            : "",
      });
    }
  }
  addnewproduct() {
    this.invoicedetail = this.invoiceform.get("lines") as FormArray;

    let customercode = this.invoiceform.get("customer_id")?.value;
    let vatListId = this.invoiceform.get("vat_list_id")?.value;
    let invoiceTypeCode = this.invoiceform.get("invoice_type")?.value;
    let invoiceDate = this.invoiceform.get("invoice_date")?.value;
    let companyid = this.invoiceform.get("company_id")?.value;

    if (
      (customercode != null &&
        customercode != "" &&
        vatListId != null &&
        vatListId != "" &&
        invoiceTypeCode != null &&
        invoiceTypeCode != "" &&
        invoiceDate != null &&
        invoiceDate != "" &&
        companyid != null &&
        companyid != "") ||
      this.isedit
    ) {
      this.invoicedetail.push(this.Generaterow());
    } else {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يرجى ملء جميع الحقول"
            : this.curentLang === "en"
            ? "Please fill all fields"
            : this.curentLang === "he"
            ? "נא למלא כל השדות"
            : "",
      });
    }
  }

  get invproducts() {
    return this.invoiceform.get("lines") as FormArray;
  }

  Generaterow() {
    const newIndex = this.invoicedetail.length + 1;
    
    this.stopVatPercentageSubscription();
  
    const row = this.builder.group({
      index: this.builder.control(newIndex),
      product_id: this.builder.control("", Validators.required),
      category: this.builder.control("", Validators.required),
      measure_unit_description: this.builder.control("", Validators.required),
      measurement_unit_id: this.builder.control("", Validators.required),
      description: this.builder.control("", Validators.required),
      catalog_id: this.builder.control("", Validators.required),
      item_name: this.builder.control("", Validators.required),
      quantity: this.builder.control(1),
      price_per_unit: this.builder.control(0),
      discount: this.builder.control(0),
      sub_total: this.builder.control({ value: 0, disabled: true }),
      vat_rate: this.builder.control({
        value: this.VatPercentage,
        disabled: true,
      }),
      vat_amount: this.builder.control({ value: 0, disabled: true }),
      total_amount: this.builder.control(0),
      isVatIncluded: this.builder.control(false), // ✅ Ensure this is always included
    });
  
    console.log(`Generated FormGroup for index ${newIndex - 1}:`, row.value);
    
    return row;
  }
  


  stopVatPercentageSubscription() {
    this.invoiceform.get("vat_list_id")?.disable();
    this.invoiceform.get("invoice_date")?.disable();
  }

  resumeVatPercentageSubscription() {
    this.invoiceform.get("vat_list_id")?.enable();
    this.invoiceform.get("invoice_date")?.enable();
  }

  GetCustomers() {
    this.spinner.show();
    this.service.getAllActiveCustomersBasedOnMerchantId().subscribe((res) => {
      this.mastercustomer = res;
      this.spinner.hide();
    });
  }
  getCompanyInfoById() {
    this.spinner.show();
    const companyId = this.invoiceform.get("company_id")?.value;
    if (companyId != null && companyId != "") {
      this.service.getCompanyInfoById(this?.companyId).subscribe({
        next: (res) => {
          this.oneCompanyData = res;
          this.RefrechTookenExpiration =
            this.oneCompanyData.refresh_token_expiration;

          this.spinner.hide();
        },
        error: (error) => {
          this.spinner.hide();
        },
      });
    } else {
      this.spinner.hide();
    }
  }

  getIdd(id: number) {
    this.id = id;
  }
  getCustomerById() {
    const customerId = this.invoiceform.get("customer_id")?.value;

    if (customerId != null && customerId !== "") {
      this.spinner.show();
      this.service.getCustomerById(Number(customerId)).subscribe({
        next: (res) => {
          this.oneCustomerData = res;

          // تحديث النموذج ببيانات العميل المستلمة
          this.invoiceform.patchValue({
            customer_name: this.oneCustomerData?.[
              "customer_name_" + this.curentLang
            ].substring(0, 100),
            customer_id_number: this.oneCustomerData?.id_number,
            customer_vat_number: this.oneCustomerData?.customer_vat_number || 0,
          });

          this.customerNameSelected =
            this.invoiceform.get("customer_name")?.value;

          this.spinner.hide();
        },
        error: (error) => {
          this.spinner.hide();
        },
      });
    }
  }

  GetCompany() {
    this.service.GetCompany().subscribe((res) => {
      this.mastercompany = res;
    });
  }

  GetProducts() {
    this.service.getAllActiveProductsBasedOnMerchantId().subscribe((res) => {
      this.masterproduct = res;
    });
  }

  GetInvoiceTypeList() {
    this.spinner.show();

    this.service.GetInvoiceTypeList().subscribe((res) => {
      this.masterInvoiceTypeList = res;
      this.spinner.hide();
    });
  }

  customerchange() {
    let customer_id = this.invoiceform.get("customer_id")?.value;
    this.service.GetCustomerbyId(customer_id).subscribe((res) => {
      let custdata: any;
      custdata = res;
    });
  }

  companychange() {
    let company_id = this.invoiceform.get("company_id")?.value;
    this.service.GetCompanybyId(company_id).subscribe((res) => {
      let custdata: any;
      custdata = res;
    });
  }
  getCompanyId(id: any) {
    this.spinner.show();
    this.companyId = id;
    this.spinner.hide();
  }

  getVatPercentageByVatListIdAndDate() {
    const vatListId = this.invoiceform.get("vat_list_id")?.value;
    const invoiceDate = this.invoiceform.get("invoice_date")?.value;

    if (vatListId && invoiceDate) {
      this.spinner.show();
      this.service
        .getVatPercentageByVatListIdAndDate(vatListId, invoiceDate)
        .subscribe({
          next: (res) => {
            this.vatPersentageData = res;
            if (this.vatPersentageData && this.vatPersentageData.length > 0) {
              this.VatPercentage = this.vatPersentageData[0].vat_percentage;
              this.invoiceform.patchValue({
                vat_percentage: this.VatPercentage, // تحديث القيمة في النموذج
              });
              this.summarycalculation(); // إعادة حساب القيم الإجمالية للفاتورة
            } else {
              // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
              this.invoiceform.patchValue({
                vat_percentage: 0,
              });
            }
          },
          error: (error) => {
            // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
            this.invoiceform.patchValue({
              vat_percentage: 0,
            });
          },
          complete: () => {
            this.spinner.hide(); // إخفاء المحمل بعد الحصول على البيانات بنجاح أو بعد حدوث خطأ
          },
        });
    } else {
      // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
      this.invoiceform.patchValue({
        vat_percentage: 0,
      });
      this.spinner.hide(); // إخفاء المحمل إذا لم يتم تحديد كلا من vatListId و invoiceDate
    }
  }

  filter(event: any) {
    let x = new Date(event.target.value);
    this.selectedMonth = x.getMonth() + 1; // Month is 0-based, so add 1
    this.selectedYear = x.getFullYear();

    this.checkClosedMonth();
  }

  checkClosedMonth() {
    this.spinner.show();
    this.service
      .checkClosedMonth(
        this.companyDefault,
        this.selectedMonth,
        this.selectedYear
      )
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          if (res.status == 200) {
            this.toastr.warning(
              this.curentLang === "en"
                ? res.messageEn
                : this.curentLang === "ar"
                ? res.messageAr
                : res.messageHe,
              "",
              { timeOut: 7000 } // زيادة مدة العرض إلى 5 ثوانٍ
            );
          } else {
            // this.toastr.info(
            //   this.curentLang === 'en'
            //     ? 'The selected month and year are open.'
            //     : this.curentLang === 'ar'
            //     ? 'الشهر والسنة المختارة مفتوحة.'
            //     : 'החודש והשנה שנבחרו פתוחים.',
            //   '',
            //   { timeOut: 7000 }
            // );
          }
        },
        error: (error) => {
          this.spinner.hide();
        },
      });
  }

  getId(id: number) {
    this.invoiceId = id;
  }
  vatTypechange() {
    this.spinner.show();

    this.service.GetVatTypeList().subscribe((res) => {
      this.vatTypeList = res;
    });

    this.spinner.hide();
  }

  // productchange(index: any) {
  //   this.invoicedetail = this.invoiceform.get("lines") as FormArray;
  //   this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;
  //   let product_id = this.invoiceproduct.get("product_id")?.value;
  //   this.service.GetProductbycode(product_id).subscribe(res => {
  //     let proddata: any;
  //     proddata = res;

  //     if(proddata?.product_code === '' || null ||
  //        proddata?.categories_id ===   null
  //       || proddata?.measurement_unit_id ===   null)
  //       {
  //         Swal.fire({
  //           icon: 'warning',
  //           title: this.curentLang === 'ar' ? ' برجاء استكمال معلومات المنتج والمحاولة مرة اخري' : (this.curentLang === 'en' ? 'Please complete the product information and try again' : (this.curentLang === 'he' ? 'אנא מלא את פרטי המוצר ונסה שוב' : ''))
  //         });

  //       return

  //       }

  //     if (proddata != null) {
  //       this.invoiceproduct.get("productName")?.setValue(proddata.name);
  //       this.invoiceproduct.get("price_per_unit")?.setValue(proddata.price);
  //       this.invoiceproduct.get("category")?.setValue(proddata.categories.categories_code,);
  //       this.invoiceproduct.get("measure_unit_description")?.setValue(proddata.measurementUnits['measurement_unit_name_' + this.curentLang].substring(0, 20),);
  //       this.invoiceproduct.get("measurement_unit_id")?.setValue(proddata.measurementUnits.measurement_unit_id);
  //       this.invoiceproduct.get("description")?.setValue(proddata['des_' + this.curentLang].substring(0, 30));
  //       this.invoiceproduct.get("catalog_id")?.setValue(proddata.product_code.substring(0, 13));
  //       this.invoiceproduct.get("item_name")?.setValue(proddata['product_name_' + this.curentLang]);
  //       this.Itemcalculation(index);
  //     }
  //   });
  // };
  addedProductData() {
    const product_name_he = String(this.addProduct.value.product_name_he)?.trim() || '';

    if (product_name_he === '') {
        Swal.fire({
            icon: 'warning',
            title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة وعدم تركها فارغة أو تحتوي على مسافات فقط' : 
                   (this.curentLang === 'en' ? 'Please fill out the required fields and do not leave them empty or containing only spaces' : 
                   (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים ואל תשאיר אותם ריקים או המכילים רק רווחים' : ''))
        });

        this.addProduct.markAllAsTouched();
        this.validate = !this.validate;
        return;
    }

    // Assign product_name_he to other fields
    const productNameHe = this.addProduct.value.product_name_he;
    const descriptionNameHe = this.addProduct.value.des_he;
    this.addProduct.patchValue({
        product_name_ar: productNameHe,
        product_name_en: productNameHe,
        des_ar: descriptionNameHe,
        des_en: descriptionNameHe,
    });

    let model = {
        product_name_en: this.addProduct.value.product_name_en,
        product_name_ar: this.addProduct.value.product_name_ar,
        product_name_he: this.addProduct.value.product_name_he,
        product_code: this.addProduct.value.product_code || '',
        price: this.addProduct.value.price || 0,
        des_ar: this.addProduct.value.des_ar || '',
        des_en: this.addProduct.value.des_en || '',
        des_he: this.addProduct.value.des_he || '',
        measurement_unit_id: this.addProduct.value.measurement_unit_id || null,
        categories_id: this.addProduct.value.categories_id || null,
        merchant_id: Number(this.merchantId),
        created_by: Number(this.userRegistId),
    };

    this.spinner.show();
    this.service.Post(AddProductEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
            Swal.fire({
                icon: 'success',
                title: this.curentLang === 'ar' ? res.messageAr : 
                       (this.curentLang === 'en' ? res.messageEn : res.messageHe)
            });
            this.modalService.dismissAll();
            this.getAllActiveCategories();
            this.getAllCustomerType();
            this.getAllActiveMeasurementUnits();

            this.addProduct.reset();
            this.addProduct.patchValue({
                product_name_en: '',
                product_name_ar: '',
                product_name_he: '',
                des_ar: '',
                des_en: '',
                des_he: '',
                product_code: '',
                price: 0,
                measurement_unit_id: null,
                categories_id: null,
                merchant_id: this.merchantId,
                created_by: this.userRegistId,
            });
            this.validate = false; // Reset validation state
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
            let errorMessage;
            switch (res.status) {
                case 400:
                case 403:
                case 500:
                    errorMessage = this.curentLang === 'ar' ? res.messageAr : 
                                   (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                    break;
                default:
                    errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : 
                                   (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
            }
            Swal.fire({
                icon: 'error',
                title: errorMessage
            });
        }
        this.spinner.hide();
    });
}

  openXl2(content2: any) {
    this.modalService.open(content2, { size: "xl", centered: true });
  }

  //   productchange(index: any) {

  //     this.invoicedetail = this.invoiceform.get("lines") as FormArray;
  //     this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

  //     // استماع للتغيرات على Total Amount
  //     this.invoiceproduct.get('total_amount').valueChanges.subscribe(newTotalAmount => {
  //         this.calculateReverse(index, newTotalAmount);
  //     });

  //     let product_id = this.invoiceproduct.get("product_id")?.value;

  //     this.service.GetProductbycode(product_id).subscribe(res => {
  //         let proddata: any;
  //         proddata = res;

  //         // التحقق من إذا كانت بيانات المنتج غير مكتملة
  //         if (proddata?.product_code === '' || proddata?.product_code === null ||
  //             proddata?.categories_id === null || proddata?.measurement_unit_id === null) {

  //             // عرض الرسالة التحذيرية وإدخال للمستخدم لإضافة الكود
  //             Swal.fire({
  //               icon: 'warning',
  //               title: this.curentLang === 'ar' ? ' برجاء استكمال معلومات المنتج والمحاولة مرة اخري' :
  //                      (this.curentLang === 'en' ? 'Please complete the product information and try again' :
  //                      (this.curentLang === 'he' ? 'אנא מלא את פרטי המוצר ונסה שוב' : '')),
  //               input: 'text', // إدخال نص للمستخدم
  //               inputPlaceholder: this.curentLang === 'ar' ? 'أدخل كود المنتج' :
  //                                  (this.curentLang === 'en' ? 'Enter product code' :
  //                                  (this.curentLang === 'he' ? 'הזן קוד מוצר' : '')),
  //               showCancelButton: true,
  //               confirmButtonText: this.curentLang === 'ar' ? 'إرسال' :
  //                                  (this.curentLang === 'en' ? 'Submit' :
  //                                  (this.curentLang === 'he' ? 'שלח' : '')),
  //               preConfirm: (value) => {
  //                 if (!value || value.trim() === '') {
  //                     Swal.showValidationMessage(
  //                         this.curentLang === 'ar' ? 'لا يمكن إدخال قيمة فارغة أو مسافة فقط' :
  //                         (this.curentLang === 'en' ? 'Empty or whitespace-only values are not allowed' :
  //                         (this.curentLang === 'he' ? 'אין להזין ערך ריק או הכולל רק רווחים' : ''))
  //                     );
  //                     return false;
  //                 }
  //                 return value.trim();
  //               }
  //             }).then(result => {
  //                 if (result.isConfirmed && result.value) {
  //                     let newProductCode = result.value;
  //                     this.invoiceproduct.get("catalog_id")?.setValue(newProductCode);

  //                     // إرسال الكود الجديد إلى الخادم
  //                     const model = {
  //                       product_code: newProductCode,
  //                       updated_by: this.userRegistId
  //                     };

  //                     this.spinner.show();
  //                     this.service.updateProductsById(product_id, model).subscribe((res) => {

  //                         // جلب البيانات المحدثة بعد التعديل
  //                         this.service.GetProductbycode(product_id).subscribe(updatedProdData => {
  //                             this.updateFormWithProductData(updatedProdData, index);
  //                         });
  //                     });
  //                     this.spinner.hide();
  //                 }
  //             });

  //             return;
  //         }

  //         if (proddata != null) {
  //             this.updateFormWithProductData(proddata, index);
  //         }
  //     });
  // }

  selectedProductNames: string[] = []; // مصفوفة لتخزين أسماء المنتجات
  selectedProductPrice: string[] = [];
  productchange(index: any) {
    this.invoicedetail = this.invoiceform.get("lines") as FormArray;
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

    // استماع للتغيرات على Total Amount
    this.invoiceproduct
      .get("total_amount")
      .valueChanges.subscribe((newTotalAmount) => {
        this.calculateReverse(index, newTotalAmount);
      });

    let product_id = this.invoiceproduct.get("product_id")?.value;

    this.service.GetProductbycode(product_id).subscribe((res) => {
      let proddata: any;
      proddata = res;

      // التحقق من إذا كانت بيانات المنتج غير مكتملة
      if (
        proddata?.product_code === "" ||
        proddata?.product_code === null ||
        proddata?.categories_id === null ||
        proddata?.measurement_unit_id === null
      ) {
        // عرض الرسالة التحذيرية وإدخال للمستخدم لإضافة الكود
        Swal.fire({
          icon: "warning",
          title:
            this.curentLang === "ar"
              ? " برجاء استكمال معلومات المنتج والمحاولة مرة اخري"
              : this.curentLang === "en"
              ? "Please complete the product information and try again"
              : this.curentLang === "he"
              ? "אנא מלא את פרטי המוצר ונסה שוב"
              : "",
          input: "text", // إدخال نص للمستخدم
          inputPlaceholder:
            this.curentLang === "ar"
              ? "أدخل كود المنتج"
              : this.curentLang === "en"
              ? "Enter product code"
              : this.curentLang === "he"
              ? "הזן קוד מוצר"
              : "",
          showCancelButton: true,
          confirmButtonText:
            this.curentLang === "ar"
              ? "إرسال"
              : this.curentLang === "en"
              ? "Submit"
              : this.curentLang === "he"
              ? "שלח"
              : "",
          preConfirm: (value) => {
            if (!value || value.trim() === "") {
              Swal.showValidationMessage(
                this.curentLang === "ar"
                  ? "لا يمكن إدخال قيمة فارغة أو مسافة فقط"
                  : this.curentLang === "en"
                  ? "Empty or whitespace-only values are not allowed"
                  : this.curentLang === "he"
                  ? "אין להזין ערך ריק או הכולל רק רווחים"
                  : ""
              );
              return false;
            }
            return value.trim();
          },
        }).then((result) => {
          if (result.isConfirmed && result.value) {
            let newProductCode = result.value;
            this.invoiceproduct.get("catalog_id")?.setValue(newProductCode);

            // إرسال الكود الجديد إلى الخادم
            const model = {
              product_code: newProductCode,
              updated_by: this.userRegistId,
            };

            this.spinner.show();
            this.service.updateProductsById(product_id, model).subscribe(() => {
              // جلب البيانات المحدثة بعد التعديل
              this.service
                .GetProductbycode(product_id)
                .subscribe((updatedProdData) => {
                  this.updateFormWithProductData(updatedProdData, index);
                  this.storeProductName(updatedProdData, index); // حفظ اسم المنتج
                });
            });
            this.spinner.hide();
          }
        });

        return;
      }

      if (proddata != null) {
        this.updateFormWithProductData(proddata, index);
        this.storeProductName(proddata, index); // حفظ اسم المنتج
      }
    });
  }

  // حفظ اسم المنتج بناءً على اللغة
  storeProductName(proddata: any, index: number): void {
    const productPrice = proddata.price;
    const productName =
      this.curentLang === "en"
        ? proddata.product_name_en
        : this.curentLang === "ar"
        ? proddata.product_name_ar
        : proddata.product_name_he;

    this.selectedProductNames[index] = productName;
    this.selectedProductPrice[index] = productPrice;
  }

  // دالة لتحديث حقول النموذج بقيم المنتج المحدثة
  updateFormWithProductData(proddata: any, index: number) {
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

    if (!this.invoiceproduct) {
        console.error(`No FormGroup found at index ${index}`);
        return;
    }

    // ✅ Preserve existing 'isVatIncluded' value (or default to false)
    let currentVatIncluded = this.invoiceproduct.get("isVatIncluded")?.value ?? false;

    this.invoiceproduct.get("productName")?.setValue(proddata.name);
    this.invoiceproduct.get("price_per_unit")?.setValue(proddata.price);
    this.invoiceproduct.get("category")?.setValue(proddata.categories.categories_code);
    this.invoiceproduct.get("measure_unit_description")?.setValue(
        proddata.measurementUnits["measurement_unit_name_" + this.curentLang].substring(0, 20)
    );
    this.invoiceproduct.get("measurement_unit_id")?.setValue(proddata.measurementUnits.measurement_unit_id);
    this.invoiceproduct.get("description")?.setValue(proddata["des_" + this.curentLang].substring(0, 300));
    this.invoiceproduct.get("catalog_id")?.setValue(proddata.product_code.substring(0, 13));
    this.invoiceproduct.get("item_name")?.setValue(proddata["product_name_" + this.curentLang]);

    // ✅ Reapply 'isVatIncluded' value to prevent it from being removed
    if (!this.invoiceproduct.contains('isVatIncluded')) {
        console.warn(`isVatIncluded is missing at index ${index}, adding it back.`);
        this.invoiceproduct.addControl('isVatIncluded', this.builder.control(false));
    }
    this.invoiceproduct.get("isVatIncluded")?.setValue(currentVatIncluded);
    this.invoiceproduct.get("isVatIncluded")?.updateValueAndValidity();

    console.log(`Updated FormGroup at index ${index}:`, this.invoiceproduct.value);
    this.Itemcalculation(index);
}

  //new
  onTotalAmountChange(index: number) {
    const lines = this.invoiceform.get('lines') as FormArray;
    const line = lines.at(index) as FormGroup;
  
    // 1. Get the raw value
    let rawVal = line.get('total_amount')?.value;
  
    // 2. Remove commas if it's a string (so "2,017.99" becomes "2017.99")
    if (typeof rawVal === 'string') {
      rawVal = rawVal.replace(/,/g, '');
    }
  
    // 3. Parse as float
    let total = parseFloat(rawVal);
  
    // 4. Default invalid or negative input to zero
    if (isNaN(total) || total < 0) {
      total = 0;
    }
  
    // 5. Round to two decimals
    total = Math.round(total * 100) / 100;
  
    // 6. Update the form control as a valid number-string (e.g., "2017.99")
    line.get('total_amount')?.setValue(total.toFixed(2), { emitEvent: false });
  
    // 7. Reverse calculations, etc.
    this.calculateReverse(index, total);
  }

  /// new
  // calculateReverse(index: any, newTotalAmount: number) {
  //   this.invoicedetail = this.invoiceform.get("lines") as FormArray;
  //   this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

  //   let quantity = this.invoiceproduct.get("quantity")?.value || 1; // التأكد من أن quantity ليس صفرًا
  //   let vat_rate = this.invoiceproduct.get("vat_rate")?.value || 0;
  //   let discount = this.invoiceproduct.get("discount")?.value || 0;

  //   // حساب Sub Total قبل تطبيق الضريبة
  //   let sub_total_before_vat = newTotalAmount / (1 + (vat_rate / 100));

  //   // حساب الخصم الكامل (يتم تطبيق الخصم على السعر الإجمالي للمنتجات)
  //   let total_without_discount = sub_total_before_vat + discount;

  //   // حساب السعر لكل وحدة
  //   let price_per_unit = total_without_discount / quantity;

  //   // حساب مبلغ الضريبة
  //   let vat_amount = parseFloat((sub_total_before_vat * (vat_rate / 100)).toFixed(2));

  //   // تعيين القيم المحسوبة إلى النموذج
  //   this.invoiceproduct.get("sub_total")?.setValue(sub_total_before_vat.toFixed(2));
  //   this.invoiceproduct.get("price_per_unit")?.setValue(price_per_unit.toFixed(2));
  //   this.invoiceproduct.get("vat_amount")?.setValue(vat_amount.toFixed(2));

  //   // تحديث القيم الإجمالية للفاتورة
  //   this.summarycalculation();
  // }

  calculateReverse(index: number, newTotalAmount: number) {
    const line = (this.invoiceform.get('lines') as FormArray).at(index) as FormGroup;
  
    // Use user’s total as-is (which is an integer if you forced rounding)
    // Then do your VAT logic:
    const vatRate = parseFloat(line.get("vat_rate")?.value) || 18;
    const quantity = parseFloat(line.get("quantity")?.value) || 1;
    const discount = parseFloat(line.get("discount")?.value) || 0;
    const isVatIncluded = line.get("isVatIncluded")?.value || false;
  
    const vatFraction = vatRate / 100;
  
    let subTotal = isVatIncluded
      ? newTotalAmount / (1 + vatFraction)
      : newTotalAmount;
  
    // Apply discount (don’t let net go below zero)
    let net = Math.max(subTotal - discount, 0);
    let vat = net * vatFraction;
    let pricePerUnit = quantity ? net / quantity : 0;
    let finalTotal = net + vat;
  
    // If you want to preserve integer totals, do not overwrite total_amount with decimals
    // Or, if you do want decimals, you can set them here:
    line.get("sub_total")?.setValue(subTotal.toFixed(2), { emitEvent: false });
    line.get("vat_amount")?.setValue(vat.toFixed(2), { emitEvent: false });
    line.get("price_per_unit")?.setValue(pricePerUnit.toFixed(2), { emitEvent: false });
  
    // Optionally override the total with finalTotal if you want to show decimals
    // line.get("total_amount")?.setValue(finalTotal.toFixed(2), { emitEvent: false });
  
    // Finally update summary
    this.summarycalculation();
  }

  Itemcalculation(index: number) {
    const lines = this.invoiceform.get("lines") as FormArray;
    const line = lines.at(index) as FormGroup;
  
    // Defensive parse
    const parse = (v: any, fallback = 0) =>
      isNaN(parseFloat(v)) ? fallback : parseFloat(v);
  
    let quantity = parse(line.get("quantity")?.value, 1);
    let price = parse(line.get("price_per_unit")?.value, 0);
    let discount = parse(line.get("discount")?.value, 0);
    let vatRate = parse(line.get("vat_rate")?.value, this.VatPercentage || 18);
    let isVatIncluded = line.get("isVatIncluded")?.value || false;
  
    // If VAT is included in the price_per_unit, remove it to get "net" price
    let adjustedPrice = isVatIncluded
      ? price / (1 + vatRate / 100)
      : price;
  
    // 1) Subtotal BEFORE discount
    let line_subtotal = quantity * adjustedPrice;
  
    // 2) Net after discount
    let line_net = Math.max(line_subtotal - discount, 0);
  
    // 3) VAT amount on the net
    let vat_amount = line_net * (vatRate / 100);
  
    // 4) Final total (net + vat)
    let total_amount = line_net + vat_amount;
  
    // Round each step to 2 decimals
    line_subtotal = Math.round(line_subtotal * 100) / 100;
    line_net = Math.round(line_net * 100) / 100;
    vat_amount = Math.round(vat_amount * 100) / 100;
    total_amount = Math.round(total_amount * 100) / 100;
  
    // Update form controls
    line.get("sub_total")?.setValue(line_subtotal.toFixed(2), { emitEvent: false });
    line.get("vat_amount")?.setValue(vat_amount.toFixed(2), { emitEvent: false });
    line.get("total_amount")?.setValue(total_amount.toFixed(2), { emitEvent: false });
  
    // Recalculate summary each time a line item changes
    this.summarycalculation();
  }
onVatCheckboxChange(index: number, event: any) {
  console.log(`Checkbox changed for index ${index}, checked: ${event.target.checked}`);

  this.invoicedetail = this.invoiceform.get("lines") as FormArray;
  this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

  if (!this.invoiceproduct) {
      console.error(`No FormGroup found at index ${index}`);
      return;
  }

  // Update the checkbox value in the form
  this.invoiceproduct.get("isVatIncluded")?.setValue(event.target.checked, { emitEvent: false });
  this.invoiceproduct.get("isVatIncluded")?.updateValueAndValidity();

  // Log to confirm update
  console.log(`isVatIncluded value updated: ${this.invoiceproduct.get("isVatIncluded")?.value}`);

  // Call the recalculation function
  this.Itemcalculation(index);
}


  
  // Add this method to handle the checkbox change


  getIncludingVATText(): string {
    return this.curentLang === "en"
      ? "Including VAT"
      : this.curentLang === "ar"
      ? "يشمل<br>قيمه<br>الضريبه"
      : "כולל מע\"מ";
  }
  Removeproduct(index: any): void {
    // إزالة المنتج من المصفوفة invproducts
    this.invproducts.removeAt(index);
  
    // حذف اسم المنتج من المصفوفة selectedProductNames
    if (this.selectedProductNames && this.selectedProductNames.length > index) {
      this.selectedProductNames.splice(index, 1);
    }
  
    if (this.selectedProductPrice && this.selectedProductPrice.length > index) {
      this.selectedProductPrice.splice(index, 1);
    }
  
    // إعادة حساب الملخص
    this.summarycalculation();
  
    // التحقق من إعادة تفعيل اشتراك ضريبة القيمة المضافة إذا أصبحت المصفوفة فارغة
    if (this.invproducts.length == 0) {
      this.resumeVatPercentageSubscription();
    }
  
    // إغلاق المودال النشط
    this.closeActiveModal();
  
    // فتح مودال اختيار المنتج
  }
  // Removeproducttwo(index: any): void {
  //   // إزالة المنتج من المصفوفة invproducts
  //   this.invproducts.removeAt(index);

  //   // حذف اسم المنتج من المصفوفة selectedProductNames
  //   if (this.selectedProductNames && this.selectedProductNames.length > index) {
  //     this.selectedProductNames.splice(index, 1);
  //   }

  //   if (this.selectedProductPrice && this.selectedProductPrice.length > index) {
  //     this.selectedProductPrice.splice(index, 1);
  //   }

  //   // إعادة حساب الملخص
  //   this.summarycalculation();

  //   // التحقق من إعادة تفعيل اشتراك ضريبة القيمة المضافة إذا أصبحت المصفوفة فارغة
  //   if (this.invproducts.length == 0) {
  //     this.resumeVatPercentageSubscription();
  //   }


  // }
  summarycalculation() {
    const lines = this.invoiceform.get("lines") as FormArray;
  
    let sumSubTotal = 0;
    let sumDiscount = 0;
    let sumVat = 0;
    let sumNet = 0;
    let sumTotal = 0;
  
    lines.controls.forEach((ctrl: AbstractControl) => {
      const parseNum = (v: any, fallback = 0) =>
        isNaN(parseFloat(v)) ? fallback : parseFloat(v);
  
      const quantity = parseNum(ctrl.get("quantity")?.value, 1);
      const discount = parseNum(ctrl.get("discount")?.value, 0);
      const vatRate = parseNum(ctrl.get("vat_rate")?.value, this.VatPercentage || 18);
      const totalAmount = parseNum(ctrl.get("total_amount")?.value, 0);
  
      // If totalAmount is final (VAT included), net = totalAmount / (1 + vatFraction)
      const vatFraction = vatRate / 100;
      let net = totalAmount / (1 + vatFraction);
      let subTotal = net + discount; // Because net = subTotal - discount
      let vat = net * vatFraction;
  
      sumSubTotal += subTotal;
      sumDiscount += discount;
      sumVat += vat;
      sumNet += net;
      sumTotal += totalAmount;
    });
  
    // Round sums
    const round = (x: number) => Math.round(x * 100) / 100;
    sumSubTotal = round(sumSubTotal);
    sumDiscount = round(sumDiscount);
    sumVat = round(sumVat);
    sumNet = round(sumNet);
    sumTotal = round(sumTotal);
  
    // Update invoice form fields
    this.invoiceform.get("amount_before_discount")?.setValue(sumSubTotal);
    this.invoiceform.get("all_discount")?.setValue(sumDiscount);
    this.invoiceform.get("payment_amount")?.setValue(sumNet);
    this.invoiceform.get("all_vat_amount")?.setValue(sumVat);
    this.invoiceform.get("payment_amount_including_vat")?.setValue(sumTotal);
  }
}
