<div class="dropdown-wrapper" [ngClass]="{'cursor-disabled': companiesLoading}">
  <i class="pi pi-building"></i>
  <p-dropdown 
    [options]="filteredCompanies" 
    [(ngModel)]="selectedCompany" 
    [placeholder]="(companiesLoading ? loadingPlaceholder : placeholder) | translate" 
    [showClear]="true"
    [dropdownIcon]="companiesLoading ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-down'" 
    [filter]="true"
    filterBy="label" 
    [autofocus]="true" 
    (onChange)="onCompanyChange($event)" 
    (onFilter)="onFilter($event)">
  </p-dropdown>
</div>
