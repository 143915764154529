<div ngbDropdown class="lang languages">
  <div class="language languages__current">
    <button class="mx-3 dropdown-button languages__button" id="dropdownConfig" ngbDropdownToggle>
      <i class="flag-icon flag-icon-{{selectedLanguage.icon}} languages__icon"></i>
      <span class="languages__text">{{ 'brand.' + selectedLanguage.language | translate }}</span>
    </button>
  </div>
  <div ngbDropdownMenu aria-labelledby="dropdownConfig" class="languages__menu">
    <a class="dropdown-item languages__item" (click)="changeLang('ar')">
      <i class="flag-icon flag-icon-sa languages__icon"></i>
      <span class="languages__text">{{ 'brand.Arabic' | translate }}</span>
    </a>
    <a class="dropdown-item languages__item" (click)="changeLang('en')">
      <i class="flag-icon flag-icon-us languages__icon"></i>
      <span class="languages__text">{{ 'brand.English' | translate }}</span>
    </a>
    <a class="dropdown-item languages__item" (click)="changeLang('he')">
      <i class="flag-icon flag-icon-il languages__icon"></i>
      <span class="languages__text">{{ 'brand.Hebrew' | translate }}</span>
    </a>
  </div>
</div>