<app-breadcrumb [title]="curentLang === 'en' ? 'Merchant Info' : (curentLang === 'ar' ? ' معلومات التاجر  ' : '  מידע סוחר')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['   לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Merchant Info' : (curentLang === 'ar' ? ' معلومات التاجر  ' : '  מידע סוחר')">
</app-breadcrumb>


<div class="container-fluid ">
    <div class="edit-profile">
      <div class="row">
        <div class="col-xl-4 ">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title mb-0" style="color:  grey;"> 
                {{curentLang === 'en' ? 'Merchant Info' : (curentLang === 'ar' ? ' معلومات التاجر  ' : '  מידע סוחר')}}
            </h5>
              <div class="card-options">
                <a class="card-options-collapse" href="javascript:void(0)" data-bs-toggle="card-collapse"><i class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="javascript:void(0)" data-bs-toggle="card-remove"><i class="fe fe-x"></i></a>
              </div>
            </div>
            <div class="card-body">
              <form  [formGroup]=" updateMerchant" novalidate [ngClass]="{ 'was-validated': validate }">
                <div class="row mb-2">
                  <!-- <div class="col-auto"><img class="img-70 rounded-circle" alt="" src="assets/images/user/7.jpg" /></div> -->
                </div>

                <div class="mb-3">
                  <label class="form-label" style="color: grey;"> 
                    {{ curentLang === 'en' ? 'Merchant Name Ar' : (curentLang === 'ar' ? '  اسم التاجر عربي   ' : 'שם סוחר ')}}
                 </label>
                  <input style="color: grey;" onlyAlphabets readonly
                   class="form-control" maxlength="80"  required=""
                   type="text" formControlName="merchant_name_ar" 
                    [(ngModel)]="MerchantNameAr"/>
                    <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter merchant name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم التاجر ' : 'נא להזין את שם הסוחר')}}  </div>

                </div>

                <div class="mb-3">
                    <label class="form-label" style="color: grey;"> 
                      {{ curentLang === 'en' ? 'Merchant Name En' : (curentLang === 'ar' ? '  اسم التاجر انجليزي   ' : 'שם סוחר בערבית ')}}
                   </label>
                    <input style="color: grey;" onlyAlphabets readonly
                    class="form-control"
                     type="text"  required=""
                    maxlength="80" 
                      formControlName="merchant_name_en"
                       [(ngModel)]=" MerchantNameEn"/>
                       <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter merchant name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم التاجر ' : 'נא להזין את שם הסוחר')}}  </div>

                  </div>

                  <div class="mb-3">
                    <label class="form-label" style="color: grey;"> 
                      {{ curentLang === 'en' ? 'Merchant Name He' : (curentLang === 'ar' ? '  اسم التاجر عبري   ' : 'שם סוחר אנגלית')}}
                   </label>
                    <input style="color: grey;" class="form-control" onlyAlphabets readonly
                     type="text" 
                     formControlName="merchant_name_he"  required=""
                     maxlength="80" 
                     [(ngModel)]=" MerchantNameHe"/>
                     <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter merchant name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم التاجر ' : 'נא להזין את שם הסוחר')}}  </div>

                  </div>

                  <div class="mb-3">
                    <label class="form-label" style="color: grey;"> 
                      {{ curentLang === 'en' ? 'Merchant Address En' : (curentLang === 'ar' ? '  عنوان التاجر انجليزي   ' : ' כתובת עוסק   ')}}
                   </label>
                    <input style="color: grey;"  readonly
                    class="form-control" type="text" 
                     formControlName="merchant_address" onlyAlphabets
                     maxlength="400" required=""
                       [(ngModel)]=" MerchantAddress"/>
                       <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter merchant address' : (curentLang === 'ar' ? '   الرجاء ادخال عنوان التاجر ' : 'נא להזין את כתובת הסוחר')}}  </div>

                  </div>

                  <div class="mb-3">
                    <label class="form-label" style="color: grey;"> 
                      {{ curentLang === 'en' ? 'Id Number' : (curentLang === 'ar' ? ' رقم الهوية  ' : ' מספר ישות (ת.ז / ח.פ.)  ')}}
                   </label>
                    <input style="color: grey;" onlyAlphabets
                    class="form-control" type="text"  readonly
                     formControlName="id_number"
                     maxlength="400" required=""
                       [(ngModel)]=" IdNumber"/>
                       <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter id number  ' : (curentLang === 'ar' ? '   الرجاء ادخال رقم الهوية ' : '  נא להזין את מספר תעודת הזהות שלך')}}  </div>

                  </div>

                  <!-- <div class="form-footer" style="margin-top: 74px;">
                    <button class="btn d-block w-100 me-1" style=" background-color: #213FA6;color: #fff;" (click)="updateMerchantById()"> 
                      {{ curentLang === 'en' ? 'Update' : (curentLang === 'ar' ? 'تعديل ' : 'עדכן')}}
                      <i class="fa fa-check-circle mx-1"></i>
                    </button>
                    </div> -->
              </form>
            </div>
          </div>
        </div>
       
        <div class="col-md-8 ">
          <div class="card ">
              <div class="card-header mx-4">
                  <h5 style="color: grey;">  {{ curentLang === 'en' ? 'Subscription Plan Details' : (curentLang === 'ar' ? ' تفاصيل خطة الأشتراك  ' : 'פרטי החבילה')}}</h5>
                </div>
              <div class="card-body">
          <div class="row">
            <div class="col-xxl-4 col-sm-6 box-col-6 " >
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass }}" >
                  <span class="f-light">
                      {{ curentLang === 'en' ? 'Plan Name' : (curentLang === 'ar' ? '  اسم الخطة' : '  שם החבילה ')}}
                  </span>
                  <div class="d-flex align-items-end gap-1">
                    <h4 style="color: grey;">
                      {{curentLang === 'en' ? planNameEn : (curentLang === 'ar' ? planNameAr : planNamehE)}}
                  </h4>

                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <!---->
            <div class="col-xxl-4 col-sm-6 box-col-6 " >
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass }}" >
                  <span class="f-light">
                      {{ curentLang === 'en' ? 'Price' : (curentLang === 'ar' ? '  سعر الخطة' : '  מחיר חבילה')}}
                  </span>
                  <div class="d-flex align-items-end gap-1">
                    <h4 style="color: grey;">
                      {{Price}}
                  </h4>

                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon3 }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <!---->
            <div class="col-xxl-4 col-sm-6 box-col-6 " >
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass }}" >
                  <span class="f-light">
                      {{ curentLang === 'en' ? ' Number Of Documents' : (curentLang === 'ar' ? ' عدد المستندات' : '  מספר מסמכים')}}
                  </span>
                  <div class="d-flex align-items-end gap-1">
                    <h4 style="color: grey;">
                      {{numberOfDocuments}}
                  </h4>

                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <!---->
            <div class="col-xxl-4 col-sm-6 box-col-6 " >
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass }}" >
                  <span class="f-light">
                      {{ curentLang === 'en' ? ' Number Of Companies' : (curentLang === 'ar' ? ' عدد الشركات' : 'מס חברות')}}
                  </span>
                  <div class="d-flex align-items-end gap-1">
                    <h4 style="color: grey;">
                      {{numberOfCompany}}
                  </h4>

                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <!---->
            <div class="col-xxl-4 col-sm-6 box-col-6 " >
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass }}" >
                  <span class="f-light">
                      {{ curentLang === 'en' ? ' Number Of Users' : (curentLang === 'ar' ? ' عددالمستخدمين' : 'מס משתמשים')}}
                  </span>
                  <div class="d-flex align-items-end gap-1">
                    <h4 style="color: grey;">
                      {{numberOfUsers}}
                  </h4>

                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon2 }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <!---->
            <!-- <div class="col-xxl-4 col-sm-6 box-col-6 " >
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass }}" >
                  <span class="f-light">
                      {{ curentLang === 'en' ? ' Amount Paid' : (curentLang === 'ar' ? '  مبلغ الدفع' : '  כמות שולת ')}}
                  </span>
                  <div class="d-flex align-items-end gap-1">
                    <h4 style="color: grey;">
                      {{amountPaid}}
                  </h4>

                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon3 }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div> -->
            <!---->
            <div class="col-xxl-4 col-sm-6 box-col-6 " >
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass }}" >
                  <span class="f-light">
                      {{ curentLang === 'en' ? '  Payment Type  ' : (curentLang === 'ar' ? ' نوع الدفع ' : 'סוג תשלום')}}
                  </span>
                  <div class="d-flex align-items-end gap-1">
                    <h6 style="color: grey;">
                      {{paymentType}}
                  </h6>

                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <!---->
            <div class="col-xxl-4 col-sm-6 box-col-6 " >
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass }}" >
                  <span class="f-light">
                      {{ curentLang === 'en' ? 'Subscribe Plan Status' : (curentLang === 'ar' ? ' حالة خطة الأشتراك' : '  סטטוס החבילה     ')}}
                  </span>
                  <div class="d-flex align-items-end gap-1">
                      <h4 style="color: green;" *ngIf="status == 1">
                          {{ curentLang === 'en' ? 'Active' : (curentLang === 'ar' ? ' نشط' : ' פעיל ') }}
                      </h4>
                      <h4 style="color: red;" *ngIf="status == 59">
                          {{ curentLang === 'en' ? 'Active' : (curentLang === 'ar' ? ' نشط' : ' פעיל ') }}
                      </h4>
                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <!---->
            <div class="col-xxl-4 col-sm-6 box-col-6 " >
              <div class="card small-widget">
                <div class="card-body {{ newOrders.colorClass }}" >
                  <span class="f-light">
                      {{ curentLang === 'en' ? 'Subscribe Status' : (curentLang === 'ar' ? ' حالة الأشتراك' : '  סטטוס ההרשמה     ')}}
                  </span>
                  <div class="d-flex align-items-end gap-1">
                      <h4 style="color: green;" *ngIf="lookup_detail_id == 99">
                          {{ curentLang === 'en' ? 'Subscribed' : (curentLang === 'ar' ? ' مشترك' : '  נרשמ ') }}
                      </h4>
                      <h4 style="color: red;" *ngIf="lookup_detail_id == 90">
                          {{ curentLang === 'en' ? SubscribeStatusEN : (curentLang === 'ar' ? SubscribeStatusAr : SubscribeStatushE) }}
                      </h4>
                  </div>
                  <div class="bg-gradient">
                    <svg class="stroke-icon svg-fill">
                      <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <!---->
          </div>
        </div>
        <!--back-->
        <div class=" d-flex justify-content-end"> 
          <a routerLink="/dashboard/merchant-info">
            <button class="btn btn-danger m-2 ml-auto" >
              {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
              <i class="fa fa-rotate-left mx-1"></i>
            </button>
          </a>
        </div>
          </div>

    </div>
      
    </div>
    
  </div>

</div>



