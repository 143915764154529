import { Injectable } from '@angular/core';
import { TimePeriodOption } from './alldocuments-period.model';
import { TIME_PERIOD_OPTIONS } from './time-period-options'; // Import the static data
import { TranslateService } from '@ngx-translate/core'; // Import TranslateService

@Injectable({
  providedIn: 'root'
})
export class TimePeriodService {
  constructor(private translate: TranslateService) {} // Inject TranslateService

  private readonly TIME_PERIOD_OPTIONS: TimePeriodOption[] = TIME_PERIOD_OPTIONS;

  getTimePeriodOptions(): TimePeriodOption[] {
    return this.TIME_PERIOD_OPTIONS.map(option => ({
      ...option,
      label: this.translate.instant(option.label) // Translate the label
    }));
  }

  getTimePeriodDates(option: TimePeriodOption): Date[] {
    const today = new Date();
    let startDate: Date;
    let endDate = new Date(); // Default end date is today

    // Calculate start date based on selected option
    switch (option.id) {
      case 'currentMonth':
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        break;
      case 'lastMonth':
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        endDate = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case 'last2Months':
        startDate = new Date(today.getFullYear(), today.getMonth() - 2, 1);
        break;
      case 'startOfYear':
        startDate = new Date(today.getFullYear(), 0, 1);
        break;
      case 'lastYear':
        startDate = new Date(today.getFullYear() - 1, 0, 1);
        endDate = new Date(today.getFullYear() - 1, 11, 31);
        break;
      case 'last2Years':
        startDate = new Date(today.getFullYear() - 2, 0, 1);
        break;
      default:
        return []; // Return empty array for unrecognized options
    }

    return [startDate, endDate];
  }

  formatTimePeriod(timePeriod: Date[] | null): string {
    if (!timePeriod || (!timePeriod[0] && !timePeriod[1])) {
      return '';
    }

    if (timePeriod[0] && timePeriod[1]) {
      return `${this.formatDate(timePeriod[0])} - ${this.formatDate(timePeriod[1])}`;
    } else if (timePeriod[0]) {
      return `From ${this.formatDate(timePeriod[0])}`;
    } else if (timePeriod[1]) {
      return `Until ${this.formatDate(timePeriod[1])}`;
    }

    return '';
  }

  private formatDate(date: Date): string {
    if (!date) return '';

    try {
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  }
}
