import { Plan } from "./subscription-plans.model";

export const plans: Plan[] = [
    {
        id: 0,
        name: "Free Plan",
        price: 0,
        companies: 1,
        users: 1,
        trial: 0,
        billing: "monthly",
        features: ["Basic Support", "Limited Access"],
        badge: "Free",
        color: "gray",
        icon: "free-icon",
        startDate: undefined,
        nextPaymentDate: undefined,
    },
    {
        id: 1,
        name: "Starter Plan",
        price: 50,
        companies: 2,
        users: 10,
        trial: 30,
        billing: "monthly",
        features: [
            "2 Companies", // Updated description for features
            "10 Users",
            "30 Days Trial",
            "Basic Support",
            "Email Notifications",
        ],
        description: "Small Team Access", // Keep original text for plan description
        startDate: new Date("2023-01-01"), // Dummy start date
        nextPaymentDate: new Date("2023-02-01"), // Dummy next payment date
    },
    {
        id: 2,
        name: "Advanced Plan",
        price: 100,
        companies: 5,
        users: 20,
        trial: 30,
        billing: "monthly",
        features: [
            "5 Companies", // Updated description for features
            "20 Users",
            "30 Days Trial",
            "Priority Support",
            "Email & SMS Notifications",
            "Advanced Analytics",
            "Custom Branding",
        ],
        description: "Medium Team Access", // Keep original text for plan description
        startDate: new Date("2023-01-15"), // Dummy start date
        nextPaymentDate: new Date("2023-02-15"), // Dummy next payment date
    },
    {
        id: 3,
        name: "Starter Plan",
        price: 42,
        companies: 2,
        users: 10,
        trial: 30,
        billing: "yearly",
        features: [
            "2 Companies", // Updated description for features
            "10 Users",
            "30 Days Trial",
            "Basic Support",
            "Email Notifications",
        ],
        description: "Small Team Access", // Keep original text for plan description
        startDate: new Date("2023-01-01"), // Dummy start date
        nextPaymentDate: new Date("2024-01-01"), // Dummy next payment date
    },
    {
        id: 4,
        name: "Advanced Plan",
        price: 90,
        companies: 5,
        users: 20,
        trial: 30,
        billing: "yearly",
        features: [
            "5 Companies", // Updated description for features
            "20 Users",
            "30 Days Trial",
            "Priority Support",
            "Email & SMS Notifications",
            "Advanced Analytics",
            "Custom Branding",
        ],
        description: "Medium Team Access", // Keep original text for plan description
        startDate: new Date("2023-01-15"), // Dummy start date
        nextPaymentDate: new Date("2024-01-15"), // Dummy next payment date
    },
];