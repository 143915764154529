<div class="mb-4 justify-content-between align-items-center d-none d-md-flex">
  <div class="actions">
    <div class="position-relative d-inline-block actions-new-document">
      <p-menu #newDocument [popup]="true" [model]="documentTypesMenu"></p-menu>
      <button pButton pRipple [label]="'allDocuments.actions.newDocument' | translate" icon="pi pi-file" iconPos="left"
        class="p-button-lg p-button-raised" (click)="newDocument.toggle($event)"></button>
    </div>
    <button pButton pRipple 
      [label]="(isPdfExporting ? 'allDocuments.actions.exportingPdf' : 'allDocuments.actions.exportPdf') | translate" 
      [icon]="isPdfExporting ? 'pi pi-spin pi-spinner' : 'pi pi-download'" iconPos="left"
      class="ms-3 p-button-lg p-button-raised p-button-secondary" (click)="onExportPdf()"
      [disabled]="isPdfExporting"></button>
  </div>
  <div>
    <button pButton pRipple [label]="'allDocuments.actions.resetColumns' | translate" icon="pi pi-sort-alt-slash" iconPos="left"
      class="p-button-outlined p-button-info me-2" (click)="onResetColumns()"
      *ngIf="showResetColumnsButton"></button>
    <button *ngIf="activeFiltersCount !== 0" pButton pRipple 
      [label]="('allDocuments.actions.clearFilters' | translate) + ' ' + activeFiltersCount + ' ' + ('allDocuments.actions.filters' | translate)"
      icon="pi pi-filter-slash" iconPos="left" class="p-button-outlined p-button-danger"
      (click)="onClearFilters()"></button>
  </div>
</div>

<p-speedDial class="speed-dial d-md-none" [model]="mobileDocumentsActions" radius="160" direction="up-left" type="quarter-circle"></p-speedDial>

<!-- New Document Dialog using PrimeNG -->
<p-dialog [(visible)]="displayDocumentTypeDialog" [modal]="true" [style]="{width: '90%', maxWidth: '650px'}"
  [draggable]="false" [resizable]="false" [header]="'modal.newDocument.title' | translate" [closeOnEscape]="true"
  styleClass="document-type-dialog">

  <p>{{ 'modal.newDocument.selectDocumentType' | translate }}</p>

  <div class="document-type-container">
    <div *ngFor="let docType of documentTypes" class="document-type-item d-flex align-items-center"
      (click)="selectDocumentType(docType)">
      <div class="document-icon">
        <i [class]="docType.icon"></i>
      </div>
            <div class="document-details flex-grow-1 ms-3">
              <h5>{{ docType.label | translate }}</h5>
              <p *ngIf="docType.description">{{ docType.description | translate }}</p>
            </div>
            <i class="pi pi-chevron-right"></i>
        </div>
    </div>
    <ng-template pTemplate="footer">
      <button pButton pRipple type="button" icon="pi pi-times" label="{{ 'modal.common.close' | translate }}"
        class="p-button-secondary" (click)="closeDocumentTypeDialog()"></button>
    </ng-template>
</p-dialog>
