import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TranszillaService {
    private apiUrl = 'https://api.transzilla.com'; // Replace with actual API URL

    constructor(private http: HttpClient) {}

    initiatePayment(data: any): Observable<any> {
        return this.http.post(`${this.apiUrl}/payments/initiate`, data);
    }

    validatePayment(paymentId: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/payments/validate/${paymentId}`);
    }

    completePayment(paymentId: string, data: any): Observable<any> {
        return this.http.post(`${this.apiUrl}/payments/complete/${paymentId}`, data);
    }

    subscribeToPlan(planId: string, userId: string): Observable<any> {
        const data = { planId, userId };
        return this.http.post(`${this.apiUrl}/subscriptions/create`, data);
    }

    cancelSubscription(subscriptionId: string): Observable<any> {
        return this.http.post(`${this.apiUrl}/subscriptions/cancel`, { subscriptionId });
    }

    getSubscriptionDetails(userId: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/subscriptions/details/${userId}`);
    }
}
