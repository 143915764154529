import { DOCUMENT } from "@angular/common";
import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "src/app/Auth/service/auth.service";
import { HttpHelpService } from "src/app/Data/service/http-help.service";
import Swal from 'sweetalert2';
import { passwordValidators } from './../../../../../validators/passwordValidators';

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  dataId:any;
	allInfo:any;
  curentLang:string="";
  profileData:any = [];
  updatePassword:FormGroup;
  public file: any;
  url: any; 
  files: File[] = [];
  currentImageUrl:any
  public openCart: boolean = false;

  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));

	toggleChat: boolean = true;
	toggleSingle: boolean = true;
	isLogin:boolean = false;
	public validate = false;
	public show: boolean = false;

	uploadImage=new FormGroup({
		file :new FormControl(''),  
		id:new FormControl(''),
	  });

	constructor(public router: Router ,
		 private authService:AuthService ,
		  private service:HttpHelpService ,
		public translate:TranslateService , 
		@Inject(DOCUMENT) private document: Document, 
		private spinner: NgxSpinnerService ,
		private modalService: NgbModal,
		private cdRef:ChangeDetectorRef ,) {

		authService.currentUser.subscribe(()=>{
			if(authService.currentUser.getValue() != null)
			{
			   this.isLogin = false;
			}
			else
			{
			  this.isLogin = true ;
			};
		  });
	      this.dataId = localStorage.getItem('user_id');

		    //Change Lang:
			this.curentLang = localStorage.getItem('curentLang');
			this.changeLanguage(this.curentLang)

			this.updatePassword=new FormGroup({
				currentPassword :new FormControl (''),
				password: new FormControl('', passwordValidators),
				user_id :new FormControl(this.userRegistId),   
				});
    }	
	toggleCart() {
		this.openCart = !this.openCart;
 	  }
	
    switchDirection(lang:string , dir:string)
	{
		document.body.setAttribute('direction', dir);
		this.document.getElementsByTagName('html')[0].lang = lang; 
        document.getElementsByTagName('html')[0].setAttribute('dir', dir);
		document.getElementsByTagName('html')[0].setAttribute('class',dir);
	}

	changeLanguage(lang:string)
	{ 

	  localStorage.setItem('curentLang', lang)
	  this.translate.use(lang);
	  if(lang == "en") {
		this.switchDirection('en', 'ltr');
	  }
	   else if(lang == "ar") 
	  {
		this.switchDirection('ar', 'rtl'); 
	  }
	  const currentUrl = this.router.url;
    this.router.navigateByUrl('/dashboard', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
    });
	};
	
	isLogOut()
    {
      this.authService.logout();
	//   localStorage.clear()
	  this.authService.isLogin.next(false)

	  this.router.navigate(['/login'])
	 localStorage.removeItem('user_id')
	 localStorage.removeItem('token')
	 localStorage.removeItem('user_type')
	 localStorage.removeItem('merchant_id')
	 localStorage.removeItem('allPrivilige')
	 localStorage.removeItem('userType_en')
	 localStorage.removeItem('routeId')
	 localStorage.removeItem('office_id')
	//   localStorage.removeItem('companyDefault')
	//   localStorage.removeItem('selectedCompanyLogo')
    };

	openXl(content:any) {
		this.modalService.open(content, { size: 'md' , centered:true });
	  };
	handlePasswordValidation(formGroup: FormGroup) {
		const passwordControl = formGroup.get('password');
		if (passwordControl) {
			passwordControl.markAsTouched();
		}
	}

	  showPassword() {
		this.show = !this.show;
		  const passwordInput = document.querySelector('input[name="login[password]"]') as HTMLInputElement;
		  if (passwordInput) {
			  passwordInput.focus();
		  }
		  this.handlePasswordValidation(this.updatePassword);
	  }
	
	  ChangeUserPasswordByUserId() {
		let password = this.updatePassword.value.password;
		if (password.length < 8 || password.length > 20) {
		  Swal.fire({
			icon: 'error',
			title: this.curentLang === 'ar' ? 'يجب أن تكون كلمة المرور أطول من أو تساوي 8 أحرف وأقل من أو تساوي 20 حرفًا' : 
				   (this.curentLang === 'en' ? 'Password must be longer than or equal to 8 characters and shorter than or equal to 20 characters' : 
				   (this.curentLang === 'he' ? 'הסיסמה חייבת להיות ארוכה מ-8 תווים וקצרה מ-20 תווים' : ''))
		  });
		  this.validate = !this.validate;
		  return;
		}
		let model = {
		  currentPassword: this.updatePassword.value.currentPassword,
		  password: this.updatePassword.value.password,
		  user_id: Number(this.userRegistId),
		};
	  
		this.spinner.show();
		this.service.ChangeUserPasswordByUserId( model).subscribe((res) => {
		  if (res.status === 200) {
			Swal.fire({
			  icon: 'success',
			  title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
			});
			this.modalService.dismissAll();
			this.getProfileInfo();

		  } else if (res.status === 400 || res.status === 403 || res.status === 500) {
			let errorMessage;
			switch (res.status) {
			  case 400:
				errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
				break;
			  case 403:
				errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
				break;
			  case 500:
				errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
				break;
			  default:
				errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
			}
			Swal.fire({
			  icon: 'error',
			  title: errorMessage
			});
		  }
		  this.spinner.hide();
		});
	
	
	};

	getProfileInfo() {
		this.spinner.show();
		this.service.getProfileInfo().subscribe({
		  next: res => {
			this.profileData = res;		
 
			const data =  this.profileData?.lookupDetailsUserType?.lookupDetailEnName;
 			  localStorage.setItem( "userType_en",data );
			 
		
			this.spinner.hide();
		  },
		  error: error => {
			this.spinner.hide();
		  }
		});
	  };
	
	  executeFunctions(userId: number, uploadData: any) {
		this.openVerticallyCentered2(uploadData , userId);
		this.setValue(userId);
		const selectedProfile = this.profileData;
		
		  if (selectedProfile) {
			this.currentImageUrl = selectedProfile?.image_url; 
		  } 
	  };

	  openVerticallyCentered2(uploadData:any , userId:any) {
		this.modalService.open(uploadData, { centered: true });
		userId = this.userRegistId;
	  
	  };

	  setValue(userId: number) {
		const userToUpdate = this.profileData; 
	  
		if (userToUpdate) {
		  this.uploadImage?.get('id')?.setValue(userToUpdate.company_id);
		  this.uploadImage?.get('file')?.setValue(this.currentImageUrl);
		}
	  };
	

	  onFileChanged(event: any) {
		this.file = event.target.files[0];
	  
	   if (event.target.files && event.target.files[0]) {
			  var reader = new FileReader();
	  
			  reader.onload = (event:any) => {
				this.currentImageUrl = event.target.result; 
				this.cdRef.detectChanges();
			  }
	  
			  reader.readAsDataURL(event.target.files[0]);
	  
		  };
	  };

	  uploadFile()
	  {
	  
		const formData= new FormData();
		if(this.file != null){
		  formData.append("file", this.file, this.file.name);
		}
		   this.spinner.show();
		 this.service.updateUserImage(this.userRegistId , formData  ).subscribe(({
		  next:response=>
		  {
			Swal.fire({
			  icon: 'success',
			  title: this.curentLang === 'ar' ? response.messageAr : (this.curentLang === 'en' ? response.messageEn : response.messageHe)
			});
			this.modalService.dismissAll();
			this.getProfileInfo();
			this.uploadImage.reset();
			this.spinner.hide();
		 if(response.status == 400) {
		  Swal.fire(response.message);
		  this.spinner.hide();
		}
	  },
	  error:error =>
	  {
		Swal.fire(error.message);
		this.spinner.hide();
	  }
		   
		 }));
	  };

	  ngOnInit() {

		this.getProfileInfo();
	  this.layoutVersion = localStorage.getItem("layout_version");
	   if (this.layoutVersion == null || this.layoutVersion == undefined) {
		 localStorage.setItem("layout_version", "light-only");
		 this.layoutVersion= "light-only"; 
	   } else {
		 this.layoutVersion = localStorage.getItem("layout_version"); 
		 if (this.layoutVersion === "dark-only") {
		   document.body.classList.add("dark-only");
		 } else {
		   document.body.classList.add("light-only");
	
		 }
	   }
	   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
	   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
	   this.user_type = JSON.parse(localStorage.getItem('user_type'));
	
	  };

 
}
