<h4>{{ 'allDocuments.filters.companies.title' | translate }}</h4>
<ng-container *ngIf="companies && companies.length">
  <div class="p-field-radiobutton" *ngFor="let company of companies | slice:0:(showAllCompanies ? companies.length : 3)">
    <label [for]="'mobile-company-' + company.value">
      <p-radioButton name="selectedCompany" 
                   [value]="company.code" 
                   [inputId]="'mobile-company-' + company.value"
                   [(ngModel)]="selectedValue"
                   (ngModelChange)="onCompanyChange($event)">
      </p-radioButton>
      {{company.label}}
    </label>
  </div>
  <a href="#" class="cursor-pointer" (click)="$event.preventDefault(); toggleShowAllCompanies()">
    {{ (showAllCompanies ? 'allDocuments.filters.companies.showLess' : 'allDocuments.filters.companies.showAll') |
    translate:{count: companies.length} }}
  </a>
</ng-container>
<ng-container *ngIf="!companies || !companies.length || loading">
  <div class="loading-wrapper">
    <p-progressSpinner [style]="{width: '30px', height: '30px'}" styleClass="custom-spinner" strokeWidth="4">
    </p-progressSpinner>
    <p>{{ 'allDocuments.filters.companies.loading' | translate }}</p>
  </div>
</ng-container>
