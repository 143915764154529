import { Directive, ElementRef, HostListener, OnInit, AfterViewChecked } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumberFormat]'
})
export class NumberFormatDirective implements OnInit, AfterViewChecked {
  private isFocused = false;
  private lastValue: any;

  constructor(
    private el: ElementRef,
    private control: NgControl
  ) {}

  ngOnInit() {
    const initialValue = this.control.value;
    if (initialValue) {
      this.formatValue(initialValue);
    }
    this.lastValue = this.control.value;

    // Subscribe to value changes for enabled controls
    this.control.valueChanges?.subscribe((newVal) => {
      if (!this.isFocused) {
        this.formatValue(newVal);
      }
      this.lastValue = newVal;
    });

    // Subscribe to status changes (fires when control becomes disabled/enabled)
    this.control.statusChanges?.subscribe(() => {
      if (!this.isFocused) {
        this.formatValue(this.control.value);
      }
    });
  }

  ngAfterViewChecked() {
    // Even if the control is disabled, check if the value changed externally and update it.
    if (!this.isFocused && this.control.value !== this.lastValue) {
      this.formatValue(this.control.value);
      this.lastValue = this.control.value;
    }
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value: string) {
    this.isFocused = true;
    if (value) {
      // Remove commas for easier editing.
      this.el.nativeElement.value = value.replace(/,/g, '');
    }
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string) {
    this.isFocused = false;
    this.formatValue(value);
  }

  private formatValue(value: any) {
    if (value == null || value === '') {
      this.el.nativeElement.value = '';
      return;
    }
    // Remove any existing commas, parse to float, then format
    const numericValue = parseFloat(value.toString().replace(/,/g, ''));
    if (!isNaN(numericValue)) {
      this.el.nativeElement.value = numericValue.toLocaleString();
    } else {
      this.el.nativeElement.value = '';
    }
  }
}