<p-selectButton class="filter-status flex-shrink-0" [options]="statuses"
  [ngModel]="selectedStatus" (ngModelChange)="onSelectedStatusChange($event)"
  optionLabel="label" optionValue="value" [ngClass]="currentStatusClass">
  <ng-template pTemplate="item" let-option>
    <span [ngClass]="{
      'p-button-icon': true, 
      'p-button-icon-left': selectedStatus === option.value,
      'status-confirmed': option.value === 'confirmed',
      'status-saved': option.value === 'saved',
      'status-cancelled': option.value === 'cancelled'
    }" [class]="option.icon"></span>
    <span class="p-button-label" *ngIf="selectedStatus === option.value">{{ option.label }}</span>
  </ng-template>
</p-selectButton>
