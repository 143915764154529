<p-breadcrumb [model]="breadcrumbs" class="primary-color"></p-breadcrumb>

<p-card header="{{'allDocuments.breadcrumbs.title' | translate}}">
<!-- Use the new actions component -->
<app-alldocuments-actions [activeFiltersCount]="countActiveFilters()"
	[showResetColumnsButton]="!filtersMobile && !sortingMobile && currentSorting.field !== 'created_date'"
	[isPdfExporting]="isPdfExporting" (clearMessages)="clearMessages()" (newDocument)="newDocument()"
	(exportPdf)="downloadPDF()" (clearFilters)="clearFilters()" (resetColumns)="resetColumns()"
	(navigateToNewDocument)="handleNavigateToNewDocument($event)">
</app-alldocuments-actions>

<!-- filters start -->
<div class="filters d-flex pb-3 ps-2 ps-lg-0 pt-2 pt-lg-0" [formGroup]="filter">
	<app-alldocuments-search class="flex-grow-1 d-flex" [initialValue]="filter.get('searchValue')?.value"
		placeholder="allDocuments.filters.search.placeholder" (search)="onSearchChange($event)" (clear)="onSearchClear()">
	</app-alldocuments-search>
	<div class="d-none d-md-flex">
		<app-alldocuments-customers [selectedCustomer]="filter.get('selectedCustomer')?.value" [currentLang]="curentLang"
			(customerChange)="onCustomerChange($event)" (customersLoaded)="onCustomersLoaded($event)">
		</app-alldocuments-customers>
	</div>
	<div class="d-none d-md-flex">
		<app-alldocuments-companies [selectedCompany]="filter.get('selectedCompany')?.value" [currentLang]="curentLang"
			(companyChange)="onCompanyChange($event)" (companiesLoaded)="onCompaniesLoaded($event)">
		</app-alldocuments-companies>
	</div>
	<div class="d-none d-md-flex">
		<app-alldocuments-types [selectedType]="filter.get('selectedDocumentType')?.value"
			(typeChange)="onDocumentTypeChange($event)" (documentTypesLoaded)="onDocumentTypesLoaded($event)">
		</app-alldocuments-types>
	</div>
	<div class="d-none d-md-flex">
		<app-alldocuments-period [selectedTimePeriod]="filter.get('selectedTimePeriod')?.value"
			(timePeriodChange)="onPeriodChange($event)">
		</app-alldocuments-period>
	</div>
	<button pButton pRipple (click)="sortingMobile = true" type="button"
		class="d-md-none p-button-outlined p-button-primary p-button-sm flex-shrink-0" icon="pi pi-sort-alt"></button>
	<button pButton pRipple (click)="filtersMobile = true" type="button"
		class="d-md-none p-button-outlined p-button-secondary p-button-sm flex-shrink-0">
		<i class="pi pi-filter p-text-secondary" pBadge [value]="countActiveFilters()" severity="danger"></i>
	</button>
	<div class="d-none d-md-flex">
		<!-- Replace the status selection with the new component -->
		<app-alldocuments-statuses [selectedStatus]="filter.get('selectedStatus')?.value"
			(statusChange)="onStatusChange($event)">
		</app-alldocuments-statuses>
	</div>
</div>
<!-- filters end -->
<!-- filters mobile start -->
<p-sidebar [(visible)]="filtersMobile" position="bottom" [baseZIndex]="10000" [style]="{height:'100%'}">
	<h3>{{ 'allDocuments.filters.title' | translate }}</h3>
	<hr>
	<div [formGroup]="filter">
		<h4>{{ 'allDocuments.filters.status' | translate }}</h4>
		<!-- Replace the status selection in mobile view with the new component -->
		<app-alldocuments-statuses [selectedStatus]="filter.get('selectedStatus')?.value"
			(statusChange)="onStatusChange($event)">
		</app-alldocuments-statuses>
		<br>
		<!-- Replace the customers code with the new component -->
		<app-alldocuments-customers-mobile [selectedCustomerId]="filter.get('selectedCustomer')?.value"
			[currentLang]="curentLang" [loading]="false" (customerChange)="onCustomerChange($event)">
		</app-alldocuments-customers-mobile>
		<app-alldocuments-companies-mobile [selectedCompanyId]="filter.get('selectedCompany')?.value" [currentLang]="curentLang"
			[loading]="false" (companyChange)="onCompanyChange($event)">
		</app-alldocuments-companies-mobile>
		<app-alldocuments-types-mobile [selectedType]="filter.get('selectedDocumentType')?.value"
			(typeChange)="onDocumentTypeChange($event)">
		</app-alldocuments-types-mobile>
		<!-- Replace the time period code with the new component -->
		<app-alldocuments-period-mobile [selectedTimePeriod]="filter.get('selectedTimePeriod')?.value"
			(timePeriodChange)="onPeriodChange($event)">
		</app-alldocuments-period-mobile>
	</div>
</p-sidebar>
<!-- filter mobile end -->
<!-- table start -->
<p-table [value]="documents" [paginator]="documents && documents.length > 0 && !isInfiniteScrolling"
	[rowsPerPageOptions]="[10,20,50,100,'All']" [rows]="rowsPerPage" responsiveLayout="scroll"
	[currentPageReportTemplate]="'allDocuments.table.showing' | translate" [globalFilterFields]="globalFilterFields"
	[totalRecords]="totalRecords" (onLazyLoad)="loadDocumentsLazy($event)" [lazy]="true"
	[showCurrentPageReport]="documents && documents.length > 0 && !isInfiniteScrolling"
	[resizableColumns]="resizableColumns" styleClass="p-datatable-gridlines" [columns]="selectedColumns"
	dataKey="code"
	(onColResize)="onColumnResize($event)">
<!-- Removed [contextMenu]="cm" and [(contextMenuSelection)]="selectedDocument" -->

	<ng-template pTemplate="header" let-columns>
		<tr class="table-row">
			<th *ngFor="let col of columns" [hidden]="!selectedColumns.includes(col) || col.visible === false" pResizableColumn
				[pSortableColumn]="col.field" [className]="col.field"
				[style]="col.field === 'status' ? {'width': '60px'} : col.field === 'actions' ? {'width': '40px'} : {}">
				{{col.header | translate}}
				<p-sortIcon class="d-none d-md-inline" field="{{col.field}}" *ngIf="col.field !== 'actions'"></p-sortIcon>
			</th>
			<th style="overflow: visible;">
				<p-multiSelect dropdownIcon="pi pi-sliders-v" [options]="colsOptions" [(ngModel)]="selectedColumns" optionLabel="header"
					selectedItemsLabel="{0}" placeholder="Choose Columns" [optionDisabled]="isColumnOptionDisabled">
					<ng-template pTemplate="item" let-column>
						<div class="column-option" [class.required-column]="column.required">
							<span>{{ column.header | translate }}</span>
							<small *ngIf="column.required" class="required-column-note">
								(Required)
							</small>
						</div>
					</ng-template>
				</p-multiSelect>
			</th>
		</tr>
	</ng-template>

	<ng-template pTemplate="body" let-document>
		<ng-container *ngIf="loading">
			<!-- Create multiple skeleton rows based on rows per page -->
			<tr class="table-row table-row--skeleton" *ngFor="let i of skeletonRowsArray">
				<!-- Create skeleton cells for each visible column -->
				<td *ngFor="let col of _selectedColumns" [className]="col.field">
					<p-skeleton width="100%" height="37px"></p-skeleton>
				</td>
				<!-- Add an extra skeleton cell for the actions column -->
				<td style="width: 60px; text-align: center;">
					<p-skeleton width="40px" height="37px" styleClass="p-d-block p-mx-auto"></p-skeleton>
				</td>
			</tr>
		</ng-container>
		<ng-container *ngIf="!loading">
			<tr [ngClass]="{
          'bg-confirmed': document.status === 'confirmed',
          'bg-saved': document.status === 'saved',
          'bg-cancelled': document.status === 'cancelled'
        }" class="table-row">
				<td (click)="onRowClick($event, document)" *ngFor="let col of _selectedColumns" [className]="col.field"
					[style]="col.field === 'status' ? {'text-align': 'center'} : {}">
					<span
						*ngIf="col.field !== 'status' && col.field !== 'invoice_type' && col.field !== 'confirmation_number' && col.field !== 'payment_amount'">{{document[col.field]}}</span>
					<span *ngIf="col.field === 'confirmation_number'">{{document[col.field] | slice:-9}}</span>
					<span *ngIf="col.field === 'payment_amount'">{{document[col.field] | number:'1.2-2'}}</span>
					<span *ngIf="col.field === 'invoice_type'">
						<i [class]="document.icon" class="me-2"></i>
						<span class="d-md-inline">{{document[col.field] | translate}}</span>
					</span>
					<span *ngIf="col.field === 'status'" [ngClass]="{
										              'pi': true,
										              'pi-check-circle status-confirmed': document.status === 'confirmed',
										              'pi-save status-saved': document.status === 'saved',
										              'pi-times-circle status-cancelled': document.status === 'cancelled'
										            }"></span>
					<span *ngIf="col.field === 'created_date'">
						{{ document?.createdDate }}
					</span>
				</td>
				<td style="text-align: center; width: 60px; position: relative; overflow: visible;">
					<button pButton pRipple type="button" icon="pi pi-ellipsis-v" class="p-button-rounded p-button-plain p-button-text"
						(click)="toggleMenu($event, document)"></button>
					<p-tieredMenu #menu [popup]="true" [model]="getFilteredActions(selectedDocument)" appendTo="body"></p-tieredMenu>
				</td>
			</tr>
		</ng-container>
	</ng-template>

	<!-- Add empty message template for when no documents are found -->
	<ng-template pTemplate="emptymessage">
		<ng-container *ngIf="!loading">
			<tr>
				<td [attr.colspan]="_selectedColumns.length + 1" class="text-center p-5">
					<div class="empty-state">
						<i class="pi pi-folder-open text-muted" style="font-size: 3rem;"></i>
						<h3>{{ 'allDocuments.table.noDocuments' | translate }}</h3>
						<p>
							{{ 'allDocuments.table.noMatchingDocuments' | translate }}
							<br>
							{{ 'allDocuments.table.adjustFilters' | translate }}
						</p>
						<button pButton pRipple label="{{ 'allDocuments.actions.clearFilters' | translate }}" icon="pi pi-filter-slash"
							class="p-button-outlined p-button-danger" (click)="clearFilters()"></button>
					</div>
				</td>
			</tr>
		</ng-container>
		<ng-container *ngIf="loading">
			<!-- Create multiple skeleton rows based on rows per page -->
			<tr class="table-row table-row--skeleton" *ngFor="let i of skeletonRowsArray">
				<!-- Create skeleton cells for each visible column -->
				<td *ngFor="let col of _selectedColumns" [className]="col.field">
					<p-skeleton width="100%" height="37px"></p-skeleton>
				</td>
				<!-- Add an extra skeleton cell for the actions column -->
				<td style="width: 60px; text-align: center;">
					<p-skeleton width="40px" height="37px" styleClass="p-d-block p-mx-auto"></p-skeleton>
				</td>
			</tr>
		</ng-container>
	</ng-template>
<!-- Add footer template for infinite scroll loading/complete indicators -->
<ng-template pTemplate="footer" *ngIf="isInfiniteScrolling">
	<tr>
		<td [attr.colspan]="_selectedColumns.length + 1" class="text-center p-3">
			<!-- Show spinner when loading more data -->
			<div *ngIf="isLoadingMore" class="loading-more-spinner">
				<i class="pi pi-spin pi-spinner" style="font-size: 2rem; color: #3B82F6;"></i>
				<p>{{ 'allDocuments.table.loadingMore' | translate }}</p>
			</div>

			<!-- Show completion message when all data loaded -->
			<div *ngIf="allDataLoaded && documents.length > 0" class="all-data-loaded">
				<i class="pi pi-check-circle" style="font-size: 1.5rem; color: #22C55E;"></i>
				<p>{{ 'allDocuments.table.allDocumentsLoaded' | translate }}</p>
			</div>
		</td>
	</tr>
</ng-template>

</p-table>
<!-- table end -->
<!-- modals start -->
<!-- Remove the PDF viewer modal template as it's now in its own component -->

<p-confirmDialog [style]="{width: '450px'}" styleClass="p-confirm-dialog"></p-confirmDialog>

<div class="menu-overlay" *ngIf="menuVisible" (click)="closeMenu()"></div>
<!-- Add the PDF viewer component -->
<app-alldocuments-view-pdf-modal></app-alldocuments-view-pdf-modal>

</p-card>

<p-toast></p-toast>

<!-- sorting mobile start -->
<p-sidebar [(visible)]="sortingMobile" position="bottom" [baseZIndex]="10000" [style]="{height:'100%'}">
	<h3>{{ 'allDocuments.sorting.title' | translate }}</h3>
	<hr>
	<h4>{{ 'allDocuments.sorting.sortOrder' | translate }}</h4>
	<p-toggleButton [(ngModel)]="sortOrder" onLabel="{{ 'allDocuments.sorting.ascending' | translate }}"
		offLabel="{{ 'allDocuments.sorting.descending' | translate }}" onIcon="pi pi-sort-amount-down"
		offIcon="pi pi-sort-amount-up" (onChange)="onSortOrderChange()"></p-toggleButton>
	<br><br>
	<h4>{{ 'allDocuments.sorting.sortFields' | translate }}</h4>
	<div class="p-field-radiobutton" *ngFor="let col of cols">
		<label for="col{{col.id}}" *ngIf="col.field !== 'created_date' && col.sortable">
			<p-radioButton name="sortField" inputId="col{{col.id}}" [value]="col.field" [(ngModel)]="sortField"
				(onChange)="onSortFieldChange()"></p-radioButton>
			{{col.header | translate}}
		</label>
	</div>
	<div class="mt-4 text-center">
		<button pButton pRipple type="button" label="{{ 'allDocuments.sorting.apply' | translate }}" icon="pi pi-check"
			class="p-button-success" (click)="applySortingFromMobile()"></button>
		<button pButton pRipple type="button" label="{{ 'allDocuments.sorting.cancel' | translate }}" icon="pi pi-times"
			class="p-button-text p-button-danger ml-2" (click)="sortingMobile = false"></button>
	</div>
</p-sidebar>
<!-- sorting mobile end -->
