import { Validators, ValidatorFn, AbstractControl } from "@angular/forms";

export const passwordValidators: ValidatorFn[] = [
  Validators.required,
  Validators.minLength(8),
  Validators.maxLength(20),
  (control: AbstractControl) => {
    const hasUppercase = /[A-Z]/.test(control.value);
    return hasUppercase ? null : { uppercase: true };
  },
  (control: AbstractControl) => {
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(control.value);
    return hasSpecialChar ? null : { specialChar: true };
  }
];
