import { Component, OnInit } from "@angular/core";
import Swal from 'sweetalert2';
import { ToastrService } from "ngx-toastr";
import { formatDate } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../service/http-help.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MerchantValidationService } from "../service/MerchantValidation.Service";
import { HttpClient } from "@angular/common/http";
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { baseUrl } from "../service/global.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-reverce-invoice",
  templateUrl: "./reverce-invoice.component.html",
  styleUrls: ["./reverce-invoice.component.scss"]
})

export class ReverceInvoiceComponent implements OnInit {
  curentLang: string = "";
  layoutVersion: any;
  userRegistId: any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  companyDefault = JSON.parse(localStorage.getItem('companyDefault'));

  invoiceId: any;
  companyId: any;
  term: any;
  p: any;
  companyData: any = [];
  merchantInfoData: any = [];
  merchantInfoCompanyData: any = [];
  invoicesData: any = [];
  currentPage: number = 1;
  limit: number = 400;
  offset: number = 0;
  total: number = 0;
  invoicesDataFilters: any = [];
  fromDate: string | null = null;
  toDate: string | null = null;
  selectedCompany:any;
  allCompaniesOption = { company_id: this.invoicesData, company_name_en: 'All Companies', company_name_ar: 'الكل', company_name_he: 'הכל' };
  customLimit: number = 400; 
  isValidMerchant: boolean = false; // متغير لحالة صلاحية التاجر
  merchant_id:any;
  selectedInvoiceUuid: string | null = null;
  openedIndex: number | null = null; 
  openedIndex2: number | null = null; 

  DocumentNumber: any;
  Confirm: any;
  invoiceInfoData: any = [];
  id: any;
  CompanyVat:any;
  MerchantNameEn:any;
  viewData: any;
  curentLangDocument: string = ''
  selectedPeriod: number = 1;
  documentForm: FormGroup ;
  CopyVersion:  any;
  pdfInvoiceId:  any;
  pdfUrl: SafeResourceUrl;  // استخدام SafeResourceUrl لتخزين الرابط الآمن
  rawPdfUrl: string;  // 
  PdfbaseUrl:  string = '';
  isSkelton:boolean = true
  PdfLink :  string = '';
  MaxAmount: any;
  Year: any;
  YearData:any =  new Date().getFullYear();
  constructor(
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    private service: HttpHelpService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private validationService: MerchantValidationService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.merchant_id = this.merchantId;

    this.documentForm = new FormGroup({
      companyId: new FormControl(this.selectedCompany,),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
    });
  }

  availableLanguages = [
    { code: "ar", name: "----" },
     { code: "en", name: "English" },
    { code: "ar", name: "Arabic" },
    { code: "he", name: "Hebrew" }
];

  copyLinkToClipboard(link: string) {
    const tempInput = document.createElement('input');
    document.body.appendChild(tempInput);
    tempInput.value = link;
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    
    // إظهار الرسالة
    const copyMessage = document.getElementById('copy-message');
    if (copyMessage) {
      copyMessage.style.display = 'block';
      
      // إخفاء الرسالة بعد 3 ثوانٍ
      setTimeout(() => {
        copyMessage.style.display = 'none';
      }, 3000); // 3000ms = 3 seconds
    }
  }
  
   
  openPdfModal(row: any, content: any) {
    // إذا لم يكن هناك رابط pdf_link، قم بتشغيل الهيكل العظمي
    if (!row.pdf_link) {
      this.isSkelton = true;
  
      // تكوين الرابط النهائي
      this.PdfbaseUrl = environment.PdfbaseUrl;
      const merchantId = JSON.parse(localStorage.getItem('merchant_id'));
      const companyId = row.company_id;
      const invoiceType = row.invoice_type;
      const invoiceUuid = row.invoice_uuid;
  
      // تكوين رابط PDF النهائي مع سلسلة استعلام ديناميكية
      const timestamp = new Date().getTime(); // طابع زمني فريد
      const generatedPdfLink = `${this.PdfbaseUrl}/merchant_${merchantId}/company_${companyId}/invoices/${invoiceType}/${invoiceUuid}.pdf?t=${timestamp}`;
      
      this.rawPdfUrl = generatedPdfLink;
  
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(generatedPdfLink);
  
      // إيقاف عرض الهيكل العظمي بعد ثانيتين وعرض الرابط
      setTimeout(() => {
        this.isSkelton = false;
      }, 4000);
    } else {
      // إذا كان pdf_link موجود، استخدم الرابط مباشرةً مع سلسلة استعلام ديناميكية
      const timestamp = new Date().getTime(); // طابع زمني فريد
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${row.pdf_link}?t=${timestamp}`);
      this.rawPdfUrl = `${row.pdf_link}?t=${timestamp}`;
  
      this.isSkelton = false;
    }
  
    // فتح المودال
    this.modalService.open(content, { size: 'xl' });
  }

  toggleCollapse(index: number): void {
    // إذا كان القسم المفتوح هو القسم الذي نضغط عليه، إغلاقه، وإلا فتح القسم الجديد
    this.openedIndex = this.openedIndex === index ? null : index;
     
    
  };

  toggleCollapse2(index: number): void {
    // إذا كان القسم المفتوح هو القسم الذي نضغط عليه، إغلاقه، وإلا فتح القسم الجديد
    this.openedIndex2 = this.openedIndex2 === index ? null : index;
     
    
  };
  validateMerchant() {
    this.validationService.validateMerchant(this.merchantId).subscribe(result => {
      if (result.status === 200) {
        this.isValidMerchant = true; // تعيين حالة صلاحية التاجر كصحيحة
      } else {
        this.isValidMerchant = false; // تعيين حالة صلاحية التاجر كغير صحيحة
        this.toastr.error(result.messageAr || result.messageEn || result.messageHe);
      }
      });
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.offset = (page - 1) * this.limit;
    this.getInvoicesFilters(this.fromDate, this.toDate, this.limit, this.offset , this.term);
  }

  onLimitChange() {
    this.limit = this.customLimit;
    this.offset = (this.currentPage - 1) * this.limit;
    this.getInvoicesFilters(this.fromDate, this.toDate, this.limit, this.offset, this.term);
  }

  getInvoicesFilters(fromDate?: string, toDate?: string, limit?: number, offset?: number, invoice_type: any = 330 , term?: string) {
    limit = limit || this.limit;
    offset = offset || (this.currentPage - 1) * this.limit;

    this.spinner.show();

    this.service.getInvoicesFilters(fromDate, toDate, this.selectedCompany?.company_id, limit, offset, invoice_type , this.merchant_id , term).subscribe({
      next: (res) => {
        this.invoicesDataFilters = res.data;
         this.total = res.total;
         
        this.spinner.hide();
      },
      error: () => {
        this.spinner.hide();
      }
    });
  }

  loadData() {
    this.getInvoicesFilters(this.fromDate, this.toDate);
  }

  getAllCompaniesInfo(limit?: number, offset?: number) {
    limit = limit || this.limit;
    offset = offset || (this.currentPage - 1) * this.limit;
    this.spinner.show();
    this.service.getAllCompaniesInfo(limit, offset).subscribe({
      next: res => {
        this.companyData = res.data;
        this.total = res.total;
        this.spinner.hide();

        const companyDefaultId = localStorage.getItem('companyDefault');
        if (companyDefaultId) {
          this.selectedCompany = this.companyData.find(company => company.company_id == companyDefaultId);
          if (this.selectedCompany) {
            this.loadData();
          }
        } else {
          this.selectedCompany = this.allCompaniesOption;
          this.loadData();
        }
      },
      error: () => {
        this.spinner.hide();
      }
    });
  }

  getInvoicesByCompanyId(companyId: number, limit?: number, offset?: number) {
    limit = limit || this.limit;
    offset = offset || (this.currentPage - 1) * this.limit;
    this.spinner.show();
    this.service.getALLInvoiceByBasedMerchantId(limit, offset).subscribe({
      next: res => {
        this.invoicesData = res.invoice.filter(invoice => invoice.company_id === companyId);
        this.total = res.count;
        this.spinner.hide();
      },
      error: () => {
        this.spinner.hide();
      }
    });
  }
  
  filter(event: any) {
    this.selectedCompany = event;
    this.getInvoicesFilters(this.fromDate, this.toDate, this.limit, this.offset, undefined, this.term);
  };

  filterMonth(event: any) {
    const selectedValue = parseInt(event.target.value, 10);
    const currentDate = new Date();
  
    let fromDate: Date;
    let toDate: Date;
  
    switch (selectedValue) {
      case 1: // Current month
        fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        toDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        break;
      case 2: // Last month
        fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
        toDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        break;
      case 3: // Start of the year to the end of the current month
        fromDate = new Date(currentDate.getFullYear(), 0, 1);
        toDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        break;
      case 4: // Last year
        fromDate = new Date(currentDate.getFullYear() - 1, 0, 1);
        toDate = new Date(currentDate.getFullYear() - 1, 11, 31);
        break;
      case 5: // Last two full months (excluding the current month)
        fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1);
        toDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        break;
      default:
        return;
    }
  
    this.fromDate = this.formatDate(fromDate);
    this.toDate = this.formatDate(toDate);
    this.selectedPeriod = selectedValue;
  
    this.documentForm.patchValue({
      startDate: this.fromDate,
      endDate: this.toDate,
      period: selectedValue
    });
}
  
  


formatDate(date: Date): string {
  const day = ('0' + date?.getDate()).slice(-2);
  const month = ('0' + (date?.getMonth() + 1)).slice(-2);
  const year = date?.getFullYear();
  return `${day}/${month}/${year}`;
}
onFromDateChange(event: Date): void {
  this.fromDate = this.formatDate(event);
}

onToDateChange(event: Date): void {
  this.toDate = this.formatDate(event);
  }

  openVerticallyCentered(dataDeleted: any) {
    this.modalService.open(dataDeleted, { centered: true });
  }

  getId(invoiceId: number, companyId: number) {
    this.invoiceId = invoiceId;
    this.companyId = companyId;
  }

  deleteInvoiceById() {
    this.spinner.show();
    this.service.deleteInvoiceById(this.invoiceId).subscribe({
      next: (res) => {
        if (res.status == 400) {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            color: 'green',
            backdrop:true,
             
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          });
          
          Toast.fire({
            icon: 'error',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.spinner.hide();
          return;
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            color: 'green',
            backdrop:true,
             
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          });
          
          Toast.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
           
          this.getInvoicesFilters(String(this.limit), String((this.currentPage - 1) * this.limit));
          this.loadData();
          this.modalService.dismissAll();
          this.spinner.hide();
        }
      },
      error: (err) => {
        Swal.fire(err.messageAr);
        this.spinner.hide();
      }
    });
  }

  convertTheInvoiceToReverseInvoice(invoiceId: number) {
    this.spinner.show();
    this.service.convertTheInvoiceToReverseInvoice(invoiceId).subscribe({
      next: (res) => {
        if (res.status == 400) {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            color: 'green',
            backdrop:true,
             
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          });
          
          Toast.fire({
            icon: 'error',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.spinner.hide();
          return;
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            color: 'green',
            backdrop:true,
             
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          });
          
          Toast.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.getInvoicesFilters(String(this.limit), String((this.currentPage - 1) * this.limit));
          this.loadData();
          this.modalService.dismissAll();
          this.spinner.hide();
        }
      },
      error: (err) => {
        Swal.fire(err.messageAr);
        this.spinner.hide();
      }
    });
  }

  openVerticallyCenteredReverse(reverseInvoice: any) {
    this.modalService.open(reverseInvoice, { centered: true });
  }

  openVerticallyCenteredConfirmed(dataConfirm: any) {
    this.modalService.open(dataConfirm, { centered: true });
  }

  checkSequenceExists(companyId: number, docType: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.spinner.show();
      this.service.checkSequenceExists(companyId, docType).subscribe({
        next: res => {
          this.spinner.hide();
          resolve(res);
        },
        error: err => {
          this.spinner.hide();
          reject(err);
        }
      });
    });
  }

  async checkAndConvertToReverse(invoiceId: number, companyId: number) {
    try {
      const sequenceExists = await this.checkSequenceExists(companyId, '330');
   
      if (!sequenceExists) {
        const result = await Swal.fire({
          title: this.curentLang === 'en' ? 'Enter the starting number for the document sequence:' : this.curentLang === 'ar' ? 'أدخل الرقم الابتدائي لتسلسل المستند:' : 'הזן את המספר ההתחלתי של רצף המסמכים:',
          input: 'number',
          inputAttributes: {
            min: '1',
            step: '1'
          },
          inputValue: 1, // تعيين القيمة الافتراضية إلى 1
          showCancelButton: false,
          confirmButtonText: this.curentLang === 'en' ? 'Submit' : this.curentLang === 'ar' ? 'إرسال' : 'שלח',
          allowOutsideClick: false,
          allowEscapeKey: false,
          preConfirm: (value) => {
            if (!value || value < 1) {
              Swal.showValidationMessage(
                this.curentLang === 'en' ? 'Please enter a valid number greater than 0' : this.curentLang === 'ar' ? 'يرجى إدخال رقم صالح أكبر من 0' : 'אנא הזן מספר חוקי גדול מ-0'
              );
            }
          }
        });
  
        if (result.value) {
          await this.createSequence(companyId, '330', parseInt(result.value, 10));
        }
      }
      this.convertTheInvoiceToReverseInvoice(invoiceId);
    } catch (error) {
     }
  }
  
  createSequence(companyId: number, docType: string, startWith: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.service.createSequence(companyId, docType, startWith).subscribe({
        next: () => {
           

          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            color: 'green',
            backdrop:true,
             
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          });
          
          Toast.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? 'تم إنشاء التسلسل بنجاح': (this.curentLang === 'en' ? 'Sequence created successfully' : 'רצף נוצר בהצלחה')
          });
          resolve();
        },
        error: err => {
          reject(err);
        }
      });
    });
  }

  confirmInvoice() {
    this.spinner.show();
    this.service.confirmInvoice(this.invoiceId).subscribe((res) => {
      if (res.status === 200) {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          color: 'green',
          backdrop:true,
           
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        });
        
        Toast.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        this.modalService.dismissAll();
        this.getInvoicesFilters(String(this.limit), String((this.currentPage - 1) * this.limit));
        this.loadData();
      } else if (res.status == 400 || res.status == 403 || res.status == 500) {
        const errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          color: 'red',
          backdrop: true,
           
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          }
        });
        
        Toast.fire({
          icon: 'error',
          title: errorMessage
        });
      
      }
      this.spinner.hide();
    });
  }

  confirmAction() {
    this.getInvoicesFilters(this.fromDate, this.toDate, this.limit, this.offset, this.term);
  }

  ngOnInit() {
    this.validateMerchant();
     this.GetYear();
    this.getAllCompaniesInfo();
   // this.loadData();
    this.layoutVersion = localStorage.getItem("layout_version");
    if (this.layoutVersion == null || this.layoutVersion == undefined) {
      localStorage.setItem("layout_version", "light-only");
      this.layoutVersion = "light-only";
    } else {
      this.layoutVersion = localStorage.getItem("layout_version");
      if (this.layoutVersion === "dark-only") {
        document.body.classList.add("dark-only");
      } else {
        document.body.classList.add("light-only");
      }
    }
    this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));

    this.companyDefault = localStorage.getItem('companyDefault');
    if (this.companyDefault) {
      this.selectedCompany = this.companyDefault;
    }

    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  
    this.documentForm.patchValue({
      startDate: this.formatDate(startDate),
      endDate: this.formatDate(endDate)
    });
  
    this.fromDate = this.formatDate(startDate);
    this.toDate = this.formatDate(endDate);

   }


   openInvoiceXl(content4: any) {
    this.modalService.open(content4, { size: 'xl' }).result.then(() => {
     }, () => {
      
    });
  };

  openInvoiceXl2(content5: any) {
    this.modalService.open(content5, { size: 'xl' }).result.then(() => {
     }, () => {
      
    });
  };

  getInvoiceId(id:any) {
    this.invoiceId = id
    this.getPdfByInvoiceId()
    this.selectedInvoiceUuid = id; // تعيين الصف النشط
  
  };
  
  executeFunctions(ProductID: number, uploadData: any) {
    this.getId2(ProductID);
    this.getPdfByInvoiceId();
    this.viewData = uploadData;
   };

  getPdfByInvoiceId() {
    this.spinner.show();
    this.service.getPdfByInvoiceId(this.invoiceId).subscribe({
      next: res => {
        this.invoiceInfoData = res;
        this.PdfLink = this.invoiceInfoData.pdf_link

        this.Confirm = this.invoiceInfoData?.confirm;
        this.curentLangDocument = this.invoiceInfoData?.document_language;
        this.CopyVersion =  this.invoiceInfoData?.copy_version;
        this.DocumentNumber = this.invoiceInfoData?.document_number;
        this.CompanyVat = this.invoiceInfoData?.company_vat;
        this.MerchantNameEn = this.invoiceInfoData?.merchant?.merchant_name_en;
        this.pdfInvoiceId  = this.invoiceInfoData?.invoice_id;
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  }

  downloadDocumentPdf() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
      color: 'green',
      backdrop: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
    Toast.fire({
      icon: 'success',
      title: this.curentLang === 'ar' ? 'جاري الآن تجهيز الملف' : (this.curentLang === 'en' ? 'The file is now being prepared' : 'הקובץ נמצא כעת בהכנה')
    });
    this.service.downloadDocumentPdf(this.pdfInvoiceId, this.curentLangDocument).subscribe({
      next: res => {
        this.spinner.hide();
        
        // إنشاء رابط لتحميل الـ PDF
        const blob = new Blob([res], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `Invoice-${this.pdfInvoiceId}.pdf`; // تحديد اسم الملف
        link.click();
  
        // إطلاق الـ URL لتجنب أي تسرب في الذاكرة
        window.URL.revokeObjectURL(url);
        this.getPdfByInvoiceId();
      },
      error: error => {
        this.spinner.hide();
        console.error('Error downloading the file', error);
      }
    });
  }

  downloadOriginalDocPdfFromLink() {
    fetch(this.PdfLink)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `Invoice-${this.pdfInvoiceId || 'Document'}.pdf`;
      link.click();
      window.URL.revokeObjectURL(url); // تنظيف URL المؤقت
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: this.curentLang === 'ar' ? 'حدث خطأ أثناء التنزيل' : (this.curentLang === 'en' ? 'An error occurred during download' : 'אירעה שגיאה במהלך ההורדה'),
        text: error.message
      });
    });
    this.updateVersionCopy();
    this.modalService.dismissAll()
  }

  filterDocumentLanguage(event: any) {
    this.curentLangDocument = event;
  }

  getId2(invoiceId: any ) {
    this.invoiceId = invoiceId;
    this.selectedInvoiceUuid = invoiceId; 
   }


   updateVersionCopy() {
    this.spinner.show();
    this.service.updateVersionCopy(this.invoiceId).subscribe({
      next: res => {
        this.invoiceInfoData = res;
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  }

  async generatePDF2() {
    if (this.invoiceInfoData?.confirm === 0) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'لا يمكن تصدير الفاتورة لأنها غير مؤكدة' : (this.curentLang === 'en' ? 'The invoice cannot be exported because it is not confirmed' : 'לא ניתן לייצא את החשבונית מכיוון שהיא לא אושרה')
      });
      return;
    }
  
    try {
      this.spinner.show();
      const pages = document.querySelectorAll('.page');
      const pdf = new jspdf.jsPDF('p', 'mm', 'a4');
  
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const xOffset = 10;
      const yOffset = 10;
      const pdfPageHeight = pageHeight - yOffset * 2;
      const totalNumberOfPages = pages.length;
  
      for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
        const pageElement = pages[pageIndex] as HTMLElement;
        const canvas = await html2canvas(pageElement, {
          scale: 2,
          useCORS: true,
          scrollX: 0,
          scrollY: -window.scrollY,
          windowWidth: pageElement.scrollWidth,
          windowHeight: pageElement.scrollHeight
        });
  
        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const imgHeight = (canvas.height * pageWidth) / canvas.width;
        let remainingHeight = canvas.height;
        let yPosition = 0;
  
        while (remainingHeight > 0) {
          if (yPosition > 0) {
            pdf.addPage();
          }
  
          const sliceHeight = Math.min(remainingHeight, pdfPageHeight * canvas.width / pageWidth);
  
          const canvasSlice = document.createElement('canvas');
          canvasSlice.width = canvas.width;
          canvasSlice.height = sliceHeight;
  
          const ctx = canvasSlice.getContext('2d');
          ctx.drawImage(canvas, 0, yPosition, canvas.width, sliceHeight, 0, 0, canvas.width, sliceHeight);
  
          const imgSlice = canvasSlice.toDataURL('image/jpeg', 1.0);
          pdf.addImage(imgSlice, 'JPEG', xOffset, yOffset, pageWidth - 2 * xOffset, (sliceHeight * pageWidth) / canvas.width);
  
          yPosition += sliceHeight;
          remainingHeight -= sliceHeight;
  
          // Adding page numbers
          pdf.setFontSize(10);
          pdf.text(`Page ${pageIndex + 1} of ${totalNumberOfPages}`, pageWidth - 20, pageHeight - 5);
        }
      }
  
      // Add company logo and signature on the last page
      const signatureImg = await this.getLogosignatureImgeData();
      const logoImg = await this.getLogoImageData();
  
      // Drawing a circle for the signature
      const signatureX = pageWidth - 200;
      const signatureY = pageHeight - 160;
      const signatureRadius = 20;
  
      // Add the signature image inside the circle
      pdf.addImage(signatureImg, 'PNG', signatureX, signatureY, 1.5 * signatureRadius, 2 * signatureRadius, undefined, 'FAST');
  
      // Add the company logo
      pdf.addImage(logoImg, 'PNG', pageWidth - 220, pageHeight - 15, 50, 10);
  
      // Add a border (line) below the signature image
      const lineYPosition = signatureY + 1.5 * signatureRadius + 3; // Position it slightly below the signature
      pdf.setLineWidth(0); // Optional: set line thickness
      const lineStartX = signatureX + 10; // Move the line start 10mm to the right
      const lineEndX = signatureX + 1 * signatureRadius + 10; // Adjust the line end accordingly
      pdf.line(signatureX, lineYPosition, signatureX + 2 * signatureRadius, lineYPosition); // Draw the line
  
      const pdfBlob = pdf.output('blob');
      await this.sendPdfToApi(pdfBlob, `thinvoices-${this.DocumentNumber}.pdf`);
      this.updateVersionCopy();
      this.getPdfByInvoiceId();
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: this.curentLang === 'ar' ? 'حدث خطأ أثناء تصدير الفاتورة' : (this.curentLang === 'en' ? 'An error occurred while exporting the invoice' : 'אירעה שגיאה במהלך ייצוא החשבונית')
      });
    }
  }

  async sendPdfToApi(pdfBlob: Blob, fileName: string) {
    const formData: FormData = new FormData();
    formData.append('file', pdfBlob, fileName);
    formData.append('companyName', this.CompanyVat); // الرقم الضريبي للشركه
    formData.append('signerName', this.MerchantNameEn); // اسم التاجر انجلش

    try {
      const response: Blob = await this.http.post(`${baseUrl}sign-pdf/sign`, formData, { responseType: 'blob' }).toPromise();
      this.downloadSignedPdf(response, `${fileName}`);
      // Swal.fire({
      //   icon: 'success',
      //   title: this.curentLang === 'ar' ? 'تم إرسال الفاتورة بنجاح' : (this.curentLang === 'en' ? 'Invoice sent successfully' : 'החשבונית נשלחה בהצלחה')
      // });
    } catch (error) {
      // Swal.fire({
      //   icon: 'error',
      //   title: this.curentLang === 'ar' ? 'حدث خطأ أثناء إرسال الفاتورة' : (this.curentLang === 'en' ? 'An error occurred while sending the invoice' : 'אירעה שגיאה במהלך שליחת החשבונית')
      // });
    }
  }

  downloadSignedPdf(blob: Blob, fileName: string) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  async getLogoImageData(): Promise<any> {
    const img = new Image();
    img.src = '../../../../assets/images/logo/thi.png'; // Adjust path as needed
    img.crossOrigin = 'Anonymous';
    img.style.width = '10px';
    img.style.height = '10px';
    await new Promise<void>(resolve => {
      img.onload = () => resolve();
    });
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL('image/png');
  }

  async getLogosignatureImgeData(): Promise<any> {
    const img = new Image();
    img.src = '../../../../assets/images/logo/12.png'; // Adjust path as needed
    img.crossOrigin = 'Anonymous';
    img.style.width = '10px';
    img.style.height = '10px';
    await new Promise<void>(resolve => {
      img.onload = () => resolve();
    });
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL('image/png');
  }

   openProceduresModal(dataProcedures: any) {
      this.modalService.open(dataProcedures, { centered: true , size: 'lg' });
    }
  
     InvoiceDecisionApiCancel() {
        this.spinner.show();
        this.service.InvoiceDecisionApiCancel(this.invoiceId).subscribe((res) => {
          if (res.status == 200) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              color: 'green',
              backdrop: true,
               
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            });
            
            Toast.fire({
              icon: 'success',
              title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
            });
            this.modalService.dismissAll();
            this.getInvoicesFilters(String(this.limit), String((this.currentPage - 1) * this.limit));
            this.loadData();
          } else if (res.status == 400 || res.status == 403 || res.status == 500) {
            const errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              color: 'red',
              backdrop: true,
               
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            });
            
            Toast.fire({
              icon: 'error',
              title: errorMessage
            });
          
          } else {
            const unknownError = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
            this.toastr.error(unknownError);
          }
          this.spinner.hide();
        });
      }
    
      InvoiceDecisionApiContinue() {
        this.spinner.show();
        this.service.InvoiceDecisionApiContinue(this.invoiceId).subscribe((res) => {
          if (res.status == 200) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              color: 'green',
              backdrop: true,
               
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            });
            
            Toast.fire({
              icon: 'success',
              title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
            });
            this.modalService.dismissAll();
            this.getInvoicesFilters(String(this.limit), String((this.currentPage - 1) * this.limit));
            this.loadData();
          } else if (res.status == 400 || res.status == 403 || res.status == 500) {
            const errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              color: 'red',
              backdrop: true,
               
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            });
            
            Toast.fire({
              icon: 'error',
              title: errorMessage
            });
          
          } else {
            const unknownError = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
            this.toastr.error(unknownError);
          }
          this.spinner.hide();
        });
      }
    
      InvoiceDecisionApiFurtherObjection() {
         this.spinner.show();
         this.service.InvoiceDecisionApiFurtherObjection(this.invoiceId).subscribe((res) => {
           if (res.status == 200) {
             const Toast = Swal.mixin({
               toast: true,
               position: 'top',
               showConfirmButton: false,
               timer: 3000,
               timerProgressBar: true,
               color: 'green',
               backdrop: true,
                
               didOpen: (toast) => {
                 toast.addEventListener('mouseenter', Swal.stopTimer);
                 toast.addEventListener('mouseleave', Swal.resumeTimer);
               }
             });
             
             Toast.fire({
               icon: 'success',
               title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
             });
             this.modalService.dismissAll();
             const Toast2 = Swal.mixin({
               toast: true,
               position: 'top',
               showConfirmButton: false,
               timer: 7000,
               timerProgressBar: true,
               color: 'green',
               backdrop: true,
                
               didOpen: (Toast2) => {
                 Toast2.addEventListener('mouseenter', Swal.stopTimer);
                 Toast2.addEventListener('mouseleave', Swal.resumeTimer);
               }
             });
             Toast.fire({
               icon: 'success',
               title: this.curentLang === 'ar' ? 'جاري تهيئتك لطلب جلسة استماع للفاتورة الرجاء الانتظار' : (this.curentLang === 'en' ? "You are being prepared to request a hearing session for the invoice, please wait." : "מכינים אותך לבקשת שימוע עבור החשבונית, אנא המתן."
                       )
             });
             const windowFeatures = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes,width=1200,height=800";
             window.open(res.link, '_blank', windowFeatures);
             this.getInvoicesFilters(String(this.limit), String((this.currentPage - 1) * this.limit));
             this.loadData();
           } else if (res.status == 400 || res.status == 403 || res.status == 500) {
             const errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
             const Toast = Swal.mixin({
               toast: true,
               position: 'top',
               showConfirmButton: false,
               timer: 3000,
               timerProgressBar: true,
               color: 'red',
               backdrop: true,
                
               didOpen: (toast) => {
                 toast.addEventListener('mouseenter', Swal.stopTimer);
                 toast.addEventListener('mouseleave', Swal.resumeTimer);
               }
             });
             
             Toast.fire({
               icon: 'error',
               title: errorMessage
             });
           
           } else {
             const unknownError = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
             this.toastr.error(unknownError);
           }
           this.spinner.hide();
         });
       }
    
      InvoiceAfterHearingApproval() {
        this.spinner.show();
        this.service.InvoiceAfterHearingApproval(this.invoiceId).subscribe((res) => {
          if (res.status == 200) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              color: 'green',
              backdrop: true,
               
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            });
            
            Toast.fire({
              icon: 'success',
              title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
            });
            this.modalService.dismissAll();
            this.getInvoicesFilters(String(this.limit), String((this.currentPage - 1) * this.limit));
            this.loadData();
          } else if (res.status == 400 || res.status == 403 || res.status == 500) {
            const errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              color: 'red',
              backdrop: true,
               
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            });
            
            Toast.fire({
              icon: 'error',
              title: errorMessage
            });
          
          } else {
            const unknownError = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
            this.toastr.error(unknownError);
          }
          this.spinner.hide();
        });
      }
      
    
      InvoiceReversingTheCharge() {
        this.spinner.show();
        this.service.InvoiceReversingTheCharge(this.invoiceId).subscribe((res) => {
          if (res.status == 200) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              color: 'green',
              backdrop: true,
               
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            });
            
            Toast.fire({
              icon: 'success',
              title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
            });
            this.modalService.dismissAll();
            this.getInvoicesFilters(String(this.limit), String((this.currentPage - 1) * this.limit));
            this.loadData();
          } else if (res.status == 400 || res.status == 403 || res.status == 500) {
            const errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              color: 'red',
              backdrop: true,
               
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            });
            
            Toast.fire({
              icon: 'error',
              title: errorMessage
            });
          
          } else {
            const unknownError = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
            this.toastr.error(unknownError);
          }
          this.spinner.hide();
        });
      };


        reSendInvoiceSecondToGovernmentMainProject(invoiceId:number) {
              this.spinner.show();
              this.service.reSendInvoiceSecondToGovernmentMainProject(invoiceId).subscribe((res) => {
                if (res.status == 200) {
                  const Toast = Swal.mixin({
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    color: 'green',
                    backdrop: true,
                     
                    didOpen: (toast) => {
                      toast.addEventListener('mouseenter', Swal.stopTimer);
                      toast.addEventListener('mouseleave', Swal.resumeTimer);
                    }
                  });
                  
                  Toast.fire({
                    icon: 'success',
                    title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
                  });
                  this.modalService.dismissAll();
                  this.getInvoicesFilters(String(this.limit), String((this.currentPage - 1) * this.limit));
                  this.loadData();
                } else if (res.status == 400 || res.status == 403 || res.status == 500) {
                  const errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                  const Toast = Swal.mixin({
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    color: 'red',
                    backdrop: true,
                     
                    didOpen: (toast) => {
                      toast.addEventListener('mouseenter', Swal.stopTimer);
                      toast.addEventListener('mouseleave', Swal.resumeTimer);
                    }
                  });
                  
                  Toast.fire({
                    icon: 'error',
                    title: errorMessage
                  });
                
                } else {
                  const unknownError = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
                  this.toastr.error(unknownError);
                }
                this.spinner.hide();
              });
            }
      
      
      
            GetYear() {
              this.spinner.show();
              this.service.GetYear(Number(this.YearData)).subscribe({
                next: res => {
                  this.YearData = res;
                  this.MaxAmount = Number(this.YearData.maxAmount )
                  
                  
                  this.Year = this.YearData.year
                  this.spinner.hide();
                },
                error: error => {
                  this.spinner.hide();
                }
              });
             
          }
}
