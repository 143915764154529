import { DocumentStatus, Column, TimePeriodOption } from './alldocuments.model';
import { DocumentType } from './alldocuments-types/alldocuments-types.model';

// Update DOCUMENT_STATUSES to use translation keys instead of hardcoded English labels
export const DOCUMENT_STATUSES: DocumentStatus[] = [
    { id: 1, label: 'documentStatuses.confirmed', value: 'confirmed', icon: 'pi pi-check-circle' },
    { id: 0, label: 'documentStatuses.saved', value: 'saved', icon: 'pi pi-save' },
    { id: 2, label: 'documentStatuses.cancelled', value: 'cancelled', icon: 'pi pi-times-circle' }
];

export const TABLE_COLUMNS: Column[] = [
    {
        id: 0,
        filterValue: 0,
        field: 'created_date',
        sortable: true,
        isSorted: true,
        sortOrder: 'DESC',
        visible: true
    },
    {
        id: 2,
        filterValue: 1,
        field: 'invoice_type',
        header: 'allDocuments.columns.invoice_type',
        sortable: true,
        visible: true
    },
    {
        id: 8,
        filterValue: 3,
        field: 'status',
        header: 'allDocuments.columns.status',
        sortable: true,
        visible: true,
        required: true // Mark status column as required
    },
    {
        id: 3,
        filterValue: 3,
        field: 'document_number',
        header: 'allDocuments.columns.document_number',
        sortable: true,
        visible: true
    },
    {
        id: 6,
        filterValue: 4,
        field: 'confirmation_number',
        header: 'allDocuments.columns.confirmation_number',
        sortable: true,
        visible: true
    },
    {
        id: 1,
        filterValue: 5,
        field: 'invoice_date',
        header: 'allDocuments.columns.invoice_date',
        sortable: true,
        visible: true
    },
    {
        id: 4,
        filterValue: 6,
        field: 'company_name',
        header: 'allDocuments.columns.company_name',
        sortable: true,
        visible: true
    },
    {
        id: 5,
        filterValue: 7,
        field: 'customer_name',
        header: 'allDocuments.columns.customer_name',
        sortable: true,
        visible: true
    },
    {
        id: 7,
        filterValue: 8,
        field: 'payment_amount',
        header: 'allDocuments.columns.payment_amount',
        sortable: true,
        visible: true
    }
];
