import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TimePeriodEvent, TimePeriodOption } from '../alldocuments-period.model';
import { TimePeriodService } from '../alldocuments-period.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alldocuments-period-mobile',
  templateUrl: './alldocuments-period-mobile.component.html',
  styleUrls: ['./alldocuments-period-mobile.component.scss']
})
export class AlldocumentsPeriodMobileComponent implements OnInit {
  @Input() selectedTimePeriod: Date[] | null = null;
  @Output() timePeriodChange = new EventEmitter<TimePeriodEvent>();

  timePeriodOptions: TimePeriodOption[] = [];
  translatedTimePeriodOptions: any[];
  
  constructor(
    private timePeriodService: TimePeriodService,
    private translate: TranslateService
  ) {}
  
  ngOnInit(): void {
    this.timePeriodOptions = this.timePeriodService.getTimePeriodOptions();
    this.translateTimePeriodOptions();
  }
  
  getFormattedTimePeriod(): string {
    return this.timePeriodService.formatTimePeriod(this.selectedTimePeriod);
  }
  
  onTimePeriodSelect(option: TimePeriodOption): void {
    const dates = this.timePeriodService.getTimePeriodDates(option);
    
    // Reset the selected state of all options
    this.timePeriodOptions.forEach(opt => opt.selected = false);
    
    // Mark the current option as selected
    option.selected = true;
    
    // Set the selected time period
    this.selectedTimePeriod = dates;
    
    // Emit the selected time period
    this.timePeriodChange.emit({
      value: dates,
      originalEvent: undefined
    });
  }
  
  applyTimePeriod(): void {
    this.timePeriodChange.emit({
      value: this.selectedTimePeriod,
      originalEvent: undefined
    });
  }

  translateTimePeriodOptions(): void {
    this.translatedTimePeriodOptions = this.timePeriodOptions.map(option => ({
      ...option,
      label: this.translate.instant(option.label)
    }));
  }

  /**
   * Handle calendar select events to ensure proper event emission
   */
  onCalendarSelect(event: any): void {
    if (this.selectedTimePeriod && this.selectedTimePeriod.length === 2) {
      this.timePeriodChange.emit({
        value: this.selectedTimePeriod,
        originalEvent: event
      });
    }
  }
}
