import { Component } from '@angular/core';
import { TranszillaService } from 'src/app/services/transzilla.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner'; // Import spinner service
import { MessageService } from 'primeng/api'; // Import MessageService

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  providers: [MessageService] // Add MessageService provider
})
export class CheckoutComponent {
  paymentData: any = {}; // Form data for payment
  paymentStatus: string | null = null;
  currentLang: string = 'en'; // Add this property and initialize it

  constructor(
    private transzillaService: TranszillaService,
    private translate: TranslateService, // Inject TranslateService
    private spinner: NgxSpinnerService, // Inject spinner service
    private messageService: MessageService // Inject MessageService
  ) {
    this.translate.setDefaultLang('en'); // Set default language
  }

  switchLanguage(lang: string): void {
    this.translate.use(lang); // Switch language dynamically
    localStorage.setItem('selectedLanguage', lang); // Save selected language
    this.currentLang = lang; // Update current language property
  }

  ngOnInit(): void {
    const savedLang = localStorage.getItem('selectedLanguage') || 'en'; // Default to English
    this.switchLanguage(savedLang); // Apply saved language on initialization
  }

  initiatePayment(): void {
    this.transzillaService.initiatePayment(this.paymentData).subscribe({
      next: (response) => {
        console.log('Payment initiated:', response);
        this.paymentStatus = 'Payment initiated successfully.';
      },
      error: (error) => {
        console.error('Error initiating payment:', error);
        this.paymentStatus = 'Failed to initiate payment.';
      }
    });
  }

  validatePayment(paymentId: string): void {
    this.transzillaService.validatePayment(paymentId).subscribe({
      next: (response) => {
        console.log('Payment validated:', response);
        if (response.status === 'validated') {
          this.paymentStatus = this.translate.instant('checkout.validationSuccess');
        } else {
          this.paymentStatus = this.translate.instant('checkout.validationError');
        }
      },
      error: (error) => {
        console.error('Error validating payment:', error);
        this.paymentStatus = this.translate.instant('checkout.validationError');
      }
    });
  }

  completePayment(paymentId: string): void {
    const completionData = { /* ...additional data... */ };
    this.transzillaService.completePayment(paymentId, completionData).subscribe({
      next: (response) => {
        console.log('Payment completed:', response);
        if (response.status === 'completed') {
          this.paymentStatus = this.translate.instant('checkout.completionSuccess');
        } else {
          this.paymentStatus = this.translate.instant('checkout.completionError');
        }
      },
      error: (error) => {
        console.error('Error completing payment:', error);
        this.paymentStatus = this.translate.instant('checkout.completionError');
      }
    });
  }

  subscribeToPlan(planId: string): void {
    const userId = 'currentUserId'; // Replace with actual user ID
    this.transzillaService.subscribeToPlan(planId, userId).subscribe({
      next: (response) => {
        console.log('Subscription successful:', response);
        this.paymentStatus = 'Subscription successful.';
      },
      error: (error) => {
        console.error('Error subscribing to plan:', error);
        this.paymentStatus = 'Failed to subscribe to plan.';
      }
    });
  }

  cancelSubscription(subscriptionId: string): void {
    this.transzillaService.cancelSubscription(subscriptionId).subscribe({
      next: (response) => {
        console.log('Subscription canceled:', response);
        this.paymentStatus = 'Subscription canceled successfully.';
      },
      error: (error) => {
        console.error('Error canceling subscription:', error);
        this.paymentStatus = 'Failed to cancel subscription.';
      }
    });
  }

  submitPayment(): void {
    this.spinner.show(); // Show spinner
    this.transzillaService.initiatePayment(this.paymentData).subscribe({
      next: (response) => {
        console.log('Payment initiated:', response);
        this.spinner.hide(); // Hide spinner
        if (response.status === 'success') {
          this.paymentStatus = this.translate.instant('checkout.paymentSuccess');
          this.messageService.add({ severity: 'success', summary: 'Success', detail: this.paymentStatus });
        } else {
          this.paymentStatus = this.translate.instant('checkout.paymentError');
          this.messageService.add({ severity: 'error', summary: 'Error', detail: this.paymentStatus });
        }
      },
      error: (error) => {
        console.error('Error initiating payment:', error);
        this.spinner.hide(); // Hide spinner
        this.paymentStatus = this.translate.instant('checkout.paymentError');
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.paymentStatus });
      }
    });
  }
}
