<div class="container">
    <div class="language-switcher">
        <button 
            [class.active]="currentLang === 'en'" 
            (click)="switchLanguage('en')">{{ 'brand.English' | translate }}</button>
        <button 
            [class.active]="currentLang === 'ar'" 
            (click)="switchLanguage('ar')">{{ 'brand.Arabic' | translate }}</button>
        <button 
            [class.active]="currentLang === 'he'" 
            (click)="switchLanguage('he')">{{ 'brand.Hebrew' | translate }}</button>
    </div>
    <h2>{{ 'checkout.title' | translate }}</h2>
    <form (ngSubmit)="submitPayment()" #paymentForm="ngForm">
      <div class="form-group">
        <label for="cardNumber">{{ 'checkout.cardNumber' | translate }}</label>
        <input type="text" id="cardNumber" name="cardNumber" class="form-control" [(ngModel)]="paymentData.cardNumber"
          required>
      </div>
      <div class="form-group">
        <label for="expiryDate">{{ 'checkout.expiryDate' | translate }}</label>
        <input type="text" id="expiryDate" name="expiryDate" class="form-control" [(ngModel)]="paymentData.expiryDate"
          required>
      </div>
      <div class="form-group">
        <label for="cvv">{{ 'checkout.cvv' | translate }}</label>
        <input type="text" id="cvv" name="cvv" class="form-control" [(ngModel)]="paymentData.cvv" required>
      </div>
      <div class="form-group">
        <label for="amount">{{ 'checkout.amount' | translate }}</label>
        <input type="number" id="amount" name="amount" class="form-control" [(ngModel)]="paymentData.amount" required>
      </div>
      <button type="submit" class="btn btn-primary">{{ 'checkout.payNow' | translate }}</button>
    </form>
    <p *ngIf="paymentStatus">{{ paymentStatus }}</p>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-spin-clockwise"></ngx-spinner>
    <p-toast></p-toast> <!-- Toast notifications -->
</div>