import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { HttpHelpService } from "../../service/http-help.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from "@angular/common";
import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { EMPTY, catchError, concatMap, of, throwError } from "rxjs";
import { israelPhoneNumberValidator } from "src/app/validators/israelPhoneNumberValidator";
import {
  AddCustomerEndPoient,
  AddProductEndPoient,
} from "../../service/global.service";

@Component({
  selector: 'app-update-invoice-with-receipt',
  templateUrl: './update-invoice-with-receipt.component.html',
  styleUrls: ['./update-invoice-with-receipt.component.scss']
})
export class UpdateInvoiceWithReceiptComponent implements OnInit {
  @ViewChild("chooseProductModal") chooseProductModal: TemplateRef<any>;

  @ViewChild("checkModal") checkModalRef!: TemplateRef<any>;
  @ViewChild("cashModal") cashModalRef!: TemplateRef<any>;
  @ViewChild("bankModal") bankModalRef!: TemplateRef<any>;
  @ViewChild("creditModal") creditModalRef!: TemplateRef<any>;
  onProductSelect(product: any, i: number) {
    if (!product || (Array.isArray(product) && product.length === 0)) {
      console.error("No product selected");
      return;
    }
  
    console.log("Product selected:", product);
  
    const selectedProduct = Array.isArray(product) ? product[0] : product;
    console.log("Extracted Product:", selectedProduct);
  
    let productName = selectedProduct.product_name_en || "Unknown";
    if (this.curentLang === "ar") {
      productName = selectedProduct.product_name_ar || "اسم غير معروف";
    } else if (this.curentLang === "he") {
      productName = selectedProduct.product_name_he || "שם לא ידוע";
    }
  
    // التأكد من تهيئة `FormArray`
    if (!this.invproducts || !this.invproducts.controls) {
      console.error("FormArray invproducts is not initialized properly");
      return;
    }
  
    // ✅ إضافة عنصر جديد فقط عند اختيار المنتج
    this.invproducts.push(
      this.fb.group({
        index: this.invproducts.length + 1,
        product_id: selectedProduct.product_id,
        category: selectedProduct.category || "",
        measure_unit_description: selectedProduct.measure_unit_description || "",
        measurement_unit_id: selectedProduct.measurement_unit_id || "",
        description: selectedProduct.description || "",
        catalog_id: selectedProduct.catalog_id || "",
        item_name: selectedProduct.item_name || "",
        quantity: 1,
        price_per_unit: parseFloat(selectedProduct.price) || 0,
        discount: 0,
        sub_total: parseFloat(selectedProduct.price) || 0,
        vat_rate: this.VatPercentage || 0,
        vat_amount: 0,
        total_amount: parseFloat(selectedProduct.price) || 0,
      })
    );
    console.log("New product form added!");
  
    // تحديث النموذج مع البيانات
    const lastIndex = this.invproducts.controls.length - 1;
    this.invproducts.controls[lastIndex].patchValue({
      product_id: selectedProduct.product_id,
      description: selectedProduct.des_en || "",
      price_per_unit: parseFloat(selectedProduct.price) || 0,
      quantity: 1,
      discount: 0,
      vat_amount: 0,
      total_amount: parseFloat(selectedProduct.price) || 0,
    });
    
    // Re-disable vat_amount after setting values
    this.invproducts.controls[lastIndex].get('vat_amount')?.disable();
  
    console.log(
      "Updated form with:",
      this.invproducts.controls[lastIndex].value
    );
  
    // افتح المودال بعد تحديد المنتج
    this.activeModal = this.modalService.open(this.chooseProductModal,this.modalOptions );
  
    // استدعاء دالة productchange بعد تحديث المنتج
    this.productchange(lastIndex); // تمرير الـ index أو الرقم المناسب
  }
  invoiceReceiptForm: FormGroup;
  masterBank: any;
  masterproduct: any;
  vatTypeList: any;
  collapsedIndexes: boolean[] = [];

  selectedProductNames: string[] = []; // مصفوفة لتخزين أسماء المنتجات
  selectedProductPrice: string[] = [];
  VatPercentage: any;
  curentLang: string;
  invoiceproduct: any;
  invoicedetail: any;
  invoiceId: number;
  mastercustomer: any;
  id: number;
  oneCustomerData: any;
  CustomerNameEn: any;
  CustomerNameHe: any;
  companyId: number;
  selectedPaymentMethodsSummary: { code: string; amount: number }[] = [];
  showFields: boolean = false;
  selectedProductIndex: number;

  CustomerNameAr: any;
  mastercompany: any;
  masterInvoiceTypeList: Object;
  vatPersentageData: any;
  cashType = 1;
  checkType = 2;
  bankTransferType = 4;
  masterCustomer: any;
  modalOptions: NgbModalOptions;

  creditCardType = 3;
  allBranchesData: any;
  uuId: any;
  YearData: any = new Date().getFullYear();
  MaxAmount: any;
  Year: any;
  availableLanguages: any = [
    { code: "en", name: "English" },
    { code: "ar", name: "Arabic" },
    { code: "he", name: "Hebrew" },
  ];
  userRegistId: any = JSON.parse(localStorage.getItem("user_id"));
  merchantId = JSON.parse(localStorage.getItem("merchant_id"));
  user_type = JSON.parse(localStorage.getItem("user_type"));
  companyDefault = JSON.parse(localStorage.getItem("companyDefault"));
  creditFilter: any;
  masterBranches: any = [];
  addCustomer: FormGroup;
  public validate = false;
  public validate2 = false;
  public paymentMethodConfirmed = false;
  isedit = false;

  customerTypeData: any = [];
  addProduct: FormGroup;
  selectedMonth: any;
  addedPayments: { [key: string]: number } = {};
  customerNameSelected: any;
  activeModal: any; // Add this property to store the modal reference

  creditModalOpen: boolean = false; // Added property to fix the issue
  selectedYear: any;
  modalRef: NgbModalRef | undefined;
  constructor(
    private builder: FormBuilder,
    private service: HttpHelpService,
    private router: Router,
    private alert: ToastrService,
    private activeroute: ActivatedRoute,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {
    this.curentLang = localStorage.getItem("curentLang");
    this.companyDefault = localStorage.getItem("companyDefault");

    this.addCustomer = this.fb.group({
      customer_name_he: ["", Validators.required],
      customer_name_en: [""],
      customer_name_ar: [""],
      customer_type: ["", Validators.required],
      customer_phone: [""],
      id_number: [null],
      customer_vat_number: [null],
      customer_vat_tax: [null],
      customer_address_str: [""], // جعل هذا الحقل اختياريًا
      customer_address_str_ar: [""], // جعل هذا الحقل اختياريًا
      customer_address_str_en: [""], // جعل هذا الحقل اختياريًا

      customer_address_city: [""], // جعل هذا الحقل اختياريًا
      customer_address_city_ar: [""], // جعل هذا الحقل اختياريًا
      customer_address_city_en: [""], // جعل هذا الحقل اختياريًا

      customer_address_zip_code: [""], // جعل هذا الحقل اختياريًا
      customer_Building_number: [""], // جعل هذا الحقل اختياريًا
    });

    this.addProduct = new FormGroup({
      product_name_en: new FormControl("", Validators.required),
      product_name_ar: new FormControl("", Validators.required),
      product_name_he: new FormControl("", Validators.required),
      des_ar: new FormControl("", Validators.required),
      des_en: new FormControl("", Validators.required),
      des_he: new FormControl("", Validators.required),
      product_code: new FormControl("", Validators.required),
      price: new FormControl("", Validators.required),
      measurement_unit_id: new FormControl("", Validators.required),
      categories_id: new FormControl("", Validators.required),
      merchant_id: new FormControl(this.merchantId),
      created_by: new FormControl(this.userRegistId),
    });
  }

  selectedProduct: any;
  selectedMeasurementUnit: any;
  categoriesData: any = [];
  unitMesauereData: any = [];
  formatDateForInput(dateString?: string): string {
    if (!dateString) return ''; // Handle empty or undefined dates
    const parts = dateString.split('/');
    if (parts.length === 3) {
      return `${parts[2]}-${parts[1].padStart(2, '0')}-${parts[0].padStart(2, '0')}`;
    }
    return ''; // Return empty string if format is incorrect
  }
  getAllActiveCategories() {
    this.spinner.show();
    this.service.getAllActiveCategories().subscribe({
      next: (res) => {
        this.categoriesData = res;

        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }
  paymentMethods = [
    { code: "CASH", name_en: "Cash", name_ar: "نقدي", name_he: "מזומן" },
    { code: "CHK", name_en: "Check", name_ar: "شيك", name_he: "שיק" },
    {
      code: "BT",
      name_en: "Bank Transfer",
      name_ar: "حوالة بنكية",
      name_he: "העברה בנקאית",
    },
    {
      code: "CC",
      name_en: "Credit Card",
      name_ar: "بطاقة ائتمان",
      name_he: "כרטיס אשראי",
    },
  ];

  getMethodLabel(code: string): string {
    switch (code) {
      case "CHK":
        return this.curentLang === "en"
          ? "Check"
          : this.curentLang === "ar"
          ? "شيك"
          : "שיק";
      case "CASH":
        return this.curentLang === "en"
          ? "Cash"
          : this.curentLang === "ar"
          ? "نقدي"
          : "מזומן";
      case "BT":
        return this.curentLang === "en"
          ? "Bank Transfer"
          : this.curentLang === "ar"
          ? "حوالة بنكية"
          : "העברה בנקאית";
      case "CC":
        return this.curentLang === "en"
          ? "Credit Card"
          : this.curentLang === "ar"
          ? "بطاقة ائتمان"
          : "כרטיס אשראי";
      default:
        return code;
    }
  }
  toggleFieldsVisibility(): void {
    this.showFields = !this.showFields;
  }
  editPaymentMethod(method: any) {
    switch (method.code) {
      case "CASH":
        this.openEditModal(this.invoiceReceiptForm.get('linesCash') as FormArray, method, this.cashModalRef);
        break;
      case "CHK":
        this.openEditModal(this.invoiceReceiptForm.get('linesChecks') as FormArray, method, this.checkModalRef);
        break;
      case "BT":
        this.openEditModal(this.invoiceReceiptForm.get('linesBankPayment') as FormArray, method, this.bankModalRef);
        break;
      case "CC":
        this.openEditModal(this.invoiceReceiptForm.get('linesCredit') as FormArray, method, this.creditModalRef);
        break;
      default:
        console.warn('Unknown payment method type:', method.code);
    }
  }
  closeAllModals() {
    this.modalService.dismissAll('Cross click');
    
  }
  openChooseProductModal2(chooseProductModal: any, index: number) {
    // Set the selected product index
    this.selectedProductIndex = index;
  
    // Open the modal with the same options
    this.activeModal = this.modalService.open(chooseProductModal, {
      backdrop: 'static',
      keyboard: false,
      size: 'xl'
    });
  }
  onVatCheckboxChange(index: number, event: any) {
    console.log(`Checkbox changed for index ${index}, checked: ${event.target.checked}`);
  
    this.invoicedetail = this.invoiceReceiptForm.get("lines") as FormArray;
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;
  
    if (!this.invoiceproduct) {
        console.error(`No FormGroup found at index ${index}`);
        return;
    }
  
    // Update the checkbox value in the form
    this.invoiceproduct.get("isVatIncluded")?.setValue(event.target.checked, { emitEvent: false });
    this.invoiceproduct.get("isVatIncluded")?.updateValueAndValidity();
  
    // Log to confirm update
    console.log(`isVatIncluded value updated: ${this.invoiceproduct.get("isVatIncluded")?.value}`);
  
    // Call the recalculation function
    this.Itemcalculation(index);
  }
  openEditModal(formArray: FormArray, method: any, modalRef: TemplateRef<any>) {
    console.log('Editing Method:', method);
    console.log('FormArray Controls:', formArray.controls.map(control => control.value));
  
    const targetPaymentType = String(method.code).toUpperCase(); // Ensure it's a string
    const targetAmount = parseFloat(method.amount || '0');
  
    console.log(`🔎 Searching for: { paymentType: '${targetPaymentType}', amount: ${targetAmount} }`);
  
    // Define mapping for numeric payment types to their actual codes
    const paymentTypeMap: { [key: string]: string } = {
      '1': 'CASH',
      '2': 'CHK',
      '3': 'CC',
      '4': 'BT'  // This maps '4' to 'BT'
    };
  
    const index = formArray.controls.findIndex((control) => {
      const formGroup = control as FormGroup;
      let storedPaymentType: any = formGroup.get('payment_type')?.value;
  
      // Convert numeric payment types to their actual codes
      storedPaymentType = paymentTypeMap[storedPaymentType] || String(storedPaymentType).toUpperCase();
  
      const storedAmount = parseFloat(formGroup.get('amount')?.value || '0');
  
      console.log(`🧐 Checking: { paymentType: '${storedPaymentType}', amount: ${storedAmount} }`);
  
      return storedPaymentType === targetPaymentType && storedAmount === targetAmount;
    });
  
    if (index !== -1) {
      const control = formArray.at(index) as FormGroup;
  
      // Update form values
      Object.keys(method).forEach((key) => {
        if (control.get(key)) {
          control.get(key)?.setValue(method[key]);
        }
      });
  
      // Open the modal
      this.modalService.open(modalRef, {
        size: 'lg',
        backdrop: 'static',
        centered: true,
      });
    } else {
      console.warn('❌ No matching payment method found for editing.');
    }
  }
  onConfirmCheckModal(modal: any) {
    const linesChecks = this.invoiceReceiptForm.get("linesChecks") as FormArray;
    let valid = true;

    linesChecks.controls.forEach((control) => {
      control.markAllAsTouched();
      if (control.invalid) {
        valid = false;
      }
    });

    if (!valid) return;

    const total = this.getTotalAmountChecksArray();
    this.invoiceReceiptForm.patchValue({ checks_amount: total });

    this.addedPayments["CHK"] = total;

    // 🔁 Update the summary just like cash
    this.selectedPaymentMethodsSummary = [
      ...this.selectedPaymentMethodsSummary.filter((m) => m.code !== "CHK"),
      { code: "CHK", amount: total },
    ];

    modal.close();
  }
  onConfirmCashModal(modal: NgbModalRef) {
    const cashArray = this.invoiceReceiptForm.get("linesCash") as FormArray;
    let valid = true;

    cashArray.controls.forEach((control) => {
      control.markAllAsTouched();
      if (control.invalid) {
        valid = false;
      }
    });

    if (!valid) return;

    const total = this.getTotalAmountCashArray();
    this.addedPayments["CASH"] = total;
    this.selectedPaymentMethodsSummary = [
      ...this.selectedPaymentMethodsSummary.filter((m) => m.code !== "CASH"),
      { code: "CASH", amount: total },
    ];

    modal.close();
  }
  onConfirmBankModal(modal: NgbModalRef) {
    if (this.linesBankPayment.invalid) {
      this.linesBankPayment.markAllAsTouched();
      return;
    }

    const total = this.getTotalAmountBankPaymentArray();
    this.addedPayments["BT"] = total;

    // Update external summary
    const existing = this.selectedPaymentMethodsSummary.find(
      (x) => x.code === "BT"
    );
    if (existing) {
      existing.amount = total;
    } else {
      this.selectedPaymentMethodsSummary.push({
        code: "BT",
        amount: total,
      });
    }

    modal.close();
  }
  onConfirmCreditModal(modal: NgbModalRef) {
    console.log("🔘 Confirm Credit Modal Clicked");

    if (!this.linesCredit || this.linesCredit.length === 0) {
      console.warn("⚠️ No credit lines found");
      return;
    }

    let isValid = true;

    this.linesCredit.controls.forEach((control, index) => {
      control.markAllAsTouched();
      if (control.invalid) {
        isValid = false;
        console.warn(`❌ Credit line ${index + 1} is invalid`, control.errors);
      }
    });

    if (!isValid) {
      console.warn("🛑 Credit form has errors. Confirmation blocked.");
      return;
    }

    const total = this.getTotalAmountCreidtArray();
    this.addedPayments["CC"] = total;

    const existing = this.selectedPaymentMethodsSummary.find(
      (x) => x.code === "CC"
    );
    if (existing) {
      existing.amount = total;
    } else {
      this.selectedPaymentMethodsSummary.push({
        code: "CC",
        amount: total,
      });
    }

    this.creditModalOpen = false;
    modal.close();

    console.log("✅ Credit Modal Confirmed | Total:", total);
  }



  getAllActiveMeasurementUnits() {
    this.spinner.show();
    this.service.getAllActiveMeasurementUnits().subscribe({
      next: (res) => {
        this.unitMesauereData = res;
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }

  filterProduct(event: any) {
    this.selectedProduct = event;
  }

  filterMeasurementUnit(event: any) {
    this.selectedMeasurementUnit = event;
  }
  openXl2(content2: any) {
    this.modalService.open(content2, { size: "xl", centered: true });
  }

  updatePaymentMethodSummary(code: string, amount: number) {
    const existing = this.selectedPaymentMethodsSummary.find(
      (m) => m.code === code
    );
    if (existing) {
      existing.amount = amount;
    } else {
      this.selectedPaymentMethodsSummary.push({ code, amount });
    }
  }
  removeSelectedPaymentMethod(code: string) {
    // Remove from selectedPaymentMethod array
    const current =
      this.invoiceReceiptForm.get("selectedPaymentMethod")?.value || [];
    const updated = current.filter((c: string) => c !== code);
    this.invoiceReceiptForm.patchValue({ selectedPaymentMethod: updated });

    // Remove from summary array
    this.selectedPaymentMethodsSummary =
      this.selectedPaymentMethodsSummary.filter((m) => m.code !== code);

    // Clear FormArray
    switch (code) {
      case "CHK":
        (this.invoiceReceiptForm.get("linesChecks") as FormArray).clear();
        break;
      case "CASH":
        (this.invoiceReceiptForm.get("linesCash") as FormArray).clear();
        break;
      case "BT":
        (this.invoiceReceiptForm.get("linesBankPayment") as FormArray).clear();
        break;
      case "CC":
        (this.invoiceReceiptForm.get("linesCredit") as FormArray).clear();
        break;
    }
  }

  onSelectPaymentMethod(code: string, modal: NgbModalRef) {
    modal.close();

    const selected = this.invoiceReceiptForm.get("selectedPaymentMethod")?.value || [];
    if (!selected.includes(code)) {
      const updated = [...selected, code];
      this.invoiceReceiptForm.patchValue({ selectedPaymentMethod: updated });
    }

    this.paymentMethodConfirmed = true;

    switch (code) {
      case "CHK":
        (this.invoiceReceiptForm.get("linesChecks") as FormArray).clear(); // ✅ clear
        this.addNewLineToChecks(); // ✅ add one
        this.modalRef = this.modalService.open(this.checkModalRef, {
          size: "lg",
          backdrop: "static",
          centered: true,
        });
        this.modalRef.result.catch(() => {
          (this.invoiceReceiptForm.get("linesChecks") as FormArray).clear(); // ✅ discard on cancel
          this.calculateTotalAmount();
        });
        break;

      case "CASH":
        (this.invoiceReceiptForm.get("linesCash") as FormArray).clear(); // ✅ clear
        this.addNewLineToCash(); // ✅ add one
        this.modalRef = this.modalService.open(this.cashModalRef, {
          size: "lg",
          backdrop: "static",
          centered: true,
        });
        this.modalRef.result.catch(() => {
          (this.invoiceReceiptForm.get("linesCash") as FormArray).clear(); // ✅ discard
          this.calculateTotalAmount();
        });
        break;

      case "BT":
        (this.invoiceReceiptForm.get("linesBankPayment") as FormArray).clear(); // ✅ clear
        this.addNewLineToBankPayment(); // ✅ add one
        this.modalRef = this.modalService.open(this.bankModalRef, {
          size: "lg",
          backdrop: "static",
          centered: true,
        });
        this.modalRef.result.catch(() => {
          (this.invoiceReceiptForm.get("linesBankPayment") as FormArray).clear(); // ✅ discard
          this.calculateTotalAmount();
        });
        break;

      case "CC":
        (this.invoiceReceiptForm.get("linesCredit") as FormArray).clear(); // ✅ clear
        this.addNewLineToCredit(); // ✅ add one
        this.modalRef = this.modalService.open(this.creditModalRef, {
          size: "lg",
          backdrop: "static",
          centered: true,
        });
        this.modalRef.result.catch(() => {
          (this.invoiceReceiptForm.get("linesCredit") as FormArray).clear(); // ✅ discard
          this.calculateTotalAmount();
        });
        break;
    }
  }

  addedProductData() {
    const product_name_he =
      String(this.addProduct.value.product_name_he)?.trim() || "";
    const product_code =
      String(this.addProduct.value.product_code)?.trim() || "";
    const price = Number(this.addProduct.value.price) || 0;
    const des_he = String(this.addProduct.value.des_he)?.trim() || "";
    const measurement_unit_id =
      Number(this.addProduct.value.measurement_unit_id) || 0;
    const categories_id = Number(this.addProduct.value.categories_id) || 0;

    if (
      product_name_he === "" ||
      product_code === "" ||
      price === 0 ||
      des_he === "" ||
      measurement_unit_id === 0 ||
      categories_id === 0
    ) {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يرجى ملء الحقول المطلوبة وعدم تركها فارغة أو تحتوي على مسافات فقط"
            : this.curentLang === "en"
            ? "Please fill out the required fields and do not leave them empty or containing only spaces"
            : this.curentLang === "he"
            ? "אנא מלא את השדות הנדרשים ואל תשאיר אותם ריקים או המכילים רק רווחים"
            : "",
      });

      this.addProduct.markAllAsTouched();
      this.validate = !this.validate;
      return;
    }

    // تعيين قيمة product_name_he إلى الحقول الأخرى
    const productNameHe = this.addProduct.value.product_name_he;
    const descriptionNameHe = this.addProduct.value.des_he;
    this.addProduct.patchValue({
      product_name_ar: productNameHe,
      product_name_en: productNameHe,
      des_ar: descriptionNameHe,
      des_en: descriptionNameHe,
    });

    let model = {
      product_name_en: this.addProduct.value.product_name_en,
      product_name_ar: this.addProduct.value.product_name_ar,
      product_name_he: this.addProduct.value.product_name_he,
      product_code: this.addProduct.value.product_code,
      price: price,
      des_ar: this.addProduct.value.des_ar,
      des_en: this.addProduct.value.des_en,
      des_he: this.addProduct.value.des_he,
      measurement_unit_id: measurement_unit_id,
      categories_id: categories_id,
      merchant_id: Number(this.merchantId),
      created_by: Number(this.userRegistId),
    };

    this.spinner.show();
    this.service.Post(AddProductEndPoient.POST, model).subscribe((res) => {
      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          title:
            this.curentLang === "ar"
              ? res.messageAr
              : this.curentLang === "en"
              ? res.messageEn
              : res.messageHe,
        });
        this.modalService.dismissAll();

        this.getAllCustomerType();
        this.GetProducts();

        this.addProduct.reset();
      } else if (
        res.status === 400 ||
        res.status === 403 ||
        res.status === 500
      ) {
        let errorMessage;
        switch (res.status) {
          case 400:
            errorMessage =
              this.curentLang === "ar"
                ? res.messageAr
                : this.curentLang === "en"
                ? res.messageEn
                : res.messageHe;
            break;
          case 403:
            errorMessage =
              this.curentLang === "ar"
                ? res.messageAr
                : this.curentLang === "en"
                ? res.messageEn
                : res.messageHe;
            break;
          case 500:
            errorMessage =
              this.curentLang === "ar"
                ? res.messageAr
                : this.curentLang === "en"
                ? res.messageEn
                : res.messageHe;
            break;
          default:
            errorMessage =
              this.curentLang === "ar"
                ? "حدث خطأ غير معروف"
                : this.curentLang === "en"
                ? "An unknown error occurred"
                : "אירעה שגיאה לא ידועה";
        }
        Swal.fire({
          icon: "error",
          title: errorMessage,
        });
      }
      this.spinner.hide();
    });
  }

  openChoosePaymentMethodModal(modal: NgbModalRef) {
    this.modalService.open(modal, { size: "md" });
  }
  checkClosedMonth() {
    this.spinner.show();
    this.service
      .checkClosedMonth(
        this.companyDefault,
        this.selectedMonth,
        this.selectedYear
      )
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          if (res.status == 200) {
            this.toastr.warning(
              this.curentLang === "en"
                ? res.messageEn
                : this.curentLang === "ar"
                ? res.messageAr
                : res.messageHe,
              "",
              { timeOut: 7000 } // زيادة مدة العرض إلى 5 ثوانٍ
            );
          } else {
            // this.toastr.info(
            //   this.curentLang === 'en'
            //     ? 'The selected month and year are open.'
            //     : this.curentLang === 'ar'
            //     ? 'الشهر والسنة المختارة مفتوحة.'
            //     : 'החודש והשנה שנבחרו פתוחים.',
            //   '',
            //   { timeOut: 7000 } // زيادة مدة العرض إلى 5 ثوانٍ
            // );
          }
        },
        error: (error) => {
          this.spinner.hide();
        },
      });
  }

  ngOnInit(): void {
    this.loadInvoiceData();
    this.curentLang = localStorage.getItem("curentLang") || "en";

    this.invoiceReceiptForm = this.builder.group({
      invoice_type: [320],
      document_language: ["he"],
      allocation_number: this.builder.control(null || 1, Validators.required),
      invoice_date: this.builder.control(
        this.datePipe.transform(new Date(), "yyyy-MM-dd"),
        Validators.required
      ),
      amount_before_discount: [{ value: 0, disabled: true }],
      all_discount: [{ value: 0, disabled: true }],
      payment_amount: [{ value: 0, disabled: true }],
      all_vat_amount: [{ value: 0, disabled: true }],
      payment_amount_including_vat: [{ value: 0, disabled: true }],
      invoice_note: ["", Validators.maxLength(255)],
      customer_id: ["", Validators.required],
      company_id: [null, Validators.required],
      vat_list_id: [null, Validators.required],
      vat_percentage: [{ value: 0, disabled: true }],
      confirm: [{ value: 0, disabled: true }],
      cash_amount: [0],
      checks_amount: [0],
      selectedPaymentMethod: [[], Validators.required],
      deduction_from_source: [0],
      bank_payment_amount: [0],
      credit_amount: [0],
      total_amount: [null],
      merchant_id: Number(localStorage.getItem("merchant_id")),
      created_by: Number(localStorage.getItem("user_id")),
      customer_name: this.builder.control("", Validators.required),
      customer_id_number: this.builder.control(""),
      customer_vat_number: this.builder.control(""),
      lines: this.builder.array([], Validators.required),
      linesCash: this.builder.array([]),
      linesChecks: this.builder.array([]),
      linesBankPayment: this.builder.array([]),
      linesCredit: this.builder.array([]),
    });

    // تحديث النموذج بالقيم المستلمة
    this.invoiceReceiptForm.patchValue({
      vat_list_id: 8,
    });

    this.getVatPercentageByVatListIdAndDate();

    const today = new Date();
    this.selectedMonth = today.getMonth() + 1;
    this.selectedYear = today.getFullYear();
    this.checkClosedMonth();

    this.onCustomerTypeChange();

    this.GetYear();
    this.GetProducts();
    this.vatTypechange();
    this.GetCompany();
    this.GetInvoiceTypeList();
    this.getCustomerById();
    this.GetCustomers();
    this.GetBankList();
    this.getAllBankBranch();
    this.getAllCustomerType();
    this.getAllActiveCategories();
    this.getAllActiveMeasurementUnits();
    this.invoiceReceiptForm.valueChanges.subscribe(() => {
      this.calculateTotalAmount();
    });

    if (this.companyDefault !== null && this.companyDefault !== "null") {
      this.invoiceReceiptForm.patchValue({
        company_id: Number(this.companyDefault),
      });
      this.checkSequenceExists(Number(this.companyDefault));
    }

    this.invoiceReceiptForm.valueChanges.subscribe(() => {
      this.calculateTotalAmount();
    });

    this.invoiceReceiptForm
      .get("company_id")
      .valueChanges.subscribe((companyId) => {
        if (companyId) {
          this.checkSequenceExists(companyId);
        }
      });
  }
  loadInvoiceData() {
    this.spinner.show(); // Show loading spinner before fetching data
    this.invoiceId = this.activeroute.snapshot.params["id"];
  
    this.service.getInvoicesAndReceiptsByInvoiceId(this.invoiceId).subscribe(
      (res) => {
        this.invoicedetail = res;
  
        console.log("Loaded Invoice Data:", this.invoicedetail); // Debugging
  
        // Update form with invoice & receipt details
        this.invoiceReceiptForm.patchValue({
          invoice_type: this.invoicedetail.invoice_type,
          all_discount: this.invoicedetail.all_discount,
          customer_id: this.invoicedetail.customer_id,
          company_id: this.invoicedetail.company_id,
          invoice_date: this.invoicedetail.invoice_date,
          customer_id_number: this.invoicedetail?.customer_id_number,
          customer_name: this.invoicedetail.customer_name,
          customer_vat_number: this.invoicedetail.customer_vat_number,
          vat_list_id: this.invoicedetail.vat_list_id,
          document_language: this.invoicedetail.document_language,
          vat_percentage: this.invoicedetail.vat_percentage,
          invoice_note: this.invoicedetail.invoice_note,
          total_amount: this.invoicedetail.total_amount,
          deduction_from_source: this.invoicedetail.deduction_from_source,
  
          // Preserve receipt details
          credit_amount: parseFloat(this.invoicedetail.credit_amount) || 0,
          bank_payment_amount: parseFloat(this.invoicedetail.bank_payment_amount) || 0,
          cash_amount: parseFloat(this.invoicedetail.cash_amount) || 0,
          checks_amount: parseFloat(this.invoicedetail.checks_amount) || 0,
          receipt_number: this.invoicedetail.receipt_number || "",
          receipt_date: this.invoicedetail.receipt_date || null,
          receipt_status: this.invoicedetail.receipt_status || "Pending",
        });
  
        // Populate invoice-related lines
        this.populateFormArray(this.invoicedetail.soldItems, "lines");
  
        // Preserve and translate payment methods:
        this.translateAndPopulatePayments(this.invoicedetail.linesCash, "linesCash", "Cash Payment");
        this.translateAndPopulatePayments(this.invoicedetail.linesChecks, "linesChecks", "Check Payment");
        this.translateAndPopulatePayments(this.invoicedetail.linesBankPayment, "linesBankPayment", "Bank Transfer");
        this.translateAndPopulatePayments(this.invoicedetail.linesCredit, "linesCredit", "Credit Payment");
  
        // Update the payment methods summary
        this.updatePaymentMethodsSummary();
  
        // Ensure VAT and total calculations are updated
        this.getVatPercentageByVatListIdAndDate();
        this.calculateTotalAmount();
  
        this.spinner.hide();
      },
      (error) => {
        console.error("Error loading invoice and receipt data:", error);
        this.spinner.hide();
      }
    );
  }
  populateFormArray(data: any[], formArrayName: string) {
    const formArray = this.invoiceReceiptForm.get(formArrayName) as FormArray;
    formArray.clear();
    data.forEach(detail => {
        const formGroup = this.builder.group(detail);
        formArray.push(formGroup);
    });
}

updatePaymentMethodsSummary() {
  this.selectedPaymentMethodsSummary = [];

  // Add Cash Payments
  const cashTotal = this.getTotalAmountCashArray();
  if (cashTotal > 0) {
    this.selectedPaymentMethodsSummary.push({ code: "CASH", amount: cashTotal });
  }

  // Add Check Payments
  const checksTotal = this.getTotalAmountChecksArray();
  if (checksTotal > 0) {
    this.selectedPaymentMethodsSummary.push({ code: "CHK", amount: checksTotal });
  }

  // Add Bank Transfers
  const bankTotal = this.getTotalAmountBankPaymentArray();
  if (bankTotal > 0) {
    this.selectedPaymentMethodsSummary.push({ code: "BT", amount: bankTotal });
  }

  // Add Credit Card Payments
  const creditTotal = this.getTotalAmountCreidtArray();
  if (creditTotal > 0) {
    this.selectedPaymentMethodsSummary.push({ code: "CC", amount: creditTotal });
  }
}

  translateAndPopulatePayments(lines: any[], formArrayName: string, paymentType: string) {
    if (!lines || lines.length === 0) {
      console.warn(`No ${paymentType} data found.`);
      return;
    }
  
    console.log(`Processing ${paymentType} Data:`, lines);
  
    const paymentArray = lines.map(item => ({
      id: item.id,
      date: item.date,
      line_id: item.line_id,
      reference: item.reference || "N/A",
      bank: item.bank || "N/A",
      branch: item.branch || "N/A",
      account: item.account || "N/A",
      details: item.details || "N/A",
      amount: parseFloat(item.amount) || 0,
      credit_card_code: item.credit_card_code || "0",
      credit_card_name: item.credit_card_name || "",
      credit_type: item.credit_type || "0",
      payment_type: item.payment_type,
      invoice_id: item.invoice_id
    }));
  
    this.populateFormArray(paymentArray, formArrayName);
  }
  openXl(content: any) {
    this.modalService.open(content, { size: "xl", centered: true });
  }

  filterCustomer(event: any) {
    let x = event.target.value;
  }
  getAllCustomerType() {
    this.spinner.show();
    this.service.getAllCustomerType().subscribe({
      next: (res) => {
        this.customerTypeData = res;

        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }

  onCustomerTypeChange() {
    this.addCustomer.get("customer_type")?.valueChanges.subscribe((value) => {
      if (value == 5) {
        //    this.addCustomer.get('id_number')?.setValidators([Validators.required, Validators.maxLength(9)]);
        this.addCustomer.get("customer_vat_number")?.clearValidators();
      } else if (value == 6) {
        this.addCustomer
          .get("customer_vat_number")
          ?.setValidators([
            Validators.required,
            Validators.pattern("[0-9]{9}"),
          ]);
        //    this.addCustomer.get('id_number')?.clearValidators();
      }
      //   this.addCustomer.get('id_number')?.updateValueAndValidity();
      this.addCustomer.get("customer_vat_number")?.updateValueAndValidity();
    });
  }
  addedData() {
    if (this.addCustomer.invalid) {
      this.validate = true;
      Object.keys(this.addCustomer.controls).forEach((key) => {
        const control = this.addCustomer.get(key);
        if (control.invalid) {
          // Optional: Scroll to or highlight invalid fields
        }
      });
      return;
    }

    // Copy customer_name_he into en & ar
    const customerNameHe = this.addCustomer.get("customer_name_he")?.value;
    this.addCustomer.patchValue({
      customer_name_en: customerNameHe,
      customer_name_ar: customerNameHe,
    });

    // Copy city & street
    const city = this.addCustomer.get("customer_address_city")?.value;
    const street = this.addCustomer.get("customer_address_str")?.value;
    this.addCustomer.patchValue({
      customer_address_city_en: city,
      customer_address_city_ar: city,
      customer_address_str_en: street,
      customer_address_str_ar: street,
    });

    const model = {
      customer_name_en: this.addCustomer.value.customer_name_en,
      customer_name_ar: this.addCustomer.value.customer_name_ar,
      customer_name_he: this.addCustomer.value.customer_name_he,
      customer_address_str: this.addCustomer.value.customer_address_str,
      customer_address_str_ar: this.addCustomer.value.customer_address_str_ar,
      customer_address_str_en: this.addCustomer.value.customer_address_str_en,
      customer_address_city: this.addCustomer.value.customer_address_city,
      customer_address_city_ar: this.addCustomer.value.customer_address_city_ar,
      customer_address_city_en: this.addCustomer.value.customer_address_city_en,
      customer_address_zip_code:
        this.addCustomer.value.customer_address_zip_code,
      customer_Building_number: this.addCustomer.value.customer_Building_number,
      customer_phone:
        this.addCustomer.value.customer_phone !== ""
          ? "+972" + this.addCustomer.value.customer_phone
          : "",
      customer_vat_number: this.addCustomer.value.customer_vat_number,
      customer_vat_tax: this.addCustomer.value.customer_vat_tax,
      id_number: this.addCustomer.value.id_number,
      customer_type: Number(this.addCustomer.value.customer_type),
      merchant_id: Number(this.merchantId),
      created_by: Number(this.userRegistId),
    };

    this.spinner.show();

    this.service.Post(AddCustomerEndPoient.POST, model).subscribe(
      (res) => {
        this.spinner.hide();

        if (res.status === 200) {
          // ✅ Add new customer to list (triggers UI update)
          this.masterCustomer = [...this.masterCustomer, res.data]; // or res.object if your API returns it under a different name

          Swal.fire({
            icon: "success",
            title:
              this.curentLang === "ar"
                ? res.messageAr
                : this.curentLang === "en"
                ? res.messageEn
                : res.messageHe,
          });

          this.modalService.dismissAll();
          this.getAllCustomerType();

          // Reset form
          this.addCustomer.reset({
            customer_name_en: "",
            customer_name_ar: "",
            customer_name_he: "",
            customer_address_str: "",
            customer_address_str_ar: "",
            customer_address_str_en: "",
            customer_address_city: "",
            customer_address_city_ar: "",
            customer_address_city_en: "",
            customer_address_zip_code: "",
            customer_Building_number: "",
            customer_phone: "",
            customer_vat_number: null,
            customer_vat_tax: null,
            id_number: null,
            customer_type: null,
          });

          this.validate = false;
        } else {
          let errorMessage =
            this.curentLang === "ar"
              ? res.messageAr
              : this.curentLang === "en"
              ? res.messageEn
              : res.messageHe;

          Swal.fire({
            icon: "error",
            title: errorMessage,
          });
        }
      },
      (error) => {
        this.spinner.hide();
        Swal.fire({
          icon: "error",
          title:
            this.curentLang === "en"
              ? "An error occurred"
              : this.curentLang === "ar"
              ? "حدث خطأ"
              : "אירעה שגיאה",
        });
      }
    );
  }

  getCustomerTypeName() {
    const customerTypeId = this.addCustomer.get("customer_type").value;
    const selectedType = this.customerTypeData.find(
      (item) => item.lookup_detail_id === customerTypeId
    );
    return selectedType
      ? this.curentLang === "en"
        ? selectedType.lookupDetailEnName
        : this.curentLang === "ar"
        ? selectedType.lookupDetailArName
        : selectedType.lookupDetailHeName
      : "";
  }

  isNumber(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    } else {
      return true;
    }
  }
  checkSequenceExists(companyId: number): void {
    const docType = "320"; // نوع المستند ثابت
    this.spinner.show();
    this.service.checkSequenceExists(companyId, docType).subscribe(
      (res) => {
        const sequenceExists = res;

        if (!sequenceExists) {
          Swal.fire({
            title:
              this.curentLang === "en"
                ? "Enter the starting number for the document sequence:"
                : this.curentLang === "ar"
                ? "أدخل الرقم الابتدائي لتسلسل المستند:"
                : "הזן את המספר ההתחלתי של רצף המסמכים:",
            input: "number",
            inputAttributes: {
              min: "1",
              step: "1",
            },
            inputValue: 1, // تعيين القيمة الافتراضية إلى 1
            showCancelButton: false,
            confirmButtonText:
              this.curentLang === "en"
                ? "Submit"
                : this.curentLang === "ar"
                ? "إرسال"
                : "שלח",
            allowOutsideClick: false,
            allowEscapeKey: false,
            preConfirm: (value) => {
              if (!value || value < 1) {
                Swal.showValidationMessage(
                  this.curentLang === "en"
                    ? "Please enter a valid number greater than 0"
                    : this.curentLang === "ar"
                    ? "يرجى إدخال رقم صالح أكبر من 0"
                    : "אנא הזן מספר חוקי גדול מ-0"
                );
              }
            },
          }).then((result) => {
            if (result.value) {
              this.createSequence(companyId, parseInt(result.value, 10));
            }
          });
        }

        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  createSequence(companyId: number, startWith: number): void {
    this.spinner.show();
    const docType = "320"; // نوع المستند ثابت

    this.service.createSequence(companyId, docType, startWith).subscribe(
      (res) => {
        Swal.fire({
          icon: "success",
          title:
            this.curentLang === "en"
              ? "Sequence created successfully"
              : this.curentLang === "ar"
              ? "تم إنشاء التسلسل بنجاح"
              : "רצף נוצר בהצלחה",
        });
      },
      (error) => {}
    );
    this.spinner.hide();
  }

  getBranchesForIndex(index: number) {
    // Return the branches data for the corresponding row index
    return this.masterBranches[index] || [];
  }

  confirmInvoice() {
    this.spinner.show();
    let isValidCustomer = true; // متغير علم لتحديد صلاحية العميل

    const linesCash = this.invoiceReceiptForm.get("linesCash") as FormArray;
    const linesChecks = this.invoiceReceiptForm.get("linesChecks") as FormArray;
    const linesBankPayment = this.invoiceReceiptForm.get(
      "linesBankPayment"
    ) as FormArray;
    const linesCredit = this.invoiceReceiptForm.get("linesCredit") as FormArray;
    if (
      linesCash?.length === 0 &&
      linesChecks?.length === 0 &&
      linesBankPayment?.length === 0 &&
      linesCredit?.length === 0
    ) {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يجب أن يكون هناك بيانات على الأقل في واحدة من وسائل الدفع"
            : this.curentLang === "he"
            ? "   חייבים להיות נתונים לפחות באחד מאמצעי התשלום    "
            : "There must be data in at least one of the payment methods",
      });
      this.spinner.hide();
      return; // Stop the execution if the condition is not met
    }

    if (
      this.invoiceReceiptForm.get("payment_amount_including_vat")?.value >=
      this.MaxAmount
    ) {
      const customerType = this.oneCustomerData.customer_type;
      const customerId = this.oneCustomerData.customer_id;

      if (customerType === 6) {
        //  const customerVatNumber = Number(this.oneCustomerData.customer_vat_number);
        const customerVatNumber = Number(
          this.invoiceReceiptForm.get("customer_vat_number")?.value
        );
        if (customerVatNumber === 0 || customerVatNumber === null) {
          isValidCustomer = false; // إيقاف علم صلاحية العميل مؤقتًا
          Swal.fire({
            icon: "warning",
            title:
              this.curentLang === "ar"
                ? "الرقم الضريبي للعميل غير صحيح"
                : this.curentLang === "en"
                ? "The customer tax number is incorrect"
                : "מספר המס של הלקוח שגוי",
            showCancelButton: true,
            confirmButtonText:
              this.curentLang === "ar" ? "استمر رغم ذلك" : "Keep going though",
            cancelButtonText:
              this.curentLang === "ar"
                ? "تعديل بيانات العميل"
                : "Edit Customer Data",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              this.invoiceReceiptForm.patchValue({
                customer_vat_number: "999999998",
                customer_name: this.oneCustomerData?.[
                  "customer_name_" + this.curentLang
                ].substring(0, 100),
                customer_id_number: this.oneCustomerData?.id_number,
              });
              isValidCustomer = true; // إعادة صلاحية العميل
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire({
                title:
                  this.curentLang === "ar"
                    ? "تحديث الرقم الضريبي للعميل"
                    : "Update Customer VAT Number",
                input: "text",
                inputValue: this.oneCustomerData.customer_vat_number,
                showCancelButton: true,
                confirmButtonText:
                  this.curentLang === "ar" ? "تحديث" : "Update",
                inputValidator: (value) => {
                  if (
                    !value ||
                    value.trim() === "" ||
                    value.startsWith("0") ||
                    /^0+$/.test(value) ||
                    value.length < 9
                  ) {
                    return this.curentLang === "ar"
                      ? "الرجاء إدخال رقم ضريبي صالح لا يقل عن 9 أرقام ولا يبدأ بصفر"
                      : "Please enter a valid VAT number with at least 9 digits and does not start with 0";
                  }
                },
              }).then((inputResult) => {
                if (inputResult.isConfirmed) {
                  this.invoiceReceiptForm.patchValue({
                    customer_vat_number: inputResult.value,
                    customer_name: this.oneCustomerData?.[
                      "customer_name_" + this.curentLang
                    ].substring(0, 25),
                    customer_id_number: this.oneCustomerData?.id_number,
                  });
                  isValidCustomer = true; // إعادة صلاحية العميل بعد التحديث

                  const model = {
                    customer_vat_number: inputResult.value,
                    updated_by: this.userRegistId,
                  };
                  this.service
                    .updateCustomersById(customerId, model)
                    .subscribe();
                }
              });
            }
          });
        }
      }

      if (customerType === 5) {
        const customerIdNumber = Number(
          this.invoiceReceiptForm.get("customer_id_number")?.value
        );
        if (
          customerIdNumber === 0 ||
          customerIdNumber === null ||
          customerIdNumber === undefined
        ) {
          isValidCustomer = false;
          Swal.fire({
            icon: "warning",
            title:
              this.curentLang === "ar"
                ? "رقم الهوية للعميل غير صحيح"
                : "The customer ID number is incorrect",
            showCancelButton: true,
            confirmButtonText:
              this.curentLang === "ar" ? "استمر رغم ذلك" : "Keep going though",
            cancelButtonText:
              this.curentLang === "ar"
                ? "تعديل بيانات العميل"
                : "Edit Customer Data",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              this.invoiceReceiptForm.patchValue({
                customer_id_number: "999999998",
                customer_name: this.oneCustomerData?.[
                  "customer_name_" + this.curentLang
                ].substring(0, 25),
                customer_vat_number:
                  this.oneCustomerData?.customer_vat_number || 0,
              });
              isValidCustomer = true;
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire({
                title:
                  this.curentLang === "ar"
                    ? "تحديث رقم الهوية للعميل"
                    : "Update Customer ID Number",
                input: "text",
                inputValue: this.oneCustomerData.id_number,
                showCancelButton: true,
                confirmButtonText:
                  this.curentLang === "ar" ? "تحديث" : "Update",
                inputValidator: (value) => {
                  if (
                    !value ||
                    value.trim() === "" ||
                    value.startsWith("0") ||
                    /^0+$/.test(value) ||
                    value.length < 9
                  ) {
                    return this.curentLang === "ar"
                      ? "الرجاء إدخال رقم هوية صالح لا يقل عن 9 أرقام ولا يبدأ بصفر"
                      : "Please enter a valid ID number with at least 9 digits and does not start with 0";
                  }
                },
              }).then((inputResult) => {
                if (inputResult.isConfirmed) {
                  this.invoiceReceiptForm.patchValue({
                    customer_id_number: inputResult.value,
                    customer_vat_number:
                      this.oneCustomerData.customer_vat_number,
                    customer_name: this.oneCustomerData[
                      "customer_name_" + this.curentLang
                    ].substring(0, 25),
                  });
                  isValidCustomer = true;

                  const model = {
                    id_number: inputResult.value,
                    updated_by: this.userRegistId,
                  };
                  this.service
                    .updateCustomersById(customerId, model)
                    .subscribe();
                }
              });
            }
          });
        }
      }
    }

    if (isValidCustomer && this.invoiceReceiptForm.valid) {
      const cashAmount = this.getTotalAmountCashArray();
      const checksAmount = this.getTotalAmountChecksArray();
      const bankPaymentAmount = this.getTotalAmountBankPaymentArray();
      const creditAmount = this.getTotalAmountCreidtArray();

      this.invoiceReceiptForm.get("cash_amount").setValue(cashAmount);
      this.invoiceReceiptForm.get("checks_amount").setValue(checksAmount);
      this.invoiceReceiptForm
        .get("bank_payment_amount")
        .setValue(bankPaymentAmount);
      this.invoiceReceiptForm.get("credit_amount").setValue(creditAmount);

      const deductionFromSource = Number(
        this.invoiceReceiptForm.get("deduction_from_source").value || 0
      );
      const totalLinesPayment =
        cashAmount +
        checksAmount +
        bankPaymentAmount +
        creditAmount +
        Number(deductionFromSource);

      if (
        totalLinesPayment !==
        Number(
          this.invoiceReceiptForm.get("payment_amount_including_vat").value
        )
      ) {
        Swal.fire({
          icon: "warning",
          title:
            this.curentLang === "ar"
              ? "المبلغ الأجمالي للفاتوره ليس مطابق لمبلغ الإجمالي للدفع"
              : this.curentLang === "he"
              ? "   הסכום הכולל של הזמנות לא מתאים לסכום הכולל של התשלום    "
              : "The total amount of the orders does not match the total amount of the payment",
        });
        this.spinner.hide();
        return;
      }
      this.spinner.show();
      return this.service
        .createInvoiceAndReceipt(this.invoiceReceiptForm.getRawValue())
        .pipe(
          concatMap((res: any) => {
            let result: any = res;
            this.invoiceId = result?.data?.invoice_id;
            this.uuId = result?.data?.invoice_uuid;
            if (result.status === 200) {
              // Return an observable here
              return of(null);
            } else if (
              result.status === 400 ||
              result.status === 403 ||
              result.status === 500
            ) {
              this.spinner.hide();
              let errorMessage;
              switch (result.status) {
                case 400:
                case 403:
                case 500:
                  errorMessage =
                    this.curentLang === "ar"
                      ? result.messageAr
                      : this.curentLang === "en"
                      ? result.messageEn
                      : result.messageHe;
                  break;
                default:
                  errorMessage =
                    this.curentLang === "ar"
                      ? "حدث خطأ غير معروف"
                      : this.curentLang === "en"
                      ? "An unknown error occurred"
                      : "אירעה שגיאה לא ידועה";
              }
              const Toast = Swal.mixin({
                toast: true,
                position: "top",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                color: "red",
                backdrop: true,

                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "error",
                title: errorMessage,
              });

              // Return an observable here
              return throwError(errorMessage);
            }
            // Return an observable here
            return of(null);
          }),
          catchError((error) => {
            this.spinner.hide();
            return throwError(error);
          }),
          concatMap(() => this.service.confirmInvoice(this.invoiceId))
        )
        .subscribe((res) => {
          if (res.status === 200) {
            this.router.navigate([`/dashboard/documents`]);

            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              color: "green",
              backdrop: true,

              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title:
                this.curentLang === "ar"
                  ? res.messageAr
                  : this.curentLang === "en"
                  ? res.messageEn
                  : res.messageHe,
            });

            this.modalService.dismissAll();
          } else if (
            res.status === 400 ||
            res.status === 403 ||
            res.status === 500
          ) {
            let errorMessage;
            switch (res.status) {
              case 400:
              case 403:
              case 500:
                errorMessage =
                  this.curentLang === "ar"
                    ? res.messageAr
                    : this.curentLang === "en"
                    ? res.messageEn
                    : res.messageHe;
                this.router.navigate(["/dashboard/documents"]);

                break;

              default:
                errorMessage =
                  this.curentLang === "ar"
                    ? "حدث خطأ غير معروف"
                    : this.curentLang === "en"
                    ? "An unknown error occurred"
                    : "אירעה שגיאה לא ידועה";
            }

            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              color: "red",
              backdrop: true,

              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: errorMessage,
            });
          }
          this.spinner.hide();
        });
    } else if (!isValidCustomer) {
      this.spinner.hide();
    } else {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يرجى ملء جميع الحقول"
            : this.curentLang === "en"
            ? "Please fill all fields"
            : this.curentLang === "he"
            ? "  נא למלא כל השדות    "
            : "",
      });
      this.spinner.hide();
      return EMPTY;
    }
  }

  openChooseProductModalSelected(chooseProductModalSelected: any) {
    this.activeModal = this.modalService.open(chooseProductModalSelected,this.modalOptions);
  }
  saveInvoiceAndReceipt() {
    this.spinner.show();
    const linesCash = this.invoiceReceiptForm.get("linesCash") as FormArray;
    const linesChecks = this.invoiceReceiptForm.get("linesChecks") as FormArray;
    const linesBankPayment = this.invoiceReceiptForm.get(
      "linesBankPayment"
    ) as FormArray;
    const linesCredit = this.invoiceReceiptForm.get("linesCredit") as FormArray;
    if (
      linesCash.length === 0 &&
      linesChecks.length === 0 &&
      linesBankPayment.length === 0 &&
      linesCredit.length === 0
    ) {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يجب أن يكون هناك بيانات على الأقل في واحدة من وسائل الدفع"
            : this.curentLang === "he"
            ? "   חייבים להיות נתונים לפחות באחד מאמצעי התשלום    "
            : "There must be data in at least one of the payment methods",
      });
      this.spinner.hide();
      return; // Stop the execution if the condition is not met
    }

    let isValidCustomer = true; // متغير علم لتحديد صلاحية العميل

    if (
      this.invoiceReceiptForm.get("payment_amount_including_vat")?.value >=
      this.MaxAmount
    ) {
      const customerType = this.oneCustomerData.customer_type;
      const customerId = this.oneCustomerData.customer_id;

      if (customerType === 6) {
        // const customerVatNumber = Number(this.oneCustomerData.customer_vat_number);
        const customerVatNumber = Number(
          this.invoiceReceiptForm.get("customer_vat_number")?.value
        );
        if (customerVatNumber === 0 || customerVatNumber === null) {
          isValidCustomer = false; // إيقاف علم صلاحية العميل مؤقتًا
          Swal.fire({
            icon: "warning",
            title:
              this.curentLang === "ar"
                ? "الرقم الضريبي للعميل غير صحيح"
                : this.curentLang === "en"
                ? "The customer tax number is incorrect"
                : "מספר המס של הלקוח שגוי",
            showCancelButton: true,
            confirmButtonText:
              this.curentLang === "ar" ? "استمر رغم ذلك" : "Keep going though",
            cancelButtonText:
              this.curentLang === "ar"
                ? "تعديل بيانات العميل"
                : "Edit Customer Data",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              this.invoiceReceiptForm.patchValue({
                customer_vat_number: "999999998",
                customer_name: this.oneCustomerData?.[
                  "customer_name_" + this.curentLang
                ].substring(0, 25),
                customer_id_number: this.oneCustomerData?.id_number,
              });
              isValidCustomer = true; // إعادة صلاحية العميل
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire({
                title:
                  this.curentLang === "ar"
                    ? "تحديث الرقم الضريبي للعميل"
                    : "Update Customer VAT Number",
                input: "text",
                inputValue: this.oneCustomerData.customer_vat_number,
                showCancelButton: true,
                confirmButtonText:
                  this.curentLang === "ar" ? "تحديث" : "Update",
                inputValidator: (value) => {
                  if (
                    !value ||
                    value.trim() === "" ||
                    value.startsWith("0") ||
                    /^0+$/.test(value) ||
                    value.length < 9
                  ) {
                    return this.curentLang === "ar"
                      ? "الرجاء إدخال رقم ضريبي صالح لا يقل عن 9 أرقام ولا يبدأ بصفر"
                      : "Please enter a valid VAT number with at least 9 digits and does not start with 0";
                  }
                },
              }).then((inputResult) => {
                if (inputResult.isConfirmed) {
                  this.invoiceReceiptForm.patchValue({
                    customer_vat_number: inputResult.value,
                    customer_name: this.oneCustomerData?.[
                      "customer_name_" + this.curentLang
                    ].substring(0, 25),
                    customer_id_number: this.oneCustomerData?.id_number,
                  });
                  isValidCustomer = true; // إعادة صلاحية العميل بعد التحديث

                  const model = {
                    customer_vat_number: inputResult.value,
                    updated_by: this.userRegistId,
                  };
                  this.service
                    .updateCustomersById(customerId, model)
                    .subscribe();
                }
              });
            }
          });
        }
      }

      if (customerType === 5) {
        const customerIdNumber = Number(
          this.invoiceReceiptForm.get("customer_id_number")?.value
        );

        if (
          customerIdNumber === 0 ||
          customerIdNumber === null ||
          customerIdNumber === undefined
        ) {
          isValidCustomer = false;
          Swal.fire({
            icon: "warning",
            title:
              this.curentLang === "ar"
                ? "رقم الهوية للعميل غير صحيح"
                : "The customer ID number is incorrect",
            showCancelButton: true,
            confirmButtonText:
              this.curentLang === "ar" ? "استمر رغم ذلك" : "Keep going though",
            cancelButtonText:
              this.curentLang === "ar"
                ? "تعديل بيانات العميل"
                : "Edit Customer Data",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              this.invoiceReceiptForm.patchValue({
                customer_id_number: "999999998",
                customer_name: this.oneCustomerData?.[
                  "customer_name_" + this.curentLang
                ].substring(0, 25),
                customer_vat_number:
                  this.oneCustomerData?.customer_vat_number || 0,
              });
              isValidCustomer = true;
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire({
                title:
                  this.curentLang === "ar"
                    ? "تحديث رقم الهوية للعميل"
                    : "Update Customer ID Number",
                input: "text",
                inputValue: this.oneCustomerData.id_number,
                showCancelButton: true,
                confirmButtonText:
                  this.curentLang === "ar" ? "تحديث" : "Update",
                inputValidator: (value) => {
                  if (
                    !value ||
                    value.trim() === "" ||
                    value.startsWith("0") ||
                    /^0+$/.test(value) ||
                    value.length < 9
                  ) {
                    return this.curentLang === "ar"
                      ? "الرجاء إدخال رقم هوية صالح لا يقل عن 9 أرقام ولا يبدأ بصفر"
                      : "Please enter a valid ID number with at least 9 digits and does not start with 0";
                  }
                },
              }).then((inputResult) => {
                if (inputResult.isConfirmed) {
                  this.invoiceReceiptForm.patchValue({
                    customer_id_number: inputResult.value,
                    customer_vat_number:
                      this.oneCustomerData.customer_vat_number,
                    customer_name: this.oneCustomerData[
                      "customer_name_" + this.curentLang
                    ].substring(0, 25),
                  });
                  isValidCustomer = true;

                  const model = {
                    id_number: inputResult.value,
                    updated_by: this.userRegistId,
                  };
                  this.service
                    .updateCustomersById(customerId, model)
                    .subscribe();
                }
              });
            }
          });
        }
      }
    }

    if (isValidCustomer && this.invoiceReceiptForm.valid) {
      // Ensure cash_amount and checks_amount are set correctly
      const cashAmount = this.getTotalAmountCashArray();
      const checksAmount = this.getTotalAmountChecksArray();
      const bankPaymentAmount = this.getTotalAmountBankPaymentArray();
      const creditAmount = this.getTotalAmountCreidtArray();

      this.invoiceReceiptForm.get("cash_amount")?.setValue(cashAmount);
      this.invoiceReceiptForm.get("checks_amount")?.setValue(checksAmount);
      this.invoiceReceiptForm
        .get("bank_payment_amount")
        ?.setValue(bankPaymentAmount);
      this.invoiceReceiptForm.get("credit_amount")?.setValue(creditAmount);

      const totalLinesPayment =
        cashAmount + checksAmount + bankPaymentAmount + creditAmount;

      const formData = this.invoiceReceiptForm.getRawValue();

      // تحويل بيانات النموذج إلى سلسلة نصية وعرضها في وحدة التحكم

      this.service.createInvoiceAndReceipt(formData).subscribe({
        next: (response) => {
          let result: any;
          result = response;
          this.invoiceId = result?.data?.invoice_id;
          this.uuId = result?.data?.invoice_uuid;
          if (result.status === 200) {
            this.router.navigate(["/dashboard/documents"]);
            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              color: "green",
              backdrop: true,

              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title:
                this.curentLang === "ar"
                  ? result.messageAr
                  : this.curentLang === "en"
                  ? result.messageEn
                  : result.messageHe,
            });

            this.invoiceReceiptForm.reset();
          } else if (
            result.status === 400 ||
            result.status === 403 ||
            result.status === 500
          ) {
            let errorMessage;
            switch (result.status) {
              case 400:
                errorMessage =
                  this.curentLang === "ar"
                    ? result.messageAr
                    : this.curentLang === "en"
                    ? result.messageEn
                    : result.messageHe;
                break;
              case 403:
                errorMessage =
                  this.curentLang === "ar"
                    ? result.messageAr
                    : this.curentLang === "en"
                    ? result.messageEn
                    : result.messageHe;
                break;
              case 500:
                errorMessage =
                  this.curentLang === "ar"
                    ? result.messageAr
                    : this.curentLang === "en"
                    ? result.messageEn
                    : result.messageHe;
                break;
              default:
                errorMessage =
                  this.curentLang === "ar"
                    ? "حدث خطأ غير معروف"
                    : this.curentLang === "en"
                    ? "An unknown error occurred"
                    : "אירעה שגיאה לא ידועה";
            }
            Swal.fire({
              icon: "error",
              title: errorMessage,
            });
          }
          this.spinner.hide();
        },
        error: (error) => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            color: "red",
            backdrop: true,

            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title:
              this.curentLang === "ar"
                ? "خطأ في حفظ الفاتورة والإيصال"
                : this.curentLang === "he"
                ? "שגיאה בשמירת החשבונית והקבלה"
                : "Error saving Invoice and Receipt",
          });

          this.spinner.hide();
        },
      });
    } else if (!isValidCustomer) {
      this.spinner.hide();
    } else {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يرجى ملء جميع الحقول"
            : this.curentLang === "en"
            ? "Please fill all fields"
            : this.curentLang === "he"
            ? "נא למלא כל השדות"
            : "",
      });
      this.spinner.hide();
      return EMPTY;
    }
    this.spinner.hide();
  }
  addProductWithValidation(chooseProductModalSelected: any) {
    let customercode = this.invoiceReceiptForm.get("customer_id")?.value;
    let vatListId = this.invoiceReceiptForm.get("vat_list_id")?.value;
    let invoiceTypeCode = this.invoiceReceiptForm.get("invoice_type")?.value;
    let invoiceDate = this.invoiceReceiptForm.get("invoice_date")?.value;
    let companyid = this.invoiceReceiptForm.get("company_id")?.value;

    if (
      (customercode != null &&
        customercode != "" &&
        vatListId != null &&
        vatListId != "" &&
        invoiceTypeCode != null &&
        invoiceTypeCode != "" &&
        invoiceDate != null &&
        invoiceDate != "" &&
        companyid != null &&
        companyid != "") ||
      this.isedit
    ) {
      this.openChooseProductModalSelected(chooseProductModalSelected);
    } else {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يرجى ملء جميع الحقول"
            : this.curentLang === "en"
            ? "Please fill all fields"
            : this.curentLang === "he"
            ? "נא למלא כל השדות"
            : "",
      });
    }
  }
  GetYear() {
    this.spinner.show();
    this.service.GetYear(Number(this.YearData)).subscribe({
      next: (res) => {
        this.YearData = res;
        this.MaxAmount = this.YearData.maxAmount;
        this.Year = this.YearData.year;
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }

  Itemcalculation(index: number) {
    console.log(`Itemcalculation called for index ${index}`);
    this.invoicedetail = this.invoiceReceiptForm.get("lines") as FormArray;
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;
  
    if (!this.invoiceproduct) {
        console.error(`No FormGroup found at index ${index}`);
        return;
    }
  
    console.log(`FormGroup at index ${index}:`, this.invoiceproduct.value);
  
    const safeParse = (val: any, defaultValue = 0) => {
        const num = parseFloat(val);
        return isNaN(num) ? defaultValue : num;
    };
  
    let quantity = safeParse(this.invoiceproduct.get("quantity")?.value, 1);
    let price = safeParse(this.invoiceproduct.get("price_per_unit")?.value, 0);
    let discount = safeParse(this.invoiceproduct.get("discount")?.value, 0);
    let vat_rate = safeParse(this.invoiceproduct.get("vat_rate")?.value, this.VatPercentage);
    let isVatIncluded = this.invoiceproduct.get("isVatIncluded")?.value || false;
  
    console.log(`isVatIncluded: ${isVatIncluded}`);
  
    let adjustedPrice = price;
    if (isVatIncluded) {
        adjustedPrice = price / (1 + vat_rate / 100);
        console.log(`Price adjusted to exclude VAT: ${adjustedPrice}`);
    }
  
    let sub_total = Math.max(quantity * adjustedPrice - discount, 0);
    let vat_amount = sub_total * (vat_rate / 100);
    let total_amount = sub_total + vat_amount;
  
    // Round values to 2 decimal places
    sub_total = Math.round(sub_total * 100) / 100;
    vat_amount = Math.round(vat_amount * 100) / 100;
    total_amount = Math.round(total_amount * 100) / 100;
  
    console.log(`sub_total: ${sub_total}, vat_amount: ${vat_amount}, total_amount: ${total_amount}`);
  
    // Set calculated values
    this.invoiceproduct.get("sub_total")?.setValue(sub_total.toFixed(2), { emitEvent: false });
    this.invoiceproduct.get("vat_amount")?.setValue(vat_amount.toFixed(2), { emitEvent: false });
    this.invoiceproduct.get("total_amount")?.setValue(total_amount.toFixed(2), { emitEvent: false });
  
    this.summarycalculation();
  }
  closeModal(modal: any) {
    // تعيين جميع القيم إلى false لإغلاق جميع العناصر
    this.collapsedIndexes = Array(this.invproducts.controls.length).fill(true);

    // إغلاق المودال
    modal.dismiss("Cross click");
  }
  summarycalculation() {
    const lines = this.invoiceReceiptForm.get("lines") as FormArray;
  
    let sumSubTotal = 0;
    let sumDiscount = 0;
    let sumVat = 0;
    let sumNet = 0;
    let sumTotal = 0;
  
    lines.controls.forEach((ctrl: AbstractControl) => {
      const parseNum = (v: any, fallback = 0) =>
        isNaN(parseFloat(v)) ? fallback : parseFloat(v);
  
      const quantity = parseNum(ctrl.get("quantity")?.value, 1);
      const discount = parseNum(ctrl.get("discount")?.value, 0);
      const vatRate = parseNum(ctrl.get("vat_rate")?.value, this.VatPercentage || 18);
      const totalAmount = parseNum(ctrl.get("total_amount")?.value, 0);
  
      // If totalAmount is final (VAT included), net = totalAmount / (1 + vatFraction)
      const vatFraction = vatRate / 100;
      let net = totalAmount / (1 + vatFraction);
      let subTotal = net + discount; // Because net = subTotal - discount
      let vat = net * vatFraction;
  
      sumSubTotal += subTotal;
      sumDiscount += discount;
      sumVat += vat;
      sumNet += net;
      sumTotal += totalAmount;
    });
  
    // Round sums
    const round = (x: number) => Math.round(x * 100) / 100;
    sumSubTotal = round(sumSubTotal);
    sumDiscount = round(sumDiscount);
    sumVat = round(sumVat);
    sumNet = round(sumNet);
    sumTotal = round(sumTotal);
  
    // Update invoice form fields
    this.invoiceReceiptForm.get("amount_before_discount")?.setValue(sumSubTotal);
    this.invoiceReceiptForm.get("all_discount")?.setValue(sumDiscount);
    this.invoiceReceiptForm.get("payment_amount")?.setValue(sumNet);
    this.invoiceReceiptForm.get("all_vat_amount")?.setValue(sumVat);
    this.invoiceReceiptForm.get("payment_amount_including_vat")?.setValue(sumTotal);
  }

  filterCreidt(event: any, index: number) {
    const selectedValue = event.target.value;
    this.creditFilter = selectedValue;

    // Update the form control for credit_card_code at the specified index
    (this.invoiceReceiptForm.get("linesCredit") as FormArray)
      .at(index)
      .get("credit_card_code")
      ?.setValue(this.creditFilter);
  }

  addnewproduct() {
    this.invoicedetail = this.invoiceReceiptForm.get("lines") as FormArray;

    let customercode = this.invoiceReceiptForm.get("customer_id")?.value;
    let vatListId = this.invoiceReceiptForm.get("vat_list_id")?.value;
    let invoiceTypeCode = this.invoiceReceiptForm.get("invoice_type")?.value;
    let invoiceDate = this.invoiceReceiptForm.get("invoice_date")?.value;
    let companyid = this.invoiceReceiptForm.get("company_id")?.value;

    if (
      customercode != null &&
      customercode != "" &&
      vatListId != null &&
      vatListId != "" &&
      invoiceTypeCode != null &&
      invoiceTypeCode != "" &&
      invoiceDate != null &&
      invoiceDate != "" &&
      companyid != null &&
      companyid != ""
    ) {
      this.invoicedetail.push(this.Generaterow());
    } else {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يرجى ملء جميع الحقول"
            : this.curentLang === "en"
            ? "Please fill all fields"
            : this.curentLang === "he"
            ? "נא למלא כל השדות"
            : "",
      });
    }
  }

  filterPayment(event: any) {
    let x = event.target.value;
  }

  calculateTotalAmount() {
    const cashAmount = this.getTotalAmountCashArray();
    const checksAmount = this.getTotalAmountChecksArray();
    const bankPayment = this.getTotalAmountBankPaymentArray();
    const creditAmount = this.getTotalAmountCreidtArray();
    const oldTotalAmount = this.invoiceReceiptForm.get("total_amount").value; // قيمة total_amount السابقة
    const deductionFromSource =
      this.invoiceReceiptForm.get("deduction_from_source").value || 0;
    const newTotalAmount =
      cashAmount +
      checksAmount +
      bankPayment +
      creditAmount +
      Number(deductionFromSource);

    if (oldTotalAmount !== newTotalAmount) {
      // فقط في حالة تغير القيمة
      this.invoiceReceiptForm.get("total_amount").setValue(newTotalAmount);
    }
  }

  getTotalAmount(): number {
    return this.invoiceReceiptForm.get("total_amount").value;
  }

  get invproducts() {
    return this.invoiceReceiptForm.get("lines") as FormArray;
  }

  get linesChecks() {
    return this.invoiceReceiptForm.get("linesChecks") as FormArray;
  }

  get linesCash() {
    return this.invoiceReceiptForm.get("linesCash") as FormArray;
  }

  get linesBankPayment() {
    return this.invoiceReceiptForm.get("linesBankPayment") as FormArray;
  }

  get linesCredit() {
    return this.invoiceReceiptForm.get("linesCredit") as FormArray;
  }

  Generaterow() {
    const newIndex = this.invoicedetail.length + 1;
    
    this.stopVatPercentageSubscription();
  
    const row = this.builder.group({
      index: this.builder.control(newIndex),
      product_id: this.builder.control("", Validators.required),
      category: this.builder.control("", Validators.required),
      measure_unit_description: this.builder.control("", Validators.required),
      measurement_unit_id: this.builder.control("", Validators.required),
      description: this.builder.control("", Validators.required),
      catalog_id: this.builder.control("", Validators.required),
      item_name: this.builder.control("", Validators.required),
      quantity: this.builder.control(1),
      price_per_unit: this.builder.control(0),
      discount: this.builder.control(0),
      sub_total: this.builder.control({ value: 0, disabled: true }),
      vat_rate: this.builder.control({
        value: this.VatPercentage,
        disabled: true,
      }),
      vat_amount: this.builder.control({ value: 0, disabled: true }),
      total_amount: this.builder.control(0),
      isVatIncluded: this.builder.control(false), // ✅ Ensure this is always included
    });
  
    console.log(`Generated FormGroup for index ${newIndex - 1}:`, row.value);
    
    return row;
  }

  stopVatPercentageSubscription() {
    this.invoiceReceiptForm.get("vat_list_id")?.disable();
    this.invoiceReceiptForm.get("invoice_date")?.disable();
  }

  resumeVatPercentageSubscription() {
    this.invoiceReceiptForm.get("vat_list_id")?.enable();
    this.invoiceReceiptForm.get("invoice_date")?.enable();
  }
  openChooseCustomerModal(chooseCustomerModal: any) {
    this.modalService.open(chooseCustomerModal, { size: "xl" });
  }
  GetCustomers() {
    this.spinner.show();
    this.service.getAllActiveCustomersBasedOnMerchantId().subscribe((res) => {
      this.masterCustomer = res;
      this.spinner.hide();
    });
  }

  getIdd(id: number) {
    this.id = id;
  }

  getCustomerById() {
    const customerId = this.invoiceReceiptForm.get("customer_id")?.value;
  
    if (customerId != null && customerId !== "") {
      this.spinner.show();
      this.service.getCustomerById(Number(customerId)).subscribe({
        next: (res) => {
          this.oneCustomerData = res;
          // Retrieve the customer's name based on the current language.
          const customerName = this.oneCustomerData?.[
            "customer_name_" + this.curentLang
          ].substring(0, 100);
  
          // Update the form with the received customer data.
          this.invoiceReceiptForm.patchValue({
            customer_name: customerName,
            customer_id_number: this.oneCustomerData?.id_number,
            customer_vat_number: this.oneCustomerData?.customer_vat_number || 0,
          });
  
          // Synchronize the mobile view by updating the customerNameSelected variable.
          this.customerNameSelected = customerName;
  
          this.spinner.hide();
        },
        error: (error) => {
          this.spinner.hide();
        },
      });
    }
  }
  onCustomerSelect(event: any): void {
    const selectedCustomer = event.value[0].customer_id; // الحصول على العميل المختار

    this.invoiceReceiptForm.get("customer_id")?.setValue(selectedCustomer); // تحديث قيمة الحقل
    this.getCustomerById();

    this.modalService.dismissAll();
  }
  GetCompany() {
    this.service.GetCompany().subscribe((res) => {
      this.mastercompany = res;
    });
  }

  GetProducts() {
    this.service.getAllActiveProductsBasedOnMerchantId().subscribe((res) => {
      this.masterproduct = res;
    });
  }

  GetInvoiceTypeList() {
    this.spinner.show();

    this.service.GetInvoiceTypeList().subscribe((res) => {
      this.masterInvoiceTypeList = res;
      this.spinner.hide();
    });
  }

  customerchange() {
    let customer_id = this.invoiceReceiptForm.get("customer_id")?.value;
    this.service.GetCustomerbyId(customer_id).subscribe((res) => {
      let custdata: any;
      custdata = res;
    });
  }

  companychange() {
    let company_id = this.invoiceReceiptForm.get("company_id")?.value;
    this.service.GetCompanybyId(company_id).subscribe((res) => {
      let custdata: any;
      custdata = res;
    });
  }
  getCompanyId(id: any) {
    this.spinner.show();
    this.companyId = id;
    this.spinner.hide();
  }

  getVatPercentageByVatListIdAndDate() {
    const vatListId = this.invoiceReceiptForm.get("vat_list_id")?.value;
    const invoiceDate = this.invoiceReceiptForm.get("invoice_date")?.value;

    if (vatListId && invoiceDate) {
      this.spinner.show();
      this.service
        .getVatPercentageByVatListIdAndDate(vatListId, invoiceDate)
        .subscribe({
          next: (res) => {
            this.vatPersentageData = res;
            if (this.vatPersentageData && this.vatPersentageData.length > 0) {
              this.VatPercentage = this.vatPersentageData[0].vat_percentage;
              this.invoiceReceiptForm.patchValue({
                vat_percentage: this.VatPercentage, // تحديث القيمة في النموذج
              });
              this.summarycalculation(); // إعادة حساب القيم الإجمالية للفاتورة
            } else {
              // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
              this.invoiceReceiptForm.patchValue({
                vat_percentage: 0,
              });
            }
          },
          error: (error) => {
            // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
            this.invoiceReceiptForm.patchValue({
              vat_percentage: 0,
            });
          },
          complete: () => {
            this.spinner.hide(); // إخفاء المحمل بعد الحصول على البيانات بنجاح أو بعد حدوث خطأ
          },
        });
    } else {
      // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
      this.invoiceReceiptForm.patchValue({
        vat_percentage: 0,
      });
      this.spinner.hide(); // إخفاء المحمل إذا لم يتم تحديد كلا من vatListId و invoiceDate
    }
  }

  filter(event: any) {
    let x = new Date(event.target.value);
    this.selectedMonth = x.getMonth() + 1; // Month is 0-based, so add 1
    this.selectedYear = x.getFullYear();

    this.checkClosedMonth();
  }

  getId(id: number) {
    this.invoiceId = id;
  }
  vatTypechange() {
    this.spinner.show();

    this.service.GetVatTypeList().subscribe((res) => {
      this.vatTypeList = res;
    });

    this.spinner.hide();
  }
  storeProductName(proddata: any, index: number): void {
    const productPrice = proddata.price;
    const productName =
      this.curentLang === "en"
        ? proddata.product_name_en
        : this.curentLang === "ar"
        ? proddata.product_name_ar
        : proddata.product_name_he;

    this.selectedProductNames[index] = productName;
    this.selectedProductPrice[index] = productPrice;
  }
  productchange(index: any) {
    this.invoicedetail = this.invoiceReceiptForm.get("lines") as FormArray;
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

    // استماع للتغيرات على Total Amount
    this.invoiceproduct
      .get("total_amount")
      .valueChanges.subscribe((newTotalAmount) => {
        this.calculateReverse(index, newTotalAmount);
      });

    let product_id = this.invoiceproduct.get("product_id")?.value;

    this.service.GetProductbycode(product_id).subscribe((res) => {
      let proddata: any;
      proddata = res;

      // التحقق من إذا كانت بيانات المنتج غير مكتملة
      if (
        proddata?.product_code === "" ||
        proddata?.product_code === null ||
        proddata?.categories_id === null ||
        proddata?.measurement_unit_id === null
      ) {
        // عرض الرسالة التحذيرية وإدخال للمستخدم لإضافة الكود
        Swal.fire({
          icon: "warning",
          title:
            this.curentLang === "ar"
              ? " برجاء استكمال معلومات المنتج والمحاولة مرة اخري"
              : this.curentLang === "en"
              ? "Please complete the product information and try again"
              : this.curentLang === "he"
              ? "אנא מלא את פרטי המוצר ונסה שוב"
              : "",
          input: "text", // إدخال نص للمستخدم
          inputPlaceholder:
            this.curentLang === "ar"
              ? "أدخل كود المنتج"
              : this.curentLang === "en"
              ? "Enter product code"
              : this.curentLang === "he"
              ? "הזן קוד מוצר"
              : "",
          showCancelButton: true,
          confirmButtonText:
            this.curentLang === "ar"
              ? "إرسال"
              : this.curentLang === "en"
              ? "Submit"
              : this.curentLang === "he"
              ? "שלח"
              : "",
          preConfirm: (value) => {
            if (!value || value.trim() === "") {
              Swal.showValidationMessage(
                this.curentLang === "ar"
                  ? "لا يمكن إدخال قيمة فارغة أو مسافة فقط"
                  : this.curentLang === "en"
                  ? "Empty or whitespace-only values are not allowed"
                  : this.curentLang === "he"
                  ? "אין להזין ערך ריק או הכולל רק רווחים"
                  : ""
              );
              return false;
            }
            return value.trim();
          },
        }).then((result) => {
          if (result.isConfirmed && result.value) {
            let newProductCode = result.value;
            this.invoiceproduct.get("catalog_id")?.setValue(newProductCode);

            // إرسال الكود الجديد إلى الخادم
            const model = {
              product_code: newProductCode,
              updated_by: this.userRegistId,
            };

            this.spinner.show();
            this.service.updateProductsById(product_id, model).subscribe(() => {
              // جلب البيانات المحدثة بعد التعديل
              this.service
                .GetProductbycode(product_id)
                .subscribe((updatedProdData) => {
                  this.updateFormWithProductData(updatedProdData, index);
                  this.storeProductName(updatedProdData, index); // حفظ اسم المنتج
                });
            });
            this.spinner.hide();
          }
        });

        return;
      }

      if (proddata != null) {
        this.updateFormWithProductData(proddata, index);
        this.storeProductName(proddata, index); // حفظ اسم المنتج
      }
    });
  }

  // دالة لتحديث حقول النموذج بقيم المنتج المحدثة
  updateFormWithProductData(proddata: any, index: number) {
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

    if (!this.invoiceproduct) {
        console.error(`No FormGroup found at index ${index}`);
        return;
    }

    // ✅ Preserve existing 'isVatIncluded' value (or default to false)
    let currentVatIncluded = this.invoiceproduct.get("isVatIncluded")?.value ?? false;

    this.invoiceproduct.get("productName")?.setValue(proddata.name);
    this.invoiceproduct.get("price_per_unit")?.setValue(proddata.price);
    this.invoiceproduct.get("category")?.setValue(proddata.categories.categories_code);
    this.invoiceproduct.get("measure_unit_description")?.setValue(
        proddata.measurementUnits["measurement_unit_name_" + this.curentLang].substring(0, 20)
    );
    this.invoiceproduct.get("measurement_unit_id")?.setValue(proddata.measurementUnits.measurement_unit_id);
    this.invoiceproduct.get("description")?.setValue(proddata["des_" + this.curentLang].substring(0, 300));
    this.invoiceproduct.get("catalog_id")?.setValue(proddata.product_code.substring(0, 13));
    this.invoiceproduct.get("item_name")?.setValue(proddata["product_name_" + this.curentLang]);

    // ✅ Reapply 'isVatIncluded' value to prevent it from being removed
    if (!this.invoiceproduct.contains('isVatIncluded')) {
        console.warn(`isVatIncluded is missing at index ${index}, adding it back.`);
        this.invoiceproduct.addControl('isVatIncluded', this.builder.control(false));
    }
    this.invoiceproduct.get("isVatIncluded")?.setValue(currentVatIncluded);
    this.invoiceproduct.get("isVatIncluded")?.updateValueAndValidity();

    console.log(`Updated FormGroup at index ${index}:`, this.invoiceproduct.value);
    this.Itemcalculation(index);
}

  //new
  onTotalAmountChange(index: number) {
    const lines = this.invoiceReceiptForm.get('lines') as FormArray;
    const line = lines.at(index) as FormGroup;
  
    // 1. Get the raw value
    let rawVal = line.get('total_amount')?.value;
  
    // 2. Remove commas if it's a string (so "2,017.99" becomes "2017.99")
    if (typeof rawVal === 'string') {
      rawVal = rawVal.replace(/,/g, '');
    }
  
    // 3. Parse as float
    let total = parseFloat(rawVal);
  
    // 4. Default invalid or negative input to zero
    if (isNaN(total) || total < 0) {
      total = 0;
    }
  
    // 5. Round to two decimals
    total = Math.round(total * 100) / 100;
  
    // 6. Update the form control as a valid number-string (e.g., "2017.99")
    line.get('total_amount')?.setValue(total.toFixed(2), { emitEvent: false });
  
    // 7. Reverse calculations, etc.
    this.calculateReverse(index, total);
  }

  /// new
  calculateReverse(index: number, newTotalAmount: number) {
    const line = (this.invoiceReceiptForm.get('lines') as FormArray).at(index) as FormGroup;
  
    // Use user’s total as-is (which is an integer if you forced rounding)
    // Then do your VAT logic:
    const vatRate = parseFloat(line.get("vat_rate")?.value) || 18;
    const quantity = parseFloat(line.get("quantity")?.value) || 1;
    const discount = parseFloat(line.get("discount")?.value) || 0;
    const isVatIncluded = line.get("isVatIncluded")?.value || false;
  
    const vatFraction = vatRate / 100;
  
    let subTotal = isVatIncluded
      ? newTotalAmount / (1 + vatFraction)
      : newTotalAmount;
  
    // Apply discount (don’t let net go below zero)
    let net = Math.max(subTotal - discount, 0);
    let vat = net * vatFraction;
    let pricePerUnit = quantity ? net / quantity : 0;
    let finalTotal = net + vat;
  
    // If you want to preserve integer totals, do not overwrite total_amount with decimals
    // Or, if you do want decimals, you can set them here:
    line.get("sub_total")?.setValue(subTotal.toFixed(2), { emitEvent: false });
    line.get("vat_amount")?.setValue(vat.toFixed(2), { emitEvent: false });
    line.get("price_per_unit")?.setValue(pricePerUnit.toFixed(2), { emitEvent: false });
  
    // Optionally override the total with finalTotal if you want to show decimals
    // line.get("total_amount")?.setValue(finalTotal.toFixed(2), { emitEvent: false });
  
    // Finally update summary
    this.summarycalculation();
  }
Removeproduct(index: any): void {
  // إزالة المنتج من المصفوفة invproducts
  this.invproducts.removeAt(index);

  // حذف اسم المنتج من المصفوفة selectedProductNames
  if (this.selectedProductNames && this.selectedProductNames.length > index) {
    this.selectedProductNames.splice(index, 1);
  }

  if (this.selectedProductPrice && this.selectedProductPrice.length > index) {
    this.selectedProductPrice.splice(index, 1);
  }

  // إعادة حساب الملخص
  this.summarycalculation();

  // التحقق من إعادة تفعيل اشتراك ضريبة القيمة المضافة إذا أصبحت المصفوفة فارغة
  if (this.invproducts.length == 0) {
    this.resumeVatPercentageSubscription();
  }

  // إغلاق المودال النشط
  this.closeActiveModal();

  // فتح مودال اختيار المنتج
}
closeActiveModal() {
  if (this.activeModal) {
    this.activeModal.close(); // Closes the active modal
  }  }
  GetBankList() {
    this.spinner.show();
    this.service.GetBankList().subscribe((res) => {
      this.masterBank = res;

      this.spinner.hide();
    });
  }

  getBranches(bankCode: string, index: number) {
    // التحقق إذا كانت القيم غير موجودة (undefined أو فارغة)
    if (!bankCode || index === undefined || index === null) {
      // تعيين قائمة فارغة للفروع لهذا السطر
      this.masterBranches[index] = [];
      // إعادة تعيين حقل الفرع في الفورم
      const branchControl = (
        this.invoiceReceiptForm.get("linesChecks") as FormArray
      )
        .at(index)
        .get("branch");
      branchControl.reset(); // إعادة تعيين حقل الفرع
      return;
    }

    // إذا كانت القيم موجودة، نتابع استدعاء الـ API
    const selectedBank = this.masterBank.find(
      (bank) => bank.bank_code === bankCode
    );
    if (selectedBank) {
      const bankId = selectedBank.bank_id;
      this.spinner.show(); // عرض الـ spinner أثناء التحميل
      this.service.getAllBranchById(bankId).subscribe({
        next: (res) => {
          // تخزين البيانات الخاصة بالفروع لهذا السطر
          this.masterBranches[index] = res;

          // التحقق من وجود الفرع المختار ضمن الفروع المحملة
          const branchControl = (
            this.invoiceReceiptForm.get("linesChecks") as FormArray
          )
            .at(index)
            .get("branch");
          const branchValue = branchControl.value;
          const branchExists = res.some(
            (branch) => branch.bank_branch_code === branchValue
          );
          if (!branchExists) {
            // إعادة تعيين حقل الفرع إذا لم يكن موجودًا
            branchControl.reset();
          }

          this.spinner.hide(); // إخفاء الـ spinner بعد نجاح الاستدعاء
        },
        error: (error) => {
          console.error("Error fetching branches:", error);
          this.spinner.hide(); // إخفاء الـ spinner عند حدوث خطأ
        },
      });
    }
  }

  getBranches2(bankCode: string, index: number) {
    // التحقق إذا كانت القيم غير موجودة (undefined أو فارغة)
    if (!bankCode || index === undefined || index === null) {
      console.warn("bankCode or index is missing");
      // تعيين قائمة فارغة للفروع لهذا السطر
      this.masterBranches[index] = [];
      // إعادة تعيين حقل الفرع في الفورم
      const branchControl = (
        this.invoiceReceiptForm.get("linesBankPayment") as FormArray
      )
        .at(index)
        .get("branch");
      branchControl.reset(); // إعادة تعيين حقل الفرع
      return;
    }
  }

  getAllBankBranch() {
    this.spinner.show();
    this.service.getAllBankBranch().subscribe({
      next: (res) => {
        this.allBranchesData = res;
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }

  addNewLineToCash() {
    let linesCashArray = this.invoiceReceiptForm.get("linesCash") as FormArray;

    let customerId = this.invoiceReceiptForm.get("customer_id")?.value;
    let companyId = this.invoiceReceiptForm.get("company_id")?.value;

    if (
      customerId != null &&
      customerId != "" &&
      companyId != null &&
      companyId != ""
    ) {
      linesCashArray.push(this.GenerateRowCash());

      // Update cash_amount
      this.invoiceReceiptForm
        .get("cash_amount")
        .setValue(this.getTotalAmountCashArray());
      this.calculateTotalAmount(); // Update total_amount
    } else {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يرجى ملء جميع الحقول"
            : this.curentLang === "en"
            ? "Please fill all fields"
            : this.curentLang === "he"
            ? "נא למלא כל השדות"
            : "",
      });
    }
  }

  addNewLineToBankPayment() {
    let linesBankPaymentArray = this.invoiceReceiptForm.get(
      "linesBankPayment"
    ) as FormArray;

    let customerId = this.invoiceReceiptForm.get("customer_id")?.value;
    let companyId = this.invoiceReceiptForm.get("company_id")?.value;

    if (
      customerId != null &&
      customerId != "" &&
      companyId != null &&
      companyId != ""
    ) {
      linesBankPaymentArray.push(this.GenerateRowBankPayment());

      // Update cash_amount
      this.invoiceReceiptForm
        .get("cash_amount")
        .setValue(this.getTotalAmountBankPaymentArray());
      this.calculateTotalAmount(); // Update total_amount
    } else {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يرجى ملء جميع الحقول"
            : this.curentLang === "en"
            ? "Please fill all fields"
            : this.curentLang === "he"
            ? "נא למלא כל השדות"
            : "",
      });
    }
  }

  addNewLineToChecks() {
    let linesChecksArray = this.invoiceReceiptForm.get(
      "linesChecks"
    ) as FormArray;

    let customerId = this.invoiceReceiptForm.get("customer_id")?.value;
    let companyId = this.invoiceReceiptForm.get("company_id")?.value;

    if (
      customerId != null &&
      customerId != "" &&
      companyId != null &&
      companyId != ""
    ) {
      linesChecksArray.push(this.GenerateRowCheck());

      // Update checks_amount
      this.invoiceReceiptForm
        .get("checks_amount")
        .setValue(this.getTotalAmountChecksArray());
      this.calculateTotalAmount(); // Update total_amount
    } else {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يرجى ملء جميع الحقول"
            : this.curentLang === "en"
            ? "Please fill all fields"
            : this.curentLang === "he"
            ? "נא למלא כל השדות"
            : "",
      });
    }
  }

  addNewLineToCredit() {
    const linesCreditArray = this.invoiceReceiptForm.get(
      "linesCredit"
    ) as FormArray;

    const customerId = this.invoiceReceiptForm.get("customer_id")?.value;
    const companyId = this.invoiceReceiptForm.get("company_id")?.value;

    if (customerId && companyId) {
      linesCreditArray.push(this.GenerateRowCreidt());

      this.invoiceReceiptForm
        .get("checks_amount")
        ?.setValue(this.getTotalAmountCreidtArray());
      this.calculateTotalAmount(); // Update total_amount
    } else {
      Swal.fire({
        icon: "warning",
        title:
          this.curentLang === "ar"
            ? "يرجى ملء جميع الحقول"
            : this.curentLang === "en"
            ? "Please fill all fields"
            : this.curentLang === "he"
            ? "נא למלא כל השדות"
            : "",
      });
    }
  }
  getReceiptDate(): string {
    return (
      this.invoiceReceiptForm.get("invoice_date")?.value ||
      this.datePipe.transform(new Date(), "yyyy-MM-dd")
    );
  }
  // GENERATE ROWS
  GenerateRowCheck() {
    return this.builder.group({
      date: this.builder.control(this.getReceiptDate(), Validators.required),
      reference: this.builder.control(1, Validators.required),
      bank: this.builder.control("", Validators.required),
      branch: this.builder.control("", Validators.required),
      account: this.builder.control(
        { value: 0, disabled: false },
        Validators.required
      ),
      amount: this.builder.control(
        { value: 0, disabled: false },
        Validators.required
      ),
      payment_type: this.builder.control({
        value: this.checkType,
        disabled: true,
      }),
    });
  }

  GenerateRowCash() {
    return this.builder.group({
      date: this.builder.control(this.getReceiptDate(), Validators.required),
      amount: this.builder.control("", Validators.required),
      payment_type: this.builder.control({
        value: this.cashType,
        disabled: true,
      }),
    });
  }

  GenerateRowBankPayment() {
    return this.builder.group({
      date: this.builder.control(this.getReceiptDate(), Validators.required),
      amount: this.builder.control("", Validators.required),
      account: this.builder.control({ value: 0, disabled: false }),
      bank: this.builder.control(""),
      payment_type: this.builder.control({
        value: this.bankTransferType,
        disabled: true,
      }),
    });
  }

  GenerateRowCreidt() {
    return this.builder.group({
      date: this.builder.control(this.getReceiptDate(), Validators.required),
      amount: this.builder.control("", [
        Validators.required,
        Validators.min(0.01),
      ]),
      credit_card_code: this.builder.control("", Validators.required),
      credit_card_name: this.builder.control("", Validators.required),
      account: this.builder.control("", [
        Validators.required,
        Validators.min(1),
      ]),
      credit_type: this.builder.control("", Validators.required),
      payment_type: this.builder.control({
        value: this.creditCardType,
        disabled: true,
      }),
    });
  }

  // Remove Cash Row
  removeLineFromCash(index: number) {
    const linesCashArray = this.invoiceReceiptForm.get(
      "linesCash"
    ) as FormArray;
    linesCashArray.removeAt(index);
    this.calculateTotalAmount();
  }

  // Remove Check Row
  removeLineFromChecks(index: number) {
    const linesChecksArray = this.invoiceReceiptForm.get(
      "linesChecks"
    ) as FormArray;
    linesChecksArray.removeAt(index);
    this.calculateTotalAmount();
  }

  removeLineFromBankPayment(index: number) {
    const linesChecksArray = this.invoiceReceiptForm.get(
      "linesBankPayment"
    ) as FormArray;
    linesChecksArray.removeAt(index);
    this.calculateTotalAmount();
  }

  removeLineFromCreidtPayment(index: number) {
    const linesChecksArray = this.invoiceReceiptForm.get(
      "linesCredit"
    ) as FormArray;
    linesChecksArray.removeAt(index);
    this.calculateTotalAmount();
  }

  getTotalAmountChecksArray(): number {
    let total = 0;
    const array = this.invoiceReceiptForm.get("linesChecks") as FormArray;
    array.controls.forEach((group: FormGroup) => {
      const value = group.get("amount")?.value;
      total += parseFloat(value) || 0;
    });
    return total;
  }

  getTotalAmountCashArray(): number {
    let totalAmount = 0;
    const linesCashArray = this.invoiceReceiptForm.get(
      "linesCash"
    ) as FormArray;
    linesCashArray.controls.forEach((control: FormGroup) => {
      const value = control.get("amount")?.value;
      totalAmount += parseFloat(value) || 0;
    });
    return totalAmount;
  }

  getTotalAmountBankPaymentArray(): number {
    let totalAmount = 0;
    const linesBankPaymentArray = this.invoiceReceiptForm.get(
      "linesBankPayment"
    ) as FormArray;
    linesBankPaymentArray.controls.forEach((control: FormGroup) => {
      const value = control.get("amount")?.value;
      totalAmount += parseFloat(value) || 0;
    });
    return totalAmount;
  }

  getTotalAmountCreidtArray(): number {
    let totalAmount = 0;
    const linesChecksArray = this.invoiceReceiptForm.get(
      "linesCredit"
    ) as FormArray;

    linesChecksArray.controls.forEach((control: FormGroup) => {
      const rawAmount = control.get("amount")?.value;
      const parsedAmount = parseFloat(rawAmount) || 0;
      totalAmount += parsedAmount;
    });

    return totalAmount;
  }

  getBankLabel(item: any): string {
    return this.curentLang === "en"
      ? item.bank_name_en
      : this.curentLang === "ar"
      ? item.bank_name_ar
      : item.bank_name_he;
  }

  getBranchLabel(item: any): string {
    return this.curentLang === "en"
      ? item.bank_branch_name_en
      : this.curentLang === "ar"
      ? item.bank_branch_name_ar
      : item.bank_branch_name_he;
  }
}
