import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { DocumentType, DocumentTypeEvent } from './alldocuments-types.model';
import { DOCUMENT_TYPES } from './alldocuments-types.data';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DocumentTypeService } from './types.service';

@Component({
  selector: 'app-alldocuments-types',
  templateUrl: './alldocuments-types.component.html',
  styleUrls: ['./alldocuments-types.component.scss']
})
export class AlldocumentsTypesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedType: DocumentType | null = null;
  @Input() placeholder: string = 'allDocuments.filters.types.placeholder';
  @Output() typeChange = new EventEmitter<DocumentTypeEvent>();
  @Output() documentTypesLoaded = new EventEmitter<DocumentType[]>(); // Add new event emitter

  documentTypes: DocumentType[] = [];
  filteredDocumentTypes: DocumentType[] = [];
  selectedValue: DocumentType | null = null;
  private destroy$ = new Subject<void>();

  // Initialize with a default icon to prevent ExpressionChangedAfterItHasBeenCheckedError
  placeholderIcon: string = 'pi pi-file';

  constructor(private translateService: TranslateService, private documentTypeService: DocumentTypeService, private cdr: ChangeDetectorRef) { }

  async ngOnInit(): Promise<void> {
    this.documentTypes = await this.documentTypeService.getDocumentTypes();
    this.filteredDocumentTypes = [...this.documentTypes];
    this.selectedValue = null;
    this.selectedType = null;

    // Emit the loaded types
    this.documentTypesLoaded.emit(this.documentTypes);

    // Ensure that any changes are properly detected
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedType']) {
      this.selectedValue = this.selectedType;
      // Make sure icons are properly set when selectedType changes
      this.ensureSelectedTypeHasIcon();
    }
  }

  onTypeChange(event: any): void {
    this.selectedType = event.value;
    this.typeChange.emit({
      value: event.value,
      originalEvent: event.originalEvent
    });
  }

  isTypeSelected(type: DocumentType): boolean {
    return this.documentTypeService.isTypeSelected(this.selectedType, type);
  }

  // Ensure selected type has an icon set
  private ensureSelectedTypeHasIcon(): void {
    if (this.selectedType && !this.selectedType.icon) {
      this.selectedType.icon = this.placeholderIcon;
      this.cdr.detectChanges();
    }
  }
}
