import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { plans } from "./subscription-plans.data";
import { Plan } from "./subscription-plans.model";
import { TranszillaService } from 'src/app/services/transzilla.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
    selector: "app-subscription-plans",
    templateUrl: "./subscription-plans.component.html",
    styleUrls: ["./subscription-plans.component.scss"],
    providers: [ConfirmationService, MessageService]
})
export class SubscriptionPlansComponent {
    selectedPlan: number | null = null;
    planType: "monthly" | "yearly" = "monthly";
    currentPlan: Plan = plans[1];

    plans: Plan[] = plans.map(plan => ({
        ...plan,
        isRelevant: plan.id === 2 // Example logic: Mark plan with id 2 as most relevant
    }));

    billingOptions = [
        { label: "Monthly", value: "monthly" },
        { label: "Yearly", value: "yearly" }
    ];

    get filteredPlans(): Plan[] {
        return this.plans.filter(plan => plan.billing === this.planType);
    }

    selectPlan(id: number): void {
        this.selectedPlan = id;
    }

    setPlanType(type: "monthly" | "yearly"): void {
        this.planType = type; // Update the plan type
        this.selectedPlan = null; // Reset selected plan when switching billing type
    }

    compareFeatures(planA: Plan, planB: Plan): number {
        const featuresA = planA.features.length;
        const featuresB = planB.features.length;
        return featuresB - featuresA; // Plans with more features come first
    }

    confirmSubscription(planId: number): void {
        this.confirmationService.confirm({
            message: 'Are you sure you want to subscribe to this plan?',
            header: 'Confirm Subscription',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.subscribeToPlan(planId);
            },
            reject: () => {
                this.messageService.add({ severity: 'info', summary: 'Cancelled', detail: 'Subscription cancelled' });
            }
        });
    }

    subscribeToPlan(planId: number): void {
        const userId = 'currentUserId'; // Replace with actual user ID
        this.transzillaService.subscribeToPlan(planId.toString(), userId).subscribe({
            next: (response) => {
                console.log('Subscribed to plan:', response);
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Subscription successful!' });
            },
            error: (error) => {
                console.error('Error subscribing to plan:', error);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to subscribe to plan.' });
            }
        });
    }

    cancelSubscription(): void {
        // Implementation will be added in Step 5
    }

    get trialEndDate(): Date {
        if (!this.currentPlan?.startDate || !this.currentPlan?.trial) {
            return null;
        }

        const startDate = new Date(this.currentPlan.startDate);
        return new Date(startDate.setDate(startDate.getDate() + this.currentPlan.trial));
    }

    constructor(
        private router: Router,
        private transzillaService: TranszillaService,
        private confirmationService: ConfirmationService,
        private messageService: MessageService
    ) {}
}
