import { Injectable } from '@angular/core';
import { HttpHelpService } from 'src/app/Data/service/http-help.service';
import { Customer, CustomerData, CustomerResponse } from './alldocuments-customers.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  private customersCache: CustomerResponse | null = null;
  private customersSubject = new BehaviorSubject<Customer[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private customersMap: Map<string, Customer> = new Map();

  public customers$ = this.customersSubject.asObservable();
  public loading$ = this.loadingSubject.asObservable();

  constructor(private httpHelpService: HttpHelpService) {}

  getCustomers(): Observable<Customer[]> {
    // Get current language from localStorage
    const currentLang = localStorage.getItem('currentLang') || 'en';

    if (this.customersCache && this.customersSubject.value.length > 0) {
      return of(this.customersSubject.value);
    }

    this.loadingSubject.next(true);

    return this.httpHelpService.getCustomersdrop().pipe(
      tap((data: CustomerResponse) => {
        if (data) {
          this.customersCache = data as CustomerResponse;
        }
      }),
      map((data: CustomerResponse) => {
        if (data && Array.isArray(data.customers)) {
          const customers = data.customers.map((customer: CustomerData) => ({
            label: customer[`customer_customer_name_${currentLang}`]?.trim() ?? "Unknown",
            value: customer.customer_customer_id?.toString() ?? null,
            code: customer.customer_customer_id?.toString() ?? null,
            name: customer[`customer_customer_name_${currentLang}`]?.trim() ?? "Unknown"
          }));

          // Build the customers map for quick lookup
          customers.forEach(customer => {
            this.customersMap.set(customer.value, customer);
          });

          this.customersSubject.next(customers);
          return customers;
        }
        return [];
      }),
      catchError(error => {
        console.error("Error fetching customers:", error);
        return of([]);
      }),
      tap(() => this.loadingSubject.next(false))
    );
  }

  filterCustomers(customers: Customer[], filterValue: string): Customer[] {
    if (!filterValue) {
      return [...customers];
    }
    
    const lowerFilter = filterValue.toLowerCase();
    return customers.filter(customer => 
      customer.label && customer.label.toLowerCase().includes(lowerFilter)
    );
  }

  // Add a new method to get customer by ID
  getCustomerById(id: string): Customer | undefined {
    // First check our map for quick lookup
    if (this.customersMap.has(id)) {
      return this.customersMap.get(id);
    }

    // If not in map, look through the list (slower fallback)
    // This is a placeholder - the map should have all customers if getCustomers was called
    return undefined;
  }

  // Add a helper method to get a formatted customer name
  getCustomerNameById(id: string): string {
    const customer = this.getCustomerById(id);
    return customer ? customer.name : `Customer #${id}`;
  }
}
