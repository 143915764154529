import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-alldocuments-search',
  templateUrl: './alldocuments-search.component.html',
  styleUrls: ['./alldocuments-search.component.scss']
})
export class AlldocumentsSearchComponent implements OnInit, OnDestroy {
  @Input() placeholder: string = 'allDocuments.filters.search.placeholder';
  @Input() initialValue: string = '';
  @Input() debounceTime: number = 400;
  
  @Output() search = new EventEmitter<string>();
  @Output() clear = new EventEmitter<void>();
  
  searchControl = new FormControl('');
  private destroy$ = new Subject<void>();
  
  constructor(
    private translate: TranslateService,
    private messageService: MessageService
  ) {}
  
  ngOnInit(): void {
    if (this.initialValue) {
      this.searchControl.setValue(this.initialValue);
    }

    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.debounceTime),
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.search.emit(this.normalizeSearch(value ?? ''));
      });
  }
  
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  clearSearch(): void {
    this.searchControl.setValue('');
    this.clear.emit();

    this.messageService.add({
      severity: 'info',
      summary: this.translate.instant('allDocuments.filters.search.clearTitle'),
      detail: this.translate.instant('allDocuments.filters.search.clearMessage')
    });
  }

  normalizeSearch(text: string): string {
    if (!text) return "";

    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[أإآ]/g, "ا")
      .replace(/ة/g, "ه")
      .replace(/ى/g, "ي")
      .replace(/[\u064B-\u065F]/g, "");
  }
}
