import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { DashboardComponent } from "./dashboard.component";
import { ButtonModule } from "primeng/button";
import { CardModule } from "primeng/card";
import { ToggleButtonModule } from "primeng/togglebutton";
import { BreadcrumbModule } from "primeng/breadcrumb"; // Import PrimeNG BreadcrumbModule
import { SharedModule } from "../../shared/shared.module"; // Import shared module if app-breadcrumb is declared there
import { SelectButtonModule } from "primeng/selectbutton"; // Import SelectButtonModule
import { SubscriptionPlansModule } from './subscription-plans/subscription-plans.module'; // Correct import path
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        DashboardComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ButtonModule,
        CardModule,
        ToggleButtonModule,
        BreadcrumbModule, // Add BreadcrumbModule
        SharedModule, // Add SharedModule if app-breadcrumb is declared there
        SelectButtonModule, // Add SelectButtonModule
        SubscriptionPlansModule, // Ensure correct module import
        RouterModule
    ],
    exports: [
        DashboardComponent,
    ],
})
export class DashboardModule {}
