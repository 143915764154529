<div class="dropdown-wrapper" [ngClass]="{'cursor-disabled': customersLoading}">
  <i class="pi pi-user"></i>
  <p-dropdown 
    [options]="filteredCustomers" 
    [(ngModel)]="selectedCustomer" 
    [placeholder]="(customersLoading ? loadingPlaceholder : placeholder) | translate" 
    [showClear]="true"
    [dropdownIcon]="customersLoading ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-down'" 
    [filter]="true"
    filterBy="label" 
    [autofocus]="true" 
    (onChange)="onCustomerChange($event)" 
    (onFilter)="onFilter($event)">
  </p-dropdown>
</div>
