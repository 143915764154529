<div>
  <h4>{{ 'allDocuments.filters.period.title' | translate }}</h4>
  
  <!-- Text field to display selected time period -->
  <span class="p-input-icon-right w-100 mb-3">
    <!-- TODO: make in work in both sides -->
    <input type="text" pInputText class="w-100" [value]="getFormattedTimePeriod()" 
           placeholder="{{ 'allDocuments.filters.period.placeholder' | translate }}" readonly disabled>
    <i class="pi pi-calendar"></i>
  </span>

  <!-- Time period option buttons -->
  <div class="mb-3">
    <p-selectButton [options]="translatedTimePeriodOptions" optionLabel="label" 
                   styleClass="w-100 flex-wrap" (onOptionClick)="onTimePeriodSelect($event.option)">
          <ng-template let-item>
            {{ item.label | translate }}
          </ng-template>
        </p-selectButton>
  </div>
  
  <!-- Calendar for custom date selection -->
  <div class="calendar-container">
    <p-calendar [(ngModel)]="selectedTimePeriod" [inline]="true" selectionMode="range"
      (onSelect)="onCalendarSelect($event)" showButtonBar="true">
    </p-calendar>
  </div>
</div>
