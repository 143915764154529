import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http'; // Add this import
import { TooltipModule } from 'primeng/tooltip'; // Add this import

// PrimeNG imports
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ToolbarModule } from 'primeng/toolbar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

import { AlldocumentsViewPdfModalComponent } from './alldocuments-view-pdf-modal.component';

@NgModule({
  declarations: [
    AlldocumentsViewPdfModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    HttpClientModule, // Add this module
    DialogModule,
    ButtonModule,
    ToolbarModule,
    SelectButtonModule,
    SplitButtonModule,
    ToastModule,
    TooltipModule, // Add this module
  ],
  exports: [
    AlldocumentsViewPdfModalComponent
  ],
  providers: [
    MessageService
  ]
})
export class AlldocumentsViewPdfModalModule { }
